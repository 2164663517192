.yellow {
    background-color: #EDB65A
}

.blue {
    background-color: #76BFDC
}

.blue2 {
    background-color: #83A4E9
}

.purple {
    background-color: #C9A9E6
}

/* .green {
    background-color: #61CC68
} */

.green2 {
    background-color: #62B9BE
}

.pink {
    background-color: #e79dc5
}

.orange {
    background-color: #ed9977
}

.gray {
    background-color: #bdc6ce
}

.color-pl {
    background-color: #e9c580
}

.lightyellow {
    background-color: #e9c580
}

.lightblue {
    background: #a7d7ea
}

.lightblue2 {
    background: #a5b7de
}

.lightpurple {
    background: #b7a9d7
}

.lightgreen {
    background: #bce1ad
}

.lightgreen2 {
    background: #90ddd6
}

.lightpink {
    background: #e79dc5
}

.lightorange {
    background: #efb890
}

.lightgray {
    background: rgba(52, 73, 94, 0.4)
}

.HKD,
.RMB,
.PIN {
    background-color: #EDB65A
}

.PTI,
.PTAD,
.BDT {
    background-color: #eecc2d
}

.INR,
.PTS {
    background-color: #76BFDC
}

.PUI,
.PBU {
    background-color: #92dae1
}

.EUR,
.KRW {
    background-color: #83A4E9
}

.PTR,
.VND {
    background-color: #D1E231
}

.PBU,
.IDR {
    background-color: #C9A9E6
}

.PTA,
.EHK {
    background-color: #b7a9d7
}

.THB,
.PTU {
    background-color: #61CC68
}

.PTM,
.PKR {
    background-color: #90ddd6
}

.AED,
.PTE {
    background-color: #62B9BE
}

.P,
.PKU {
    background-color: #50C878
}

.PHP,
.PTG {
    background-color: #e790c0
}

.PTD,
.PR {
    background-color: #e79dc5
}

.GBP,
.PTH {
    background-color: #ed9977
}

.IR,
.PIR {
    background-color: #efb890
}

.textRed {
    color: #E74C3C !important
}

.badge_ss {
    background-color: #b7a9d7
}

.badge_sup {
    background-color: #90ddd6
}

.badge_ma {
    background-color: #e9c580
}

.badge_pl {
    background-color: #a7d7ea
}

.site_title i {
    content: "";
    display: block
}

.scroll_box,
.x-open #mainb {
    overflow-x: hidden;
    overflow-y: auto
}

.x-collapse #mainb,
.x-collapse-thead #mainb,
.x-collapse-total #mainb {
    overflow-x: hidden;
    overflow-y: hidden
}

.setDefault .id,
.function_wrap .date_box,
.function_wrap .currency_box,
.function_wrap .gametype_box,
.function_wrap .product_box,
.bottom .input-sm,
.setDefault .id_box,
.function_wrap .product_content {
    padding-left: 0;
    text-indent: 5px;
    border-top: 1px solid #ACC1CE;
    border-right: 1px solid #ACC1CE;
    border-bottom: 1px solid #ACC1CE;
    border-left: 1px solid #ACC1CE
}

.setDefault .id,
.function_wrap .date_box,
.function_wrap .currency_box,
.function_wrap .gametype_box,
.function_wrap .product_box,
.bottom .input-sm {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    background: #fff;
    height: 34px;
    vertical-align: middle;
    color: #333;
    border-radius: 5px;
    cursor: pointer
}

.setDefault .id span,
.function_wrap .date_box span,
.function_wrap .currency_box span,
.function_wrap .gametype_box span,
.function_wrap .product_box span,
.bottom .input-sm span {
    color: #A2A2A2
}

.setDefault .id .fa-user,
.function_wrap .date_box .fa-user,
.function_wrap .currency_box .fa-user,
.function_wrap .gametype_box .fa-user,
.function_wrap .product_box .fa-user,
.bottom .input-sm .fa-user {
    margin-right: 5px
}

.setDefault .id .arrow-down,
.function_wrap .date_box .arrow-down,
.function_wrap .currency_box .arrow-down,
.function_wrap .gametype_box .arrow-down,
.function_wrap .product_box .arrow-down,
.bottom .input-sm .arrow-down {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
    height: 100%;
    padding: 5px 9px 5px 0px
}

.setDefault .id:hover .arrow-down,
.function_wrap .date_box:hover .arrow-down,
.function_wrap .currency_box:hover .arrow-down,
.function_wrap .gametype_box:hover .arrow-down,
.function_wrap .product_box:hover .arrow-down,
.bottom .input-sm:hover .arrow-down {
    background-color: #E0EDF1;
    border-left: 1px solid #ACC1CE;
    border-radius: 0px 4px 4px 0px
}

.setDefault .open.id,
.function_wrap .open.date_box,
.function_wrap .open.currency_box,
.function_wrap .open.gametype_box,
.function_wrap .open.product_box,
.bottom .open.input-sm {
    border-right: none
}

.setDefault .open.id .arrow-down,
.function_wrap .open.date_box .arrow-down,
.function_wrap .open.currency_box .arrow-down,
.function_wrap .open.gametype_box .arrow-down,
.function_wrap .open.product_box .arrow-down,
.bottom .open.input-sm .arrow-down {
    background-color: #E0EDF1;
    border-left: 1px solid #ACC1CE;
    border-right: 1px solid #ACC1CE;
    height: calc(100% + 5px);
    border-radius: 0 4px 0 0
}

.setDefault .id_box,
.function_wrap .product_content {
    display: none;
    position: absolute;
    left: 0;
    top: calc(34px + 3px);
    width: 100%;
    padding-top: 5px;
    background-color: #E0EDF1
}

.setDefault .open.id_box,
.function_wrap .open.product_content {
    display: block
}

.yellow {
    background-color: #EDB65A
}

.blue {
    background-color: #76BFDC
}

.blue2 {
    background-color: #83A4E9
}

.purple {
    background-color: #C9A9E6
}

/* .green {
    background-color: #61CC68
} */

/* .green2 {
    background-color: #62B9BE
} */

.pink {
    background-color: #e79dc5
}

.orange {
    background-color: #ed9977
}

.gray {
    background-color: #bdc6ce
}

.color-pl {
    background-color: #e9c580
}

.lightyellow {
    background-color: #e9c580
}

.lightblue {
    background: #a7d7ea
}

.lightblue2 {
    background: #a5b7de
}

.lightpurple {
    background: #b7a9d7
}

.lightgreen {
    background: #bce1ad
}

.lightgreen2 {
    background: #90ddd6
}

.lightpink {
    background: #e79dc5
}

.lightorange {
    background: #efb890
}

.lightgray {
    background: rgba(52, 73, 94, 0.4)
}

.HKD,
.RMB,
.PIN {
    background-color: #EDB65A
}

.PTI,
.PTAD,
.BDT {
    background-color: #eecc2d
}

.INR,
.PTS {
    background-color: #76BFDC
}

.PUI,
.PBU {
    background-color: #92dae1
}

.EUR,
.KRW {
    background-color: #83A4E9
}

.PTR,
.VND {
    background-color: #D1E231
}

.PBU,
.IDR {
    background-color: #C9A9E6
}

.PTA,
.EHK {
    background-color: #b7a9d7
}

.THB,
.PTU {
    background-color: #61CC68
}

.PTM,
.PKR {
    background-color: #90ddd6
}

.AED,
.PTE {
    background-color: #62B9BE
}

.P,
.PKU {
    background-color: #50C878
}

.PHP,
.PTG {
    background-color: #e790c0
}

.PTD,
.PR {
    background-color: #e79dc5
}

.GBP,
.PTH {
    background-color: #ed9977
}

.IR,
.PIR {
    background-color: #efb890
}

.textRed {
    color: #E74C3C !important
}

.badge_ss {
    background-color: #b7a9d7
}

.badge_sup {
    background-color: #90ddd6
}

.badge_ma {
    background-color: #e9c580
}

.badge_pl {
    background-color: #a7d7ea
}

.nav-md {
    color: #2A3F54;
    background: #F7F7F7;
    font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.471;
    width: 1437px;
}

.nav-sm {
    color: #2A3F54;
    background: #F7F7F7;
    font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.471;
    width: 1437px;
}

.blue {
    color: #76BFDC
}

.purple {
    color: #C9A9E6
}

.green {
    color: #61CC68
}

.aero {
    color: #9CC2CB
}

.red {
    color: #E74C3C
}

.dark {
    color: #34495E
}

.border-blue {
    border-color: #76BFDC !important
}

.border-purple {
    border-color: #C9A9E6 !important
}

.border-green {
    border-color: #61CC68 !important
}

.border-aero {
    border-color: #9CC2CB !important
}

.border-red {
    border-color: #E74C3C !important
}

.border-dark {
    border-color: #34495E !important
}

.bg-white {
    background: #fff !important;
    border: 1px solid #fff !important;
    color: #73879C
}

.bg-green {
    background: #61CC68 !important;
    border: 1px solid #61CC68 !important;
    color: #fff
}

.bg-red {
    background: #E74C3C !important;
    border: 1px solid #E74C3C !important;
    color: #fff
}

.bg-blue {
    background: #76BFDC !important;
    border: 1px solid #76BFDC !important;
    color: #fff
}

.bg-orange {
    background: #ed9977 !important;
    border: 1px solid #ed9977 !important;
    color: #fff
}

.bg-purple {
    background: #C9A9E6 !important;
    border: 1px solid #C9A9E6 !important;
    color: #fff
}

.bg-blue-sky {
    background: #50C1CF !important;
    border: 1px solid #50C1CF !important;
    color: #fff
}

.bg-PL {
    background-color: #e5f3f9 !important
}

.bg-MA {
    background-color: #f8edd7 !important
}

.bg-SUP {
    background-color: #eaf5e5 !important
}

.bg-SS {
    background-color: #e9e4f3 !important
}

.bg-SSS {
    background-color: #ffe1e6 !important
}

.bg-ONE {
    background-color: #f9e3d3 !important
}

.bg-downline {
    background-color: #eff5b7 !important
}

.align-L {
    text-align: left
}

.align-R {
    text-align: right
}

.align-C {
    text-align: center
}

select {
    -webkit-appearance: none;
    -moz-appearance: none
}

label {
    font-weight: normal !important;
    margin-bottom: 0 !important
}

.confirm_box {
    display: flex;
    justify-content: flex-end;
    padding: 5px !important;
    border-top: 1px solid #ACC1CE;
    text-indent: 0
}

.confirm_box .btn-group-sm>.btn {
    margin: 0;
    color: #fff;
    font-size: 13px;
    background-color: #425668
}

.confirm_box .fa-chevron-up {
    margin-right: 5px
}

.confirm_box .btn-group-sm {
    margin: 0
}

.disable {
    background-color: #f8f9f9 !important;
    color: #ACC1CE !important;
    cursor: initial !important
}

.disable .arrow-down {
    color: #ACC1CE !important
}

.disable:hover .arrow-down {
    background-color: #f8f9f9 !important;
    border-left: none !important;
    border-radius: inherit
}

.scroll_box {
    width: 100%;
    max-height: 180px;
    height: auto;
    padding: 5px
}

.scroll_box.multi_select {
    max-height: 290px
}

.btn_box {
    display: flex
}

.form-control-feedback.right {
    right: 2px !important
}

.DTFC_LeftBodyWrapper .table-bordered th,
.dataTables_scrollBody .table-bordered th {
    border-top-width: 0px !important;
    border-bottom-width: 0px !important
}

.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>th {
    background-color: rgba(52, 73, 94, 0.1);
    color: #465a6f !important
}

table.jambo_table thead {
    background-color: rgba(52, 73, 94, 0.1) !important
}

table.jambo_table thead tr th {
    color: #465a6f !important
}

.table-bordered th:not(.no-bd-bottom) {
    border-bottom: 2px solid rgba(52, 73, 94, 0.4) !important
}

.th-bg-dark {
    background-color: rgba(188, 197, 206, 0.5) !important
}

.even td {
    background-color: #f7f7f7
}

.border-bottom-bold td {
    border-bottom: 1px solid rgba(34, 48, 61, 0.4) !important
}

.border-bottom-bold+.show-data td[colspan] {
    border-top-width: 1px !important
}

.show-data td[colspan] {
    background: #CFDEE3;
    border-top-width: 0px;
    border-bottom: 1px solid rgba(34, 48, 61, 0.4)
}

.show-data a {
    display: block
}

.show-data a span {
    display: inline-block
}

.show-data a span:last-child {
    text-decoration: underline;
    text-decoration-thickness: 1px
}

.show-data a span:last-child:hover {
    text-decoration: none
}

.show-data a .badge_ss+span {
    color: #947fc4
}

td button.btn {
    width: 100%;
    margin: 0;
    padding: 3px
}

td .fa {
    font-size: 16px
}

td .fa+span {
    margin-left: 3px
}

td .badge+span {
    margin-left: 3px
}

td a .badge+span {
    text-decoration: underline;
    text-decoration-thickness: 1px
}

.badge {
    color: #2A3F54;
    min-width: 35px;
    font-size: 11px
}

.badge_level {
    padding: 0px 5px;
    border-radius: 3px
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle-split::after {
    margin-left: 0
}

.dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 15px
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa
}

.dropdown-item:focus:last-child,
.dropdown-item:hover:last-child {
    border-radius: 0px 0px 4px 4px
}

.dropdown-item i {
    font-size: 14px
}

.dropdown-divider {
    height: 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef
}

.dropdown-divider+div {
    padding: 8px;
    text-align: center
}

.dropdown-divider+div .btn {
    width: 100%
}

.dropdown-menu {
    top: 34px !important
}

.dropdown-menu .text-muted {
    padding: 10px 15px;
    color: #23527c;
    font-weight: bold
}

.dropdown-menu .btn {
    padding: 3px 5px
}

.dropdown-menu .btn i {
    vertical-align: middle
}

.user-profile.dropdown-toggle::after {
    display: none
}

.user-profile .fa {
    margin-left: 8px
}

select.input-sm {
    line-height: initial
}

.bread-box {
    position: relative;
    display: flex;
    align-items: center;
    height: 38px;
    overflow: hidden
}

.bread-box a {
    position: relative;
    display: flex;
    align-items: center;
    flex-basis: 170px;
    height: 100%;
    padding-left: 16px;
    border: 1px solid #d8d8d8;
    border-right: none
}

.bread-box a span {
    margin-left: 8px;
    color: #2A3F54 !important
}

.bread-box a .step-arrow {
    position: absolute;
    top: 0;
    right: -17px
}

.bread-box a .step-arrow:before,
.bread-box a .step-arrow:after {
    content: "";
    position: absolute;
    top: -1px;
    right: 0px;
    border-style: solid;
    border-width: 19px 0 19px 17px;
    border-color: transparent transparent transparent #d8d8d8;
    z-index: 1
}

.bread-box a .step-arrow:after {
    right: 1px;
    border-color: transparent transparent transparent #f1eff7;
    z-index: 2
}

.bread-box a:nth-of-type(1) {
    padding-left: 0px;
    background-color: #f1eff7
}

.bread-box a:nth-of-type(1):hover {
    background-color: #e3ddef
}

.bread-box a:nth-of-type(1):hover .step-arrow:after {
    border-color: transparent transparent transparent #e3ddef
}

.bread-box a:nth-of-type(1) .step-arrow {
    display: initial !important
}

.bread-box a:nth-of-type(2) {
    background-color: #e6f7f6
}

.bread-box a:nth-of-type(2) .step-arrow:after {
    border-color: transparent transparent transparent #e6f7f6
}

.bread-box a:nth-of-type(2):hover {
    background-color: #cbefec
}

.bread-box a:nth-of-type(2):hover .step-arrow:after {
    border-color: transparent transparent transparent #cbefec
}

.bread-box a:nth-of-type(3) {
    background-color: #faf1e0
}

.bread-box a:nth-of-type(3) .step-arrow:after {
    border-color: transparent transparent transparent #faf1e0
}

.bread-box a:nth-of-type(3):hover {
    background-color: #f4e3c1
}

.bread-box a:nth-of-type(3):hover .step-arrow:after {
    border-color: transparent transparent transparent #f4e3c1
}

.bread-box a:nth-of-type(4) {
    background-color: #edf7fb
}

.bread-box a:nth-of-type(4) .step-arrow:after {
    border-color: transparent transparent transparent #edf7fb
}

.bread-box a:nth-of-type(4):hover {
    background-color: #ddeff7
}

.bread-box a:nth-of-type(4):hover .step-arrow:after {
    border-color: transparent transparent transparent #ddeff7
}

.bread-box a:last-of-type {
    border-right: 1px solid #d8d8d8
}

.bread-box a:last-of-type .step-arrow {
    display: none
}

@media (max-width: 768px) {
    .bread-box {
        margin-bottom: 10px
    }
}

/* @font-face {
    font-family: 'icomoon';
    src: url("");
    src: url("") format(""),url("../fonts/icomoon.ttf?1zl9y") format("truetype"),url("../fonts/icomoon.woff?1zl9y") format("woff"),url("../fonts/icomoon.svg?1zl9y#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block
} */

[class^="icon-"],
[class*=" icon-"] {
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-group:before {
    content: "\e900"
}

.nav_menu {
    position: relative;
    float: left;
    background: #EDEDED;
    border-bottom: 1px solid #D9DEE4;
    margin-bottom: 10px;
    width: 100%
}

.nav_menu nav {
    display: flex;
    justify-content: space-between;
    align-items: center
}

@media (min-width: 480px) {
    .nav_menu {
        position: static
    }
}

.region_wrap {
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 0;
    list-style: none
}

.region_wrap button {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 180px;
    line-height: 40px;
    background-color: #fff;
    border-radius: 3px;
    border: none;
    margin-right: 8px;
    margin-bottom: 0 !important;
    padding: 0 24px 0 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3)
}

.region_wrap button .fa {
    position: absolute;
    right: 8px
}

.region_wrap li {
    position: relative
}

.region_wrap li:nth-child(even) button {
    margin-right: 0
}

.region_wrap li:last-child button {
    margin-right: 0
}

.region_wrap .btn_title {
    color: #fff;
    background-color: #5A738E;
    border-radius: 3px 0 0 3px;
    line-height: 40px;
    margin-right: 8px;
    padding: 0 8px
}

.region_wrap .dropdown-menu {
    top: 40px !important;
    min-width: 180px
}

.region_wrap .dropdown-item {
    font-size: 14px
}

.toggle {
    margin: 0;
    width: 70px
}

.toggle a {
    padding: 15px 15px 0;
    margin: 0;
    cursor: pointer
}

.toggle a i {
    font-size: 26px;
    color: #024168;
}

.navbar-brand,
.navbar-nav>li>a {
    font-weight: 500;
    color: #ECF0F1 !important;
    margin-left: 0 !important;
    line-height: 32px
}

.nav.navbar-nav>li>a {
    color: #515356 !important
}

.nav.navbar-nav>li>a span:nth-child(2) {
    display: inline-block
}

.nav.top_menu>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
    color: #34495E !important
}

.nav>li>a:hover,
.nav>li>a:focus {
    background-color: transparent
}

.navbar-header {
    background: #34495E
}

.navbar-right {
    margin-right: 0
}

.top_nav .navbar-right {
    margin: 0
}

.top_nav .navbar-right li {
    display: inline-block;

    position: static
}

@media (max-width: 667px) {
    .region_wrap button {
        flex-wrap: wrap;
        min-width: calc(180px - 90px);
        padding-right: 0
    }

    .region_wrap button .fa {
        position: relative;
        right: 0;
        flex: 0 1 27%
    }

    .region_wrap button span:nth-child(2) {
        display: inline-flex;
        flex: 0 1 calc(100% - 27%);
        line-height: 24px;
        padding-left: 5px
    }

    .region_wrap .btn_title {
        width: 100%;
        font-size: 11px;
        line-height: 16px;
        text-align: left;
        border-radius: 3px 3px 0 0;
        margin-right: 0px;
        padding: 0 0 0 5px
    }

    .region_wrap .dropdown-menu {
        min-width: calc(180px - 90px)
    }

    .nav.navbar-nav>li>a span:nth-child(2) {
        display: none
    }

    .user-profile img {
        margin-right: 0 !important
    }
}

@media (max-width: 375px) {
    .nav-sm .region_wrap {
        min-width: auto;
        padding-left: 5px
    }

    .nav-sm .user-profile {
        padding: 13px 5px
    }

    .nav-sm .user-profile .fa {
        margin-left: 5px
    }

    .nav-sm .navbar-nav {
        flex: 1 0 20%
    }
}

@media (min-width: 480px) {
    .top_nav .navbar-right li {
        position: relative
    }
}

.top_nav .dropdown-menu li {
    width: 100%
}

.top_nav .dropdown-menu li a {
    width: 100%;
    padding: 12px 20px
}

.top_nav li a i {
    font-size: 15px
}

.navbar-static-top {
    position: fixed;
    top: 0;
    width: 100%
}

.top_nav .nav>li>a:focus,
.top_nav .nav>li>a:hover,
.top_nav .nav .open>a,
.top_nav .nav .open>a:focus,
.top_nav .nav .open>a:hover {
    background: #D9DEE4
}

ul.msg_list li {
    background: #f7f7f7;
    padding: 5px;
    display: flex;
    margin: 6px 6px 0;
    width: 96% !important
}

ul.msg_list li:last-child {
    margin-bottom: 6px;
    padding: 10px
}

ul.msg_list li a {
    padding: 3px 5px !important
}

ul.msg_list li a .image img {
    border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    float: left;
    margin-right: 10px;
    width: 11%
}

ul.msg_list li a .time {
    font-size: 11px;
    font-style: italic;
    font-weight: bold;
    position: absolute;
    right: 35px
}

ul.msg_list li a .message {
    display: block !important;
    font-size: 11px
}

.dropdown-menu.msg_list span {
    white-space: normal
}

.dropdown-menu {
    border: medium none;
    box-shadow: none;
    display: none;
    float: left;
    font-size: 12px;
    left: 0;
    list-style: none outside none;
    padding: 0;
    position: absolute;
    text-shadow: none;
    top: 100%;
    z-index: 9998;
    border: 1px solid #D9DEE4;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.dropdown-menu>li>a {
    color: #5A738E
}

.navbar-nav .open .dropdown-menu {
    position: absolute;
    background: #fff;
    margin-top: 0;
    border: 1px solid #D9DEE4;

    right: 0;
    left: auto;
    width: 220px
}

.navbar-nav .open .dropdown-menu.msg_list {
    width: 300px
}

.info-number .badge {
    font-size: 10px;
    font-weight: normal;
    line-height: 13px;
    padding: 2px 6px;
    position: absolute;
    right: 2px;
    top: 8px
}

ul.to_do {
    padding: 0
}

ul.to_do li {
    background: #f3f3f3;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    padding: 7px;
    margin-bottom: 5px;
    list-style: none
}

ul.to_do p {
    margin: 0
}

.dashboard-widget {
    background: #f6f6f6;
    border-top: 5px solid #79C3DF;
    border-radius: 3px;
    padding: 5px 10px 10px
}

.dashboard-widget .dashboard-widget-title {
    font-weight: normal;
    border-bottom: 1px solid #c1cdcd;
    margin: 0 0 10px 0;
    padding-bottom: 5px;
    padding-left: 40px;
    line-height: 30px
}

.dashboard-widget .dashboard-widget-title i {
    font-size: 100%;
    margin-left: -35px;
    margin-right: 10px;
    color: #33a1c9;
    padding: 3px 6px;
    border: 1px solid #abd9ea;
    border-radius: 5px;
    background: #fff
}

ul.quick-list {
    width: 45%;
    padding-left: 0;
    display: inline-block
}

ul.quick-list li {
    padding-left: 10px;
    list-style: none;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

ul.quick-list li i {
    padding-right: 10px;
    color: #757679
}

.dashboard-widget-content {
    padding-top: 9px
}

.dashboard-widget-content .sidebar-widget {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    background: #fff;
    border: 1px solid #abd9ea;
    border-radius: 5px;
    text-align: center;

    padding: 2px;
    margin-top: 10px
}

.widget_summary {
    width: 100%;
    display: inline-flex
}

.widget_summary .w_left {
    float: left;
    text-align: left
}

.widget_summary .w_center {
    float: left
}

.widget_summary .w_right {
    float: left;
    text-align: right
}

.widget_summary .w_right span {
    font-size: 20px
}

.w_20 {
    width: 20%
}

.w_25 {
    width: 25%
}

.w_55 {
    width: 55%
}

h5.graph_title {
    text-align: left;
    margin-left: 10px
}

h5.graph_title i {
    margin-right: 10px;
    font-size: 17px
}

span.right {
    float: right;
    font-size: 14px !important
}

.tile_info a {
    text-overflow: ellipsis
}

.dashboard_graph {
    background: #fff;
    padding: 7px 10px
}

.dashboard_graph .col-md-9,
.dashboard_graph .col-md-3 {
    padding: 0
}

a.user-profile {
    color: #5E6974 !important
}

.user-profile img {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    margin-right: 10px
}

ul.top_profiles {
    height: 330px;
    width: 100%
}

ul.top_profiles li {
    margin: 0;
    padding: 3px 5px
}

ul.top_profiles li:nth-child(odd) {
    background-color: #eee
}

.media .profile_thumb {
    border: 1px solid;
    width: 50px;
    height: 50px;
    margin: 5px 10px 5px 0;
    border-radius: 50%;
    padding: 9px 12px
}

.media .profile_thumb i {
    font-size: 30px
}

.media .date {
    background: #ccc;
    width: 52px;
    margin-right: 10px;
    border-radius: 10px;
    padding: 5px
}

.media .date .month {
    margin: 0;
    text-align: center;
    color: #fff
}

.media .date .day {
    text-align: center;
    color: #fff;
    font-size: 27px;
    margin: 0;
    line-height: 27px;
    font-weight: bold
}

.event .media-body a.title {
    font-weight: bold
}

.event .media-body p {
    margin-bottom: 0
}

h4.graph_title {
    margin: 7px;
    text-align: center
}

.left_col {
    background: linear-gradient(180deg, #de352c 8%, #de352c 100%);
    height: 707px;
}

.nav-md.container body.main_container.casinoDownline {

    margin-left: 238px;

}

.navbar {
    margin-bottom: 0
}

.nav_title {
    width: 230px;
    float: left;
    background: #000;
    border-radius: 0;
    height: 57px
}

.site_title {
    overflow: hidden;
    font-weight: 400;
    font-size: 22px;
    width: 100%;
    color: #ECF0F1 !important;
    margin-left: 0 !important;
    display: block;
    height: 100%;
    margin: 0;
    padding-left: 10px
}

.site_title i {
    border: 1px solid #EAEAEA;
    padding: 5px 6px;
    /* border-radius: 50%; */
    background-image: url(../images/bdtwin_logo.png)
}

.site_title a {
    display: flex;
    align-items: center
}

.site_title:hover,
.site_title:focus {
    text-decoration: none
}

.nav-sm .container.body .col-md-3.left_col {
    min-height: 100%;
    width: 70px;
    padding: 0;
    z-index: 9999;
    position: absolute
}

.nav-sm .container.body .col-md-3.left_col .mCSB_container,
.nav-sm .container.body .col-md-3.left_col .mCustomScrollBox {
    overflow: visible
}

.nav-sm .hidden-small {
    visibility: hidden
}

.nav-sm .container.body .right_col {
    padding: 10px 20px;
    margin-left: 70px;
    z-index: 2
}

.nav-sm .navbar.nav_title {
    width: 75px
}

.nav-sm .navbar.nav_title a span {
    display: none
}

.nav-sm .navbar.nav_title a i {
    font-size: 27px;
    margin: 13px 0 0 3px
}

.menu_section {
    margin-bottom: 35px
}

.menu_section h3 {
    padding-left: 23px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 0;
    margin-top: 0;
    text-shadow: 1px 1px #000
}

.menu_section>ul {
    margin-top: 10px
}

.profile_pic {
    width: 35%;
    float: left
}

.img-circle.profile_img {
    width: 70%;
    background: #fff;
    margin-left: 15%;
    z-index: 1000;
    position: inherit;
    margin-top: 20px;
    border: 1px solid rgba(52, 73, 94, 0.44);
    padding: 4px
}

.profile_info {
    padding: 25px 10px 10px;
    width: 65%;
    float: left
}

.profile_info span {
    font-size: 13px;
    line-height: 30px;
    color: #BAB8B8
}

.profile_info h2 {
    font-size: 14px;
    color: #ECF0F1;
    margin: 0;
    font-weight: 300
}

.profile.img_2 {
    text-align: center
}

.profile.img_2 .profile_pic {
    width: 100%
}

.profile.img_2 .profile_pic .img-circle.profile_img {
    width: 50%;
    margin: 10px 0 0
}

.profile.img_2 .profile_info {
    padding: 15px 10px 0;
    width: 100%;
    margin-bottom: 10px;
    float: left
}

.main_menu span.fa {
    float: right;
    text-align: center;
    margin-top: 5px;
    font-size: 10px;
    min-width: inherit;
    color: #C4CFDA
}

.active a span.fa {
    text-align: right !important;
    margin-right: 4px
}

.nav-sm .menu_section {
    margin: 0
}

.nav-sm .menu_section span.fa,
.nav-sm .menu_section h3 {
    display: none
}

.nav-sm li li span.fa {
    display: inline-block
}

.nav-sm .main_container .top_nav {
    display: block;
    margin-left: 70px;
    z-index: 2
}

.nav-sm .nav.side-menu li a {
    text-align: center !important;
    font-weight: 400;
    font-size: 10px;
    padding: 10px 5px
}

.nav-sm .nav.child_menu li.active,
.nav-sm .nav.side-menu li.active-sm {
    border-right: 5px solid #1ABB9C
}

.nav-sm ul.nav.child_menu ul,
.nav-sm .nav.side-menu li.active-sm ul ul {
    position: static;
    width: 200px;
    background: none
}

.nav-sm>.nav.side-menu>li.active-sm>a {
    color: #1ABB9C !important
}

.nav-sm .nav.side-menu li a i.toggle-up {
    display: none !important
}

.nav-sm .nav.side-menu li a i {
    font-size: 25px !important;
    text-align: center;
    width: 100% !important;
    margin-bottom: 5px
}

.nav-sm ul.nav.child_menu {
    left: 100%;
    position: absolute;
    top: 0;
    width: 210px;
    z-index: 4000;
    background: #3E5367;
    display: none
}

.nav-sm ul.nav.child_menu li {
    padding: 0 10px
}

.nav-sm ul.nav.child_menu li a {
    text-align: left !important
}

.nav-sm .profile {
    display: none
}

.nav-md .container.body .col-md-3.left_col {
    min-height: 100%;
    width: 230px;
    padding: 0;
    position: absolute;
    display: flex;
    z-index: 1
}

.nav-md .container.body .col-md-3.left_col.menu_fixed {
    height: 100%;
    position: fixed
}

.sidebar-header {
    border-bottom: 0;
    margin-top: 46px
}

.sidebar-header:first-of-type {
    margin-top: 0
}

.nav.side-menu>li {
    position: relative;
    display: block;
    cursor: pointer
}

.nav.side-menu>li>a {
    margin-bottom: 6px
}

.nav.side-menu>li>a:hover {
    color: #F2F5F7 !important
}

.nav.side-menu>li>a:hover,
.nav>li>a:focus {
    text-decoration: none;
    background: #e25953
}

.nav.child_menu {
    display: none
}

.nav.child_menu li:hover,
.nav.child_menu li.active {
    background-color: rgba(255, 255, 255, 0.06);
    background-color: rgba(255, 255, 255, 0.2)
}

.nav.child_menu li {
    padding-left: 36px
}

.nav-md ul.nav.child_menu li:before {
    background: #425668;
    bottom: auto;
    content: "";
    height: 8px;
    left: 23px;
    margin-top: 15px;
    position: absolute;
    right: auto;
    width: 8px;
    z-index: 1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
}

.nav-md ul.nav.child_menu li:after {
    border-left: 1px solid #425668;
    bottom: 0;
    content: "";
    left: 27px;
    position: absolute;
    top: 0
}

.nav-md ul.nav.child_menu li:last-child::after {
    bottom: 50%
}

.nav.side-menu>li>a,
.nav.child_menu>li>a {
    color: white;
    font-weight: 500
}

.nav.child_menu li li:hover,
.nav.child_menu li li.active {
    background: none
}

.nav.child_menu li li a:hover,
.nav.child_menu li li a.active {
    color: #fff
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 13px 15px 12px
}

.nav.side-menu>li.current-page,
.nav.side-menu>li.active {
    border-right: 5px solid #1ABB9C
}

.nav li.current-page {
    background: rgba(255, 255, 255, 0.05)
}

.nav li li li.current-page {
    background: none
}

.nav.side-menu>li.active>a {
    text-shadow: rgba(0, 0, 0, 0.25) 0 -1px 0;
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #5b6479), color-stop(100%, #4c5566)), #686e78;
    background: -webkit-linear-gradient(#334556, #2C4257), #2A3F54;
    background: -moz-linear-gradient(#334556, #2C4257), #2A3F54;
    background: -o-linear-gradient(#334556, #2C4257), #2A3F54;
    background: linear-gradient(#334556, #2C4257), #2A3F54;
    -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
    -moz-box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0
}

.nav.child_menu>li>a {
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;
    padding: 9px
}

.nav li li.current-page a {
    color: #fff
}

.sidebar-footer {
    bottom: 0px;
    clear: both;
    display: block;
    padding: 5px 0 0 0;
    position: fixed;
    width: 230px;
    background: #2A3F54
}

.sidebar-footer a {
    padding: 7px 0 3px;
    text-align: center;
    width: 25%;
    font-size: 17px;
    display: block;
    float: left;
    background: #172D44
}

.sidebar-footer a:hover {
    background: #425567
}

.fontawesome-icon-list .fa-hover a:hover {
    background-color: #ddd;
    color: #fff;
    text-decoration: none
}

.fontawesome-icon-list .fa-hover a {
    display: block;
    line-height: 32px;
    height: 32px;
    padding-left: 10px;
    border-radius: 4px
}

.fontawesome-icon-list .fa-hover a:hover .fa {
    font-size: 28px;
    vertical-align: -6px
}

.fontawesome-icon-list .fa-hover a .fa {
    width: 32px;
    font-size: 16px;
    display: inline-block;
    text-align: right;
    margin-right: 10px
}

.main_menu .fa {
    width: 26px;
    opacity: .99;
    color: white;
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.site_title {
    position: relative;
    text-indent: 52px;
    padding-left: none;
    display: inline-flex;
    align-items: center
}

.site_title i {
    position: absolute;
    top: 0px;
    left: 4px;
    width: 73px;
    height: 58px;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
}

.site_title span {
    font-size: 18px
}

.nav-sm .navbar.nav_title a i {
    margin: 0
}

.sidebar-footer {
    display: none
}

body .container.body .right_col {
    background: #F7F7F7
}

.nav-md .container.body .right_col {
    padding: 10px 20px 0;
    margin-left: 230px
}

.page-title .title_left {
    width: inherit !important
}

.dataTables_wrapper>.row {
    overflow: auto !important
}

.btn-group-sm {
    margin: 0 0 10px
}

.table {
    width: max-content !important;
    max-width: inherit !important;
    min-width: 100%
}

table.rule thead tr th {
    color: #34495E
}

table.rule .bgsub {
    color: rgba(52, 73, 94, 0.94);
    background: rgba(52, 73, 94, 0.1);
    font-weight: normal
}

table.rule .thTotal {
    color: rgba(52, 73, 94, 0.94)
}

.container {
    width: 100%;
    padding: 0
}

.navbar-nav>li>a,
.navbar-brand,
.navbar-nav>li>a {
    color: #fff !important
}

.main_container .top_nav {
    display: block;
    margin-left: 230px
}

.main_container {
    position: relative
}

.no-padding {
    padding: 0 !important
}

.page-title {
    width: 100%;
    height: 65px;
    padding: 10px 0
}

.page-title .title_left {
    width: 45%;
    float: left;
    display: block
}

.page-title .title_left h3 {
    margin: 9px 0
}

.page-title .title_right {
    width: 55%;
    float: left;
    display: block
}

.page-title .title_right .pull-right {
    margin: 10px 0
}

.fixed_height_320 {
    height: 320px
}

.fixed_height_390 {
    height: 390px
}

.fixed_height_200 {
    height: 200px
}

.overflow_hidden {
    overflow: hidden
}

.progress-bar-dark {
    background-color: #34495E !important
}

.progress-bar-gray {
    background-color: #BDC3C7 !important
}

table.no-margin .progress {
    margin-bottom: 0
}

.main_content {
    padding: 10px 20px
}

.col-md-55 {
    width: 50%;
    margin-bottom: 10px
}

.x_panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;

    opacity: 1;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

.x_title {
    border-bottom: 2px solid #E6E9ED;
    padding: 1px 5px 6px;
    margin-bottom: 10px
}

.x_title .filter {
    width: 40%;
    float: right
}

.x_title h2 {
    margin: 5px 0 6px;
    float: left;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap
}

.x_title h2 small {
    margin-left: 10px
}

.x_title span {
    color: #BDBDBD
}

.x_content {
    padding: 0 5px 6px;
    position: relative;
    width: 100%;
    float: left;
    height: 574px;
    clear: both;
    margin-top: 5px
}

.x_content h4 {
    font-size: 16px;
    font-weight: 500
}

.active_set {
    background-color: #fff9e9
}

/* .container1 {
    margin: 50px auto;
    width: 280px;
    text-align: center;
      background-color: #f2f2f2;
  } */
/* .container1 > .switch {
    display: block;
    margin: 22px auto;
  } */

.switch {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 96px;
    height: 36px;
    padding: 1px;
    border-radius: 3px;
    cursor: pointer;
}

.switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.switch-label {
    position: relative;
    display: block;
    height: inherit;
    height: 22px;
    width: 65px;
    left: 20px;
    font-size: 10px;
    text-transform: uppercase;
    background: #fff;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
    -webkit-transition: 0.15s ease-out;
    -moz-transition: 0.15s ease-out;
    -o-transition: 0.15s ease-out;
    transition: 0.15s ease-out;
    -webkit-transition-property: opacity background;
    -moz-transition-property: opacity background;
    -o-transition-property: opacity background;
    transition-property: opacity background;
}

.switch-label:before,
.switch-label:after {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
}

.switch-label:before {
    content: attr(data-off);
    right: 9px;
    color: #aaa;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.switch-label:after {
    content: attr(data-on);
    left: 11px;
    color: #000;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
}

.switch-input:checked~.switch-label {
    background: #de352c;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
    height: 22px;
    width: 65px;
    left: 20px;
}

.container1 {
    position: relative;
    left: 12px;
    height: 20px;


}

.switch-input:checked~.switch-label:before {
    opacity: 0;
}

.switch-input:checked~.switch-label:after {
    opacity: 1;
}

.switch-handle {
    position: absolute;
    top: 1px;
    left: 20px;
    width: 25px;
    height: 21px;
    background: white;
    border-radius: 3px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
    background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
    background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
    background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: left 0.15s ease-out;
    -o-transition: left 0.15s ease-out;
    transition: left 0.15s ease-out;
}

.switch-handle1 {
    position: absolute;
    top: 1px;
    left: 13px;
    width: 25px;
    height: 21px;
    background: white;
    border-radius: 3px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
    background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
    background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
    background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: left 0.15s ease-out;
    -o-transition: left 0.15s ease-out;
    transition: left 0.15s ease-out;
}

/* .switch-handle:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: #f9f9f9;
    border-radius: 6px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
    background-image: -webkit-linear-gradient(top, #eeeeee, white);
    background-image: -moz-linear-gradient(top, #eeeeee, white);
    background-image: -o-linear-gradient(top, #eeeeee, white);
    background-image: linear-gradient(to bottom, #eeeeee, rgb(141, 123, 123));
  } */
.switch-input:checked~.switch-handle {
    left: 70px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-input:checked~.switch-handle1 {
    left: 70px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-input:checked~.switch-handle {
    left: 65px;
    box-shadow: -1px 1px 5px rgb(0 0 0 / 20%);
    height: 22px;
    width: 21px;
}

.switch-input:checked~.switch-handle1 {
    left: 51px;
    box-shadow: -1px 1px 5px rgb(0 0 0 / 20%);
    height: 22px;
    width: 21px;
}

.switch-green>.switch-input:checked~.switch-label {
    background: #4fb845;
}

.active_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid #ddd;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.active_content li {
    display: flex;
    width: calc(100% / 3);
    height: 36px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    line-height: 18px;
    padding: 8px
}

.active_content li:nth-child(3n) {
    border-right: 0px
}

.active_content span:first-child {
    flex: 0 1 65%
}

.active_content span:last-child {
    flex: 1 0 35%;
    text-align: right
}

.no_bottom {
    border-bottom: 0px !important
}

.x_unique #mainb {
    max-height: 736px;
    overflow-y: scroll
}

.x_unique table.jambo_table thead {
    position: relative
}

.x_active .active_content {
    max-height: 181px;
    overflow-y: scroll
}

.order-1 {
    float: left !important
}

.order-12 {
    float: right !important
}

.img_max {
    display: flex;
    margin: 15px 0
}

.img_max img {
    width: 50%;
    height: 50%
}

.img_max img:first-child {
    padding-right: 15px
}

legend {
    padding-bottom: 7px
}

.demo-placeholder {
    height: 280px
}

.panel_toolbox {
    float: right;
    min-width: 70px
}

.panel_toolbox>li {
    float: left;
    cursor: pointer;
    list-style: none
}

.panel_toolbox>li>a {
    padding: 5px;
    color: #C5C7CB;
    font-size: 14px
}

.panel_toolbox>li>a:hover {
    background: #F5F7FA
}

.line_30 {
    line-height: 30px
}

.main_menu_side {
    padding: 0
}

.bs-docs-sidebar .nav>li>a {
    display: block;
    padding: 4px 6px
}

@media (max-width: 1370px) {
    .active_content {
        border-bottom-width: 1px
    }

    .active_content li {
        width: calc(100% / 2)
    }

    .active_content li:nth-child(3n) {
        border-right: 1px solid #ddd
    }

    .active_content li:nth-child(2n) {
        border-right: 0px
    }

    .active_content li:last-child {
        border-bottom: 0px !important
    }

    .no_bottom {
        border-bottom: 1px solid #ddd !important
    }
}

@media (max-width: 1200px) {
    .order-change {
        display: flex
    }

    .x_unique #mainb {
        max-height: 176px
    }
}

@media (max-width: 500px) {
    .active_content li {
        width: 100%;
        border-right: 0px
    }

    .active_content li:nth-child(3n) {
        border-right: 0px
    }

    .img_max {
        display: block;
        margin: 10px 0
    }

    .img_max img {
        width: 100%;
        height: 100%
    }

    .img_max img:first-child {
        padding-right: 0px;
        padding-bottom: 10px
    }
}

footer {
    background: #fff;
    padding: 15px 20px;
    margin-top: 10px;
    display: block
}

@media (max-width: 991px) {
    footer {
        margin-top: 0
    }
}

@media (min-width: 992px) {
    footer {
        margin-left: 230px
    }
}

.nav-sm footer {
    margin-left: 70px
}

.footer_fixed footer {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%
}

@media (min-width: 768px) {
    .footer_fixed footer {
        margin-left: 0
    }
}

@media (min-width: 768px) {
    .footer_fixed .nav-sm footer {
        margin-left: 0
    }
}

.btn {
    border-radius: 3px
}

a.btn-success,
a.btn-primary,
a.btn-warning,
a.btn-danger {
    color: #fff
}

.btn-success {
    background: #26B99A;
    border: 1px solid #169F85
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
    background: #169F85
}

.btn-dark {
    color: #E9EDEF;
    background-color: #4B5F71;
    border-color: #364B5F
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
    color: #FFFFFF;
    background-color: #394D5F;
    border-color: #394D5F
}

.btn-round {
    border-radius: 30px
}

.btn.btn-app {
    position: relative;
    padding: 15px 5px;
    margin: 0 0 10px 10px;
    min-width: 80px;
    height: 60px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    text-align: center;
    color: #666;
    border: 1px solid #ddd;
    background-color: #fafafa;
    font-size: 12px
}

.btn.btn-app>.fa,
.btn.btn-app>.glyphicon,
.btn.btn-app>.ion {
    font-size: 20px;
    display: block
}

.btn.btn-app:hover {
    background: #f4f4f4;
    color: #444;
    border-color: #aaa
}

.btn.btn-app:active,
.btn.btn-app:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

.btn.btn-app>.badge {
    position: absolute;
    top: -3px;
    right: -10px;
    font-size: 10px;
    font-weight: 400
}

.row {
    vertical-align: top;
    flex-wrap: wrap-reverse
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    vertical-align: inherit;
    color: #5A738E;
    line-height: 18px
}

.table>thead>tr>th {
    border-bottom: 1px solid #ddd
}

.setTb>tbody>tr.level2 {
    background: #DBECF1
}

.setTb>tbody>tr.level2 td {
    border-top: 1px solid #d4d4d4
}

.setTb>tbody>tr.level3 {
    background: #CFDEE3
}

.setTb>tbody>tr.level3 td {
    border-top: 1px solid #cecece
}

.setTb>tbody>tr.totalTb {
    font-weight: bold
}

.setTb>tbody>tr.totalTb th,
.setTb>tbody>tr.totalTb td {
    color: #2A3F54
}

.setTb>tbody>tr.totalTb .nameTd {
    text-align: left
}

.setTb>tbody>tr.totalTb td {
    text-align: right
}

.x_panel {
    padding: 0
}

.x_title {
    padding: 5px
}

.x_title h2 {
    color: #2A3F54;
    text-indent: 5px
}

.x_title h2 a {
    color: #2A3F54
}

.x_title i {
    margin: 0 5px
}

.x_title.ap_head {
    background-color: #2A3F54;
    border-bottom-width: 0
}

.x_title.ap_head h2 {
    color: #fff
}

.x_title.ap_head i {
    color: #fff
}

.x_title.ap_head .panel_toolbox>li>a:hover {
    background: #3b5976
}

.panel_toolbox {
    min-width: 0
}

.x_content span {
    line-height: 20px
}

.ccu_max {
    display: flex;
    align-items: center;
    margin-bottom: 10px
}

.ccu_max .x_panel {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0 10px 0 0;
    padding: 10px 15px
}

.ccu_max .x_panel:before {
    content: "\e900";
    margin-right: 10px;
    font-size: 50px;
    color: #83BED3;
    font-family: 'icomoon' !important
}

.ccu_max .x_panel:last-child {
    margin-right: 0
}

.ccu_max dl {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0
}

.ccu_max dt {
    font-size: 17px;
    font-weight: normal
}

.ccu_max dd {
    flex: 1 0;
    text-align: right;
    font-size: 36px;
    font-weight: bold;
    line-height: 36px
}

@media (min-width: 1200px) and (max-width: 1366px),
(max-width: 880px) {
    .ccu_max .x_panel::before {
        order: 3;
        margin-right: 0
    }

    .ccu_max dl {
        display: initial
    }

    .ccu_max dt {
        color: #5A738E
    }

    .ccu_max dd {
        text-align: left
    }
}

@media (max-width: 540px) {
    .ccu_max {
        display: block
    }

    .ccu_max .x_panel {
        margin-bottom: 10px
    }

    .ccu_max .x_panel:last-child {
        margin-bottom: 0
    }

    .ccu_max dt {
        color: #5A738E
    }

    .ccu_max dd {
        font-size: 30px;
        line-height: 30px
    }
}

.setTb {
    margin-bottom: 0
}

.setTb thead tr th {
    color: #fff
}

.setTb thead tr th.textL {
    text-align: left
}

.setTb thead tr th.textR {
    text-align: right
}

.setTb tbody td,
.setTb tbody th {
    color: #5A738E
}

.setTb tbody td {
    text-align: right
}

.setTb tbody th {
    text-align: left
}

.setTb tbody .nameTd {
    text-align: left
}

.setTb tbody .nameTd a {
    margin-right: 5px
}

.setTb tbody .nameTd .fa {
    font-size: 16px
}

.setTb tbody .currencySp {
    display: inline-block;
    width: 40px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 3px;
    margin-right: 5px;
    color: #2A3F54
}

.setTb.ap_tb .nameTd {
    width: 190px
}

.setTb.ap_tb td:not(.nameTd) {
    border-right: 1px solid #ddd
}

.x-collapse #mainb {
    height: 112px
}

.x-collapse-thead #mainb {
    height: 147px
}

.x-collapse-total #mainb {
    height: 36px
}

.x-open #mainb {
    max-height: 314px
}

.setDefault {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    width: 100%
}

.setDefault .data_box,
.setDefault .currency_box,
.setDefault .gametype_box,
.setDefault .product_box {
    margin-bottom: 5px
}

.setDefault [class$="_box"] .form-control {
    border-color: #ACC1CE;
    border-radius: 4px;
    box-shadow: none;
    /* background: url(../images/input_arrow.png) no-repeat right center transparent */
}


.setDefault .currency_box {
    flex: 1
}

.setDefault .checkbox {
    flex: 2;
    padding: 5px;
    margin: 0
}

.setDefault .checkbox label {
    padding-left: 0px
}

.setDefault .currency_box,
.setDefault .id,
.setDefault .checkbox {
    padding-right: 8px
}

.setDefault .id_box .btn-default i.fa-star {
    color: #bdc6ce;
    margin-left: 5px
}

.setDefault .id_box .btn-default.select i.fa-star {
    margin-left: 5px
}

.setDefault .id_box .btn-default.default-set i.fa-star {
    color: #F5A623;
    margin-left: 5px
}

.setDefault .id_box .btn-default.select,
.setDefault .id_box .btn-default:active,
.setDefault .id_box .btn-default.select:focus,
.setDefault .id_box .btn-default.select-default {
    color: #fff;
    background-color: #232323
}

.setDefault .id_box .btn-default.select:hover,
.setDefault .id_box .btn-default:active:hover,
.setDefault .id_box .btn-default.select:focus:hover,
.setDefault .id_box .btn-default.select-default:hover {
    color: #fff;
    background-color: #232323
}

#mainb {
    margin-top: 5px
}

.btn-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background-color: #34495E;
    font-size: 24px;
    padding: 0;
    border: 1px solid #2b3c4e;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 99
}

.btn-to-top .fa {
    display: flex !important;
    justify-content: center;
    align-items: center
}

.btn-to-top .fa.fa-angle-up::before {
    margin-bottom: 3px
}

.btn-to-top.show {
    opacity: 1;
    visibility: visible
}

table.jambo_table thead {
    position: sticky;
    top: 0
}

.nav-sm ul.nav.child_menu {
    width: 140px;
    border-radius: 0px 4px 4px 0px
}

.nav-sm .nav.side-menu li a {
    font-size: 12px
}

.nav-sm ul.nav.child_menu li {
    border-bottom: 1px solid #404040
}

.nav-sm ul.nav.child_menu li:last-child {
    border-bottom: none
}

.nav-sm ul.nav.child_menu li a {
    font-size: 13px
}

.map_size {
    width: 100%;
    min-height: 500px
}

.map_size.for_world {
    min-height: 600px
}

@media (max-width: 575.98px) {
    .map_size {
        min-height: 350px
    }

    .map_size.for_world {
        min-height: 350px
    }
}

.function_wrap .form-control {
    padding-left: 3px
}

.function_wrap .right {
    right: 2px !important
}

.function_wrap .date_box,
.function_wrap .currency_box,
.function_wrap .gametype_box,
.function_wrap .product_box {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 300px;
    min-width: 100px;
    border-radius: 4px !important;
    margin-right: 8px;
    margin-bottom: 7px;
    padding-right: 0 !important
}

.function_wrap .short_items_box {
    display: flex;
    flex: 1
}

.function_wrap .short_items_box .open.currency_box .arrow-down,
.function_wrap .short_items_box .open.gametype_box .arrow-down {
    height: 100%;
    border-bottom-right-radius: 4px
}

.function_wrap .short_items_box .dropdown-menu {
    width: 100%;
    min-width: inherit;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3)
}

.function_wrap .short_items_box .dropdown-menu.open {
    display: block
}

.function_wrap .short_items_box .dropdown-item {
    line-height: 20px
}

.function_wrap .short_items_box .dropdown-item:hover {
    background-color: #E0EDF1
}

.function_wrap .date_box {
    flex: 1 0 300px
}

.function_wrap .date_box input {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 4px;
    padding-left: 5px
}

.function_wrap .date_box .form-control-feedback.right {
    right: 5px !important;
    top: -3px
}

.function_wrap .product_content {
    z-index: 2
}

.function_wrap .product_item {
    display: flex;
    flex-wrap: wrap
}

.function_wrap .product_item P {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 10px
}

.function_wrap .product_item input {
    margin-top: 0px
}

.function_wrap .product_item input,
.function_wrap .product_item label {
    cursor: pointer
}

.function_wrap .product_item a {
    display: block;
    width: 100%
}

.function_wrap .few_items {
    flex: 0 1 300px !important
}

.function_wrap .btn_box {
    flex: 1;
    min-width: 200px
}

@media (max-width: 1330px) {
    .function_wrap {
        flex-wrap: wrap
    }

    .function_wrap .few_items [class$="_box"] {
        flex: 0 1 300px
    }
}

@media (max-width: 768px) {
    .function_wrap .date_box {
        flex: 1 0 100%;
        max-width: 100%
    }

    .function_wrap .short_items_box {
        flex: 1 0 100%
    }

    .function_wrap .short_items_box [class$="_box"] {
        flex: 0 1 calc(100% / 3)
    }

    .function_wrap .date_box,
    .function_wrap .currency_box,
    .function_wrap .gametype_box,
    .function_wrap .product_box {
        margin-right: 0px
    }

    .function_wrap .currency_box,
    .function_wrap .gametype_box {
        margin-right: 5px
    }

    .function_wrap .product_content {
        top: calc(68px + 8px)
    }

    .function_wrap .few_items {
        flex-basis: 100% !important
    }

    .function_wrap .few_items [class$="_box"],
    .function_wrap .few_items .open.currency_box,
    .function_wrap .few_items .open.gametype_box {
        flex: 1;
        max-width: 100% !important
    }

    .function_wrap .few_items [class$="_box"]:last-child,
    .function_wrap .few_items .open.currency_box:last-child,
    .function_wrap .few_items .open.gametype_box:last-child {
        margin-right: 0
    }
}

@media (max-width: 375px) {
    .nav-sm .function_wrap .short_items_box [class$="_box"] {
        min-width: 80px
    }
}

.bottom {
    display: grid;
    grid-template-columns: auto auto 140px 180px
}

.bottom .dataTables_paginate {
    grid-column: span 2;
    order: 1
}

.bottom .dataTables_info {
    grid-column: span 4;
    order: 4;
    padding-right: 10px;
    line-height: 34px;
    text-align: right
}

.bottom .dataTables_length {
    order: 3
}

.bottom .dataTables_filter {
    order: 2
}

.bottom .pagination {
    display: flex;
    align-items: center
}

.bottom .pagination>.active>a {
    background-color: #7f7f7f !important;
    border-color: #7f7f7f !important
}

.bottom .dataTables_length,
.bottom .dataTables_info,
.bottom .dataTables_filter {
    float: none !important;
    width: auto !important
}

.bottom .input-sm {
    display: inline-block;
    width: 80px !important;
    /* background: url(../images/input_arrow.png) no-repeat right center transparent; */
    margin: 0 5px;
    padding: inherit;
    box-shadow: none;
    font-size: inherit
}

.bottom .input-sm:not(select) {
    background: none;
    text-indent: 5px
}

.bottom .input-sm:not(select):hover {
    background: none
}


@media (max-width: 670px) {
    .bottom {
        grid-template-columns: 140px auto;
        overflow-x: scroll
    }

    .bottom .dataTables_paginate {
        grid-column: span 2;
        margin-bottom: 8px !important
    }

    .bottom .dataTables_info {
        grid-column: span 2;
        text-align: left !important
    }

    .bottom .dataTables_length {
        grid-column: span 1;
        text-align: left !important
    }

    .bottom .dataTables_filter {
        grid-column: span 1;
        text-align: left !important
    }
}

.pagination {
    margin: 0
}

@media (max-width: 768px) {
    .table-responsive {
        margin-bottom: 7px
    }
}

.middle {
    margin-bottom: 5px !important
}

.nav-md .daterangepicker.dropdown-menu {
    top: 110px !important;
    left: 256px !important
}

@media (max-width: 991px) {
    .nav-md .daterangepicker.dropdown-menu {
        left: 16px !important
    }
}

.nav-sm .daterangepicker.dropdown-menu {
    top: 110px !important;
    left: 86px !important
}

@media (max-width: 563px) {
    .daterangepicker.show-calendar .calendar {
        width: 100%
    }
}

@media (max-width: 563px) {
    .calendar.right .daterangepicker_input i {
        top: 292px !important
    }
}

.text-left {
    text-align: left !important
}

.col_clear {
    margin-bottom: 15px
}

@media (max-width: 991px) {
    .setDefault .id {
        flex: 2;
        border-left: none
    }

    .setDefault .id.open .arrow-down {
        background-color: #E0EDF1;
        border-left: 1px solid #ACC1CE;
        height: calc(100% + 1px);
        border-radius: 0
    }

    .setDefault .checkbox {
        flex: 1;
        height: 34px;
        border-top: 1px solid #ACC1CE;
        border-right: 1px solid #ACC1CE;
        border-bottom: 1px solid #ACC1CE
    }

    .setDefault .checkbox label {
        vertical-align: middle
    }

    .setDefault [class$="_box"] .form-control {
        border-radius: 0
    }

    .setDefault .currency_box,
    .setDefault .id,
    .setDefault .checkbox {
        padding-right: 0;
        border-radius: 0px
    }

    .setDefault .id_box {
        top: 34px;
        border-top: none
    }

    .setDefault .confirm_box {
        display: inherit;
        justify-content: inherit;
        border-top: none
    }

    .setDefault .confirm_box .btn-group-sm>.btn {
        width: 100%
    }
}

@media (max-width: 1200px) {
    .col_clear:nth-child(odd) {
        clear: left
    }
}

@media (min-width: 1201px) {
    .col_clear:nth-child(3n+1) {
        clear: left
    }
}

/* @media (max-width: 991px) {
    .nav-md .container.body .right_col,.nav-md .container.body .top_nav {
        width:100%;
        margin: 0
    }

    .nav-md .container.body .col-md-3.left_col {
        display: none
    }

    .nav-md .container.body .right_col {
        width: 100%;
        padding-right: 0
    }

    .right_col {
        padding: 10px !important
    }
} */


.x_title h2 {
    width: 62%;
    font-size: 17px
}

.tile,
.graph {
    zoom: 85%;
    height: inherit
}


@media (max-width: 1270px) and (min-width: 192px) {
    .x_title h2 small {
        display: none
    }
}

.left_col .mCSB_scrollTools {
    width: 6px
}

.left_col .mCSB_dragger {
    max-height: 400px !important
}

@media (min-width: 768px) {
    .col-md-55 {
        width: 20%
    }
}

@media (min-width: 992px) {
    .col-md-55 {
        width: 20%
    }
}

@media (min-width: 1200px) {
    .col-md-55 {
        width: 20%
    }
}

@media (min-width: 192px) and (max-width: 1270px) {
    table.tile_info span.right {
        margin-right: 7px;
        float: left
    }
}

.center-margin {
    margin: 0 auto;
    float: none !important
}

.col-md-55,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px
}

.row {
    margin-right: -10px;
    margin-left: -10px
}

.grid_slider .col-md-6 {
    padding: 0 40px
}

/* h1,.h1,h2,.h2,h3,.h3 {
    margin-top: 10px;
    margin-bottom: 10px
} */

/* a {
    color: #5A738E;
    text-decoration: none
} */

a,
a:visited,
a:focus,
a:active,
:visited,
:focus,
:active,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline: 0
}

a:hover,
a:focus {
    text-decoration: none
}

.top_search {
    padding: 0
}

.top_search .form-control {
    border-right: 0;
    box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.075);
    border-radius: 25px 0px 0px 25px;
    padding-left: 20px;
    border: 1px solid rgba(221, 226, 232, 0.49)
}

.top_search .form-control:focus {
    border: 1px solid rgba(221, 226, 232, 0.49);
    border-right: 0
}

.top_search .input-group-btn button {
    border-radius: 0px 25px 25px 0px;
    border: 1px solid rgba(221, 226, 232, 0.49);
    border-left: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #93A2B2;
    margin-bottom: 0 !important
}

.tile-stats.sparkline {
    padding: 10px;
    text-align: center
}

.jqstooltip {
    background: #34495E !important;
    width: 30px !important;
    height: 22px !important;
    text-decoration: none
}

.tooltip {
    display: block !important
}

.tiles {
    border-top: 1px solid #ccc;
    margin-top: 15px;
    padding-top: 5px;
    margin-bottom: 0
}

.tile {
    overflow: hidden
}

.top_tiles {
    margin-bottom: 0
}

.top_tiles .tile h2 {
    font-size: 30px;
    line-height: 30px;
    margin: 3px 0 7px;
    font-weight: bold
}

article.media {
    width: 100%
}

/* *,*:before,*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
} */


#integration-list {
    width: 100%;
    margin: 0 auto;
    display: table
}

#integration-list ul {
    padding: 0;
    margin: 20px 0;
    color: #555
}

#integration-list ul>li {
    list-style: none;
    border-top: 1px solid #ddd;
    display: block;
    padding: 15px;
    overflow: hidden
}

#integration-list ul:last-child {
    border-bottom: 1px solid #ddd
}

#integration-list ul>li:hover {
    background: #efefef
}

.expand {
    display: block;
    text-decoration: none;
    color: #555;
    cursor: pointer
}

.expand h2 {
    width: 85%;
    float: left
}

/* h2 {
    font-size: 18px;
    font-weight: 400
} */

#left,
#right {
    display: table
}

#sup {
    display: table-cell;
    vertical-align: middle;
    width: 80%
}

.detail a {
    text-decoration: none;
    color: #C0392B;
    border: 1px solid #C0392B;
    padding: 6px 10px 5px;
    font-size: 13px;
    margin-right: 7px
}

.detail {
    margin: 10px 0 10px 0px;
    display: none;
    line-height: 22px;
    height: 150px
}

.detail span {
    margin: 0
}

.right-arrow {
    width: 10px;
    float: right;
    font-weight: bold;
    font-size: 20px
}

.accordion .panel {
    margin-bottom: 5px;
    border-radius: 0;
    border-bottom: 1px solid #efefef
}

.accordion .panel-heading {
    background: #F2F5F7;
    padding: 13px;
    width: 100%;
    display: block
}

.accordion .panel:hover {
    background: #F2F5F7
}

.profile_details:nth-child(3n) {
    clear: both
}

.profile_details .profile_view {
    display: inline-block;
    padding: 10px 0 0;
    background: #fff
}

.profile_details .profile_view .divider {
    border-top: 1px solid #e5e5e5;
    padding-top: 5px;
    margin-top: 5px
}

.profile_details .profile_view .ratings {
    margin-bottom: 0
}

.profile_details .profile_view .bottom {
    background: #F2F5F7;
    padding: 9px 0;
    border-top: 1px solid #E6E9ED
}

.profile_details .profile_view .left {
    margin-top: 20px
}

.profile_details .profile_view .left p {
    margin-bottom: 3px
}

.profile_details .profile_view .right {
    margin-top: 0px;
    padding: 10px
}

.profile_details .profile_view .img-circle {
    border: 1px solid #E6E9ED;
    padding: 2px
}

.profile_details .profile_view h2 {
    margin: 5px 0
}

.profile_details .profile_view .ratings {
    text-align: left;
    font-size: 16px
}

.profile_details .profile_view .brief {
    margin: 0;
    font-weight: 300
}

.profile_details .profile_left {
    background: white
}

.pagination.pagination-split li {
    display: inline-block;
    margin-right: 3px
}

.pagination.pagination-split li a {
    border-radius: 4px;
    color: #768399;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px
}

table.tile h3,
table.tile h4,
table.tile span {
    font-weight: bold;
    vertical-align: middle !important
}

table.tile th,
table.tile td {
    text-align: center
}

table.tile th {
    border-bottom: 1px solid #E6ECEE
}

table.tile td {
    padding: 5px 0
}

table.tile td ul {
    text-align: left;
    padding-left: 0
}

table.tile td ul li {
    list-style: none;
    width: 100%
}

table.tile td ul li a {
    width: 100%
}

table.tile td ul li a big {
    right: 0;
    float: right;
    margin-right: 13px
}

table.tile_info {
    width: 100%
}

table.tile_info td {
    text-align: left;
    padding: 1px;
    font-size: 15px
}

table.tile_info td p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    line-height: 28px
}

table.tile_info td i {
    margin-right: 8px;
    font-size: 17px;
    float: left;
    width: 18px;
    line-height: 28px
}

table.tile_info td:first-child {
    width: 83%
}

/* td span {
    line-height: 28px
} */

.sidebar-widget {
    overflow: hidden
}

.error-number {
    font-size: 90px;
    line-height: 90px;
    margin: 20px 0
}

.col-middle {
    margin-top: 5%
}

.mid_center {
    width: 370px;
    margin: 0 auto;
    text-align: center;
    padding: 10px 20px
}

h3.degrees {
    font-size: 22px;
    font-weight: 400;
    text-align: center
}

.degrees:after {
    content: "o";
    position: relative;
    top: -12px;
    font-size: 13px;
    font-weight: 300
}

.daily-weather .day {
    font-size: 14px;
    border-top: 2px solid rgba(115, 135, 156, 0.36);
    text-align: center;
    border-bottom: 2px solid rgba(115, 135, 156, 0.36);
    padding: 5px 0
}

.weather-days .col-sm-2 {
    overflow: hidden;
    width: 16.66666667%
}

.weather .row {
    margin-bottom: 0
}

.bulk-actions {
    display: none
}

table.countries_list {
    width: 100%
}

table.countries_list td {
    padding: 0 10px;
    line-height: 30px;
    border-top: 1px solid #eeeeee
}

.dataTables_paginate a {
    padding: 6px 9px !important;
    background: #ddd !important;
    border-color: #ddd !important
}

.paging_full_numbers a.paginate_active {
    background-color: rgba(38, 185, 154, 0.59) !important;
    border-color: rgba(38, 185, 154, 0.59) !important
}

button.DTTT_button,
div.DTTT_button,
a.DTTT_button {
    border: 1px solid #E7E7E7 !important;
    background: #E7E7E7 !important;
    box-shadow: none !important
}

table.jambo_table {
    border: 1px solid rgba(221, 221, 221, 0.78)
}

table.jambo_table thead {
    background: rgba(52, 73, 94, 0.94);
    color: #ECF0F1
}

table.jambo_table tbody tr:hover td {
    background: rgba(38, 185, 154, 0.07);
    border-top: 1px solid rgba(38, 185, 154, 0.11);
    border-bottom: 1px solid rgba(38, 185, 154, 0.11)
}

table.jambo_table tbody tr.selected {
    background: rgba(38, 185, 154, 0.16)
}

table.jambo_table tbody tr.selected td {
    border-top: 1px solid rgba(38, 185, 154, 0.4);
    border-bottom: 1px solid rgba(38, 185, 154, 0.4)
}

.dataTables_paginate a {
    background: #ff0000
}

.dataTables_wrapper {
    position: relative;
    clear: both;
    zoom: 1
}

.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250px;
    height: 30px;
    margin-left: -125px;
    margin-top: -15px;
    padding: 14px 0 2px 0;
    border: 1px solid #ddd;
    text-align: center;
    color: #999;
    font-size: 14px;
    background-color: white
}

.dataTables_length {
    width: 40%;
    float: left
}

.dataTables_filter {
    width: 50%;
    float: right;
    text-align: right
}

.dataTables_info {
    width: 60%;
    float: left
}

.dataTables_paginate {
    float: right;
    text-align: right
}

table.dataTable th.focus,
table.dataTable td.focus {
    outline: 2px solid #1ABB9C !important;
    outline-offset: -1px
}

table.display {
    margin: 0 auto;
    clear: both;
    width: 100%
}

table.display thead th {
    padding: 8px 18px 8px 10px;
    border-bottom: 1px solid black;
    font-weight: bold;
    cursor: pointer
}

table.display tfoot th {
    padding: 3px 18px 3px 10px;
    border-top: 1px solid black;
    font-weight: bold
}

table.display tr.heading2 td {
    border-bottom: 1px solid #aaa
}

table.display td {
    padding: 3px 10px
}

table.display td.center {
    text-align: center
}

table.display thead th:active,
table.display thead td:active {
    outline: none
}

.dataTables_scroll {
    clear: both
}

.dataTables_scrollBody {
    *margin-top: -1px;
    -webkit-overflow-scrolling: touch
}

.top .dataTables_info {
    float: none
}

.clear {
    clear: both
}

.dataTables_empty {
    text-align: center
}

tfoot input {
    margin: 0.5em 0;
    width: 100%;
    color: #444
}

tfoot input.search_init {
    color: #999
}

td.group {
    background-color: #d1cfd0;
    border-bottom: 2px solid #A19B9E;
    border-top: 2px solid #A19B9E
}

td.details {
    background-color: #d1cfd0;
    border: 2px solid #A19B9E
}

.example_alt_pagination div.dataTables_info {
    width: 40%
}

.paging_full_numbers {
    width: 400px;
    height: 22px;
    line-height: 22px
}

.paging_full_numbers a:active {
    outline: none
}

.paging_full_numbers a:hover {
    text-decoration: none
}

.paging_full_numbers a.paginate_button,
.paging_full_numbers a.paginate_active {
    border: 1px solid #aaa;

    padding: 2px 5px;
    margin: 0 3px;
    cursor: pointer
}

.paging_full_numbers a.paginate_button {
    background-color: #ddd
}

.paging_full_numbers a.paginate_button:hover {
    background-color: #ccc;
    text-decoration: none !important
}

.paging_full_numbers a.paginate_active {
    background-color: #99B3FF
}

table.display tr.even.row_selected td {
    background-color: #B0BED9
}

table.display tr.odd.row_selected td {
    background-color: #9FAFD1
}

div.box {
    height: 100px;
    padding: 10px;
    overflow: auto;
    border: 1px solid #8080FF;
    background-color: #E5E5FF
}

.tile_count {
    margin-bottom: 20px;
    margin-top: 20px
}

.tile_count .tile_stats_count {
    border-bottom: 1px solid #D9DEE4;
    padding: 0 10px 0 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative
}


.tile_count .tile_stats_count:before {
    content: "";
    position: absolute;
    left: 0;
    height: 65px;
    border-left: 2px solid #ADB2B5;
    margin-top: 10px
}


.tile_count .tile_stats_count .count {
    font-size: 30px;
    line-height: 47px;
    font-weight: 600
}





.tile_count .tile_stats_count span {
    font-size: 12px
}



.tile_count .tile_stats_count .count_bottom i {
    width: 12px
}

.tile-stats {
    position: relative;
    display: block;
    margin-bottom: 12px;
    border: 1px solid #E4E4E4;
    -webkit-border-radius: 5px;
    overflow: hidden;
    padding-bottom: 5px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 5px;
    -moz-background-clip: padding;
    border-radius: 5px;
    background-clip: padding-box;
    background: #FFF;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out
}

.tile-stats:hover .icon i {
    animation-name: tansformAnimation;
    animation-duration: .5s;
    animation-iteration-count: 1;
    color: rgba(58, 58, 58, 0.41);
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    -webkit-animation-name: tansformAnimation;
    -webkit-animation-duration: .5s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: tansformAnimation;
    -moz-animation-duration: .5s;
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: ease;
    -moz-animation-fill-mode: forwards
}

.tile-stats .icon {
    width: 20px;
    height: 20px;
    color: #BAB8B8;
    position: absolute;
    right: 53px;
    top: 22px;
    z-index: 1
}

.tile-stats .icon i {
    margin: 0;
    font-size: 60px;
    line-height: 0;
    vertical-align: bottom;
    padding: 0
}

.tile-stats .count {
    font-size: 38px;
    font-weight: bold;
    line-height: 1.65857143
}

.tile-stats .count,
.tile-stats h3,
.tile-stats p {
    position: relative;
    margin: 0;
    margin-left: 10px;
    z-index: 5;
    padding: 0
}

.tile-stats h3 {
    color: #BAB8B8
}

.tile-stats p {
    margin-top: 5px;
    font-size: 12px
}

.tile-stats>.dash-box-footer {
    position: relative;
    text-align: center;
    margin-top: 5px;
    padding: 3px 0;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    text-decoration: none
}

.tile-stats>.dash-box-footer:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.15)
}

.tile-stats>.dash-box-footer:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.15)
}

table.tile_info {
    padding: 10px 15px
}

table.tile_info span.right {
    margin-right: 0;
    float: right;
    position: absolute;
    right: 4%
}

.tile:hover {
    text-decoration: none
}

.tile_header {
    border-bottom: transparent;
    padding: 7px 15px;
    margin-bottom: 15px;
    background: #E7E7E7
}

.tile_head h4 {
    margin-top: 0;
    margin-bottom: 5px
}

.tiles-bottom {
    padding: 5px 10px;
    margin-top: 10px;
    background: rgba(194, 194, 194, 0.3);
    text-align: left
}

a.star {
    color: #428bca !important
}

.mail_content {
    background: none repeat scroll 0 0 #FFFFFF;
    border-radius: 4px;
    margin-top: 20px;
    min-height: 500px;
    padding: 10px 11px;
    width: 100%
}

.list-btn-mail {
    margin-bottom: 15px
}

.list-btn-mail.active {
    border-bottom: 1px solid #39B3D7;
    padding: 0 0 14px
}

.list-btn-mail>i {
    float: left;
    font-size: 18px;
    font-style: normal;
    width: 33px
}

.list-btn-mail>.cn {
    background: none repeat scroll 0 0 #39B3D7;
    border-radius: 12px;
    color: #FFFFFF;
    float: right;
    font-style: normal;
    padding: 0 5px
}

.button-mail {
    margin: 0 0 15px !important;
    text-align: left;
    width: 100%
}

/* button,.buttons,.btn,.modal-footer .btn+.btn {
    margin-bottom: 7px !important;
    margin-right: 8px
} */

.btn-group-vertical .btn,
.btn-group .btn {
    margin-bottom: 0;
    margin-right: 0
}

.mail_list_column {
    border-left: 1px solid #DBDBDB
}

.mail_view {
    border-left: 1px solid #DBDBDB
}

.mail_list {
    width: 100%;
    border-bottom: 1px solid #DBDBDB;
    margin-bottom: 2px;
    display: inline-block
}

.mail_list .left {
    width: 5%;
    float: left;
    margin-right: 3%
}

.mail_list .right {
    width: 90%;
    float: left
}

.mail_list h3 {
    font-size: 15px;
    font-weight: bold;
    margin: 0px 0 6px
}

.mail_list h3 small {
    float: right;
    color: #ADABAB;
    font-size: 11px;
    line-height: 20px
}

.mail_list .badge {
    padding: 3px 6px;
    font-size: 8px;
    background: #BAB7B7
}



.mail_heading h4 {
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-top: 20px
}

.attachment {
    margin-top: 30px
}

.attachment ul {
    width: 100%;
    list-style: none;
    padding-left: 0;
    display: inline-block;
    margin-bottom: 30px
}

.attachment ul li {
    float: left;
    width: 150px;
    margin-right: 10px;
    margin-bottom: 10px
}

.attachment ul li img {
    height: 150px;
    border: 1px solid #ddd;
    padding: 5px;
    margin-bottom: 10px
}

.attachment ul li span {
    float: right
}

.attachment .file-name {
    float: left
}

.attachment .links {
    width: 100%;
    display: inline-block
}

.compose {
    padding: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fff;
    border: 1px solid #D9DEE4;
    border-right: 0;
    border-bottom: 0;
    border-top-left-radius: 5px;
    z-index: 9999;
    display: none
}

.compose .compose-header {
    padding: 5px;
    background: #169F85;
    color: #fff;
    border-top-left-radius: 5px
}

.compose .compose-header .close {
    text-shadow: 0 1px 0 #ffffff;
    line-height: .8
}

.compose .compose-body .editor.btn-toolbar {
    margin: 0
}

.compose .compose-body .editor-wrapper {
    height: 100%;
    min-height: 50px;
    max-height: 180px;
    border-radius: 0;
    border-left: none;
    border-right: none;
    overflow: auto
}

.compose .compose-footer {
    padding: 10px
}

.editor.btn-toolbar {
    zoom: 1;
    background: #F7F7F7;
    margin: 5px 2px;
    padding: 3px 0;
    border: 1px solid #EFEFEF
}

.input-group {
    margin-bottom: 10px
}

.ln_solid {
    border-top: 1px solid #e5e5e5;
    color: #ffffff;
    background-color: #ffffff;
    height: 1px;
    margin: 20px 0
}

span.section {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5
}

.form-control {
    border-radius: 0;
    width: 100%
}

.form-horizontal .control-label {
    padding-top: 8px
}

.form-control:focus {
    border-color: #CCD0D7;
    box-shadow: none !important
}

legend {
    font-size: 18px;
    color: inherit
}

.form-horizontal .form-group {
    margin-right: 0;
    margin-left: 0
}

.form-control-feedback {
    margin-top: 8px;
    height: 23px;
    color: #bbb;
    line-height: 24px;
    font-size: 15px
}

.form-control-feedback.left {
    border-right: 1px solid #ccc;
    left: 13px
}

.form-control-feedback.right {
    border-left: 1px solid #ccc;
    right: 13px
}

.form-control.has-feedback-left {
    padding-left: 45px
}

.form-control.has-feedback-right {
    padding-right: 45px
}

.form-group {
    margin-bottom: 10px
}

.validate {
    margin-top: 10px
}

.invalid-form-error-message {
    margin-top: 10px;
    padding: 5px
}

.invalid-form-error-message.filled {
    border-left: 2px solid #E74C3C
}

p.parsley-success {
    color: #468847;
    background-color: #DFF0D8;
    border: 1px solid #D6E9C6
}

p.parsley-error {
    color: #B94A48;
    background-color: #F2DEDE;
    border: 1px solid #EED3D7
}

ul.parsley-errors-list {
    list-style: none;
    color: #E74C3C;
    padding-left: 0
}

input.parsley-error,
textarea.parsley-error,
select.parsley-error {
    background: #FAEDEC;
    border: 1px solid #E85445
}

.btn-group .parsley-errors-list {
    display: none
}

.bad input,
.bad select,
.bad textarea {
    border: 1px solid #CE5454;
    box-shadow: 0 0 4px -2px #CE5454;
    position: relative;
    left: 0;

}

.item input,
.item textarea {
    -webkit-transition: 0.42s;
    -moz-transition: 0.42s;
    transition: 0.42s
}

.item .alert {
    float: left;
    margin: 0 0 0 20px;
    padding: 3px 10px;
    color: #FFF;
    border-radius: 3px 4px 4px 3px;
    background-color: #CE5454;
    max-width: 170px;
    white-space: pre;
    position: relative;
    left: -15px;
    opacity: 0;
    z-index: 1;
    transition: 0.15s ease-out
}

.item .alert::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
    border-color: transparent #CE5454 transparent transparent;
    border-style: solid;
    border-width: 11px 7px;
    position: absolute;
    left: -13px;
    top: 1px
}

.item.bad .alert {
    left: 0;
    opacity: 1
}

.inl-bl {
    display: inline-block
}

.well .markup {
    background: #fff;
    color: #777;
    position: relative;
    padding: 45px 15px 15px;
    margin: 15px 0 0 0;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: none
}

.well .markup::after {
    content: "Example";
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 12px;
    font-weight: bold;
    color: #bbb;
    text-transform: uppercase;
    letter-spacing: 1px
}

.autocomplete-suggestions {
    border: 1px solid #e4e4e4;
    background: #F4F4F4;
    cursor: default;
    overflow: auto
}

.autocomplete-suggestion {
    padding: 2px 5px;
    font-size: 1.2em;
    white-space: nowrap;
    overflow: hidden
}

.autocomplete-selected {
    background: #f0f0f0
}

.autocomplete-suggestions strong {
    font-weight: normal;
    color: #3399ff;
    font-weight: bolder
}

textarea {
    padding: 10px;
    vertical-align: top;
    width: 200px
}

textarea:focus {
    outline-style: solid;
    outline-width: 2px
}

.btn_ {
    display: inline-block;
    padding: 3px 9px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #333333;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    background-color: #f5f5f5;
    /* background-image: -moz-linear-gradient(top, #fff, #e6e6e6); */
    /* background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#e6e6e6)); */
    /* background-image: -webkit-linear-gradient(top, #fff, #e6e6e6); */
    /* background-image: -o-linear-gradient(top, #fff, #e6e6e6); */
    /* background-image: linear-gradient(to bottom, #fff, #e6e6e6); */
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    border: 1px solid #cccccc;
    border-bottom-color: #b3b3b3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)
}

.bs-glyphicons {
    margin: 0 -10px 20px;
    overflow: hidden
}

.bs-glyphicons-list {
    padding-left: 0;
    list-style: none
}

.bs-glyphicons li {
    float: left;
    width: 25%;
    height: 115px;
    padding: 10px;
    font-size: 10px;
    line-height: 1.4;
    text-align: center;
    background-color: #f9f9f9;
    border: 1px solid #fff
}

.bs-glyphicons .glyphicon {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 24px
}

.bs-glyphicons .glyphicon-class {
    display: block;
    text-align: center;
    word-wrap: break-word
}

.bs-glyphicons li:hover {
    color: #fff;
    background-color: #1ABB9C
}

.tagsinput {
    border: 1px solid #CCC;
    background: #FFF;
    padding: 6px 6px 0;
    width: 300px;
    overflow-y: auto
}

span.tag {

    display: block;
    float: left;
    padding: 5px 9px;
    text-decoration: none;
    background: #1ABB9C;
    color: #F1F6F7;
    margin-right: 5px;
    font-weight: 500;
    margin-bottom: 5px;
    font-family: helvetica
}

span.tag a {
    color: #F1F6F7 !important
}

.tagsinput span.tag a {
    font-weight: bold;
    color: #82ad2b;
    text-decoration: none;
    font-size: 11px
}

.tagsinput input {
    width: 80px;
    margin: 0px;
    font-family: helvetica;
    font-size: 13px;
    border: 1px solid transparent;
    padding: 3px;
    background: transparent;
    color: #000;
    outline: 0px
}

.tagsinput div {
    display: block;
    float: left
}

.tags_clear {
    clear: both;
    width: 100%;
    height: 0px
}

.not_valid {
    background: #FBD8DB !important;
    color: #90111A !important
}

ul.bar_tabs {
    overflow: visible;
    background: #F5F7FA;
    height: 25px;
    margin: 21px 0 14px;
    position: relative;
    z-index: 1;
    width: 100%;
    border-bottom: 1px solid #E6E9ED
}

ul.bar_tabs>li {
    border: 1px solid #E6E9ED;
    color: #333 !important;
    margin-top: -17px;
    margin-left: 8px;
    background: #fff;
    border-bottom: none;
    border-radius: 4px 4px 0 0
}

ul.bar_tabs>li.active {
    border-right: 6px solid #D3D6DA;
    border-top: 0;
    margin-top: -15px
}

ul.bar_tabs>li a {
    padding: 10px 17px;
    background: #F5F7FA;
    margin: 0;
    border-top-right-radius: 0
}

ul.bar_tabs>li a:hover {
    border: 1px solid transparent
}

ul.bar_tabs>li.active a {
    border-bottom: none
}

ul.bar_tabs.right {
    padding-right: 14px
}

ul.bar_tabs.right li {
    float: right
}

a:focus {
    outline: none
}

ul.timeline li {
    position: relative;
    border-bottom: 1px solid #e8e8e8;
    clear: both
}

.timeline .block {
    margin: 0;
    border-left: 3px solid #e8e8e8;
    overflow: visible;
    padding: 10px 15px;
    margin-left: 105px
}

.timeline.widget {
    min-width: 0;
    max-width: inherit
}

.timeline.widget .block {
    margin-left: 5px
}

.timeline .tags {
    position: absolute;
    top: 15px;
    left: 0;
    width: 84px
}

.timeline .tag {
    display: block;
    height: 30px;
    font-size: 13px;
    padding: 8px
}

.timeline .tag span {
    display: block;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis
}

.tag {
    line-height: 1;
    background: #1ABB9C;
    color: #fff !important
}

.tag:after {
    content: " ";
    height: 30px;
    width: 0;
    position: absolute;
    left: 100%;
    top: 0;
    margin: 0;
    pointer-events: none;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-left: 11px solid #1ABB9C
}

.timeline h2.title {
    position: relative;
    font-size: 16px;
    margin: 0
}

.timeline h2.title:before {
    content: "";
    position: absolute;
    left: -23px;
    top: 3px;
    display: block;
    width: 14px;
    height: 14px;
    border: 3px solid #d2d3d2;
    border-radius: 14px;
    background: #f9f9f9
}

.timeline .byline {
    padding: .25em 0
}

.byline {
    -webkit-font-smoothing: antialiased;
    font-style: italic;
    font-size: .9375em;
    line-height: 1.3;
    color: #aab6aa
}

ul.social li {
    border: 0
}

.social-sidebar,
.social-body {
    float: right
}

.social-sidebar {
    background: #EDEDED;
    width: 22%
}

.social-body {
    border: 1px solid #ccc;
    width: 78%
}

.thumb img {
    width: 50px;
    height: 50px;
    border-radius: 50%
}

.chat .thumb img {
    width: 27px;
    height: 27px;
    border-radius: 50%
}

.chat .status {
    float: left;
    margin: 16px 0 0 -16px;
    font-size: 14px;
    font-weight: bold;
    width: 12px;
    height: 12px;
    display: block;
    border: 2px solid #FFF;
    z-index: 12312;
    border-radius: 50%
}

.chat .status.online {
    background: #1ABB9C
}

.chat .status.away {
    background: #F39C12
}

.chat .status.offline {
    background: #ccc
}

.chat .media-body {
    padding-top: 5px
}

.dashboard_graph .x_title {
    padding: 5px 5px 7px
}

.dashboard_graph .x_title h3 {
    margin: 0;
    font-weight: normal
}

.chart {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 110px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center
}

.chart canvas {
    position: absolute;
    top: 0;
    left: 0
}

.percent {
    display: inline-block;
    line-height: 110px;
    z-index: 2;
    font-size: 18px
}

.percent:after {
    content: '%';
    margin-left: 0.1em;
    font-size: .8em
}

.angular {
    margin-top: 100px
}

.angular .chart {
    margin-top: 0
}

.widget {
    min-width: 250px;
    max-width: 310px
}

.widget_tally_box .btn-group button {
    text-align: center
}

.widget_tally_box .btn-group button {
    color: inherit;
    font-weight: 500;
    background-color: #f5f5f5;
    border: 1px solid #e7e7e7
}

ul.widget_tally,
ul.widget_tally li {
    width: 100%
}

ul.widget_tally li {
    padding: 2px 10px;
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 4px
}

ul.widget_tally .month {
    width: 70%;
    float: left
}

ul.widget_tally .count {
    width: 30%;
    float: left;
    text-align: right
}

.pie_bg {
    border-bottom: 1px solid rgba(101, 204, 182, 0.16);
    padding-bottom: 15px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    padding-bottom: 10px;
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    box-shadow: 0 4px 6px -6px #222
}

.widget_tally_box .flex {
    display: flex
}

ul.widget_profile_box {
    width: 100%;
    height: 42px;
    padding: 3px;
    background: #ececec;
    margin-top: 40px;
    margin-left: 1px
}

ul.widget_profile_box li:first-child {
    width: 25%;
    float: left
}

ul.widget_profile_box li:first-child a {
    float: left
}

ul.widget_profile_box li:last-child {
    width: 25%;
    float: right
}

ul.widget_profile_box li:last-child a {
    float: right
}

ul.widget_profile_box li a {
    font-size: 22px;
    text-align: center;
    width: 35px;
    height: 35px;
    border: 1px solid rgba(52, 73, 94, 0.44);
    display: block;
    border-radius: 50%;
    padding: 0px
}

ul.widget_profile_box li a:hover {
    color: #1ABB9C !important;
    border: 1px solid #26b99a
}

ul.widget_profile_box li .profile_img {
    width: 85px;
    height: 85px;
    margin: 0;
    margin-top: -28px
}

.widget_tally_box p,
.widget_tally_box span {
    text-align: center
}

.widget_tally_box .name {
    text-align: center;
    margin: 25px
}

.widget_tally_box .name_title {
    text-align: center;
    margin: 5px
}

.widget_tally_box ul.legend {
    margin: 0
}

.widget_tally_box ul.legend p,
.widget_tally_box ul.legend span {
    text-align: left
}

.widget_tally_box ul.legend li .icon {
    font-size: 20px;
    float: left;
    width: 14px
}

.widget_tally_box ul.legend li .name {
    font-size: 14px;
    margin: 5px 0 0 14px;
    text-overflow: ellipsis;
    float: left
}

.widget_tally_box ul.legend p {
    display: inline-block;
    margin: 0
}

.widget_tally_box ul.verticle_bars li {
    height: 140px;
    width: 23%
}

.widget .verticle_bars li .progress.vertical.progress_wide {
    width: 65%
}

ul.count2 {
    width: 100%;
    margin-left: 1px;
    border: 1px solid #ddd;
    border-left: 0;
    border-right: 0;
    padding: 10px 0
}

ul.count2 li {
    width: 30%;
    text-align: center
}

ul.count2 li h3 {
    font-weight: 400;
    margin: 0
}

ul.count2 li span {
    font-weight: 300
}

.divider {
    border-bottom: 1px solid #ddd;
    margin: 10px
}

.divider-dashed {
    border-top: 1px dashed #e7eaec;
    background-color: #ffffff;
    height: 1px;
    margin: 10px 0
}

ul.messages {
    padding: 0;
    list-style: none
}

ul.messages li,
.tasks li {
    border-bottom: 1px dotted #e6e6e6;
    padding: 8px 0
}

ul.messages li img.avatar,
img.avatar {
    height: 32px;
    width: 32px;

    display: inline-block;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 2px;
    background: #f7f7f7;
    border: 1px solid #e6e6e6
}

ul.messages li .message_date {
    float: right;
    text-align: right
}

ul.messages li .message_wrapper {
    margin-left: 50px;
    margin-right: 40px
}

ul.messages li .message_wrapper h4.heading {
    font-weight: 600;
    margin: 0;
    cursor: pointer;
    margin-bottom: 10px;
    line-height: 100%
}

ul.messages li .message_wrapper blockquote {
    padding: 0px 10px;
    margin: 0;
    border-left: 5px solid #eee
}

ul.user_data li {
    margin-bottom: 6px
}

ul.user_data li p {
    margin-bottom: 0
}

ul.user_data li .progress {
    width: 90%
}

.project_progress .progress {
    margin-bottom: 3px !important;
    margin-top: 5px
}

.projects .list-inline {
    margin: 0
}

.profile_title {
    background: #F5F7FA;
    border: 0;
    padding: 7px 0;
    display: flex
}

ul.stats-overview {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
    margin-bottom: 10px
}

ul.stats-overview li {
    display: inline-block;
    text-align: center;
    padding: 0 15px;
    width: 30%;
    font-size: 14px;
    border-right: 1px solid #e8e8e8
}

ul.stats-overview li:last-child {
    border-right: 0
}

ul.stats-overview li .name {
    font-size: 12px
}

ul.stats-overview li .value {
    font-size: 14px;
    font-weight: bold;
    display: block
}

ul.stats-overview li:first-child {
    padding-left: 0
}

ul.project_files li {
    margin-bottom: 5px
}

ul.project_files li a i {
    width: 20px
}

.project_detail p {
    margin-bottom: 10px
}

.project_detail p.title {
    font-weight: bold;
    margin-bottom: 0
}

.avatar img {
    border-radius: 50%;
    max-width: 45px
}

.pricing {
    background: #fff
}

.pricing .title {
    background: #1ABB9C;
    height: 110px;
    color: #fff;
    padding: 15px 0 0;
    text-align: center
}

.pricing .title h2 {
    text-transform: capitalize;
    font-size: 18px;
    border-radius: 5px 5px 0 0;
    margin: 0;
    font-weight: 400
}

.pricing .title h1 {
    font-size: 30px;
    margin: 12px
}

.pricing .title span {
    background: rgba(51, 51, 51, 0.28);
    padding: 2px 5px
}

.pricing_features {
    background: #FAFAFA;
    padding: 20px 15px;
    min-height: 230px;
    font-size: 13.5px
}

.pricing_features ul li {
    margin-top: 10px
}

.pricing_footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    text-align: center;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.pricing_footer p {
    font-size: 13px;
    padding: 10px 0 2px;
    display: block
}

.ui-ribbon-container {
    position: relative
}

.ui-ribbon-container .ui-ribbon-wrapper {
    position: absolute;
    overflow: hidden;
    width: 85px;
    height: 88px;
    top: -3px;
    right: -3px
}

.ui-ribbon-container.ui-ribbon-primary .ui-ribbon {
    background-color: #5b90bf
}

.ui-ribbon-container .ui-ribbon {
    position: relative;
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    padding: 7px 0;
    left: -5px;
    top: 15px;
    width: 120px;
    line-height: 20px;
    background-color: #555;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3)
}

.ui-ribbon-container.ui-ribbon-primary .ui-ribbon:after,
.ui-ribbon-container.ui-ribbon-primary .ui-ribbon:before {
    border-top: 2px solid #5b90bf
}

.ui-ribbon-container .ui-ribbon:before {
    left: 0;
    bottom: -1px
}

.ui-ribbon-container .ui-ribbon:before {
    right: 0
}

.ui-ribbon-container .ui-ribbon:after,
.ui-ribbon-container .ui-ribbon:before {
    position: absolute;
    content: " ";
    line-height: 0;
    border-top: 2px solid #555;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent
}

.thumbnail .image {
    height: 120px;
    overflow: hidden
}

.caption {
    padding: 9px 5px;
    background: #F7F7F7
}

.caption p {
    margin-bottom: 5px
}

.thumbnail {
    height: 190px;
    overflow: hidden
}

.view {
    overflow: hidden;
    position: relative;
    text-align: center;
    box-shadow: 1px 1px 2px #e6e6e6;
    cursor: default
}

.view .mask,
.view .content {
    position: absolute;
    width: 100%;
    overflow: hidden;
    top: 0;
    left: 0
}

.view img {
    display: block;
    position: relative
}

.view .tools {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 3px;
    background: rgba(0, 0, 0, 0.35);
    margin: 43px 0 0 0
}

.mask.no-caption .tools {
    margin: 90px 0 0 0
}

.view .tools a {
    display: inline-block;
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
    padding: 0 4px
}

.view p {
    font-family: Georgia, serif;
    font-style: italic;
    font-size: 12px;
    position: relative;
    color: #fff;
    padding: 10px 20px 20px;
    text-align: center
}

.view a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: #000;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0 0 1px #000
}

.view-first img {
    transition: all 0.2s linear
}

.view-first .mask {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.4s ease-in-out
}

.view-first .tools {
    transform: translateY(-100px);
    opacity: 0;
    transition: all 0.2s ease-in-out
}

.view-first p {
    transform: translateY(100px);
    opacity: 0;
    transition: all 0.2s linear
}

.view-first:hover img {
    transform: scale(1.1)
}

.view-first:hover .mask {
    opacity: 1
}

.view-first:hover .tools,
.view-first:hover p {
    opacity: 1;
    transform: translateY(0px)
}

.view-first:hover p {
    transition-delay: 0.1s
}

/*!
 * bootstrap-vertical-tabs - v1.2.1
 /* * https://dbtek.github.io/bootstrap-vertical-tabs */


.tabs-left,
.tabs-right {
    border-bottom: none;
    padding-top: 2px
}

.tabs-left {
    border-right: 1px solid #F7F7F7
}

.tabs-right {
    border-left: 1px solid #F7F7F7
}

.tabs-left>li,
.tabs-right>li {
    float: none;
    margin-bottom: 2px
}

.tabs-left>li {
    margin-right: -1px
}

.tabs-right>li {
    margin-left: -1px
}

.tabs-left>li.active>a,
.tabs-left>li.active>a:hover,
.tabs-left>li.active>a:focus {
    border-bottom-color: #F7F7F7;
    border-right-color: transparent
}

.tabs-right>li.active>a,
.tabs-right>li.active>a:hover,
.tabs-right>li.active>a:focus {
    border-bottom: 1px solid #F7F7F7;
    border-left-color: transparent
}

.tabs-left>li>a {
    border-radius: 4px 0 0 4px;
    margin-right: 0;
    display: block;
    background: #F7F7F7;
    text-overflow: ellipsis;
    overflow: hidden
}

.tabs-right>li>a {
    border-radius: 0 4px 4px 0;
    margin-right: 0;
    background: #F7F7F7;
    text-overflow: ellipsis;
    overflow: hidden
}

.sideways {
    margin-top: 50px;
    border: none;
    position: relative
}

.sideways>li {
    height: 20px;
    width: 120px;
    margin-bottom: 100px
}

.sideways>li>a {
    border-bottom: 1px solid #ddd;
    border-right-color: transparent;
    text-align: center;
    border-radius: 4px 4px 0px 0px
}

.sideways>li.active>a,
.sideways>li.active>a:hover,
.sideways>li.active>a:focus {
    border-bottom-color: transparent;
    border-right-color: #ddd;
    border-left-color: #ddd
}

.sideways.tabs-left {
    left: -50px
}

.sideways.tabs-right {
    right: -50px
}

.sideways.tabs-right>li {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg)
}

.sideways.tabs-left>li {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.morris-hover {
    position: absolute;
    z-index: 1000
}

.morris-hover.morris-default-style {
    padding: 6px;
    color: #666;
    background: rgba(243, 242, 243, 0.8);
    border: solid 2px rgba(195, 194, 196, 0.8);
    font-family: sans-serif;
    font-size: 12px;
    text-align: center
}

.morris-hover.morris-default-style .morris-hover-row-label {
    font-weight: bold;
    margin: 0.25em 0
}

.morris-hover.morris-default-style .morris-hover-point {
    white-space: nowrap;
    margin: 0.1em 0
}

.price {
    font-size: 40px;
    font-weight: 400;
    color: #26B99A;
    margin: 0
}

.prod_title {
    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 5px;
    margin: 30px 0;
    font-size: 20px;
    font-weight: 400
}

.product-image img {
    width: 90%
}

.prod_color li {
    margin: 0 10px
}

.prod_color li p {
    margin-bottom: 0
}

.prod_size li {
    padding: 0
}

.prod_color .color {
    width: 25px;
    height: 25px;
    border: 2px solid rgba(51, 51, 51, 0.28) !important;
    padding: 2px;
    border-radius: 50px
}

.product_gallery a {
    width: 100px;
    height: 100px;
    float: left;
    margin: 10px;
    border: 1px solid #e5e5e5
}

.product_gallery a img {
    width: 100%;
    margin-top: 15px
}

.product_price {
    margin: 20px 0;
    padding: 5px 10px;
    background-color: #FFFFFF;
    text-align: left;
    border: 2px dashed #E0E0E0
}

.price-tax {
    font-size: 18px
}

.product_social {
    margin: 20px 0
}

.product_social ul li a i {
    font-size: 35px
}

.login {
    background: #F7F7F7
}

.login .fa-paw {
    font-size: 26px
}

a.hiddenanchor {
    display: none
}

.login_wrapper {
    right: 0px;
    margin: 0px auto;
    margin-top: 5%;
    max-width: 350px;
    position: relative
}

.registration_form,
.login_form {
    position: absolute;
    top: 0px;
    width: 100%
}

.registration_form {
    z-index: 21;
    opacity: 0;
    width: 100%
}

.login_form {
    z-index: 22
}

#signup:target~.login_wrapper .registration_form,
#signin:target~.login_wrapper .login_form {
    z-index: 22;
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -ms-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    -o-animation-delay: .1s;
    -ms-animation-delay: .1s;
    animation-delay: .1s
}

#signup:target~.login_wrapper .login_form,
#signin:target~.login_wrapper .registration_form {
    -webkit-animation-name: fadeOutLeft;
    -moz-animation-name: fadeOutLeft;
    -ms-animation-name: fadeOutLeft;
    -o-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft
}

.animate {
    -webkit-animation-duration: 0.5s;
    -webkit-animation-timing-function: ease;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: 0.5s;
    -moz-animation-timing-function: ease;
    -moz-animation-fill-mode: both;
    -o-animation-duration: 0.5s;
    -o-animation-timing-function: ease;
    -o-animation-fill-mode: both;
    -ms-animation-duration: 0.5s;
    -ms-animation-timing-function: ease;
    -ms-animation-fill-mode: both;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-fill-mode: both
}

.login_box {
    padding: 20px;
    margin: auto
}

.left {
    float: left
}

.alignleft {
    float: left;
    margin-right: 15px
}

.alignright {
    float: right;
    margin-left: 15px
}

.clearfix:after,
form:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden
}

.login_content {
    margin: 0 auto;
    padding: 25px 0 0;
    position: relative;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    min-width: 280px
}

.login_content a,
.login_content .btn-default:hover {
    text-decoration: none
}

.login_content a:hover {
    text-decoration: underline
}

.login_content h1 {
    font: normal 25px Helvetica, Arial, sans-serif;
    letter-spacing: -0.05em;
    line-height: 20px;
    margin: 10px 0 30px
}

.login_content h1:before,
.login_content h1:after {
    content: "";
    height: 1px;
    position: absolute;
    top: 10px;
    width: 27%
}

.login_content h1:after {
    background: #7e7e7e;
    background: -moz-linear-gradient(left, #7e7e7e 0%, #fff 100%);
    background: -webkit-linear-gradient(left, #7e7e7e 0%, #fff 100%);
    background: -o-linear-gradient(left, #7e7e7e 0%, #fff 100%);
    background: -ms-linear-gradient(left, #7e7e7e 0%, #fff 100%);
    background: linear-gradient(to right, #7e7e7e 0%, #fff 100%);
    right: 0
}

.login_content h1:before {
    background: #7e7e7e;
    background: -moz-linear-gradient(right, #7e7e7e 0%, #fff 100%);
    background: -webkit-linear-gradient(right, #7e7e7e 0%, #fff 100%);
    background: -o-linear-gradient(right, #7e7e7e 0%, #fff 100%);
    background: -ms-linear-gradient(right, #7e7e7e 0%, #fff 100%);
    background: linear-gradient(to left, #7e7e7e 0%, #fff 100%);
    left: 0
}

.login_content h1:before,
.login_content h1:after {
    content: "";
    height: 1px;
    position: absolute;
    top: 10px;
    width: 20%
}

.login_content h1:after {
    background: #7e7e7e;
    background: -moz-linear-gradient(left, #7e7e7e 0%, #fff 100%);
    background: -webkit-linear-gradient(left, #7e7e7e 0%, #fff 100%);
    background: -o-linear-gradient(left, #7e7e7e 0%, #fff 100%);
    background: -ms-linear-gradient(left, #7e7e7e 0%, #fff 100%);
    background: linear-gradient(to right, #7e7e7e 0%, #fff 100%);
    right: 0
}

.login_content h1:before {
    background: #7e7e7e;
    background: -moz-linear-gradient(right, #7e7e7e 0%, #fff 100%);
    background: -webkit-linear-gradient(right, #7e7e7e 0%, #fff 100%);
    background: -o-linear-gradient(right, #7e7e7e 0%, #fff 100%);
    background: -ms-linear-gradient(right, #7e7e7e 0%, #fff 100%);
    background: linear-gradient(to left, #7e7e7e 0%, #fff 100%);
    left: 0
}

.login_content form {
    margin: 20px 0;
    position: relative
}

.login_content form input[type="text"],
.login_content form input[type="email"],
.login_content form input[type="password"] {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
    -moz-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
    -ms-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
    -o-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
    box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
    border: 1px solid #c8c8c8;
    color: #777;
    margin: 0 0 20px;
    width: 100%
}

.login_content form input[type="text"]:focus,
.login_content form input[type="email"]:focus,
.login_content form input[type="password"]:focus {
    -webkit-box-shadow: 0 0 2px #AA77B4 inset;
    -moz-box-shadow: 0 0 2px #ed1c24 inset;
    -ms-box-shadow: 0 0 2px #ed1c24 inset;
    -o-box-shadow: 0 0 2px #ed1c24 inset;
    box-shadow: 0 0 2px #A97AAD inset;
    background-color: #fff;
    border: 1px solid #A878AF;
    outline: none
}

#username {
    background-position: 10px 10px !important
}

#password {
    background-position: 10px -53px !important
}

.login_content form div a {
    font-size: 12px;
    margin: 10px 15px 0 0
}

.reset_pass {
    margin-top: 10px !important
}

.login_content div .reset_pass {
    margin-top: 13px !important;
    margin-right: 39px;
    float: right
}

.separator {
    border-top: 1px solid #D8D8D8;
    margin-top: 10px;
    padding-top: 10px
}

.button {
    background: #f7f9fa;
    background: -moz-linear-gradient(top, #f7f9fa 0%, #f0f0f0 100%);
    background: -webkit-linear-gradient(top, #f7f9fa 0%, #f0f0f0 100%);
    background: -o-linear-gradient(top, #f7f9fa 0%, #f0f0f0 100%);
    background: -ms-linear-gradient(top, #f7f9fa 0%, #f0f0f0 100%);
    background: linear-gradient(to bottom, #f7f9fa 0%, #f0f0f0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f9fa', endColorstr='#f0f0f0', GradientType=0);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    -o-border-radius: 0 0 5px 5px;
    -ms-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #CFD5D9;
    padding: 15px 0
}

.login_content form input[type="submit"],
#content form .submit {
    float: left;
    margin-left: 38px
}

.button a {
    /* background: url(http://cssdeck.com/uploads/media/items/8/8bcLQqF.png) 0 -112px no-repeat; */
    color: #7E7E7E;
    font-size: 17px;
    padding: 2px 0 2px 40px;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.button a:hover {
    background-position: 0 -135px;
    color: #00aeef
}

header {
    width: 100%
}

#nprogress .bar {
    background: #1ABB9C
}

#nprogress .peg {
    box-shadow: 0 0 10px #1ABB9C, 0 0 5px #1ABB9C
}

#nprogress .spinner-icon {
    border-top-color: #1ABB9C;
    border-left-color: #1ABB9C
}

.editor-wrapper {
    min-height: 250px;
    background-color: white;
    border-collapse: separate;
    border: 1px solid #ccc;
    padding: 4px;
    box-sizing: content-box;
    -webkit-box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset;
    box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    overflow: scroll;
    outline: none
}

.voiceBtn {
    width: 20px;
    color: transparent;
    background-color: transparent;
    transform: scale(2, 2);
    -webkit-transform: scale(2, 2);
    -moz-transform: scale(2, 2);
    border: transparent;
    cursor: pointer;
    box-shadow: none;
    -webkit-box-shadow: none
}

div[data-role="editor-toolbar"] {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.dropdown-menu a {
    cursor: pointer
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0;
    min-height: 38px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #73879C;
    padding-top: 5px
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding-top: 3px
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 36px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__clear {
    margin-top: 2px;
    border: none;
    border-radius: 0;
    padding: 3px 5px
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #ccc
}

.switchery {
    width: 32px;
    height: 20px
}

.switchery>small {
    width: 20px;
    height: 20px
}

fieldset {
    border: none;
    margin: 0;
    padding: 0
}

.cropper .img-container,
.cropper .img-preview {
    background-color: #f7f7f7;
    width: 100%;
    text-align: center
}

.cropper .img-container {
    min-height: 200px;
    max-height: 516px;
    margin-bottom: 20px
}



.cropper .img-container>img {
    max-width: 100%
}

.cropper .docs-preview {
    margin-right: -15px
}

.cropper .img-preview {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden
}

.cropper .img-preview>img {
    max-width: 100%
}

.cropper .preview-lg {
    width: 263px;
    height: 148px
}

.cropper .preview-md {
    width: 139px;
    height: 78px
}

.cropper .preview-sm {
    width: 69px;
    height: 39px
}

.cropper .preview-xs {
    width: 35px;
    height: 20px;
    margin-right: 0
}

.cropper .docs-data>.input-group {
    margin-bottom: 10px
}

.cropper .docs-data>.input-group>label {
    min-width: 80px
}

.cropper .docs-data>.input-group>span {
    min-width: 50px
}

.cropper .docs-buttons>.btn,
.cropper .docs-buttons>.btn-group,
.cropper .docs-buttons>.form-control {
    margin-right: 5px;
    margin-bottom: 10px
}

.cropper .docs-toggles>.btn,
.cropper .docs-toggles>.btn-group,
.cropper .docs-toggles>.dropdown {
    margin-bottom: 10px
}

.cropper .docs-tooltip {
    display: block;
    margin: -6px -12px;
    padding: 6px 12px
}

.cropper .docs-tooltip>.icon {
    margin: 0 -3px;
    vertical-align: top
}

.cropper .tooltip-inner {
    white-space: normal
}

.cropper .btn-upload .tooltip-inner,
.cropper .btn-toggle .tooltip-inner {
    white-space: nowrap
}

.cropper .btn-toggle {
    padding: 6px
}

.cropper .btn-toggle>.docs-tooltip {
    margin: -6px;
    padding: 6px
}



.cropper .docs-options .dropdown-menu {
    width: 100%
}

.cropper .docs-options .dropdown-menu>li {
    padding: 3px 20px
}

.cropper .docs-options .dropdown-menu>li:hover {
    background-color: #f7f7f7
}

.cropper .docs-options .dropdown-menu>li>label {
    display: block
}

.cropper .docs-cropped .modal-body {
    text-align: center
}

.cropper .docs-cropped .modal-body>img,
.cropper .docs-cropped .modal-body>canvas {
    max-width: 100%
}

.cropper .docs-diagram .modal-dialog {
    max-width: 352px
}

.cropper .docs-cropped canvas {
    max-width: 100%
}

.form_wizard .stepContainer {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    border: 0 solid #CCC;
    overflow-x: hidden
}

.wizard_horizontal ul.wizard_steps {
    display: table;
    list-style: none;
    position: relative;
    width: 100%;
    margin: 0 0 20px
}

.wizard_horizontal ul.wizard_steps li {
    display: table-cell;
    text-align: center
}

.wizard_horizontal ul.wizard_steps li a,
.wizard_horizontal ul.wizard_steps li:hover {
    display: block;
    position: relative;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
    opacity: 1;
    color: #666
}

.wizard_horizontal ul.wizard_steps li a:before {
    content: "";
    position: absolute;
    height: 4px;
    background: #ccc;
    top: 20px;
    width: 100%;
    z-index: 4;
    left: 0
}

.wizard_horizontal ul.wizard_steps li a.disabled .step_no {
    background: #ccc
}

.wizard_horizontal ul.wizard_steps li a .step_no {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100px;
    display: block;
    margin: 0 auto 5px;
    font-size: 16px;
    text-align: center;
    position: relative;
    z-index: 5
}

.wizard_horizontal ul.wizard_steps li a.selected:before,
.step_no {
    background: #34495E;
    color: #fff
}

.wizard_horizontal ul.wizard_steps li a.done:before,
.wizard_horizontal ul.wizard_steps li a.done .step_no {
    background: #1ABB9C;
    color: #fff
}

.wizard_horizontal ul.wizard_steps li:first-child a:before {
    left: 50%
}

.wizard_horizontal ul.wizard_steps li:last-child a:before {
    right: 50%;
    width: 50%;
    left: auto
}

.wizard_verticle .stepContainer {
    width: 80%;
    float: left;
    padding: 0 10px
}

.actionBar {
    width: 100%;
    border-top: 1px solid #ddd;
    padding: 10px 5px;
    text-align: right;
    margin-top: 10px
}

.actionBar .buttonDisabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none
}

.actionBar a {
    margin: 0 3px
}

.wizard_verticle .wizard_content {
    width: 80%;
    float: left;
    padding-left: 20px
}

.wizard_verticle ul.wizard_steps {
    display: table;
    list-style: none;
    position: relative;
    width: 20%;
    float: left;
    margin: 0 0 20px
}

.wizard_verticle ul.wizard_steps li {
    display: list-item;
    text-align: center
}

.wizard_verticle ul.wizard_steps li a {
    height: 80px
}

.wizard_verticle ul.wizard_steps li a:first-child {
    margin-top: 20px
}

.wizard_verticle ul.wizard_steps li a,
.wizard_verticle ul.wizard_steps li:hover {
    display: block;
    position: relative;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
    opacity: 1;
    color: #666
}

.wizard_verticle ul.wizard_steps li a:before {
    content: "";
    position: absolute;
    height: 100%;
    background: #ccc;
    top: 20px;
    width: 4px;
    z-index: 4;
    left: 49%
}

.wizard_verticle ul.wizard_steps li a.disabled .step_no {
    background: #ccc
}

.wizard_verticle ul.wizard_steps li a .step_no {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100px;
    display: block;
    margin: 0 auto 5px;
    font-size: 16px;
    text-align: center;
    position: relative;
    z-index: 5
}

.wizard_verticle ul.wizard_steps li a.selected:before,
.step_no {
    background: #34495E;
    color: #fff
}

.wizard_verticle ul.wizard_steps li a.done:before,
.wizard_verticle ul.wizard_steps li a.done .step_no {
    background: #1ABB9C;
    color: #fff
}

.wizard_verticle ul.wizard_steps li:first-child a:before {
    left: 49%
}

.wizard_verticle ul.wizard_steps li:last-child a:before {
    left: 49%;
    left: auto;
    width: 0
}

.form_wizard .loader {
    display: none
}

.form_wizard .msgBox {
    display: none
}

.progress {
    border-radius: 0
}

.progress-bar-info {
    background-color: #3498DB
}

.progress-bar-success {
    background-color: #26B99A
}

.progress_summary .progress {
    margin: 5px 0 12px !important
}

.progress_summary .row {
    margin-bottom: 5px
}

.progress_summary .row .col-xs-2 {
    padding: 0
}

.progress_summary .more_info span {
    text-align: right;
    float: right
}

.progress_summary .data span {
    text-align: right;
    float: right
}

.progress_summary p {
    margin-bottom: 3px;
    width: 100%
}

.progress_title .left {
    float: left;
    text-align: left
}

.progress_title .right {
    float: right;
    text-align: right;
    font-weight: 300
}

.progress.progress_sm {
    border-radius: 0;
    margin-bottom: 18px;
    height: 10px !important
}

.progress.progress_sm .progress-bar {
    height: 10px !important
}

.dashboard_graph p {
    margin: 0 0 4px
}

ul.verticle_bars {
    width: 100%
}

ul.verticle_bars li {
    width: 23%;
    height: 200px;
    margin: 0
}

.progress.vertical.progress_wide {
    width: 35px
}

.alert-success {
    color: #ffffff;
    background-color: rgba(38, 185, 154, 0.88);
    border-color: rgba(38, 185, 154, 0.88)
}

.alert-info {
    color: #E9EDEF;
    background-color: rgba(52, 152, 219, 0.88);
    border-color: rgba(52, 152, 219, 0.88)
}

.alert-warning {
    color: #E9EDEF;
    background-color: rgba(243, 156, 18, 0.88);
    border-color: rgba(243, 156, 18, 0.88)
}

.alert-danger,
.alert-error {
    color: #E9EDEF;
    background-color: rgba(231, 76, 60, 0.88);
    border-color: rgba(231, 76, 60, 0.88)
}

.ui-pnotify.dark .ui-pnotify-container {
    color: #E9EDEF;
    background-color: rgba(52, 73, 94, 0.88);
    border-color: rgba(52, 73, 94, 0.88)
}

.custom-notifications {
    position: fixed;
    margin: 15px;
    right: 0;
    float: right;
    width: 400px;
    z-index: 4000;
    bottom: 0
}

ul.notifications {
    float: right;
    display: block;
    margin-bottom: 7px;
    padding: 0;
    width: 100%
}

.notifications li {
    float: right;
    margin: 3px;
    width: 36px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3)
}

.notifications li:last-child {
    margin-left: 0
}

.notifications a {
    display: block;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    padding: 9px 8px
}

.tabbed_notifications .text {
    padding: 5px 15px;
    height: 140px;
    border-radius: 7px;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3)
}

.tabbed_notifications div p {
    display: inline-block
}

.tabbed_notifications h2 {
    font-weight: bold;
    text-transform: uppercase;
    width: 80%;
    float: left;
    height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block
}

.tabbed_notifications .close {
    padding: 5px;
    color: #E9EDEF;
    float: right;
    opacity: 1
}

.fc-state-default {
    background: #f5f5f5;
    color: #73879C
}

.fc-state-down,
.fc-state-active {
    color: #333;
    background: #ccc
}

.dropzone {
    min-height: 300px;
    border: 1px solid #e5e5e5
}

.main_menu .label {
    line-height: 11px;
    margin-top: 4px
}


/* // */
/* // Utility classes */
/* // -------------------------------------------------- */
/* // Floats */
/* // ------------------------- */


.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

/* // Toggling content */
/* // ------------------------- */
/* // Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1 */
.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.invisible {
    visibility: hidden;
}



/* // Hide from screenreaders and browsers */
/* // */
/* // Credit: HTML5 Boilerplate */
.hidden {
    display: none !important;
}

/* // For Affix plugin */
/* // ------------------------- */
.affix {
    position: fixed;
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
    display: table;
    content: " ";
}

.btn-group-vertical>.btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
    clear: both;
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
    display: table;
    content: " ";
}

.col-md-3.left_col {
    min-height: 100%;
    width: 230px;
    padding: 0;
    position: absolute;
    display: flex;
    z-index: 1;
}

.col-md-4 {
    width: 33.33333333%;
}

.open>.dropdown-menu {
    display: block;
}

.dropdown-menu {
    position: absolute;
    background: #fff;
    margin-top: 0;
    border: 1px solid #D9DEE4;

    right: 0;
    left: auto;
    width: 220px;
}

@media (min-width: 768px) {
    .dropdown-menu {
        right: 0;
        left: auto;
    }
}

.dropdown-menu li {
    width: 100%;
}

@media (min-width: 480px) {
    .navbar-right li {
        position: relative;
    }
}

.dropdown-menu {
    border: medium none;
    box-shadow: none;
    display: none;
    /* float: left; */
    font-size: 12px;
    left: 0;
    list-style: none outside none;
    padding: 0;
    position: absolute;
    text-shadow: none;
    top: 100%;
    z-index: 9998;
    border: 1px solid #D9DEE4;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.dropdown-menu li a {
    width: 100%;
    padding: 12px 20px;
}

.dropdown-menu>li>a {
    color: #5A738E;
}

.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-menu {
    top: 34px !important;
}

.dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

@media (min-width: 768px) {
    .dropdown-menu {
        right: 0;
        left: auto;
    }
}

.col_clear {
    margin-bottom: 15px;
}

.jambo_table tbody tr td {
    padding: 8px !important;
}

/* Wait me */
/*
waitMe - 1.19 [31.10.17]
Author: vadimsva
Github: https://github.com/vadimsva/waitMe
*/
.waitMe_container:not(body),
body.waitMe_container .waitMe {
    transform: translateZ(0);
    backface-visibility: hidden
}

body.waitMe_container .waitMe {
    position: fixed;
    height: 100vh
}

body.waitMe_container .waitMe .waitMe_content {
    top: 50% !important;
    transform: none !important
}

.waitMe_container .waitMe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9989;
    text-align: center;
    overflow: hidden;
    cursor: wait
}

.waitMe_container .waitMe * {
    font-family: inherit;
    font-size: inherit;
    font-weight: initial;
    font-style: initial;
    line-height: initial;
    color: initial;
    text-decoration: initial;
    text-transform: initial;
    padding: initial;
    margin: initial
}

.waitMe_container .waitMe .waitMe_content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    backface-visibility: hidden
}

.waitMe_container .waitMe .waitMe_content.horizontal>.waitMe_progress {
    display: inline-block;
    vertical-align: middle
}

.waitMe_container .waitMe .waitMe_content.horizontal>.waitMe_text {
    display: inline-block;
    margin: 0 0 0 20px;
    vertical-align: middle
}

.waitMe_container .waitMe .waitMe_progress {
    position: relative;
    font-size: 0;
    line-height: 0
}

.waitMe_container .waitMe .waitMe_progress>div {
    animation-fill-mode: both;
    display: inline-block;
    transform: translateZ(0);
    backface-visibility: hidden
}

.waitMe_container .waitMe .waitMe_text {
    position: relative;
    margin: 20px 0 0
}

body.waitMe_body {
    overflow: hidden;
    height: 100%
}

body.waitMe_body.hideMe {
    transition: opacity .2s ease-in-out;
    opacity: 0
}

body.waitMe_body .waitMe_container:not([data-waitme_id]) {
    position: fixed;
    z-index: 9989;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff
}

body.waitMe_body .waitMe_container:not([data-waitme_id])>div {
    animation-fill-mode: both;
    position: absolute
}

body.waitMe_body .waitMe_container.progress>div {
    width: 0;
    height: 3px;
    top: 0;
    left: 0;
    background: #000;
    box-shadow: -5px 0 5px 2px rgba(0, 0, 0, .2);
    animation: progress_body 7s infinite ease-out
}

body.waitMe_body .waitMe_container.working>div {
    width: 10%;
    height: 3px;
    top: 0;
    left: -10%;
    background: #000;
    box-shadow: -5px 0 5px 2px rgba(0, 0, 0, .2);
    animation: working_body 2s infinite linear
}

body.waitMe_body .waitMe_container.progress>div:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 60%;
    right: 0;
    width: 60px;
    border-radius: 50%;
    opacity: .5;
    transform: rotate(3deg);
    box-shadow: #000 1px 0 6px 1px
}

body.waitMe_body .waitMe_container.img>div {
    width: 100%;
    height: 100%;
    text-align: center;
    background-position: center !important;
    background-repeat: no-repeat !important
}

body.waitMe_body .waitMe_container.text>div {
    width: 100%;
    top: 45%;
    text-align: center
}

@keyframes progress_body {
    0% {
        width: 0
    }

    100% {
        width: 100%
    }
}

@keyframes working_body {
    0% {
        left: -10%
    }

    100% {
        left: 100%
    }
}

.waitMe_container .waitMe_progress.bounce>div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out
}

.waitMe_container .waitMe_progress.bounce .waitMe_progress_elem1 {
    animation-delay: -.32s
}

.waitMe_container .waitMe_progress.bounce .waitMe_progress_elem2 {
    animation-delay: -.16s
}

@keyframes bounce {

    0%,
    100%,
    80% {
        transform: scale(0)
    }

    40% {
        transform: scale(1)
    }
}

.waitMe_container .waitMe_progress.rotateplane>div {
    width: 30px;
    height: 30px;
    animation: rotateplane 1.2s infinite ease-in-out;
    backface-visibility: visible
}

@keyframes rotateplane {
    0% {
        transform: perspective(120px)
    }

    50% {
        transform: perspective(120px) rotateY(180deg)
    }

    100% {
        transform: perspective(120px) rotateY(180deg) rotateX(180deg)
    }
}

.waitMe_container .waitMe_progress.stretch {
    height: 60px
}

.waitMe_container .waitMe_progress.stretch>div {
    width: 1px;
    height: 100%;
    margin: 0 4px;
    animation: stretch 1.2s infinite ease-in-out
}

.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem2 {
    animation-delay: -1.1s
}

.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem3 {
    animation-delay: -1s
}

.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem4 {
    animation-delay: -.9s
}

.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem5 {
    animation-delay: -.8s
}

@keyframes stretch {

    0%,
    100%,
    40% {
        transform: scaleY(.4)
    }

    20% {
        transform: scaleY(1)
    }
}

.waitMe_container .waitMe_progress.orbit {
    width: 40px;
    height: 40px;
    margin: auto;
    position: relative
}

.waitMe_container .waitMe_progress.orbit>div {
    width: 100%;
    height: 100%;
    margin: auto;
    top: 0;
    left: 0;
    position: absolute;
    animation: orbit_rotate 2s infinite linear
}

.waitMe_container .waitMe_progress.orbit>div>div {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    top: 10%;
    left: 10%;
    position: absolute;
    animation: orbit 2s infinite ease-in-out
}

.waitMe_container .waitMe_progress.orbit .waitMe_progress_elem2>div {
    top: auto;
    bottom: 10%;
    left: auto;
    right: 10%;
    animation-delay: -1s
}

@keyframes orbit_rotate {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes orbit {

    0%,
    100% {
        transform: scale(0)
    }

    50% {
        transform: scale(1)
    }
}

.waitMe_container .waitMe_progress.roundBounce {
    width: 60px;
    height: 60px;
    margin: auto
}

.waitMe_container .waitMe_progress.roundBounce>div {
    width: 24%;
    height: 24%;
    border-radius: 50%;
    position: absolute;
    animation: roundBounce 1.2s infinite ease-in-out
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem1 {
    top: 0;
    left: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem2 {
    top: 0;
    right: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem3 {
    bottom: 0;
    right: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem4 {
    bottom: 0;
    left: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem5 {
    top: -3%;
    left: 50%;
    margin-top: -12%;
    margin-left: -12%;
    animation-delay: -1.1s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem6 {
    top: 50%;
    right: -3%;
    margin-top: -12%;
    margin-right: -12%
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem7 {
    bottom: -3%;
    left: 50%;
    margin-bottom: -12%;
    margin-left: -12%
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem8 {
    top: 50%;
    left: -3%;
    margin-top: -12%;
    margin-left: -12%
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem9 {
    top: 0;
    right: 0;
    animation-delay: -1s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem2 {
    animation-delay: -.9s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem6 {
    animation-delay: -.8s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem10 {
    bottom: 0;
    right: 0;
    animation-delay: -.7s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem3 {
    animation-delay: -.6s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem7 {
    animation-delay: -.5s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem11 {
    bottom: 0;
    left: 0;
    animation-delay: -.4s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem4 {
    animation-delay: -.3s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem8 {
    animation-delay: -.2s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem12 {
    top: 0;
    left: 0;
    animation-delay: -.1s
}

@keyframes roundBounce {

    0%,
    100%,
    80% {
        transform: scale(0)
    }

    40% {
        transform: scale(1)
    }
}

.waitMe_container .waitMe_progress.win8 {
    width: 40px;
    height: 40px;
    margin: auto
}

.waitMe_container .waitMe_progress.win8>div {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    transform: rotate(225deg);
    animation: win8 5.5s infinite
}

.waitMe_container .waitMe_progress.win8>div>div {
    width: 15%;
    height: 15%;
    border-radius: 50%;
    position: absolute
}

.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem2 {
    animation-delay: .24s
}

.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem3 {
    animation-delay: .48s
}

.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem4 {
    animation-delay: .72s
}

.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem5 {
    animation-delay: .96s
}

@keyframes win8 {
    0% {
        transform: rotate(225deg);
        animation-timing-function: ease-out
    }

    7% {
        opacity: 1;
        transform: rotate(345deg);
        animation-timing-function: linear
    }

    30% {
        transform: rotate(455deg);
        animation-timing-function: ease-in-out
    }

    39% {
        transform: rotate(690deg);
        animation-timing-function: linear
    }

    70% {
        opacity: 1;
        transform: rotate(815deg);
        animation-timing-function: ease-out
    }

    75% {
        transform: rotate(945deg);
        animation-timing-function: ease-out
    }

    100%,
    76% {
        opacity: 0;
        transform: rotate(945deg)
    }
}

.waitMe_container .waitMe_progress.win8_linear {
    margin: auto;
    width: 150px;
    height: 6px
}

.waitMe_container .waitMe_progress.win8_linear>div {
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    animation: win8_linear 3s infinite
}

.waitMe_container .waitMe_progress.win8_linear>div>div {
    width: 4%;
    height: 100%;
    border-radius: 50%
}

.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem2 {
    animation-delay: .3s
}

.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem3 {
    animation-delay: .6s
}

.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem4 {
    animation-delay: .9s
}

.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem5 {
    animation-delay: 1.2s
}

@keyframes win8_linear {

    0%,
    100% {
        transform: translateX(0);
        animation-timing-function: ease-out
    }

    10% {
        opacity: 1;
        transform: translateX(33.333%);
        animation-timing-function: linear
    }

    50% {
        opacity: 1;
        transform: translateX(53.333%);
        animation-timing-function: ease-in-out
    }

    60% {
        opacity: 0;
        transform: translateX(86.666%)
    }
}

.waitMe_container .waitMe_progress.ios {
    margin: auto;
    width: 40px;
    height: 40px
}

.waitMe_container .waitMe_progress.ios>div {
    width: 10%;
    height: 26%;
    position: absolute;
    left: 44.5%;
    top: 37%;
    opacity: 0;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    animation: ios 1s infinite linear
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem1 {
    transform: rotate(0) translate(0, -142%);
    animation-delay: 0s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem2 {
    transform: rotate(30deg) translate(0, -142%);
    animation-delay: -.9167s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem3 {
    transform: rotate(60deg) translate(0, -142%);
    animation-delay: -.833s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem4 {
    transform: rotate(90deg) translate(0, -142%);
    animation-delay: -.75s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem5 {
    transform: rotate(120deg) translate(0, -142%);
    animation-delay: -.667s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem6 {
    transform: rotate(150deg) translate(0, -142%);
    animation-delay: -.5833s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem7 {
    transform: rotate(180deg) translate(0, -142%);
    animation-delay: -.5s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem8 {
    transform: rotate(210deg) translate(0, -142%);
    animation-delay: -.41667s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem9 {
    transform: rotate(240deg) translate(0, -142%);
    animation-delay: -.333s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem10 {
    transform: rotate(270deg) translate(0, -142%);
    animation-delay: -.25s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem11 {
    transform: rotate(300deg) translate(0, -142%);
    animation-delay: -.1667s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem12 {
    transform: rotate(330deg) translate(0, -142%);
    animation-delay: -.0833s
}

@keyframes ios {
    0% {
        opacity: 1
    }

    100% {
        opacity: .25
    }
}

.waitMe_container .waitMe_progress.facebook {
    margin: auto
}

.waitMe_container .waitMe_progress.facebook>div {
    width: 6px;
    height: 25px;
    margin-left: 3px;
    border-radius: 20px;
    transform: scaleY(.7);
    opacity: .1;
    animation: facebook 1.3s infinite ease-in-out
}

.waitMe_container .waitMe_progress.facebook>.waitMe_progress_elem1 {
    animation-delay: .2s;
    transform: scaleY(.7)
}

.waitMe_container .waitMe_progress.facebook>.waitMe_progress_elem2 {
    animation-delay: .4s;
    transform: scaleY(.85)
}

.waitMe_container .waitMe_progress.facebook>.waitMe_progress_elem3 {
    animation-delay: .6s;
    transform: scaleY(1)
}

@keyframes facebook {

    0%,
    100% {
        transform: scaleY(.7);
        opacity: .1
    }

    50% {
        transform: scaleY(1);
        opacity: 1
    }
}

.waitMe_container .waitMe_progress.rotation>div {
    width: 60px;
    height: 60px;
    margin: auto;
    border-radius: 100%;
    border-width: 6px;
    border-style: solid;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    animation: rotation 1s infinite linear
}

@keyframes rotation {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.waitMe_container .waitMe_progress.timer {
    width: 40px;
    height: 40px;
    margin: auto;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    text-indent: -9999px
}

.waitMe_container .waitMe_progress.timer>.waitMe_progress_elem1,
.waitMe_container .waitMe_progress.timer>.waitMe_progress_elem2 {
    border-radius: 3px;
    position: absolute;
    width: 2px;
    height: 48%;
    left: 50%;
    top: 50%;
    margin-left: -1px;
    margin-top: -1px;
    animation: timer 1.25s infinite linear;
    transform-origin: 1px 1px
}

.waitMe_container .waitMe_progress.timer>.waitMe_progress_elem2 {
    height: 40%;
    animation: timer 15s infinite linear
}

@keyframes timer {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.waitMe_container .waitMe_progress.pulse {
    width: 30px;
    height: 30px;
    margin: auto;
    position: relative
}

.waitMe_container .waitMe_progress.pulse>div {
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    animation: pulsate 1s infinite ease-out
}

@keyframes pulsate {
    0% {
        transform: scale(.1);
        opacity: 0
    }

    50% {
        opacity: 1
    }

    100% {
        transform: scale(1.2);
        opacity: 0
    }
}

.waitMe_container .waitMe_progress.progressBar {
    width: 200px;
    height: 20px;
    margin: auto;
    background: rgba(0, 0, 0, .1);
    padding: 5px;
    border-radius: 20px;
    line-height: 0;
    max-width: 100%
}

.waitMe_container .waitMe_progress.progressBar>div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    background-size: 50px 50px;
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, .3), inset 0 -2px 6px rgba(0, 0, 0, .4);
    background-image: linear-gradient(-45deg, rgba(240, 240, 240, .4) 26%, transparent 25%, transparent 51%, rgba(240, 240, 240, .4) 50%, rgba(240, 240, 240, .4) 76%, transparent 75%, transparent);
    animation: progressBar 2s linear infinite
}

@keyframes progressBar {
    0% {
        background-position: 0 0
    }

    100% {
        background-position: 50px 50px
    }
}

.waitMe_container .waitMe_progress.bouncePulse>div {
    width: 20px;
    height: 20px;
    margin-right: 1px;
    display: inline-block;
    border-radius: 50%;
    transform: scale(.5);
    animation: bouncePulse 1.4s infinite ease-in-out
}

.waitMe_container .waitMe_progress.bouncePulse>.waitMe_progress_elem1,
.waitMe_container .waitMe_progress.bouncePulse>.waitMe_progress_elem3 {
    animation-delay: .1s
}

.waitMe_container .waitMe_progress.bouncePulse>.waitMe_progress_elem2 {
    animation-delay: .4s
}

@keyframes bouncePulse {

    0%,
    100%,
    80% {
        transform: scale(.5)
    }

    40% {
        transform: scale(1)
    }
}