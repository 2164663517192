.top-sprite,
.login-wrap li.user,
.account-wrap .account,
.account-wrap .account.select,
.language,
.menu .casino:after,
.setting-wrap .casino:after,
.menu .bet-game:after,
.setting-wrap .bet-game:after,
.menu .tag-new:before,
.setting-wrap .tag-new:before,
.live-menu img,
.one_click img,
.one_click.select img,
.col-edit img,
.o_12bet .login-wrap li.user,
.o_12bet .account-wrap .head-refresh a,
.o_12bet .account-wrap .account,
.o_12bet .one_click img,
.o_12bet .one_click.select img,
.o_dafabet .login-wrap li.user,
.o_dafabet .account-wrap .head-refresh a,
.o_dafabet .account-wrap .account,
.o_dafabet .one_click img,
.o_dafabet .one_click.select img,
.mazaplay .login-wrap li.user,
.mazaplay .account-wrap .head-refresh a,
.mazaplay .account-wrap .account,
.mazaplay .one_click img,
.mazaplay .one_click.select img,
.mysports247 .login-wrap li.user,
.mysports247 .account-wrap .head-refresh a,
.mysports247 .account-wrap .account,
.mysports247 .one_click img,
.mysports247 .one_click.select img,
.bdtwin .login-wrap li.user,
.bdtwin .account-wrap .head-refresh a,
.bdtwin .account-wrap .account,
.bdtwin .one_click img,
.bdtwin .one_click.select img,
.o_spondemo .language,
.o_spondemo .login-wrap li.user,
.o_spondemo .account-wrap .head-refresh a,
.o_spondemo .account-wrap .account,
.o_spondemo .one_click img,
.o_spondemo .one_click.select img,
.skyinplay .login-wrap li.user,
.skyinplay .account-wrap .head-refresh a,
.skyinplay .account-wrap .account,
.skyinplay .live-menu img,
.skyinplay .one_click img,
.skyinplay .one_click.select img,
.bigexch .login-wrap li.user,
.bigexch .account-wrap .head-refresh a,
.bigexch .account-wrap .account,
.bigexch .live-menu img,
.bigexch .one_click img,
.bigexch .one_click.select img,
.masterexchange .login-wrap li.user,
.masterexchange .account-wrap .head-refresh a,
.masterexchange .account-wrap .account,
.masterexchange .live-menu img,
.masterexchange .one_click img,
.masterexchange .one_click.select img,
.matador .login-wrap li.user,
.matador .account-wrap .head-refresh a,
.matador .account-wrap .account,
.matador .live-menu img,
.matador .one_click img,
.matador .one_click.select img,
.fairenter .login-wrap li.user,
.fairenter .account-wrap .head-refresh a,
.fairenter .account-wrap .account,
.fairenter .one_click img,
.fairenter .one_click.select img,
.lucky7 .login-wrap li.user,
.lucky7 .account-wrap .head-refresh a,
.lucky7 .account-wrap .account,
.lucky7 .one_click img,
.lucky7 .one_click.select img,
.qexch .login-wrap li.user,
.qexch .account-wrap .head-refresh a,
.qexch .account-wrap .account,
.qexch .one_click img,
.qexch .one_click.select img,
.betmygame .login-wrap li.user,
.betmygame .account-wrap .head-refresh a,
.betmygame .account-wrap .account,
.betmygame .one_click img,
.betmygame .one_click.select img,
.fancyfair .login-wrap li.user,
.fancyfair .account-wrap .head-refresh a,
.fancyfair .account-wrap .account,
.fancyfair .one_click img,
.fancyfair .one_click.select img,
.maruti .login-wrap li.user,
.maruti .account-wrap .head-refresh a,
.maruti .account-wrap .account,
.maruti .one_click img,
.maruti .one_click.select img,
.marutibook .login-wrap li.user,
.marutibook .account-wrap .head-refresh a,
.marutibook .account-wrap .account,
.marutibook .one_click img,
.marutibook .one_click.select img,
.bdtwin247 .login-wrap li.user,
.bdtwin247 .account-wrap .head-refresh a,
.bdtwin247 .account-wrap .account,
.bdtwin247 .one_click img,
.bdtwin247 .one_click.select img,
.oceanexch1 .login-wrap li.user,
.oceanexch1 .account-wrap .head-refresh a,
.oceanexch1 .account-wrap .account,
.oceanbook1 .login-wrap li.user,
.oceanbook1 .account-wrap .head-refresh a,
.oceanbook1 .account-wrap .account,
.probetx .login-wrap li.user,
.probetx .account-wrap .head-refresh a,
.probetx .account-wrap .account,
.probetx .live-menu img,
.probetx .one_click img,
.probetx .one_click.select img,
.alphaexch .login-wrap li.user,
.alphaexch .account-wrap .head-refresh a,
.alphaexch .account-wrap .account,
.alphaexch .one_click img,
.alphaexch .one_click.select img,
.alphaexchlive .login-wrap li.user,
.alphaexchlive .account-wrap .head-refresh a,
.alphaexchlive .account-wrap .account,
.alphaexchlive .one_click img,
.alphaexchlive .one_click.select img,
.maxexch9 .login-wrap li.user,
.maxexch9 .account-wrap .head-refresh a,
.maxexch9 .account-wrap .account,
.maxexch9 .one_click img,
.maxexch9 .one_click.select img,
.cftbet365 .login-wrap li.user,
.cftbet365 .account-wrap .head-refresh a,
.cftbet365 .account-wrap .account,
.cftbet365 .live-menu img,
.cftbet365 .one_click img,
.cftbet365 .one_click.select img,
.crickzoom .login-wrap li.user,
.crickzoom .account-wrap .head-refresh a,
.crickzoom .account-wrap .account,
.crickzoom .live-menu img,
.crickzoom .one_click img,
.crickzoom .one_click.select img,
.crickzoomlive .login-wrap li.user,
.crickzoomlive .account-wrap .head-refresh a,
.crickzoomlive .account-wrap .account,
.crickzoomlive .live-menu img,
.crickzoomlive .one_click img,
.crickzoomlive .one_click.select img,
.maxinplay .login-wrap li.user,
.maxinplay .account-wrap .head-refresh a,
.maxinplay .account-wrap .account,
.maxinplay .one_click img,
.maxinplay .one_click.select img,
.betbarter .login-wrap li.user,
.betbarter .account-wrap .head-refresh a,
.betbarter .account-wrap .account,
.betbarter .one_click img,
.betbarter .one_click.select img,
.probet247 .login-wrap li.user,
.probet247 .account-wrap .head-refresh a,
.probet247 .account-wrap .account,
.probet247 .live-menu img,
.probet247 .one_click img,
.probet247 .one_click.select img,
.goexchange .login-wrap li.user,
.goexchange .account-wrap .head-refresh a,
.goexchange .account-wrap .account,
.goexchange .one_click img,
.goexchange .one_click.select img,
.kingfair24 .login-wrap li.user,
.kingfair24 .account-wrap .head-refresh a,
.kingfair24 .account-wrap .account,
.kingfair24 .one_click img,
.kingfair24 .one_click.select img,
.sgexch247 .login-wrap li.user,
.sgexch247 .account-wrap .head-refresh a,
.sgexch247 .account-wrap .account,
.sgexch247 .live-menu img,
.sgexch247 .one_click img,
.sgexch247 .one_click.select img,
.sixball .login-wrap li.user,
.sixball .account-wrap .head-refresh a,
.sixball .account-wrap .account,
.sixball .live-menu img,
.sixball .one_click img,
.sixball .one_click.select img,
.sixballio .login-wrap li.user,
.sixballio .account-wrap .head-refresh a,
.sixballio .account-wrap .account,
.sixballio .live-menu img,
.sixballio .one_click img,
.sixballio .one_click.select img,
.wickets247 .login-wrap li.user,
.wickets247 .account-wrap .head-refresh a,
.wickets247 .account-wrap .account,
.wickets247 .one_click img,
.wickets247 .one_click.select img,
.crickex .login-wrap li.user,
.crickex .account-wrap .head-refresh a,
.crickex .account-wrap .account,
.crickex .one_click img,
.crickex .one_click.select img,
.iceEX .login-wrap li.user,
.iceEX .account-wrap .head-refresh a,
.iceEX .account-wrap .account,
.iceEX .one_click img,
.iceEX .one_click.select img,
.iceinplay .login-wrap li.user,
.iceinplay .account-wrap .head-refresh a,
.iceinplay .account-wrap .account,
.iceinplay .one_click img,
.iceinplay .one_click.select img,
.dhoom .login-wrap li.user,
.dhoom .account-wrap .head-refresh a,
.dhoom .account-wrap .account,
.dhoom .one_click img,
.dhoom .one_click.select img,
.baji .login-wrap li.user,
.baji .account-wrap .head-refresh a,
.baji .account-wrap .account,
.baji .live-menu img,
.baji .one_click img,
.baji .one_click.select img,
.agexch247 .login-wrap li.user,
.agexch247 .account-wrap .head-refresh a,
.agexch247 .account-wrap .account,
.agexch247 .one_click img,
.agexch247 .one_click.select img,
.baji365 .login-wrap li.user,
.baji365 .account-wrap .head-refresh a,
.baji365 .account-wrap .account,
.baji365 .live-menu img,
.baji365 .one_click img,
.baji365 .one_click.select img,
.sportsexchlive .login-wrap li.user,
.sportsexchlive .account-wrap .head-refresh a,
.sportsexchlive .account-wrap .account,
.sportsexchlive .one_click img,
.sportsexchlive .one_click.select img {
    background-image: url("../images/sprite/top-s5f29df32a5.png");
    background-repeat: no-repeat
}

.flag-sprite,
.flag.UK {
    background-image: url("../images/sprite/flag-sd4ad5b971b.png");
    background-repeat: no-repeat
}

.left-sprite,
.top_player-wrap .to-expand,
.racing-wrap .to-expand,
.game-wrap .to-expand,
.game-highlight-wrap .to-expand,
.favor-wrap .to-expand,
.sub_path .to-expand,
.pop-title .to-expand,
.close.top_player-wrap .to-expand,
.close.racing-wrap .to-expand,
.close.game-wrap .to-expand,
.close.game-highlight-wrap .to-expand,
.close.favor-wrap .to-expand,
.close.sub_path .to-expand,
.close.pop-title .to-expand,
.menu-list .Go,
.star,
.favor-wrap h3 .star,
.favor-wrap .menu-list a.btn-star,
.favor-set,
.path li,
.path .path-back,
.path .path-back.select,
.path-pop a,
.to-go,
.o_12bet .path .path-back,
.o_12bet .path .path-back.select,
.o_12bet .path-pop a,
.o_dafabet .path .path-back,
.o_dafabet .path .path-back.select,
.o_dafabet .path-pop a,
.mazaplay .menu-list .Go,
.mazaplay .path .path-back,
.mazaplay .path .path-back.select,
.mysports247 .menu-list .Go,
.mysports247 .path .path-back,
.mysports247 .path .path-back.select,
.bdtwin .menu-list .Go,
.bdtwin .path .path-back,
.bdtwin .path .path-back.select,
.o_spondemo .path .path-back,
.o_spondemo .path .path-back.select,
.o_spondemo .path-pop a,
.skyinplay .menu-list .Go,
.skyinplay .path .path-back,
.skyinplay .path .path-back.select,
.bigexch .menu-list .Go,
.bigexch .path .path-back,
.bigexch .path .path-back.select,
.masterexchange .menu-list .Go,
.masterexchange .path .path-back,
.masterexchange .path .path-back.select,
.masterexchange .path-pop a,
.matador .menu-list .Go,
.matador .path li,
.matador .path .path-back,
.matador .path .path-back.select,
.matador .path-pop a,
.fairenter .menu-list .Go,
.fairenter .path .path-back,
.fairenter .path .path-back.select,
.lucky7 .menu-list .Go,
.lucky7 .path .path-back,
.lucky7 .path .path-back.select,
.qexch .menu-list .Go,
.qexch .path .path-back,
.qexch .path .path-back.select,
.qexch .path-pop a,
.betmygame .menu-list .Go,
.betmygame .path .path-back,
.betmygame .path .path-back.select,
.betmygame .path-pop a,
.fancyfair .menu-list .Go,
.fancyfair .path .path-back,
.fancyfair .path .path-back.select,
.fancyfair .path-pop a,
.maruti .menu-list .Go,
.maruti .path .path-back,
.maruti .path .path-back.select,
.marutibook .menu-list .Go,
.marutibook .path .path-back,
.marutibook .path .path-back.select,
.bdtwin247 .menu-list .Go,
.bdtwin247 .path .path-back,
.bdtwin247 .path .path-back.select,
.oceanexch1 .menu-list .Go,
.oceanexch1 .path .path-back,
.oceanexch1 .path .path-back.select,
.oceanexch1 .path-pop a,
.oceanbook1 .menu-list .Go,
.oceanbook1 .path .path-back,
.oceanbook1 .path .path-back.select,
.oceanbook1 .path-pop a,
.probetx .menu-list .Go,
.probetx .path .path-back,
.probetx .path .path-back.select,
.alphaexch .menu-list .Go,
.alphaexch .path .path-back,
.alphaexch .path .path-back.select,
.alphaexchlive .menu-list .Go,
.alphaexchlive .path .path-back,
.alphaexchlive .path .path-back.select,
.maxexch9 .menu-list .Go,
.maxexch9 .path .path-back,
.maxexch9 .path .path-back.select,
.cftbet365 .menu-list .Go,
.cftbet365 .path .path-back,
.cftbet365 .path .path-back.select,
.crickzoom .menu-list .Go,
.crickzoom .path .path-back,
.crickzoom .path .path-back.select,
.crickzoomlive .menu-list .Go,
.crickzoomlive .path .path-back,
.crickzoomlive .path .path-back.select,
.maxinplay .menu-list .Go,
.maxinplay .path .path-back,
.maxinplay .path .path-back.select,
.betbarter .path .path-back,
.betbarter .path .path-back.select,
.betbarter .path-pop a,
.probet247 .menu-list .Go,
.probet247 .path .path-back,
.probet247 .path .path-back.select,
.goexchange .menu-list .Go,
.goexchange .path .path-back,
.goexchange .path .path-back.select,
.kingfair24 .menu-list .Go,
.kingfair24 .path .path-back,
.kingfair24 .path .path-back.select,
.sgexch247 .menu-list .Go,
.sgexch247 .path .path-back,
.sgexch247 .path .path-back.select,
.sixball .menu-list .Go,
.sixball .path .path-back,
.sixball .path .path-back.select,
.sixballio .menu-list .Go,
.sixballio .path .path-back,
.sixballio .path .path-back.select,
.wickets247 .menu-list .Go,
.wickets247 .path .path-back,
.wickets247 .path .path-back.select,
.crickex .menu-list .Go,
.crickex .path .path-back,
.crickex .path .path-back.select,
.iceEX .menu-list .Go,
.iceEX .path .path-back,
.iceEX .path .path-back.select,
.iceinplay .menu-list .Go,
.iceinplay .path .path-back,
.iceinplay .path .path-back.select,
.iceinplay .path-pop a,
.dhoom .menu-list .Go,
.dhoom .path .path-back,
.dhoom .path .path-back.select,
.baji .menu-list .Go,
.baji .path .path-back,
.baji .path .path-back.select,
.agexch247 .menu-list .Go,
.agexch247 .path .path-back,
.agexch247 .path .path-back.select,
.baji365 .menu-list .Go,
.baji365 .path .path-back,
.baji365 .path .path-back.select,
.sportsexchlive .menu-list .Go,
.sportsexchlive .path .path-back,
.sportsexchlive .path .path-back.select {
    background-image: url("../images/sprite/left-s87016b32f7.png");
    background-repeat: no-repeat
}

.right-sprite,
.loading img,
.delete,
.top_player-wrap .to-expand,
.racing-wrap .to-expand,
.close.top_player-wrap .to-expand,
.close.racing-wrap .to-expand,
.slip_set-pop .slip_set,
.oneclick_set-pop .slip_set,
.odd-add .up,
.odd-add .down,
.o_12bet .slip-wrap .to-expand,
.o_12bet .slip-wrap.close .to-expand,
.o_12bet .matched-wrap .to-expand,
.o_12bet .matched-wrap.close .to-expand,
.o_12bet .slip_refresh,
.o_dafabet .slip-wrap .to-expand,
.o_dafabet .slip-wrap.close .to-expand,
.o_dafabet .matched-wrap .to-expand,
.o_dafabet .matched-wrap.close .to-expand,
.o_dafabet .slip_refresh,
.o_spondemo .slip-wrap .to-expand,
.o_spondemo .slip-wrap.close .to-expand,
.o_spondemo .matched-wrap .to-expand,
.o_spondemo .matched-wrap.close .to-expand,
.o_spondemo .slip_refresh,
.o_spondemo .multi_refresh,
.betbarter .slip-wrap .to-expand,
.betbarter .slip-wrap.close .to-expand,
.betbarter .matched-wrap .to-expand,
.betbarter .matched-wrap.close .to-expand,
.betbarter .slip_refresh {
    background-image: url("../images/sprite/right-sdc390e89c4.png");
    background-repeat: no-repeat
}

.main-sprite,
.btn-close,
.icon-time,
.icon-live,
.icon-irun,
.icon-predict,
.icon-cashout,
.icon-in_play,
.icon-no_play,
.icon-cricket,
.icon-soccer,
.icon-tennis,
.icon-basketball,
.icon-dog,
.icon-HR,
.icon-racing,
.game-wrap .to-expand,
.game-highlight-wrap .to-expand,
.close.game-wrap .to-expand,
.close.game-highlight-wrap .to-expand,
.o_spondemo .game-wrap .to-expand,
.o_spondemo .game-highlight-wrap .to-expand,
.o_spondemo .close.game-wrap .to-expand,
.o_spondemo .close.game-highlight-wrap .to-expand,
.favor,
.favor.select,
.upcome-head dt,
.tab-highlight a,
.tab-highlight a.select,
.transfer-wrap .close,
.transfer-wrap-slider .close,
.login_to_go .close,
.live-list a:after,
.icon-TV_head,
.marquee-box h4:before,
.marquee-box .tc-content h5:before,
.tc-content .marquee-box h5:before,
.marquee-box h4:after,
.marquee-box .tc-content h5:after,
.tc-content .marquee-box h5:after,
.o_12bet .game-wrap .to-expand,
.o_12bet .game-wrap.close .to-expand,
.o_12bet .tab-highlight a,
.o_12bet .tab-highlight a.select,
.o_12bet .game-wrap-horse .to-expand,
.o_12bet .game-wrap-horse.close .to-expand,
.o_12bet .tab-markets a.select,
.o_12bet .add-pin h3,
.o_12bet .delete-pin h3,
.o_12bet .one_click-stake,
.o_dafabet .game-wrap .to-expand,
.o_dafabet .game-wrap.close .to-expand,
.o_dafabet .tab-highlight a,
.o_dafabet .tab-highlight a.select,
.o_dafabet .game-wrap-horse .to-expand,
.o_dafabet .game-wrap-horse.close .to-expand,
.o_dafabet .tab-markets a.select,
.o_dafabet .add-pin h3,
.o_dafabet .delete-pin h3,
.o_dafabet .one_click-stake,
.mazaplay .one_click-setting .a-edit img,
.mazaplay .one_click-stake,
.mysports247 .one_click-setting .a-edit img,
.mysports247 .one_click-stake,
.bdtwin .login_to_go .close,
.bdtwin .one_click-setting .a-edit img,
.bdtwin .one_click-stake,
.o_spondemo .tab-highlight,
.o_spondemo .tab-highlight a,
.o_spondemo .tab-highlight a.select,
.o_spondemo .tab-markets a.select,
.o_spondemo .add-pin h3,
.o_spondemo .delete-pin h3,
.o_spondemo .one_click-stake,
.skyinplay .one_click-setting .a-edit img,
.skyinplay .one_click-stake,
.bigexch .one_click-setting .a-edit img,
.bigexch .one_click-stake,
.masterexchange .login_to_go .close,
.masterexchange .one_click-setting .a-edit img,
.masterexchange .one_click-stake,
.matador .one_click-setting .a-edit img,
.matador .one_click-stake,
.fairenter .one_click-setting .a-edit img,
.fairenter .one_click-stake,
.lucky7 .one_click-setting .a-edit img,
.lucky7 .one_click-stake,
.qexch .one_click-setting .a-edit img,
.qexch .one_click-stake,
.betmygame .one_click-setting .a-edit img,
.betmygame .one_click-stake,
.fancyfair .one_click-setting .a-edit img,
.fancyfair .one_click-stake,
.maruti .one_click-setting .a-edit img,
.maruti .one_click-stake,
.marutibook .one_click-setting .a-edit img,
.marutibook .one_click-stake,
.bdtwin247 .login_to_go .close,
.bdtwin247 .one_click-setting .a-edit img,
.bdtwin247 .one_click-stake,
.probetx .one_click-setting .a-edit img,
.probetx .one_click-stake,
.alphaexch .one_click-setting .a-edit img,
.alphaexch .one_click-stake,
.alphaexchlive .one_click-setting .a-edit img,
.alphaexchlive .one_click-stake,
.maxexch9 .one_click-setting .a-edit img,
.maxexch9 .one_click-stake,
.cftbet365 .one_click-setting .a-edit img,
.cftbet365 .one_click-stake,
.crickzoom .one_click-setting .a-edit img,
.crickzoom .one_click-stake,
.crickzoomlive .one_click-setting .a-edit img,
.crickzoomlive .one_click-stake,
.maxinplay .one_click-setting .a-edit img,
.maxinplay .one_click-stake,
.betbarter .game-wrap .to-expand,
.betbarter .game-wrap.close .to-expand,
.betbarter .tab-highlight a,
.betbarter .tab-highlight a.select,
.betbarter .game-wrap-horse .to-expand,
.betbarter .game-wrap-horse.close .to-expand,
.betbarter .tab-markets a.select,
.betbarter .add-pin h3,
.betbarter .delete-pin h3,
.betbarter .one_click-stake,
.probet247 .one_click-setting .a-edit img,
.probet247 .one_click-stake,
.goexchange .login_to_go .close,
.goexchange .one_click-setting .a-edit img,
.goexchange .one_click-stake,
.kingfair24 .one_click-setting .a-edit img,
.kingfair24 .one_click-stake,
.sgexch247 .one_click-setting .a-edit img,
.sgexch247 .one_click-stake,
.sixball .one_click-setting .a-edit img,
.sixball .one_click-stake,
.sixballio .one_click-setting .a-edit img,
.sixballio .one_click-stake,
.wickets247 .login_to_go .close,
.wickets247 .one_click-setting .a-edit img,
.wickets247 .one_click-stake,
.crickex .login_to_go .close,
.crickex .one_click-setting .a-edit img,
.crickex .one_click-stake,
.iceEX .login_to_go .close,
.iceEX .one_click-setting .a-edit img,
.iceEX .one_click-stake,
.iceinplay .one_click-setting .a-edit img,
.iceinplay .one_click-stake,
.dhoom .login_to_go .close,
.dhoom .one_click-setting .a-edit img,
.dhoom .one_click-stake,
.baji .login_to_go .close,
.baji .one_click-setting .a-edit img,
.baji .one_click-stake,
.agexch247 .login_to_go .close,
.agexch247 .one_click-setting .a-edit img,
.agexch247 .one_click-stake,
.baji365 .login_to_go .close,
.baji365 .one_click-setting .a-edit img,
.baji365 .one_click-stake,
.sportsexchlive .login_to_go .close,
.sportsexchlive .one_click-setting .a-edit img,
.sportsexchlive .one_click-stake {
    background-image: url("../images/sprite/main-s1aea395e8c.png");
    background-repeat: no-repeat
}

.report-sprite,
.cal-input,
.upcome dd,
.filter-list .filter-content span,
.filter-list.golf-list .compt img,
.filter-list.golf-list .compt:hover img,
.expand-close,
.expand-open,
.align-L .expand-close,
.table01 caption .expand-close,
.table-s caption .expand-close,
.table_one caption .expand-close,
.no-data .expand-close,
.status-popup .expand-close,
.access-popup .expand-close,
.table_one thead th .expand-close,
.table-pt .expand td.expand-event:first-child .expand-close,
.pt_allowed dl dt .expand-close,
.tab-transfer .full-amount .expand-close,
.tab-transfer .remark .expand-close,
.tips-popup .expand-close,
.slip-head .col-playID .expand-close,
.slip-head .col-type .expand-close,
.slip-head .col-country .expand-close,
.slip-head .col-local .expand-close,
.table01 .td-type .expand-close,
.table-s .td-type .expand-close,
.table_one .td-type .expand-close,
.table01 .td-UID .expand-close,
.table-s .td-UID .expand-close,
.table_one .td-UID .expand-close,
.td-uid .expand-close,
.DW-amount .expand-close,
.pop_bg .form-wrap h1 .expand-close,
.align-L .expand-open,
.table01 caption .expand-open,
.table-s caption .expand-open,
.table_one caption .expand-open,
.no-data .expand-open,
.status-popup .expand-open,
.access-popup .expand-open,
.table_one thead th .expand-open,
.table-pt .expand td.expand-event:first-child .expand-open,
.pt_allowed dl dt .expand-open,
.tab-transfer .full-amount .expand-open,
.tab-transfer .remark .expand-open,
.tips-popup .expand-open,
.slip-head .col-playID .expand-open,
.slip-head .col-type .expand-open,
.slip-head .col-country .expand-open,
.slip-head .col-local .expand-open,
.table01 .td-type .expand-open,
.table-s .td-type .expand-open,
.table_one .td-type .expand-open,
.table01 .td-UID .expand-open,
.table-s .td-UID .expand-open,
.table_one .td-UID .expand-open,
.td-uid .expand-open,
.DW-amount .expand-open,
.pop_bg .form-wrap h1 .expand-open,
.expand-arrow,
.expand-arrow-R,
.acc-info .user,
.acc-info .time,
.fromto,
.prevMonth,
.nextMonth,
.selection,
.trans-replay,
.btn_expand:before,
.btn_expand.close:before {
    background-image: url("../images/sprite/report-sf227858fbc.png");
    background-repeat: no-repeat
}

.bg-sprite,
.top_player-wrap h3,
.racing-wrap h3,
.game-wrap h3,
.game-highlight-wrap h3,
.favor-wrap h3,
.sub_path h3,
.pop-title h3,
.one_click,
.one_click.select,
.path,
.upcome-head,
.tab-highlight,
.live-head h3,
.live-channel,
.change-channel,
.tab-topplay a,
.tab-topplay .select a,
.o_12bet .path,
.o_12bet .slip-wrap h3,
.o_12bet .matched-wrap h3,
.o_12bet .slider-bar span,
.o_12bet .upcome-head,
.o_12bet .tab-highlight,
.o_12bet .one_click-setting,
.o_dafabet .path,
.o_dafabet .slip-wrap h3,
.o_dafabet .matched-wrap h3,
.o_dafabet .slider-bar span,
.o_dafabet .upcome-head,
.o_dafabet .tab-highlight,
.o_dafabet .one_click-setting,
.mazaplay .tab-highlight,
.mazaplay .one_click-setting,
.mysports247 .path,
.mysports247 .tab-highlight,
.mysports247 .one_click-setting,
.bdtwin .one_click-setting,
.o_spondemo .path,
.o_spondemo .slip-wrap h3,
.o_spondemo .matched-wrap h3,
.o_spondemo .slider-bar span,
.o_spondemo .upcome-head,
.o_spondemo .one_click-setting,
.skyinplay .path,
.skyinplay .tab-highlight,
.skyinplay .one_click-setting,
.bigexch .path,
.bigexch .tab-highlight,
.bigexch .one_click-setting,
.masterexchange .path,
.masterexchange .tab-highlight,
.masterexchange .one_click-setting,
.matador .tab-highlight,
.matador .one_click-setting,
.fairenter .path,
.fairenter .tab-highlight,
.fairenter .one_click-setting,
.lucky7 .path,
.lucky7 .tab-highlight,
.lucky7 .one_click-setting,
.qexch .path,
.qexch .tab-highlight,
.qexch .one_click-setting,
.betmygame .path,
.betmygame .tab-highlight,
.betmygame .one_click-setting,
.fancyfair .one_click,
.fancyfair .one_click.select,
.fancyfair .path,
.fancyfair .tab-highlight,
.fancyfair .one_click-setting,
.maruti .tab-highlight,
.maruti .one_click-setting,
.marutibook .one_click,
.marutibook .one_click.select,
.marutibook .tab-highlight,
.marutibook .one_click-setting,
.bdtwin247 .one_click-setting,
.oceanexch1 .path,
.oceanexch1 .tab-highlight,
.oceanbook1 .path,
.oceanbook1 .tab-highlight,
.probetx .path,
.probetx .tab-highlight,
.probetx .one_click-setting,
.alphaexch .tab-highlight,
.alphaexch .one_click-setting,
.alphaexchlive .tab-highlight,
.alphaexchlive .one_click-setting,
.maxexch9 .path,
.maxexch9 .tab-highlight,
.maxexch9 .one_click-setting,
.cftbet365 .one_click,
.cftbet365 .one_click.select,
.cftbet365 .tab-highlight,
.cftbet365 .one_click-setting,
.crickzoom .path,
.crickzoom .tab-highlight,
.crickzoom .one_click-setting,
.crickzoomlive .path,
.crickzoomlive .tab-highlight,
.crickzoomlive .one_click-setting,
.maxinplay .path,
.maxinplay .tab-highlight,
.maxinplay .one_click-setting,
.betbarter .path,
.betbarter .slip-wrap h3,
.betbarter .matched-wrap h3,
.betbarter .slider-bar span,
.betbarter .upcome-head,
.betbarter .tab-highlight,
.betbarter .one_click-setting,
.probet247 .path,
.probet247 .tab-highlight,
.probet247 .one_click-setting,
.goexchange .one_click,
.kingfair24 .tab-highlight,
.kingfair24 .one_click-setting,
.sgexch247 .path,
.sgexch247 .tab-highlight,
.sgexch247 .one_click-setting,
.sixball .path,
.sixball .tab-highlight,
.sixball .one_click-setting,
.sixballio .path,
.sixballio .tab-highlight,
.sixballio .one_click-setting,
.wickets247 .one_click-setting,
.crickex .tab-highlight,
.crickex .one_click,
.crickex .one_click.select,
.crickex .one_click-setting,
.iceEX .one_click,
.iceinplay .path,
.iceinplay .tab-highlight,
.iceinplay .one_click-setting,
.baji .one_click-setting,
.baji365 .one_click-setting,
.sportsexchlive .one_click-setting {
    background-image: url("../images/sprite/bg-s2ccfda7730.png");
    background-repeat: no-repeat
}

.login-sprite,
.power-wrap h3 img,
.power-wrap-b img,
.browser-wrap img,
.mysports247 .icon-login,
.bdtwin .icon-login,
.skyinplay .icon-login,
.bigexch .icon-login,
.masterexchange .icon-login,
.fairenter .icon-login,
.qexch .icon-login,
.bdtwin247 .icon-login,
.oceanexch1 .icon-login,
.probetx .icon-login,
.maxexch9 .icon-login,
.cftbet365 .icon-login,
.crickzoom .icon-login,
.crickzoomlive .icon-login,
.probet247 .icon-login,
.goexchange .icon-login,
.kingfair24 .icon-login,
.kingfair24 .menu .logout img,
.kingfair24 .setting-wrap .logout img,
.sgexch247 .icon-login,
.sixball .icon-login,
.sixballio .icon-login,
.wickets247 .icon-login,
.crickex .icon-login,
.iceEX .icon-login,
.iceinplay .icon-login,
.baji .icon-login,
.baji365 .icon-login,
.sportsexchlive .icon-login {
    background-image: url("../images/sprite/login-s28112929a1.png");
    background-repeat: no-repeat
}

.agent-sprite,
.close_pop,
.go-back img,
.pop-close img,
.bank,
.betting_history,
.p_l,
.profile,
.status,
.total_all .btn_replay img,
.add_member img,
.status-active img,
.status-suspend img,
.status-lock img,
.table01 .ico_account img,
.table-s .ico_account img,
.table_one .ico_account img,
.table01 .status_all .icon_active img,
.table-s .status_all .icon_active img,
.table_one .status_all .icon_active img,
.table01 .status_all .icon_locked img,
.table-s .status_all .icon_locked img,
.table_one .status_all .icon_locked img,
.table01 .status_all .icon_suspend img,
.table-s .status_all .icon_suspend img,
.table_one .status_all .icon_suspend img,
.table01 .action .bank:hover,
.table-s .action .bank:hover,
.table_one .action .bank:hover,
.table01 .action .betting_history:hover,
.table-s .action .betting_history:hover,
.table_one .action .betting_history:hover,
.table01 .action .p_l:hover,
.table-s .action .p_l:hover,
.table_one .action .p_l:hover,
.table01 .action .profile:hover,
.table-s .action .profile:hover,
.table_one .action .profile:hover,
.table01 .action .status:hover,
.table-s .action .status:hover,
.table_one .action .status:hover,
.btn_open,
.btn_close,
.ico_dot1,
.ico_dot2,
.ico_dot3,
.toggle_on,
.toggle_off,
.status_but .but_active img,
.status_but .but_suspend img,
.status_but .but_locked img,
.status_but .but_active.open img,
.status_but .open.but_suspend img,
.status_but .open.but_locked img,
.status_but .but_active.disable img,
.status_but .disable.but_suspend img,
.status_but .disable.but_locked img,
.agent_path-L li,
.agent_path-L img,
.currency_tag_all li.currency_tag,
.currency_tag_all li:first-child,
.drop_down_li a,
.col-center .acc-info .user,
.tab-topplay li:before,
.tab-topplay li:after,
.tab-topplay .select:before,
.tab-topplay .select:after,
.sort-this:after,
.open-odds:before,
.close-odds:before,
.icon-date,
.agent-path li,
.agent-path .point-agent-path,
.credit-amount-member .btn,
.credit-amount-member .btn-send,
.credit-amount-member .pages a,
.pages .credit-amount-member a,
.credit-amount-member .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount-member input,
.credit-amount-member .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount-member textarea,
.credit-amount-member .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount-member a,
.credit-amount-member .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount-member a,
.credit-amount-member .total_all .btn_replay,
.total_all .credit-amount-member .btn_replay,
.credit-amount-member .btn_expand,
.credit-amount-member .add_member,
.credit-amount-member .btn:hover,
.credit-amount-member .btn-send:hover,
.credit-amount-member .pages a:hover,
.pages .credit-amount-member a:hover,
.credit-amount-member .calendarTable_inputBox input:hover,
.calendarTable_inputBox .credit-amount-member input:hover,
.credit-amount-member .calendarTable_inputBox textarea:hover,
.calendarTable_inputBox .credit-amount-member textarea:hover,
.credit-amount-member .calendarTable_inputBox .member_select a:hover,
.calendarTable_inputBox .member_select .credit-amount-member a:hover,
.credit-amount-member .member_select .calendarTable_inputBox a:hover,
.member_select .calendarTable_inputBox .credit-amount-member a:hover,
.credit-amount-member .total_all .btn_replay:hover,
.total_all .credit-amount-member .btn_replay:hover,
.credit-amount-member .btn_expand:hover,
.credit-amount-member .add_member:hover,
.btn-add:before,
.icon-select_all,
.icon-select_partial {
    background-image: url("../images/sprite/agent-sf42eb279ae.png");
    background-repeat: no-repeat
}

* {
    margin: 0;
    padding: 0
}

html,
body {
    height: 100%;
    font-family: Tahoma, Helvetica, sans-serif;
    color: #1E1E1E;
    font-size: 12px;
    line-height: 15px
}

a {
    text-decoration: none;
    color: #2789CE;
    outline: none;
    /* hlbr:expression(this.onFocus=this.blur()) */
}

a:hover {
    text-decoration: underline;
    cursor: pointer
}

table {
    border-collapse: collapse
}

input,
textarea,
.member_select a {
    font-family: Tahoma, Helvetica, sans-serif;
    color: #1E1E1E;
    font-size: 12px;
    border: 0px #aaa solid;
    background: #fff;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 5px;
    margin: 0 5px 5px 0;
    box-sizing: border-box
}

input[type=checkbox],
textarea[type=checkbox],
.member_select a[type=checkbox],
input[type=radio],
textarea[type=radio],
.member_select a[type=radio] {
    width: auto !important;
    height: auto !important;
    border-width: 0;
    border-radius: 0;
    background-color: transparent !important;
    padding: 0 !important;
    box-shadow: none
}



input:focus,
textarea:focus,
.member_select a:focus {
    padding: 4px
}

input.disable,
textarea.disable,
.member_select a.disable {
    background-color: #dcdcdc;
    color: #666;
    box-shadow: none;
    border: 1px solid #bbb
}

label {
    cursor: pointer
}

select {
    width: 120px;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
    margin: 0 5px 5px 0
}

option {
    padding: 3px
}

h2 {
    font-size: 13px;
    line-height: 20px;
    padding-top: 6px;
    margin-bottom: 6px
}

a img {
    border: 0px solid #fff
}

p {
    margin-bottom: 7px
}

.clearfix:after,
.over-wrap:after,
.pop-warp:after,
.log-wrap:after,
.streaming-pop:after,
.col-center:after,
.login-wrap:after,
.account-wrap:after,
.menu:after,
.setting-wrap:after,
.tab-highlight:after,
.marquee-pop .input-list:after,
.marquee-pop .filter-list:after,
.tab:after,
.input-list:after,
.filter-list:after,
.filter-pop ul:after,
.acc-info:after,
.total-show:after,
.total_info:after,
.action:after,
.footer_fixed .action:after,
.half_box:after,
.pt_allowed dl:after,
.status_but:after,
.agent_path ul:after,
.member_select:after,
.tab-topplay:after,
.slip-head:after,
.agent-path:after,
.btn_list-DW:after,
.change_pass:after,
.game-wrap:after,
.game-highlight-wrap:after,
.menu-wrap:after,
.stake-set:after,
.odds-set:after,
.upcome:after,
.marquee-box:after,
.form_list dd:after,
.add_account_box dl dd:after,
.pt_allowed dl dd:after,
.white-wrap:after,
.total_box:after,
.fix-content:after,
.pop-left dl:after,
.pop-left dd:after,
.initial-balance:after,
.final-balance:after,
.total_all:after,
.table01 .ico_account:after,
.table-s .ico_account:after,
.table_one .ico_account:after,
.footer_fixed .main_wrap:after,
.footer_fixed .header:after,
.footer_fixed .footer-content:after,
.pt_allowed:after,
.status_id:after,
.agent_path:after,
.agent_path-L .drop_down_li:after,
.inline-form:after,
.play_race-wrap:after,
.top_player-wrap:after,
.banking-head dd:after,
.DW-amount:after,
.credit-amount:after,
.wrap-refer_edit dl:after {
    content: "";
    display: block;
    clear: both
}

.clearfix,
.over-wrap,
.pop-warp,
.log-wrap,
.streaming-pop,
.col-center,
.login-wrap,
.account-wrap,
.menu,
.setting-wrap,
.tab-highlight,
.marquee-pop .input-list,
.marquee-pop .filter-list,
.tab,
.input-list,
.filter-list,
.filter-pop ul,
.acc-info,
.total-show,
.total_info,
.action,
.footer_fixed .action,
.half_box,
.pt_allowed dl,
.status_but,
.agent_path ul,
.member_select,
.tab-topplay,
.slip-head,
.agent-path,
.btn_list-DW,
.change_pass,
.game-wrap,
.game-highlight-wrap,
.menu-wrap,
.stake-set,
.odds-set,
.upcome,
.marquee-box,
.form_list dd,
.add_account_box dl dd,
.pt_allowed dl dd,
.white-wrap,
.total_box,
.fix-content,
.pop-left dl,
.pop-left dd,
.initial-balance,
.final-balance,
.total_all,
.table01 .ico_account,
.table-s .ico_account,
.table_one .ico_account,
.footer_fixed .main_wrap,
.footer_fixed .header,
.footer_fixed .footer-content,
.pt_allowed,
.status_id,
.agent_path,
.agent_path-L .drop_down_li,
.inline-form,
.play_race-wrap,
.top_player-wrap,
.banking-head dd,
.DW-amount,
.credit-amount,
.wrap-refer_edit dl {
    zoom: 1
}

.clear,
.set-content,
.table01 .status_all,
.table-s .status_all,
.table_one .status_all {
    clear: both
}

.float-L,
.delete,
.login-wrap li,
.account-wrap li,
.menu li,
.setting-wrap li,
.tab-highlight li,
.marquee-pop .input-list li,
.marquee-pop .filter-list li,
.tab li,
.input-list li,
.filter-list li,
.filter-pop ul li,
.acc-info li,
.total-show li,
.total_info li,
.action li,
.footer_fixed .action li,
.half_box li,
.pt_allowed dl li,
.status_but li,
.agent_path ul li,
.member_select li,
.tab-topplay li,
.slip-head li,
.agent-path li,
.btn_list-DW li,
.login-panel,
.change_pass .login-panel,
.change_pass .note,
.login-wrap .btn-login,
.login-wrap .btn-signup,
.header h1,
.login-wrap input,
.login-wrap textarea,
.login-wrap .member_select a,
.member_select .login-wrap a,
.menu,
.setting-wrap,
.stake-set dd,
.odds-set dd,
.favor,
.event-left,
.event-right,
.event-left .slip-head span,
.event-right .slip-head span,
.live-left,
.live-right,
.live-channel span,
.marquee-box .marquee a,
.profile-wrap dt,
.form_list dt,
.add_account_box dl dt,
.pt_allowed dl dt,
.filter-pop .btn-wrap .btn,
.filter-pop .btn-wrap .btn-send,
.filter-pop .btn-wrap .pages a,
.pages .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .filter-pop .btn-wrap input,
.filter-pop .btn-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .filter-pop .btn-wrap textarea,
.filter-pop .btn-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .total_all .btn_replay,
.total_all .filter-pop .btn-wrap .btn_replay,
.filter-pop .btn-wrap .btn_expand,
.filter-pop .btn-wrap .add_member,
.market-float,
.sum-pl dt,
.head-balance,
.pop-left,
.pop-left dt,
.select-betting .selection,
.pop-right,
.initial-balance dt,
.final-balance dt,
.initial-balance dd,
.final-balance dd,
.initial-balance dt span,
.final-balance dt span,
.total_box .total_dl,
.total_all h2,
.total_all .input-list,
.total_all .filter-list,
.total_info,
.table01 strong.no,
.table-s strong.no,
.table_one strong.no,
.table01 .ico_account,
.table-s .ico_account,
.table_one .ico_account,
.btn_open,
.btn_close,
.lv_0,
.lv_1,
.lv_2,
.lv_3,
.lv_4,
.lv_5,
.lv_sub,
.ico_dot1,
.ico_dot2,
.ico_dot3,
.table-pt .expand-close,
.table-pt .expand-open,
.pt_allowed dl,
.status_id p,
.agent_path ul,
.agent_path-L,
.agent_path-L img,
.total_all .agent_path,
.inline-form .form_list,
.inline-form .add_account_box dl,
.add_account_box .inline-form dl,
.inline-form .pt_allowed dl,
.pt_allowed .inline-form dl,
.inline-form .btn,
.inline-form .btn-send,
.inline-form .pages a,
.pages .inline-form a,
.inline-form .calendarTable_inputBox input,
.calendarTable_inputBox .inline-form input,
.inline-form .calendarTable_inputBox textarea,
.calendarTable_inputBox .inline-form textarea,
.inline-form .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .inline-form a,
.inline-form .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .inline-form a,
.inline-form .total_all .btn_replay,
.total_all .inline-form .btn_replay,
.inline-form .btn_expand,
.inline-form .add_member,
.top_player-wrap,
.racing-wrap,
.col-2,
.table01 .order,
.table-s .order,
.table_one .order,
.open-odds,
.close-odds,
.btn-book,
.banking-head dd span,
.DW-amount input,
.DW-amount textarea,
.DW-amount .member_select a,
.member_select .DW-amount a,
.DW-amount .btn,
.DW-amount .btn-send,
.DW-amount .pages a,
.pages .DW-amount a,
.DW-amount .calendarTable_inputBox input,
.calendarTable_inputBox .DW-amount input,
.DW-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .DW-amount textarea,
.DW-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .DW-amount a,
.DW-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .DW-amount a,
.DW-amount .total_all .btn_replay,
.total_all .DW-amount .btn_replay,
.DW-amount .btn_expand,
.DW-amount .add_member,
.btn_list-DW,
.credit-amount input,
.credit-amount textarea,
.credit-amount .member_select a,
.member_select .credit-amount a,
.wrap-refer_edit dt {
    float: left
}

.float-R,
.pop-warp.no-match_odds .pop-left .select-betting .selection,
.no-match_odds.log-wrap .pop-left .select-betting .selection,
.no-match_odds.streaming-pop .pop-left .select-betting .selection,
.btn-close,
.login-wrap,
.account-wrap,
.language,
.setting-wrap,
.time_zone,
.gmt,
.menu-list .Go,
.favor-set,
.event-right,
.live-head h3 span,
.total-show .sports-switch,
.menu .logout,
.setting-wrap .logout,
.menu .time_zone,
.setting-wrap .time_zone,
.total_all .btn_replay,
.btn_expand,
.add_member,
.table01 .action a,
.table-s .action a,
.table_one .action a,
.action,
.footer_fixed .action,
.status_id .status-active,
.status_id .status-suspend,
.status_id .status-lock,
.full-amount .btn,
.full-amount .btn-send,
.full-amount .pages a,
.pages .full-amount a,
.full-amount .calendarTable_inputBox input,
.calendarTable_inputBox .full-amount input,
.full-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .full-amount textarea,
.full-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .full-amount a,
.full-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .full-amount a,
.full-amount .btn_expand,
.full-amount .add_member,
.credit-amount .btn,
.credit-amount .btn-send,
.credit-amount .pages a,
.pages .credit-amount a,
.credit-amount .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount input,
.credit-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount textarea,
.credit-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount a,
.credit-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount a,
.credit-amount .btn_expand,
.credit-amount .add_member,
.credit-amount-member .btn,
.credit-amount-member .btn-send,
.credit-amount-member .pages a,
.pages .credit-amount-member a,
.credit-amount-member .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount-member input,
.credit-amount-member .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount-member textarea,
.credit-amount-member .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount-member a,
.credit-amount-member .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount-member a,
.credit-amount-member .btn_expand,
.credit-amount-member .add_member,
.wrap-refer_edit dd .btn,
.wrap-refer_edit dd .btn-send,
.wrap-refer_edit dd .pages a,
.pages .wrap-refer_edit dd a,
.wrap-refer_edit dd .calendarTable_inputBox input,
.calendarTable_inputBox .wrap-refer_edit dd input,
.wrap-refer_edit dd .calendarTable_inputBox textarea,
.calendarTable_inputBox .wrap-refer_edit dd textarea,
.wrap-refer_edit dd .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .wrap-refer_edit dd a,
.wrap-refer_edit dd .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .wrap-refer_edit dd a,
.wrap-refer_edit dd .total_all .btn_replay,
.total_all .wrap-refer_edit dd .btn_replay,
.wrap-refer_edit dd .btn_expand,
.wrap-refer_edit dd .add_member,
.btn-add {
    float: right
}

.align-L,
.table01 caption,
.table-s caption,
.table_one caption,
.no-data,
.status-popup,
.access-popup,
.table_one thead th,
.table-pt .expand td.expand-event:first-child,
.pt_allowed dl dt,
.tab-transfer .full-amount,
.tab-transfer .remark,
.tips-popup,
.slip-head .col-playID,
.slip-head .col-type,
.slip-head .col-country,
.slip-head .col-local,
.table01 .td-type,
.table-s .td-type,
.table_one .td-type,
.table01 .td-UID,
.table-s .td-UID,
.table_one .td-UID,
.td-uid,
.DW-amount,
.pop_bg .form-wrap h1 {
    text-align: left
}

.align-R,
.table01,
.table-s,
.table_one,
.account-wrap .main-wallet p,
.time_zone,
.path ul,
.change-channel span,
.form_list dt,
.add_account_box dl dt,
.pt_allowed dl dt,
.table-trans .unit-num,
.sports_set.pt_player dd,
.pt_player.sports_set2 dd,
.tab-transfer input,
.tab-transfer textarea,
.tab-transfer .member_select a,
.member_select .tab-transfer a,
.full-amount input,
.full-amount textarea,
.full-amount .member_select a,
.member_select .full-amount a,
.slip-head .col-exp,
.slip-head .col-amount,
.form-edit label,
.table-subaccount th:nth-last-child(-n+2),
.table-subaccount td:nth-last-child(-n+2),
.footer-content,
.casinopt-list dd,
.profile-wrap .dl_list .casinopt-list dd,
.sports_box .casinopt-list dd,
.casinopt-list-head dd,
.profile-wrap .dl_list .casinopt-list-head dd,
.sports_box .casinopt-list-head dd {
    text-align: right
}

.align-C,
.loading li,
.btn,
.btn-send,
.marquee-pop .pages a,
.pages a,
.calendarTable_inputBox input,
.calendarTable_inputBox textarea,
.calendarTable_inputBox .member_select a,
.member_select .calendarTable_inputBox a,
.total_all .btn_replay,
.btn_expand,
.add_member,
.change_pass,
.btn-book,
.browser-wrap,
.sys-suspend .status-suspend,
.sys-lock .status-suspend,
.sys-suspend .status-lock,
.sys-lock .status-lock,
.btn_list-DW.select-D:after,
.btn_list-DW.select-W:after,
.login-wrap .btn-login,
.login-wrap .btn-signup,
.account-wrap ul .logout a,
.stake-set .stake_edit-input,
.odds-set .stake_edit-input,
.col-edit a,
.tab-highlight li,
.message-wrap p,
.dialog-wrap h4,
.dialog-wrap-w h4,
.dialog-wrap .tc-content h5,
.tc-content .dialog-wrap h5,
.dialog-wrap-w .tc-content h5,
.tc-content .dialog-wrap-w h5,
.dialog-wrap .break li,
.dialog-wrap-w .break li,
.support-wrap,
.app-link,
.announce-footer,
.urgent-notice .announce-header h1,
.announce-content table,
.article-date,
.news-wrap .announce-header,
.apk-img,
.marquee-pop .nofound .nofound_box dd,
.confirm_box .white-wrap,
.confirm_box .total_box,
.confirm_box .fix-content,
.go-back,
.pop-close,
.tab a,
.pages,
.chart-wrap,
.initial-balance dt span,
.final-balance dt span,
.table-trans td,
.table01 strong.no,
.table-s strong.no,
.table_one strong.no,
.lv_0,
.lv_1,
.lv_2,
.lv_3,
.lv_4,
.lv_5,
.lv_sub,
.pop_box p.note,
.pop_refer p.note,
.status_but,
.btn_box,
.suspend-wrap .status-suspend,
.suspend-wrap .status-lock,
.fix-content .status_but,
.submit-wrap,
.submit-wrap input,
.submit-wrap textarea,
.submit-wrap .member_select a,
.member_select .submit-wrap a,
.slip-head li,
.table01 .order,
.table-s .order,
.table_one .order,
.form-wrap h1,
.btn-group,
.table-subaccount {
    text-align: center
}

.displayB,
.delete,
.btn,
.btn-send,
.marquee-pop .pages a,
.pages a,
.calendarTable_inputBox input,
.calendarTable_inputBox textarea,
.calendarTable_inputBox .member_select a,
.member_select .calendarTable_inputBox a,
.total_all .btn_replay,
.btn_expand,
.add_member,
.login-wrap,
.account-wrap,
.menu,
.setting-wrap,
.tab-highlight,
.marquee-pop .input-list,
.marquee-pop .filter-list,
.tab,
.input-list,
.filter-list,
.filter-pop ul,
.acc-info,
.total-show,
.total_info,
.action,
.footer_fixed .action,
.half_box,
.pt_allowed dl,
.status_but,
.agent_path ul,
.member_select,
.tab-topplay,
.slip-head,
.agent-path,
.btn_list-DW,
.login-wrap li,
.account-wrap li,
.menu li,
.setting-wrap li,
.tab-highlight li,
.marquee-pop .input-list li,
.marquee-pop .filter-list li,
.tab li,
.input-list li,
.filter-list li,
.filter-pop ul li,
.acc-info li,
.total-show li,
.total_info li,
.action li,
.footer_fixed .action li,
.half_box li,
.pt_allowed dl li,
.status_but li,
.agent_path ul li,
.member_select li,
.tab-topplay li,
.slip-head li,
.agent-path li,
.btn_list-DW li,
.tag-live strong:before,
.age_box li .secondary:before,
.age_box li .btn-send:before,
.top_player-wrap h3 a,
.racing-wrap h3 a,
.game-wrap h3 a,
.game-highlight-wrap h3 a,
.favor-wrap h3 a,
.sub_path h3 a,
.pop-title h3 a,
.btn-book,
.account-wrap ul a,
.selection-list a,
.power-wrap h3 img,
.power-wrap-b img,
.btn_list-DW.select-D:after,
.btn_list-DW.select-W:after,
.login-wrap .btn-login,
.login-wrap .btn-signup,
.header a,
.account-wrap li .refresh-bar,
.language,
.flag,
.menu-wrap a,
.menu li:hover ul,
.setting-wrap li:hover ul,
.menu-list a,
.menu-list .Go,
.favor-set,
.path .path-back,
.path-pop a,
.favor,
.kv-wrap a,
.promo-banner-wrap a,
.tab-highlight a,
.event-left .slip-head span,
.event-right .slip-head span,
.app-link,
.live-list a,
.live-list a:after,
.change-channel::before,
.marquee-box h4:before,
.marquee-box .tc-content h5:before,
.tc-content .marquee-box h5:before,
.marquee-box h4:after,
.marquee-box .tc-content h5:after,
.tc-content .marquee-box h5:after,
.marquee-box .marquee a span,
.announce-footer .confirm-checkbox,
.close_pop,
.error-text,
.go-back,
.pop-close,
.tab a,
.expand-close,
.expand-open,
.selection,
.initial-balance dt span,
.final-balance dt span,
.btn_expand:before,
.margin-table:after,
.table01 .action a,
.table-s .action a,
.table_one .action a,
.table-pt .expand-close,
.table-pt .expand-open,
.status_but .but_active,
.status_but .but_suspend,
.status_but .but_locked,
.status_but .but_active img,
.status_but .but_suspend img,
.status_but .but_locked img,
.agent_path a,
.agent_path-L img,
.member_select a,
.odd-add a,
.note,
.tab-topplay li:before,
.tab-topplay li:after,
.tab-topplay a,
.table01 .order,
.table-s .order,
.table_one .order,
.open-odds:before,
.close-odds:before,
.banking-head dd span {
    display: block
}

.displayI,
.pages li {
    display: inline
}

.displayIB,
.menu-wrap a.menu-drop:after,
.menu-wrap a.menu-drop.select:after,
.favor-wrap .menu-list a,
.upcome-wrap dt,
.upcome-wrap dd,
.app-link a,
.announce-header h1:before,
.announce-footer li,
.toggle_on,
.toggle_off,
.form_list .radio_enter input,
.add_account_box dl .radio_enter input,
.pt_allowed dl .radio_enter input,
.form_list .radio_enter textarea,
.add_account_box dl .radio_enter textarea,
.pt_allowed dl .radio_enter textarea,
.form_list .radio_enter .member_select a,
.member_select .form_list .radio_enter a,
.add_account_box dl .radio_enter .member_select a,
.member_select .add_account_box dl .radio_enter a,
.pt_allowed dl .radio_enter .member_select a,
.member_select .pt_allowed dl .radio_enter a,
.form_list .radio_enter label,
.add_account_box dl .radio_enter label,
.pt_allowed dl .radio_enter label,
.form_list .radio_enter .radio,
.add_account_box dl .radio_enter .radio,
.pt_allowed dl .radio_enter .radio,
.form_list .add_btn .btn,
.add_account_box dl .add_btn .btn,
.pt_allowed dl .add_btn .btn,
.form_list .add_btn .btn-send,
.add_account_box dl .add_btn .btn-send,
.pt_allowed dl .add_btn .btn-send,
.form_list .add_btn .pages a,
.pages .form_list .add_btn a,
.add_account_box dl .add_btn .pages a,
.pages .add_account_box dl .add_btn a,
.pt_allowed dl .add_btn .pages a,
.pages .pt_allowed dl .add_btn a,
.form_list .add_btn .calendarTable_inputBox input,
.calendarTable_inputBox .form_list .add_btn input,
.add_account_box dl .add_btn .calendarTable_inputBox input,
.calendarTable_inputBox .add_account_box dl .add_btn input,
.pt_allowed dl .add_btn .calendarTable_inputBox input,
.calendarTable_inputBox .pt_allowed dl .add_btn input,
.form_list .add_btn .calendarTable_inputBox textarea,
.calendarTable_inputBox .form_list .add_btn textarea,
.add_account_box dl .add_btn .calendarTable_inputBox textarea,
.calendarTable_inputBox .add_account_box dl .add_btn textarea,
.pt_allowed dl .add_btn .calendarTable_inputBox textarea,
.calendarTable_inputBox .pt_allowed dl .add_btn textarea,
.form_list .add_btn .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .form_list .add_btn a,
.add_account_box dl .add_btn .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .add_account_box dl .add_btn a,
.pt_allowed dl .add_btn .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .pt_allowed dl .add_btn a,
.form_list .add_btn .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .form_list .add_btn a,
.add_account_box dl .add_btn .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .add_account_box dl .add_btn a,
.pt_allowed dl .add_btn .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .pt_allowed dl .add_btn a,
.form_list .add_btn .total_all .btn_replay,
.total_all .form_list .add_btn .btn_replay,
.add_account_box dl .add_btn .total_all .btn_replay,
.total_all .add_account_box dl .add_btn .btn_replay,
.pt_allowed dl .add_btn .total_all .btn_replay,
.total_all .pt_allowed dl .add_btn .btn_replay,
.form_list .add_btn .btn_expand,
.add_account_box dl .add_btn .btn_expand,
.pt_allowed dl .add_btn .btn_expand,
.form_list .add_btn .add_member,
.add_account_box dl .add_btn .add_member,
.pt_allowed dl .add_btn .add_member,
.btn_box .btn,
.btn_box .btn-send,
.btn_box .pages a,
.pages .btn_box a,
.btn_box .calendarTable_inputBox input,
.calendarTable_inputBox .btn_box input,
.btn_box .calendarTable_inputBox textarea,
.calendarTable_inputBox .btn_box textarea,
.btn_box .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .btn_box a,
.btn_box .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .btn_box a,
.btn_box .total_all .btn_replay,
.total_all .btn_box .btn_replay,
.btn_box .btn_expand,
.btn_box .add_member,
.fix-content .status_but li,
.account-wrap li span,
.submit-wrap li,
.submit-wrap .btn-send,
.submit-wrap .btn-send span,
.sort-this:after,
.table01 .expand-close,
.table-s .expand-close,
.table_one .expand-close,
.table01 .expand-open,
.table-s .expand-open,
.table_one .expand-open,
.banking-head dt,
.banking-head dd,
.form-edit label,
.form-edit .btn,
.form-edit .btn-send,
.form-edit .pages a,
.pages .form-edit a,
.form-edit .calendarTable_inputBox input,
.calendarTable_inputBox .form-edit input,
.form-edit .calendarTable_inputBox textarea,
.calendarTable_inputBox .form-edit textarea,
.form-edit .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .form-edit a,
.form-edit .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .form-edit a,
.form-edit .total_all .btn_replay,
.total_all .form-edit .btn_replay,
.form-edit .btn_expand,
.form-edit .add_member,
.access-selection,
.icon-select_all,
.icon-select_partial,
.footer-content dd {
    display: inline-block
}

.displayF,
.promo-banner .promo-control-nav,
.account-wrap .main-wallet .nums,
.account-wrap .a-refresh,
.badge-currency,
.tag-live,
.tag-live strong,
.age_box li .secondary,
.age_box li .btn-send,
.power-wrap,
.account-wrap a,
.account-wrap .main-wallet,
.account-wrap .main-wallet .a-wallet ul,
.account-wrap p.loading-bar,
.licence_embed,
.live-head,
.change-channel,
.marquee-box,
.marquee-box h4,
.marquee-box .tc-content h5,
.tc-content .marquee-box h5,
.marquee-box .marquee a,
.marquee-pop,
.marquee-pop .nofound,
.marquee-pop .nofound .nofound_box,
.marquee-pop .pages,
.btn_expand,
.sports_box dl,
.casinopt-list,
.profile-wrap .dl_list .casinopt-list,
.sports_box .casinopt-list,
.casinopt-list-head,
.profile-wrap .dl_list .casinopt-list-head,
.sports_box .casinopt-list-head,
.sports_box .casino-setting-head {
    display: -webkit-box;
    display: flex
}

.border-box,
.tag-live,
.marquee-pop .announce-content,
.total_all .btn_replay,
.total_all .input-list .btn-send,
.total_all .filter-list .btn-send,
.add_member {
    box-sizing: border-box
}

.main_wrap,
.header,
.footer-content {
    position: relative;
    width: 1350px;
    margin: 0 auto
}

.full-wrap {
    position: relative;
    min-width: 1350px;
    max-width: calc(100% - 40px);
    height: calc(100% - 105px);
    margin: 0 auto
}

.over-wrap {
    overflow: hidden;
    overflow-y: scroll
}

.pop-warp,
.log-wrap,
.streaming-pop {
    width: 920px;
    height: 600px;
    overflow: hidden;
    margin: 0 auto;
    background-color: #fff
}

.pop-warp .game-name,
.log-wrap .game-name,
.streaming-pop .game-name {
    position: relative;
    padding-right: 130px
}

.pop-warp .game-name p,
.log-wrap .game-name p,
.streaming-pop .game-name p {
    width: 780px;
    margin-bottom: 0;
    line-height: 25px
}

.pop-warp,
.streaming-pop .game-name span {
    position: absolute;
    top: 6px;
    right: 10px
}

.pop-warp.no-match_odds,
.no-match_odds.log-wrap,
.no-match_odds.streaming-pop {
    width: 450px;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content
}

.pop-warp.no-match_odds .game-name p,
.no-match_odds.log-wrap .game-name p,
.no-match_odds.streaming-pop .game-name p {
    width: 310px
}

.pop-warp.no-match_odds .pop-left dl,
.no-match_odds.log-wrap .pop-left dl,
.no-match_odds.streaming-pop .pop-left dl {
    display: none
}

.pop-warp.no-match_odds .pop-left .select-betting,
.no-match_odds.log-wrap .pop-left .select-betting,
.no-match_odds.streaming-pop .pop-left .select-betting {
    display: block
}

.pop-warp.no-match_odds .pop-right,
.no-match_odds.log-wrap .pop-right,
.no-match_odds.streaming-pop .pop-right {
    display: none
}

.pop-warp.no-match_odds .pop-left,
.no-match_odds.log-wrap .pop-left,
.no-match_odds.streaming-pop .pop-left,
.pop-warp.no-match_odds .chart-wrap,
.no-match_odds.log-wrap .chart-wrap,
.no-match_odds.streaming-pop .chart-wrap,
.pop-warp.no-match_odds .pop-note,
.no-match_odds.log-wrap .pop-note,
.no-match_odds.streaming-pop .pop-note {
    width: calc(100% - 20px - 20px)
}

.pop-warp.no-match_odds .pop-left,
.no-match_odds.log-wrap .pop-left,
.no-match_odds.streaming-pop .pop-left {
    height: 400px
}

.pop-warp.no-match_odds .chart-wrap,
.no-match_odds.log-wrap .chart-wrap,
.no-match_odds.streaming-pop .chart-wrap {
    margin: 0 auto
}

.pop-warp.no-match_odds .chart-wrap h2,
.no-match_odds.log-wrap .chart-wrap h2,
.no-match_odds.streaming-pop .chart-wrap h2 {
    width: inherit
}

.log-wrap {
    width: 1100px;
    height: 650px
}

.col-left {
    position: absolute;
    left: 0;
    top: 0;
    width: 17.36111%
}

.full-wrap .col-left {
    height: 100%;
    overflow: hidden
}

.col-center {
    position: relative;
    margin-left: 17.36111%;
    margin-right: 26.04167%;
    padding: 0 15px
}

.full-wrap .col-center {
    height: 100%;
    overflow: hidden
}

.col-center.player {
    height: 100%;
    padding: 0 5px
}

.col-center.markets {
    padding: 0 5px
}

.col-center.inplay {
    height: 100%;
    padding-left: 0;
    margin-left: 0
}

.col-center.binary {
    height: 100%;
    padding-left: 0;
    margin-left: 0
}

.col-center.full-center {
    height: 100%;
    padding-left: 0;
    margin-left: 0
}

.col-center.report {
    padding-right: 0;
    margin-right: 0
}

.col-right {
    position: absolute;
    width: 26.04167%;
    top: 0;
    right: 0
}

.full-wrap .col-right {
    height: 100%;
    overflow: hidden
}

.loading-wrap {
    display: none;
    top: 20%;
    height: 90px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    padding-top: 25px;
    z-index: 50;
    position: absolute;
    left: 50%;
    width: 190px;
    margin-left: -95px
}

.loading-wrap.multi-load {
    padding-top: 15px;
    height: 66px;
    top: 10%;
    margin-top: -10px
}

.loading {
    padding: 5% 0
}

.loading li {
    list-style: none;
    font-size: 11px;
    color: #243A48;
    margin-bottom: 3x
}

.loading img {
    background-position: -42px -415px;
    height: 22px;
    width: 38px
}

.table01,
.table-s,
.table_one {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
    border-bottom: 1px solid #7E97A7;
    margin-bottom: 15px
}

.table01 caption,
.table-s caption,
.table_one caption {
    background-color: #3B5160;
    border-bottom: 1px solid #7E97A7;
    color: #fff;
    line-height: 24px;
    font-weight: bold;
    padding: 0 10px
}

.table01 th,
.table-s th,
.table_one th {
    color: #243A48;
    background-color: #E4E4E4;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    padding: 8px 10px
}

.table01 td,
.table-s td,
.table_one td {
    border-top: 1px solid #eee;
    padding: 8px 10px;
    vertical-align: middle
}

.table01 a,
.table-s a,
.table_one a {
    text-decoration: underline
}

.table01 .btn,
.table-s .btn,
.table_one .btn,
.table01 .btn-send,
.table-s .btn-send,
.table_one .btn-send,
.table01 .pages a,
.pages .table01 a,
.table-s .pages a,
.pages .table-s a,
.table_one .pages a,
.pages .table_one a,
.table01 .calendarTable_inputBox input,
.calendarTable_inputBox .table01 input,
.table-s .calendarTable_inputBox input,
.calendarTable_inputBox .table-s input,
.table_one .calendarTable_inputBox input,
.calendarTable_inputBox .table_one input,
.table01 .calendarTable_inputBox textarea,
.calendarTable_inputBox .table01 textarea,
.table-s .calendarTable_inputBox textarea,
.calendarTable_inputBox .table-s textarea,
.table_one .calendarTable_inputBox textarea,
.calendarTable_inputBox .table_one textarea,
.table01 .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .table01 a,
.table-s .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .table-s a,
.table_one .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .table_one a,
.table01 .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .table01 a,
.table-s .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .table-s a,
.table_one .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .table_one a,
.table01 .total_all .btn_replay,
.total_all .table01 .btn_replay,
.table-s .total_all .btn_replay,
.total_all .table-s .btn_replay,
.table_one .total_all .btn_replay,
.total_all .table_one .btn_replay,
.table01 .btn_expand,
.table-s .btn_expand,
.table_one .btn_expand,
.table01 .add_member,
.table-s .add_member,
.table_one .add_member {
    text-decoration: none
}

.over-wrap .table01:last-of-type,
.over-wrap .table-s:last-of-type,
.over-wrap .table_one:last-of-type {
    margin-bottom: 0
}

.table01 .win,
.table-s .win,
.table_one .win {
    color: #508D0E
}

.cal-input {
    width: 110px;
    cursor: pointer;
    background-position: 100% -23px
}

.time-input {
    width: 45px
}

.delete {
    background-position: -63px -135px;
    height: 32px;
    width: 17px
}

.delete:hover,
.delete.del-hover {
    background-position: -63px -167px
}

.btn-close {
    background-position: -404px -1385px;
    height: 10px;
    width: 10px
}

.btn-close:hover,
.btn-close.close-black-hover {
    background-position: -404px -1395px
}

.btn,
.btn-send,
.marquee-pop .pages a,
.pages a,
.calendarTable_inputBox input,
.calendarTable_inputBox textarea,
.calendarTable_inputBox .member_select a,
.member_select .calendarTable_inputBox a,
.total_all .btn_replay,
.btn_expand,
.add_member {
    background: #eee url(../images/bg-btn.gif) repeat-x;
    border: 1px solid #bbb;
    border-radius: 4px;
    color: #1E1E1E;
    font-weight: bold;
    line-height: 23px;
    font-size: 12px;
    margin: 0 5px 10px 0
}

.btn:hover,
.btn-send:hover,
.pages a:hover,
.calendarTable_inputBox input:hover,
.calendarTable_inputBox textarea:hover,
.calendarTable_inputBox .member_select a:hover,
.member_select .calendarTable_inputBox a:hover,
.total_all .btn_replay:hover,
.btn_expand:hover,
.add_member:hover {
    text-decoration: none;
    background: #fff url(../images/bg-btn_hover.gif) repeat-x
}

.btn.select,
.select.btn-send,
.pages a.select,
.calendarTable_inputBox input.select,
.calendarTable_inputBox textarea.select,
.calendarTable_inputBox .member_select a.select,
.member_select .calendarTable_inputBox a.select,
.total_all .select.btn_replay,
.select.btn_expand,
.select.add_member {
    background: #FFCC2E url(../images/bg-btn_select.gif) repeat-x;
    border-color: #CB8009
}

.btn.disable,
.disable.btn-send,
.pages a.disable,
.calendarTable_inputBox input.disable,
.calendarTable_inputBox textarea.disable,
.calendarTable_inputBox .member_select a.disable,
.member_select .calendarTable_inputBox a.disable,
.total_all .disable.btn_replay,
.disable.btn_expand,
.disable.add_member {
    background: #dfdfdf;
    color: #999;
    cursor: not-allowed
}

table .btn,
table .btn-send,
table .marquee-pop .pages a,
.marquee-pop .pages table a,
table .pages a,
.pages table a,
table .calendarTable_inputBox input,
.calendarTable_inputBox table input,
table .calendarTable_inputBox textarea,
.calendarTable_inputBox table textarea,
table .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select table a,
table .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox table a,
table .total_all .btn_replay,
.total_all table .btn_replay,
table .btn_expand,
table .add_member {
    margin: 0
}

.secondary {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D6D6D6), color-stop(89%, #B2B2B2));
    background-image: -webkit-linear-gradient(top, #D6D6D6 0%, #B2B2B2 89%);
    background-image: linear-gradient(180deg, #D6D6D6 0%, #B2B2B2 89%);
    border: 1px solid #969696
}

.secondary:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#B2B2B2), color-stop(89%, #D6D6D6));
    background-image: -webkit-linear-gradient(top, #B2B2B2 0%, #D6D6D6 89%);
    background-image: linear-gradient(180deg, #B2B2B2 0%, #D6D6D6 89%);
    border: 1px solid #969696
}

.btn-box {
    padding: 0 7px 10px
}

.btn-send {
    background: #FFA00C url(../images/bg-send.gif) repeat-x;
    border-color: #CB8009
}

.btn-send:hover {
    background: #FFB80C url(../images/bg-send_hover.gif) repeat-x
}

.btn-send.disable {
    background: #FFE2B6 url(../images/bg-send_no.gif) repeat-x;
    border-color: #EFD9B5
}

.btn-back,
.btn-lay {
    color: #1E1E1E;
    font-weight: bold;
    margin-right: 1%;
    background-color: #72BBEF;
    border-left-color: #fff
}

.btn-back:hover,
.btn-lay:hover {
    background-color: #80c2f1
}

.btn-back.select,
.select.btn-lay {
    background-color: #1a8ee1;
    color: #fff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5)
}

.btn-back.select a,
.select.btn-lay a {
    color: #fff
}

.btn-back:hover,
.btn-lay:hover {
    text-decoration: none
}

.disable .btn-back,
.disable .btn-lay {
    cursor: not-allowed
}

.btn-lay {
    background-color: #FAA9BA;
    border-left-color: #fff
}

.btn-lay:hover {
    background-color: #fbb7c6
}

.btn-lay.select {
    background-color: #f4496d;
    color: #fff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5)
}

.btn-lay.select a {
    color: #fff
}

.btn-recall {
    color: #3B5160;
    font-size: 15px;
    font-weight: bold;
    line-height: 2.2;
    padding: 0 8px;
    background-color: rgba(94, 190, 255, 0.15);
    border-radius: 4px;
    border: 1px solid #7E97A7;
    cursor: pointer
}

.btn-recall:hover {
    background-color: rgba(94, 190, 255, 0.3)
}

.link-open {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    text-decoration: none !important
}

.link-open:after {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: top;
    background: url('data:image/svg+xml,<svg width="31" height="30" xmlns="http://www.w3.org/2000/svg"><path d="M23.668 0a7 7 0 017 7v16a7 7 0 01-7 7h-16a7 7 0 01-7-7V7a7 7 0 017-7h16zm-8 7a1.28 1.28 0 00-1.28 1.28v5.44h-5.44a1.28 1.28 0 100 2.56h5.44v5.44a1.28 1.28 0 102.56 0v-5.44h5.44a1.28 1.28 0 100-2.56h-5.44V8.28A1.28 1.28 0 0015.668 7z" fill="%232789CE" fill-rule="evenodd"/></svg>') center no-repeat;
    background-size: contain;
    margin-left: 5px
}

.link-open.red span {
    border-bottom: 1px solid;
    padding-bottom: 1px
}

.table01 .link-open.red span,
.table-s .link-open.red span,
.table_one .link-open.red span {
    text-decoration: none
}

.link-open.red:after {
    background: url('data:image/svg+xml,<svg width="31" height="30" xmlns="http://www.w3.org/2000/svg"><path d="M23.668 0a7 7 0 017 7v16a7 7 0 01-7 7h-16a7 7 0 01-7-7V7a7 7 0 017-7h16zm-8 7a1.28 1.28 0 00-1.28 1.28v5.44h-5.44a1.28 1.28 0 100 2.56h5.44v5.44a1.28 1.28 0 102.56 0v-5.44h5.44a1.28 1.28 0 100-2.56h-5.44V8.28A1.28 1.28 0 0015.668 7z" fill="%23D0021B" fill-rule="evenodd"/></svg>') center no-repeat;
    background-size: contain
}

.icon-time {
    background-position: -399px -2869px
}

.icon-live {
    background-position: -399px -2438px
}

.icon-irun {
    background-position: -399px -2401px
}

.icon-predict {
    background-position: -398px -1968px;
    height: 16px;
    width: 16px
}

.icon-cashout {
    background-position: -399px -1953px;
    height: 15px;
    width: 15px
}

.icon-in_play {
    margin-right: 5px;
    background-position: -406px -2363px;
    height: 8px;
    width: 8px
}

.menu-list .icon-in_play {
    margin-right: 9px
}

.icon-no_play {
    margin-right: 5px;
    background-position: -406px -2577px;
    height: 8px;
    width: 8px
}

.menu-list .icon-no_play {
    margin-right: 9px
}

.icon-cricket {
    background-position: -402px -1984px;
    height: 12px;
    width: 12px
}

.icon-soccer {
    background-position: -402px -2884px;
    height: 12px;
    width: 12px
}

.icon-tennis {
    background-position: -402px -2857px;
    height: 12px;
    width: 12px
}

.icon-basketball {
    background-position: -402px -1814px;
    height: 12px;
    width: 12px
}

.icon-dog {
    background-position: -402px -1996px;
    height: 12px;
    width: 12px
}

.icon-HR {
    background-position: -402px -2350px;
    height: 13px;
    width: 12px
}

.icon-racing {
    background-position: -401px -2585px;
    height: 13px;
    width: 13px
}

.lay {
    color: #E33A5E !important
}

.back {
    color: #1F72AC !important
}

.red {
    color: #024168 !important
}

.green {
    color: #508D0E !important
}

.bg-back {
    background-color: #BEDDF4 !important
}

.bg-lay {
    background-color: #F3DCE2 !important
}

.tag-new {
    position: absolute;
    width: 32px;
    height: 18px;
    background: url('data:image/svg+xml,<svg width="32" height="16" viewBox="0 0 32 16" xmlns="http://www.w3.org/2000/svg"><path d="M20 12l-7 4 1-4h-11c-1.657 0-3-1.343-3-3v-6c0-1.657 1.343-3 3-3h26c1.657 0 3 1.343 3 3v6c0 1.657-1.343 3-3 3h-9z" fill="%23D0021B"/></svg>') center no-repeat;
    background-size: contain;
    color: #FFFFFF;
    line-height: 12px;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
    -webkit-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6));
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6));
    z-index: 2
}

.delete,
.btn-close,
.header h1,
.mysports247 .header h1,
.maxinplay .header h1,
.maxexch9 .header h1,
.mazaplay .header h1,
.kingfair24 .header h1,
.alphaexch .header h1,
.alphaexchlive .header h1,
.bdtwin .header h1,
.goexchange .header h1,
.agexch247 .header h1,
.iceEX .header h1,
.o_spondemo .header h1,
.o_12bet .header h1,
.o_dafabet .header h1,
.skyinplay .header h1,
.probetx .header h1,
.probet247 .header h1,
.bigexch .header h1,
.sixball .header h1,
.sixballio .header h1,
.sgexch247 .header h1,
.baji .header h1,
.baji365 .header h1,
.masterexchange .header h1,
.matador .header h1,
.fairenter .header h1,
.lucky7 .header h1,
.dhoom .header h1,
.qexch .header h1,
.iceinplay .header h1,
.betmygame .header h1,
.fancyfair .header h1,
.maruti .header h1,
.marutibook .header h1,
.bdtwin247 .header h1,
.oceanexch1 .header h1,
.oceanbook1 .header h1,
.wicketspro .header h1,
.yabo101sport .header h1,
.aecricket888 .header h1,
.cftbet365 .header h1,
.crickzoom .header h1,
.crickzoomlive .header h1,
.wickets247 .header h1,
.crickex .header h1,
.language,
.menu-list .Go,
.path .path-back,
.favor,
.tag-live strong,
.transfer-wrap .close,
.transfer-wrap-slider .close,
.login_to_go .close,
.close_pop,
.table01 .action a,
.table-s .action a,
.table_one .action a,
.btn_open,
.btn_close,
.odd-add a,
.open-odds,
.close-odds,
.credit-amount-member .btn,
.credit-amount-member .btn-send,
.credit-amount-member .pages a,
.pages .credit-amount-member a,
.credit-amount-member .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount-member input,
.credit-amount-member .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount-member textarea,
.credit-amount-member .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount-member a,
.credit-amount-member .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount-member a,
.credit-amount-member .total_all .btn_replay,
.total_all .credit-amount-member .btn_replay,
.credit-amount-member .btn_expand,
.credit-amount-member .add_member {
    font-size: 0;
    text-indent: -99999px
}

.pop-warp .game-name p,
.log-wrap .game-name p,
.streaming-pop .game-name p,
.menu-list a,
.path-pop a,
.upcome,
.live-list a,
.live-channel,
.filter-list .filter-content,
.select-betting .selection,
.table-pt td,
.table-pt .ico_account,
.table01 .td-UID,
.table-s .td-UID,
.table_one .td-UID,
.td-uid {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.promo-banner .promo-control-nav {
    /* -ms-display:flex; */
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center
}

.pop-warp,
.log-wrap,
.streaming-pop,
.account-wrap ul,
.selection-list,
.slip_set-pop,
.oneclick_set-pop,
.path-pop,
.message-wrap,
.dialog-wrap-w,
.pop_box,
.pop_refer,
.filter-pop,
.calendarPanel,
.account_pop,
.tips-popup {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.5)
}

.expand>td,
.table01 .border-t td,
.table-s .border-t td,
.table_one .border-t td,
.slip-head {
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.1)
}

.tag-live strong:before,
.age_box li .secondary:before,
.age_box li .btn-send:before {
    content: ""
}

.account-wrap .main-wallet .nums,
.account-wrap .a-refresh,
.badge-currency,
.tag-live,
.tag-live strong,
.age_box li .secondary,
.age_box li .btn-send {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center
}

/* .header h1{
    width:143px;
    height:34px;
    background:url('../images/Logo.png') no-repeat
} */


/* .mysports247 .header h1{
    width:172px;
    height:52px;
    background:url(../images/mysports247/logo-mysports247.png) no-repeat
} */
/* .maxinplay .header h1{
    width:115px;
    height:56px;
    background:url(../images/maxinplay/logo-maxinplay.png) no-repeat
} */
/* .maxexch9 .header h1{
    width:134px;
    height:47px;
    background:url(../images/maxexch9/logo-maxexch9.png) no-repeat
}
.mazaplay .header h1{
    width:111px;
    height:59px;
    background:url(../images/mazaplay/logo-mazaplay.png) no-repeat
}
.kingfair24 .header h1{
    width:148px;
    height:48px;
    background:url(../images/kingfair24/logo-kingfair24.png) no-repeat
}
.alphaexch .header h1{
    width:160px;
    height:48px;
    background:url(../images/alphaexch/logo-alphaexch.png) no-repeat
}
.alphaexchlive .header h1{
    width:160px;
    height:48px;
    background:url(../images/alphaexchlive/logo-alphaexchlive.png) no-repeat
} */
.bdtwin .header h1 a {
    width: 158px;
    height: 53px;
    background: url(../images/bdtwin_logo.png) no-repeat;
    background-size: contain;
}

/* .goexchange .header h1{
    width:106px;
    height:66px;
    background:url(../images/goexchange/logo-goexchange.png) no-repeat
}
.agexch247 .header h1{
    width:106px;
    height:66px;
    background:url(../images/agEX247/logo-agEX.png) no-repeat
}
.iceEX .header h1{
    width:120px;
    height:62px;
    background:url(../images/iceEX/logo-iceEX.png) no-repeat
}
.o_spondemo .header h1{
    width:212px;
    height:39px;
    background:url(../images/spdemo/logo-spdemo.png) no-repeat
}
.o_12bet .header h1{
    width:270px;
    height:40px;
    background:url(../images/12bet/logo-12bet.png) no-repeat
}
.o_dafabet .header h1{
    width:212px;
    height:40px;
    background:url(../images/dafabet/logo-dafabet.png) no-repeat
}
.skyinplay .header h1{
    width:155px;
    height:53px;
    background:url(../images/skyinplay/logo-skyinplay.png) no-repeat;
    background-size:contain
}
.probetx .header h1{
    width:154px;
    height:65px;
    background:url(../images/probetx/logo-probetx.png) no-repeat;
    background-size:contain
}
.probet247 .header h1{
    width:170px;
    height:61px;
    background:url(../images/probet247/logo-probet247.png) no-repeat;
    background-size:contain
}
.bigexch .header h1{
    width:156px;
    height:44px;
    background:url(../images/bigEXch/logo-bigEXch.png) no-repeat;
    background-size:contain
}
.sixball .header h1{
    width:128px;
    height:67px;
    background:url(../images/sixball/logo-6ball.png) no-repeat;
    background-size:contain
}
.sixballio .header h1{
    width:128px;
    height:67px;
    background:url(../images/sixballio/logo-6ballio.png) no-repeat;
    background-size:contain
}
.sgexch247 .header h1{
    width:115px;
    height:60px;
    background:url(../images/sgexch247/logo-sgexch247.png) no-repeat;
    background-size:contain
}
.baji .header h1{
    width:97px;
    height:59px;
    background:url(../images/baji/logo-baji.png) no-repeat;
    background-size:contain
}
.baji365 .header h1{
    width:195px;
    height:59px;
    background:url(../images/baji365/logo-baji365.png) no-repeat;
    background-size:contain
}
.baji365 .header h1{
    width:152px;
    height:47px;
    background:url(../images/baji365/logo-baji365.png) no-repeat;
    background-size:contain
} */
.baji .login_to_go,
.baji .change_pass {
    background-color: #14805E
}

.baji .login_to_go .login-panel,
.baji .change_pass .login-panel {
    color: #fff
}

.baji .login_to_go .login-panel .btn-send,
.baji .change_pass .login-panel .btn-send {
    color: #000;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F3DE00), to(#CDBC00));
    background-image: -webkit-linear-gradient(top, #F3DE00 0%, #CDBC00 100%);
    background-image: linear-gradient(180deg, #F3DE00 0%, #CDBC00 100%);
    border: 1px solid #08571D
}

.baji .login_to_go .login-panel .btn-send img,
.baji .change_pass .login-panel .btn-send img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23000" fill-rule="evenodd"/></svg>') no-repeat
}

.baji .login_to_go .login-panel .btn-send:hover,
.baji .change_pass .login-panel .btn-send:hover {
    background: -webkit-gradient(linear, left top, left bottom, from(#CDBC00), to(#F3DE00));
    background: -webkit-linear-gradient(top, #CDBC00 0%, #F3DE00 100%);
    background: linear-gradient(180deg, #CDBC00 0%, #F3DE00 100%)
}

.baji .login_to_go .login-panel .error,
.baji .change_pass .login-panel .error {
    color: #CDBC00
}

.baji .login_to_go .icon-login,
.baji .change_pass .icon-login {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.baji .login_to_go .note,
.baji .change_pass .note {
    color: rgba(255, 255, 255, 0.5)
}

.baji365 .login_to_go,
.baji365 .change_pass {
    background-color: #14805E
}

.baji365 .login_to_go .login-panel,
.baji365 .change_pass .login-panel {
    color: #fff
}

.baji365 .login_to_go .login-panel .btn-send,
.baji365 .change_pass .login-panel .btn-send {
    color: #000;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F3DE00), to(#CDBC00));
    background-image: -webkit-linear-gradient(top, #F3DE00 0%, #CDBC00 100%);
    background-image: linear-gradient(180deg, #F3DE00 0%, #CDBC00 100%);
    border: 1px solid #08571D
}

.baji365 .login_to_go .login-panel .btn-send img,
.baji365 .change_pass .login-panel .btn-send img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23000" fill-rule="evenodd"/></svg>') no-repeat
}

.baji365 .login_to_go .login-panel .btn-send:hover,
.baji365 .change_pass .login-panel .btn-send:hover {
    background: -webkit-gradient(linear, left top, left bottom, from(#CDBC00), to(#F3DE00));
    background: -webkit-linear-gradient(top, #CDBC00 0%, #F3DE00 100%);
    background: linear-gradient(180deg, #CDBC00 0%, #F3DE00 100%)
}

.baji365 .login_to_go .login-panel .error,
.baji365 .change_pass .login-panel .error {
    color: #CDBC00
}

.baji365 .login_to_go .icon-login,
.baji365 .change_pass .icon-login {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.baji365 .login_to_go .note,
.baji365 .change_pass .note {
    color: rgba(255, 255, 255, 0.5)
}

/* .masterexchange .header h1{
    width:164px;
    height:56px;
    background:url(../images/masterexc/logo-masterexc.png) no-repeat;
    background-size:contain
}
.matador .header h1{
    width:176px;
    height:44px;
    background:url(../images/matador/logo-matador-w.png) no-repeat;
    background-size:contain
}
.fairenter .header h1{
    width:172px;
    height:52px;
    background:url(../images/fairenter/logo-fairenter-b.png) no-repeat;
    background-size:contain
}
.lucky7 .header h1{
    width:137px;
    height:48px;
    background:url(../images/lucky7/logo-lucky7.png) no-repeat;
    background-size:contain
}
.dhoom .header h1{
    width:108px;
    height:72px;
    background:url(../images/dhoom/logo-dhoom.png) no-repeat;
    background-size:contain
}
.qexch .header h1{
    width:180px;
    height:40px;
    background:url(../images/qexch/logo-qexch.png) no-repeat;
    background-size:contain
}
.iceinplay .header h1{
    width:120px;
    height:62px;
    background:url(../images/iceinplay/logo-iceinplay.png) no-repeat;
    background-size:contain
}
.betmygame .header h1{
    width:155px;
    height:48px;
    background:url(../images/betmygame/logo-betmygame.png) no-repeat;
    background-size:contain
}
.fancyfair .header h1{
    width:234px;
    height:48px;
    background:url(../images/fancyfair/logo-fancyfair.png) no-repeat;
    background-size:contain
}
.maruti .header h1,.marutibook .header h1{
    width:170px;
    height:60px;
    background:url(../images/maruti/logo-maruti.png) no-repeat
}
.bdtwin247 .header h1{
    width:158px;
    height:53px;
    background:url(../images/bdtwin247/logo-bdtwin247.png) no-repeat
}
.oceanexch1 .header h1{
    width:128px;
    height:45px;
    background:url(../images/oceanexch1/logo-oceanexch1.png) no-repeat
}
.oceanbook1 .header h1{
    width:128px;
    height:45px;
    background:url(../images/oceanbook1/logo-oceanbook1.png) no-repeat
}
.wicketspro .header h1{
    width:143px;
    height:34px;
    background:url(../images/9wicketspro/logo-9wicketspro.png) no-repeat
}
.yabo101sport .header h1{
    width:168px;
    height:34px;
    background:url(../images/yabo101sport/logo-yabo101sport.png) no-repeat
}
.aecricket888 .header h1{
    width:82px;
    height:39px;
    background:url(../images/aecricket888/logo-aecricket888.png) no-repeat
}
.cftbet365 .header h1{
    width:160px;
    height:59px;
    background:url(../images/cftbet365/logo-cftbet365.png) no-repeat
}
.crickzoom .header h1{
    width:148px;
    height:54px;
    background:url(../images/crickzoom/logo-crickzoom.png) no-repeat
}
.crickzoomlive .header h1{
    width:148px;
    height:54px;
    background:url(../images/crickzoomlive/logo-crickzoomlive.png) no-repeat
}
.wickets247 .header h1{
    width:158px;
    height:53px;
    background:url(../images/9wickets247/logo-9wickets247.png) no-repeat
}
.crickex .header h1{
    width:189px;
    height:32px;
    background:url(../images/crickex/logo-crickex.png) no-repeat
} */
.icon-time,
.icon-live,
.icon-irun,
.icon-predict,
.icon-cashout {
    width: 15px;
    height: 15px;
    margin: 0 5px 0 0;
    vertical-align: top
}

.login-panel {
    color: #243A48;
    margin: 100px 0 0 25px
}

.login-panel dt {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px
}

.login-panel dd {
    position: relative;
    width: 220px;
    margin-bottom: 7px
}

.login-panel input,
.login-panel textarea,
.login-panel .member_select a,
.member_select .login-panel a {
    width: 100%;
    height: 33px;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid #aaa;
    box-shadow: inset 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
    margin: 0
}

.login-panel input:focus,
.login-panel textarea:focus,
.login-panel .member_select a:focus,
.member_select .login-panel a:focus {
    padding: 5px
}

.login-panel .btn-send {
    width: 218px;
    height: 36px;
    line-height: 36px;
    font-size: 15px;
    margin: 15px 0 0
}

.change_pass {
    padding: 30px 20px;
    font-size: 15px;
    line-height: 20px
}

.change_pass h2 {
    font-size: 25px;
    line-height: 30px;
    color: #243A48;
    margin-bottom: 10px
}

.change_pass {
    text-align: left;
    padding-bottom: 40px
}

.change_pass .login-panel {
    width: 230px;
    margin: 0
}

.change_pass dd {
    width: 230px
}

.change_pass .btn-send {
    width: 228px
}

.change_pass .note {
    width: calc(100% - 230px - 27px);
    font-size: 12px;
    color: #666;
    line-height: 17px;
    margin: 40px 12px 0 15px
}

.btn-back.spark,
.spark.btn-lay {
    -webkit-animation: sparkBack 0.5s ease;
    animation: sparkBack 0.5s ease
}

@-webkit-keyframes sparkBack {
    0% {
        background-color: #72BBEF;
        color: #1E1E1E
    }

    50% {
        background-color: #F8E71C;
        color: #fff
    }

    100% {
        background-color: #72BBEF;
        color: #1E1E1E
    }
}

@keyframes sparkBack {
    0% {
        background-color: #72BBEF;
        color: #1E1E1E
    }

    50% {
        background-color: #F8E71C;
        color: #fff
    }

    100% {
        background-color: #72BBEF;
        color: #1E1E1E
    }
}

@-webkit-keyframes sparkBack_2 {
    0% {
        background-color: #B7D5EB;
        color: #1E1E1E
    }

    50% {
        background-color: #F8E71C;
        color: #fff
    }

    100% {
        background-color: #B7D5EB;
        color: #1E1E1E
    }
}

@keyframes sparkBack_2 {
    0% {
        background-color: #B7D5EB;
        color: #1E1E1E
    }

    50% {
        background-color: #F8E71C;
        color: #fff
    }

    100% {
        background-color: #B7D5EB;
        color: #1E1E1E
    }
}

@-webkit-keyframes sparkBack_3 {
    0% {
        background-color: #D7E8F4;
        color: #1E1E1E
    }

    50% {
        background-color: #F8E71C;
        color: #fff
    }

    100% {
        background-color: #D7E8F4;
        color: #1E1E1E
    }
}

@keyframes sparkBack_3 {
    0% {
        background-color: #D7E8F4;
        color: #1E1E1E
    }

    50% {
        background-color: #F8E71C;
        color: #fff
    }

    100% {
        background-color: #D7E8F4;
        color: #1E1E1E
    }
}

.btn-lay.spark {
    -webkit-animation: sparkLay 0.5s ease;
    animation: sparkLay 0.5s ease
}

@-webkit-keyframes sparkLay {
    0% {
        background-color: #FAA9BA;
        color: #1E1E1E
    }

    50% {
        background-color: #26F1F8;
        color: #fff
    }

    100% {
        background-color: #FAA9BA;
        color: #1E1E1E
    }
}

@keyframes sparkLay {
    0% {
        background-color: #FAA9BA;
        color: #1E1E1E
    }

    50% {
        background-color: #26F1F8;
        color: #fff
    }

    100% {
        background-color: #FAA9BA;
        color: #1E1E1E
    }
}

@-webkit-keyframes sparkLay_2 {
    0% {
        background-color: #EFD3D9;
        color: #1E1E1E
    }

    50% {
        background-color: #26F1F8;
        color: #fff
    }

    100% {
        background-color: #EFD3D9;
        color: #1E1E1E
    }
}

@keyframes sparkLay_2 {
    0% {
        background-color: #EFD3D9;
        color: #1E1E1E
    }

    50% {
        background-color: #26F1F8;
        color: #fff
    }

    100% {
        background-color: #EFD3D9;
        color: #1E1E1E
    }
}

@-webkit-keyframes sparkLay_3 {
    0% {
        background-color: #F6E6EA;
        color: #1E1E1E
    }

    50% {
        background-color: #26F1F8;
        color: #fff
    }

    100% {
        background-color: #F6E6EA;
        color: #1E1E1E
    }
}

@keyframes sparkLay_3 {
    0% {
        background-color: #F6E6EA;
        color: #1E1E1E
    }

    50% {
        background-color: #26F1F8;
        color: #fff
    }

    100% {
        background-color: #F6E6EA;
        color: #1E1E1E
    }
}

input:focus,
textarea:focus,
.member_select a:focus,
.pt_allowed_box input:focus,
.pt_allowed_box textarea:focus,
.tab-transfer input:focus,
.tab-transfer textarea:focus,
.tab-transfer input.type-mark:focus,
.tab-transfer textarea.type-mark:focus,
.tab-transfer .member_select a.type-mark:focus,
.member_select .tab-transfer a.type-mark:focus {
    border-width: 1px;
    border-color: #2789CE;
    box-shadow: 0 0 4px 2px rgba(114, 187, 239, 0.8)
}

.top_player-wrap,
.racing-wrap,
.game-wrap,
.game-highlight-wrap,
.favor-wrap,
.sub_path,
.pop-title {
    background-color: #fff;
    border-bottom: 1px solid #7E97A7;
    margin-bottom: 1px
}

.top_player-wrap h3,
.racing-wrap h3,
.game-wrap h3,
.game-highlight-wrap h3,
.favor-wrap h3,
.sub_path h3,
.pop-title h3 {
    color: #fff;
    font-size: 12px;
    line-height: 25px;
    font-weight: normal;
    padding-left: 10px;
    background-repeat: repeat-x;
    background-position: 0 -6148px
}

.top_player-wrap h3 a,
.racing-wrap h3 a,
.game-wrap h3 a,
.game-highlight-wrap h3 a,
.favor-wrap h3 a,
.sub_path h3 a,
.pop-title h3 a {
    color: #fff
}

.top_player-wrap h3 a:hover,
.racing-wrap h3 a:hover,
.game-wrap h3 a:hover,
.game-highlight-wrap h3 a:hover,
.favor-wrap h3 a:hover,
.sub_path h3 a:hover,
.pop-title h3 a:hover {
    text-decoration: none
}

.top_player-wrap h3 img,
.racing-wrap h3 img,
.game-wrap h3 img,
.game-highlight-wrap h3 img,
.favor-wrap h3 img,
.sub_path h3 img,
.pop-title h3 img {
    margin-top: 5px
}

.top_player-wrap .to-expand,
.racing-wrap .to-expand,
.game-wrap .to-expand,
.game-highlight-wrap .to-expand,
.favor-wrap .to-expand,
.sub_path .to-expand,
.pop-title .to-expand {
    background-position: 100% -1925px
}

.close.top_player-wrap,
.close.racing-wrap,
.close.game-wrap,
.close.game-highlight-wrap,
.close.favor-wrap,
.close.sub_path,
.close.pop-title {
    height: 25px;
    overflow: hidden
}

.close.top_player-wrap .to-expand,
.close.racing-wrap .to-expand,
.close.game-wrap .to-expand,
.close.game-highlight-wrap .to-expand,
.close.favor-wrap .to-expand,
.close.sub_path .to-expand,
.close.pop-title .to-expand {
    background-position: 100% -1900px
}

.top_player-wrap,
.racing-wrap {
    margin-bottom: 0
}

.top_player-wrap h3,
.racing-wrap h3 {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    background-position: 0 -6273px
}

.top_player-wrap .to-expand,
.racing-wrap .to-expand {
    background-position: 100% -299px
}

.close.top_player-wrap,
.close.racing-wrap {
    border-bottom-color: #eee
}

.close.top_player-wrap .to-expand,
.close.racing-wrap .to-expand {
    background-position: 100% -110px
}

.game-wrap,
.game-highlight-wrap {
    border-bottom: 1px solid #7E97A7;
    margin-bottom: 15px
}

.game-wrap h3,
.game-highlight-wrap h3 {
    background: #243A48
}

.game-wrap h4,
.game-highlight-wrap h4,
.game-wrap .tc-content h5,
.tc-content .game-wrap h5,
.game-highlight-wrap .tc-content h5,
.tc-content .game-highlight-wrap h5 {
    background: #3b5667
}

.game-wrap .close-info,
.game-highlight-wrap .close-info {
    padding-left: 10px;
    color: #FFB80C
}

.game-wrap .to-expand,
.game-highlight-wrap .to-expand {
    -webkit-box-flex: 1;
    flex: 1;
    background-position: 100% -1714px
}

.close.game-wrap,
.close.game-highlight-wrap {
    border-bottom-color: #eee
}

.close.game-wrap .to-expand,
.close.game-highlight-wrap .to-expand {
    background-position: 100% -1505px
}

.o_spondemo .game-wrap .to-expand,
.o_spondemo .game-highlight-wrap .to-expand {
    background-position: 100% -1689px
}

.o_spondemo .close.game-wrap .to-expand,
.o_spondemo .close.game-highlight-wrap .to-expand {
    background-position: 100% -1480px
}

.game-team .game-name span {
    background-color: #fff;
    color: #243A48;
    padding: 1px 8px;
    border-radius: 4px;
    margin: 0 5px
}

.game-team {
    width: 100%;
    background-color: #1B2D38;
    color: #fff;
    line-height: 22px;
    font-size: 18px;
    font-weight: bold
}

.game-team td {
    position: relative;
    width: 40%;
    height: 28px;
    vertical-align: top
}

.game-team .game-name {
    padding: 5px 10px 5px 30px
}

.game-team .game-name span {
    font-size: 15px
}

.pop-warp .game-team .game-name,
.log-wrap .game-team .game-name,
.streaming-pop .game-team .game-name,
.log-wrap .game-team .game-name {
    padding-left: 10px
}

.disable .btn-back,
.disable .btn-lay {
    background-color: #ddd;
    color: #999
}

.disable .btn-back:hover,
.disable .btn-lay:hover {
    background-color: #ddd
}

.btn-book {
    width: 38px;
    height: 27px;
    line-height: 27px;
    background-color: #FFCC51;
    border: 1px solid #CF9A47;
    border-radius: 5px;
    color: #1E1E1E
}

.account-wrap ul,
.selection-list {
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    z-index: 99
}

.account-wrap ul li,
.selection-list li {
    list-style: none
}

.account-wrap ul a,
.selection-list a {
    padding: 0 10px;
    border-bottom: 1px solid #E0E6E6
}

.account-wrap ul a:hover,
.selection-list a:hover {
    text-decoration: none;
    background-color: #EFF2F2
}

.power-wrap {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 8px;
    padding: 5px 10px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 30px;
    -webkit-box-align: stretch;
    align-items: stretch
}

.power-wrap h3 {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    padding-right: 7px;
    margin-right: 7px
}

.power-wrap h3 span {
    font-size: 10px;
    font-weight: normal
}

.power-wrap h3 img {
    background-position: 0 -1064px;
    height: 18px;
    width: 107px
}

.power-wrap p {
    -webkit-box-flex: 1;
    flex: 1;
    font-size: 11px;
    line-height: 14px;
    margin: 0;
    padding-left: 5px
}

.power-wrap a {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: underline
}

.power-wrap-b {
    width: 107px;
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.4);
    padding: 5px 10px 7px;
    margin: 0 auto 20px
}

.power-wrap-b span {
    font-size: 10px;
    font-weight: normal
}

.power-wrap-b img {
    background-position: 0 -1046px;
    height: 18px;
    width: 107px
}

.browser-wrap {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.6)
}

.browser-wrap img {
    margin-bottom: 5px
}

.status_but .but_active.disable,
.status_but .disable.but_suspend,
.status_but .disable.but_locked {
    background: #fff;
    color: #B0B0B0;
    border: #DDD 1px solid;
    cursor: not-allowed
}

.status-active,
.status-suspend,
.status-lock {
    font-size: 11px;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 3px
}

.status-active img,
.status-suspend img,
.status-lock img {
    margin-right: 5px
}

.sys-suspend,
.sys-lock {
    margin-bottom: 7px
}

.status_id.sys-suspend,
.status_id.sys-lock {
    padding: 7px
}

.sys-suspend .status-suspend,
.sys-lock .status-suspend,
.sys-suspend .status-lock,
.sys-lock .status-lock {
    float: none
}

.btn_list-DW.select-D:after,
.btn_list-DW.select-W:after {
    position: absolute;
    right: -25px;
    width: 12px;
    font-size: 15px;
    font-weight: normal;
    line-height: 30px;
    z-index: 2
}

.login-wrap .btn-login,
.login-wrap .btn-signup {
    width: 80px;
    height: 25px;
    line-height: 25px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    border-radius: 4px
}

.login-wrap .btn-login:hover,
.login-wrap .btn-signup:hover {
    text-decoration: none
}

.tag-live strong:before,
.age_box li .secondary:before,
.age_box li .btn-send:before {
    content: ""
}

@-webkit-keyframes cooldown {
    0% {
        width: 100%;
        opacity: 0
    }

    5% {
        width: 95%;
        opacity: 1
    }

    95% {
        width: 5%;
        opacity: 1
    }

    100% {
        width: 0;
        opacity: 0
    }
}

@keyframes cooldown {
    0% {
        width: 100%;
        opacity: 0
    }

    5% {
        width: 95%;
        opacity: 1
    }

    95% {
        width: 5%;
        opacity: 1
    }

    100% {
        width: 0;
        opacity: 0
    }
}

.tag-live strong:before {
    -webkit-animation: twinkling 1.4s infinite ease;
    animation: twinkling 1.4s infinite ease
}

@-webkit-keyframes twinkling {

    0%,
    100% {
        opacity: 1
    }

    50% {
        opacity: 0.3
    }
}

@keyframes twinkling {

    0%,
    100% {
        opacity: 1
    }

    50% {
        opacity: 0.3
    }
}

@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}

.skyinplay .account-wrap .a-refresh img,
.lucky7 .account-wrap .a-refresh img,
.qexch .account-wrap .a-refresh img,
.oceanexch1 .account-wrap .a-refresh img,
.oceanbook1 .account-wrap .a-refresh img,
.probetx .account-wrap .a-refresh img,
.crickzoom .account-wrap .a-refresh img,
.crickzoomlive .account-wrap .a-refresh img,
.probet247 .account-wrap .a-refresh img,
.crickex .account-wrap .a-refresh img,
.iceinplay .account-wrap .a-refresh img,
.dhoom .account-wrap .a-refresh img,
.agexch247 .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.o_12bet .account-wrap .a-wallet .red,
.o_dafabet .account-wrap .a-wallet .red,
.bdtwin .account-wrap .a-wallet .red,
.skyinplay .account-wrap .a-wallet .red,
.bigexch .account-wrap .a-wallet .red,
.matador .account-wrap .a-wallet .red,
.lucky7 .account-wrap .a-wallet .red,
.qexch .account-wrap .a-wallet .red,
.fancyfair .account-wrap .a-wallet .red,
.maruti .account-wrap .a-wallet .red,
.marutibook .account-wrap .a-wallet .red,
.bdtwin247 .account-wrap .a-wallet .red,
.oceanexch1 .account-wrap .a-wallet .red,
.oceanbook1 .account-wrap .a-wallet .red,
.probetx .account-wrap .a-wallet .red,
.cftbet365 .account-wrap .a-wallet .red,
.crickzoom .account-wrap .a-wallet .red,
.crickzoomlive .account-wrap .a-wallet .red,
.probet247 .account-wrap .a-wallet .red,
.goexchange .account-wrap .a-wallet .red,
.sgexch247 .account-wrap .a-wallet .red,
.sixball .account-wrap .a-wallet .red,
.sixballio .account-wrap .a-wallet .red,
.wickets247 .account-wrap .a-wallet .red,
.crickex .account-wrap .a-wallet .red,
.iceEX .account-wrap .a-wallet .red,
.iceinplay .account-wrap .a-wallet .red,
.dhoom .account-wrap .a-wallet .red,
.agexch247 .account-wrap .a-wallet .red,
.sportsexchlive .account-wrap .a-wallet .red {
    background-color: #fff;
    padding: 0 3px;
    border-radius: 2px
}

.top {
    min-width: 1350px;
    background: #000 url(../images/skyexch/bg-top-skyEX.jpg);
    background-attachment: fixed;
    margin-bottom: 1px
}

.header {
    height: 54px;
    padding-top: 20px
}

.header.full-wrap {
    width: 100%;
    min-height: inherit;
    height: 54px
}

.header h1 {
    margin: -2px 15px 0 0
}

.header a {
    height: 100%
}

.login-wrap li {
    margin: 0 5px 2px 0
}

.login-wrap li.user {
    padding-left: 22px;
    background-position: -96px -5692px
}

.login-wrap li.re_pass {
    width: 140px;
    padding-left: 22px;
    clear: both
}

.login-wrap li.re_pass input,
.login-wrap li.re_pass textarea,
.login-wrap li.re_pass .member_select a,
.member_select .login-wrap li.re_pass a {
    width: auto;
    margin-right: 5px
}

.login-wrap li.other_link,
.login-wrap li.re_pass {
    font-size: 11px
}

.login-wrap input,
.login-wrap textarea,
.login-wrap .member_select a,
.member_select .login-wrap a {
    width: 130px;
    height: 25px;
    margin: 0
}

.login-wrap a {
    color: #1E1E1E;
    text-decoration: underline
}

.login-wrap .btn-login {
    background-color: #E83523;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F72424), to(#BB1C00));
    background-image: -webkit-linear-gradient(top, #F72424 0%, #BB1C00 100%);
    background-image: linear-gradient(-180deg, #F72424 0%, #BB1C00 100%)
}

.login-wrap .btn-login img {
    width: 10px;
    height: 11px;
    margin: -3px 0 0 3px;
    vertical-align: middle;
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.login-wrap .btn-signup {
    display: none;
    width: 76px
}

.login-wrap .valid-code {
    position: relative
}

.login-wrap .valid-code img {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 55px;
    height: 15px
}

.login-wrap .error {
    clear: both;
    background-color: transparent;
    padding-left: 22px;
    color: #024168;
    line-height: 1.1
}

.account-wrap {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center
}

.account-wrap li {
    position: relative;
    margin-left: 15px
}

.account-wrap li .refresh-bar {
    width: 80px;
    height: 10px;
    background: url(../images/refresh80.gif) no-repeat center;
    margin-top: 8px
}

.account-wrap a {
    -webkit-box-align: center;
    align-items: center;
    color: #1E1E1E
}

.account-wrap .main-wallet {
    -webkit-box-flex: 1;
    flex: 1;
    width: auto;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px
}

.account-wrap .main-wallet:hover,
.account-wrap .main-wallet:focus,
.account-wrap .main-walletactive {
    box-shadow: none
}

.account-wrap .main-wallet p {
    font-weight: bold;
    margin-right: 7px;
    line-height: 1.4
}

.account-wrap .main-wallet ul {
    position: relative;
    display: -webkit-box;
    display: flex;
    width: auto;
    box-shadow: none;
    background: none
}

.account-wrap .main-wallet ul:not(:target) {
    display: -webkit-box;
    display: flex
}

.account-wrap .main-wallet ul li {
    line-height: 24px;
    margin-left: 15px
}

.account-wrap .main-wallet .a-wallet {
    height: auto;
    line-height: initial;
    border: 0;
    border-radius: 4px 0 0 4px;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
    padding: 0
}

.account-wrap .main-wallet .a-wallet ul {
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    width: auto;
    height: 100%;
    line-height: 12px;
    background-color: initial !important;
    padding: 0 7px
}

.account-wrap .main-wallet .a-wallet li {
    font-weight: bold;
    margin-right: 7px;
    margin-left: 0
}

.account-wrap .main-wallet .a-wallet li:last-child {
    margin-right: 0
}

.account-wrap .main-wallet .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.3) !important;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px 0 0 3px
}

.account-wrap .main-wallet .a-wallet.open ul {
    box-shadow: none
}

.account-wrap .main-wallet .a-wallet:hover {
    text-decoration: none
}

.account-wrap .main-wallet .a-wallet:hover li:not(.nums) {
    text-decoration: underline
}

.account-wrap .main-wallet span:first-of-type {
    opacity: 0.7;
    font-weight: normal
}

.account-wrap .main-wallet .nums {
    height: 14px;
    font-size: 11px;
    line-height: 14px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    padding: 0 8px
}

.account-wrap .main-wallet .nums span {
    opacity: 1;
    font-size: 10px;
    font-weight: bold;
    margin-left: 1px
}

.account-wrap .a-refresh {
    width: 24px;
    height: 24px;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0 4px 4px 0;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5)
}

.account-wrap .a-refresh img {
    height: 14px;
    background: url("data:image/svg+xml,<svg width=\"13\" height=\"16\" viewBox=\"0 0 13 16\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z\" fill=\"rgb%28255, 255, 255%29\"/></svg>") no-repeat center center;
    background-size: contain;
    /* filter: grayscale(1); */
}

.account-wrap p.loading-bar {
    width: 80px;
    height: 23px;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0 10px 0 15px;
    border-radius: 4px 0 0 4px
}

.account-wrap p.loading-bar span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #c7342c;
    margin-right: 6px;
    opacity: 0;
    -webkit-animation: loadBar 0.8s ease infinite;
    animation: loadBar 0.8s ease infinite
}

.account-wrap p.loading-bar span:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s
}

.account-wrap p.loading-bar span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s
}

.account-wrap p.loading-bar span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s
}

.account-wrap p.loading-bar span:nth-child(4) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s
}

.account-wrap p.loading-bar span:nth-child(5) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s
}

.account-wrap p.loading-bar span:nth-child(6) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s
}

.account-wrap p.loading-bar span:nth-child(7) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s
}

.account-wrap p.loading-bar span:nth-child(8) {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
    margin-right: 0
}

@-webkit-keyframes loadBar {
    0% {
        opacity: 0.1
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
    }

    100% {
        opacity: 0.1
    }
}

@keyframes loadBar {
    0% {
        opacity: 0.1
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
    }

    100% {
        opacity: 0.1
    }
}

.account-wrap .main-wallet.no-multi {
    border: 0;
    background: none !important
}

.account-wrap .main-wallet.no-multi .a-wallet {
    background-color: transparent !important;
    box-shadow: none
}

.account-wrap .main-wallet.no-multi li {
    font-weight: bold
}

.account-wrap .main-wallet.no-multi .red {
    background: none
}

.account-wrap .main-wallet.no-multi .nums {
    display: none
}

.account-wrap .main-wallet.no-multi .loading-bar {
    background: none;
    box-shadow: none;
    padding: 0 0 0 15px
}

.account-wrap .main-wallet.no-multi .a-refresh {
    width: 28px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px
}

.account-wrap .account {
    text-indent: 27px;
    background-position: 0 -1050px;
    height: 25px;
    width: 110px
}

.account-wrap .account.select {
    background-position: 0 -1075px
}

.account-wrap .account li {
    line-height: 25px
}

.account-wrap ul {
    display: none;
    right: 0;
    width: 220px
}

.account-wrap ul:not(:target) {
    display: none
}

.account-wrap ul:target {
    display: block
}

.account-wrap ul li {
    float: none;
    text-indent: 0;
    margin: 0
}

.account-wrap ul h4,
.account-wrap ul .tc-content h5,
.tc-content .account-wrap ul h5 {
    line-height: 27px;
    color: #3B5160;
    border-bottom: 1px solid #7E97A7;
    padding: 0 0 0 10px
}

.account-wrap ul a {
    color: #1E1E1E
}

.account-wrap ul .logout {
    padding: 7px 10px 5px
}

.account-wrap ul .logout a {
    background-color: #7E97A7;
    border-bottom-width: 0;
    color: #fff;
    font-weight: bold;
    border-radius: 4px
}

.account-wrap ul .logout img {
    width: 10px;
    height: 11px;
    vertical-align: middle;
    margin: -2px 0 0 5px;
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.account-wrap .wallet-detail:not(:target) {
    display: none
}

.account-wrap .wallet-detail:target {
    display: block
}

.wallet-detail {
    display: none;
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    font-size: 12px;
    color: #3B5160;
    background-color: #E0E6E6;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    z-index: 99
}

.wallet-detail .btn,
.wallet-detail .btn-send,
.wallet-detail .pages a,
.pages .wallet-detail a,
.wallet-detail .calendarTable_inputBox input,
.calendarTable_inputBox .wallet-detail input,
.wallet-detail .calendarTable_inputBox textarea,
.calendarTable_inputBox .wallet-detail textarea,
.wallet-detail .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .wallet-detail a,
.wallet-detail .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .wallet-detail a,
.wallet-detail .total_all .btn_replay,
.total_all .wallet-detail .btn_replay,
.wallet-detail .btn_expand,
.wallet-detail .add_member {
    width: 100%;
    font-size: 13px;
    font-weight: 900;
    line-height: 31px;
    margin-bottom: 0
}

.wallet-detail-group {
    margin: 12px 7px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3)
}

.wallet-detail-group .wallet-detail-content {
    padding: 10px 7px 0
}

.wallet-detail-group .wallet-detail-content dt {
    line-height: 1.6;
    margin-bottom: 3px
}

.wallet-detail-group .wallet-detail-content dd {
    padding-bottom: 10px
}

.wallet-detail-group .wallet-balance-num {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 17px;
    line-height: 1.2;
    font-weight: bold;
    padding-right: 80px;
    color: #3B5160
}

.wallet-detail-group .wallet-exposure {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-weight: normal !important;
    padding: 7px 0;
    border-top: 1px solid #E0E6E6
}

.wallet-detail-group .wallet-exposure span {
    color: #1E1E1E
}

.wallet-detail-group:nth-child(2) .wallet-detail-content {
    position: relative;
    border-bottom: 1px solid #ccc
}

.wallet-detail-group:nth-child(2) .wallet-detail-content .wallet-recall {
    position: absolute;
    top: 50%;
    right: 7px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: right;
    padding: 0
}

.wallet-detail-group:nth-child(2) .wallet-detail-content:last-child {
    border-bottom: none
}

.wallet-detail-group .btn-box {
    width: 100%;
    padding-top: 10px
}

.badge-currency {
    margin-right: 3px;
    padding: 0 5px;
    line-height: 16px;
    font-size: 10px;
    font-weight: bold !important;
    color: #fff;
    background-color: #5F849D;
    border-radius: 3px
}

.btn-recall {
    color: #3B5160;
    font-size: 15px;
    font-weight: bold;
    line-height: 2.2;
    padding: 0 8px;
    background-color: rgba(94, 190, 255, 0.15);
    border-radius: 4px;
    border: 1px solid #7E97A7;
    cursor: pointer
}

.btn-recall:hover {
    background-color: rgba(94, 190, 255, 0.3)
}

.language {
    width: 29px;
    height: 11px;
    padding: 7px;
    background-position: -67px -1125px
}

.flag {
    width: 17px;
    height: 11px
}

.flag.UK {
    background-position: 0 0
}

.menu-wrap {
    min-width: 1350px;
    background-color: #1E1E1E;
    line-height: 30px
}

.menu-wrap a {
    color: #fff;
    padding: 0 10px
}

.menu-wrap a.menu-drop:after,
.menu-wrap a.menu-drop.select:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 3px 0 3px;
    /* border-color:#a5a5a5 transparent transparent transparent; */
    margin-left: 10px;
    vertical-align: middle;
    content: ''
}

.menu-wrap a.one_click {
    padding: 0 12px 0 7px
}

.menu-wrap .full-wrap {
    min-height: 30px;
    height: auto
}

.menu,
.setting-wrap {
    font-weight: bold
}

.menu li,
.setting-wrap li {
    border-right: 1px solid #383838
}

.menu li ul,
.setting-wrap li ul {
    display: none;
    position: absolute;
    background-color: #1E1E1E;
    border-top: 1px solid #383838;
    z-index: 99
}

.menu li li,
.setting-wrap li li {
    float: none;
    border-bottom: 1px solid #383838
}

.menu a,
.setting-wrap a {
    position: relative
}

.setting-wrap a:hover {
    background-color: #383838;
    text-decoration: none
}

.menu .select,
.setting-wrap .select {
    background-color: #4d4d4d
}

.menu .dark_menu-li,
.setting-wrap .dark_menu-li {
    border-right: 1px solid #383838 !important
}

.menu .casino,
.setting-wrap .casino,
.menu .bet-game,
.setting-wrap .bet-game {
    position: relative;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4B4B4B), to(#1E1E1E));
    background-image: -webkit-linear-gradient(#4B4B4B, #1E1E1E);
    background-image: linear-gradient(#4B4B4B, #1E1E1E)
}

.menu .casino:after,
.setting-wrap .casino:after,
.menu .bet-game:after,
.setting-wrap .bet-game:after {
    content: '';
    display: inline-block;
    position: relative;
    top: 4px;
    left: 2px;
    width: 18px;
    height: 18px;
    background-position: 100% -6759px
}

.menu .casino:hover,
.setting-wrap .casino:hover,
.menu .bet-game:hover,
.setting-wrap .bet-game:hover {
    background: -webkit-gradient(linear, left bottom, left top, from(#4B4B4B), to(#1E1E1E));
    background: -webkit-linear-gradient(bottom, #4B4B4B, #1E1E1E);
    background: linear-gradient(0deg, #4B4B4B, #1E1E1E)
}

.menu .tag-new,
.setting-wrap .tag-new {
    width: inherit;
    height: inherit;
    color: #FFFFFF;
    line-height: inherit;
    font-size: inherit;
    -webkit-filter: none;
    filter: none;
    padding-left: 20px
}

.menu .tag-new:before,
.setting-wrap .tag-new:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-position: 100% -6777px;
    height: 29px;
    width: 29px
}

.menu .bet-game:after,
.setting-wrap .bet-game:after {
    left: 5px;
    width: 18px;
    height: 19px;
    background-position: 100% -6740px
}

.setting-wrap {
    font-weight: bold
}

.setting-wrap li {
    border-right-width: 0
}

.live-menu {
    border-left: 1px solid #383838
}

.live-menu img {
    margin: 6px 7px 0 0;
    vertical-align: top;
    background-position: -89px -4674px;
    height: 18px;
    width: 21px
}

.setting {
    border-left: 1px solid #383838
}

.setting img {
    width: 12px;
    height: 12px;
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat;
    vertical-align: middle;
    margin: 0 0 3px 5px
}

.one_click {
    border-top: 3px solid #6BBD11;
    margin-top: -3px;
    background-repeat: repeat-x;
    padding: 0 12px 0 7px;
    background-position: 0 -4080px
}

.one_click:hover,
.one_click.bg-1click-hover {
    background-position: 0 -2310px
}

.one_click img {
    margin-right: 5px;
    vertical-align: top;
    background-position: -94px -4390px;
    height: 30px;
    width: 16px
}

.one_click.select {
    background-repeat: repeat-x;
    background-position: 0 -3570px
}

.one_click.select:hover,
.one_click.select.bg-1click-select-hover {
    background-position: 0 -3540px
}

.one_click.select img {
    background-position: -94px -4120px
}

.slip_set-pop,
.oneclick_set-pop {
    display: none;
    position: absolute;
    top: 30px;
    right: 0;
    width: 282px;
    z-index: 99;
    border-radius: 0 0 4px 4px
}

.slip_set-pop .slip_set,
.oneclick_set-pop .slip_set {
    z-index: 1;
    background-position: -51px -619px;
    height: 25px;
    width: 29px
}

.slip_set-pop:not(:target),
.oneclick_set-pop:not(:target) {
    display: none
}

.slip_set-pop:target,
.oneclick_set-pop:target {
    display: block
}

.set-content {
    *height: auto !important;
    *height: 100px;
    min-height: 100px;
    background-color: #E0E6E6;
    font-size: 11px;
    color: #3B5160;
    padding: 10px;
    border-radius: 0 0 4px 4px
}

.set-content input,
.set-content textarea,
.set-content .member_select a,
.member_select .set-content a {
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    margin: 0 5px 0 0
}

.set-content a {
    color: #1E1E1E;
    padding: 0
}

.set-content .btn,
.set-content .btn-send,
.set-content .pages a,
.pages .set-content a,
.set-content .calendarTable_inputBox input,
.calendarTable_inputBox .set-content input,
.set-content .calendarTable_inputBox textarea,
.calendarTable_inputBox .set-content textarea,
.set-content .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .set-content a,
.set-content .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .set-content a,
.set-content .total_all .btn_replay,
.total_all .set-content .btn_replay,
.set-content .btn_expand,
.set-content .add_member {
    line-height: 18px;
    font-weight: normal;
    font-size: 11px;
    margin: 0
}

.set-content .btn-wrap {
    margin-bottom: 0
}

.set-content .btn-wrap .btn,
.set-content .btn-wrap .btn-send,
.set-content .btn-wrap .pages a,
.pages .set-content .btn-wrap a,
.set-content .btn-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .set-content .btn-wrap input,
.set-content .btn-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .set-content .btn-wrap textarea,
.set-content .btn-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .set-content .btn-wrap a,
.set-content .btn-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .set-content .btn-wrap a,
.set-content .btn-wrap .total_all .btn_replay,
.total_all .set-content .btn-wrap .btn_replay,
.set-content .btn-wrap .btn_expand,
.set-content .btn-wrap .add_member {
    font-weight: bold;
    line-height: 23px
}

.stake-set,
.odds-set {
    position: relative;
    border-bottom: 1px solid #7E97A7;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
    line-height: 15px;
    padding-bottom: 2px;
    margin-bottom: 5px
}

.stake-set dt,
.odds-set dt {
    margin-bottom: 5px;
    font-weight: bold
}

.stake-set dd,
.odds-set dd {
    width: 50px;
    margin: 0 5px 5px 0
}

.stake-set dd.col-defult,
.odds-set dd.col-defult {
    width: 100%
}

.stake-set dd.col-edit,
.odds-set dd.col-edit {
    position: absolute;
    top: 20px;
    right: 0;
    width: 42px;
    margin-right: 0
}

.stake-set .stake-input,
.odds-set .stake-input {
    width: 29.16667%;
    font-size: 11px;
    margin-left: 5px
}

.stake-set .stake_edit-input,
.odds-set .stake_edit-input {
    width: 50px;
    font-size: 11px;
    margin-left: 0
}

.col-edit a {
    height: 43px;
    line-height: 43px;
    border-radius: 4px
}

.col-edit img {
    background-position: -101px -6221px;
    height: 9px;
    width: 9px
}

.col-edit .btn-edit {
    border: 1px solid #7E97A7;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.8);
    color: #3B5160
}

.col-edit .btn-edit:hover {
    text-decoration: underline
}

.col-edit .btn-send {
    line-height: 43px
}

.odds-set dd {
    width: 100%
}

.oneclick_set-pop {
    right: 96px
}

.time_zone {
    color: #fff;
    margin: 0;
    padding: 0 12px
}

.time_zone span {
    color: rgba(255, 255, 255, 0.6);
    font-weight: normal
}

.gmt {
    font-size: 11px;
    border-left: 1px solid #C5D0D7;
    padding: 0 5px
}

.promote-wrap {
    position: absolute;
    width: 260px;
    height: 64px;
    left: calc(50% - 260px / 2);
    top: 5px
}

.promote-wrap img {
    width: 260px;
    height: 64px
}

.menu-list li {
    border-bottom: 1px solid #E0E6E6;
    list-style: none
}

.menu-list a {
    line-height: 25px;
    padding: 0 10px
}

.menu-list a:hover {
    background-color: #EFF2F2;
    text-decoration: none
}

.menu-list .select {
    background-color: #F2DCA7;
    color: #1E1E1E
}

.menu-list .select:hover {
    background-color: #F2DCA7
}

.menu-list .Go {
    padding: 0 0 0 8px;
    background-position: 100% -1800px;
    height: 25px;
    width: 23px
}

.menu-list .Go:hover,
.menu-list .Go.Go-hover {
    background-position: 100% -1825px
}

.star {
    width: 12px;
    height: 11px;
    margin: 4px 5px 0 0;
    background-position: -16px -4286px
}

.star:hover,
.star.star-hover {
    background-position: -16px -4261px
}

.favor-wrap {
    height: 180px
}

.favor-wrap h3 .star {
    background-position: -24px -4250px
}

.favor-wrap .menu-list {
    height: calc(100% - 25px);
    overflow: hidden;
    overflow-y: auto
}

.favor-wrap .menu-list a {
    width: calc(100% - 38px);
    height: 25px;
    vertical-align: middle;
    padding: 0 10px 0 2px
}

.favor-wrap .menu-list a.btn-star {
    padding: 0 0 0 6px;
    background-position: 100% -4286px;
    height: 25px;
    width: 20px
}

.favor-wrap .menu-list a.btn-star:hover,
.favor-wrap .menu-list a.btn-star.star-hover {
    background-position: 100% -4261px
}

.favor-wrap .menu-list a.btn-star:hover {
    background-color: transparent
}

.favor-set {
    line-height: 25px;
    border-bottom-width: 0;
    padding-right: 23px;
    background-position: 100% -1975px
}

.sub_path {
    position: relative
}

.full-wrap .sub_path .menu-list {
    height: calc(100% - 25px);
    overflow: hidden;
    overflow-y: auto
}

.path {
    position: relative;
    height: 25px;
    color: #fff;
    font-size: 12px;
    line-height: 25px;
    overflow: hidden;
    background-repeat: repeat-x;
    background-position: 0 -6148px
}

.path ul {
    position: absolute;
    right: 0;
    width: 9999px
}

.path li {
    display: inline;
    padding: 4px 13px 5px 6px;
    background-position: 100% -1875px
}

.path a {
    display: inline;
    color: #fff
}

.path .path-last {
    background-image: none;
    padding-right: 6px
}

.path .path-back {
    position: absolute;
    left: 0;
    z-index: 2;
    background-position: 0 -4175px;
    height: 25px;
    width: 36px
}

.path .path-back:hover,
.path .path-back.path-back-hover {
    background-position: 0 -4225px
}

.path .path-back:active,
.path .path-back.path-back-active {
    background-position: 0 -4200px
}

.path .path-back.select {
    background-position: 0 -4225px;
    height: 25px;
    width: 36px
}

.path-pop {
    display: none;
    position: absolute;
    top: 25px;
    left: 0;
    max-width: 100%;
    background-color: #243A48;
    padding: 5px 0;
    z-index: 5
}

.path-pop li {
    list-style: none;
    white-space: nowrap
}

.path-pop a {
    color: #fff;
    padding: 5px 10px 5px 15px;
    background-position: -21px -2150px
}

.path-pop:not(:target) {
    display: none
}

.path-pop:target {
    display: block
}

.to-go {
    margin-bottom: 1px;
    background-position: 100% -1950px
}

html,
body {
    background-color: #f0ece1;
    /* background-image: linear-gradient(56deg,#ffcc2e 4%,#ffb80c 42%); */

}

.favor {
    margin-right: 5px;
    background-position: -396px -2772px;
    height: 19px;
    width: 18px
}

.favor.select {
    background-position: -396px -2753px
}

.kv-wrap {
    margin-bottom: 15px
}

.kv-wrap img {
    width: 100%
}

.promo-banner-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    margin-bottom: 15px;
    padding-top: 24.8366%
}

.promo-banner-wrap.active {
    overflow: hidden;
    padding-top: 0;
    height: auto
}

.promo-banner-loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: #000 url(../images/promo/loading.svg) center center no-repeat;
    background-size: 80px
}

.promo-banner img {
    width: 100%;
    height: 100%;
    vertical-align: middle
}

.promo-banner .slides {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    z-index: 2
}

.promo-banner .banner {
    display: none
}

.promo-banner .promo-control-nav {
    position: absolute;
    z-index: 3;
    bottom: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.promo-banner .promo-control-nav li {
    list-style: none;
    margin-left: 8px
}

.promo-banner .promo-control-nav li:first-child {
    margin-left: 0
}

.promo-banner .promo-control-nav li a {
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 999px;
    text-indent: -9999px
}

.promo-banner .promo-control-nav li a.promo-active {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    background-color: rgba(255, 255, 255, 0.8)
}

.promo-banner .promo-direction-nav {
    position: absolute;
    z-index: 3;
    top: calc((100% - 48px) / 2);
    left: 0;
    width: 100%;
    height: 0
}

.promo-banner .promo-direction-nav li {
    opacity: .4
}

.promo-banner .promo-direction-nav li:hover,
.promo-banner .promo-direction-nav li:active {
    opacity: .8
}

.promo-banner .promo-direction-nav .promo-nav-prev,
.promo-banner .promo-direction-nav .promo-nav-next {
    height: 0
}

.promo-banner .promo-direction-nav .promo-prev,
.promo-banner .promo-direction-nav .promo-next {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="rgb(255,255,255)"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path></g></svg>');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain
}

.promo-banner .promo-direction-nav .promo-next {
    left: calc(100% - 48px);
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="rgb(255,255,255)"><path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path></g></svg>')
}

.tag-live {
    position: absolute;
    top: -6px;
    right: 3px;
    min-width: 33px;
    height: 12px;
    border-radius: 3px;
    padding-right: 3px;
    color: #FFFFFF;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#FB3434), to(#E80505));
    background-image: -webkit-linear-gradient(top, #FB3434 0%, #E80505 100%);
    background-image: linear-gradient(180deg, #FB3434 0%, #E80505 100%);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5)
}

.tag-live strong {
    -webkit-box-flex: 1;
    flex: 1;
    height: 100%;
    margin-right: 3px;
    padding: 0 2px;
    border-radius: 3px 0px 0px 3px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(89%, #eee));
    background-image: -webkit-linear-gradient(top, #fff 0%, #eee 89%);
    background-image: linear-gradient(180deg, #fff 0%, #eee 89%)
}

.tag-live strong:before {
    width: 14px;
    height: 8px;
    background: url('data:image/svg+xml,<svg width="14" height="8" xmlns="http://www.w3.org/2000/svg"><g fill="rgb(255,0,0)" fill-rule="evenodd"><path d="M12.012 0l-.698.727c1.734 1.808 1.734 4.738 0 6.546l.698.727c2.117-2.207 2.117-5.79 0-8zM10.3 1.714l-.7.735c.967 1.014.967 2.66 0 3.673l.7.735c1.352-1.418 1.352-3.721 0-5.143zM1.588 0l.698.727c-1.734 1.808-1.734 4.738 0 6.546L1.588 8c-2.117-2.207-2.117-5.79 0-8zM3.3 1.714l.7.735c-.967 1.014-.967 2.66 0 3.673l-.7.735c-1.352-1.418-1.352-3.721 0-5.143z"/><circle cx="6.8" cy="4.4" r="1.6"/></g></svg>') no-repeat;
    background-size: contain
}

.upcome-wrap {
    margin-bottom: 15px;
    background-color: #fff;
    border-bottom: 1px solid #7E97A7
}

.upcome-wrap a {
    font-weight: bold
}

.upcome-head {
    color: #fff;
    line-height: 25px;
    padding-left: 10px;
    background-repeat: repeat-x;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    background-position: 0 -6273px
}

.upcome-head a {
    color: #75BFF5
}

.upcome-head dt {
    padding-right: 13px;
    margin-right: 5px;
    background-position: 100% -3340px
}

.upcome-head dd {
    margin-right: 15px
}

.upcome-head span {
    font-weight: normal;
    color: #C5D0D7;
    padding-left: 5px
}

.upcome {
    height: 18px;
    line-height: 18px;
    padding: 6px 10px
}

.upcome-closed .upcome {
    padding: 10px
}

.upcome dt {
    font-size: 11px;
    line-height: 16px;
    color: #7E97A7;
    border: 1px solid #7E97A7;
    border-radius: 4px;
    padding: 0 5px;
    margin-right: 5px
}

.upcome dd {
    width: auto;
    margin-right: 5px;
    padding-left: 10px;
    background-position: -23px -166px
}

.upcome dd:first-of-type {
    background: none;
    padding-left: 0
}

.game-highlight-wrap h3 {
    background-color: #FFB80C;
    color: #1E1E1E;
    font-weight: bold
}

.game-highlight-wrap .slip-head {
    background-color: #DDDCD6
}

.game-list {
    position: relative
}

.tab-highlight {
    padding: 0 10px;
    background-repeat: repeat-x;
    background-position: 0 -10699px
}

.tab-highlight li {
    list-style: none;
    line-height: 22px;
    margin-right: 3px
}

.tab-highlight a {
    width: 130px;
    color: #fff;
    background-position: -284px -205px
}

.tab-highlight a.select {
    height: 24px;
    color: #1E1E1E;
    margin-bottom: -2px;
    background-position: -284px -227px
}

.tab-highlight a.select:hover {
    text-decoration: none
}

.no-data {
    background-color: #fff;
    padding: 10px 10px 5px !important
}

.message-wrap {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 8px 0;
    background-color: #F8E71C;
    font-size: 14px;
    color: #1E1E1E;
    z-index: 99
}

.message-wrap p {
    line-height: 22px;
    padding: 0 10px;
    margin-bottom: 0
}

.message-wrap .btn-close {
    margin: 6px 10px 0 0
}

.col-center .message-wrap {
    margin-left: -15px
}

.inplay .message-wrap {
    margin-left: 0
}

.player .message-wrap {
    top: 38px;
    margin-left: 0
}

.message-wrap:not(:target) {
    display: none
}

.message-wrap:target {
    display: block
}

.success {
    background-color: #6BBD11
}

.error {
    background-color: #024168;
    color: #fff
}

.warning {
    background-color: #F8E71C;
    color: #1E1E1E
}

.event-left,
.event-right {
    width: 49.5%
}

.event-left .game-list-col,
.event-right .game-list-col,
.event-left .slip-head,
.event-right .slip-head {
    padding-right: 0
}

.event-left dt,
.event-right dt {
    font-size: 11px
}

.event-left .slip-head span,
.event-right .slip-head span {
    width: 49%
}

.event-left .slip-head .game-low_liq,
.event-right .slip-head .game-low_liq {
    width: auto
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 53
}

.overlay:not(:target) {
    display: none
}

.overlay:target {
    display: block
}

.dialog-wrap,
.dialog-wrap-w {
    top: 340px;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 8px;
    color: #fff;
    z-index: 20;
    position: absolute;
    left: 50%;
    width: 320px;
    margin-left: -160px
}

.dialog-wrap h4,
.dialog-wrap-w h4,
.dialog-wrap .tc-content h5,
.tc-content .dialog-wrap h5,
.dialog-wrap-w .tc-content h5,
.tc-content .dialog-wrap-w h5 {
    font-size: 15px;
    line-height: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 7px 0
}

.dialog-wrap .btn-wrap,
.dialog-wrap-w .btn-wrap {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 7px 10px 10px;
    margin-bottom: 0
}

.dialog-wrap .break li,
.dialog-wrap-w .break li {
    width: 100%;
    margin-bottom: 3px
}

.dialog-wrap .break .btn-send,
.dialog-wrap-w .break .btn-send {
    float: none;
    width: 46.42857%;
    margin: 0 auto
}

.dialog-content {
    font-size: 13px;
    line-height: 18px;
    padding: 5px 20px 0
}

.dialog-content .attention {
    color: #F8E71C
}

.dialog-wrap-w {
    background-color: #fff;
    color: #1E1E1E
}

.dialog-wrap-w .btn-wrap {
    border-top-color: rgba(0, 0, 0, 0.2)
}

.transfer-wrap,
.transfer-wrap-slider {
    position: relative;
    width: 540px;
    color: #243A48;
    top: 20%;
    margin-left: -270px
}

.transfer-wrap .transfer-title,
.transfer-wrap-slider .transfer-title {
    padding: 15px 20px;
    border-bottom: 1px solid #8FA6B5
}

.transfer-wrap .transfer-title h2,
.transfer-wrap-slider .transfer-title h2 {
    font-size: 20px;
    margin-bottom: 0;
    padding-top: 0
}

.transfer-wrap .available-wrap,
.transfer-wrap-slider .available-wrap {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center
}

.transfer-wrap .available-wrap h4,
.transfer-wrap-slider .available-wrap h4,
.transfer-wrap .available-wrap .tc-content h5,
.tc-content .transfer-wrap .available-wrap h5,
.transfer-wrap-slider .available-wrap .tc-content h5,
.tc-content .transfer-wrap-slider .available-wrap h5 {
    font-size: 20px;
    line-height: 25px;
    text-align: left;
    padding: 15px 20px;
    width: 50%
}

.transfer-wrap .available-wrap h4 span,
.transfer-wrap-slider .available-wrap h4 span,
.transfer-wrap .available-wrap .tc-content h5 span,
.tc-content .transfer-wrap .available-wrap h5 span,
.transfer-wrap-slider .available-wrap .tc-content h5 span,
.tc-content .transfer-wrap-slider .available-wrap h5 span {
    display: block;
    font-size: 14px;
    font-weight: normal
}

.transfer-wrap .btn-wrap,
.transfer-wrap-slider .btn-wrap {
    display: -webkit-box;
    display: flex;
    border-top: none
}

.transfer-wrap .btn-wrap .btn,
.transfer-wrap-slider .btn-wrap .btn,
.transfer-wrap .btn-wrap .btn-send,
.transfer-wrap-slider .btn-wrap .btn-send,
.transfer-wrap .btn-wrap .pages a,
.pages .transfer-wrap .btn-wrap a,
.transfer-wrap-slider .btn-wrap .pages a,
.pages .transfer-wrap-slider .btn-wrap a,
.transfer-wrap .btn-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .transfer-wrap .btn-wrap input,
.transfer-wrap-slider .btn-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .transfer-wrap-slider .btn-wrap input,
.transfer-wrap .btn-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .transfer-wrap .btn-wrap textarea,
.transfer-wrap-slider .btn-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .transfer-wrap-slider .btn-wrap textarea,
.transfer-wrap .btn-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .transfer-wrap .btn-wrap a,
.transfer-wrap-slider .btn-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .transfer-wrap-slider .btn-wrap a,
.transfer-wrap .btn-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .transfer-wrap .btn-wrap a,
.transfer-wrap-slider .btn-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .transfer-wrap-slider .btn-wrap a,
.transfer-wrap .btn-wrap .total_all .btn_replay,
.total_all .transfer-wrap .btn-wrap .btn_replay,
.transfer-wrap-slider .btn-wrap .total_all .btn_replay,
.total_all .transfer-wrap-slider .btn-wrap .btn_replay,
.transfer-wrap .btn-wrap .btn_expand,
.transfer-wrap-slider .btn-wrap .btn_expand,
.transfer-wrap .btn-wrap .add_member,
.transfer-wrap-slider .btn-wrap .add_member,
.transfer-wrap .btn-wrap .btn-send,
.transfer-wrap-slider .btn-wrap .btn-send {
    -webkit-box-flex: 1;
    flex: 1;
    margin-right: 10px;
    line-height: 31px
}

.transfer-wrap .btn-wrap .btn:nth-child(even),
.transfer-wrap-slider .btn-wrap .btn:nth-child(even),
.transfer-wrap .btn-wrap .btn-send:nth-child(even),
.transfer-wrap-slider .btn-wrap .btn-send:nth-child(even),
.transfer-wrap .btn-wrap .pages a:nth-child(even),
.pages .transfer-wrap .btn-wrap a:nth-child(even),
.transfer-wrap-slider .btn-wrap .pages a:nth-child(even),
.pages .transfer-wrap-slider .btn-wrap a:nth-child(even),
.transfer-wrap .btn-wrap .calendarTable_inputBox input:nth-child(even),
.calendarTable_inputBox .transfer-wrap .btn-wrap input:nth-child(even),
.transfer-wrap-slider .btn-wrap .calendarTable_inputBox input:nth-child(even),
.calendarTable_inputBox .transfer-wrap-slider .btn-wrap input:nth-child(even),
.transfer-wrap .btn-wrap .calendarTable_inputBox textarea:nth-child(even),
.calendarTable_inputBox .transfer-wrap .btn-wrap textarea:nth-child(even),
.transfer-wrap-slider .btn-wrap .calendarTable_inputBox textarea:nth-child(even),
.calendarTable_inputBox .transfer-wrap-slider .btn-wrap textarea:nth-child(even),
.transfer-wrap .btn-wrap .calendarTable_inputBox .member_select a:nth-child(even),
.calendarTable_inputBox .member_select .transfer-wrap .btn-wrap a:nth-child(even),
.transfer-wrap-slider .btn-wrap .calendarTable_inputBox .member_select a:nth-child(even),
.calendarTable_inputBox .member_select .transfer-wrap-slider .btn-wrap a:nth-child(even),
.transfer-wrap .btn-wrap .member_select .calendarTable_inputBox a:nth-child(even),
.member_select .calendarTable_inputBox .transfer-wrap .btn-wrap a:nth-child(even),
.transfer-wrap-slider .btn-wrap .member_select .calendarTable_inputBox a:nth-child(even),
.member_select .calendarTable_inputBox .transfer-wrap-slider .btn-wrap a:nth-child(even),
.transfer-wrap .btn-wrap .total_all .btn_replay:nth-child(even),
.total_all .transfer-wrap .btn-wrap .btn_replay:nth-child(even),
.transfer-wrap-slider .btn-wrap .total_all .btn_replay:nth-child(even),
.total_all .transfer-wrap-slider .btn-wrap .btn_replay:nth-child(even),
.transfer-wrap .btn-wrap .btn_expand:nth-child(even),
.transfer-wrap-slider .btn-wrap .btn_expand:nth-child(even),
.transfer-wrap .btn-wrap .add_member:nth-child(even),
.transfer-wrap-slider .btn-wrap .add_member:nth-child(even),
.transfer-wrap .btn-wrap .btn-send:nth-child(even),
.transfer-wrap-slider .btn-wrap .btn-send:nth-child(even) {
    margin-right: 0
}

.transfer-wrap p,
.transfer-wrap-slider p {
    text-indent: 20px;
    color: #024168;
    line-height: 18px;
    background-color: transparent
}

.transfer-wrap .close,
.transfer-wrap-slider .close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-position: -394px -1240px;
    height: 20px;
    width: 20px
}

.transfer-content {
    display: -webkit-box;
    display: flex;
    border-top: 1px solid #8FA6B5;
    border-bottom: 1px solid #8FA6B5;
    margin-bottom: 10px
}

.transfer-content dl {
    width: 50%;
    background-color: #CDE3F0;
    padding: 10px 0
}

.transfer-content dl:nth-child(even) {
    background-color: #fff
}

.transfer-content span {
    display: block;
    line-height: 25px
}

.transfer-content dd {
    font-size: 14px;
    line-height: 25px;
    padding: 0 20px
}

.transfer-content dd strong {
    font-size: 20px;
    margin-right: 5px
}

.transfer-content dd input,
.transfer-content dd textarea,
.transfer-content dd .member_select a,
.member_select .transfer-content dd a {
    width: 230px;
    line-height: 20px;
    font-size: 15px;
    margin: 0
}

.transfer-content dd input::-webkit-input-placeholder,
.transfer-content dd textarea::-webkit-input-placeholder,
.transfer-content dd .member_select a::-webkit-input-placeholder,
.member_select .transfer-content dd a::-webkit-input-placeholder {
    color: #989EA1
}

.transfer-content dd input::-moz-placeholder,
.transfer-content dd textarea::-moz-placeholder,
.transfer-content dd .member_select a::-moz-placeholder,
.member_select .transfer-content dd a::-moz-placeholder {
    color: #989EA1
}

.transfer-content dd input:-ms-input-placeholder,
.transfer-content dd textarea:-ms-input-placeholder,
.transfer-content dd .member_select a:-ms-input-placeholder,
.member_select .transfer-content dd a:-ms-input-placeholder {
    color: #989EA1
}

.transfer-content dd input::-ms-input-placeholder,
.transfer-content dd textarea::-ms-input-placeholder,
.transfer-content dd .member_select a::-ms-input-placeholder,
.member_select .transfer-content dd a::-ms-input-placeholder {
    color: #989EA1
}

.transfer-content dd input::placeholder,
.transfer-content dd textarea::placeholder,
.transfer-content dd .member_select a::placeholder,
.member_select .transfer-content dd a::placeholder {
    color: #989EA1
}

.transfer-content:before {
    position: absolute;
    display: block;
    content: '';
    width: 15px;
    height: 15px;
    background-color: #CDE3F0;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.transfer-content ul.quick_stake {
    display: -webkit-box;
    display: flex;
    list-style: none;
    width: 230px;
    margin-top: 5px
}

.transfer-content ul.quick_stake li {
    -webkit-box-flex: 1;
    flex: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    margin: 0 5px 0 0
}

.transfer-content ul.quick_stake li:last-child {
    margin: 0
}

.transfer-content ul.quick_stake a {
    text-indent: 0;
    padding: 0 5px;
    margin: 0
}

.transfer-wrap-slider .available-wrap {
    -webkit-box-pack: inherit;
    justify-content: inherit
}

.transfer-wrap-slider .available-wrap h4,
.transfer-wrap-slider .available-wrap .tc-content h5,
.tc-content .transfer-wrap-slider .available-wrap h5 {
    height: 90px;
    padding: 5px 20px
}

.transfer-wrap-slider .available-wrap h4:first-child,
.transfer-wrap-slider .available-wrap .tc-content h5:first-child,
.tc-content .transfer-wrap-slider .available-wrap h5:first-child {
    position: relative;
    border-right: 1px solid #C3D5E0
}

.transfer-wrap-slider .available-wrap h4:last-child,
.transfer-wrap-slider .available-wrap .tc-content h5:last-child,
.tc-content .transfer-wrap-slider .available-wrap h5:last-child {
    text-align: right
}

.transfer-wrap-slider .transfer-content {
    position: relative;
    background-color: #CDE3F0;
    border-top: 1px solid #8FA6B5;
    border-bottom: 1px solid #8FA6B5;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 85px;
    border: 0
}

.transfer-wrap-slider .transfer-content:before {
    display: none
}

.transfer-wrap-slider .amount-input {
    position: absolute;
    z-index: 1;
    top: -42px;
    left: 50%;
    line-height: 20px;
    background: #FFFFFF;
    border: 1px solid #DA8700;
    border-radius: 4px;
    color: #5E7EA3;
    font-size: 20px;
    text-indent: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.transfer-wrap-slider .amount-input:before {
    position: absolute;
    content: '';
    left: 50%;
    bottom: -12px;
    width: 10px;
    height: 10px;
    background: #FFFFFF;
    border-bottom: 1px solid #DA8700;
    border-right: 1px solid #DA8700;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg)
}

.transfer-wrap-slider .amount-input input,
.transfer-wrap-slider .amount-input textarea,
.transfer-wrap-slider .amount-input .member_select a,
.member_select .transfer-wrap-slider .amount-input a {
    border: 0;
    box-shadow: none;
    margin: 0;
    width: 240px;
    padding: 5px;
    color: #2963A7;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    border-radius: 3px;
    text-align: center;
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.27)
}

.transfer-wrap-slider .slider {
    display: -webkit-box;
    display: flex;
    width: 510px;
    height: 60px;
    line-height: inherit;
    border-right: 0;
    padding-left: 0
}

.transfer-wrap-slider .slider li {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    list-style: none;
    width: 60px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #FDFDFD), to(#eee));
    background-image: -webkit-linear-gradient(top, #FDFDFD 15%, #eee 100%);
    background-image: linear-gradient(180deg, #FDFDFD 15%, #eee 100%);
    padding-right: 0
}

.transfer-wrap-slider .slider li:first-child,
.transfer-wrap-slider .slider li:last-child {
    font-size: 15px;
    line-height: 60px;
    text-align: center;
    font-weight: bold;
    cursor: pointer
}

.transfer-wrap-slider .slider li:first-child:hover,
.transfer-wrap-slider .slider li:last-child:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #eee), to(#FDFDFD));
    background-image: -webkit-linear-gradient(top, #eee 15%, #FDFDFD 100%);
    background-image: linear-gradient(180deg, #eee 15%, #FDFDFD 100%)
}

.transfer-wrap-slider .slider li:first-child {
    border: 1px solid #BBBBBB;
    border-radius: 5px 0 0 5px
}

.transfer-wrap-slider .slider li:last-child {
    border: 1px solid #BBBBBB;
    border-radius: 0 5px 5px 0
}

.transfer-wrap-slider .slider li.col-slider {
    -webkit-box-flex: 1;
    flex: 1;
    border-top: 1px solid #BBBBBB;
    border-bottom: 1px solid #BBBBBB
}

.transfer-wrap-slider .col-slider {
    position: relative
}

.transfer-wrap-slider .balance-percent {
    position: absolute;
    top: 0;
    width: 350px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between
}

.transfer-wrap-slider .balance-percent div {
    display: block;
    width: 3px;
    height: 6px;
    background-color: rgba(199, 212, 227, 0.8)
}

.transfer-wrap-slider .balance-percent div:first-child,
.transfer-wrap-slider .balance-percent div:last-child {
    background-color: rgba(199, 212, 227, 0)
}

.transfer-wrap-slider .slider-bar {
    position: relative;
    width: 350px;
    height: 12px;
    background: #D1DDE5;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin: 0
}

.transfer-wrap-slider .slider-bar_fill {
    position: absolute;
    top: 0;
    height: 10px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #FFB80C), to(#FFA00C));
    background-image: -webkit-linear-gradient(top, #FFB80C 15%, #FFA00C 100%);
    background-image: linear-gradient(180deg, #FFB80C 15%, #FFA00C 100%);
    border: 1px solid #CB8009;
    border-radius: 5px;
    background-position: inherit
}

.transfer-wrap-slider .slider-bar_handle {
    position: absolute;
    z-index: 3;
    top: -6px;
    width: 20px;
    height: 20px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #FFB80C), to(#FFA00C));
    background-image: -webkit-linear-gradient(top, #FFB80C 15%, #FFA00C 100%);
    background-image: linear-gradient(180deg, #FFB80C 15%, #FFA00C 100%);
    border: 1px solid #CB8009;
    border-radius: 12px;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5)
}

.login_to_go {
    top: 18%;
    width: 540px;
    height: 408px;
    position: absolute;
    left: 50%;
    width: 540px;
    margin-left: -270px
}

.login_to_go .error {
    background-color: transparent;
    color: #024168;
    font-size: 13px;
    line-height: 16px
}

.login_to_go .close {
    position: absolute;
    margin-top: 10px;
    margin-left: 15px;
    background-position: -394px -1240px;
    height: 20px;
    width: 20px
}

.login-panel {
    color: #000
}

.change_pass {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 440px;
    margin-left: inherit !important
}

.change_pass .note {
    color: #1E1E1E
}

.footer {
    margin: 20px 0 40px;
    padding-top: 6px
}

.support-wrap {
    background-color: rgba(255, 255, 255, 0.5);
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 20px
}

.support-wrap .support-service {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 50px
}

.support-wrap .support-service::before,
.support-wrap .support-service::after {
    content: "";
    display: block;
    -webkit-box-flex: 1;
    flex: 1;
    align-self: flex-end;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);
    margin-bottom: -1px
}

.support-wrap .support-service a {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 50px
}

.support-wrap .support-service a::after {
    content: '';
    position: absolute;
    top: 100%;
    display: block;
    width: 50px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15)
}

.support-wrap .support-service a.open::after {
    content: '';
    position: absolute;
    top: 100%;
    height: 27px;
    background-image: url(../images/support_arrow_w.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: initial
}

.support-wrap .support-service img {
    width: 25px;
    height: 100%
}

.support-wrap .support-info {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 10px 0
}

.support-wrap .support-info .support-detail {
    display: none
}

.support-wrap .support-info .support-detail.open {
    display: block
}

.support-wrap .support-info .support-detail a {
    margin-right: 8px;
    color: #000;
    text-decoration: underline
}

.support-wrap .support-info .support-detail a:nth-child(2n) {
    padding: 0 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2)
}

.support-wrap .support-info .support-detail a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: 0
}

[class^="support-"] img {
    background-size: contain;
    background-repeat: no-repeat
}

.support-fb img,
.support-ig img {
    width: 22px !important
}

.support-mail img {
    background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="rgb(166,166,166)" fill-rule="nonzero"><path d="M8.292 15.677v3.865a.626.626 0 001.128.37l2.26-3.077-3.388-1.158zM20.738 1.116a.625.625 0 00-.652-.045l-18.75 9.792a.627.627 0 00.087 1.145l5.212 1.782 11.1-9.492-8.59 10.35 8.737 2.985a.628.628 0 00.82-.5l2.291-15.416a.626.626 0 00-.255-.601z"/></g></svg>')
}

.support-mail.open img {
    background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="rgb(97,97,97)" fill-rule="nonzero"><path d="M8.292 15.677v3.865a.626.626 0 001.128.37l2.26-3.077-3.388-1.158zM20.738 1.116a.625.625 0 00-.652-.045l-18.75 9.792a.627.627 0 00.087 1.145l5.212 1.782 11.1-9.492-8.59 10.35 8.737 2.985a.628.628 0 00.82-.5l2.291-15.416a.626.626 0 00-.255-.601z"/></g></svg>')
}

.support-whatsapp img {
    background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11.002 1h-.005C5.485 1 1 5.485 1 11c0 2.188.705 4.215 1.904 5.861l-1.246 3.715L5.5 19.347A9.913 9.913 0 0011.002 21C16.517 21 21 16.514 21 11S16.516 1 11.002 1zm5.82 14.121c-.242.681-1.2 1.246-1.963 1.412-.523.11-1.205.2-3.503-.753-2.938-1.218-4.831-4.204-4.978-4.398-.142-.193-1.188-1.58-1.188-3.016 0-1.435.729-2.134 1.022-2.434.242-.246.64-.358 1.023-.358.124 0 .235.006.335.011.294.012.441.03.635.494.241.581.829 2.016.899 2.163.071.148.142.348.042.542-.094.2-.176.288-.324.458-.147.17-.287.3-.434.483-.136.159-.288.329-.118.622.17.288.758 1.247 1.623 2.017 1.116.993 2.02 1.311 2.345 1.446.24.1.528.076.705-.111.223-.242.5-.642.78-1.035.2-.283.453-.318.718-.218.27.094 1.699.8 1.993.946.293.148.487.218.558.342.07.123.07.705-.17 1.387z" fill="rgb(166,166,166)" fill-rule="nonzero"/></svg>')
}

.support-whatsapp.open img {
    background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11.002 1h-.005C5.485 1 1 5.485 1 11c0 2.188.705 4.215 1.904 5.861l-1.246 3.715L5.5 19.347A9.913 9.913 0 0011.002 21C16.517 21 21 16.514 21 11S16.516 1 11.002 1zm5.82 14.121c-.242.681-1.2 1.246-1.963 1.412-.523.11-1.205.2-3.503-.753-2.938-1.218-4.831-4.204-4.978-4.398-.142-.193-1.188-1.58-1.188-3.016 0-1.435.729-2.134 1.022-2.434.242-.246.64-.358 1.023-.358.124 0 .235.006.335.011.294.012.441.03.635.494.241.581.829 2.016.899 2.163.071.148.142.348.042.542-.094.2-.176.288-.324.458-.147.17-.287.3-.434.483-.136.159-.288.329-.118.622.17.288.758 1.247 1.623 2.017 1.116.993 2.02 1.311 2.345 1.446.24.1.528.076.705-.111.223-.242.5-.642.78-1.035.2-.283.453-.318.718-.218.27.094 1.699.8 1.993.946.293.148.487.218.558.342.07.123.07.705-.17 1.387z" fill="rgb(97,97,97)" fill-rule="nonzero"/></svg>')
}

.support-telegram img {
    background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 21c5.524 0 10-4.476 10-10S16.524 1 11 1 1 5.476 1 11s4.476 10 10 10zM5.576 10.783l9.642-3.717c.447-.162.838.109.693.786V7.85l-1.641 7.734c-.122.548-.447.682-.903.423l-2.5-1.842-1.206 1.162c-.133.133-.246.245-.504.245l.177-2.544 4.633-4.186c.202-.177-.045-.277-.31-.1L7.93 12.346l-2.468-.77c-.536-.17-.548-.535.113-.794z" fill="rgb(166,166,166)" fill-rule="nonzero"/></svg>')
}

.support-telegram.open img {
    background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 21c5.524 0 10-4.476 10-10S16.524 1 11 1 1 5.476 1 11s4.476 10 10 10zM5.576 10.783l9.642-3.717c.447-.162.838.109.693.786V7.85l-1.641 7.734c-.122.548-.447.682-.903.423l-2.5-1.842-1.206 1.162c-.133.133-.246.245-.504.245l.177-2.544 4.633-4.186c.202-.177-.045-.277-.31-.1L7.93 12.346l-2.468-.77c-.536-.17-.548-.535.113-.794z" fill="rgb(97,97,97)" fill-rule="nonzero"/></svg>')
}

.support-skype img {
    background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M20.425 13.123c.145-.652.22-1.327.22-2.02 0-5.193-4.27-9.404-9.539-9.404-.555 0-1.1.047-1.631.137A5.567 5.567 0 006.533 1C3.475 1 1 3.441 1 6.455c0 1.006.278 1.947.76 2.759a9.32 9.32 0 00-.194 1.89c0 5.195 4.272 9.406 9.539 9.406.598 0 1.181-.055 1.747-.158A5.58 5.58 0 0015.47 21C18.524 21 21 18.558 21 15.546c0-.871-.207-1.692-.575-2.423zm-4.426 2.476c-.442.616-1.093 1.104-1.939 1.448-.836.344-1.838.52-2.98.52-1.37 0-2.519-.238-3.416-.708a4.434 4.434 0 01-1.57-1.375c-.407-.575-.61-1.146-.61-1.699 0-.344.133-.643.397-.886.261-.244.596-.365.997-.365.327 0 .61.096.84.287.218.184.407.457.558.805.169.383.353.705.547.957.188.246.457.45.8.612.347.16.813.244 1.386.244.789 0 1.436-.166 1.921-.494.476-.319.706-.704.706-1.174 0-.37-.121-.662-.367-.891-.26-.24-.603-.426-1.018-.555a20.235 20.235 0 00-1.75-.427c-.991-.21-1.832-.457-2.5-.738-.683-.287-1.236-.685-1.638-1.183-.41-.506-.618-1.14-.618-1.885 0-.71.218-1.35.648-1.902.426-.549 1.048-.977 1.852-1.269.791-.288 1.733-.435 2.799-.435.851 0 1.6.098 2.226.29.629.193 1.159.453 1.575.774.42.325.733.669.928 1.029.197.362.298.724.298 1.072 0 .337-.131.643-.391.908a1.32 1.32 0 01-.98.404c-.352 0-.63-.087-.821-.253-.179-.156-.365-.4-.57-.747-.238-.445-.526-.797-.856-1.045-.322-.242-.857-.362-1.594-.362-.683 0-1.24.135-1.653.403-.397.256-.591.551-.591.901 0 .214.063.393.191.546.135.165.327.306.569.426.25.124.509.224.768.294.265.073.708.181 1.32.32.772.163 1.48.347 2.108.545a6.759 6.759 0 011.634.734c.456.294.818.669 1.075 1.119.258.454.388 1.01.388 1.657a3.54 3.54 0 01-.67 2.098z" fill="rgb(166,166,166)" fill-rule="nonzero"/></svg>')
}

.support-skype.open img {
    background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M20.425 13.123c.145-.652.22-1.327.22-2.02 0-5.193-4.27-9.404-9.539-9.404-.555 0-1.1.047-1.631.137A5.567 5.567 0 006.533 1C3.475 1 1 3.441 1 6.455c0 1.006.278 1.947.76 2.759a9.32 9.32 0 00-.194 1.89c0 5.195 4.272 9.406 9.539 9.406.598 0 1.181-.055 1.747-.158A5.58 5.58 0 0015.47 21C18.524 21 21 18.558 21 15.546c0-.871-.207-1.692-.575-2.423zm-4.426 2.476c-.442.616-1.093 1.104-1.939 1.448-.836.344-1.838.52-2.98.52-1.37 0-2.519-.238-3.416-.708a4.434 4.434 0 01-1.57-1.375c-.407-.575-.61-1.146-.61-1.699 0-.344.133-.643.397-.886.261-.244.596-.365.997-.365.327 0 .61.096.84.287.218.184.407.457.558.805.169.383.353.705.547.957.188.246.457.45.8.612.347.16.813.244 1.386.244.789 0 1.436-.166 1.921-.494.476-.319.706-.704.706-1.174 0-.37-.121-.662-.367-.891-.26-.24-.603-.426-1.018-.555a20.235 20.235 0 00-1.75-.427c-.991-.21-1.832-.457-2.5-.738-.683-.287-1.236-.685-1.638-1.183-.41-.506-.618-1.14-.618-1.885 0-.71.218-1.35.648-1.902.426-.549 1.048-.977 1.852-1.269.791-.288 1.733-.435 2.799-.435.851 0 1.6.098 2.226.29.629.193 1.159.453 1.575.774.42.325.733.669.928 1.029.197.362.298.724.298 1.072 0 .337-.131.643-.391.908a1.32 1.32 0 01-.98.404c-.352 0-.63-.087-.821-.253-.179-.156-.365-.4-.57-.747-.238-.445-.526-.797-.856-1.045-.322-.242-.857-.362-1.594-.362-.683 0-1.24.135-1.653.403-.397.256-.591.551-.591.901 0 .214.063.393.191.546.135.165.327.306.569.426.25.124.509.224.768.294.265.073.708.181 1.32.32.772.163 1.48.347 2.108.545a6.759 6.759 0 011.634.734c.456.294.818.669 1.075 1.119.258.454.388 1.01.388 1.657a3.54 3.54 0 01-.67 2.098z" fill="rgb(97,97,97)" fill-rule="nonzero"/></svg>')
}

.support-fb img {
    background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 0C4.925 0 0 4.946 0 11.046a11.026 11.026 0 009.165 10.88v-8.575H6.512v-3.086h2.653V7.989c0-2.64 1.606-4.079 3.952-4.079 1.124 0 2.09.084 2.37.121v2.76h-1.628c-1.275 0-1.52.61-1.52 1.502v1.97h3.043l-.397 3.086h-2.647V22C17.781 21.335 22 16.688 22 11.043 22 4.946 17.075 0 11 0z" fill="rgb(166,166,166)" fill-rule="nonzero"/></svg>')
}

.support-fb.open img {
    background-image: url('data:image/svg+xml,<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M11 0C4.925 0 0 4.946 0 11.046a11.026 11.026 0 009.165 10.88v-8.575H6.512v-3.086h2.653V7.989c0-2.64 1.606-4.079 3.952-4.079 1.124 0 2.09.084 2.37.121v2.76h-1.628c-1.275 0-1.52.61-1.52 1.502v1.97h3.043l-.397 3.086h-2.647V22C17.781 21.335 22 16.688 22 11.043 22 4.946 17.075 0 11 0z" fill="rgb(97,97,97)" fill-rule="nonzero"/></svg>')
}

.support-ig img {
    background-image: url('data:image/svg+xml,<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M29.172 0C35.142 0 40 4.857 40 10.828v18.345C40 35.143 35.143 40 29.173 40H10.828C4.858 40 0 35.143 0 29.173V10.828C0 4.858 4.857 0 10.828 0zM20 9.063C13.97 9.063 9.063 13.969 9.063 20c0 6.03 4.906 10.937 10.937 10.937 6.03 0 10.937-4.906 10.937-10.937 0-6.03-4.906-10.937-10.937-10.937zm0 2.345c4.738 0 8.592 3.854 8.592 8.592S24.738 28.592 20 28.592 11.408 24.738 11.408 20s3.854-8.592 8.592-8.592zm11.199-6.23a3.235 3.235 0 00-3.232 3.232 3.235 3.235 0 003.232 3.232A3.235 3.235 0 0034.43 8.41a3.236 3.236 0 00-3.232-3.232zm0 2.345a.888.888 0 010 1.774.888.888 0 010-1.774z" fill="rgb(166,166,166)" fill-rule="nonzero"/></svg>')
}

.support-ig.open img {
    background-image: url('data:image/svg+xml,<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M29.172 0C35.142 0 40 4.857 40 10.828v18.345C40 35.143 35.143 40 29.173 40H10.828C4.858 40 0 35.143 0 29.173V10.828C0 4.858 4.857 0 10.828 0zM20 9.063C13.97 9.063 9.063 13.969 9.063 20c0 6.03 4.906 10.937 10.937 10.937 6.03 0 10.937-4.906 10.937-10.937 0-6.03-4.906-10.937-10.937-10.937zm0 2.345c4.738 0 8.592 3.854 8.592 8.592S24.738 28.592 20 28.592 11.408 24.738 11.408 20s3.854-8.592 8.592-8.592zm11.199-6.23a3.235 3.235 0 00-3.232 3.232 3.235 3.235 0 003.232 3.232A3.235 3.235 0 0034.43 8.41a3.236 3.236 0 00-3.232-3.232zm0 2.345a.888.888 0 010 1.774.888.888 0 010-1.774z" fill="rgb(97,97,97)" fill-rule="nonzero"/></svg>')
}

.power-wrap {
    width: 670px;
    margin: 0 auto 30px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.3)
}

.licence_embed {
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: 5px
}

.power-wrap-b {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.5);
    padding: 3px 7px 6px;
    margin-top: 4px
}

.power-wrap-b img {
    opacity: 0.5;
    background-position: 0 -1064px;
    height: 18px;
    width: 107px
}

.browser-wrap {
    color: rgba(0, 0, 0, 0.6)
}

.browser-wrap img {
    background-position: 0 0;
    height: 20px;
    width: 50px
}

.app-link {
    display: block;
    width: 100%;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 20px
}

.app-link a {
    margin-bottom: 2px
}

.app-link img {
    width: 155px;
    height: auto
}

.live-pop {
    width: 100%;
    height: 100%;
    background-color: #000
}

.streaming-pop {
    background-color: #EEEEEE
}

.live-left {
    width: 210px;
    height: calc(100% - 3px);
    border-top: 3px solid #7E97A7;
    overflow: hidden;
    overflow-y: auto
}

.live-list {
    padding-bottom: 15px
}

.live-list dt {
    background-color: #E0E6E6;
    line-height: 20px;
    color: #243A48;
    font-size: 11px;
    font-weight: bold;
    border-bottom: 1px solid #C5D0D7;
    padding: 0 7px
}

.live-list dt img {
    margin-right: 5px;
    margin-top: 4px;
    vertical-align: top
}

.live-list dd {
    background-color: #fff;
    font-size: 12px;
    border-bottom: 1px solid #ccc
}

.live-list a {
    position: relative;
    line-height: 28px;
    color: #1E1E1E;
    padding: 0 23px 0 7px
}

.live-list a:after {
    content: '';
    position: absolute;
    top: 6px;
    right: 5px;
    background-position: -399px 0;
    height: 15px;
    width: 15px
}

.live-list a:hover {
    background-color: #EFF2F2;
    text-decoration: none
}

.live-list a.select {
    background-color: #F2DCA7
}

.live-right {
    width: calc(100% - 210px);
    height: 100%;
    background-color: #000
}

.live-head {
    position: relative;
    flex-wrap: wrap
}

.live-head h3 {
    width: 100%;
    line-height: 35px;
    color: #fff;
    font-size: 15px;
    padding: 0 10px;
    background-repeat: repeat-x;
    background-position: 0 -6298px
}

.live-head h3 span {
    color: #7E97A7;
    font-size: 12px;
    font-weight: normal
}

.icon-TV_head {
    vertical-align: top;
    margin: 8px 7px 0 0;
    background-position: -392px -1771px;
    height: 19px;
    width: 22px
}

.live-channel {
    position: relative;
    width: calc(100% - 61px);
    line-height: 25px;
    color: #fff;
    font-weight: bold;
    padding: 0 10px;
    margin-bottom: 0;
    background-repeat: repeat-x;
    background-position: 0 -5401px
}

.live-channel span {
    height: 17px;
    line-height: 17px;
    font-size: 11px;
    color: #1E1E1E;
    font-weight: normal;
    background-color: #FFB80C;
    padding: 0 4px;
    margin: 4px 7px 0 0;
    border-radius: 3px
}

.change-channel {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 30px;
    height: 25px;
    padding: 0 5px;
    border-left: 1px solid #607e92;
    background-repeat: repeat-x;
    cursor: pointer;
    background-position: 0 -5401px
}

.change-channel span {
    padding: 5px 0 0 0;
    color: #ffffff;
    font-family: 'arial';
    font-size: 10px;
    font-weight: 400;
    font-style: normal
}

.change-channel::before {
    content: '';
    width: 20px;
    height: 100%;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="13"><path fill="%23ffffff" fill-rule="evenodd" d="M3.294 7.122a7.99 7.99 0 013.667-2.147 7.886 7.886 0 014.098 0 7.863 7.863 0 013.647 2.147l-1.608 1.685a5.643 5.643 0 00-2.627-1.545 5.769 5.769 0 00-2.942 0 5.643 5.643 0 00-2.627 1.545L3.294 7.122zm3.255 3.37a3.326 3.326 0 011.549-.902 3.44 3.44 0 011.804 0c.601.16 1.118.46 1.549.902L9 13l-2.451-2.508zM0 3.792A12.722 12.722 0 014.216.942 12.612 12.612 0 019.02 0c1.66 0 3.255.314 4.784.943A12.512 12.512 0 0118 3.792l-1.647 1.685a10.208 10.208 0 00-3.431-2.327A10.12 10.12 0 009 2.367c-1.36 0-2.667.261-3.922.783a10.208 10.208 0 00-3.43 2.327L0 3.792z"/></svg>');
    background-repeat: no-repeat;
    background-position: center
}

.change-channel:hover {
    opacity: .9
}

.live-TV {
    height: calc(100% - 60px);
    background: #000 url(../images/bg-TV.png) no-repeat center center
}

.marquee-box {
    color: #fff;
    line-height: 25px;
    height: 25px;
    margin-bottom: 1px;
    background-repeat: repeat-x;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(27%, #2A3A43), color-stop(83%, #1C282D));
    background-image: -webkit-linear-gradient(top, #2A3A43 27%, #1C282D 83%);
    background-image: linear-gradient(180deg, #2A3A43 27%, #1C282D 83%)
}

.marquee-box h4,
.marquee-box .tc-content h5,
.tc-content .marquee-box h5 {
    position: relative;
    flex-basis: 72px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center
}

.marquee-box h4:before,
.marquee-box .tc-content h5:before,
.tc-content .marquee-box h5:before {
    content: '';
    height: 17px;
    width: 17px;
    margin-right: 2px;
    background-position: 100% -2560px
}

.marquee-box h4:after,
.marquee-box .tc-content h5:after,
.tc-content .marquee-box h5:after {
    content: '';
    position: absolute;
    right: -7px;
    top: 0;
    z-index: 2;
    width: 7px;
    height: 25px;
    background-position: 100% -3365px
}

.marquee-box .marquee {
    -webkit-box-flex: 1;
    flex: 1;
    overflow: hidden;
    z-index: 1
}

.marquee-box .marquee a {
    height: 25px;
    line-height: 25px;
    font-weight: bold;
    color: #6AC2FF;
    margin-right: 100px;
    -webkit-box-align: center;
    align-items: center
}

.marquee-box .marquee a span {
    height: 15px;
    line-height: 15px;
    font-size: 11px;
    font-style: italic;
    font-weight: normal;
    color: #1C2834;
    background-color: #6AC2FF;
    border-radius: 2px;
    padding: 0 5px;
    margin-right: 5px
}

.inplay .marquee-box {
    margin-bottom: 10px
}

.tc-content,
.tc-content_few,
.announce-content {
    overflow-y: scroll;
    height: calc(580px - 56px - 72px);
    padding: 16px 24px;
    font-size: 14px;
    line-height: 24px
}

.announce-wrap,
.casino-result {
    position: relative;
    top: 12%;
    width: 720px;
    height: auto;
    margin: 0 auto;
    margin-bottom: 24px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5)
}

.announce-header {
    height: 56px;
    padding-left: 24px;
    border-bottom: 1px solid #E0E6E6
}

.announce-header h1 {
    color: #000000;
    font-size: 20px;
    line-height: 56px
}

.announce-header h1:before {
    position: relative;
    top: 4px;
    width: 6px;
    height: 24px;
    margin-right: 8px;
    content: "";
    background: #FFB80C;
    border-radius: 100px
}

.announce-footer {
    max-height: 73px;
    padding: 12px 0;
    background: #ffffff;
    border-top: 1px solid #E0E6E6;
    font-size: 16px;
    border-radius: 0 0 8px 8px
}

.announce-footer input[type=checkbox],
.announce-footer textarea[type=checkbox],
.announce-footer .member_select a[type=checkbox],
.member_select .announce-footer a[type=checkbox] {
    box-sizing: border-box
}

.announce-footer a {
    width: 240px;
    height: 40px;
    margin: 0 16px 0 0;
    color: #1E1E1E;
    font-size: 16px;
    line-height: 40px
}

.announce-footer .confirm-checkbox {
    margin-bottom: 12px
}

.age_box li .secondary:before {
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.7) url('data:image/svg+xml,<svg width="25" height="25" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h25v25H0z"/><path fill="rgb(230,0,0)" d="M19.792 6.677l-1.47-1.469-5.822 5.823-5.823-5.823-1.469 1.47 5.823 5.822-5.823 5.823 1.47 1.469 5.822-5.823 5.823 5.823 1.469-1.47-5.823-5.822z"/></g></svg>') center no-repeat;
    margin-right: 8px;
    border-radius: 13px
}

.age_box li .btn-send:before {
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.7) url('data:image/svg+xml,<svg width="25" height="25" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h25v25H0z"/><path fill="rgb(0, 129, 43)" d="M9.375 16.844L5.031 12.5l-1.479 1.469 5.823 5.823 12.5-12.5-1.469-1.47z"/></g></svg>') center no-repeat;
    margin-right: 8px;
    border-radius: 13px
}

.urgent-notice {
    z-index: 99;
    width: 660px;
    opacity: 0;
    -webkit-animation: urgent-notice 0.3s ease 1s 1 alternate forwards;
    animation: urgent-notice 0.3s ease 1s 1 alternate forwards
}

.urgent-notice .announce-header {
    padding: 0;
    background: #121212 url(../images/bdtwin/bg-send-skyEX.png) repeat-x;
    border-radius: 8px 8px 0 0
}

.urgent-notice .announce-header h1 {
    color: #F8D61C;
    font-size: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6)
}

.urgent-notice .announce-header h1:before {
    display: none
}

.urgent-notice .notice-content {
    padding: 24px 20px;
    font-size: 16px;
    line-height: 24px
}

.urgent-notice .notice-img {
    margin-top: 12px;
    width: 620px;
    height: auto
}

.urgent-notice .hilight {
    font-weight: bold;
    text-decoration: underline
}

@-webkit-keyframes urgent-notice {
    0% {
        top: -2%;
        opacity: 0
    }

    100% {
        top: 12%;
        opacity: 1
    }
}

@keyframes urgent-notice {
    0% {
        top: -2%;
        opacity: 0
    }

    100% {
        top: 12%;
        opacity: 1
    }
}

.tc-content h1 {
    margin: 40px 0 24px 0;
    font-size: 22px;
    color: #000;
    text-decoration: underline
}

.tc-content h2 {
    margin: 16px 0;
    padding: 0;
    font-size: 20px;
    color: #1B2D38;
    border-bottom: 1px dotted #ccc;
    padding-bottom: 8px
}

.tc-content h3 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 18px;
    color: rgba(30, 30, 30, 0.9)
}

.tc-content h4,
.tc-content h5 {
    margin-left: 8px;
    font-size: 14px;
    color: rgba(30, 30, 30, 0.9)
}

.tc-content h5 {
    margin-left: 32px;
    text-decoration: underline
}

.tc-content ul,
.tc-content ol {
    margin-left: 32px;
    margin-bottom: 16px
}

.tc-content ul ul,
.tc-content ul ol,
.tc-content ol ul,
.tc-content ol ol {
    margin-left: 24px
}

.tc-content strong {
    color: #243A48
}

.tc-content address {
    font-size: 13px;
    margin-bottom: 10px
}

.tc-content_few {
    height: auto
}

.declared {
    background-color: #aed5f5;
    font-size: 13px;
    line-height: 1.4;
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px
}

.declared p:last-of-type {
    margin-bottom: 0
}

.announce-content {
    font-size: 16px;
    line-height: 22px
}

.announce-content h3 {
    margin: 8px 0;
    font-size: 16px
}

.announce-content p {
    margin-bottom: 8px
}

.announce-content table {
    margin: 8px 0 16px 0;
    font-size: 14px
}

.announce-content ul {
    margin-left: 24px
}

.article-wrap {
    position: relative;
    padding: 0px 0px 56px 60px
}

.article-wrap h2 {
    margin-bottom: 16px;
    color: #243A48;
    font-size: 28px;
    line-height: 28px
}

.article-date {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    margin-right: 24px;
    border-top: 4px solid #4A4A4A;
    color: #4A4A4A
}

.article-date dt {
    margin: 4px 0 2px 0;
    font-size: 30px;
    line-height: 30px
}

.article-date dd {
    font-size: 14px;
    line-height: 16px
}

.news-wrap {
    top: 72px;
    width: 640px
}

.news-wrap .announce-header {
    height: 60px;
    background-color: #FFB80C;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#FFCC2E), color-stop(58%, #FFB80C));
    background-image: -webkit-linear-gradient(top, #FFCC2E 0%, #FFB80C 58%);
    background-image: linear-gradient(-180deg, #FFCC2E 0%, #FFB80C 58%);
    font-size: 24px;
    font-weight: bold;
    line-height: 60px;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 8px 8px 0 0
}

.news-wrap .announce-content {
    height: 412px;
    padding: 24px 20px
}

.news-wrap .announce-content img {
    width: 100%;
    box-shadow: 0px 1px 5px #ddd;
    margin: 20px 0
}

.mysports247 .announce-header h1:before {
    background: #C6BCA7
}

.mysports247 .news-wrap .announce-header {
    background: url("../images/announcement/popup-header-mysports247.png") center no-repeat
}

.mazaplay .announce-header h1:before {
    background: #48955B
}

.mazaplay .news-wrap .announce-header {
    background: url("../images/announcement/popup-header-mazaplay.png") center no-repeat
}

.bdtwin .announce-header h1:before {
    background: #FFB80C
}

.bdtwin .news-wrap .announce-header {
    background: url("../images/announcement/popup-header-bdtwin.png") center no-repeat
}

/* .o_spondemo .announce-header h1:before{
    background:#1E6EAB
}
.o_spondemo .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-o_spondemo.png") center no-repeat
}
.o_12bet .announce-header h1:before{
    background:#B73B40
}
.o_12bet .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-o_12bet.png") center no-repeat
}
.o_dafabet .announce-header h1:before{
    background:#B73B40
}
.o_dafabet .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-o_dafabet.png") center no-repeat
}
.skyinplay .announce-header h1:before{
    background:#83AE17
}
.skyinplay .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-skyinplay.png") center no-repeat
}
.bigEXch .announce-header h1:before{
    background:#83AE17
}
.bigEXch .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-bigEXch.png") center no-repeat
}
.masterExc .announce-header h1:before{
    background:#216869
}
.masterExc .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-masterExc.png") center no-repeat
}
.matador .announce-header h1:before{
    background:#C10A31
}
.matador .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-matador.png") center no-repeat
}
.lucky7 .announce-header h1:before{
    background:#303FA3
}
.lucky7 .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-lucky7.png") center no-repeat
}
.qexch .announce-header h1:before{
    background:#443A56
}
.qexch .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-qexch.png") center no-repeat
}
.betmygame .announce-header h1:before{
    background:#ED7218
}
.betmygame .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-betmygame.png") center no-repeat
}
.fancyfair .announce-header h1:before{
    background:#50996B
}
.fancyfair .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-fancyfair.png") center no-repeat
}
.maruti .announce-header h1:before{
    background:#48955B
}
.maruti .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-maruti.png") center no-repeat
}
.marutibook .announce-header h1:before{
    background:#48955B
}
.marutibook .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-marutibook.png") center no-repeat
}
.bdtwin247 .announce-header h1:before{
    background:#FFB80C
}
.bdtwin247 .news-wrap .announce-header{
    background:url("../images/announcement/popup-header-bdtwin247.png") center no-repeat
} */
.login-policy {
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
    box-shadow: none
}

.login-policy p {
    margin-bottom: 12px
}

.login-policy .tc-content {
    max-height: inherit;
    height: calc(100% - 56px - 24px - 8px);
    padding-top: 0;
    padding-bottom: 24px;
    margin-top: 56px
}

.login-policy .announce-header {
    position: fixed;
    width: 100%;
    background: #ffffff
}

@media screen and (max-height: 700px) {

    .announce-wrap,
    .casino-result {
        top: 24px;
        height: calc(100% - 24px - 24px);
        min-height: 298px
    }

    .tc-content,
    .news-wrap .announce-content {
        height: calc(100% - 24px - 24px - 56px - 73px);
        min-height: 200px
    }

    .announce-content {
        height: calc(100% - 24px - 24px - 56px - 42px);
        min-height: 200px
    }

    .news-wrap {
        min-height: 333px
    }

    .news-wrap .announce-content {
        height: calc(100% - 24px - 24px - 60px - 73px - 24px);
        min-height: 200px
    }

    .login-policy {
        top: 0;
        height: 100%
    }

    .login-policy .tc-content {
        max-height: inherit;
        height: calc(100% - 56px - 24px - 8px)
    }

    .urgent-notice {
        height: auto
    }
}

.apk-dl {
    width: 480px;
    height: 100%;
    top: 0;
    margin-bottom: 0
}

.apk-img {
    text-align: center
}

.apk-img img {
    width: 200px
}

.marquee-pop {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 0;
    width: 820px;
    height: 600px
}

.marquee-pop .announce-header {
    background: #000 url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/2wCEAAEBAQEBAQIBAQIDAgICAwQDAwMDBAUEBAQEBAUGBQUFBQUFBgYGBgYGBgYHBwcHBwcICAgICAkJCQkJCQkJCQkBAQEBAgICBAICBAkGBQYJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCf/AABEIAEoABQMBIgACEQEDEQH/xABaAAEBAQAAAAAAAAAAAAAAAAAABgoQAAEDAwMFAAAAAAAAAAAAAAABFVECEaEEBfASFDFSYgEBAAAAAAAAAAAAAAAAAAAAABEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8Az5OH1kEIm4VW85AEQ4cuCEcuXAER36yCGc0kAQrgntkESmvWQBBuKzkEt1VSAP/Z);
    /* background-image:-webkit-linear-gradient(top, #FFCC2F 0%, #FFB80C 71%); */
    /* background-image:linear-gradient(180deg, #FFCC2F 0%, #FFB80C 71%); */
    border-bottom: none
}

.marquee-pop .announce-header h1:before {
    display: none
}

.marquee-pop .function-wrap {
    padding: 10px;
    background-color: #E0E6E6
}

.marquee-pop .function-wrap input,
.marquee-pop .function-wrap textarea,
.marquee-pop .function-wrap .member_select a,
.member_select .marquee-pop .function-wrap a {
    height: 25px;
    margin: 0
}

.marquee-pop .input-list li,
.marquee-pop .filter-list li {
    list-style: none;
    line-height: 25px;
    margin: 0 5px 0 0;
    white-space: nowrap
}

.marquee-pop .input-list .btn-send,
.marquee-pop .filter-list .btn-send {
    width: 115px;
    margin: 0
}

.marquee-pop .announce-content {
    height: initial
}

.marquee-pop .announce-content .nofound_box {
    display: none
}

.marquee-pop .nofound {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center
}

.marquee-pop .nofound .nofound_box {
    -webkit-box-align: center;
    align-items: center;
    vertical-align: top;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin-bottom: 100px;
    max-width: 300px
}

.marquee-pop .nofound .nofound_box dt {
    width: 101px;
    height: 97px;
    margin-bottom: 20px;
    background: url(../images/announcement/ic-search.svg) no-repeat
}

.marquee-pop .nofound .nofound_box dd {
    margin-bottom: 5px;
    font-weight: bold;
    -webkit-box-flex: 1;
    flex: 1
}

.marquee-pop .nofound .article-wrap {
    display: none
}

.marquee-pop .pages {
    -webkit-box-pack: center;
    justify-content: center;
    margin: 10px 0
}

.marquee-pop .pages li {
    list-style: none;
    margin-right: 3px;
    margin-left: 3px
}

.marquee-pop .pages a {
    font-weight: normal;
    line-height: 25px;
    margin: 0;
    padding: 0 10px
}

.profile-wrap {
    background-color: #fff;
    border-bottom: 1px solid #7E97A7;
    margin-bottom: 15px
}

.profile-wrap h3 {
    background-color: #7E97A7;
    color: #fff;
    line-height: 24px;
    padding: 0 10px
}

.profile-wrap dt {
    width: 125px;
    padding: 5px 0 5px 10px;
    color: #243A48
}

.profile-wrap dd {
    position: relative;
    min-height: 16px;
    padding: 5px 0 5px 10px;
    border-bottom: 1px solid #E0E6E6
}

.profile-wrap dd:last-child {
    border-bottom-width: 0
}

.profile-wrap .favor-set {
    position: absolute;
    right: 0;
    top: 1px
}

.profile-wrap select {
    min-width: 150px;
    margin: 0
}

.pop_bg {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 80
}

.pop_bg:not(:target) {
    display: none
}

.pop_bg:target {
    display: block
}

.pop_box,
.pop_refer {
    position: fixed;
    top: 110px;
    left: 50%;
    background-color: #eee;
    width: 400px;
    border-radius: 5px;
    padding: 15px;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.pop_box h3,
.pop_refer h3 {
    font-size: 16px;
    color: #3B5160;
    margin-bottom: 18px
}

.pop_box .btn,
.pop_refer .btn,
.pop_box .btn-send,
.pop_refer .btn-send,
.pop_box .pages a,
.pages .pop_box a,
.pop_refer .pages a,
.pages .pop_refer a,
.pop_box .calendarTable_inputBox input,
.calendarTable_inputBox .pop_box input,
.pop_refer .calendarTable_inputBox input,
.calendarTable_inputBox .pop_refer input,
.pop_box .calendarTable_inputBox textarea,
.calendarTable_inputBox .pop_box textarea,
.pop_refer .calendarTable_inputBox textarea,
.calendarTable_inputBox .pop_refer textarea,
.pop_box .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .pop_box a,
.pop_refer .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .pop_refer a,
.pop_box .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .pop_box a,
.pop_refer .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .pop_refer a,
.pop_box .total_all .btn_replay,
.total_all .pop_box .btn_replay,
.pop_refer .total_all .btn_replay,
.total_all .pop_refer .btn_replay,
.pop_box .btn_expand,
.pop_refer .btn_expand,
.pop_box .add_member,
.pop_refer .add_member {
    width: 55%;
    margin: 15px 0 0
}

.close_pop {
    position: absolute;
    right: 15px;
    background-position: -41px -632px;
    height: 16px;
    width: 17px
}

.form_list,
.add_account_box dl,
.pt_allowed dl {
    padding: 0 20px;
    margin-bottom: 10px
}

.form_list dt,
.add_account_box dl dt,
.pt_allowed dl dt {
    width: 122px;
    margin-right: 10px;
    line-height: 24px
}

.form_list dd,
.add_account_box dl dd,
.pt_allowed dl dd {
    position: relative;
    margin-bottom: 10px;
    padding-left: 132px
}

.form_list dd input,
.add_account_box dl dd input,
.pt_allowed dl dd input,
.form_list dd textarea,
.add_account_box dl dd textarea,
.pt_allowed dl dd textarea,
.form_list dd .member_select a,
.member_select .form_list dd a,
.add_account_box dl dd .member_select a,
.member_select .add_account_box dl dd a,
.pt_allowed dl dd .member_select a,
.member_select .pt_allowed dl dd a {
    width: 160px;
    margin: 0
}

.form_list dd select,
.add_account_box dl dd select,
.pt_allowed dl dd select {
    width: 172px
}

.must {
    font-size: 13px;
    color: #024168
}

.error-text {
    color: #024168;
    font-size: 11px
}

input.error,
textarea.error,
.member_select a.error {
    background-color: #EFD3D9;
    color: #1E1E1E
}

.confirm_box {
    width: 280px;
    margin: 15% 0 0 -140px
}

.confirm_box h3 {
    height: 15px
}

.confirm_box .white-wrap,
.confirm_box .total_box,
.confirm_box .fix-content {
    padding: 15px 10px;
    margin-bottom: 10px
}

.confirm_box .white-wrap p,
.confirm_box .total_box p,
.confirm_box .fix-content p {
    width: 100%
}

.confirm_box .btn_box .btn,
.confirm_box .btn_box .btn-send,
.confirm_box .btn_box .pages a,
.pages .confirm_box .btn_box a,
.confirm_box .btn_box .calendarTable_inputBox input,
.calendarTable_inputBox .confirm_box .btn_box input,
.confirm_box .btn_box .calendarTable_inputBox textarea,
.calendarTable_inputBox .confirm_box .btn_box textarea,
.confirm_box .btn_box .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .confirm_box .btn_box a,
.confirm_box .btn_box .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .confirm_box .btn_box a,
.confirm_box .btn_box .total_all .btn_replay,
.total_all .confirm_box .btn_box .btn_replay,
.confirm_box .btn_box .btn_expand,
.confirm_box .btn_box .add_member {
    width: 110px;
    margin-right: 5px
}

.confirm_box .btn_box .btn:last-of-type,
.confirm_box .btn_box .btn-send:last-of-type,
.confirm_box .btn_box .pages a:last-of-type,
.pages .confirm_box .btn_box a:last-of-type,
.confirm_box .btn_box .calendarTable_inputBox input:last-of-type,
.calendarTable_inputBox .confirm_box .btn_box input:last-of-type,
.confirm_box .btn_box .calendarTable_inputBox textarea:last-of-type,
.calendarTable_inputBox .confirm_box .btn_box textarea:last-of-type,
.confirm_box .btn_box .calendarTable_inputBox .member_select a:last-of-type,
.calendarTable_inputBox .member_select .confirm_box .btn_box a:last-of-type,
.confirm_box .btn_box .member_select .calendarTable_inputBox a:last-of-type,
.member_select .calendarTable_inputBox .confirm_box .btn_box a:last-of-type,
.confirm_box .btn_box .total_all .btn_replay:last-of-type,
.total_all .confirm_box .btn_box .btn_replay:last-of-type,
.confirm_box .btn_box .btn_expand:last-of-type,
.confirm_box .btn_box .add_member:last-of-type {
    margin-right: 0
}

.go-back,
.pop-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 85px;
    height: 100%;
    line-height: 38px;
    color: #fff;
    font-weight: normal;
    border-left: 1px solid #5F6C74
}

.go-back img,
.pop-close img {
    margin-right: 5px;
    background-position: -46px -1182px;
    height: 12px;
    width: 12px
}

.pop-close img {
    background-position: -46px -1242px;
    height: 12px;
    width: 12px
}

/* .pop-content{
    height:calc(100% - 35px - 38px);
    padding:20px 15px 15px;
    overflow:auto
} */
.tab {
    min-width: 375px;
    width: 40%;
    margin: 0 0 10px;
    border-left: 1px solid #3B5160;
    border-radius: 5px
}

.tab li {
    list-style: none;
    border: 1px solid #3B5160;
    border-width: 1px 0
}

.tab li:first-child {
    border-radius: 4px 0 0 4px
}

.tab li:first-child a {
    border-radius: 3px 0 0 3px
}

.tab li:last-child {
    border-radius: 0 4px 4px 0
}

.tab li:last-child a {
    border-radius: 0 3px 3px 0
}

.tab a {
    background-color: #fff;
    border-right: 1px solid #3B5160;
    color: #3B5160;
    font-size: 13px;
    line-height: 27px;
    font-weight: bold
}

.tab .select {
    background-color: #3B5160;
    color: #fff
}

.tab .select:hover {
    text-decoration: none
}

.tab.col2 li {
    width: 50%
}

.tab.col3 li {
    width: 33.33%
}

.tab.col4 li {
    width: 25%
}

.tab.col5 li {
    width: 20%
}

.function-wrap {
    position: relative;
    padding: 10px 10px 0;
    background-color: #E0E6E6;
    border-bottom: 1px solid #7E97A7;
    margin-bottom: 10px
}

.white-wrap .function-wrap,
.total_box .function-wrap,
.fix-content .function-wrap {
    border-bottom-color: #E0E6E6;
    margin-bottom: 0
}

.game-wrap .function-wrap {
    margin-bottom: 0;
    padding-top: 5px
}

.game-wrap .function-wrap .input-list,
.game-wrap .function-wrap .filter-list {
    margin-bottom: 0
}

.trans-wrap .function-wrap {
    margin-bottom: 0
}

.input-list,
.filter-list {
    margin-bottom: 5px
}

.input-list li,
.filter-list li {
    list-style: none;
    line-height: 25px;
    margin: 0 5px 5px 0;
    white-space: nowrap
}

.input-list label,
.filter-list label {
    margin-right: 5px
}

.input-list input,
.filter-list input,
.input-list textarea,
.filter-list textarea,
.input-list .member_select a,
.member_select .input-list a,
.filter-list .member_select a,
.member_select .filter-list a,
.input-list select,
.filter-list select {
    margin: 0 5px
}

.input-list .time-input,
.filter-list .time-input {
    margin-left: -3px
}

.input-list .btn,
.filter-list .btn,
.input-list .btn-send,
.filter-list .btn-send,
.input-list .pages a,
.pages .input-list a,
.filter-list .pages a,
.pages .filter-list a,
.input-list .calendarTable_inputBox input,
.calendarTable_inputBox .input-list input,
.filter-list .calendarTable_inputBox input,
.calendarTable_inputBox .filter-list input,
.input-list .calendarTable_inputBox textarea,
.calendarTable_inputBox .input-list textarea,
.filter-list .calendarTable_inputBox textarea,
.calendarTable_inputBox .filter-list textarea,
.input-list .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .input-list a,
.filter-list .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .filter-list a,
.input-list .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .input-list a,
.filter-list .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .filter-list a,
.input-list .total_all .btn_replay,
.total_all .input-list .btn_replay,
.filter-list .total_all .btn_replay,
.total_all .filter-list .btn_replay,
.input-list .btn_expand,
.filter-list .btn_expand,
.input-list .add_member,
.filter-list .add_member {
    width: 95px;
    margin: 0;
    font-weight: normal
}

.input-list .btn-send,
.filter-list .btn-send {
    width: 115px;
    font-weight: bold
}

.filter-list .filter-head {
    width: 85px;
    font-weight: bold
}

.filter-list .filter-content {
    width: calc(100% - 195px);
    height: 25px
}

.filter-list .filter-content span {
    margin-right: 5px;
    color: #243A48;
    padding-left: 10px;
    background-position: -23px -166px
}

.filter-list .filter-content .filter-first {
    background: none;
    padding-left: 0
}

.filter-list .filter-btn {
    width: 95px
}

.filter-list .filter-btn .btn,
.filter-list .filter-btn .btn-send,
.filter-list .filter-btn .pages a,
.pages .filter-list .filter-btn a,
.filter-list .filter-btn .calendarTable_inputBox input,
.calendarTable_inputBox .filter-list .filter-btn input,
.filter-list .filter-btn .calendarTable_inputBox textarea,
.calendarTable_inputBox .filter-list .filter-btn textarea,
.filter-list .filter-btn .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .filter-list .filter-btn a,
.filter-list .filter-btn .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .filter-list .filter-btn a,
.filter-list .filter-btn .total_all .btn_replay,
.total_all .filter-list .filter-btn .btn_replay,
.filter-list .filter-btn .btn_expand,
.filter-list .filter-btn .add_member {
    width: 93px
}

.filter-list.golf-list .filter-content {
    width: calc(100% - 95px);
    height: auto
}

.filter-list.golf-list .compt {
    position: relative;
    width: auto;
    height: 23px;
    padding: 0 0 0 10px
}

.filter-list.golf-list .compt img {
    border-radius: 0 4px 4px 0;
    vertical-align: middle;
    margin-top: -3px;
    margin-left: 5px;
    background-position: -15px -71px;
    height: 23px;
    width: 12px
}

.filter-list.golf-list .compt img:hover,
.filter-list.golf-list .compt img.btn-comt-hover {
    background-position: -15px -48px
}

.filter-list.golf-list .compt:hover img {
    background-position: -15px -48px
}

.filter-pop {
    position: absolute;
    right: 0;
    width: 500px;
    border-radius: 4px;
    background-color: #fff;
    padding: 8px 8px 0;
    margin-left: -500px;
    z-index: 3
}

.filter-pop:not(:target) {
    display: none
}

.filter-pop:target {
    display: block
}

.filter-pop ul {
    margin-bottom: 5px
}

.filter-pop li {
    width: 250px;
    list-style: none;
    margin-bottom: 3px
}

.filter-pop .btn-wrap {
    border-top: 1px solid #ccc;
    padding-top: 8px
}

.filter-pop .btn-wrap .btn,
.filter-pop .btn-wrap .btn-send,
.filter-pop .btn-wrap .pages a,
.pages .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .filter-pop .btn-wrap input,
.filter-pop .btn-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .filter-pop .btn-wrap textarea,
.filter-pop .btn-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .filter-pop .btn-wrap a,
.filter-pop .btn-wrap .total_all .btn_replay,
.total_all .filter-pop .btn-wrap .btn_replay,
.filter-pop .btn-wrap .btn_expand,
.filter-pop .btn-wrap .add_member {
    width: 15%;
    margin-right: 10px
}

.filter-pop .btn-wrap .btn-send {
    width: 20%
}

.expand-close,
.expand-open {
    text-decoration: none;
    color: #000;
    padding-right: 20px;
    background-position: 100% -136px
}

table .expand-close,
table .expand-open {
    text-decoration: none
}

.align-L .expand-close,
.table01 caption .expand-close,
.table-s caption .expand-close,
.table_one caption .expand-close,
.no-data .expand-close,
.status-popup .expand-close,
.access-popup .expand-close,
.table_one thead th .expand-close,
.table-pt .expand td.expand-event:first-child .expand-close,
.pt_allowed dl dt .expand-close,
.tab-transfer .full-amount .expand-close,
.tab-transfer .remark .expand-close,
.tips-popup .expand-close,
.slip-head .col-playID .expand-close,
.slip-head .col-type .expand-close,
.slip-head .col-country .expand-close,
.slip-head .col-local .expand-close,
.table01 .td-type .expand-close,
.table-s .td-type .expand-close,
.table_one .td-type .expand-close,
.table01 .td-UID .expand-close,
.table-s .td-UID .expand-close,
.table_one .td-UID .expand-close,
.td-uid .expand-close,
.DW-amount .expand-close,
.pop_bg .form-wrap h1 .expand-close,
.align-L .expand-open,
.table01 caption .expand-open,
.table-s caption .expand-open,
.table_one caption .expand-open,
.no-data .expand-open,
.status-popup .expand-open,
.access-popup .expand-open,
.table_one thead th .expand-open,
.table-pt .expand td.expand-event:first-child .expand-open,
.pt_allowed dl dt .expand-open,
.tab-transfer .full-amount .expand-open,
.tab-transfer .remark .expand-open,
.tips-popup .expand-open,
.slip-head .col-playID .expand-open,
.slip-head .col-type .expand-open,
.slip-head .col-country .expand-open,
.slip-head .col-local .expand-open,
.table01 .td-type .expand-open,
.table-s .td-type .expand-open,
.table_one .td-type .expand-open,
.table01 .td-UID .expand-open,
.table-s .td-UID .expand-open,
.table_one .td-UID .expand-open,
.td-uid .expand-open,
.DW-amount .expand-open,
.pop_bg .form-wrap h1 .expand-open {
    padding-right: 0;
    padding-left: 15px;
    color: #2789CE;
    background-position: -16px -136px
}

.expand-open {
    background-position: 100% -151px
}

.align-L .expand-open,
.table01 caption .expand-open,
.table-s caption .expand-open,
.table_one caption .expand-open,
.no-data .expand-open,
.status-popup .expand-open,
.access-popup .expand-open,
.table_one thead th .expand-open,
.table-pt .expand td.expand-event:first-child .expand-open,
.pt_allowed dl dt .expand-open,
.tab-transfer .full-amount .expand-open,
.tab-transfer .remark .expand-open,
.tips-popup .expand-open,
.slip-head .col-playID .expand-open,
.slip-head .col-type .expand-open,
.slip-head .col-country .expand-open,
.slip-head .col-local .expand-open,
.table01 .td-type .expand-open,
.table-s .td-type .expand-open,
.table_one .td-type .expand-open,
.table01 .td-UID .expand-open,
.table-s .td-UID .expand-open,
.table_one .td-UID .expand-open,
.td-uid .expand-open,
.DW-amount .expand-open,
.pop_bg .form-wrap h1 .expand-open {
    background-position: -16px -151px
}

.table-s td,
.table-s th {
    padding: 5px
}

.table-s input,
.table-s textarea,
.table-s .member_select a,
.member_select .table-s a {
    margin-bottom: 0;
    padding: 0
}

.small-date {
    font-size: 11px;
    line-height: 13px
}

.market-float {
    width: 94%
}

.expand {
    background-color: #024168
}

.expand>td {
    position: relative;
    padding: 0;
    border-top-color: #7E97A7;
    border-bottom: 1px solid #7E97A7
}

.expand table {
    width: 85%;
    border-left: 1px solid #7E97A7;
    border-bottom-width: 0;
    margin-left: 15%
}

.expand table th {
    background-color: transparent;
    border-top-width: 0
}

.expand table td {
    border-width: 0;
    padding: 8px 10px
}

.expand table .even {
    background-color: #f2f4f7
}

.table-s .expand table td {
    padding: 5px
}

.expand .table-commission {
    width: 75%;
    margin-left: 23%;
    border-right: 1px solid #7E97A7
}

.expand .sum-pl {
    background-color: #D9E4EC;
    border-top: 1px solid #7E97A7
}

.expand .sum-pl td {
    padding: 0
}

.expand-arrow {
    position: absolute;
    left: 15%;
    margin-left: -10px;
    background-position: -17px -127px;
    height: 9px;
    width: 10px
}

.sum-pl dl {
    padding: 5px 0
}

.sum-pl dt {
    width: 82%;
    padding: 0 10px 5px;
    color: #243A48
}

.sum-pl dd {
    padding: 0 10px 5px
}

.sum-pl .net_total {
    padding-top: 5px;
    border-top: 1px dotted #7E97A7
}

.expand-arrow-R {
    position: absolute;
    left: 98%;
    margin-top: -1px;
    background-position: -17px -118px;
    height: 9px;
    width: 10px
}

.white-wrap,
.total_box,
.fix-content {
    background-color: #fff;
    color: #3B5160;
    padding: 7px 10px 5px;
    border-bottom: 1px solid #7E97A7;
    margin-bottom: 15px
}

.white-wrap h3,
.total_box h3,
.fix-content h3 {
    width: 85%;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 7px
}

.white-wrap p,
.total_box p,
.fix-content p {
    width: 85%;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px
}

.acc-info {
    margin-bottom: 5px
}

.acc-info li {
    margin-right: 15px;
    padding-left: 20px
}

.acc-info .user {
    background-position: -12px -205px
}

.acc-info .time {
    background-position: -12px -190px
}

.total-show {
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 5px
}

.total-show select {
    width: 140px;
    margin-bottom: 0;
    font-weight: bold
}

/* .table-other{
    margin-top:-10px;
    color:#3B5160
} */
.head-balance {
    width: 31.37255%;
    border-right: 1px solid #d8d8d8;
    margin-right: 10px
}

.head-balance dt {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 7px
}

.head-balance dd {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
    color: #2789CE
}

.head-balance dd span {
    font-size: 12px;
    color: #7E97A7;
    font-weight: normal
}

.total,
.grand-total {
    font-weight: bold;
    color: #243A48
}

.total td,
.grand-total td {
    border-top-color: #7E97A7;
    border-bottom: 1px solid #7E97A7
}

.grand-total {
    background-color: #E0E6E6
}

.tax {
    font-weight: bold;
    border-bottom: 2px solid #979797
}

.fromto {
    margin: 0 5px;
    background-position: -20px -182px;
    height: 8px;
    width: 7px
}

.pages {
    margin: 25px 0
}

.pages li {
    list-style: none;
    line-height: 25px;
    margin-right: 5px
}

.pages a {
    display: inline;
    font-weight: normal;
    margin: 0;
    padding: 4px 10px
}

.calendarPanel {
    position: absolute;
    z-index: 9999;
    left: 0px;
    top: 0px;
    background-color: #fff
}

.calendarTable_1 {
    background-color: #243A48;
    width: 100%
}

.calendarTable_1 th {
    line-height: 30px;
    height: 30px;
    padding: 5px
}

.calendarTable_1 select {
    margin: 0;
    width: 80px
}

.calendarTable_1 .calendarYear {
    width: 60px
}

.calendarTable_1 input,
.calendarTable_1 textarea,
.calendarTable_1 .member_select a,
.member_select .calendarTable_1 a {
    width: 27px;
    height: 20px;
    cursor: pointer;
    margin: 0;
    border-radius: 0;
    box-shadow: none
}

.prevMonth {
    background-position: 0 -280px
}

.prevMonth:hover,
.prevMonth.prev_m-hover {
    background-position: 0 -260px
}

.nextMonth {
    background-position: 0 -240px
}

.nextMonth:hover,
.nextMonth.mext_m-hover {
    background-position: 0 -220px
}

.calendarTable_2 {
    font-size: 12px
}

.calendarTable_2 th {
    width: 25px;
    padding: 3px;
    font-weight: normal;
    font-size: 11px;
    color: #243A48;
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0;
    background-color: #E4E4E4
}

.calendarTable_2 td {
    width: 25px;
    padding: 3px;
    border: 1px solid #ddd
}

.calendarTable_inputBox {
    background-color: #c2c2c2
}

.calendarTable_inputBox th {
    border-bottom-width: 0
}

.calendarTable_inputBox input,
.calendarTable_inputBox textarea,
.calendarTable_inputBox .member_select a,
.member_select .calendarTable_inputBox a {
    width: 100%;
    font-size: 12px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    box-shadow: none;
    font-weight: normal
}

.comingDate {
    color: #999 !important
}

.pop-left {
    position: relative;
    width: 310px;
    height: 542px;
    padding: 20px 20px 0 20px;
    overflow: hidden;
    font-size: 15px;
    line-height: 20px
}

.pop-left dl {
    margin-bottom: 10px
}

.pop-left dt {
    width: 125px;
    margin-bottom: 10px;
    color: #3B5160
}

.pop-left dd {
    position: relative;
    color: #000;
    font-weight: bold
}

.pop-left .select-betting {
    line-height: 23px;
    border-bottom: 1px solid #C5D0D7
}

.selection {
    height: 23px;
    line-height: 23px;
    border: 1px solid #7E97A7;
    border-radius: 4px;
    padding: 0 10px;
    background-position: 100% 0
}

.select-betting .selection {
    width: 163px
}

.selection-list {
    top: 25px;
    right: 0;
    min-width: 185px;
    font-weight: normal;
    z-index: 5
}

.selection-list:not(:target) {
    display: none
}

.selection-list:target {
    display: block
}

.selection-list a {
    font-size: 14px;
    line-height: 28px;
    white-space: nowrap
}

.chart-wrap {
    width: 278px;
    overflow: hidden;
    font-size: 13px;
    margin-bottom: 10px
}

.chart-wrap img {
    width: 350px;
    height: 255px;
    margin-bottom: -5px
}

.chart-wrap h2 {
    position: absolute;
    width: 100%;
    font-size: 18px;
    line-height: 25px;
    background-color: #fff;
    margin: 0;
    padding: 0
}

.pop-note {
    position: absolute;
    bottom: 0;
    width: 310px;
    border-top: 1px solid #C5D0D7;
    font-size: 12px;
    color: #3B5160;
    line-height: 14px;
    padding: 5px 0 10px;
    margin-bottom: 0
}

.pop-right {
    width: 529px;
    height: 542px;
    padding: 20px 20px 0;
    overflow: auto;
    overflow-x: hidden;
    border-left: 1px solid #C5D0D7
}

.pop-right h2 {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 5px;
    padding-top: 0
}

.tab-depth {
    border-left: 1px solid #eee
}

.tab-depth td {
    border-right: 1px solid #eee;
    border-color: #eee;
    padding: 5px 10px
}

.tab-depth .back {
    background-color: #CDE8FD;
    color: #1E1E1E !important;
    border-color: #fff
}

.tab-depth .lay {
    background-color: #FAE5EB;
    color: #1E1E1E !important;
    border-color: #fff
}

.initial-balance,
.final-balance {
    background-color: #ECE7DC;
    color: #3B5160;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid #7E97A7
}

.initial-balance dt,
.final-balance dt,
.initial-balance dd,
.final-balance dd {
    padding: 10px
}

.initial-balance dt span,
.final-balance dt span {
    width: 60px;
    color: #1E1E1E;
    background-color: #CEA976;
    margin-right: 15px;
    font-weight: bold;
    border-radius: 3px
}

.initial-balance dd,
.final-balance dd {
    padding-left: 0;
    color: #000
}

.initial-balance dd:first-of-type,
.final-balance dd:first-of-type {
    width: 120px;
    padding-left: 10px;
    color: #3B5160
}

.table01 .initial-balance,
.table-s .initial-balance,
.table_one .initial-balance,
.table01 .final-balance,
.table-s .final-balance,
.table_one .final-balance {
    background-color: #fff;
    font-size: 12px;
    line-height: 15px
}

.table01 .initial-balance td,
.table-s .initial-balance td,
.table_one .initial-balance td,
.table01 .final-balance td,
.table-s .final-balance td,
.table_one .final-balance td {
    border: 1px solid #7E97A7;
    border-width: 1px 0 1px 0
}

.final-balance {
    border-top: 1px solid #7E97A7;
    margin: -15px 0 15px
}

.tab-scroll-y {
    width: 100%;
    height: 512px;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 15px;
    border-bottom: 1px solid #7E97A7
}

.tab-scroll-y .table01,
.tab-scroll-y .table-s,
.tab-scroll-y .table_one {
    margin-bottom: 0;
    border-bottom-width: 0
}

.report-tab-wrap {
    position: relative;
    margin-top: 16px;
    list-style-type: none
}

.report-tab-wrap .report-tab {
    display: inline-block;
    padding: 5px 24px 9px;
    margin-right: 2px;
    background-color: #FFFFFF;
    border: 1px solid #3B5160;
    border-radius: 3px 3px 0 0;
    color: #3B5160;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer
}

.report-tab-wrap .report-tab.select {
    background-color: #3B5160;
    color: #FFFFFF
}

.report-tab-wrap .report-tab.select:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #3B5160
}

.casino-grand-total {
    border-top: 1px solid #7E97A7;
    background-color: #D9E4EC;
    font-weight: bold
}

.table-trans {
    width: 100%;
    border-top: 1px solid #7E97A7;
    border-bottom: 1px solid #7E97A7
}

.table-trans tr {
    background-color: #FFFFFF
}

.table-trans tr:nth-child(odd) {
    background-color: #f2f4f7
}

.table-trans th {
    padding: 8px;
    border-right: 1px solid #7E97A7;
    border-bottom: 1px solid #7E97A7;
    background-color: #E4E4E4;
    color: #243A48
}

.table-trans th:last-child {
    border-right: none
}

.table-trans td {
    padding: 8px
}

.table-trans td span {
    display: block
}

.trans-replay {
    margin: 0 auto;
    background-position: -3px -94px;
    height: 24px;
    width: 24px
}

.data-del {
    position: relative;
    color: #999999 !important
}

.data-del:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #666666
}

.data-del span {
    color: #999999 !important
}

.casino-result {
    width: 1200px
}

.casino-result iframe {
    width: calc(100% - 16px);
    height: calc(560px - 16px);
    padding: 8px;
    overflow-y: scroll
}

.casino-result .announce-header span {
    padding: 2px 10px;
    margin: 0 5px;
    border-radius: 4px;
    background-color: #243A48;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: normal
}

.casino-result .announce-header .close_pop {
    top: 18px;
    -webkit-transform: scale(1.4);
    transform: scale(1.4)
}

.trans-wrap .input-list,
.trans-wrap .filter-list {
    display: inline-block;
    margin-left: 12px
}

.trans-wrap .input-list:first-of-type,
.trans-wrap .filter-list:first-of-type {
    margin-left: 0
}

.trans-wrap .input-list label,
.trans-wrap .filter-list label {
    margin-right: 0
}

.trans-wrap .input-list select,
.trans-wrap .filter-list select {
    margin: 0
}

.trans-wrap .btn-send {
    margin-left: 20px
}

.trans-wrap .pages {
    position: relative;
    height: 24px;
    margin: 0 auto;
    padding: 12px 0;
    background: #FFFFFF
}

.trans-wrap .pages:before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.1)));
    background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.1));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.1))
}

.trans-wrap .pop-title {
    height: calc(100% - 35px - 48px);
    overflow-y: auto;
    margin-bottom: 0;
    padding-bottom: 16px;
    border: none;
    box-sizing: border-box
}

.menu,
.setting-wrap {
    width: 100%
}

/* .menu .logout,.setting-wrap .logout{
    border-left:1px solid #383838
} */
.menu .logout img,
.setting-wrap .logout img {
    width: 10px;
    height: 11px;
    margin-left: 5px;
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="rgb(255, 255, 255)" fill-rule="evenodd"/></svg>') no-repeat
}

.bank {
    background-position: -32px -196px
}

.betting_history {
    background-position: -32px -308px
}

.p_l {
    background-position: -32px -1423px
}

.profile {
    background-position: -32px -1475px
}

.status {
    background-position: -32px -1566px
}

.total_box {
    width: 100%;
    padding: 7px 0 5px;
    margin-top: 15px
}

.total_box .total_dl {
    width: 14.96815%;
    padding: 0 10px;
    border-right: 1px solid #D2D2D2
}

.total_box .total_dl dt {
    font-size: 12px;
    margin: 0 0 5px 0;
    color: #9B9B9B
}

.total_box .total_dl dd {
    font-size: 15px;
    color: #243A48;
    line-height: 20px;
    font-weight: bold
}

.total_box .total_dl:last-child {
    border-right: none
}

.total_all {
    margin: 13px 0 10px 0
}

.total_all h2 {
    font-size: 16px;
    color: #243A48
}

.total_all .btn_replay {
    height: 30px;
    width: 30px;
    margin: 0 0 0 10px
}

.total_all .btn_replay img {
    margin-right: 0px;
    margin-top: 5px;
    background-position: -44px -980px;
    height: 17px;
    width: 14px
}

.total_all .btn_replay img:hover,
.total_all .btn_replay img.ico_replay-hover {
    background-position: -44px -963px
}

.total_all select {
    height: 30px;
    line-height: 30px;
    margin: 0
}

.total_all .input-list,
.total_all .filter-list {
    margin-bottom: 0
}

.total_all .input-list li,
.total_all .filter-list li {
    margin: 0 5px 0 0
}

.total_all .input-list .btn-send,
.total_all .filter-list .btn-send {
    height: 30px;
    line-height: 28px
}

.no-content-wrap {
    position: relative;
    height: 60px;
    background: #fff;
    padding: 20px;
    border-top: 1px solid #7E97A7;
    box-shadow: 0 2px 0 0 rgba(131, 131, 131, 0.23);
    margin-bottom: 15px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center
}

.no-content-wrap p {
    font-size: 15px;
    color: #7E97A7;
    margin-bottom: 0
}

.no-content-wrap:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 40px;
    z-index: 2;
    border-top: 13px solid #EEEEEE;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent
}

.no-content-wrap:after {
    content: '';
    position: absolute;
    top: -1px;
    left: 38px;
    z-index: 1;
    border-top: 15px solid #7E97A7;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent
}

.btn_expand {
    height: 30px;
    width: 130px;
    border-radius: 3px;
    font-size: 13px;
    color: #1E1E1E;
    font-weight: bold;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin: 0
}

.btn_expand:before {
    content: '';
    height: 14px;
    width: 12px;
    margin-right: 5px;
    background-position: 100% -136px
}

.btn_expand.close:before {
    background-position: 100% -151px
}

.total_info {
    margin-top: 10px
}

.total_info li {
    padding: 0 10px;
    border-left: 1px solid #9B9B9B
}

.total_info li:first-child {
    border-left: none;
    padding-left: 0
}

.total_info li strong {
    margin-left: 3px
}

.add_member {
    height: 30px;
    margin: 0;
    line-height: 30px;
    padding: 0 15px
}

.add_member img {
    margin-right: 5px;
    background-position: -32px -32px;
    height: 15px;
    width: 26px
}

.add_member img:hover,
.add_member img.add_member-hover {
    background-position: -32px -32px
}

.acc-info .status_all {
    padding-left: 0
}

.status-active {
    background-color: #E5F1DC;
    border: 1px solid #BEDCA7;
    color: #508D0E
}

.status-active img {
    background-position: -50px -1303px;
    height: 8px;
    width: 8px
}

.status-suspend {
    background-color: #F2E2E6;
    border: 1px solid #DEB6C0;
    color: #024168
}

.status-suspend img {
    background-position: -50px -1319px;
    height: 8px;
    width: 8px
}

.status-lock {
    background-color: #E3E8EB;
    border: 1px solid #B9C5CD;
    color: #5A7384
}

.status-lock img {
    background-position: -50px -1311px;
    height: 8px;
    width: 8px
}

.status-popup,
.access-popup {
    position: absolute;
    top: 10px;
    right: -155px;
    width: 160px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    border-radius: 4px;
    z-index: 50
}

.status-popup li,
.access-popup li {
    list-style: none;
    border-bottom: 1px solid #474747;
    padding: 5px 7px 5px 18px;
    background: url(../images/agent/status_arrow.png) no-repeat 7px 10px
}

.status-popup li:last-of-type,
.access-popup li:last-of-type {
    border-bottom-width: 0
}

.margin-table:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 36px;
    border-top: 1px solid #7E97A7
}

.table01 td,
.table-s td,
.table_one td {
    position: relative
}

.table01 .tips-popup,
.table-s .tips-popup,
.table_one .tips-popup {
    position: absolute;
    top: 0;
    right: -150px;
    z-index: 50;
    background-color: #F8E71C;
    line-height: 13px;
    color: #1E1E1E;
    border-radius: 4px;
    padding: 3px 5px;
    background-color: #F8E71C;
    text-align: left;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.5);
    width: 145px
}

.table01 strong.no,
.table-s strong.no,
.table_one strong.no {
    width: 15px
}

.table01 .ico_account img,
.table-s .ico_account img,
.table_one .ico_account img {
    margin: 0 5px;
    background-position: -45px -1514px;
    height: 13px;
    width: 13px
}

.table01 .status_all,
.table-s .status_all,
.table_one .status_all {
    padding-right: 0
}

.table01 .status_all .icon_active img,
.table-s .status_all .icon_active img,
.table_one .status_all .icon_active img {
    background-position: -50px -1335px;
    height: 8px;
    width: 8px
}

.table01 .status_all .icon_locked img,
.table-s .status_all .icon_locked img,
.table_one .status_all .icon_locked img {
    background-position: -50px -1351px;
    height: 8px;
    width: 8px
}

.table01 .status_all .icon_suspend img,
.table-s .status_all .icon_suspend img,
.table_one .status_all .icon_suspend img {
    background-position: -50px -1367px;
    height: 8px;
    width: 8px
}

.table01 .odd,
.table-s .odd,
.table_one .odd {
    background-color: #F2F4F7
}

.table01 .event,
.table-s .event,
.table_one .event {
    background-color: #E2E8ED
}

.table01 .border-t td,
.table-s .border-t td,
.table_one .border-t td {
    border-top: 1px solid #7E97A7
}

.table01 .border-d td,
.table-s .border-d td,
.table_one .border-d td {
    border-bottom: 1px solid #7E97A7
}

.table01 .border-l,
.table-s .border-l,
.table_one .border-l {
    border-left: 1px solid #7E97A7
}

.table01 .title_td-BB td,
.table-s .title_td-BB td,
.table_one .title_td-BB td {
    font-weight: bold;
    color: #243A48;
    border-bottom: 1px solid #7E97A7
}

.table01 .bg_y,
.table-s .bg_y,
.table_one .bg_y {
    background-color: #BEDDF4
}

.table01 .bg_y2,
.table-s .bg_y2,
.table_one .bg_y2 {
    background-color: #F4E0BD
}

.table01 .action a,
.table-s .action a,
.table_one .action a {
    width: 26px;
    height: 26px;
    margin-left: 3px
}

.table01 .action .bank:hover,
.table-s .action .bank:hover,
.table_one .action .bank:hover {
    background-position: -32px -170px
}

.table01 .action .betting_history:hover,
.table-s .action .betting_history:hover,
.table_one .action .betting_history:hover {
    background-position: -32px -282px
}

.table01 .action .p_l:hover,
.table-s .action .p_l:hover,
.table_one .action .p_l:hover {
    background-position: -32px -1397px
}

.table01 .action .profile:hover,
.table-s .action .profile:hover,
.table_one .action .profile:hover {
    background-position: -32px -1449px
}

.table01 .action .status:hover,
.table-s .action .status:hover,
.table_one .action .status:hover {
    background-position: -32px -1540px
}

.table01 .bgcolor-sub,
.table-s .bgcolor-sub,
.table_one .bgcolor-sub {
    background-color: #BEDDF4
}

.table01 .bgcolor-ma,
.table-s .bgcolor-ma,
.table_one .bgcolor-ma {
    background-color: #C7EABF
}

.table01 .bgcolor-sup,
.table-s .bgcolor-sup,
.table_one .bgcolor-sup {
    background-color: #DACCF5
}

.table01 .bgcolor-ss,
.table-s .bgcolor-ss,
.table_one .bgcolor-ss {
    background-color: #F3D0E5
}

.table01 .bgcolor-one,
.table-s .bgcolor-one,
.table_one .bgcolor-one {
    background-color: #F4E0BD
}

.table01 .bgcolor-bet,
.table-s .bgcolor-bet,
.table_one .bgcolor-bet {
    background-color: #F3CECE
}

.table_one_box {
    margin-bottom: 37px
}

.table_one {
    margin-bottom: 0
}

.table_one thead th {
    font-size: 15px;
    background: #3B5160;
    color: #fff;
    border: none
}

.btn_open,
.btn_close {
    width: 9px;
    height: 9px;
    margin-right: 5px;
    background-position: -49px -511px;
    margin-top: 3px
}

.btn_close {
    background-position: -49px -450px
}

.img_none {
    background-image: none
}

.lv_0,
.lv_1,
.lv_2,
.lv_3,
.lv_4,
.lv_5,
.lv_sub {
    width: 26px;
    height: 15px;
    line-height: 15px;
    text-decoration: none !important;
    color: #fff;
    background: #f17007;
    border-radius: 4px;
    margin-right: 5px;
    font-size: 10px
}

.lv_1 {
    background: #D65D5D
}

.lv_2 {
    background: #9e06bd
}

.lv_3 {
    background: #63b405
}

.lv_4 {
    background: #055cc0
}

.lv_5 {
    background: #568bc8;
}

.lv_sub {
    background: #1c05b8
}

.ico_dot1,
.ico_dot2,
.ico_dot3 {
    width: 5px;
    height: 5px;
    margin: 0 3px;
    background-position: -13px -648px;
    margin-top: 5px
}

.ico_dot2 {
    width: 15px
}

.ico_dot3 {
    width: 25px
}

.table-pt {
    table-layout: fixed;
    font-size: 11px;
    margin: 20px 0 0
}

.table-pt:first-child {
    margin-top: 0
}

.table-pt td,
.table-pt th {
    padding: 8px 5px
}

.table-pt .title_td-BB td {
    line-height: 13px;
    padding: 5px;
    overflow: visible;
    text-overflow: normal;
    white-space: normal
}

.table-pt .title_td-BB td:first-child {
    width: 50%
}

.table-pt .expand-close,
.table-pt .expand-open {
    width: 15px;
    height: 15px;
    padding-left: 0
}

.table-pt .ico_account {
    width: calc(100% - 15px);
    float: none
}

.table-pt .self {
    background-color: #FEFBF0;
    border-top: 3px solid #024168
}

.table-pt .expand {
    box-shadow: none
}

.table-pt .expand td {
    border-bottom-color: #E2E8ED
}

.table-pt .expand td:first-child {
    padding-left: 50px
}

.table-pt .expand td:first-child.expand-event {
    padding-left: 20px
}

.table-pt .expand.first {
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.1)
}

.table-pt .expand.last td {
    border-bottom-color: #7E97A7
}

.report_over-wrap {
    overflow: auto;
    border-bottom: 1px solid #C5D0D7
}

.footer_fixed {
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 51
}

.footer_fixed .main_wrap,
.footer_fixed .header,
.footer_fixed .footer-content {
    border-top: 1px solid #d4d4d4;
    background-color: #eee
}

.footer_fixed .action {
    margin: 0 auto;
    padding: 5px 0
}

.footer_fixed .action li {
    height: 26px;
    font-size: 11px;
    padding-left: 30px
}

.footer_fixed .action p {
    line-height: 11px;
    padding-top: 7px;
    margin-right: 20px
}

.footer_fixed .action .close p {
    margin-right: 0
}

.pop_box p.note,
.pop_refer p.note {
    font-size: 12px;
    margin-bottom: 10px;
    color: #888
}

.pop_box .tab,
.pop_refer .tab {
    width: 100%
}

.pop_box .tab .bg_red,
.pop_refer .tab .bg_red {
    background: #B00016
}

.pop_box .tab .bg_green,
.pop_refer .tab .bg_green {
    background: #417505
}

.pop_box .head-balance,
.pop_refer .head-balance {
    width: 180px;
    margin: 0
}

.pop_box .head-balance dt,
.pop_refer .head-balance dt {
    font-size: 13px
}

.pop_box .head-balance dd,
.pop_refer .head-balance dd {
    font-size: 25px;
    line-height: 25px;
    word-break: break-all;
    padding-right: 5px
}

.pop_box.w_350,
.w_350.pop_refer {
    width: 350px
}

.pop_box.w_770,
.w_770.pop_refer {
    width: 770px
}

.pop_box.w_870,
.w_870.pop_refer {
    width: 870px
}

.pop_box .pt_allowed_box,
.pop_refer .pt_allowed_box {
    margin-bottom: 5px
}

.pop_box .form_list,
.pop_refer .form_list,
.pop_box .add_account_box dl,
.add_account_box .pop_box dl,
.pop_refer .add_account_box dl,
.add_account_box .pop_refer dl,
.pop_box .pt_allowed dl,
.pt_allowed .pop_box dl,
.pop_refer .pt_allowed dl,
.pt_allowed .pop_refer dl {
    padding: 0 10px
}

.pop_box .pt_allowed dl,
.pop_refer .pt_allowed dl {
    padding: 0
}

.pop_box .total_box,
.pop_refer .total_box {
    width: 380px;
    padding: 8px
}

.pop_box .total_dl,
.pop_refer .total_dl {
    width: 100%;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #D2D2D2;
    border-right: none
}

.pop_box .total_dl dt,
.pop_refer .total_dl dt {
    color: #3B5160
}

.pop_box .total_dl dd,
.pop_refer .total_dl dd {
    color: #2789CE
}

.pop_box .total_dl:last-child,
.pop_refer .total_dl:last-child {
    border-bottom: none;
    padding: 8px 0 0 0
}

.half_box {
    margin-top: 30px
}

.half_box li {
    width: 50%
}

.sports_box .half_box {
    margin-top: 0
}

.sports_box .half_box>li {
    width: calc(50% - 5px)
}

.sports_box .half_box>li:first-of-type {
    margin-right: 10px
}

.add-member-box>li:first-of-type {
    width: 360px
}

.add-member-box>li:last-of-type {
    width: calc(100% - 360px)
}

.add_account_box dl {
    width: 335px;
    padding: 0 0 0 30px;
    margin-bottom: 15px
}

.undefined {
    background: #000;
    color: #fff;
}

.add_account_box dl dd {
    margin-bottom: 6px
}

.add_account_box dl dd select {
    width: 160px;
    margin: 0
}

.add_account_box dl.border_b {
    border-bottom: 1px solid #BFBFBF
}

.pt_allowed_box {
    background-color: #fff;
    border-radius: 4px
}

.pt_allowed_box dd {
    margin-bottom: 0;
    padding-left: 0
}

.pt_allowed_box input,
.pt_allowed_box textarea,
.pt_allowed_box .member_select a,
.member_select .pt_allowed_box a {
    border: 1px solid #BBBBBB;
    box-shadow: none;
    margin: 0
}

.pt_allowed_box input:focus,
.pt_allowed_box textarea:focus,
.pt_allowed_box .member_select a:focus,
.member_select .pt_allowed_box a:focus {
    padding: 5px
}

.pt_allowed {
    padding: 10px 10px 0;
    border-bottom: 1px solid #E8E8E8
}

.pt_allowed dl {
    width: 100%;
    margin-bottom: 0;
    padding: 0
}

.pt_allowed dl dt {
    padding: 0 10px;
    width: auto;
    margin-right: 0
}

.pt_allowed dl dd {
    padding: 0 10px 0 70%;
    float: none
}

.pt_allowed dl dd input,
.pt_allowed dl dd textarea,
.pt_allowed dl dd .member_select a,
.member_select .pt_allowed dl dd a {
    width: 100%
}

.w_770 div.pt_allowed_box .pt_allowed dl {
    width: calc(50% - 5px)
}

.toggle_on,
.toggle_off {
    color: #fff;
    width: 58px;
    height: 25px;
    text-indent: 7px;
    background-position: 0 -1623px
}

.toggle_off {
    text-indent: 25px;
    background-position: 0 -1598px
}

.sports_box {
    padding: 10px
}

.sports_box ul li {
    list-style: none
}

.sports_box dl {
    line-height: 25px;
    background-color: #fff;
    padding: 5px
}

.sports_box dl dt {
    -webkit-box-flex: 1;
    flex: 1;
    padding: 0 5px
}

.sports_box dl dd {
    height: 28px;
    flex-basis: 80px;
    color: #0350AA;
    margin-left: 5px
}

.add-member-box .sports_box dl dd {
    flex-basis: 45px
}

.sports_box dl dd.dd_last {
    background: rgba(101, 118, 134, 0.15);
    color: #1E1E1E;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 1px
}

.sports_box dl dd.dd_last input,
.sports_box dl dd.dd_last textarea,
.sports_box dl dd.dd_last .member_select a,
.member_select .sports_box dl dd.dd_last a {
    background: none;
    border: none
}

.sports_box dl dd.dd_last input:focus,
.sports_box dl dd.dd_last textarea:focus,
.sports_box dl dd.dd_last .member_select a:focus,
.member_select .sports_box dl dd.dd_last a:focus {
    outline: none;
    border-color: #BBB;
    box-shadow: none
}

.sports_box dl .dd_lay {
    color: #B00015
}

.sports_box input,
.sports_box textarea,
.sports_box .member_select a,
.member_select .sports_box a {
    width: 100%;
    height: 28px
}

.sports_box p {
    margin: 0 10px 10px
}

.sports_box .odd-add {
    right: 5px
}

.sports_set,
.sports_set2 {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 266px;
    border-bottom: 1px solid #7E97A7;
    margin-bottom: 10px
}

.sports_set dl,
.sports_set2 dl {
    line-height: 27px;
    background-color: #F2F4F7
}

.sports_set dt,
.sports_set2 dt {
    z-index: 20
}

.sports_set dd,
.sports_set2 dd {
    position: relative
}

.sports_set .bg_head,
.sports_set2 .bg_head {
    color: #fff;
    background-color: #3B5160
}

.sports_set .bg_dark,
.sports_set2 .bg_dark {
    background-color: #E2E8ED
}

.sports_set.pt_player dd,
.pt_player.sports_set2 dd {
    color: #1E1E1E;
    margin-left: 0;
    margin-right: 5px
}

.sports_set.pt_player .bg_head dd,
.pt_player.sports_set2 .bg_head dd {
    color: #fff
}

.sports_set select,
.sports_set2 select {
    width: 100%;
    height: 28px
}

.sports_set2 {
    overflow-x: hidden;
    overflow-y: hidden;
    height: inherit;
    border-top: 1px solid #7E97A7
}

.sports_set2 dt,
.sports_set2 dd {
    text-indent: 20px;
    line-height: 16px
}

.status_but {
    width: 351px;
    margin: 8px auto
}

.status_but .but_active,
.status_but .but_suspend,
.status_but .but_locked {
    width: 105px;
    height: 50px;
    font-size: 15px;
    line-height: 15px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#FEFEFE), to(#E0E0E0));
    background-image: -webkit-linear-gradient(top, #FEFEFE 0%, #E0E0E0 100%);
    background-image: linear-gradient(-180deg, #FEFEFE 0%, #E0E0E0 100%);
    border: 1px solid #9D9D9D;
    border-radius: 8px;
    margin: 0 5px;
    padding: 8px 0;
    color: #508D0E
}

.status_but .but_active:hover,
.status_but .but_suspend:hover,
.status_but .but_locked:hover {
    text-decoration: none;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(23%, #E9E9E9), to(#FEFEFE));
    background-image: -webkit-linear-gradient(top, #E9E9E9 23%, #FEFEFE 100%);
    background-image: linear-gradient(-180deg, #E9E9E9 23%, #FEFEFE 100%)
}

.status_but .but_active img,
.status_but .but_suspend img,
.status_but .but_locked img {
    margin: 0 auto;
    background-position: -25px -743px;
    height: 33px;
    width: 33px
}

.status_but .but_active.open,
.status_but .open.but_suspend,
.status_but .open.but_locked {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(3%, #8DD13B), to(#4E7427));
    background-image: -webkit-linear-gradient(top, #8DD13B 3%, #4E7427 100%);
    background-image: linear-gradient(-180deg, #8DD13B 3%, #4E7427 100%);
    color: #fff
}

.status_but .but_active.open img,
.status_but .open.but_suspend img,
.status_but .open.but_locked img {
    background-position: -25px -809px;
    height: 33px;
    width: 33px
}

.status_but .but_active.disable img,
.status_but .disable.but_suspend img,
.status_but .disable.but_locked img {
    background-position: -25px -776px;
    height: 33px;
    width: 33px
}

.status_but .but_active.disable:hover,
.status_but .disable.but_suspend:hover,
.status_but .disable.but_locked:hover {
    background: none
}

.status_but .but_suspend {
    color: #E83523
}

.status_but .but_suspend img {
    background-position: -25px -997px;
    height: 33px;
    width: 33px
}

.status_but .but_suspend.open {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#DB2828), to(#921313));
    background-image: -webkit-linear-gradient(top, #DB2828 0%, #921313 100%);
    background-image: linear-gradient(-180deg, #DB2828 0%, #921313 100%);
    color: #fff
}

.status_but .but_suspend.open img {
    background-position: -26px -1063px;
    height: 32px;
    width: 32px
}

.status_but .but_suspend.disable img {
    background-position: -25px -1030px;
    height: 33px;
    width: 33px
}

.status_but .but_suspend.disable:hover {
    background: none
}

.status_but .but_locked {
    color: #708B9D
}

.status_but .but_locked img {
    background-position: -25px -842px;
    height: 33px;
    width: 33px
}

.status_but .but_locked.open {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#9AB6CE), to(#536174));
    background-image: -webkit-linear-gradient(top, #9AB6CE 0%, #536174 100%);
    background-image: linear-gradient(-180deg, #9AB6CE 0%, #536174 100%);
    color: #fff
}

.status_but .but_locked.open img {
    background-position: -25px -908px;
    height: 33px;
    width: 33px
}

.status_but .but_locked.disable img {
    background-position: -25px -875px;
    height: 33px;
    width: 33px
}

.status_but .but_locked.disable:hover {
    background: none
}

.status_id {
    background-color: #fff;
    padding: 10px 7px;
    border-bottom: 1px solid #eee
}

.status_id p {
    line-height: 15px;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0
}

.status_id p span {
    font-weight: normal
}

.status_id .status-active,
.status_id .status-suspend,
.status_id .status-lock {
    font-size: 12px;
    background-color: transparent;
    border-width: 0;
    padding: 0
}

.form_list dd,
.add_account_box dl dd,
.pt_allowed dl dd {
    line-height: 24px
}

.form_list dd textarea,
.add_account_box dl dd textarea,
.pt_allowed dl dd textarea {
    font-size: 12px;
    width: 160px;
    height: 40px
}

.form_list .radio_enter input,
.add_account_box dl .radio_enter input,
.pt_allowed dl .radio_enter input,
.form_list .radio_enter textarea,
.add_account_box dl .radio_enter textarea,
.pt_allowed dl .radio_enter textarea,
.form_list .radio_enter .member_select a,
.member_select .form_list .radio_enter a,
.add_account_box dl .radio_enter .member_select a,
.member_select .add_account_box dl .radio_enter a,
.pt_allowed dl .radio_enter .member_select a,
.member_select .pt_allowed dl .radio_enter a {
    width: 140px
}

.form_list .radio_enter label,
.add_account_box dl .radio_enter label,
.pt_allowed dl .radio_enter label {
    width: 149px
}

.form_list .radio_enter .radio,
.add_account_box dl .radio_enter .radio,
.pt_allowed dl .radio_enter .radio {
    width: auto;
    margin-right: 3px;
    box-shadow: none
}

.form_list .add_btn,
.add_account_box dl .add_btn,
.pt_allowed dl .add_btn {
    padding-left: 0
}

.form_list .add_btn .btn,
.add_account_box dl .add_btn .btn,
.pt_allowed dl .add_btn .btn,
.form_list .add_btn .btn-send,
.add_account_box dl .add_btn .btn-send,
.pt_allowed dl .add_btn .btn-send,
.form_list .add_btn .pages a,
.pages .form_list .add_btn a,
.add_account_box dl .add_btn .pages a,
.pages .add_account_box dl .add_btn a,
.pt_allowed dl .add_btn .pages a,
.pages .pt_allowed dl .add_btn a,
.form_list .add_btn .calendarTable_inputBox input,
.calendarTable_inputBox .form_list .add_btn input,
.add_account_box dl .add_btn .calendarTable_inputBox input,
.calendarTable_inputBox .add_account_box dl .add_btn input,
.pt_allowed dl .add_btn .calendarTable_inputBox input,
.calendarTable_inputBox .pt_allowed dl .add_btn input,
.form_list .add_btn .calendarTable_inputBox textarea,
.calendarTable_inputBox .form_list .add_btn textarea,
.add_account_box dl .add_btn .calendarTable_inputBox textarea,
.calendarTable_inputBox .add_account_box dl .add_btn textarea,
.pt_allowed dl .add_btn .calendarTable_inputBox textarea,
.calendarTable_inputBox .pt_allowed dl .add_btn textarea,
.form_list .add_btn .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .form_list .add_btn a,
.add_account_box dl .add_btn .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .add_account_box dl .add_btn a,
.pt_allowed dl .add_btn .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .pt_allowed dl .add_btn a,
.form_list .add_btn .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .form_list .add_btn a,
.add_account_box dl .add_btn .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .add_account_box dl .add_btn a,
.pt_allowed dl .add_btn .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .pt_allowed dl .add_btn a,
.form_list .add_btn .total_all .btn_replay,
.total_all .form_list .add_btn .btn_replay,
.add_account_box dl .add_btn .total_all .btn_replay,
.total_all .add_account_box dl .add_btn .btn_replay,
.pt_allowed dl .add_btn .total_all .btn_replay,
.total_all .pt_allowed dl .add_btn .btn_replay,
.form_list .add_btn .btn_expand,
.add_account_box dl .add_btn .btn_expand,
.pt_allowed dl .add_btn .btn_expand,
.form_list .add_btn .add_member,
.add_account_box dl .add_btn .add_member,
.pt_allowed dl .add_btn .add_member {
    width: 50px;
    margin: 0
}

.agent_path {
    margin: 15px 0 10px 0
}

.agent_path ul {
    background: url('../images/agent/ag_path_bg.gif');
    border: 1px solid #7E97A7;
    border-radius: 3px
}

.agent_path ul li {
    position: relative;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    list-style: none
}

.agent_path ul .status {
    font-size: 12px
}

.agent_path a {
    height: 30px
}

.agent_path-L li {
    padding: 0 15px 0 10px;
    background-position: 100% -45px
}

.agent_path-L img {
    margin: 9px 5px 8px 5px;
    background-position: -45px -1501px;
    height: 13px;
    width: 13px
}

.agent_path-L span {
    margin-top: 7px;
    font-weight: lighter
}

.agent_path-L a {
    color: #1E1E1E
}

.agent_path-L .last_li {
    background-image: none;
    padding-right: 5px
}

.currency_tag_all li.currency_tag {
    background-color: #CED5DA;
    background-position: 100% -75px
}

.currency_tag_all li:first-child {
    background-position: 100% -107px
}

.drop_down_li {
    position: relative
}

.drop_down_li a {
    background-position: 100% -653px;
    padding: 0 20px 0 0
}

.drop_down_li .account_pop {
    position: absolute;
    background: #fff;
    border: none;
    border-radius: 0
}

.total_all .agent_path {
    margin: 0
}

.account_pop {
    position: absolute;
    left: 0;
    background-color: #fff;
    z-index: 99;
    overflow-y: auto
}

.account_pop:not(:target) {
    display: none
}

.account_pop:target {
    display: block
}

.account_pop li {
    border-bottom: 1px solid #E0E6E6;
    float: none;
    background: none;
    padding: 0
}

.account_pop a {
    min-width: 150px;
    background-image: none;
    padding: 0 15px;
    font-size: 12px;
    font-weight: normal;
    border-left: none;
    font-size: 12px;
    text-indent: 0
}

.account_pop a:hover {
    background-color: #EFF2F2;
    text-decoration: none
}

.agent_path .account_pop a {
    height: 25px;
    line-height: 25px
}

.agent_path-L .account_pop {
    top: 30px
}

.icon_action .account_pop {
    right: 0;
    left: inherit
}

.icon_action .account_pop li {
    padding: 0
}

.col-left {
    top: 42px
}

.col-left .class {
    color: #fff;
    padding: 0 10px;
    background: #243A48;
    line-height: 25px;
    padding: 0 10px
}

.col-center h2 {
    font-size: 16px;
    line-height: 16px;
    color: #243A48;
    padding-top: 0;
    margin: 15px 0
}

.col-center .acc-info .user {
    background-position: -43px 0
}

.last_border-R {
    border-right: 1px solid #d8d8d8;
    padding-left: 10px
}

.member_select {
    border-bottom: 1px solid #C5D0D7;
    padding-bottom: 5px;
    margin-bottom: 10px
}

.member_select label {
    margin-right: 5px;
    line-height: 23px
}

.member_select a {
    width: 140px;
    padding: 3px 5px;
    line-height: 17px;
    background: #fff url('../images/agent/drop_down-arrow.gif') right center no-repeat
}

.member_select a span {
    font-size: 9px
}

.member_select a:hover {
    text-decoration: none
}

.member_select .no_work {
    color: #b0b0b0;
    background: #ececec url('../images/agent/drop_down-arrow2.gif') right center no-repeat
}

.member_select li {
    position: relative
}

.member_select .account_pop a {
    color: #2789CE;
    background: none
}

.member_select .account_pop a:hover {
    background-color: #EFF2F2;
    text-decoration: none
}

.member_select .account_pop {
    top: 25px
}

.member_select .account_pop a {
    margin: 0;
    box-shadow: none;
    height: 25px;
    line-height: 25px
}

.sports-switch select {
    margin-top: 2px
}

.btn_box {
    margin-top: 20px
}

.btn_box .btn,
.btn_box .btn-send,
.btn_box .pages a,
.pages .btn_box a,
.btn_box .calendarTable_inputBox input,
.calendarTable_inputBox .btn_box input,
.btn_box .calendarTable_inputBox textarea,
.calendarTable_inputBox .btn_box textarea,
.btn_box .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .btn_box a,
.btn_box .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .btn_box a,
.btn_box .total_all .btn_replay,
.total_all .btn_box .btn_replay,
.btn_box .btn_expand,
.btn_box .add_member {
    width: 150px;
    margin: 0 auto
}

.inline-form {
    margin-top: 15px
}

.inline-form .form_list,
.inline-form .add_account_box dl,
.add_account_box .inline-form dl,
.inline-form .pt_allowed dl,
.pt_allowed .inline-form dl {
    padding: 0;
    margin-bottom: 0
}

.inline-form .form_list dt,
.inline-form .add_account_box dl dt,
.add_account_box .inline-form dl dt,
.inline-form .pt_allowed dl dt,
.pt_allowed .inline-form dl dt {
    width: 60px
}

.inline-form .form_list dd,
.inline-form .add_account_box dl dd,
.add_account_box .inline-form dl dd,
.inline-form .pt_allowed dl dd,
.pt_allowed .inline-form dl dd {
    width: 168px;
    padding-left: 60px;
    margin-bottom: 0
}

.inline-form .form_list input,
.inline-form .add_account_box dl input,
.add_account_box .inline-form dl input,
.inline-form .pt_allowed dl input,
.pt_allowed .inline-form dl input,
.inline-form .form_list textarea,
.inline-form .add_account_box dl textarea,
.add_account_box .inline-form dl textarea,
.inline-form .pt_allowed dl textarea,
.pt_allowed .inline-form dl textarea,
.inline-form .form_list .member_select a,
.member_select .inline-form .form_list a,
.inline-form .add_account_box dl .member_select a,
.member_select .inline-form .add_account_box dl a,
.add_account_box .inline-form dl .member_select a,
.member_select .add_account_box .inline-form dl a,
.inline-form .pt_allowed dl .member_select a,
.member_select .inline-form .pt_allowed dl a,
.pt_allowed .inline-form dl .member_select a,
.member_select .pt_allowed .inline-form dl a {
    width: 140px
}

.suspend-wrap {
    margin-bottom: 7px
}

.suspend-wrap .status_id {
    padding: 7px
}

.suspend-wrap .status-suspend,
.suspend-wrap .status-lock {
    float: none
}

.fix-content {
    padding: 7px
}

.fix-content p {
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    color: #1E1E1E;
    margin-bottom: 10px
}

.fix-content .status_but {
    width: auto;
    margin: 0 auto 5px
}

.fix-content .status_but li {
    float: none;
    margin-right: 5px
}

.fix-content .status_but li:last-of-type {
    margin-right: 0
}

.fix-content .status_but a {
    width: 170px;
    height: 35px;
    line-height: 35px;
    padding: 0;
    margin: 0
}

.sys-suspend {
    background-color: #F2E2E6;
    border-bottom-color: #B1767D
}

.sys-lock {
    background-color: #E3E8EB;
    border-bottom-color: #7D93A2
}

.suspend-wrap .sys-lock {
    margin-bottom: 0
}

.account-wrap li span {
    color: #fff;
    height: 15px;
    line-height: 15px;

    padding: 0 5px;
    border-radius: 4px;
    background-color: #000;
    margin-right: 3px
}

.account-wrap .loading-bar span {
    padding: 0
}

.profile-wrap .dl_list {
    position: relative;
    height: inherit;
    padding: 0;
    border-top: 1px solid #7E97A7;
    border-bottom: 1px solid #7E97A7;
    background-color: #E2E8ED
}

.profile-wrap .dl_list .expand-arrow {
    left: 50px;
    margin-top: -2px;
    z-index: 20
}

.profile-wrap .dl_list dl {
    background-color: #f2f4f7;
    margin-left: 50px
}

.profile-wrap .dl_list dl dt {
    -webkit-box-flex: 0;
    flex: 0 0 28%
}

.profile-wrap .dl_list dl dd {
    -webkit-box-flex: 0;
    flex: 0 0 14%;
    border-left: 1px solid #7E97A7
}

.tab-transfer {
    margin-bottom: 0
}

.tab-transfer th {
    padding: 4px 10px;
    line-height: 15px
}

.tab-transfer td {
    padding: 4px 10px;
    line-height: 25px
}

.tab-transfer input,
.tab-transfer textarea,
.tab-transfer .member_select a,
.member_select .tab-transfer a {
    border: 1px solid #aaa;
    padding: 4px 5px;
    box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.1);
    margin: 0;
    height: 30px
}

.tab-transfer input:focus,
.tab-transfer textarea:focus,
.tab-transfer .member_select a:focus,
.member_select .tab-transfer a:focus {
    line-height: 15px
}

.tab-transfer input.type-mark,
.tab-transfer textarea.type-mark,
.tab-transfer .member_select a.type-mark,
.member_select .tab-transfer a.type-mark {
    border-color: #CB8009;
    background-color: #FEFCF9;
    box-shadow: inset 0px 2px 0px rgba(81, 49, 26, 0.2)
}

.tab-transfer .btn,
.tab-transfer .btn-send,
.tab-transfer .pages a,
.pages .tab-transfer a,
.tab-transfer .calendarTable_inputBox input,
.calendarTable_inputBox .tab-transfer input,
.tab-transfer .calendarTable_inputBox textarea,
.calendarTable_inputBox .tab-transfer textarea,
.tab-transfer .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .tab-transfer a,
.tab-transfer .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .tab-transfer a,
.tab-transfer .total_all .btn_replay,
.total_all .tab-transfer .btn_replay,
.tab-transfer .btn_expand,
.tab-transfer .add_member {
    width: 45px;
    height: 28px;
    line-height: 28px
}

th+.full-amount {
    line-height: 30px
}

.full-amount .btn,
.full-amount .btn-send,
.full-amount .pages a,
.pages .full-amount a,
.full-amount .calendarTable_inputBox input,
.calendarTable_inputBox .full-amount input,
.full-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .full-amount textarea,
.full-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .full-amount a,
.full-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .full-amount a,
.full-amount .total_all .btn_replay,
.total_all .full-amount .btn_replay,
.full-amount .btn_expand,
.full-amount .add_member {
    width: 58px
}

.full-amount input,
.full-amount textarea,
.full-amount .member_select a,
.member_select .full-amount a {
    width: 163px
}

.tab-transfer .full-amount {
    border-left: 1px solid #7E97A7
}

.remark input,
.remark textarea,
.remark .member_select a,
.member_select .remark a {
    width: 100%
}

.tab-transfer .remark {
    border-left: 1px solid #7E97A7
}

.submit-wrap {
    border-top: 1px solid #D4D4D4;
    padding: 15px 0
}

.submit-wrap li {
    vertical-align: middle
}

.submit-wrap .btn,
.submit-wrap .btn-send,
.submit-wrap .pages a,
.pages .submit-wrap a,
.submit-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .submit-wrap input,
.submit-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .submit-wrap textarea,
.submit-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .submit-wrap a,
.submit-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .submit-wrap a,
.submit-wrap .total_all .btn_replay,
.total_all .submit-wrap .btn_replay,
.submit-wrap .btn_expand,
.submit-wrap .add_member {
    width: 130px;
    line-height: 33px;
    font-size: 14px;
    margin: 0
}

.submit-wrap .submit-payment {
    background-color: #C5D0D7;
    border-radius: 6px;
    padding: 5px;
    margin-left: 10px
}

.submit-wrap input,
.submit-wrap textarea,
.submit-wrap .member_select a,
.member_select .submit-wrap a {
    width: 180px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    padding: 0;
    margin: 0
}

.submit-wrap input:focus,
.submit-wrap textarea:focus,
.submit-wrap .member_select a:focus,
.member_select .submit-wrap a:focus {
    line-height: 33px
}

.submit-wrap .btn-send {
    width: 228px;
    margin-left: 5px
}

.submit-wrap .btn-send span {
    width: 24px;
    height: 24px;
    background-color: #CB8009;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    border-radius: 50%;
    vertical-align: top;
    margin-top: 4px
}

.odd-add {
    position: absolute;
    top: 5px;
    right: 26px;
    height: 19px;
    z-index: 2
}

.odd-add li {
    list-style: none;
    float: none
}

.odd-add .up {
    line-height: 10px;
    background-position: -68px -464px;
    height: 10px;
    width: 12px
}

.odd-add .up:hover,
.odd-add .up.num_up-hover {
    background-position: -68px -484px
}

.odd-add .down {
    line-height: 9px;
    background-position: -68px -437px;
    height: 9px;
    width: 12px
}

.odd-add .down:hover,
.odd-add .down.num_down-hover {
    background-position: -68px -455px
}

.note {
    color: #CB8009;
    margin-top: 3px
}

.tips-popup {
    position: absolute;
    left: 135px;
    width: auto;
    background-color: #F8E71C;
    line-height: 13px;
    color: #1E1E1E;
    border-radius: 4px;
    padding: 3px 5px;
    z-index: 50
}

.play_race-wrap {
    margin-bottom: 20px
}

.top_player-wrap {
    width: calc(100% - 467px - 15px);
    margin-right: 15px
}

.top_player-wrap h3 {
    position: relative;
    height: 25px
}

.tab-topplay {
    position: absolute;
    top: -3px;
    left: 12px
}

.tab-topplay li {
    position: relative;
    height: 28px;
    line-height: 28px;
    font-weight: bold;
    padding: 0 19px 0 5px;
    margin-left: -12px
}

.tab-topplay li:before {
    position: absolute;
    left: 0;
    content: '';
    background-position: -53px -364px;
    height: 28px;
    width: 5px
}

.tab-topplay li:after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    background-position: -39px -422px;
    height: 28px;
    width: 19px
}

.tab-topplay a {
    height: 100%;
    background-repeat: repeat-x;
    padding-left: 10px;
    background-position: 0 -10751px
}

.tab-topplay .select {
    height: 30px;
    z-index: 2
}

.tab-topplay .select:before {
    background-position: -53px -334px;
    height: 30px;
    width: 5px
}

.tab-topplay .select:after {
    background-position: -39px -392px;
    height: 30px;
    width: 19px
}

.tab-topplay .select a {
    color: #254D6A;
    background-repeat: repeat-x;
    background-position: 0 -10721px
}

.racing-wrap {
    width: 467px
}

.col-2 {
    width: 50%
}

.slip-head {
    background-color: #CED5DA;
    padding: 0 10px
}

.slip-head li {
    padding: 5px 0
}

.slip-head .col-playID {
    width: calc(100% - 100px - 130px)
}

.slip-head .col-exp {
    width: 100px
}

.slip-head .col-amount {
    width: 111px
}

.slip-head .col-type {
    width: 65px
}

.slip-head .col-country {
    width: 50px
}

.slip-head .col-local {
    width: calc(100% - 10px - 65px - 50px - 111px);
    padding-left: 10px
}

.top_player-wrap .slip-head .col-amount {
    width: 130px
}

.sort-this:after {
    content: '';
    background-position: -47px -163px;
    height: 7px;
    width: 11px
}

.top_player-wrap .table01,
.top_player-wrap .table-s,
.top_player-wrap .table_one,
.racing-wrap .table01,
.racing-wrap .table-s,
.racing-wrap .table_one {
    margin-bottom: 0;
    border-right: 1px solid #eee;
    border-bottom-width: 0
}

.table01 .td-type,
.table-s .td-type,
.table_one .td-type {
    padding: 8px 5px 8px 10px
}

.table01 .order,
.table-s .order,
.table_one .order {
    width: 22px;
    margin-right: 7px;
    color: #999;
    /* font-weight:bold */
}

.table01 .spread-line td,
.table-s .spread-line td,
.table_one .spread-line td {
    border-top-color: #7E97A7
}

.table01 .expand-close,
.table-s .expand-close,
.table_one .expand-close,
.table01 .expand-open,
.table-s .expand-open,
.table_one .expand-open,
.table01 .expand-open,
.table-s .expand-open,
.table_one .expand-open {
    float: left;
    width: 13px;
    height: 15px;
    padding-right: 0
}

.table01 span,
.table-s span,
.table_one span {
    vertical-align: middle
}

.table01 .btn-recall,
.table-s .btn-recall,
.table_one .btn-recall {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 60px;
    font-size: 11px;
    line-height: 2;
    text-decoration: none;
    padding: 0
}

.expand-balance th {
    padding: 8px 10px
}

.expand-balance>td {
    padding: 0
}

.tab-banking .expand-balance table {
    width: 100%;
    margin-left: 90px !important
}

.tab-banking .expand-balance .expand-arrow {
    left: 90px !important
}

.match-wrap {
    background: #DDDCD7;
    box-shadow: 0 2px 0 0 #fff, inset 0 2px 0 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 7px 10px 5px;
    margin-bottom: 25px
}

.match-wrap h3 {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px
}

.match-wrap .table01,
.match-wrap .table-s,
.match-wrap .table_one {
    margin-bottom: 10px
}

.match-wrap td {
    padding: 4px 10px;
    line-height: 25px;
    border: 1px solid #7E97A7
}

.match-wrap .border-t td {
    box-shadow: none
}

.match-wrap .total_all {
    margin: 0 0 5px
}

.risk_matchodd .btn,
.risk_matchodd .btn-send,
.risk_matchodd .pages a,
.pages .risk_matchodd a,
.risk_matchodd .calendarTable_inputBox input,
.calendarTable_inputBox .risk_matchodd input,
.risk_matchodd .calendarTable_inputBox textarea,
.calendarTable_inputBox .risk_matchodd textarea,
.risk_matchodd .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .risk_matchodd a,
.risk_matchodd .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .risk_matchodd a,
.risk_matchodd .total_all .btn_replay,
.total_all .risk_matchodd .btn_replay,
.risk_matchodd .btn_expand,
.risk_matchodd .add_member {
    font-weight: normal
}

.risk_matchodd .expand>td {
    padding: 0
}

.risk_matchodd .expand .expand-arrow {
    left: 44px
}

.risk_matchodd .expand iframe {
    width: calc(100% - 45px - 1px);
    border-left: 1px solid #7E97A7;
    margin-left: 45px;
    overflow: hidden
}

.risk-message {
    background-color: rgba(255, 255, 255, 0.5);
    width: calc(100% - 45px - 30px - 1px);
    border-left: 1px solid #7E97A7;
    margin-left: 45px;
    padding: 25px 15px;
    color: #3B5160;
    font-size: 14px
}

.open-odds,
.close-odds {
    position: relative;
    width: 25px;
    margin-right: 10px
}

.open-odds:before {
    background-image: url('../images/right-arrow.png');
}

.open-odds:before,
.close-odds:before {
    position: absolute;
    content: '';
    background-position: -33px -1219px;
    height: 23px;
    width: 25px
}

.table01 .open-odds,
.table-s .open-odds,
.table_one .open-odds,
.table01 .close-odds,
.table-s .close-odds,
.table_one .close-odds {
    margin-right: 10px
}

.close-odds:before {
    background-position: -33px -1105px;
    height: 23px;
    width: 25px
}

.pop-content .agent_path {
    margin-top: 0
}

.pop-title {
    margin-bottom: 20px
}

.pop-title h3 {
    font-weight: bold
}

.pop-title h3 span {
    color: #C6CCD1
}

.pop-title .table01,
.pop-title .table-s,
.pop-title .table_one {
    margin-bottom: 0;
    border-bottom-width: 0
}

.pop-title .table01 .border-t td,
.pop-title .table-s .border-t td,
.pop-title .table_one .border-t td {
    box-shadow: none
}

.pop-title .table01 .horse-type th,
.pop-title .table-s .horse-type th,
.pop-title .table_one .horse-type th {
    background-color: #fff
}

.pop-title .table01 .font-s th,
.pop-title .table-s .font-s th,
.pop-title .table_one .font-s th {
    padding: 5px
}

.icon-date {
    background-position: -46px -1128px;
    height: 13px;
    width: 12px
}

.pop-title h3 .icon-date {
    margin: 7px 5px 0 0
}

.font-s {
    font-size: 10px
}

.bg-green {
    background-color: #C7E4AD !important
}

.bg-yellow {
    background-color: #F3DFB0 !important
}

.agent-path {
    position: absolute;
    width: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    white-space: normal;
    z-index: 10
}

.agent-path li {
    background-color: #fff;
    padding: 4px 8px 4px 4px;
    color: #1E1E1E;
    font-weight: bold;
    background-position: 100% -139px
}

.agent-path .last-lv {
    background-image: none
}

.agent-path .point-agent-path {
    position: absolute;
    padding: 0;
    background-color: transparent;
    margin-top: 23px;
    margin-left: -4px;
    background-position: -47px -1527px;
    height: 13px;
    width: 11px
}

.banking-head {
    margin-bottom: 10px
}

.banking-head dt,
.banking-head dd {
    line-height: 30px;
    vertical-align: top
}

.banking-head dt {
    font-size: 13px;
    font-weight: bold;
    color: #3B5160;
    padding-right: 10px;
    margin-right: -5px;
    border-right: 1px solid #7E97A7
}

.banking-head dd {
    height: 30px;
    font-size: 23px;
    font-weight: bold;
    padding-left: 10px
}

.banking-head dd span {
    font-size: 13px;
    line-height: 15px;
    font-weight: normal;
    color: #3B5160;
    margin: 8px 5px 0 0
}

.tab-banking input,
.tab-banking textarea,
.tab-banking .member_select a,
.member_select .tab-banking a {
    width: 100%
}

.tab-banking .btn,
.tab-banking .btn-send,
.tab-banking .pages a,
.pages .tab-banking a,
.tab-banking .calendarTable_inputBox input,
.calendarTable_inputBox .tab-banking input,
.tab-banking .calendarTable_inputBox textarea,
.calendarTable_inputBox .tab-banking textarea,
.tab-banking .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .tab-banking a,
.tab-banking .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .tab-banking a,
.tab-banking .total_all .btn_replay,
.total_all .tab-banking .btn_replay,
.tab-banking .btn_expand,
.tab-banking .add_member {
    width: 45px
}

.DW-amount {
    border-left: 1px solid #7E97A7
}

.DW-amount input,
.DW-amount textarea,
.DW-amount .member_select a,
.member_select .DW-amount a {
    width: calc(100% - 63px - 47px - 14px);
    font-size: 13px;
    font-weight: bold;
    margin: 0 7px
}

.DW-amount input.type-w,
.DW-amount textarea.type-w,
.DW-amount .member_select a.type-w,
.member_select .DW-amount a.type-w {
    position: relative;
    color: #024168
}

.btn_list-DW {
    position: relative
}

.btn_list-DW li:first-of-type a {
    border-radius: 4px 0 0 4px;
    border-right-width: 0
}

.btn_list-DW li:last-of-type a {
    border-radius: 0 4px 4px 0
}

.btn_list-DW .btn,
.btn_list-DW .btn-send,
.btn_list-DW .pages a,
.pages .btn_list-DW a,
.btn_list-DW .calendarTable_inputBox input,
.calendarTable_inputBox .btn_list-DW input,
.btn_list-DW .calendarTable_inputBox textarea,
.calendarTable_inputBox .btn_list-DW textarea,
.btn_list-DW .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .btn_list-DW a,
.btn_list-DW .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .btn_list-DW a,
.btn_list-DW .total_all .btn_replay,
.total_all .btn_list-DW .btn_replay,
.btn_list-DW .btn_expand,
.btn_list-DW .add_member {
    width: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #3B5160
}

.btn_list-DW .btn.disable,
.btn_list-DW .disable.btn-send,
.btn_list-DW .pages a.disable,
.pages .btn_list-DW a.disable,
.btn_list-DW .calendarTable_inputBox input.disable,
.calendarTable_inputBox .btn_list-DW input.disable,
.btn_list-DW .calendarTable_inputBox textarea.disable,
.calendarTable_inputBox .btn_list-DW textarea.disable,
.btn_list-DW .calendarTable_inputBox .member_select a.disable,
.calendarTable_inputBox .member_select .btn_list-DW a.disable,
.btn_list-DW .member_select .calendarTable_inputBox a.disable,
.member_select .calendarTable_inputBox .btn_list-DW a.disable,
.btn_list-DW .total_all .disable.btn_replay,
.total_all .btn_list-DW .disable.btn_replay,
.btn_list-DW .disable.btn_expand,
.btn_list-DW .disable.add_member {
    color: #999
}

.btn_list-DW.select-D:after {
    content: '\002b';
    color: #508D0E
}

.btn_list-DW.select-D li:first-of-type a {
    border-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    background: #5BAB03;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)
}

.btn_list-DW.select-W:after {
    content: '\002d';
    font-size: 20px;
    color: #024168
}

.btn_list-DW.select-W li:last-of-type a {
    border-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    background: #024168;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)
}

.credit-amount {
    border-left: 1px solid #7E97A7
}

.credit-amount input,
.credit-amount textarea,
.credit-amount .member_select a,
.member_select .credit-amount a {
    width: calc(100% - 50px - 7px);
    font-size: 13px;
    font-weight: bold
}

.credit-amount .btn,
.credit-amount .btn-send,
.credit-amount .pages a,
.pages .credit-amount a,
.credit-amount .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount input,
.credit-amount .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount textarea,
.credit-amount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount a,
.credit-amount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount a,
.credit-amount .total_all .btn_replay,
.total_all .credit-amount .btn_replay,
.credit-amount .btn_expand,
.credit-amount .add_member {
    width: 48px;
    margin-left: 7px
}

.credit-amount-member {
    line-height: 26px
}

.credit-amount-member .btn,
.credit-amount-member .btn-send,
.credit-amount-member .pages a,
.pages .credit-amount-member a,
.credit-amount-member .calendarTable_inputBox input,
.calendarTable_inputBox .credit-amount-member input,
.credit-amount-member .calendarTable_inputBox textarea,
.calendarTable_inputBox .credit-amount-member textarea,
.credit-amount-member .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .credit-amount-member a,
.credit-amount-member .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .credit-amount-member a,
.credit-amount-member .total_all .btn_replay,
.total_all .credit-amount-member .btn_replay,
.credit-amount-member .btn_expand,
.credit-amount-member .add_member {
    width: 26px;
    height: 26px;
    border-width: 0;
    margin-left: 5px;
    background-position: -32px -485px
}

.credit-amount-member .btn:hover,
.credit-amount-member .btn-send:hover,
.credit-amount-member .pages a:hover,
.pages .credit-amount-member a:hover,
.credit-amount-member .calendarTable_inputBox input:hover,
.calendarTable_inputBox .credit-amount-member input:hover,
.credit-amount-member .calendarTable_inputBox textarea:hover,
.calendarTable_inputBox .credit-amount-member textarea:hover,
.credit-amount-member .calendarTable_inputBox .member_select a:hover,
.calendarTable_inputBox .member_select .credit-amount-member a:hover,
.credit-amount-member .member_select .calendarTable_inputBox a:hover,
.member_select .calendarTable_inputBox .credit-amount-member a:hover,
.credit-amount-member .total_all .btn_replay:hover,
.total_all .credit-amount-member .btn_replay:hover,
.credit-amount-member .btn_expand:hover,
.credit-amount-member .add_member:hover,
.credit-amount-member .btn.btn-edit-hover,
.credit-amount-member .btn-edit-hover.btn-send,
.credit-amount-member .pages a.btn-edit-hover,
.pages .credit-amount-member a.btn-edit-hover,
.credit-amount-member .calendarTable_inputBox input.btn-edit-hover,
.calendarTable_inputBox .credit-amount-member input.btn-edit-hover,
.credit-amount-member .calendarTable_inputBox textarea.btn-edit-hover,
.calendarTable_inputBox .credit-amount-member textarea.btn-edit-hover,
.credit-amount-member .calendarTable_inputBox .member_select a.btn-edit-hover,
.calendarTable_inputBox .member_select .credit-amount-member a.btn-edit-hover,
.credit-amount-member .member_select .calendarTable_inputBox a.btn-edit-hover,
.member_select .calendarTable_inputBox .credit-amount-member a.btn-edit-hover,
.credit-amount-member .total_all .btn-edit-hover.btn_replay,
.total_all .credit-amount-member .btn-edit-hover.btn_replay,
.credit-amount-member .btn-edit-hover.btn_expand,
.credit-amount-member .btn-edit-hover.add_member {
    background-position: -32px -459px
}

.credit-amount-member .btn:hover,
.credit-amount-member .btn-send:hover,
.credit-amount-member .pages a:hover,
.pages .credit-amount-member a:hover,
.credit-amount-member .calendarTable_inputBox input:hover,
.calendarTable_inputBox .credit-amount-member input:hover,
.credit-amount-member .calendarTable_inputBox textarea:hover,
.calendarTable_inputBox .credit-amount-member textarea:hover,
.credit-amount-member .calendarTable_inputBox .member_select a:hover,
.calendarTable_inputBox .member_select .credit-amount-member a:hover,
.credit-amount-member .member_select .calendarTable_inputBox a:hover,
.member_select .calendarTable_inputBox .credit-amount-member a:hover,
.credit-amount-member .total_all .btn_replay:hover,
.total_all .credit-amount-member .btn_replay:hover,
.credit-amount-member .btn_expand:hover,
.credit-amount-member .add_member:hover {
    background-position: -32px -459px
}

.pop_refer {
    width: 380px;
    padding: 0
}

.pop_refer h3 {
    padding: 15px;
    margin-bottom: 0
}

.pop_refer .close_pop {
    top: 15px
}

.pop_refer .btn-wrap {
    padding: 15px
}

.pop_refer .btn-wrap li {
    list-style: none
}

.pop_refer .btn-wrap .btn,
.pop_refer .btn-wrap .btn-send,
.pop_refer .btn-wrap .pages a,
.pages .pop_refer .btn-wrap a,
.pop_refer .btn-wrap .calendarTable_inputBox input,
.calendarTable_inputBox .pop_refer .btn-wrap input,
.pop_refer .btn-wrap .calendarTable_inputBox textarea,
.calendarTable_inputBox .pop_refer .btn-wrap textarea,
.pop_refer .btn-wrap .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .pop_refer .btn-wrap a,
.pop_refer .btn-wrap .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .pop_refer .btn-wrap a,
.pop_refer .btn-wrap .total_all .btn_replay,
.total_all .pop_refer .btn-wrap .btn_replay,
.pop_refer .btn-wrap .btn_expand,
.pop_refer .btn-wrap .add_member {
    width: 140px;
    margin: 0 auto
}

.wrap-refer_edit {
    border: 1px solid #ccc;
    border-width: 1px 0 1px 0;
    padding: 15px 40px 0
}

.wrap-refer_edit dl {
    margin-bottom: 10px;
    line-height: 28px
}

.wrap-refer_edit dd {
    padding-left: 60px
}

.wrap-refer_edit dd .btn,
.wrap-refer_edit dd .btn-send,
.wrap-refer_edit dd .pages a,
.pages .wrap-refer_edit dd a,
.wrap-refer_edit dd .calendarTable_inputBox input,
.calendarTable_inputBox .wrap-refer_edit dd input,
.wrap-refer_edit dd .calendarTable_inputBox textarea,
.calendarTable_inputBox .wrap-refer_edit dd textarea,
.wrap-refer_edit dd .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .wrap-refer_edit dd a,
.wrap-refer_edit dd .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .wrap-refer_edit dd a,
.wrap-refer_edit dd .total_all .btn_replay,
.total_all .wrap-refer_edit dd .btn_replay,
.wrap-refer_edit dd .btn_expand,
.wrap-refer_edit dd .add_member {
    width: 58px;
    height: 26px;
    line-height: 26px;
    margin: 0
}

.wrap-refer_edit strong {
    font-size: 16px
}

.wrap-refer_edit input,
.wrap-refer_edit textarea,
.wrap-refer_edit .member_select a,
.member_select .wrap-refer_edit a {
    width: 100%
}

body.matador {
    background-color: #EEE !important
}

.form-wrap {
    position: relative
}

.form-wrap h1 {
    padding: 32px 0 16px;
    color: #243A48;
    font-size: 18px;
    line-height: 1.5
}

.form-edit {
    width: 442px;
    margin: 0 auto;
    background: #ffffff;
    border-bottom: 1px solid #7E97A7
}

.form-edit label {
    width: 135px;
    margin-right: 10px
}

.form-edit input,
.form-edit textarea,
.form-edit .member_select a,
.member_select .form-edit a {
    width: 240px;
    height: 32px;
    padding: 4px;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: none
}

.form-edit input:disabled,
.form-edit textarea:disabled,
.form-edit .member_select a:disabled,
.member_select .form-edit a:disabled {
    background: #DEDEDE;
    cursor: not-allowed
}

.form-edit .btn,
.form-edit .btn-send,
.form-edit .pages a,
.pages .form-edit a,
.form-edit .calendarTable_inputBox input,
.calendarTable_inputBox .form-edit input,
.form-edit .calendarTable_inputBox textarea,
.calendarTable_inputBox .form-edit textarea,
.form-edit .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .form-edit a,
.form-edit .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .form-edit a,
.form-edit .total_all .btn_replay,
.total_all .form-edit .btn_replay,
.form-edit .btn_expand,
.form-edit .add_member {
    width: 128px;
    height: 32px;
    margin: 0 8px;
    line-height: 32px
}

.form-status {
    padding: 20px;
    background: #EEEEEE
}

.form-status .status_but {
    width: 100%;
    margin: 0 auto
}

.form-status .status_but li {
    display: inline-block;
    float: none
}

.form-status .white-wrap,
.form-status .total_box,
.form-status .fix-content {
    margin-bottom: 20px;
    padding: 12px 0
}

.form-status .suspend-wrap {
    margin-bottom: 0
}

.form-status .inline-form {
    width: 100%;
    margin: 0;
    padding: 0
}

.form-status .inline-form dt {
    line-height: 32px
}

.form-status .inline-form dd {
    width: 208px
}

.form-status .inline-form input,
.form-status .inline-form textarea,
.form-status .inline-form .member_select a,
.member_select .form-status .inline-form a {
    width: 180px
}

.form-status .inline-form .form_list,
.form-status .inline-form .add_account_box dl,
.add_account_box .form-status .inline-form dl,
.form-status .inline-form .pt_allowed dl,
.pt_allowed .form-status .inline-form dl,
.form-status .inline-form .btn-send {
    display: inline-block;
    float: none
}

.form-group {
    position: relative;
    width: 100%;
    margin-bottom: 16px
}

.form-group:first-of-type {
    padding-top: 20px
}

.btn-group {
    padding: 16px 0
}

.validation-img {
    position: absolute;
    top: 6px;
    right: 64px;
    width: auto;
    height: 20px
}

.pop_bg .form-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 5px
}

.pop_bg .form-wrap h1 {
    padding: 12px 0 10px 20px;
    border-bottom: 1px solid #CCCCCC;
    color: #243A48;
    font-size: 16px
}

.pop_bg .form-edit {
    width: 460px;
    margin: 0;
    border: 0;
    border-radius: 5px
}

.pop_bg .close_pop {
    top: 9px;
    right: 10px
}

.pop_bg .btn-group {
    padding: 12px 0;
    border-top: 1px solid #CCCCCC
}

.select-group {
    padding: 20px;
    background: #EEEEEE;
    border-top: 1px solid #CCCCCC
}

.access-selection {
    box-sizing: border-box;
    width: 200px;
    padding: 14px 20px;
    margin-bottom: 14px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    list-style: none
}

.access-selection:nth-child(odd) {
    margin-right: 16px
}

.access-selection:last-of-type {
    margin-bottom: 0
}

.access-selection li {
    margin: 0 0 10px 14px
}

.access-selection li:first-of-type {
    margin-left: 0;
    font-size: 14px;
    font-weight: bold
}

.access-selection li:last-of-type {
    margin-bottom: 0
}

.access-selection input[type='checkbox'],
.access-selection textarea[type='checkbox'],
.access-selection .member_select a[type='checkbox'],
.member_select .access-selection a[type='checkbox'] {
    margin-right: 4px
}

.btn-add {
    position: relative;
    height: 30px;
    padding: 0 8px 0 20px;
    margin: 0;
    line-height: 30px
}

.btn-add:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 7px;
    background-position: -48px -1095px;
    height: 10px;
    width: 10px
}

.table-subaccount:last-of-type {
    margin-bottom: 36px
}

.table-subaccount .btn,
.table-subaccount .btn-send,
.table-subaccount .pages a,
.pages .table-subaccount a,
.table-subaccount .calendarTable_inputBox input,
.calendarTable_inputBox .table-subaccount input,
.table-subaccount .calendarTable_inputBox textarea,
.calendarTable_inputBox .table-subaccount textarea,
.table-subaccount .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .table-subaccount a,
.table-subaccount .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .table-subaccount a,
.table-subaccount .total_all .btn_replay,
.total_all .table-subaccount .btn_replay,
.table-subaccount .btn_expand,
.table-subaccount .add_member {
    display: inline-block;
    width: 60px;
    margin: 0 auto
}

.table-subaccount .status-popup,
.table-subaccount .access-popup {
    top: 10px;
    left: 102px;
    right: 0;
    width: 104px
}

.table-subaccount .access-popup {
    left: 84px;
    width: 138px
}

.access-popup li {
    padding: 6px 10px 6px 18px
}

.access-popup .unselect {
    opacity: .5
}

.icon-select_all {
    background-position: -38px -1267px;
    height: 18px;
    width: 20px
}

.icon-select_partial {
    background-position: -38px -1285px;
    height: 18px;
    width: 20px
}

.footer-content {
    box-sizing: border-box;
    height: 36px;
    padding: 8px 0
}

.footer-content dt {
    vertical-align: text-bottom
}

.footer-content dd {
    margin-right: 24px
}

.casinopt-list-wrap {
    position: relative
}

.casinopt-list-wrap .casino-edit {
    position: absolute;
    top: 0;
    right: 0
}

.casinopt-list,
.profile-wrap .dl_list .casinopt-list,
.sports_box .casinopt-list,
.casinopt-list-head,
.profile-wrap .dl_list .casinopt-list-head,
.sports_box .casinopt-list-head {
    box-sizing: border-box
}

.casinopt-list dt,
.profile-wrap .dl_list .casinopt-list dt,
.sports_box .casinopt-list dt,
.casinopt-list-head dt,
.profile-wrap .dl_list .casinopt-list-head dt,
.sports_box .casinopt-list-head dt {
    -webkit-box-flex: 0;
    flex: 0 0 28%;
    border-bottom: 1px solid #E0E6E6;
    border-left: 1px solid #7E97A7;
    box-sizing: border-box;
    color: #1E1E1E
}

.casinopt-list dd,
.profile-wrap .dl_list .casinopt-list dd,
.sports_box .casinopt-list dd,
.casinopt-list-head dd,
.profile-wrap .dl_list .casinopt-list-head dd,
.sports_box .casinopt-list-head dd {
    -webkit-box-flex: 1;
    flex: 1;
    position: relative;
    padding: 5px 10px;
    border-bottom: 1px solid #E0E6E6;
    border-left: 0;
    color: #1E1E1E;
    box-sizing: border-box
}

.casinopt-list input[type="text"]:disabled,
.casinopt-list-head input[type="text"]:disabled,
.casinopt-list textarea[type="text"]:disabled,
.casinopt-list-head textarea[type="text"]:disabled,
.casinopt-list .member_select a[type="text"]:disabled,
.member_select .casinopt-list a[type="text"]:disabled,
.casinopt-list-head .member_select a[type="text"]:disabled,
.member_select .casinopt-list-head a[type="text"]:disabled {
    background-color: #DDDDDD;
    color: #888888
}

.sports_box .casinopt-list:nth-of-type(odd),
.sports_box .casinopt-list-head:nth-of-type(odd) {
    background-color: #F2F4F7
}

.sports_box .casinopt-list:nth-of-type(even),
.sports_box .casinopt-list-head:nth-of-type(even) {
    background-color: #E2E8ED
}

.sports_box .profile-wrap .dl_list .casinopt-list dt,
.profile-wrap .dl_list .sports_box .casinopt-list dt,
.sports_box .casinopt-list dt,
.sports_box .profile-wrap .dl_list .casinopt-list-head dt,
.profile-wrap .dl_list .sports_box .casinopt-list-head dt,
.sports_box .casinopt-list-head dt {
    -webkit-box-flex: 1px;
    flex: 1px;
    border-bottom: 0;
    border-left: 0
}

.sports_box .profile-wrap .dl_list .casinopt-list dd,
.profile-wrap .dl_list .sports_box .casinopt-list dd,
.sports_box .casinopt-list dd,
.sports_box .profile-wrap .dl_list .casinopt-list-head dd,
.profile-wrap .dl_list .sports_box .casinopt-list-head dd,
.sports_box .casinopt-list-head dd {
    padding: 0;
    border-bottom: 0
}

.casinopt-list-head,
.profile-wrap .dl_list .casinopt-list-head,
.sports_box .casinopt-list-head {
    background-color: #E0E6E6;
    border-bottom: 1px solid #7E97A7
}

.sports_box .profile-wrap .dl_list .casinopt-list-head,
.profile-wrap .dl_list .sports_box .casinopt-list-head,
.sports_box .casinopt-list-head {
    background-color: #E0E6E6
}

.sports_box .profile-wrap .dl_list .casinopt-list-head dd,
.profile-wrap .dl_list .sports_box .casinopt-list-head dd,
.sports_box .casinopt-list-head dd {
    color: #3B5160
}

.sports_box .casino-setting-wrap {
    margin-bottom: 8px;
    border-bottom: 1px solid #7E97A7;
    list-style-type: none
}

.sports_box .casino-setting-head {
    position: relative;
    -webkit-box-pack: end;
    justify-content: flex-end;
    background-color: #3B5160;
    color: #FFFFFF
}

.sports_box .casino-setting-head dt {
    position: absolute;
    left: 5px;
    padding: 0 5px;
    font-weight: bold
}

.sports_box .casino-setting-head dd {
    color: #FFFFFF;
    padding: 0 5px
}

.sports_box .casino-setting-head .toggle_on,
.sports_box .casino-setting-head .toggle_off {
    text-decoration: none
}

.marquee-box {
    border-radius: 4px
}

.table-pnl .expand-open,
.table-pnl .expand-close,
.table-pnl .expand-open {
    display: block;
    width: inherit;
    padding-right: 20px;
    float: none
}

body.o_12bet {
    background-color: #E6E7E8
}

.o_12bet a {
    color: #444
}

.o_12bet a:hover {
    text-decoration: none
}

.o_12bet .table01 caption,
.o_12bet .table-s caption,
.o_12bet .table_one caption {
    background-color: #444
}

/* .o_12bet .btn.select,.o_12bet .select.btn-send,.o_12bet .pages a.select,.pages .o_12bet a.select,.o_12bet .calendarTable_inputBox input.select,.calendarTable_inputBox .o_12bet input.select,.o_12bet .calendarTable_inputBox textarea.select,.calendarTable_inputBox .o_12bet textarea.select,.o_12bet .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .o_12bet a.select,.o_12bet .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .o_12bet a.select,.o_12bet .total_all .select.btn_replay,.total_all .o_12bet .select.btn_replay,.o_12bet .select.btn_expand,.o_12bet .select.add_member{
    color:#fff;
    background:#DF8284 url(../images/12bet/btn-select-12bet.jpg) repeat-x;
    border-color:#B73B40
}
.o_12bet .btn-send{
    background:#C4494E url(../images/12bet/bg-send-12bet.png) repeat-x;
    color:#fff;
    border-color:#912F33
}
.o_12bet .btn-send:hover{
    background:#D76E71 url(../images/12bet/bg-send_hover-12bet.png) repeat-x
} */
/* .o_12bet .btn-send.disable{
    background:#CDCDCD url(../images/12bet/bg-send-disable-12bet.png) repeat-x;
    border-color:#aaa;
    color:#A3A3A3
}
.o_12bet .login-panel{
    color:#000
}
.o_12bet .top{
    background:#1E1E1E url(../images/12bet/bg-top-12bet.jpg) repeat-x
} */
.o_12bet .header {
    color: #fff
}

.o_12bet .header h1 {
    margin-top: -6px
}

.o_12bet .login-wrap .error {
    background-color: transparent;
    color: #FEEC6E
}

.o_12bet .login-wrap .btn-login {
    background-color: #E83523;
    background-image: none
}

.o_12bet .login-wrap li.user {
    background-position: -96px -4867px
}

.o_12bet .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.o_12bet .account-wrap .main-wallet.no-multi .red {
    color: #FFE000 !important
}

.o_12bet .account-wrap a {
    color: #fff
}

.o_12bet .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
    background-color: #bd0b0b
}

.o_12bet .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.o_12bet .account-wrap .loading-bar span {
    background: #fff
}

.o_12bet .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.o_12bet .account-wrap .loading-bar,
.o_12bet .account-wrap .a-refresh {
    background-color: #bd0b0b
}

.o_12bet .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.o_12bet .account-wrap .head-refresh a {
    background-position: -80px -1150px;
    height: 25px;
    width: 30px
}

.o_12bet .account-wrap .account {
    background-position: 0 0;
    height: 25px;
    width: 110px
}

.o_12bet .account-wrap ul a {
    color: #1E1E1E
}

/* .o_12bet .account-wrap .logout a{
    background:#C4494E url(../images/12bet/bg-send-12bet.png) repeat-x;
    color:#fff
} *
.o_12bet .account-wrap .logout img{
    background:url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}
.o_12bet .menu-wrap{
    background-image:-webkit-gradient(linear, left top, left bottom, from(#BE0000), to(#a00));
    background-image:-webkit-linear-gradient(top, #BE0000 0%, #a00 100%);
    background-image:linear-gradient(180deg, #BE0000 0%, #a00 100%)
}
.o_12bet .menu-wrap a.menu-drop:after{
    border-top-color:#FFF
}
.o_12bet .menu a,.o_12bet .setting-wrap a{
    color:#fff
}
.o_12bet .one_click{
    border-top-color:#FF9B1A;
    color:#fff !important;
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(11%, #959595), to(#6B6B6B));
    background-image:-webkit-linear-gradient(top, #959595 11%, #6B6B6B 100%);
    background-image:linear-gradient(180deg, #959595 11%, #6B6B6B 100%);
    background-position:inherit
}
.o_12bet .one_click img{
    background-position:-94px -2230px
}
.o_12bet .one_click.select{
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(11%, #F77A00), to(#FB5F00));
    background-image:-webkit-linear-gradient(top, #F77A00 11%, #FB5F00 100%);
    background-image:linear-gradient(180deg, #F77A00 11%, #FB5F00 100%);
    background-position:inherit
}
.o_12bet .one_click.select img{
    background-position:-94px -2200px
}
.o_12bet .one_click.select:hover{
    background-image:-webkit-linear-gradient(89deg, #F77A00 0%, #FB5F00 89%);
    background-image:linear-gradient(1deg, #F77A00 0%, #FB5F00 89%)
}
.o_12bet .one_click:hover{
    background-image:-webkit-linear-gradient(89deg, #959595 0%, #6B6B6B 89%);
    background-image:linear-gradient(1deg, #959595 0%, #6B6B6B 89%)
}
.o_12bet .menu-list a:hover{
    color:#f60
}
.o_12bet .menu-list .select{
    background-color:#F2DCA7;
    color:#f60
}
.o_12bet .menu-list .select:hover{
    background-color:#F2DCA7
}
.o_12bet .path{
    background-repeat:repeat-x;
    background-position:0 -5473px
}
.o_12bet .path a{
    color:#fff
}
.o_12bet .path .path-back{
    background-position:0 -2175px;
    height:25px;
    width:36px
}
.o_12bet .path .path-back:hover,.o_12bet .path .path-back.path-back-12bet-hover{
    background-position:0 -2200px
}
.o_12bet .path .path-back.select{
    background-position:0 -2200px;
    height:25px;
    width:36px
}
.o_12bet .path-pop{
    background-color:#666
}
.o_12bet .path-pop a{
    color:#fff;
    background-position:-21px -2000px
}
.o_12bet .slip-wrap h3{
    background-repeat:repeat-x;
    background-position:0 -6173px
}
.o_12bet .slip-wrap .to-expand{
    background-position:100% -199px
}
.o_12bet .slip-wrap.close .to-expand{
    background-position:100% -10px
}
.o_12bet .slip-head{
    background-color:#ddd
}
.o_12bet .matched-head{
    background-color:#fff
}
.o_12bet .tips-popup{
    background-color:#FEEC6E
}
.o_12bet .matched-wrap h3{
    background-repeat:repeat-x;
    background-position:0 -6173px
}
.o_12bet .matched-wrap .to-expand{
    background-position:100% -199px
}
.o_12bet .matched-wrap.close .to-expand{
    background-position:100% -10px
}
.o_12bet .slip_refresh{
    background-position:-51px -494px;
    height:25px;
    width:29px
}
.o_12bet .cashout-wrap .col-profit{
    border-color:#B73B40
}
.o_12bet .slider{
    border-color:#B73B40
}
.o_12bet .slider-bar span{
    border-color:#B73B40;
    background-repeat:repeat-x;
    background-position:0 -9898px
}
/* .o_12bet .slider-bar a{
    background:url(../images/12bet/btn-slider-12bet.png) no-repeat
} */
.o_12bet .upcome-head {
    background-repeat: repeat-x;
    background-position: 0 -6173px
}

.o_12bet .upcome-head a {
    color: #FED63A
}

.o_12bet .upcome-head a:hover {
    color: #f60
}

.o_12bet .upcome a:hover {
    color: #f60
}

.o_12bet .game-wrap h3 {
    background: #353535
}

.o_12bet .game-wrap .to-expand {
    background-position: 100% -1614px
}

.o_12bet .game-wrap.close .to-expand {
    background-position: 100% -1405px
}

.o_12bet .game-highlight-wrap h3 {
    background-color: #C4484D;
    color: #fff
}

.o_12bet .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -9963px
}

.o_12bet .tab-highlight a {
    color: #fff;
    background-position: -284px -117px
}

.o_12bet .tab-highlight a.select {
    color: #1E1E1E;
    background-position: -284px -227px
}

.o_12bet .success {
    background-color: #8DDE73
}

.o_12bet .error {
    background-color: #ff4800
}

.o_12bet .warning {
    background-color: #FEEC6E
}

.o_12bet .game-wrap-horse h3 {
    background: #353535
}

.o_12bet .game-wrap-horse .to-expand {
    background-position: 100% -1614px
}

.o_12bet .game-wrap-horse.close .to-expand {
    background-position: 100% -1405px
}

.o_12bet .game-wrap-horse .hs-time a:hover {
    border-color: #444
}

.o_12bet .tab-markets {
    background: transparent
}

.o_12bet .tab-markets a.select {
    background-position: -284px -706px
}

.o_12bet .add-pin h3 {
    background-position: -385px -835px;
    height: 25px;
    width: 29px
}

.o_12bet .add-pin h3:hover,
.o_12bet .add-pin h3.btn-add-pin-12bet-hover {
    background-position: -385px -810px
}

.o_12bet .delete-pin h3 {
    background-position: -385px -1285px;
    height: 25px;
    width: 29px
}

.o_12bet .delete-pin h3:hover,
.o_12bet .delete-pin h3.btn-delete-pin-hover {
    background-position: -385px -1260px
}

.o_12bet .one_click-setting {
    background-color: #FF4B00;
    background-repeat: repeat-x;
    background-position: 0 -4110px
}

.o_12bet .one_click-setting a {
    border-color: #ab4800
}

.o_12bet .one_click-stake {
    background-position: 0 -3466px;
    height: 31px;
    width: 414px
}

.o_12bet .one_click-stake input,
.o_12bet .one_click-stake textarea,
.o_12bet .one_click-stake .member_select a,
.member_select .o_12bet .one_click-stake a {
    border-color: #AB4800;
    color: #1E1E1E;
    background-color: #ddd
}

.o_12bet .one_click-stake input:focus,
.o_12bet .one_click-stake textarea:focus,
.o_12bet .one_click-stake .member_select a:focus,
.member_select .o_12bet .one_click-stake a:focus {
    border-color: #B73B40;
    background-color: #fff
}

.o_12bet .profile-wrap h3 {
    background-color: #666
}

.o_12bet .tab li {
    border-color: #912F33
}

.o_12bet .tab a {
    border-color: #912F33;
    color: #912F33
}

.o_12bet .tab .select {
    background-color: #912F33;
    color: #fff
}

.o_12bet .menu .logout,
.o_12bet .setting-wrap .logout {
    border-left-width: 0
}

.o_12bet .menu .logout img,
.o_12bet .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.o_12bet .function-wrap {
    background-color: #DADBCE
}

.o_12bet .footer_fixed .main_wrap,
.o_12bet .footer_fixed .header,
.o_12bet .footer_fixed .footer-content {
    background-color: #E6E7E8
}

.o_12bet .submit-wrap .btn-send span {
    background-color: #912F33
}

body.o_dafabet {
    background-color: #E6E7E8
}

.o_dafabet a {
    color: #444
}

.o_dafabet a:hover {
    text-decoration: none
}

.o_dafabet .table01 caption,
.o_dafabet .table-s caption,
.o_dafabet .table_one caption {
    background-color: #444
}

/* .o_dafabet .btn.select,.o_dafabet .select.btn-send,.o_dafabet .pages a.select,.pages .o_dafabet a.select,.o_dafabet .calendarTable_inputBox input.select,.calendarTable_inputBox .o_dafabet input.select,.o_dafabet .calendarTable_inputBox textarea.select,.calendarTable_inputBox .o_dafabet textarea.select,.o_dafabet .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .o_dafabet a.select,.o_dafabet .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .o_dafabet a.select,.o_dafabet .total_all .select.btn_replay,.total_all .o_dafabet .select.btn_replay,.o_dafabet .select.btn_expand,.o_dafabet .select.add_member{
    color:#fff;
    background:#DF8284 url(../images/dafabet/btn-select-dafabet.jpg) repeat-x;
    border-color:#B73B40
}
.o_dafabet .btn-send{
    background:#C4494E url(../images/dafabet/bg-send-dafabet.png) repeat-x;
    color:#fff;
    border-color:#912F33
}
.o_dafabet .btn-send:hover{
    background:#D76E71 url(../images/dafabet/bg-send_hover-dafabet.png) repeat-x
}
.o_dafabet .btn-send.disable{
    background:#CDCDCD url(../images/dafabet/bg-send-disable-dafabet.png) repeat-x;
    border-color:#aaa;
    color:#A3A3A3
} */
/* .o_dafabet .login-panel{
    color:#000
}
.o_dafabet .top{
    background:#930000 url(../images/dafabet/bg-top-dafabet.jpg) repeat-x
}
.o_dafabet .header{
    color:#fff
}
.o_dafabet .header h1{
    margin-top:-4px
}
.o_dafabet .login-wrap .error{
    background-color:transparent;
    color:#FEEC6E
}
.o_dafabet .login-wrap .btn-login{
    background-color:#E83523;
    background-image:none
} */
/* .o_dafabet .login-wrap li.user{
    background-position:-96px -4867px
}
.o_dafabet .account-wrap li .refresh-bar{
    background:url(../images/livesports/refresh80-livesports.gif) no-repeat center
}
.o_dafabet .account-wrap .main-wallet.no-multi .red{
    color:#FFE000 !important
}
.o_dafabet .account-wrap a{
    color:#fff
}
.o_dafabet .account-wrap .main-wallet{
    border-color:rgba(0,0,0,0.4);
    background-color:#c01010
}
.o_dafabet .account-wrap .a-wallet .nums{
    border:1px solid #FFF
}
.o_dafabet .account-wrap .loading-bar span{
    background:#fff
}
.o_dafabet .account-wrap .a-refresh img{
    background:url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size:contain
}
.o_dafabet .account-wrap .loading-bar,.o_dafabet .account-wrap .a-refresh{
    background-color:#bd0b0b
}
.o_dafabet .account-wrap .main-wallet.no-multi .a-refresh{
    border:1px solid rgba(0,0,0,0.5)
}
.o_dafabet .account-wrap .head-refresh a{
    background-position:-80px -1575px;
    height:25px;
    width:30px
}
.o_dafabet .account-wrap .account{
    background-position:0 -425px;
    height:25px;
    width:110px
}
.o_dafabet .account-wrap ul a{
    color:#1E1E1E
}
.o_dafabet .account-wrap .logout a{
    background:#C4494E url(../images/dafabet/bg-send-dafabet.png) repeat-x;
    color:#fff
}
.o_dafabet .account-wrap .logout img{
    background:url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
} */
/* .o_dafabet .menu-wrap{
    background-image:-webkit-gradient(linear, left top, left bottom, from(#1E1E1E), to(#1E1E1E));
    background-image:-webkit-linear-gradient(top, #1E1E1E 0%, #1E1E1E 100%);
    background-image:linear-gradient(180deg, #1E1E1E 0%, #1E1E1E 100%);
    box-shadow:inset 0 2px 0 0 rgba(0,0,0,0.2);
    background-position:inherit
}
.o_dafabet .menu-wrap a.menu-drop:after{
    border-top-color:#FFF
}
.o_dafabet .menu a,.o_dafabet .setting-wrap a{
    color:#fff
}
.o_dafabet .one_click{
    border-top-color:#FF9B1A;
    color:#fff !important;
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(11%, #959595), to(#6B6B6B));
    background-image:-webkit-linear-gradient(top, #959595 11%, #6B6B6B 100%);
    background-image:linear-gradient(180deg, #959595 11%, #6B6B6B 100%);
    background-position:inherit
}
.o_dafabet .one_click img{
    background-position:-94px -3250px
}
.o_dafabet .one_click.select{
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(11%, #F77A00), to(#FB5F00));
    background-image:-webkit-linear-gradient(top, #F77A00 11%, #FB5F00 100%);
    background-image:linear-gradient(180deg, #F77A00 11%, #FB5F00 100%);
    background-position:inherit
}
.o_dafabet .one_click.select img{
    background-position:-94px -3220px
}
.o_dafabet .one_click.select:hover{
    background-image:-webkit-linear-gradient(89deg, #F77A00 0%, #FB5F00 89%);
    background-image:linear-gradient(1deg, #F77A00 0%, #FB5F00 89%)
}
.o_dafabet .one_click:hover{
    background-image:-webkit-linear-gradient(89deg, #959595 0%, #6B6B6B 89%);
    background-image:linear-gradient(1deg, #959595 0%, #6B6B6B 89%)
}
.o_dafabet .menu-list a:hover{
    color:#f60
}
.o_dafabet .menu-list .select{
    background-color:#F2DCA7;
    color:#f60
}
.o_dafabet .menu-list .select:hover{
    background-color:#F2DCA7
}
.o_dafabet .path{
    background-repeat:repeat-x;
    background-position:0 -5748px
}
.o_dafabet .path a{
    color:#fff
}
.o_dafabet .path .path-back{
    background-position:0 -3025px;
    height:25px;
    width:36px
}
.o_dafabet .path .path-back:hover,.o_dafabet .path .path-back.path-back-dafabet-hover{
    background-position:0 -3050px
}
.o_dafabet .path .path-back.select{
    background-position:0 -3050px;
    height:25px;
    width:36px
}
.o_dafabet .path-pop{
    background-color:#666
}
.o_dafabet .path-pop a{
    color:#fff;
    background-position:-21px -2050px
}
.o_dafabet .slip-wrap h3{
    background-repeat:repeat-x;
    background-position:0 -6223px
}
.o_dafabet .slip-wrap .to-expand{
    background-position:100% -249px
}
.o_dafabet .slip-wrap.close .to-expand{
    background-position:100% -60px
}
.o_dafabet .slip-head{
    background-color:#ddd
}
.o_dafabet .matched-head{
    background-color:#fff
}
.o_dafabet .tips-popup{
    background-color:#FEEC6E
}
.o_dafabet .matched-wrap h3{
    background-repeat:repeat-x;
    background-position:0 -6223px
}
.o_dafabet .matched-wrap .to-expand{
    background-position:100% -249px
}
.o_dafabet .matched-wrap.close .to-expand{
    background-position:100% -60px
}
.o_dafabet .slip_refresh{
    background-position:-51px -544px;
    height:25px;
    width:29px
}
.o_dafabet .cashout-wrap .col-profit{
    border-color:#B73B40
}
.o_dafabet .slider{
    border-color:#B73B40
}
.o_dafabet .slider-bar span{
    border-color:#B73B40;
    background-repeat:repeat-x;
    background-position:0 -9914px
}
.o_dafabet .slider-bar a{
    background:url('../images/dafabet/btn-slider-dafabet.png') no-repeat
}
.o_dafabet .upcome-head{
    background-repeat:repeat-x;
    background-position:0 -6223px
}
.o_dafabet .upcome-head a{
    color:#FED63A
}
.o_dafabet .upcome-head a:hover{
    color:#f60
} */
.o_dafabet .upcome a:hover {
    color: #f60
}

.o_dafabet .game-wrap h3 {
    background: #353535
}

.o_dafabet .game-wrap .to-expand {
    background-position: 100% -1664px
}

.o_dafabet .game-wrap.close .to-expand {
    background-position: 100% -1455px
}

.o_dafabet .game-highlight-wrap h3 {
    background-color: #C4484D;
    color: #fff
}

.o_dafabet .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10325px
}

.o_dafabet .tab-highlight a {
    color: #fff;
    background-position: -284px -161px
}

.o_dafabet .tab-highlight a.select {
    color: #1E1E1E;
    background-position: -284px -227px
}

.o_dafabet .success {
    background-color: #8DDE73
}

.o_dafabet .error {
    background-color: #ff4800
}

.o_dafabet .warning {
    background-color: #FEEC6E
}

.o_dafabet .game-wrap-horse h3 {
    background: #353535
}

.o_dafabet .game-wrap-horse .to-expand {
    background-position: 100% -1664px
}

.o_dafabet .game-wrap-horse.close .to-expand {
    background-position: 100% -1455px
}

.o_dafabet .game-wrap-horse .hs-time a:hover {
    border-color: #444
}

.o_dafabet .tab-markets {
    background: transparent
}

.o_dafabet .tab-markets a.select {
    background-position: -284px -706px
}

.o_dafabet .add-pin h3 {
    background-position: -385px -935px;
    height: 25px;
    width: 29px
}

.o_dafabet .add-pin h3:hover,
.o_dafabet .add-pin h3.btn-add-pin-dafabet-hover {
    background-position: -385px -910px
}

.o_dafabet .delete-pin h3 {
    background-position: -385px -1285px;
    height: 25px;
    width: 29px
}

.o_dafabet .delete-pin h3:hover,
.o_dafabet .delete-pin h3.btn-delete-pin-hover {
    background-position: -385px -1260px
}

.o_dafabet .one_click-setting {
    background-color: #FF4B00;
    background-repeat: repeat-x;
    background-position: 0 -4638px
}

.o_dafabet .one_click-setting a {
    border-color: #ab4800
}

.o_dafabet .one_click-stake {
    background-position: 0 -3994px;
    height: 31px;
    width: 414px
}

.o_dafabet .one_click-stake input,
.o_dafabet .one_click-stake textarea,
.o_dafabet .one_click-stake .member_select a,
.member_select .o_dafabet .one_click-stake a {
    border-color: #AB4800;
    color: #1E1E1E;
    background-color: #ddd
}

.o_dafabet .one_click-stake input:focus,
.o_dafabet .one_click-stake textarea:focus,
.o_dafabet .one_click-stake .member_select a:focus,
.member_select .o_dafabet .one_click-stake a:focus {
    border-color: #B73B40;
    background-color: #fff
}

.o_dafabet .profile-wrap h3 {
    background-color: #666
}

.o_dafabet .tab li {
    border-color: #912F33
}

.o_dafabet .tab a {
    border-color: #912F33;
    color: #912F33
}

.o_dafabet .tab .select {
    background-color: #912F33;
    color: #fff
}

.o_dafabet .menu .logout,
.o_dafabet .setting-wrap .logout {
    border-left-width: 0
}

.o_dafabet .menu .logout img,
.o_dafabet .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.o_dafabet .function-wrap {
    background-color: #DADBCE
}

.o_dafabet .footer_fixed .main_wrap,
.o_dafabet .footer_fixed .header,
.o_dafabet .footer_fixed .footer-content {
    background-color: #E6E7E8
}

.o_dafabet .submit-wrap .btn-send span {
    background-color: #912F33
}

/* body.mazaplay{
    background-color:#F8F9E9
}
.mazaplay .btn.select,.mazaplay .select.btn-send,.mazaplay .pages a.select,.pages .mazaplay a.select,.mazaplay .calendarTable_inputBox input.select,.calendarTable_inputBox .mazaplay input.select,.mazaplay .calendarTable_inputBox textarea.select,.calendarTable_inputBox .mazaplay textarea.select,.mazaplay .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .mazaplay a.select,.mazaplay .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .mazaplay a.select,.mazaplay .total_all .select.btn_replay,.total_all .mazaplay .select.btn_replay,.mazaplay .select.btn_expand,.mazaplay .select.add_member{
    background:#7BB54C url(../images/mazaplay/btn-select-maza.jpg) repeat-x;
    border-color:#32803A
}
.mazaplay .btn-send{
    background:#38703D url(../images/mazaplay/bg-send-maza.png) repeat-x;
    color:#fff;
    border-color:#07571D
}
.mazaplay .btn-send:hover{
    background:#48955B url(../images/mazaplay/bg-send_hover-maza.png) repeat-x
}
.mazaplay .btn-send.disable{
    background:#C3D5C5 url(../images/mazaplay/bg-send-disable-maza.png) repeat-x;
    border-color:#A2B4A7;
    color:#EFFCF4
}
.mazaplay .kv{
    background:#48955B url(../images/mazaplay/bg-login_wrap-mazaplay.jpg) no-repeat
}
.mazaplay .login-panel{
    color:#fff
}
.mazaplay .login-panel dt{
    color:#000
}
.mazaplay .top{
    background:#307234 url(../images/mazaplay/bg-top-maza.jpg) repeat-x
} */
.mazaplay .header {
    color: #fff
}

.mazaplay .header h1 {
    margin-top: -12px
}

.mazaplay .login-wrap li.user {
    background-position: -96px -5442px
}

.mazaplay .login-wrap .error {
    color: #EFDA54
}

.mazaplay .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.mazaplay .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #CA1F00;
    border-radius: 3px
}

.mazaplay .account-wrap a {
    color: #fff
}

.mazaplay .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4)
}

.mazaplay .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.mazaplay .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.mazaplay .account-wrap .a-wallet .red {
    background-color: #fff;
    padding: 0 3px;
    border-radius: 2px
}

.mazaplay .account-wrap .loading-bar span {
    background: #fff
}

.mazaplay .account-wrap .head-refresh a {
    background-position: -80px -1900px;
    height: 25px;
    width: 30px
}

.mazaplay .account-wrap .account {
    background-position: 0 -750px;
    height: 25px;
    width: 110px
}

.mazaplay .account-wrap ul a {
    color: #1E1E1E
}

.mazaplay .account-wrap .logout a {
    color: #fff
}

.mazaplay .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2C5F36), to(#3C7447));
    background-image: -webkit-linear-gradient(top, #2C5F36 0%, #3C7447 100%);
    background-image: linear-gradient(180deg, #2C5F36 0%, #3C7447 100%);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.4)
}

.mazaplay .menu li,
.mazaplay .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.mazaplay .menu a:hover,
.mazaplay .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.mazaplay .menu .select,
.mazaplay .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.mazaplay .setting {
    border-left-color: rgba(255, 255, 255, 0.1)
}

.mazaplay .one_click {
    border-top-color: #F0DB55;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(14%, #2B6B37), to(#014E0F));
    background-image: -webkit-linear-gradient(top, #2B6B37 14%, #014E0F 100%);
    background-image: linear-gradient(180deg, #2B6B37 14%, #014E0F 100%);
    background-position: inherit
}

.mazaplay .one_click img {
    background-position: -94px -3970px
}

.mazaplay .one_click.select {
    color: #604401;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BCA300), to(#BCA300));
    background-image: -webkit-linear-gradient(top, #BCA300 0%, #BCA300 100%);
    background-image: linear-gradient(180deg, #BCA300 0%, #BCA300 100%);
    background-position: inherit
}

.mazaplay .one_click.select img {
    background-position: -94px -3940px
}

.mazaplay .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(27%, #BDA500), to(#C8B71D));
    background-image: -webkit-linear-gradient(top, #BDA500 27%, #C8B71D 100%);
    background-image: linear-gradient(180deg, #BDA500 27%, #C8B71D 100%)
}

.mazaplay .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(14%, #014E0F), to(#2B6B37));
    background-image: -webkit-linear-gradient(top, #014E0F 14%, #2B6B37 100%);
    background-image: linear-gradient(180deg, #014E0F 14%, #2B6B37 100%)
}

.mazaplay .time_zone span {
    color: #99B39E
}

.mazaplay .menu-list li {
    border-bottom-color: #DDE7DE
}

.mazaplay .menu-list a {
    color: #1A4F3D
}

.mazaplay .menu-list a:hover {
    background-color: #C8D3CD
}

.mazaplay .menu-list .select {
    background-color: #C8D3CD;
    color: #1A4F3D
}

.mazaplay .menu-list .select:hover {
    background-color: #C8D3CD
}

.mazaplay .menu-list .Go {
    background-position: 100% -1350px;
    height: 25px;
    width: 23px
}

.mazaplay .menu-list .Go:hover,
.mazaplay .menu-list .Go.Go-maza-hover {
    background-position: 100% -1375px
}

.mazaplay .sub_path {
    background-color: #ADACAC
}

.mazaplay .path {
    background: #33694F
}

.mazaplay .path .path-back {
    background-position: 0 -3675px;
    height: 25px;
    width: 36px
}

.mazaplay .path .path-back:hover,
.mazaplay .path .path-back.path-back-maza-hover {
    background-position: 0 -3700px
}

.mazaplay .path .path-back.select {
    background-position: 0 -3700px;
    height: 25px;
    width: 36px
}

.mazaplay .game-highlight-wrap h3 {
    background-color: #32803A;
    color: #fff
}

.mazaplay .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10545px
}

.mazaplay .tab-markets {
    background: transparent
}

.mazaplay .one_click-setting {
    color: #604401;
    background-repeat: repeat-x;
    background-color: #B29500;
    background-position: 0 -5046px
}

.mazaplay .one_click-setting a {
    border-color: #604401
}

.mazaplay .one_click-setting .a-edit {
    color: #604401
}

.mazaplay .one_click-setting .a-edit img {
    background-position: -405px -2224px;
    height: 9px;
    width: 9px
}

.mazaplay .one_click-stake {
    background-position: 0 -4402px;
    height: 31px;
    width: 414px
}

.mazaplay .one_click-stake input,
.mazaplay .one_click-stake textarea,
.mazaplay .one_click-stake .member_select a,
.member_select .mazaplay .one_click-stake a {
    border-color: #604401;
    color: #1E1E1E;
    background-color: #B09C02
}

.mazaplay .one_click-stake input:focus,
.mazaplay .one_click-stake textarea:focus,
.mazaplay .one_click-stake .member_select a:focus,
.member_select .mazaplay .one_click-stake a:focus {
    border-color: #fff;
    background-color: #A5AB38
}

.mazaplay .menu .logout,
.mazaplay .setting-wrap .logout {
    border-left-width: 0
}

.mazaplay .footer_fixed .main_wrap,
.mazaplay .footer_fixed .header,
.mazaplay .footer_fixed .footer-content {
    background-color: #F8F9E9
}

.mazaplay .submit-wrap .btn-send span {
    background-color: #E9D753;
    color: #07571D
}

/* body.mysports247{
    background-color:#E5E5DF
}
.mysports247 .btn.select,.mysports247 .select.btn-send,.mysports247 .pages a.select,.pages .mysports247 a.select,.mysports247 .calendarTable_inputBox input.select,.calendarTable_inputBox .mysports247 input.select,.mysports247 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .mysports247 textarea.select,.mysports247 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .mysports247 a.select,.mysports247 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .mysports247 a.select,.mysports247 .total_all .select.btn_replay,.total_all .mysports247 .select.btn_replay,.mysports247 .select.btn_expand,.mysports247 .select.add_member{
    background:#F2D65D url(../images/mysports247/btn-select-247.jpg) repeat-x;
    border-color:#897700
}
.mysports247 .btn-send{
    background:#BD9406 url(../images/mysports247/bg-send-247.png) repeat-x;
    border-color:#897700
}
.mysports247 .btn-send:hover{
    background:#EAC535 url(../images/mysports247/bg-send_hover-247.png) repeat-x
}
.mysports247 .btn-send.disable{
    background:#EBDEB4 url(../images/mysports247/bg-send-disable-247.png) repeat-x;
    border-color:#A2B4A7
}
.mysports247 .kv{
    background:#122343 url(../images/mysports247/bg-login_wrap-mysports247.jpg) no-repeat
}
.mysports247 .icon-login{
    background-position:0 -112px;
    height:12px;
    width:12px
}
.mysports247 .top{
    background:#C4A73F url(../images/mysports247/bg-top-mysports247.jpg) repeat-x
}
.mysports247 .header h1{
    margin-top:-9px
}
.mysports247 .login-wrap .error{
    color:#024168
}
.mysports247 .login-wrap li.user{
    background-position:-96px -5467px
}
.mysports247 .account-wrap .head-refresh a{
    background-position:-80px -1925px;
    height:25px;
    width:30px
}
.mysports247 .account-wrap .account{
    background-position:0 -775px;
    height:25px;
    width:110px
}
.mysports247 .menu-wrap{
    background-image:-webkit-gradient(linear, left bottom, left top, from(#162231), color-stop(70%, #23354B));
    background-image:-webkit-linear-gradient(bottom, #162231 0%, #23354B 70%);
    background-image:linear-gradient(0deg, #162231 0%, #23354B 70%);
    box-shadow:inset 0 1px 3px 0 rgba(0,0,0,0.4)
}
.mysports247 .menu-wrap a{
    color:#ECCB4B
}
.mysports247 .menu li,.mysports247 .setting-wrap li{
    border-right-color:rgba(255,255,255,0.1)
}
.mysports247 .menu li ul,.mysports247 .setting-wrap li ul{
    background-color:#22354A
}
.mysports247 .menu li li,.mysports247 .setting-wrap li li{
    border-bottom-color:#466071
}
.mysports247 .menu a:hover,.mysports247 .setting-wrap a:hover{
    background-color:rgba(255,255,255,0.1)
}
.mysports247 .menu .select,.mysports247 .setting-wrap .select{
    background-color:rgba(255,255,255,0.2)
}
.mysports247 .setting{
    border-left-color:rgba(255,255,255,0.1)
}
.mysports247 .setting img{
    background:url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23ECCB4B" fill-rule="evenodd"/></svg>') no-repeat
}
.mysports247 .one_click{
    border-top-color:#D56525;
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(12%, #2D4561), to(#1E2E41));
    background-image:-webkit-linear-gradient(top, #2D4561 12%, #1E2E41 100%);
    background-image:linear-gradient(180deg, #2D4561 12%, #1E2E41 100%);
    background-position:inherit
}
.mysports247 .one_click img{
    background-position:-94px -2290px
}
.mysports247 .one_click.select{
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(10%, #B83A08), color-stop(77%, #912B06));
    background-image:-webkit-linear-gradient(top, #B83A08 10%, #912B06 77%);
    background-image:linear-gradient(180deg, #B83A08 10%, #912B06 77%);
    background-position:inherit
}
.mysports247 .one_click.select img{
    background-position:-94px -2260px
}
.mysports247 .one_click.select:hover{
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(10%, #912B06), color-stop(77%, #B83A08));
    background-image:-webkit-linear-gradient(top, #912B06 10%, #B83A08 77%);
    background-image:linear-gradient(180deg, #912B06 10%, #B83A08 77%)
}
.mysports247 .one_click:hover{
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(12%, #1E2E41), to(#2D4561));
    background-image:-webkit-linear-gradient(top, #1E2E41 12%, #2D4561 100%);
    background-image:linear-gradient(180deg, #1E2E41 12%, #2D4561 100%)
}
.mysports247 .slip_set-pop a,.mysports247 .oneclick_set-pop a{
    color:#1E1E1E
}
.mysports247 .time_zone{
    color:#ECCB4B
}
.mysports247 .time_zone span{
    color:#847C48
}
.mysports247 .menu-list li{
    border-bottom-color:#466071
}
.mysports247 .menu-list a{
    color:#D3CFB6
}
.mysports247 .menu-list a:hover{
    background-color:#12232E
}
.mysports247 .menu-list .select{
    background-color:#12232E;
    color:#D3CFB6
}
.mysports247 .menu-list .select:hover{
    background-color:#12232E
}
.mysports247 .menu-list .Go{
    background-position:100% 0;
    height:25px;
    width:23px
}
.mysports247 .menu-list .Go:hover,.mysports247 .menu-list .Go.Go-247-hover{
    background-position:100% -25px
}
.mysports247 .sub_path{
    background-color:#243A48
}
.mysports247 .path{
    background-repeat:repeat-x;
    background-position:0 -5498px
}
.mysports247 .path a{
    color:#243A48
}
.mysports247 .path .path-back{
    background-position:0 -2225px;
    height:25px;
    width:36px
}
.mysports247 .path .path-back:hover,.mysports247 .path .path-back.path-back-247-hover{
    background-position:0 -2250px
}
.mysports247 .path .path-back.select{
    background-position:0 -2250px;
    height:25px;
    width:36px
}
.mysports247 .game-highlight-wrap h3{
    background-color:#D7C45E;
    color:#222
}
.mysports247 .tab-highlight{
    background-repeat:repeat-x;
    background-position:0 -9985px
}
.mysports247 .tab-markets{
    background:transparent
}
.mysports247 .one_click-setting{
    color:#ECCB4B;
    background-repeat:repeat-x;
    background-color:#912B06;
    background-position:0 -4141px
}
.mysports247 .one_click-setting a{
    border-color:#621F04
}
.mysports247 .one_click-setting .a-edit{
    color:#ECCB4B
}
.mysports247 .one_click-setting .a-edit img{
    background-position:-405px -2008px;
    height:9px;
    width:9px
}
.mysports247 .one_click-stake{
    background-position:0 -3497px;
    height:31px;
    width:414px
}
.mysports247 .one_click-stake input,.mysports247 .one_click-stake textarea,.mysports247 .one_click-stake .member_select a,.member_select .mysports247 .one_click-stake a{
    border-color:#621F04;
    color:#1E1E1E;
    background-color:#CD7441
}
.mysports247 .one_click-stake input:focus,.mysports247 .one_click-stake textarea:focus,.mysports247 .one_click-stake .member_select a:focus,.member_select .mysports247 .one_click-stake a:focus{
    border-color:#ECCB4B;
    background-color:#D6956D
}
.mysports247 .footer_fixed .main_wrap,.mysports247 .footer_fixed .header,.mysports247 .footer_fixed .footer-content{
    background-color:#E5E5DF
}
.mysports247 .submit-wrap .btn-send span{
    background-color:#243A48
} */
body.bdtwin {
    background-color: #F0ECE1
}

.bdtwin .btn.select,
.bdtwin .select.btn-send,
.bdtwin .pages a.select,
.pages .bdtwin a.select,
.bdtwin .calendarTable_inputBox input.select,
.calendarTable_inputBox .bdtwin input.select,
.bdtwin .calendarTable_inputBox textarea.select,
.calendarTable_inputBox .bdtwin textarea.select,
.bdtwin .calendarTable_inputBox .member_select a.select,
.calendarTable_inputBox .member_select .bdtwin a.select,
.bdtwin .member_select .calendarTable_inputBox a.select,
.member_select .calendarTable_inputBox .bdtwin a.select,
.bdtwin .total_all .select.btn_replay,
.total_all .bdtwin .select.btn_replay,
.bdtwin .select.btn_expand,
.bdtwin .select.add_member {
    color: #F8D61C;
    background: #444 url(../images/bdtwin/btn-select-skyEX.jpg) repeat-x;
    border-color: #222
}

.bdtwin .btn.select:hover,
.bdtwin .select.btn-send:hover,
.bdtwin .pages a.select:hover,
.pages .bdtwin a.select:hover,
.bdtwin .calendarTable_inputBox input.select:hover,
.calendarTable_inputBox .bdtwin input.select:hover,
.bdtwin .calendarTable_inputBox textarea.select:hover,
.calendarTable_inputBox .bdtwin textarea.select:hover,
.bdtwin .calendarTable_inputBox .member_select a.select:hover,
.calendarTable_inputBox .member_select .bdtwin a.select:hover,
.bdtwin .member_select .calendarTable_inputBox a.select:hover,
.member_select .calendarTable_inputBox .bdtwin a.select:hover,
.bdtwin .total_all .select.btn_replay:hover,
.total_all .bdtwin .select.btn_replay:hover,
.bdtwin .select.btn_expand:hover,
.bdtwin .select.add_member:hover {
    background: #444 url(../images/bdtwin/btn-select-skyEX.jpg) repeat-x
}

.bdtwin .btn-send {
    background: #121212 url(../images/bdtwin/bg-send-skyEX.png) repeat-x;
    color: #F8D61C;
    border-color: #000
}

.bdtwin .btn-send:hover {
    background: #464646 url(../images/bdtwin/bg-send_hover-skyEX.png) repeat-x
}

.bdtwin .btn-send.disable {
    background: #A4A4A4 url(../images/bdtwin/bg-send-disable-skyEX.png) repeat-x;
    border-color: #aaa;
    color: #E6DFCD
}

.bdtwin .kv {
    background: url('../images/bg.gif') no-repeat
}

.bdtwin .login-panel {
    color: #000
}

.bdtwin .icon-login {
    background-position: 0 -425px;
    height: 12px;
    width: 12px
}

.bdtwin .announce-header h1:before {
    background: #FFB80C
}

.bdtwin .top {
    background: #000 url(../images/bdtwin/bg-top-skyEX.jpg) repeat-x
}

.bdtwin .header {
    color: #FFB80C
}

.bdtwin .header h1 {
    margin-top: -10px
}

.bdtwin .login-wrap li.user {
    background-position: -96px -5567px
}

.bdtwin .login-wrap .error {
    color: #F8D61C
}

.bdtwin .login-wrap .btn-signup {
    display: block;
    background-color: #666666;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#666), to(#333));
    background-image: -webkit-linear-gradient(top, #666 0%, #333 100%);
    background-image: linear-gradient(-180deg, #666 0%, #333 100%)
}

.bdtwin .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.bdtwin .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #F2E9CF !important;
    background-color: #BF0000;
    border-radius: 3px
}

.bdtwin .account-wrap a {
    color: #FFB80C
}

.bdtwin .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8)
}

.bdtwin .account-wrap .a-wallet .nums {
    border: 1px solid #FFB80C
}

.bdtwin .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important
}

.bdtwin .account-wrap .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.25) !important
}

.bdtwin .account-wrap .loading-bar span {
    background: #fff
}

.bdtwin .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFB80C"/></svg>') no-repeat center center;
    background-size: contain
}

.bdtwin .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.8)
}

.bdtwin .account-wrap .head-refresh a {
    background-position: -80px -2075px;
    height: 25px;
    width: 30px
}

.bdtwin .account-wrap .account {
    background-position: 0 -925px;
    height: 25px;
    width: 110px
}

.bdtwin .account-wrap ul a {
    color: #1E1E1E
}

.bdtwin .account-wrap .logout a {
    color: #fff
}

.bdtwin .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, #FFCC2F), to(#FFB80C));
    background-image: -webkit-linear-gradient(top, #FFCC2F 8%, #FFB80C 100%);
    background-image: linear-gradient(180deg, #FFCC2F 8%, #FFB80C 100%);
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.75)
}

.bdtwin .menu-wrap a.menu-drop:after {
    border-top-color: #222
}

.bdtwin .menu li,
.bdtwin .setting-wrap li {
    border-right-color: rgba(69, 32, 7, 0.2)
}

.bdtwin .menu li ul a,
.bdtwin .setting-wrap li ul a {
    color: #FFB80C
}

.bdtwin .menu a,
.bdtwin .setting-wrap a {
    color: #222
}

.bdtwin .menu a:hover,
.bdtwin .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.bdtwin .menu .select,
.bdtwin .setting-wrap .select {
    box-shadow: inset 0 1px 3px 0 rgba(52, 9, 9, 0.5);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#DBBC68), color-stop(61%, #FFDC7A));
    background-image: -webkit-linear-gradient(top, #DBBC68 0%, #FFDC7A 61%);
    background-image: linear-gradient(180deg, #DBBC68 0%, #FFDC7A 61%);
    background-position: inherit
}

.bdtwin .menu .tag-new,
.bdtwin .setting-wrap .tag-new {
    color: #FFFFFF
}

.bdtwin .setting {
    border-left-color: rgba(69, 32, 7, 0.2)
}

.bdtwin .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23222" fill-rule="evenodd"/></svg>') no-repeat
}

.bdtwin .one_click {
    border-top-color: #FFB80C;
    color: #FFB80C !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(13%, #444), color-stop(81%, #1B1B1B));
    background-image: -webkit-linear-gradient(top, #444 13%, #1B1B1B 81%);
    background-image: linear-gradient(180deg, #444 13%, #1B1B1B 81%);
    background-position: inherit
}

.bdtwin .one_click img {
    background-position: -94px -4240px
}

.bdtwin .one_click.select {
    color: #1E1E1E !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#00C15D), to(#009347)) !important;
    background-image: -webkit-linear-gradient(top, #00C15D 0%, #009347 100%) !important;
    background-image: linear-gradient(180deg, #00C15D 0%, #009347 100%) !important;
    background-position: inherit
}

.bdtwin .one_click.select img {
    background-position: -94px -4210px
}

.bdtwin .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(100%, #009347), color-stop(0%, #00C15D)) !important;
    background-image: -webkit-linear-gradient(top, #009347 100%, #00C15D 0%) !important;
    background-image: linear-gradient(180deg, #009347 100%, #00C15D 0%) !important
}

.bdtwin .one_click:hover {
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(19%, #444), color-stop(87%, #1B1B1B));
    background-image: -webkit-linear-gradient(bottom, #444 19%, #1B1B1B 87%);
    background-image: linear-gradient(0deg, #444 19%, #1B1B1B 87%)
}

.bdtwin .slip_set-pop a:hover,
.bdtwin .oneclick_set-pop a:hover {
    text-decoration: none
}

.bdtwin .slip_set-pop .btn-send,
.bdtwin .oneclick_set-pop .btn-send {
    color: #F8D61C
}

.bdtwin .time_zone {
    color: #000
}

.bdtwin .time_zone span {
    color: #9A7816
}

.bdtwin .menu-list li {
    border-bottom-color: #EFE1C1
}

.bdtwin .menu-list a {
    color: #222
}

.bdtwin .menu-list a:hover {
    background-color: #F2DCA7
}

.bdtwin .menu-list .select {
    background-color: #F2DCA7;
    color: #222
}

.bdtwin .menu-list .select:hover {
    background-color: #F2DCA7
}

.bdtwin .menu-list .Go {
    background-position: 100% -1650px;
    height: 25px;
    width: 23px
}

.bdtwin .menu-list .Go:hover,
.bdtwin .menu-list .Go.Go-skyEX-hover {
    background-position: 100% -1675px
}

.bdtwin .path {
    background: #222
}

.bdtwin .path a {
    color: #FFB80C
}

.bdtwin .path .path-back {
    background-position: 0 -3975px;
    height: 25px;
    width: 36px
}

.bdtwin .path .path-back:hover,
.bdtwin .path .path-back.path-back-skyEX-hover {
    background-position: 0 -4000px
}

.bdtwin .path .path-back.select {
    background-position: 0 -4000px;
    height: 25px;
    width: 36px
}

.bdtwin .path-pop {
    background-color: #222
}

.bdtwin .login_to_go {
    background-color: #FFB80C
}

.bdtwin .login_to_go .close {
    background-position: -394px -1180px;
    height: 20px;
    width: 20px
}

.bdtwin .one_click-setting {
    color: #1E1E1E;
    background-repeat: repeat-x;
    background-color: #008841;
    background-position: 0 -5202px
}

.bdtwin .one_click-setting a {
    border-color: #005F2D
}

.bdtwin .one_click-setting .a-edit {
    color: #1E1E1E
}

.bdtwin .one_click-setting .a-edit img {
    background-position: -405px -2260px;
    height: 9px;
    width: 9px
}

.bdtwin .one_click-stake {
    background-position: 0 -4558px;
    height: 31px;
    width: 414px
}

.bdtwin .one_click-stake input,
.bdtwin .one_click-stake textarea,
.bdtwin .one_click-stake .member_select a,
.member_select .bdtwin .one_click-stake a {
    border-color: #005F2D;
    color: #fff;
    background-color: #1F8C53
}

.bdtwin .one_click-stake input:focus,
.bdtwin .one_click-stake textarea:focus,
.bdtwin .one_click-stake .member_select a:focus,
.member_select .bdtwin .one_click-stake a:focus {
    border-color: #fff;
    background-color: #00632F
}

.bdtwin .menu .logout,
.bdtwin .setting-wrap .logout {
    border-left-width: 0
}

.bdtwin .menu .logout img,
.bdtwin .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23222" fill-rule="evenodd"/></svg>') no-repeat
}

.bdtwin .footer_fixed .main_wrap,
.bdtwin .footer_fixed .header,
.bdtwin .footer_fixed .footer-content {
    background-color: #F0ECE1
}

.bdtwin .submit-wrap .btn-send span {
    background-color: #666
}

body.o_spondemo {
    background-color: #f1f1f1
}

.o_spondemo a {
    color: #666
}

.o_spondemo a:hover {
    text-decoration: none
}

.o_spondemo .table01 caption,
.o_spondemo .table-s caption,
.o_spondemo .table_one caption {
    background-color: #717171;
    border-bottom: 1px solid #aaa
}

.o_spondemo .btn.select,
.o_spondemo .select.btn-send,
.o_spondemo .pages a.select,
.pages .o_spondemo a.select,
.o_spondemo .calendarTable_inputBox input.select,
.calendarTable_inputBox .o_spondemo input.select,
.o_spondemo .calendarTable_inputBox textarea.select,
.calendarTable_inputBox .o_spondemo textarea.select,
.o_spondemo .calendarTable_inputBox .member_select a.select,
.calendarTable_inputBox .member_select .o_spondemo a.select,
.o_spondemo .member_select .calendarTable_inputBox a.select,
.member_select .calendarTable_inputBox .o_spondemo a.select,
.o_spondemo .total_all .select.btn_replay,
.total_all .o_spondemo .select.btn_replay,
.o_spondemo .select.btn_expand,
.o_spondemo .select.add_member {
    color: #fff;
    background: #92a7cb;
    border-color: #aaa;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4)
}

.o_spondemo .btn-send {
    background: #4E69A0 url(../images/spdemo/bg-send-spdemo.png) repeat-x;
    color: #fff;
    border-color: #aaa
}

.o_spondemo .btn-send:hover {
    background: #769EE3 url(../images/spdemo/bg-send_hover-spdemo.png) repeat-x
}

.o_spondemo .btn-send.disable {
    background: #cdcdcd url(../images/spdemo/bg-send-disable-spdemo.png) repeat-x;
    border-color: #aaa;
    color: #a3a3a3
}

.o_spondemo .top {
    background: #f1f1f1 url(../images/spdemo/bg-top-spdemo.jpg) repeat-x
}

.o_spondemo .header {
    color: #1E1E1E
}

.o_spondemo .header h1 {
    margin-top: -4px
}

.o_spondemo .language {
    background-position: -67px -1100px
}

.o_spondemo .login-wrap .error {
    background-color: transparent;
    color: #024168
}

.o_spondemo .login-wrap li.user {
    background-position: -96px -5617px
}

.o_spondemo .account-wrap li .refresh-bar {
    background: url(../images/refresh80.gif) no-repeat center
}

.o_spondemo .account-wrap .main-wallet.no-multi .red {
    color: #EB6307 !important
}

.o_spondemo .account-wrap a {
    color: #1E1E1E
}

.o_spondemo .account-wrap .a-wallet.open {
    background-color: rgba(0, 0, 0, 0.05) !important;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.3)
}

.o_spondemo .account-wrap .head-refresh a {
    background-position: -80px -2125px;
    height: 25px;
    width: 30px
}

.o_spondemo .account-wrap .account {
    background-position: 0 -975px;
    height: 25px;
    width: 110px
}

.o_spondemo .account-wrap ul a {
    color: #1E1E1E
}

.o_spondemo .account-wrap .logout a {
    background: #4E69A0 url(../images/spdemo/bg-send-spdemo.png) repeat-x;
    color: #fff
}

.o_spondemo .account-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.o_spondemo .search-input {
    border: 1px solid #CCCCCC;
    box-shadow: none
}

.o_spondemo .suggestion-wrap {
    border: 1px solid #CCCCCC;
    border-top: 1px solid #E0E6E6
}

.o_spondemo .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#206DA9), color-stop(89%, #528FBB));
    background-image: -webkit-linear-gradient(top, #206DA9 0%, #528FBB 89%);
    background-image: linear-gradient(180deg, #206DA9 0%, #528FBB 89%)
}

.o_spondemo .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.o_spondemo .menu a,
.o_spondemo .setting-wrap a {
    color: #fff
}

.o_spondemo .menu a:hover,
.o_spondemo .setting-wrap a:hover {
    background-color: #1C649A
}

.o_spondemo .menu li,
.o_spondemo .setting-wrap li {
    border-right-color: #dbdada
}

.o_spondemo .menu li ul,
.o_spondemo .setting-wrap li ul {
    background-color: #3d77a3;
    border-top-color: #fff
}

.o_spondemo .menu li ul li,
.o_spondemo .setting-wrap li ul li {
    border-bottom-color: rgba(255, 255, 255, 0.4)
}

.o_spondemo .menu .select,
.o_spondemo .setting-wrap .select {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(13%, #225D89), to(#2D4556));
    background-image: -webkit-linear-gradient(top, #225D89 13%, #2D4556 100%);
    background-image: linear-gradient(180deg, #225D89 13%, #2D4556 100%);
    background-position: inherit
}

.o_spondemo .setting {
    border-left-color: #fff
}

.o_spondemo .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.o_spondemo .live-menu {
    border-left-color: #fff
}

.o_spondemo .one_click {
    border-left: 1px solid #fff;
    border-top-color: #ffa76b;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #F97B23), to(#F97B23));
    background-image: -webkit-linear-gradient(top, #F97B23 11%, #F97B23 100%);
    background-image: linear-gradient(180deg, #F97B23 11%, #F97B23 100%);
    background-position: inherit
}

.o_spondemo .one_click img {
    background-position: -94px -4390px
}

.o_spondemo .one_click.select {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #F97B23), to(#F97B23));
    background-image: -webkit-linear-gradient(top, #F97B23 11%, #F97B23 100%);
    background-image: linear-gradient(180deg, #F97B23 11%, #F97B23 100%);
    background-position: inherit
}

.o_spondemo .one_click.select img {
    background-position: -94px -4120px
}

.o_spondemo .one_click.select.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #EA6306), to(#F97A20));
    background-image: -webkit-linear-gradient(top, #EA6306 11%, #F97A20 100%);
    background-image: linear-gradient(180deg, #EA6306 11%, #F97A20 100%)
}

.o_spondemo .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #444), to(#707070));
    background-image: -webkit-linear-gradient(top, #444 11%, #707070 100%);
    background-image: linear-gradient(180deg, #444 11%, #707070 100%)
}

.o_spondemo .col-edit .btn-edit:hover {
    background-color: #E0E6E6
}

.o_spondemo .col-edit .btn-send:hover {
    background: #769EE3 url(../images/spdemo/bg-send_hover-spdemo.png) repeat-x
}

.o_spondemo .time_zone span {
    color: #e1e1e1
}

.o_spondemo .menu-list a:hover {
    color: #EB6307
}

.o_spondemo .path {
    background-repeat: repeat-x;
    background-position: 0 -6123px
}

.o_spondemo .path a {
    color: #eee
}

.o_spondemo .path .path-back {
    background-position: 0 -4075px;
    height: 25px;
    width: 36px
}

.o_spondemo .path .path-back:hover,
.o_spondemo .path .path-back.path-back-spdemo-hover {
    background-position: 0 -4100px
}

.o_spondemo .path .path-back.select {
    background-position: 0 -4100px;
    height: 25px;
    width: 36px
}

.o_spondemo .path-pop {
    background-color: #717171
}

.o_spondemo .path-pop a {
    color: #fff;
    background-position: -21px -2100px
}

.o_spondemo .slip-wrap h3 {
    background-repeat: repeat-x;
    background-position: 0 -6248px
}

.o_spondemo .slip-wrap .to-expand {
    background-position: 100% -274px
}

.o_spondemo .slip-wrap.close .to-expand {
    background-position: 100% -85px
}

.o_spondemo .slip-head {
    background-color: #ddd
}

.o_spondemo .matched-head {
    background-color: #fff
}

.o_spondemo .tips-popup {
    background-color: #F6F9BB
}

.o_spondemo .matched-wrap h3 {
    background-repeat: repeat-x;
    background-position: 0 -6248px
}

.o_spondemo .matched-wrap .to-expand {
    background-position: 100% -274px
}

.o_spondemo .matched-wrap.close .to-expand {
    background-position: 100% -85px
}

.o_spondemo .slip_refresh {
    background-position: -51px -569px;
    height: 25px;
    width: 29px
}

.o_spondemo .cashout-wrap .col-profit {
    border-color: #aaa
}

.o_spondemo .slider {
    border-color: #aaa
}

.o_spondemo .slider-bar span {
    border-color: #aaa;
    background-repeat: repeat-x;
    background-position: 0 -9922px
}

.o_spondemo .slider-bar a {
    background: url('../images/spdemo/btn-slider-spdemo.png') no-repeat
}

.o_spondemo .upcome-head {
    background-repeat: repeat-x;
    background-position: 0 -6248px
}

.o_spondemo .upcome-head a {
    color: #FFEDA4
}

.o_spondemo .game-wrap h3 {
    background-color: #717171;
    color: #fff
}

.o_spondemo .game-highlight-wrap h3 {
    background-color: #1E6EAB;
    color: #fff
}

.o_spondemo .game-highlight-wrap .slip-head {
    background-color: #ddd
}

.o_spondemo .tab-highlight {
    background: #1E6EAB;
    background-position: -284px -183px
}

.o_spondemo .tab-highlight a {
    color: #fff;
    background-position: -284px -183px
}

.o_spondemo .tab-highlight a.select {
    color: #1E1E1E;
    background-position: -284px -227px
}

.o_spondemo .success {
    background-color: #ade597
}

.o_spondemo .error {
    background-color: #ff645d
}

.o_spondemo .warning {
    background-color: #F9F9BB
}

.o_spondemo .game-wrap-horse h3 {
    background-color: #717171
}

.o_spondemo .tab-markets {
    background: transparent
}

.o_spondemo .tab-markets a.select {
    background-position: -284px -706px
}

.o_spondemo .add-pin h3 {
    background-position: -385px -1010px;
    height: 25px;
    width: 29px
}

.o_spondemo .add-pin h3:hover,
.o_spondemo .add-pin h3.btn-add-pin-spdemo-hover {
    background-position: -385px -985px
}

.o_spondemo .delete-pin h3 {
    background-position: -385px -1285px;
    height: 25px;
    width: 29px
}

.o_spondemo .delete-pin h3:hover,
.o_spondemo .delete-pin h3.btn-delete-pin-hover {
    background-position: -385px -1260px
}

.o_spondemo .multi_refresh {
    background-position: -51px -569px;
    height: 25px;
    width: 29px
}

.o_spondemo .one_click-setting {
    background-repeat: repeat-x;
    background-color: #ff7b21;
    background-position: 0 -5264px
}

.o_spondemo .one_click-setting a {
    border-color: #ab4800
}

.o_spondemo .one_click-stake {
    background-position: 0 -4620px;
    height: 31px;
    width: 414px
}

.o_spondemo .one_click-stake .btn,
.o_spondemo .one_click-stake .btn-send,
.o_spondemo .one_click-stake .pages a,
.pages .o_spondemo .one_click-stake a,
.o_spondemo .one_click-stake .calendarTable_inputBox input,
.calendarTable_inputBox .o_spondemo .one_click-stake input,
.o_spondemo .one_click-stake .calendarTable_inputBox textarea,
.calendarTable_inputBox .o_spondemo .one_click-stake textarea,
.o_spondemo .one_click-stake .calendarTable_inputBox .member_select a,
.calendarTable_inputBox .member_select .o_spondemo .one_click-stake a,
.o_spondemo .one_click-stake .member_select .calendarTable_inputBox a,
.member_select .calendarTable_inputBox .o_spondemo .one_click-stake a,
.o_spondemo .one_click-stake .total_all .btn_replay,
.total_all .o_spondemo .one_click-stake .btn_replay,
.o_spondemo .one_click-stake .btn_expand,
.o_spondemo .one_click-stake .add_member {
    color: #1E1E1E;
    border-color: #ab4800
}

.o_spondemo .one_click-stake .btn.select,
.o_spondemo .one_click-stake .select.btn-send,
.o_spondemo .one_click-stake .pages a.select,
.pages .o_spondemo .one_click-stake a.select,
.o_spondemo .one_click-stake .calendarTable_inputBox input.select,
.calendarTable_inputBox .o_spondemo .one_click-stake input.select,
.o_spondemo .one_click-stake .calendarTable_inputBox textarea.select,
.calendarTable_inputBox .o_spondemo .one_click-stake textarea.select,
.o_spondemo .one_click-stake .calendarTable_inputBox .member_select a.select,
.calendarTable_inputBox .member_select .o_spondemo .one_click-stake a.select,
.o_spondemo .one_click-stake .member_select .calendarTable_inputBox a.select,
.member_select .calendarTable_inputBox .o_spondemo .one_click-stake a.select,
.o_spondemo .one_click-stake .total_all .select.btn_replay,
.total_all .o_spondemo .one_click-stake .select.btn_replay,
.o_spondemo .one_click-stake .select.btn_expand,
.o_spondemo .one_click-stake .select.add_member {
    border-color: #717171
}

.o_spondemo .one_click-stake input,
.o_spondemo .one_click-stake textarea,
.o_spondemo .one_click-stake .member_select a,
.member_select .o_spondemo .one_click-stake a {
    color: #1E1E1E;
    border: 1px solid #717171;
    background-color: #e1e1e1;
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15)
}

.o_spondemo .one_click-stake input:focus,
.o_spondemo .one_click-stake textarea:focus,
.o_spondemo .one_click-stake .member_select a:focus,
.member_select .o_spondemo .one_click-stake a:focus {
    color: #000;
    border-color: #ab4800;
    background-color: #fff
}

.o_spondemo .profile-wrap h3 {
    background-color: #717171
}

.o_spondemo .go-back,
.o_spondemo .pop-close {
    color: #fff
}

.o_spondemo .tab {
    border-left-color: #1E6EAB
}

.o_spondemo .tab li {
    border-color: #1E6EAB;
    border-width: 1px 0
}

.o_spondemo .tab a {
    color: #1E6EAB;
    border-right-color: #1E6EAB
}

.o_spondemo .tab a:hover {
    text-decoration: underline
}

.o_spondemo .tab .select {
    color: #fff;
    background-color: #1E6EAB
}

.o_spondemo .tab .select:hover {
    text-decoration: none
}

.o_spondemo .head-balance dd {
    color: #1E6EAB
}

.o_spondemo .menu .logout,
.o_spondemo .setting-wrap .logout {
    border-left-width: 0
}

.o_spondemo .menu .logout img,
.o_spondemo .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.o_spondemo .submit-wrap .btn-send {
    background-color: #4E69A0
}

.o_spondemo .submit-wrap .btn-send span {
    background-color: #0f3654
}

.o_spondemo .submit-wrap .btn-send:hover {
    background-color: #769EE3
}

/* body.skyinplay{
    background-color:#EDEDED
}
.skyinplay .btn.select,.skyinplay .select.btn-send,.skyinplay .pages a.select,.pages .skyinplay a.select,.skyinplay .calendarTable_inputBox input.select,.calendarTable_inputBox .skyinplay input.select,.skyinplay .calendarTable_inputBox textarea.select,.calendarTable_inputBox .skyinplay textarea.select,.skyinplay .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .skyinplay a.select,.skyinplay .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .skyinplay a.select,.skyinplay .total_all .select.btn_replay,.total_all .skyinplay .select.btn_replay,.skyinplay .select.btn_expand,.skyinplay .select.add_member{
    color:#fff;
    background:#77A435 url(../images/skyinplay/btn-select-skyinplay.jpg) repeat-x;
    border-color:#265E04
}
.skyinplay .btn-send{
    background:#142240 url(../images/skyinplay/bg-send-skyinplay.png) repeat-x;
    border:1px solid #3A6EDE;
    border-radius:4px;
    color:#fff
}
.skyinplay .btn-send:hover{
    background:#1947AC url(../images/skyinplay/bg-send_hover-skyinplay.png) repeat-x
}
.skyinplay .btn-send.disable{
    background:#8F99AF url(../images/skyinplay/bg-send-disable-skyinplay.png) repeat-x;
    border-color:#A9C2F8;
    color:#BFD0EA
}
.skyinplay .login_to_go{
    background-color:#83AE17
}
.skyinplay .kv{
    background:#14213D url(../images/skyinplay/bg-login_wrap-skyinplay.png) no-repeat
}
.skyinplay .login-panel{
    color:#1E1E1E
}
.skyinplay .login-panel input,.skyinplay .login-panel textarea,.skyinplay .login-panel .member_select a,.member_select .skyinplay .login-panel a{
    border-color:#3E8142
}
.skyinplay .login-panel .error{
    color:#1E1E1E
}
.skyinplay .icon-login{
    background-position:0 -437px;
    height:12px;
    width:12px
}
.skyinplay .announce-header h1:before{
    background:#83AE17
}
.skyinplay .top{
    background:#14213D url(../images/skyinplay/bg-top-skyinplay.jpg) repeat-x
}
.skyinplay .header{
    color:#fff
}
.skyinplay .header h1{
    margin-top:-9px
}
.skyinplay .login-wrap .btn-login{
    background-image:-webkit-gradient(linear, left bottom, left top, from(#E28614), to(#FDB52D));
    background-image:-webkit-linear-gradient(bottom, #E28614 0%, #FDB52D 100%);
    background-image:linear-gradient(0deg, #E28614 0%, #FDB52D 100%)
}
.skyinplay .login-wrap li.user{
    background-position:-96px -5592px
}
.skyinplay .login-wrap .error{
    color:rgba(255,255,255,0.8)
}
.skyinplay .login-wrap .btn-signup{
    display:block;
    background-color:#555;
    background-image:-webkit-gradient(linear, left top, left bottom, from(#888), to(#555));
    background-image:-webkit-linear-gradient(top, #888 0%, #555 100%);
    background-image:linear-gradient(-180deg, #888 0%, #555 100%)
}
.skyinplay .account-wrap li .refresh-bar{
    background:url(../images/livesports/refresh80-livesports.gif) no-repeat center
}
.skyinplay .account-wrap .main-wallet.no-multi .red{
    padding:1px 3px;
    color:#fff !important;
    background-color:#BE0000;
    border-radius:3px
}
.skyinplay .account-wrap a{
    color:#fff
}
.skyinplay .account-wrap .main-wallet{
    border-color:rgba(0,0,0,0.6);
    background-color:#3e5588
}
.skyinplay .account-wrap .a-wallet .nums{
    border:1px solid #FFF
}
.skyinplay .account-wrap .loading-bar span{
    background:#fff
}
.skyinplay .account-wrap .a-refresh{
    background-color:#3e5588
}
.skyinplay .account-wrap .main-wallet.no-multi .a-refresh{
    border:1px solid rgba(0,0,0,0.7)
}
.skyinplay .account-wrap .head-refresh a{
    background-position:-80px -2100px;
    height:25px;
    width:30px
}
.skyinplay .account-wrap .account{
    background-position:0 -950px;
    height:25px;
    width:110px
}
.skyinplay .account-wrap ul a{
    color:#1E1E1E
}
.skyinplay .account-wrap .logout a{
    color:#fff
}
.skyinplay .menu-wrap{
    background-image:-webkit-linear-gradient(89deg, #61A123 3%, #B3CE60 99%);
    background-image:linear-gradient(1deg, #61A123 3%, #B3CE60 99%);
    border:1px solid #417505
}
.skyinplay .menu-wrap a.menu-drop:after{
    border-top-color:#333
}
.skyinplay .menu-wrap a.select.menu-drop:after{
    border-top-color:#FFF;
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(20%, #588D23), to(#609F22));
    background-image:-webkit-linear-gradient(top, #588D23 20%, #609F22 100%);
    background-image:linear-gradient(180deg, #588D23 20%, #609F22 100%);
    box-shadow:inset 0 1px 3px 0 rgba(6,23,7,0.5)
}
.skyinplay .menu li,.skyinplay .setting-wrap li{
    border-right-color:rgba(9,68,16,0.3)
}
.skyinplay .menu li ul a,.skyinplay .setting-wrap li ul a{
    color:#fff
}
.skyinplay .menu a,.skyinplay .setting-wrap a{
    color:#333
}
.skyinplay .menu a:hover,.skyinplay .setting-wrap a:hover{
    background-color:transparent;
    text-decoration:underline
}
.skyinplay .menu .select,.skyinplay .setting-wrap .select{
    color:#fff;
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(20%, #588D23), to(#609F22));
    background-image:-webkit-linear-gradient(top, #588D23 20%, #609F22 100%);
    background-image:linear-gradient(180deg, #588D23 20%, #609F22 100%);
    box-shadow:inset 0 1px 3px 0 rgba(6,23,7,0.5)
}
.skyinplay .menu .logout,.skyinplay .setting-wrap .logout{
    border-left-width:0
}
.skyinplay .menu .logout img,.skyinplay .setting-wrap .logout img{
    background:url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23333" fill-rule="evenodd"/></svg>') no-repeat
}
.skyinplay .menu .tag-new,.skyinplay .setting-wrap .tag-new{
    color:#FFFFFF
}
.skyinplay .live-menu{
    border-left-color:rgba(9,68,16,0.2)
}
.skyinplay .live-menu img{
    background-position:-89px -4656px
}
.skyinplay .setting{
    border-left-color:rgba(9,68,16,0.2)
}
.skyinplay .setting img{
    background:url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23333" fill-rule="evenodd"/></svg>') no-repeat
}
.skyinplay .one_click{
    border-top-color:#F98181;
    color:#fff !important;
    background-image:-webkit-gradient(linear, left top, left bottom, from(#374F82), to(#2B3E65));
    background-image:-webkit-linear-gradient(top, #374F82 0%, #2B3E65 100%);
    background-image:linear-gradient(180deg, #374F82 0%, #2B3E65 100%);
    background-position:inherit
}
.skyinplay .one_click img{
    background-position:-94px -4300px
}
.skyinplay .one_click.select{
    color:#fff;
    box-shadow:inset 0 2px 3px -2px rgba(0,0,0,0.4);
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(18%, #E05951), to(#B0463F));
    background-image:-webkit-linear-gradient(top, #E05951 18%, #B0463F 100%);
    background-image:linear-gradient(180deg, #E05951 18%, #B0463F 100%);
    background-position:inherit
}
.skyinplay .one_click.select img{
    background-position:-94px -4270px
}
.skyinplay .one_click.select:hover{
    background-image:-webkit-gradient(linear, left top, left bottom, color-stop(18%, #B0463F), to(#E05951));
    background-image:-webkit-linear-gradient(top, #B0463F 18%, #E05951 100%);
    background-image:linear-gradient(180deg, #B0463F 18%, #E05951 100%)
}
.skyinplay .one_click:hover{
    background-image:-webkit-gradient(linear, left top, left bottom, from(#2B3E65), to(#374F82));
    background-image:-webkit-linear-gradient(top, #2B3E65 0%, #374F82 100%);
    background-image:linear-gradient(180deg, #2B3E65 0%, #374F82 100%)
}
.skyinplay .time_zone{
    color:#333333
}
.skyinplay .time_zone span{
    color:rgba(51,51,51,0.5)
}
.skyinplay .menu-list li{
    border-bottom-color:#D2D6E2
}
.skyinplay .menu-list a{
    color:#0E2E75
}
.skyinplay .menu-list a:hover{
    background-color:#E6EFD1
}
.skyinplay .menu-list .select{
    background-color:#D1DDEF;
    color:#1E1E1E
}
.skyinplay .menu-list .select:hover{
    background-color:#D1DDEF
}
.skyinplay .menu-list .Go{
    background-position:100% -1700px;
    height:25px;
    width:23px
}
.skyinplay .menu-list .Go:hover,.skyinplay .menu-list .Go.Go-skyinplay-hover{
    background-position:100% -1725px
}
.skyinplay .path{
    background-repeat:repeat-x;
    background-position:0 -6098px
}
.skyinplay .path a{
    color:#B7F875
}
.skyinplay .path .path-back{
    background-position:0 -4025px
}
.skyinplay .path .path-back:hover,.skyinplay .path .path-back.path-back-skyinplay-hover{
    background-position:0 -4050px
}
.skyinplay .path .path-back.select{
    background-position:0 -4050px
}
.skyinplay .path-pop{
    background-color:#14213D
}
.skyinplay .path-pop a{
    color:#B7F875
}
.skyinplay .game-highlight-wrap h3{
    background-color:#99C04D
}
.skyinplay .tab-highlight{
    background-repeat:repeat-x;
    background-position:0 -10677px
}
.skyinplay .tab-markets{
    background:transparent
}
.skyinplay .one_click-setting{
    color:#fff;
    background-repeat:repeat-x;
    background-color:#A4413B;
    background-position:0 -5233px
}
.skyinplay .one_click-setting a{
    border-color:#7D3232
}
.skyinplay .one_click-setting .a-edit{
    color:#fff
}
.skyinplay .one_click-setting .a-edit img{
    background-position:-405px -2269px;
    height:9px;
    width:9px
}
.skyinplay .one_click-stake{
    background-position:0 -4589px;
    height:31px;
    width:414px
}
.skyinplay .one_click-stake input,.skyinplay .one_click-stake textarea,.skyinplay .one_click-stake .member_select a,.member_select .skyinplay .one_click-stake a{
    border-color:#7D3232;
    color:#1E1E1E;
    background-color:#C37E81
}
.skyinplay .one_click-stake input:focus,.skyinplay .one_click-stake textarea:focus,.skyinplay .one_click-stake .member_select a:focus,.member_select .skyinplay .one_click-stake a:focus{
    border-color:#113687;
    background-color:#F4DDDB
}
.skyinplay .function-wrap{
    background-color:#D5E1E6
}
.skyinplay .submit-wrap .btn-send span{
    background-color:#EB5E55
} */
body.bigexch {
    background-color: #E1E3E1
}

/* .bigexch .btn.select,.bigexch .select.btn-send,.bigexch .pages a.select,.pages .bigexch a.select,.bigexch .calendarTable_inputBox input.select,.calendarTable_inputBox .bigexch input.select,.bigexch .calendarTable_inputBox textarea.select,.calendarTable_inputBox .bigexch textarea.select,.bigexch .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .bigexch a.select,.bigexch .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .bigexch a.select,.bigexch .total_all .select.btn_replay,.total_all .bigexch .select.btn_replay,.bigexch .select.btn_expand,.bigexch .select.add_member{
    color:#fff;
    background:#C97608 url(../images/bigEXch/btn-select-bigEXch.jpg) repeat-x;
    border-color:#764B11
}
.bigexch .btn-send{
    background:#466D04 url(../images/bigEXch/bg-send-bigEXch.png) repeat-x;
    border:1px solid #7BBE09;
    border-radius:4px;
    color:#fff
} */
/* .bigexch .btn-send:hover{
    background:#8FBE17 url(../images/bigEXch/bg-send_hover-bigEXch.png) repeat-x
}
.bigexch .btn-send.disable{
    background:#A3B782 url(../images/bigEXch/bg-send-disable-bigEXch.png) repeat-x;
    border-color:#C4D5A6;
    color:#D5E9CC
}
.bigexch .login_to_go{
    background-color:#fff
}
.bigexch .kv{
    background:#14213D url(../images/bigEXch/bg-login_wrap-bigEXch.png) no-repeat
}
.bigexch .login-panel{
    color:#1E1E1E
} */
.bigexch .login-panel input,
.bigexch .login-panel textarea,
.bigexch .login-panel .member_select a,
.member_select .bigexch .login-panel a {
    border-color: #3E8142
}

.bigexch .login-panel .error {
    color: #1E1E1E
}

.bigexch .icon-login {
    background-position: 0 -220px;
    height: 12px;
    width: 12px
}

.bigexch .announce-header h1:before {
    background: #83AE17
}

/* .bigexch .top{
    background:#000 url(../images/bigEXch/bg-top-bigEXch.jpg) repeat-x
} */
.bigexch .header {
    color: #fff
}

.bigexch .header h1 {
    margin-top: -7px
}

.bigexch .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#E28614), to(#FDB52D));
    background-image: -webkit-linear-gradient(bottom, #E28614 0%, #FDB52D 100%);
    background-image: linear-gradient(0deg, #E28614 0%, #FDB52D 100%)
}

.bigexch .login-wrap li.user {
    background-position: -96px -5117px
}

.bigexch .login-wrap .error {
    color: #83AE17
}

.bigexch .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.bigexch .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.bigexch .account-wrap a {
    color: #fff
}

.bigexch .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
    background-color: #34392f
}

.bigexch .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.bigexch .account-wrap .loading-bar span {
    background: #fff
}

.bigexch .account-wrap .loading-bar,
.bigexch .account-wrap .a-refresh {
    background-color: #34392f
}

.bigexch .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.bigexch .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.8)
}

.bigexch .account-wrap .head-refresh a {
    background-position: -80px -1450px;
    height: 25px;
    width: 30px
}

.bigexch .account-wrap .account {
    background-position: 0 -300px;
    height: 25px;
    width: 110px
}

.bigexch .account-wrap ul a {
    color: #1E1E1E
}

.bigexch .account-wrap .logout a {
    color: #fff
}

.bigexch .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1F3C01), to(#3F7607));
    background-image: -webkit-linear-gradient(top, #1F3C01 0%, #3F7607 100%);
    background-image: linear-gradient(180deg, #1F3C01 0%, #3F7607 100%);
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2);
    background-position: inherit
}

.bigexch .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.bigexch .menu li,
.bigexch .setting-wrap li {
    border-right-color: rgba(218, 255, 116, 0.2)
}

.bigexch .menu li ul a,
.bigexch .setting-wrap li ul a {
    color: #fff
}

.bigexch .menu a,
.bigexch .setting-wrap a {
    color: #fff
}

.bigexch .menu a:hover,
.bigexch .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.bigexch .menu .select,
.bigexch .setting-wrap .select {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#83AE17), to(#4E7B08));
    background-image: -webkit-linear-gradient(top, #83AE17 0%, #4E7B08 100%);
    background-image: linear-gradient(180deg, #83AE17 0%, #4E7B08 100%)
}

.bigexch .menu .logout,
.bigexch .setting-wrap .logout {
    border-left-width: 0
}

.bigexch .menu .logout img,
.bigexch .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.bigexch .live-menu {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.bigexch .live-menu img {
    background-position: -89px -4512px
}

.bigexch .setting {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.bigexch .one_click {
    border-top-color: #C8B810;
    color: #fff !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #32372C), to(#272B23));
    background-image: -webkit-linear-gradient(top, #32372C 12%, #272B23 100%);
    background-image: linear-gradient(180deg, #32372C 12%, #272B23 100%);
    background-position: inherit
}

.bigexch .one_click img {
    background-position: -94px -2950px
}

.bigexch .one_click.select {
    color: #fff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #479C88), to(#377969));
    background-image: -webkit-linear-gradient(top, #479C88 11%, #377969 100%);
    background-image: linear-gradient(180deg, #479C88 11%, #377969 100%);
    background-position: inherit
}

.bigexch .one_click.select img {
    background-position: -94px -2920px
}

.bigexch .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #377969), to(#479C88));
    background-image: -webkit-linear-gradient(top, #377969 11%, #479C88 100%);
    background-image: linear-gradient(180deg, #377969 11%, #479C88 100%)
}

.bigexch .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #272B23), to(#32372C));
    background-image: -webkit-linear-gradient(top, #272B23 12%, #32372C 100%);
    background-image: linear-gradient(180deg, #272B23 12%, #32372C 100%)
}

.bigexch .time_zone {
    color: white
}

.bigexch .time_zone span {
    color: rgba(255, 255, 255, 0.5)
}

.bigexch .menu-list li {
    border-bottom-color: #D2D6E2
}

.bigexch .menu-list a {
    color: #000
}

.bigexch .menu-list a:hover {
    background-color: #E6EFD1
}

.bigexch .menu-list .Go {
    background-position: 100% -500px;
    height: 25px;
    width: 23px
}

.bigexch .menu-list .Go:hover,
.bigexch .menu-list .Go.Go-bigEXch-hover {
    background-position: 100% -525px
}

.bigexch .path {
    background-repeat: repeat-x;
    background-position: 0 -5673px
}

.bigexch .path .path-back {
    background-position: 0 -2775px
}

.bigexch .path .path-back:hover,
.bigexch .path .path-back.path-back-bigEXch-hover {
    background-position: 0 -2800px
}

.bigexch .path .path-back.select {
    background-position: 0 -2800px
}

.bigexch .path-pop {
    background-color: #000
}

.bigexch .game-highlight-wrap h3 {
    background-color: #CB7808;
    color: #fff
}

.bigexch .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10215px
}

.bigexch .tab-markets {
    background: transparent
}

.bigexch .one_click-setting {
    color: #fff;
    background-repeat: repeat-x;
    background-color: #A4413B;
    background-position: 0 -4483px
}

.bigexch .one_click-setting a {
    border-color: #244A3E
}

.bigexch .one_click-setting .a-edit {
    color: #fff
}

.bigexch .one_click-setting .a-edit img {
    background-position: -405px -2089px;
    height: 9px;
    width: 9px
}

.bigexch .one_click-stake {
    background-position: 0 -3839px;
    height: 31px;
    width: 414px
}

.bigexch .one_click-stake input,
.bigexch .one_click-stake textarea,
.bigexch .one_click-stake .member_select a,
.member_select .bigexch .one_click-stake a {
    border-color: #244A3E;
    color: #1E1E1E;
    background-color: #E9DC76
}

.bigexch .one_click-stake input:focus,
.bigexch .one_click-stake textarea:focus,
.bigexch .one_click-stake .member_select a:focus,
.member_select .bigexch .one_click-stake a:focus {
    border-color: #8F3B27;
    background-color: #F4F4DB
}

.bigexch .function-wrap {
    background-color: #E7EFEF
}

.bigexch .submit-wrap .btn-send span {
    background-color: #1D3900
}

body.masterexchange {
    background-color: #E9E9E9
}

/* .masterexchange .btn.select,.masterexchange .select.btn-send,.masterexchange .pages a.select,.pages .masterexchange a.select,.masterexchange .calendarTable_inputBox input.select,.calendarTable_inputBox .masterexchange input.select,.masterexchange .calendarTable_inputBox textarea.select,.calendarTable_inputBox .masterexchange textarea.select,.masterexchange .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .masterexchange a.select,.masterexchange .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .masterexchange a.select,.masterexchange .total_all .select.btn_replay,.total_all .masterexchange .select.btn_replay,.masterexchange .select.btn_expand,.masterexchange .select.add_member{
    color:#1E1E1E;
    background:#F4D218 url(../images/masterexc/btn-select-masterexc.png) repeat-x;
    border-color:#6B5F02
}
.masterexchange .btn-send{
    background:#BAA012 url(../images/masterexc/bg-send-masterexc.png) repeat-x;
    border:1px solid #6B5F02;
    border-radius:4px;
    color:#1E1E1E
}
.masterexchange .btn-send:hover{
    background:#F4D218 url(../images/masterexc/bg-send_hover-masterexc.png) repeat-x
}
.masterexchange .btn-send.disable{
    background:#E0D289 url(../images/masterexc/bg-send-disable-masterexc.png) repeat-x;
    border-color:#B5AF81;
    color:#93928F
} */
.masterexchange .login_to_go {
    background-color: #216869
}

.masterexchange .login_to_go .close {
    opacity: 0.8;
    background-position: -394px -1160px;
    height: 20px;
    width: 20px
}

/* .masterexchange .kv{
    background:#1C303C url(../images/masterexc/bg-login_wrap-masterexc.png) no-repeat
} */
.masterexchange .login-panel {
    color: #fff
}

.masterexchange .login-panel input,
.masterexchange .login-panel textarea,
.masterexchange .login-panel .member_select a,
.member_select .masterexchange .login-panel a {
    border-color: #aaa
}

.masterexchange .login-panel .error {
    color: #fff
}

.masterexchange .change_pass dt {
    color: #1E1E1E
}

.masterexchange .icon-login {
    background-position: 0 -112px;
    height: 12px;
    width: 12px
}

.masterexchange .announce-header h1:before {
    background: #216869
}

/* .masterexchange .top{
    background:#E3E3E3 url(../images/masterexc/bg-top-masterexc.jpg) repeat-x
} */
.masterexchange .header {
    color: #1E1E1E
}

.masterexchange .header h1 {
    margin-top: -8px
}

.masterexchange .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#9D2F0B), to(#C83C10));
    background-image: -webkit-linear-gradient(bottom, #9D2F0B 0%, #C83C10 100%);
    background-image: linear-gradient(0deg, #9D2F0B 0%, #C83C10 100%)
}

.masterexchange .login-wrap li.user {
    background-position: -96px -5367px
}

.masterexchange .login-wrap .error {
    color: #BE0000
}

.masterexchange .account-wrap li .refresh-bar {
    background: url(../images/refresh80.gif) no-repeat center
}

.masterexchange .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #BE0000 !important
}

.masterexchange .account-wrap a {
    color: #1E1E1E
}

.masterexchange .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
    background-color: #e8e8e8
}

.masterexchange .account-wrap .a-wallet .nums {
    border: 1px solid rgba(0, 0, 0, 0.8)
}

.masterexchange .account-wrap .a-wallet.open {
    background-color: rgba(0, 0, 0, 0.05) !important;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.3)
}

.masterexchange .account-wrap .loading-bar,
.masterexchange .account-wrap .a-refresh {
    background-color: #e8e8e8
}

.masterexchange .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.8)
}

.masterexchange .account-wrap .head-refresh a {
    background-position: -80px -1800px;
    height: 25px;
    width: 30px
}

.masterexchange .account-wrap .account {
    background-position: 0 -650px;
    height: 25px;
    width: 110px
}

.masterexchange .account-wrap ul a {
    color: #1E1E1E
}

.masterexchange .account-wrap .logout a {
    color: #fff
}

.masterexchange .search-input {
    border: 1px solid #373737;
    box-shadow: none
}

.masterexchange .suggestion-wrap {
    border: 1px solid #373737;
    border-top: 1px solid #AAAAAA
}

.masterexchange .menu-wrap {
    background-repeat: repeat-x;
    background-image: -webkit-linear-gradient(271deg, #216869 3%, #216869 100%);
    background-image: linear-gradient(179deg, #216869 3%, #216869 100%);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.4)
}

.masterexchange .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.masterexchange .menu li,
.masterexchange .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.2)
}

.masterexchange .menu li ul a,
.masterexchange .setting-wrap li ul a {
    color: #fff
}

.masterexchange .menu a,
.masterexchange .setting-wrap a {
    color: #fff
}

.masterexchange .menu a:hover,
.masterexchange .setting-wrap a:hover {
    background-color: rgba(0, 0, 0, 0.3);
    text-decoration: underline
}

.masterexchange .menu .select,
.masterexchange .setting-wrap .select {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff
}

.masterexchange .menu .logout,
.masterexchange .setting-wrap .logout {
    border-left-width: 0
}

.masterexchange .menu .logout img,
.masterexchange .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.masterexchange .live-menu {
    border-left-color: rgba(9, 68, 16, 0.2)
}

.masterexchange .live-menu img {
    background-position: -89px -4674px
}

.masterexchange .setting {
    border-left-color: rgba(255, 255, 255, 0.2)
}

.masterexchange .one_click {
    border-top-color: #F4D218;
    background-repeat: repeat-x;
    color: #F4D218 !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4C4C4C), to(#303030));
    background-image: -webkit-linear-gradient(top, #4C4C4C 0%, #303030 100%);
    background-image: linear-gradient(180deg, #4C4C4C 0%, #303030 100%);
    background-position: inherit
}

.masterexchange .one_click img {
    background-position: -94px -3730px
}

.masterexchange .one_click.select {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#052F47), to(#031926));
    background-image: -webkit-linear-gradient(top, #052F47 0%, #031926 100%);
    background-image: linear-gradient(180deg, #052F47 0%, #031926 100%);
    background-position: inherit
}

.masterexchange .one_click.select img {
    background-position: -94px -3700px
}

.masterexchange .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#031926), to(#052F47));
    background-image: -webkit-linear-gradient(top, #031926 0%, #052F47 100%);
    background-image: linear-gradient(180deg, #031926 0%, #052F47 100%)
}

.masterexchange .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#303030), to(#4C4C4C));
    background-image: -webkit-linear-gradient(top, #303030 0%, #4C4C4C 100%);
    background-image: linear-gradient(180deg, #303030 0%, #4C4C4C 100%)
}

.masterexchange .time_zone {
    color: white
}

.masterexchange .time_zone span {
    color: rgba(255, 255, 255, 0.5)
}

.masterexchange .menu-list li {
    background: #FFFFFF;
    border-bottom-color: #CCCCCC
}

.masterexchange .menu-list a {
    color: #216869
}

.masterexchange .menu-list a:hover {
    background-color: #216869;
    color: #FFFFFF
}

.masterexchange .menu-list .select {
    background-color: #216869;
    color: #FFFFFF
}

.masterexchange .menu-list .select:hover {
    background-color: #216869
}

.masterexchange .menu-list .Go {
    background-position: 100% -1150px;
    height: 25px;
    width: 23px
}

.masterexchange .menu-list .Go:hover,
.masterexchange .menu-list .Go.Go-masterexc-hover {
    background-position: 100% -1175px
}

.masterexchange .menu-list .class {
    background: #243A48
}

.masterexchange .sub_path {
    background: #FFFFFF
}

.masterexchange .path {
    background-repeat: repeat-x;
    background-position: 0 -5898px
}

.masterexchange .path a {
    color: #FFFFFF
}

.masterexchange .path .path-back {
    background-position: 0 -3475px
}

.masterexchange .path .path-back:hover,
.masterexchange .path .path-back.path-back-masterexc-hover {
    background-position: 0 -3500px
}

.masterexchange .path .path-back.select {
    background-position: 0 -3500px
}

.masterexchange .path-pop {
    background-color: 8.03922%
}

.masterexchange .path-pop a {
    color: #FFFFFF;
    background-position: -21px -2125px
}

.masterexchange .game-highlight-wrap h3 {
    color: #fff;
    background-color: #216869
}

.masterexchange .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10457px
}

.masterexchange .tab-markets {
    background: transparent
}

.masterexchange .one_click-setting {
    color: #F4D218;
    background-repeat: repeat-x;
    background-color: #1C2E39;
    background-position: 0 -4921px
}

.masterexchange .one_click-setting a {
    border-color: #F4D218
}

.masterexchange .one_click-setting .a-edit {
    color: #F4D218
}

.masterexchange .one_click-setting .a-edit img {
    background-position: -405px -2188px;
    height: 9px;
    width: 9px
}

.masterexchange .one_click-stake {
    background-position: 0 -4277px;
    height: 31px;
    width: 414px
}

.masterexchange .one_click-stake input,
.masterexchange .one_click-stake textarea,
.masterexchange .one_click-stake .member_select a,
.member_select .masterexchange .one_click-stake a {
    border-color: #929292;
    color: #fff;
    background-color: #535E5B
}

.masterexchange .one_click-stake input:focus,
.masterexchange .one_click-stake textarea:focus,
.masterexchange .one_click-stake .member_select a:focus,
.member_select .masterexchange .one_click-stake a:focus {
    border-color: #F4D218;
    color: #F4D218;
    background-color: #3E4529
}

.masterexchange .function-wrap {
    background-color: #D5E1E6
}

.masterexchange .submit-wrap .btn-send span {
    background-color: #216869
}

body.matador {
    background-color: #FFFFFF
}

/* .matador .btn.select,.matador .select.btn-send,.matador .pages a.select,.pages .matador a.select,.matador .calendarTable_inputBox input.select,.calendarTable_inputBox .matador input.select,.matador .calendarTable_inputBox textarea.select,.calendarTable_inputBox .matador textarea.select,.matador .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .matador a.select,.matador .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .matador a.select,.matador .total_all .select.btn_replay,.total_all .matador .select.btn_replay,.matador .select.btn_expand,.matador .select.add_member{
    color:#FFFFFF;
    background:#C10A31 url(../images/matador/btn-select-matador.png) repeat-x;
    border-color:#860A22
}
.matador .btn-send{
    background:#C10A31 url(../images/matador/bg-send-matador.png) repeat-x;
    border-color:transparent;
    border-radius:4px;
    color:#FFFFFF
}
.matador .btn-send:hover{
    background:#FF0032 url(../images/matador/bg-send_hover-matador.png) repeat-x
}
.matador .btn-send.disable{
    background:#FF9AAE url(../images/matador/bg-send-disable-matador.png) repeat-x;
    border-color:transparent;
    color:#FFFFFF
}
.matador .login_to_go{
    background-color:#FFFFFF
}
.matador .kv{
    background:#C10A31 url(../images/matador/bg-login_wrap-matador.png) no-repeat
} */
.matador .login-panel {
    color: #570616
}

.matador .login-panel input,
.matador .login-panel textarea,
.matador .login-panel .member_select a,
.member_select .matador .login-panel a {
    border-color: #aaa
}

.matador .login-panel .error {
    color: #9B9B9B
}

.matador .change_pass dt {
    color: #1E1E1E
}

.matador .announce-header h1:before {
    background: #C10A31
}

/* .matador .top{
    background:#C10A31 url(../images/matador/bg-top-matador.jpg) repeat-x
} */
.matador .header {
    color: #FFFFFF
}

.matador .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#D9D9D9), to(#EBEBEB));
    background-image: -webkit-linear-gradient(bottom, #D9D9D9 0%, #EBEBEB 100%);
    background-image: linear-gradient(0deg, #D9D9D9 0%, #EBEBEB 100%);
    color: #41000D
}

.matador .login-wrap .btn-login img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%2341000D" fill-rule="evenodd"/></svg>') no-repeat
}

.matador .login-wrap li.user {
    background-position: -96px -4867px
}

.matador .login-wrap .error {
    color: #FF9AAE
}

.matador .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.matador .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #C10A31 !important;
    background: #FFFFFF;
    border-radius: 3px
}

.matador .account-wrap a {
    color: #FFFFFF
}

.matador .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4);
    background-color: #c10a31
}

.matador .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.matador .account-wrap .loading-bar span {
    background: #fff
}

.matador .account-wrap .loading-bar,
.matador .account-wrap .a-refresh {
    background-color: #c10a31
}

.matador .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.matador .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.6)
}

.matador .account-wrap .head-refresh a {
    background-position: -80px -1825px;
    height: 25px;
    width: 30px
}

.matador .account-wrap .account {
    background-position: 0 -675px;
    height: 25px;
    width: 110px
}

.matador .account-wrap ul a {
    color: #1E1E1E
}

.matador .account-wrap .logout a {
    color: #fff
}

.matador .menu-wrap {
    background-image: -webkit-linear-gradient(271deg, #4B0412 0%, #7E081F 84%);
    background-image: linear-gradient(179deg, #4B0412 0%, #7E081F 84%);
    background-position: inherit
}

.matador .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.matador .menu li,
.matador .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.2)
}

.matador .menu li ul a,
.matador .setting-wrap li ul a {
    color: #fff
}

.matador .menu a,
.matador .setting-wrap a {
    color: #fff
}

.matador .menu a:hover,
.matador .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.matador .menu .select,
.matador .setting-wrap .select {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#000), color-stop(23%, #1D0207), to(#620919));
    background-image: -webkit-linear-gradient(top, #000 0%, #1D0207 23%, #620919 100%);
    background-image: linear-gradient(180deg, #000 0%, #1D0207 23%, #620919 100%)
}

.matador .menu .logout,
.matador .setting-wrap .logout {
    border-left-width: 0
}

.matador .menu .logout img,
.matador .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.matador .live-menu {
    border-left-color: #570616
}

.matador .live-menu img {
    background-position: -89px -4656px
}

.matador .setting {
    border-left-color: rgba(255, 255, 255, 0.2)
}

.matador .one_click {
    border-top-color: #FFFFFF;
    background-repeat: repeat-x;
    color: #41000D !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E7E7E7), to(#dcdcdc));
    background-image: -webkit-linear-gradient(top, #E7E7E7 0%, #dcdcdc 100%);
    background-image: linear-gradient(180deg, #E7E7E7 0%, #dcdcdc 100%);
    background-position: inherit
}

.matador .one_click img {
    background-position: -94px -3790px
}

.matador .one_click.select {
    color: #41000D;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BEBEBE), to(#ddd));
    background-image: -webkit-linear-gradient(top, #BEBEBE 0%, #ddd 100%);
    background-image: linear-gradient(180deg, #BEBEBE 0%, #ddd 100%);
    background-position: inherit
}

.matador .one_click.select img {
    background-position: -94px -3760px
}

.matador .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ddd), to(#BEBEBE));
    background-image: -webkit-linear-gradient(top, #ddd 0%, #BEBEBE 100%);
    background-image: linear-gradient(180deg, #ddd 0%, #BEBEBE 100%)
}

.matador .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#dcdcdc), to(#E7E7E7));
    background-image: -webkit-linear-gradient(top, #dcdcdc 0%, #E7E7E7 100%);
    background-image: linear-gradient(180deg, #dcdcdc 0%, #E7E7E7 100%)
}

.matador .time_zone {
    color: white
}

.matador .time_zone span {
    color: rgba(255, 255, 255, 0.5)
}

.matador .menu-list li {
    border-bottom-color: #DDDDDD
}

.matador .menu-list a {
    color: #860A22
}

.matador .menu-list a:hover {
    background-color: #860A22;
    color: #FFFFFF
}

.matador .menu-list .select {
    background-color: #860A22;
    color: #FFFFFF
}

.matador .menu-list .select:hover {
    background-color: #860A22
}

.matador .menu-list .Go {
    background-position: 100% -1200px;
    height: 25px;
    width: 23px
}

.matador .menu-list .Go:hover,
.matador .menu-list .Go.Go-matador-hover {
    background-position: 100% -1225px
}

.matador .sub_path {
    background: #FDFDFD
}

.matador .path {
    background: #ECECEC
}

.matador .path li {
    background-position: 100% -1850px
}

.matador .path a {
    color: #41000D
}

.matador .path .path-last {
    background-image: none
}

.matador .path .path-back {
    background-position: 0 -3525px
}

.matador .path .path-back:hover,
.matador .path .path-back.path-back-matador-hover {
    background-position: 0 -3550px
}

.matador .path .path-back.select {
    background-position: 0 -3550px
}

.matador .path-pop {
    background-color: #A5A5A5
}

.matador .path-pop a {
    color: #FFFFFF;
    background-position: -21px -2100px
}

.matador .game-highlight-wrap h3 {
    color: #fff;
    background-color: #C10A31
}

.matador .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10479px
}

.matador .tab-markets {
    background: transparent
}

.matador .one_click-setting {
    color: #41000D;
    background-repeat: repeat-x;
    background-color: #ECECEC;
    background-position: 0 -4952px
}

.matador .one_click-setting a {
    border-color: #C10A31
}

.matador .one_click-setting .a-edit {
    color: #C10A31
}

.matador .one_click-setting .a-edit img {
    background-position: -405px -2197px;
    height: 9px;
    width: 9px
}

.matador .one_click-stake {
    background-position: 0 -4308px;
    height: 32px;
    width: 414px
}

.matador .one_click-stake input,
.matador .one_click-stake textarea,
.matador .one_click-stake .member_select a,
.member_select .matador .one_click-stake a {
    border-color: #9B9B9B;
    color: #fff;
    background-color: #777777
}

.matador .one_click-stake input:focus,
.matador .one_click-stake textarea:focus,
.matador .one_click-stake .member_select a:focus,
.member_select .matador .one_click-stake a:focus {
    border-color: #C10A31;
    color: #fff;
    background-color: #333333
}

.matador .function-wrap {
    background-color: #D5E1E6
}

.matador .submit-wrap .btn-send span {
    background-color: #860A22
}

html {
    height: 100%
}

body.fairenter {
    background-color: #E5E5DF
}

/* .fairenter .btn.select,.fairenter .select.btn-send,.fairenter .pages a.select,.pages .fairenter a.select,.fairenter .calendarTable_inputBox input.select,.calendarTable_inputBox .fairenter input.select,.fairenter .calendarTable_inputBox textarea.select,.calendarTable_inputBox .fairenter textarea.select,.fairenter .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .fairenter a.select,.fairenter .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .fairenter a.select,.fairenter .total_all .select.btn_replay,.total_all .fairenter .select.btn_replay,.fairenter .select.btn_expand,.fairenter .select.add_member{
    background:#F2D65D url(../images/fairenter/btn-select-fairenter.jpg) repeat-x;
    border-color:#897700
}
.fairenter .btn-send{
    background:#BD9406 url(../images/fairenter/bg-send-fairenter.png) repeat-x;
    border-color:#897700
}
.fairenter .btn-send:hover{
    background:#EAC535 url(../images/fairenter/bg-send_hover-fairenter.png) repeat-x
}
.fairenter .btn-send.disable{
    background:#EBDEB4 url(../images/fairenter/bg-send-disable-fairenter.png) repeat-x;
    border-color:#A2B4A7
}
.fairenter .kv{
    background:#122343 url(../images/fairenter/bg-login_wrap-fairenter.jpg) no-repeat
} */
.fairenter .icon-login {
    background-position: 0 -280px;
    height: 12px;
    width: 12px
}

/* .fairenter .top{
    background:#C4A73F url(../images/fairenter/bg-top-fairenter.jpg) repeat-x
} */
.fairenter .header h1 {
    margin-top: -9px
}

.fairenter .login-wrap .error {
    color: #024168
}

.fairenter .login-wrap li.user {
    background-position: -96px -5242px
}

.fairenter .account-wrap .head-refresh a {
    background-position: -80px -1600px;
    height: 25px;
    width: 30px
}

.fairenter .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.4)
}

.fairenter .account-wrap .account {
    background-position: 0 -450px;
    height: 25px;
    width: 110px
}

.fairenter .menu-wrap {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#162231), color-stop(70%, #23354B));
    background-image: -webkit-linear-gradient(bottom, #162231 0%, #23354B 70%);
    background-image: linear-gradient(0deg, #162231 0%, #23354B 70%);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.4)
}

.fairenter .menu li,
.fairenter .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.fairenter .menu li ul,
.fairenter .setting-wrap li ul {
    background-color: #22354A
}

.fairenter .menu li li,
.fairenter .setting-wrap li li {
    border-bottom-color: #466071
}

.fairenter .menu a:hover,
.fairenter .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.fairenter .menu .select,
.fairenter .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.fairenter .setting {
    border-left-color: rgba(255, 255, 255, 0.1)
}

.fairenter .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.fairenter .one_click {
    border-top-color: #D56525;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #2D4561), to(#1E2E41));
    background-image: -webkit-linear-gradient(top, #2D4561 12%, #1E2E41 100%);
    background-image: linear-gradient(180deg, #2D4561 12%, #1E2E41 100%);
    background-position: inherit
}

.fairenter .one_click img {
    background-position: -94px -3310px
}

.fairenter .one_click.select {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #B83A08), color-stop(77%, #912B06));
    background-image: -webkit-linear-gradient(top, #B83A08 10%, #912B06 77%);
    background-image: linear-gradient(180deg, #B83A08 10%, #912B06 77%);
    background-position: inherit
}

.fairenter .one_click.select img {
    background-position: -94px -3280px
}

.fairenter .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #912B06), color-stop(77%, #B83A08)) !important;
    background-image: -webkit-linear-gradient(top, #912B06 10%, #B83A08 77%) !important;
    background-image: linear-gradient(180deg, #912B06 10%, #B83A08 77%) !important
}

.fairenter .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #1E2E41), to(#2D4561));
    background-image: -webkit-linear-gradient(top, #1E2E41 12%, #2D4561 100%);
    background-image: linear-gradient(180deg, #1E2E41 12%, #2D4561 100%)
}

.fairenter .slip_set-pop a,
.fairenter .oneclick_set-pop a {
    color: #1E1E1E
}

.fairenter .time_zone {
    color: #ECCB4B
}

.fairenter .time_zone span {
    color: #847C48
}

.fairenter .menu-list li {
    border-bottom-color: #466071
}

.fairenter .menu-list a {
    color: #D3CFB6
}

.fairenter .menu-list a:hover {
    background-color: #12232E
}

.fairenter .menu-list .select {
    background-color: #12232E;
    color: #D3CFB6
}

.fairenter .menu-list .select:hover {
    background-color: #12232E
}

.fairenter .menu-list .Go {
    background-position: 100% -800px;
    height: 25px;
    width: 23px
}

.fairenter .menu-list .Go:hover,
.fairenter .menu-list .Go.Go-fairenter-hover {
    background-position: 100% -825px
}

.fairenter .sub_path {
    background-color: #243A48
}

.fairenter .path {
    background-repeat: repeat-x;
    background-position: 0 -5798px
}

.fairenter .path a {
    color: #243A48
}

.fairenter .path .path-back {
    background-position: 0 -3125px;
    height: 25px;
    width: 36px
}

.fairenter .path .path-back:hover,
.fairenter .path .path-back.path-back-fairenter-hover {
    background-position: 0 -3150px
}

.fairenter .path .path-back.select {
    background-position: 0 -3150px;
    height: 25px;
    width: 36px
}

.fairenter .game-highlight-wrap h3 {
    background-color: #D7C45E;
    color: #222
}

.fairenter .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10347px
}

.fairenter .tab-markets {
    background: transparent
}

.fairenter .one_click-setting {
    color: #ECCB4B;
    background-repeat: repeat-x;
    background-color: #912B06;
    background-position: 0 -4701px
}

.fairenter .one_click-setting a {
    border-color: #621F04
}

.fairenter .one_click-setting .a-edit {
    color: #ECCB4B
}

.fairenter .one_click-setting .a-edit img {
    background-position: -405px -2143px;
    height: 9px;
    width: 9px
}

.fairenter .one_click-stake {
    background-position: 0 -4057px;
    height: 31px;
    width: 414px
}

.fairenter .one_click-stake input,
.fairenter .one_click-stake textarea,
.fairenter .one_click-stake .member_select a,
.member_select .fairenter .one_click-stake a {
    border-color: #621F04;
    color: #1E1E1E;
    background-color: #CD7441
}

.fairenter .one_click-stake input:focus,
.fairenter .one_click-stake textarea:focus,
.fairenter .one_click-stake .member_select a:focus,
.member_select .fairenter .one_click-stake a:focus {
    border-color: #ECCB4B;
    background-color: #D6956D
}

.fairenter .news-wrap .announce-header {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E3C93D), color-stop(71%, #C9A21B));
    background-image: -webkit-linear-gradient(top, #E3C93D 0%, #C9A21B 71%);
    background-image: linear-gradient(180deg, #E3C93D 0%, #C9A21B 71%)
}

.fairenter .footer_fixed .main_wrap,
.fairenter .footer_fixed .header,
.fairenter .footer_fixed .footer-content {
    background-color: #E5E5DF
}

.fairenter .submit-wrap .btn-send span {
    background-color: #243A48
}

.fairenter .streaming-pop {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse
}

.fairenter .live-left {
    background-color: #a39a89
}

.fairenter .live-left dt {
    position: relative;
    border-bottom: 1px solid #C6BCA7;
    background-color: #C6BCA7
}

.fairenter .live-left dt::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 25px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2)
}

.fairenter .live-left dt:last-child::before {
    content: none
}

.fairenter .live-left dt img {
    margin-right: 10px;
    -webkit-filter: brightness(20%);
    filter: brightness(20%)
}

.fairenter .live-left dd {
    border-bottom: 1px solid #4A4A4A;
    background-color: #12232E;
    text-indent: 10px
}

.fairenter .live-left dd a {
    color: #ffffff
}

.fairenter .live-left dd a::after {
    content: none
}

.fairenter .live-left dd a.select,
.fairenter .live-left dd a:hover {
    background-color: #2A4F66
}

.fairenter .live-left dd a.select::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border-style: solid;
    border-color: transparent transparent transparent #000000;
    border-width: 6px;
    -webkit-transform: translateY(-45%);
    transform: translateY(-45%)
}

.fairenter .live-right {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse
}

.fairenter .live-head {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
    width: 100%
}

.fairenter .live-head h3 {
    display: -webkit-box;
    display: flex;
    position: relative;
    -webkit-box-pack: start;
    justify-content: flex-start;
    background-image: none;
    background-color: #C6BCA7;
    color: #1E1E1E;
    font-size: 12px;
    -webkit-box-ordinal-group: 4;
    order: 3
}

.fairenter .live-head h3 span {
    color: #555555;
    text-indent: 10px
}

.fairenter .live-head h3 .icon-TV_head {
    position: relative;
    z-index: 2;
    margin: 8px 20px 0 0
}

.fairenter .live-head h3::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 42px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2)
}

.fairenter .live-channel {
    background: #B83A08;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.25)), to(rgba(0, 0, 0, 0.25)));
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
    -webkit-box-ordinal-group: 2;
    order: 1
}

.fairenter .live-channel span {
    color: #ffffff;
    background-color: #7A0B00
}

.fairenter .change-channel {
    border-left: 1px solid #922f07;
    background: #B83A08;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.25)), to(rgba(0, 0, 0, 0.25)));
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%)
}

body.lucky7 {
    background-color: #EEEEEE
}

/* .lucky7 .btn.select,.lucky7 .select.btn-send,.lucky7 .pages a.select,.pages .lucky7 a.select,.lucky7 .calendarTable_inputBox input.select,.calendarTable_inputBox .lucky7 input.select,.lucky7 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .lucky7 textarea.select,.lucky7 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .lucky7 a.select,.lucky7 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .lucky7 a.select,.lucky7 .total_all .select.btn_replay,.total_all .lucky7 .select.btn_replay,.lucky7 .select.btn_expand,.lucky7 .select.add_member{
    color:#fff;
    background:#C1292E url(../images/lucky7/btn-select-lucky7.png) repeat-x;
    border-color:#7B070B
}
.lucky7 .btn-send{
    background:#019991 url(../images/lucky7/bg-send-lucky7.png) repeat-x;
    border:1px solid transparent;
    border-radius:4px;
    color:#fff
}
.lucky7 .btn-send:hover{
    background:#1FB9B1 url(../images/lucky7/bg-send_hover-lucky7.png) repeat-x
}
.lucky7 .btn-send.disable{
    background:#99D7D3 url(../images/lucky7/bg-send-disable-lucky7.png) repeat-x;
    border-color:transparent;
    color:#FFFFFF
}
.lucky7 .login_to_go{
    background-color:#fff
}
.lucky7 .kv{
    background:#14213D url(../images/lucky7/bg-login_wrap-lucky7.png) no-repeat
}
.lucky7 .login-panel{
    color:#1E1E1E
}
.lucky7 .announce-header h1:before{
    background:#303FA3
}
.lucky7 .top{
    background:#14213D url(../images/lucky7/bg-top-lucky7.jpg) repeat-x
} */
.Toastify__toast--warning {
    /* background-color: #bd362f !important; */
    opacity: .8;
    border-radius: 3px !important;
    overflow: hidden;
    box-sizing: 'border-box';
}

.Toastify__toast-body {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=') !important;
    background-position: 15px center;
    background-repeat: no-repeat;
    font-size: 14px !important;
    margin: 0 0 6px !important;
    padding: 15px 15px 15px 50px !important;
    text-align: center !important;
}

.Toastify__toast--warning {
    /* background-color: #bd362f !important; */
    opacity: .8;
    border-radius: 3px !important;
    overflow: hidden;
    box-sizing: 'border-box';
}

.lucky7 .header {
    color: #fff
}

.lucky7 .header h1 {
    margin-top: -7px
}

.lucky7 .login-wrap .btn-login {
    background: #E71A05
}

.lucky7 .login-wrap li.user {
    background-position: -96px -5342px
}

.lucky7 .login-wrap .error {
    color: #cccccc
}

.lucky7 .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.lucky7 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.lucky7 .account-wrap a {
    color: #fff
}

.lucky7 .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4);
    background-color: #4552ac
}

.lucky7 .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.lucky7 .account-wrap .loading-bar span {
    background: #fff
}

.lucky7 .account-wrap .loading-bar,
.lucky7 .account-wrap .a-refresh {
    background-color: #4552ac
}

.lucky7 .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.6)
}

.lucky7 .account-wrap .head-refresh a {
    background-position: -80px -1775px;
    height: 25px;
    width: 30px
}

.lucky7 .account-wrap .account {
    background-position: 0 -625px;
    height: 25px;
    width: 110px
}

.lucky7 .account-wrap ul a {
    color: #1E1E1E
}

.lucky7 .account-wrap .logout a {
    color: #fff
}

.lucky7 .menu-wrap {
    background: #C1292E
}

.lucky7 .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.lucky7 .menu li,
.lucky7 .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.3)
}

.lucky7 .menu li ul a,
.lucky7 .setting-wrap li ul a {
    color: #fff
}

.lucky7 .menu a,
.lucky7 .setting-wrap a {
    color: #fff
}

.lucky7 .menu a:hover,
.lucky7 .setting-wrap a:hover {
    background-color: rgba(0, 0, 0, 0.3);
    text-decoration: underline
}

.lucky7 .menu .select,
.lucky7 .setting-wrap .select {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff
}

.lucky7 .menu .logout,
.lucky7 .setting-wrap .logout {
    border-left-width: 0
}

.lucky7 .menu .logout img,
.lucky7 .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.lucky7 .live-menu {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.lucky7 .setting {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.lucky7 .one_click {
    border-top-color: #28C5BF;
    color: #141E63 !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E9E9E9), to(#D9D9D9));
    background-image: -webkit-linear-gradient(top, #E9E9E9 0%, #D9D9D9 100%);
    background-image: linear-gradient(180deg, #E9E9E9 0%, #D9D9D9 100%);
    background-position: inherit
}

.lucky7 .one_click img {
    background-position: -94px -3670px
}

.lucky7 .one_click.select {
    color: #FFFFFF;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D5D5D5), to(#D9D9D9));
    background-image: -webkit-linear-gradient(top, #D5D5D5 0%, #D9D9D9 100%);
    background-image: linear-gradient(180deg, #D5D5D5 0%, #D9D9D9 100%);
    background-position: inherit
}

.lucky7 .one_click.select img {
    background-position: -94px -3640px
}

.lucky7 .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E9E9E9), to(#D9D9D9));
    background-image: -webkit-linear-gradient(top, #E9E9E9 0%, #D9D9D9 100%);
    background-image: linear-gradient(180deg, #E9E9E9 0%, #D9D9D9 100%)
}

.lucky7 .time_zone {
    color: white
}

.lucky7 .time_zone span {
    color: rgba(255, 255, 255, 0.5)
}

.lucky7 .menu-list li {
    border-bottom-color: #DDDDDD
}

.lucky7 .menu-list a {
    color: #141E63
}

.lucky7 .menu-list a:hover {
    background-color: rgba(48, 63, 163, 0.1)
}

.lucky7 .menu-list .select {
    background-color: rgba(193, 41, 46, 0.2)
}

.lucky7 .menu-list .select:hover {
    background-color: rgba(193, 41, 46, 0.4)
}

.lucky7 .menu-list .Go {
    background-position: 100% -1100px;
    height: 25px;
    width: 23px
}

.lucky7 .menu-list .Go:hover,
.lucky7 .menu-list .Go.Go-lucky7-hover {
    background-position: 100% -1125px
}

.lucky7 .path {
    background-repeat: repeat-x;
    background-position: 0 -5873px
}

.lucky7 .path .path-back {
    background-position: 0 -3425px
}

.lucky7 .path .path-back:hover,
.lucky7 .path .path-back.path-back-lucky7-hover {
    background-position: 0 -3450px
}

.lucky7 .path .path-back.select {
    background-position: 0 -3450px
}

.lucky7 .path-pop {
    background-color: #141E63
}

.lucky7 .game-highlight-wrap h3 {
    background-color: #303FA3;
    color: #fff
}

.lucky7 .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10435px
}

.lucky7 .tab-markets {
    background: transparent
}

.lucky7 .one_click-setting {
    color: #1E1E1E;
    background-repeat: repeat-x;
    background-color: #ECECEC;
    background-position: 0 -4889px
}

.lucky7 .one_click-setting a {
    border-color: #9B9B9B
}

.lucky7 .one_click-setting .a-edit {
    border: 1px solid #C1292E;
    color: #C1292E
}

.lucky7 .one_click-setting .a-edit img {
    background-position: -405px -2179px;
    height: 9px;
    width: 9px
}

.lucky7 .one_click-setting .btn-send {
    border-color: transparent
}

.lucky7 .one_click-stake {
    background-position: 0 -4245px;
    height: 32px;
    width: 414px
}

.lucky7 .one_click-stake input,
.lucky7 .one_click-stake textarea,
.lucky7 .one_click-stake .member_select a,
.member_select .lucky7 .one_click-stake a {
    border-color: #9B9B9B;
    color: #fff;
    background-color: #777777
}

.lucky7 .one_click-stake input:focus,
.lucky7 .one_click-stake textarea:focus,
.lucky7 .one_click-stake .member_select a:focus,
.member_select .lucky7 .one_click-stake a:focus {
    border-color: #C10A31;
    color: #fff;
    background-color: #333333
}

.lucky7 .submit-wrap .btn-send span {
    background-color: #215867
}

body.qexch {
    background-color: #EEEEEE
}

/* .qexch .btn.select,.qexch .select.btn-send,.qexch .pages a.select,.pages .qexch a.select,.qexch .calendarTable_inputBox input.select,.calendarTable_inputBox .qexch input.select,.qexch .calendarTable_inputBox textarea.select,.calendarTable_inputBox .qexch textarea.select,.qexch .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .qexch a.select,.qexch .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .qexch a.select,.qexch .total_all .select.btn_replay,.total_all .qexch .select.btn_replay,.qexch .select.btn_expand,.qexch .select.add_member{
    background:#443A56 url(../images/qexch/btn-select-qexch.png) repeat-x;
    border-color:#160C28;
    color:#FFFFFF
}
.qexch .btn-send{
    background:#FF9C00 url(../images/qexch/bg-send-qexch.png) repeat-x;
    border-color:transparent;
    border-radius:4px
}
.qexch .btn-send:hover{
    background:#FBC30A url(../images/qexch/bg-send_hover-qexch.png) repeat-x
}
.qexch .btn-send.disable{
    background:#FFD899 url(../images/qexch/bg-send-disable-qexch.png) repeat-x;
    border-color:transparent;
    color:#A5A5A5
} */
.qexch .login_to_go {
    background-color: #fff
}

/* .qexch .kv{
    background:#443A56 url(../images/qexch/bg-login_wrap-qexch.png) no-repeat
} */
.qexch .login-panel {
    color: #1E1E1E
}

.qexch .icon-login {
    background-position: 0 -184px;
    height: 12px;
    width: 12px
}

.qexch .announce-header h1:before {
    background: #443A56
}

/* .qexch .top{
    background:#14213D url(../images/qexch/bg-top-qexch.jpg) repeat-x
} */
.qexch .header {
    height: 46px;
    padding-top: 28px;
    color: #fff
}

.qexch .header h1 {
    margin-top: -12px
}

.qexch .login-wrap .btn-login {
    background: #E71A05
}

.qexch .login-wrap li.user {
    background-position: -96px -5667px
}

.qexch .login-wrap .error {
    color: #cccccc
}

.qexch .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.qexch .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.qexch .account-wrap a {
    color: #fff
}

.qexch .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4);
    background-color: #58437f
}

.qexch .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.qexch .account-wrap .loading-bar span {
    background: #fff
}

.qexch .account-wrap .loading-bar,
.qexch .account-wrap .a-refresh {
    background-color: #58437f
}

.qexch .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.7)
}

.qexch .account-wrap .head-refresh a {
    background-position: -80px -2025px;
    height: 25px;
    width: 30px
}

.qexch .account-wrap .account {
    background-position: 0 -875px;
    height: 25px;
    width: 110px
}

.qexch .account-wrap ul a {
    color: #1E1E1E
}

.qexch .account-wrap .logout a {
    color: #fff
}

.qexch .menu-wrap {
    background: #FBC30A
}

.qexch .menu-wrap a.menu-drop:after {
    border-top-color: #443a56
}

.qexch .menu li,
.qexch .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.3)
}

.qexch .menu li ul a,
.qexch .setting-wrap li ul a {
    color: #FFFFFF
}

.qexch .menu li ul a:hover,
.qexch .setting-wrap li ul a:hover {
    background-color: rgba(70, 46, 113, 0.5);
    color: #FFFFFF
}

.qexch .menu a,
.qexch .setting-wrap a {
    color: #443A56
}

.qexch .menu a:hover,
.qexch .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #462E71;
    text-decoration: underline
}

.qexch .menu .select,
.qexch .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.5);
    color: #462E71
}

.qexch .menu .logout,
.qexch .setting-wrap .logout {
    border-left-width: 0
}

.qexch .menu .logout img,
.qexch .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23443a56" fill-rule="evenodd"/></svg>') no-repeat
}

.qexch .menu .tag-new,
.qexch .setting-wrap .tag-new {
    color: #FFFFFF
}

.qexch .menu .tag-new:hover,
.qexch .setting-wrap .tag-new:hover {
    color: #FFFFFF
}

.qexch .live-menu {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.qexch .setting {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.qexch .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23443a56" fill-rule="evenodd"/></svg>') no-repeat
}

.qexch .one_click {
    border-top-color: #FBC30A;
    color: #443A56 !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E9E9E9), to(#D9D9D9));
    background-image: -webkit-linear-gradient(top, #E9E9E9 0%, #D9D9D9 100%);
    background-image: linear-gradient(180deg, #E9E9E9 0%, #D9D9D9 100%);
    background-position: inherit
}

.qexch .one_click img {
    background-position: -94px -4090px
}

.qexch .one_click.select {
    color: #462E71 !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D5D5D5), to(#D9D9D9));
    background-image: -webkit-linear-gradient(top, #D5D5D5 0%, #D9D9D9 100%);
    background-image: linear-gradient(180deg, #D5D5D5 0%, #D9D9D9 100%);
    background-position: inherit
}

.qexch .one_click.select img {
    background-position: -94px -4060px
}

.qexch .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E9E9E9), to(#D9D9D9));
    background-image: -webkit-linear-gradient(top, #E9E9E9 0%, #D9D9D9 100%);
    background-image: linear-gradient(180deg, #E9E9E9 0%, #D9D9D9 100%)
}

.qexch .time_zone {
    color: #1e1e1e
}

.qexch .time_zone span {
    color: rgba(30, 30, 30, 0.7)
}

.qexch .menu-list li {
    border-bottom-color: #DDDDDD
}

.qexch .menu-list a {
    color: #462E71
}

.qexch .menu-list a:hover {
    background-color: rgba(70, 46, 113, 0.2)
}

.qexch .menu-list .select {
    background-color: rgba(70, 46, 113, 0.2)
}

.qexch .menu-list .Go {
    background-position: 100% -1550px;
    height: 25px;
    width: 23px
}

.qexch .menu-list .Go:hover,
.qexch .menu-list .Go.Go-qexch-hover {
    background-position: 100% -1575px
}

.qexch .path {
    background-repeat: repeat-x;
    background-position: 0 -6048px
}

.qexch .path .path-back {
    background-position: 0 -3875px
}

.qexch .path .path-back:hover,
.qexch .path .path-back.path-back-qexch-hover {
    background-position: 0 -3900px
}

.qexch .path .path-back.select {
    background-position: 0 -3900px
}

.qexch .path-pop {
    background-color: #160C28
}

.qexch .path-pop a {
    background-position: -21px -2125px
}

.qexch .game-highlight-wrap h3 {
    background-color: #443A56;
    color: #fff
}

.qexch .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10633px
}

.qexch .tab-markets {
    background: transparent
}

.qexch .one_click-setting {
    color: #1E1E1E;
    background-repeat: repeat-x;
    background-color: #ECECEC;
    background-position: 0 -5139px
}

.qexch .one_click-setting a {
    border-color: #9B9B9B
}

/* .qexch .one_click-setting .a-edit{
    background:#FF9C00 url(../images/qexch/bg-send-qexch.png) repeat-x;
    border-color:transparent;
    color:#1E1E1E
} */
.qexch .one_click-setting .a-edit img {
    background-position: -405px -2062px;
    height: 9px;
    width: 9px
}

.qexch .one_click-setting .btn-send {
    border-color: transparent
}

.qexch .one_click-stake {
    background-position: 0 -4495px;
    height: 32px;
    width: 414px
}

.qexch .one_click-stake input,
.qexch .one_click-stake textarea,
.qexch .one_click-stake .member_select a,
.member_select .qexch .one_click-stake a {
    border-color: #9B9B9B;
    color: #fff;
    background-color: #777777
}

.qexch .one_click-stake input:focus,
.qexch .one_click-stake textarea:focus,
.qexch .one_click-stake .member_select a:focus,
.member_select .qexch .one_click-stake a:focus {
    border-color: #462E71;
    color: #fff;
    background-color: #443A56
}

.qexch .submit-wrap .btn-send span {
    background-color: #462E71
}

body.betmygame {
    background-color: #EEEEEE
}

/* .betmygame .btn.select,.betmygame .select.btn-send,.betmygame .pages a.select,.pages .betmygame a.select,.betmygame .calendarTable_inputBox input.select,.calendarTable_inputBox .betmygame input.select,.betmygame .calendarTable_inputBox textarea.select,.calendarTable_inputBox .betmygame textarea.select,.betmygame .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .betmygame a.select,.betmygame .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .betmygame a.select,.betmygame .total_all .select.btn_replay,.total_all .betmygame .select.btn_replay,.betmygame .select.btn_expand,.betmygame .select.add_member{
    background:#ED7218 url(../images/betmygame/btn-select-betmygame.png) repeat-x;
    border-color:#542400;
    color:#FFFFFF
}
.betmygame .btn-send{
    background:#F8C638 url(../images/betmygame/bg-send-betmygame.png) repeat-x;
    border-color:#D3A219;
    border-radius:4px
}
.betmygame .btn-send:hover{
    background:#FCE656 url(../images/betmygame/bg-send_hover-betmygame.png) repeat-x
}
.betmygame .btn-send.disable{
    background:#FDE9B0 url(../images/betmygame/bg-send-disable-betmygame.png) repeat-x;
    border-color:#EEDAA3;
    color:#A5A5A5
}
.betmygame .login_to_go{
    background-color:#fff
}
.betmygame .kv{
    background:#000 url(../images/betmygame/bg-login_wrap-betmygame.png) no-repeat
} */
.betmygame .login-panel {
    color: #1E1E1E
}

.betmygame .icon-login {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23000" fill-rule="evenodd"/></svg>') no-repeat
}

.betmygame .announce-header h1:before {
    background: #ED7218
}

/* .betmygame .top{
    background:#000 url(../images/betmygame/bg-top-betmygame.jpg) repeat-x
} */
.betmygame .header {
    height: 46px;
    padding-top: 28px;
    color: #fff
}

.betmygame .header h1 {
    margin-top: -12px
}

.betmygame .login-wrap .btn-login {
    background: #E71A05
}

.betmygame .login-wrap li.user {
    background-position: -96px -5667px
}

.betmygame .login-wrap .error {
    color: #cccccc
}

.betmygame .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.betmygame .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.betmygame .account-wrap a {
    color: #fff
}

.betmygame .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
    background-color: #454745
}

.betmygame .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.betmygame .account-wrap .a-wallet .red {
    background-color: #fff;
    padding: 0 3px;
    border-radius: 2px
}

.betmygame .account-wrap .loading-bar span {
    background: #fff
}

.betmygame .account-wrap .a-refresh {
    background-color: #454745
}

.betmygame .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.betmygame .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.7)
}

.betmygame .account-wrap .head-refresh a {
    background-position: -80px -1425px;
    height: 25px;
    width: 30px
}

.betmygame .account-wrap .account {
    background-position: 0 -275px;
    height: 25px;
    width: 110px
}

.betmygame .account-wrap ul a {
    color: #1E1E1E
}

.betmygame .account-wrap .logout a {
    color: #fff
}

.betmygame .menu-wrap {
    background: #ED7218
}

.betmygame .menu-wrap a.menu-drop:after {
    border-top-color: #542400
}

.betmygame .menu-wrap a.select.menu-drop:after {
    border-top-color: #000
}

.betmygame .menu li,
.betmygame .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.3)
}

.betmygame .menu li ul a,
.betmygame .setting-wrap li ul a {
    color: #FFFFFF
}

.betmygame .menu li ul a:hover,
.betmygame .setting-wrap li ul a:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #000000
}

.betmygame .menu a,
.betmygame .setting-wrap a {
    color: #542400
}

.betmygame .menu a:hover,
.betmygame .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #000000;
    text-decoration: underline
}

.betmygame .menu .select,
.betmygame .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.5);
    color: #000000
}

.betmygame .menu .logout,
.betmygame .setting-wrap .logout {
    border-left-width: 0
}

.betmygame .menu .logout img,
.betmygame .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23542400" fill-rule="evenodd"/></svg>') no-repeat
}

.betmygame .menu .logout:hover img,
.betmygame .setting-wrap .logout:hover img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23000" fill-rule="evenodd"/></svg>') no-repeat
}

.betmygame .menu .tag-new,
.betmygame .setting-wrap .tag-new {
    color: #FFFFFF
}

.betmygame .menu .tag-new:hover,
.betmygame .setting-wrap .tag-new:hover {
    color: #FFFFFF
}

.betmygame .live-menu {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.betmygame .setting {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.betmygame .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23542400" fill-rule="evenodd"/></svg>') no-repeat
}

.betmygame .setting:hover img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23000" fill-rule="evenodd"/></svg>') no-repeat
}

.betmygame .one_click {
    border-top-color: #F8C537;
    color: #FFFFFF !important;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#941A00), to(#C54014));
    background-image: -webkit-linear-gradient(bottom, #941A00 0%, #C54014 100%);
    background-image: linear-gradient(0deg, #941A00 0%, #C54014 100%);
    background-position: inherit
}

.betmygame .one_click img {
    background-position: -94px -2890px
}

.betmygame .one_click.select {
    color: #FFFFFF !important;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#CF5C36), to(#941A00));
    background-image: -webkit-linear-gradient(bottom, #CF5C36 0%, #941A00 100%);
    background-image: linear-gradient(0deg, #CF5C36 0%, #941A00 100%);
    background-position: inherit
}

.betmygame .one_click.select img {
    background-position: -94px -2860px
}

.betmygame .one_click.select:hover {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#941A00), to(#C54014));
    background-image: -webkit-linear-gradient(bottom, #941A00 0%, #C54014 100%);
    background-image: linear-gradient(0deg, #941A00 0%, #C54014 100%)
}

.betmygame .time_zone {
    color: #1e1e1e
}

.betmygame .time_zone span {
    color: rgba(30, 30, 30, 0.7)
}

.betmygame .menu-list li {
    border-bottom-color: #DDDDDD
}

.betmygame .menu-list a {
    color: #542400
}

.betmygame .menu-list a:hover {
    background-color: rgba(237, 114, 24, 0.2)
}

.betmygame .menu-list .select {
    background-color: rgba(237, 114, 24, 0.2)
}

.betmygame .menu-list .Go {
    background-position: 100% -450px;
    height: 25px;
    width: 23px
}

.betmygame .menu-list .Go:hover,
.betmygame .menu-list .Go.Go-betmygame-hover {
    background-position: 100% -475px
}

.betmygame .path {
    background-repeat: repeat-x;
    background-position: 0 -5648px
}

.betmygame .path .path-back {
    background-position: 0 -2725px
}

.betmygame .path .path-back:hover,
.betmygame .path .path-back.path-back-betmygame-hover {
    background-position: 0 -2750px
}

.betmygame .path .path-back.select {
    background-position: 0 -2750px
}

.betmygame .path-pop {
    background-color: #160C28
}

.betmygame .path-pop a {
    background-position: -21px -2125px
}

.betmygame .game-highlight-wrap h3 {
    background-color: #ED7218;
    color: #542400
}

.betmygame .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10193px
}

.betmygame .tab-markets {
    background: transparent
}

.betmygame .one_click-setting {
    color: #FFFFFF;
    background-repeat: repeat-x;
    background-color: #542400;
    background-position: 0 -4451px
}

.betmygame .one_click-setting a {
    border-color: #9B9B9B
}

/* .betmygame .one_click-setting .a-edit{
    background:#F8C638 url(../images/betmygame/bg-send-betmygame.png) repeat-x;
    border-color:#D3A219;
    color:#1E1E1E
} */
.betmygame .one_click-setting .a-edit img {
    background-position: -405px -2062px;
    height: 9px;
    width: 9px
}

.betmygame .one_click-setting .btn-send {
    border-color: transparent
}

.betmygame .one_click-stake {
    background-position: 0 -3807px;
    height: 32px;
    width: 414px
}

.betmygame .one_click-stake input,
.betmygame .one_click-stake textarea,
.betmygame .one_click-stake .member_select a,
.member_select .betmygame .one_click-stake a {
    border-color: #999999;
    color: #fff;
    background-color: #555555
}

.betmygame .one_click-stake input:focus,
.betmygame .one_click-stake textarea:focus,
.betmygame .one_click-stake .member_select a:focus,
.member_select .betmygame .one_click-stake a:focus {
    border-color: #542400;
    color: #fff;
    background-color: #ED7218
}

.betmygame .submit-wrap .btn-send span {
    background-color: #ED7218
}

body.fancyfair {
    background-color: #EEEEEE
}

/* .fancyfair .btn.select,.fancyfair .select.btn-send,.fancyfair .pages a.select,.pages .fancyfair a.select,.fancyfair .calendarTable_inputBox input.select,.calendarTable_inputBox .fancyfair input.select,.fancyfair .calendarTable_inputBox textarea.select,.calendarTable_inputBox .fancyfair textarea.select,.fancyfair .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .fancyfair a.select,.fancyfair .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .fancyfair a.select,.fancyfair .total_all .select.btn_replay,.total_all .fancyfair .select.btn_replay,.fancyfair .select.btn_expand,.fancyfair .select.add_member{
    background:#50996B url(../images/fancyfair/btn-select-fancyfair.png) repeat-x;
    border-color:#1E3231;
    color:#FFFFFF
}
.fancyfair .btn-send{
    background:#F4D800 url(../images/fancyfair/bg-send-fancyfair.png) repeat-x;
    border-color:#FBC30A;
    border-radius:4px
}
.fancyfair .btn-send:hover{
    background:#FFF07C url(../images/fancyfair/bg-send_hover-fancyfair.png) repeat-x
}
.fancyfair .btn-send.disable{
    background:#FBF099 url(../images/fancyfair/bg-send-disable-fancyfair.png) repeat-x;
    border-color:#FEE79D;
    color:#A5A5A5
}
.fancyfair .login_to_go{
    background-color:#fff
}
.fancyfair .kv{
    background:#50996B url(../images/fancyfair/bg-login_wrap-fancyfair.png) no-repeat
}
.fancyfair .login-panel{
    color:#1E3231
}
.fancyfair .announce-header h1:before{
    background:#50996B
}
.fancyfair .top{
    background:#14213D url(../images/fancyfair/bg-top-fancyfair.jpg) repeat-x
}
.fancyfair .header{
    height:46px;
    padding-top:28px;
    color:#fff
} */
.fancyfair .header h1 {
    margin-top: -12px
}

.fancyfair .login-wrap .btn-login {
    background: #E71A05
}

.fancyfair .login-wrap li.user {
    background-position: -96px -5667px
}

.fancyfair .login-wrap .error {
    color: #cccccc
}

.fancyfair .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.fancyfair .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.fancyfair .account-wrap a {
    color: #fff
}

.fancyfair .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
    background-color: #344646
}

.fancyfair .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.fancyfair .account-wrap .a-refresh {
    background-color: #344646
}

.fancyfair .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.fancyfair .account-wrap .head-refresh a {
    background-position: -80px -1625px;
    height: 25px;
    width: 30px
}

.fancyfair .account-wrap .account {
    background-position: 0 -475px;
    height: 25px;
    width: 110px
}

.fancyfair .account-wrap ul a {
    color: #1E1E1E
}

.fancyfair .account-wrap .logout a {
    color: #fff
}

.fancyfair .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#111D1C), color-stop(74%, #233A39));
    background-image: -webkit-linear-gradient(top, #111D1C 0%, #233A39 74%);
    background-image: linear-gradient(180deg, #111D1C 0%, #233A39 74%)
}

.fancyfair .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.fancyfair .menu li,
.fancyfair .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.3)
}

.fancyfair .menu li ul a,
.fancyfair .setting-wrap li ul a {
    color: #FFFFFF
}

.fancyfair .menu li ul a:hover,
.fancyfair .setting-wrap li ul a:hover {
    background-color: rgba(80, 153, 107, 0.1);
    color: #FFFFFF
}

.fancyfair .menu a,
.fancyfair .setting-wrap a {
    color: #FFFFFF
}

.fancyfair .menu a:hover,
.fancyfair .setting-wrap a:hover {
    text-decoration: underline;
    background-color: rgba(0, 0, 0, 0.5)
}

.fancyfair .menu .select,
.fancyfair .setting-wrap .select {
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.7)
}

.fancyfair .menu .logout,
.fancyfair .setting-wrap .logout {
    border-left-width: 0
}

.fancyfair .live-menu {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.fancyfair .setting {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.fancyfair .one_click {
    border-top-color: #FFF07C;
    background-repeat: repeat-x;
    color: #1E3231 !important;
    background-position: 0 -2160px
}

.fancyfair .one_click img {
    background-position: -94px -3370px
}

.fancyfair .one_click.select {
    color: #1E3231 !important;
    background-repeat: repeat-x;
    background-position: 0 -2130px
}

.fancyfair .one_click.select img {
    background-position: -94px -3340px
}

.fancyfair .time_zone {
    color: white
}

.fancyfair .time_zone span {
    color: rgba(255, 255, 255, 0.7)
}

.fancyfair .menu-list li {
    border-bottom-color: #DDDDDD
}

.fancyfair .menu-list a {
    color: #1E3231
}

.fancyfair .menu-list a:hover {
    background-color: rgba(80, 153, 107, 0.1)
}

.fancyfair .menu-list .select {
    background-color: rgba(80, 153, 107, 0.1)
}

.fancyfair .menu-list .Go {
    background-position: 100% -850px;
    height: 25px;
    width: 23px
}

.fancyfair .menu-list .Go:hover,
.fancyfair .menu-list .Go.Go-fancyfair-hover {
    background-position: 100% -875px
}

.fancyfair .path {
    background-repeat: repeat-x;
    background-position: 0 -5823px
}

.fancyfair .path .path-back {
    background-position: 0 -3175px
}

.fancyfair .path .path-back:hover,
.fancyfair .path .path-back.path-back-fancyfair-hover {
    background-position: 0 -3200px
}

.fancyfair .path .path-back.select {
    background-position: 0 -3200px
}

.fancyfair .path-pop {
    background-color: #1E3231
}

.fancyfair .path-pop a {
    background-position: -21px -2125px
}

.fancyfair .game-highlight-wrap h3 {
    background-color: #A3D7B6;
    color: #1E3231
}

.fancyfair .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10369px
}

.fancyfair .tab-markets {
    background: transparent
}

.fancyfair .one_click-setting {
    color: #1E1E1E;
    background-repeat: repeat-x;
    background-color: #ECECEC;
    background-position: 0 -4732px
}

.fancyfair .one_click-setting a {
    border-color: #9B9B9B
}

/* .fancyfair .one_click-setting .a-edit{
    background:#FF9C00 url(../images/fancyfair/bg-send-fancyfair.png) repeat-x;
    border-color:#FBC30A;
    color:#1E1E1E
} */
.fancyfair .one_click-setting .a-edit img {
    background-position: -405px -2062px;
    height: 9px;
    width: 9px
}

.fancyfair .one_click-setting .btn-send {
    border-color: #FBC30A
}

.fancyfair .one_click-stake {
    background-position: 0 -4088px;
    height: 32px;
    width: 414px
}

.fancyfair .one_click-stake input,
.fancyfair .one_click-stake textarea,
.fancyfair .one_click-stake .member_select a,
.member_select .fancyfair .one_click-stake a {
    border-color: #9B9B9B;
    color: #000000;
    background-color: #E1E1E1
}

.fancyfair .one_click-stake input:focus,
.fancyfair .one_click-stake textarea:focus,
.fancyfair .one_click-stake .member_select a:focus,
.member_select .fancyfair .one_click-stake a:focus {
    border-color: #A3D7B6;
    color: #fff;
    background-color: #50996B
}

.fancyfair .submit-wrap .btn-send span {
    background-color: #50996B
}

body.maruti {
    background-color: #F8F9E9
}

/* .maruti .btn.select,.maruti .select.btn-send,.maruti .pages a.select,.pages .maruti a.select,.maruti .calendarTable_inputBox input.select,.calendarTable_inputBox .maruti input.select,.maruti .calendarTable_inputBox textarea.select,.calendarTable_inputBox .maruti textarea.select,.maruti .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .maruti a.select,.maruti .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .maruti a.select,.maruti .total_all .select.btn_replay,.total_all .maruti .select.btn_replay,.maruti .select.btn_expand,.maruti .select.add_member{
    background:#7BB54C url(../images/maruti/btn-select-maruti.jpg) repeat-x;
    border-color:#32803A
}
.maruti .btn-send{
    background:#38703D url(../images/maruti/bg-send-maruti.png) repeat-x;
    color:#fff;
    border-color:#07571D
}
.maruti .btn-send:hover{
    background:#48955B url(../images/maruti/bg-send_hover-maruti.png) repeat-x
}
.maruti .btn-send.disable{
    background:#C3D5C5 url(../images/maruti/bg-send-disable-maruti.png) repeat-x;
    border-color:#A2B4A7;
    color:#EFFCF4
}
.maruti .kv{
    background:#48955B url(../images/maruti/bg-login_wrap-maruti.jpg) no-repeat
}
.maruti .login-panel{
    color:#fff
}
.maruti .login-panel dt{
    color:#000
}
.maruti .top{
    background:#307234 url(../images/maruti/bg-top-maruti.jpg) repeat-x
} */
.maruti .header {
    color: #fff
}

.maruti .header h1 {
    margin-top: -12px
}

.maruti .login-wrap li.user {
    background-position: -96px -5442px
}

.maruti .login-wrap .error {
    color: #EFDA54
}

.maruti .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.maruti .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #CA1F00;
    border-radius: 3px
}

.maruti .account-wrap a {
    color: #fff
}

.maruti .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4)
}

.maruti .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.maruti .account-wrap .loading-bar span {
    background: #fff
}

.maruti .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.maruti .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.maruti .account-wrap .head-refresh a {
    background-position: -80px -1900px;
    height: 25px;
    width: 30px
}

.maruti .account-wrap .account {
    background-position: 0 -750px;
    height: 25px;
    width: 110px
}

.maruti .account-wrap ul a {
    color: #1E1E1E
}

.maruti .account-wrap .logout a {
    color: #fff
}

.maruti .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#204628), color-stop(13%, #2D6137), to(#3B7546));
    background-image: -webkit-linear-gradient(top, #204628 0%, #2D6137 13%, #3B7546 100%);
    background-image: linear-gradient(180deg, #204628 0%, #2D6137 13%, #3B7546 100%)
}

.maruti .menu li,
.maruti .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.maruti .menu a:hover,
.maruti .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.maruti .menu .select,
.maruti .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.maruti .setting {
    border-left-color: rgba(255, 255, 255, 0.1)
}

.maruti .one_click {
    border-top-color: #F0DB55;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(14%, #2B6B37), to(#014E0F));
    background-image: -webkit-linear-gradient(top, #2B6B37 14%, #014E0F 100%);
    background-image: linear-gradient(180deg, #2B6B37 14%, #014E0F 100%);
    background-position: inherit
}

.maruti .one_click img {
    background-position: -94px -3970px
}

.maruti .one_click.select {
    color: #604401;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BCA300), to(#BCA300));
    background-image: -webkit-linear-gradient(top, #BCA300 0%, #BCA300 100%);
    background-image: linear-gradient(180deg, #BCA300 0%, #BCA300 100%);
    background-position: inherit
}

.maruti .one_click.select img {
    background-position: -94px -3940px
}

.maruti .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(27%, #BDA500), to(#C8B71D));
    background-image: -webkit-linear-gradient(top, #BDA500 27%, #C8B71D 100%);
    background-image: linear-gradient(180deg, #BDA500 27%, #C8B71D 100%)
}

.maruti .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(14%, #014E0F), to(#2B6B37));
    background-image: -webkit-linear-gradient(top, #014E0F 14%, #2B6B37 100%);
    background-image: linear-gradient(180deg, #014E0F 14%, #2B6B37 100%)
}

.maruti .time_zone span {
    color: #99B39E
}

.maruti .menu-list li {
    border-bottom-color: #DDE7DE
}

.maruti .menu-list a {
    color: #1A4F3D
}

.maruti .menu-list a:hover {
    background-color: #C8D3CD
}

.maruti .menu-list .select {
    background-color: #C8D3CD;
    color: #1A4F3D
}

.maruti .menu-list .select:hover {
    background-color: #C8D3CD
}

.maruti .menu-list .Go {
    background-position: 100% -1350px;
    height: 25px;
    width: 23px
}

.maruti .menu-list .Go:hover,
.maruti .menu-list .Go.Go-maza-hover {
    background-position: 100% -1375px
}

.maruti .sub_path {
    background-color: #ADACAC
}

.maruti .path {
    background: #33694F
}

.maruti .path .path-back {
    background-position: 0 -3675px;
    height: 25px;
    width: 36px
}

.maruti .path .path-back:hover,
.maruti .path .path-back.path-back-maza-hover {
    background-position: 0 -3700px
}

.maruti .path .path-back.select {
    background-position: 0 -3700px;
    height: 25px;
    width: 36px
}

.maruti .game-highlight-wrap h3 {
    background-color: #32803A;
    color: #fff
}

.maruti .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10545px
}

.maruti .tab-markets {
    background: transparent
}

.maruti .one_click-setting {
    color: #604401;
    background-repeat: repeat-x;
    background-color: #B29500;
    background-position: 0 -5046px
}

.maruti .one_click-setting a {
    border-color: #604401
}

.maruti .one_click-setting .a-edit {
    color: #604401
}

.maruti .one_click-setting .a-edit img {
    background-position: -405px -2224px;
    height: 9px;
    width: 9px
}

.maruti .one_click-stake {
    background-position: 0 -4402px;
    height: 31px;
    width: 414px
}

.maruti .one_click-stake input,
.maruti .one_click-stake textarea,
.maruti .one_click-stake .member_select a,
.member_select .maruti .one_click-stake a {
    border-color: #604401;
    color: #1E1E1E;
    background-color: #B09C02
}

.maruti .one_click-stake input:focus,
.maruti .one_click-stake textarea:focus,
.maruti .one_click-stake .member_select a:focus,
.member_select .maruti .one_click-stake a:focus {
    border-color: #fff;
    background-color: #A5AB38
}

.maruti .menu .logout,
.maruti .setting-wrap .logout {
    border-left-width: 0
}

.maruti .menu .logout img,
.maruti .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.maruti .footer_fixed .main_wrap,
.maruti .footer_fixed .header,
.maruti .footer_fixed .footer-content {
    background-color: #F8F9E9
}

.maruti .submit-wrap .btn-send span {
    background-color: #E9D753;
    color: #07571D
}

body.marutibook {
    background-color: #F8F9E9
}

/* .marutibook .btn.select,.marutibook .select.btn-send,.marutibook .pages a.select,.pages .marutibook a.select,.marutibook .calendarTable_inputBox input.select,.calendarTable_inputBox .marutibook input.select,.marutibook .calendarTable_inputBox textarea.select,.calendarTable_inputBox .marutibook textarea.select,.marutibook .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .marutibook a.select,.marutibook .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .marutibook a.select,.marutibook .total_all .select.btn_replay,.total_all .marutibook .select.btn_replay,.marutibook .select.btn_expand,.marutibook .select.add_member{
    background:#7BB54C url(../images/maruti/btn-select-maruti.jpg) repeat-x;
    border-color:#32803A
}
.marutibook .btn-send{
    background:#38703D url(../images/maruti/bg-send-maruti.png) repeat-x;
    color:#fff;
    border-color:#07571D
}
.marutibook .btn-send:hover{
    background:#48955B url(../images/maruti/bg-send_hover-maruti.png) repeat-x
}
.marutibook .btn-send.disable{
    background:#C3D5C5 url(../images/maruti/bg-send-disable-maruti.png) repeat-x;
    border-color:#A2B4A7;
    color:#EFFCF4
}
.marutibook .kv{
    background:#48955B url(../images/maruti/bg-login_wrap-maruti.jpg) no-repeat
}
.marutibook .login-panel{
    color:#fff
}
.marutibook .login-panel dt{
    color:#000
}
.marutibook .top{
    background:#307234 url(../images/maruti/bg-top-maruti.jpg) repeat-x
}
.marutibook .header{
    color:#fff
}
.marutibook .header h1{
    margin-top:-12px
}
.marutibook .login-wrap li.user{
    background-position:-96px -5442px
}
.marutibook .login-wrap .error{
    color:#EFDA54
}
.marutibook .account-wrap li .refresh-bar{
    background:url(../images/livesports/refresh80-livesports.gif) no-repeat center
}
.marutibook .account-wrap .main-wallet.no-multi .red{
    padding:1px 3px;
    color:#fff !important;
    background-color:#CA1F00;
    border-radius:3px
}
.marutibook .account-wrap a{
    color:#fff
}
.marutibook .account-wrap .main-wallet{
    border-color:rgba(0,0,0,0.4)
}
.marutibook .account-wrap .a-wallet .nums{
    border:1px solid #FFF
}
.marutibook .account-wrap .loading-bar span{
    background:#fff
} */
.marutibook .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.marutibook .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.marutibook .account-wrap .head-refresh a {
    background-position: -80px -1900px;
    height: 25px;
    width: 30px
}

.marutibook .account-wrap .account {
    background-position: 0 -750px;
    height: 25px;
    width: 110px
}

.marutibook .account-wrap ul a {
    color: #1E1E1E
}

.marutibook .account-wrap .logout a {
    color: #fff
}

.marutibook .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#204628), color-stop(13%, #2D6137), to(#3B7546));
    background-image: -webkit-linear-gradient(top, #204628 0%, #2D6137 13%, #3B7546 100%);
    background-image: linear-gradient(180deg, #204628 0%, #2D6137 13%, #3B7546 100%)
}

.marutibook .menu li,
.marutibook .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.marutibook .menu a:hover,
.marutibook .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.marutibook .menu .select,
.marutibook .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.marutibook .setting {
    border-left-color: rgba(255, 255, 255, 0.1)
}

.marutibook .one_click {
    border-top-color: #F0DB55;
    background-repeat: repeat-x;
    background-position: 0 -3210px
}

.marutibook .one_click:hover,
.marutibook .one_click.bg-1click-maza-hover {
    background-position: 0 -3120px
}

.marutibook .one_click img {
    background-position: -94px -3970px
}

.marutibook .one_click.select {
    background-repeat: repeat-x;
    color: #604401;
    background-position: 0 -3180px
}

.marutibook .one_click.select:hover,
.marutibook .one_click.select.bg-1click-maza-select-hover {
    background-position: 0 -3150px
}

.marutibook .one_click.select img {
    background-position: -94px -3940px
}

.marutibook .time_zone span {
    color: #99B39E
}

.marutibook .menu-list li {
    border-bottom-color: #DDE7DE
}

.marutibook .menu-list a {
    color: #1A4F3D
}

.marutibook .menu-list a:hover {
    background-color: #C8D3CD
}

.marutibook .menu-list .select {
    background-color: #C8D3CD;
    color: #1A4F3D
}

.marutibook .menu-list .select:hover {
    background-color: #C8D3CD
}

.marutibook .menu-list .Go {
    background-position: 100% -1350px;
    height: 25px;
    width: 23px
}

.marutibook .menu-list .Go:hover,
.marutibook .menu-list .Go.Go-maza-hover {
    background-position: 100% -1375px
}

.marutibook .sub_path {
    background-color: #ADACAC
}

.marutibook .path {
    background: #33694F
}

.marutibook .path .path-back {
    background-position: 0 -3675px;
    height: 25px;
    width: 36px
}

.marutibook .path .path-back:hover,
.marutibook .path .path-back.path-back-maza-hover {
    background-position: 0 -3700px
}

.marutibook .path .path-back.select {
    background-position: 0 -3700px;
    height: 25px;
    width: 36px
}

.marutibook .game-highlight-wrap h3 {
    background-color: #32803A;
    color: #fff
}

.marutibook .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10545px
}

.marutibook .tab-markets {
    background: transparent
}

.marutibook .one_click-setting {
    color: #604401;
    background-repeat: repeat-x;
    background-color: #B29500;
    background-position: 0 -5046px
}

.marutibook .one_click-setting a {
    border-color: #604401
}

.marutibook .one_click-setting .a-edit {
    color: #604401
}

.marutibook .one_click-setting .a-edit img {
    background-position: -405px -2224px;
    height: 9px;
    width: 9px
}

.marutibook .one_click-stake {
    background-position: 0 -4402px;
    height: 31px;
    width: 414px
}

.marutibook .one_click-stake input,
.marutibook .one_click-stake textarea,
.marutibook .one_click-stake .member_select a,
.member_select .marutibook .one_click-stake a {
    border-color: #604401;
    color: #1E1E1E;
    background-color: #B09C02
}

.marutibook .one_click-stake input:focus,
.marutibook .one_click-stake textarea:focus,
.marutibook .one_click-stake .member_select a:focus,
.member_select .marutibook .one_click-stake a:focus {
    border-color: #fff;
    background-color: #A5AB38
}

.marutibook .menu .logout,
.marutibook .setting-wrap .logout {
    border-left-width: 0
}

.marutibook .menu .logout img,
.marutibook .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.marutibook .footer_fixed .main_wrap,
.marutibook .footer_fixed .header,
.marutibook .footer_fixed .footer-content {
    background-color: #F8F9E9
}

.marutibook .submit-wrap .btn-send span {
    background-color: #E9D753;
    color: #07571D
}

body.bdtwin247 {
    background-color: #F0ECE1
}

/* .bdtwin247 .btn.select,.bdtwin247 .select.btn-send,.bdtwin247 .pages a.select,.pages .bdtwin247 a.select,.bdtwin247 .calendarTable_inputBox input.select,.calendarTable_inputBox .bdtwin247 input.select,.bdtwin247 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .bdtwin247 textarea.select,.bdtwin247 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .bdtwin247 a.select,.bdtwin247 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .bdtwin247 a.select,.bdtwin247 .total_all .select.btn_replay,.total_all .bdtwin247 .select.btn_replay,.bdtwin247 .select.btn_expand,.bdtwin247 .select.add_member{
    color:#F8D61C;
    background:#444 url(../images/bdtwin247/btn-select-skyEX.jpg) repeat-x;
    border-color:#222
}
.bdtwin247 .btn.select:hover,.bdtwin247 .select.btn-send:hover,.bdtwin247 .pages a.select:hover,.pages .bdtwin247 a.select:hover,.bdtwin247 .calendarTable_inputBox input.select:hover,.calendarTable_inputBox .bdtwin247 input.select:hover,.bdtwin247 .calendarTable_inputBox textarea.select:hover,.calendarTable_inputBox .bdtwin247 textarea.select:hover,.bdtwin247 .calendarTable_inputBox .member_select a.select:hover,.calendarTable_inputBox .member_select .bdtwin247 a.select:hover,.bdtwin247 .member_select .calendarTable_inputBox a.select:hover,.member_select .calendarTable_inputBox .bdtwin247 a.select:hover,.bdtwin247 .total_all .select.btn_replay:hover,.total_all .bdtwin247 .select.btn_replay:hover,.bdtwin247 .select.btn_expand:hover,.bdtwin247 .select.add_member:hover{
    background:#444 url(../images/bdtwin247/btn-select-skyEX.jpg) repeat-x
}
.bdtwin247 .btn-send{
    background:#121212 url(../images/bdtwin247/bg-send-skyEX.png) repeat-x;
    color:#F8D61C;
    border-color:#000
}
.bdtwin247 .btn-send:hover{
    background:#464646 url(../images/bdtwin247/bg-send_hover-skyEX.png) repeat-x
}
.bdtwin247 .btn-send.disable{
    background:#A4A4A4 url(../images/bdtwin247/bg-send-disable-skyEX.png) repeat-x;
    border-color:#aaa;
    color:#E6DFCD
}
.bdtwin247 .kv{
    background:#000 url(../images/bdtwin247/bg-login_wrap-bdtwin247.png) no-repeat
}
.bdtwin247 .login-panel{
    color:#000
} */
.bdtwin247 .icon-login {
    background-position: 0 -425px;
    height: 12px;
    width: 12px
}

.bdtwin247 .announce-header h1:before {
    background: #FFB80C
}

/* .bdtwin247 .top{
    background:#000 url(../images/bdtwin247/bg-top-skyEX.jpg) repeat-x
} */
.bdtwin247 .header {
    color: #FFB80C
}

.bdtwin247 .header h1 {
    margin-top: -10px
}

.bdtwin247 .login-wrap li.user {
    background-position: -96px -5567px
}

.bdtwin247 .login-wrap .error {
    color: #F8D61C
}

.bdtwin247 .login-wrap .btn-signup {
    display: block;
    background-color: #666666;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#666), to(#333));
    background-image: -webkit-linear-gradient(top, #666 0%, #333 100%);
    background-image: linear-gradient(-180deg, #666 0%, #333 100%)
}

.bdtwin247 .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.bdtwin247 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #F2E9CF !important;
    background-color: #BF0000;
    border-radius: 3px
}

.bdtwin247 .account-wrap a {
    color: #FFB80C
}

.bdtwin247 .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8)
}

.bdtwin247 .account-wrap .a-wallet .nums {
    border: 1px solid #FFB80C
}

.bdtwin247 .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important
}

.bdtwin247 .account-wrap .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.25) !important
}

.bdtwin247 .account-wrap .loading-bar span {
    background: #fff
}

.bdtwin247 .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFB80C"/></svg>') no-repeat center center;
    background-size: contain
}

.bdtwin247 .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.8)
}

.bdtwin247 .account-wrap .head-refresh a {
    background-position: -80px -2075px;
    height: 25px;
    width: 30px
}

.bdtwin247 .account-wrap .account {
    background-position: 0 -925px;
    height: 25px;
    width: 110px
}

.bdtwin247 .account-wrap ul a {
    color: #1E1E1E
}

.bdtwin247 .account-wrap .logout a {
    color: #fff
}

.bdtwin247 .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, #FFCC2F), to(#FFB80C));
    background-image: -webkit-linear-gradient(top, #FFCC2F 8%, #FFB80C 100%);
    background-image: linear-gradient(180deg, #FFCC2F 8%, #FFB80C 100%);
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.75)
}

.bdtwin247 .menu-wrap a.menu-drop:after {
    border-top-color: #222
}

.bdtwin247 .menu li,
.bdtwin247 .setting-wrap li {
    border-right-color: rgba(69, 32, 7, 0.2)
}

.bdtwin247 .menu li ul a,
.bdtwin247 .setting-wrap li ul a {
    color: #FFB80C
}

.bdtwin247 .menu a,
.bdtwin247 .setting-wrap a {
    color: #222
}

.bdtwin247 .menu a:hover,
.bdtwin247 .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.bdtwin247 .menu .select,
.bdtwin247 .setting-wrap .select {
    box-shadow: inset 0 1px 3px 0 rgba(52, 9, 9, 0.5);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#DBBC68), color-stop(61%, #FFDC7A));
    background-image: -webkit-linear-gradient(top, #DBBC68 0%, #FFDC7A 61%);
    background-image: linear-gradient(180deg, #DBBC68 0%, #FFDC7A 61%);
    background-position: inherit
}

.bdtwin247 .menu .tag-new,
.bdtwin247 .setting-wrap .tag-new {
    color: #FFFFFF
}

.bdtwin247 .setting {
    border-left-color: rgba(69, 32, 7, 0.2)
}

.bdtwin247 .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23222" fill-rule="evenodd"/></svg>') no-repeat
}

.bdtwin247 .one_click {
    border-top-color: #FFB80C;
    color: #FFB80C !important;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(19%, #1B1B1B), color-stop(87%, #444));
    background-image: -webkit-linear-gradient(bottom, #1B1B1B 19%, #444 87%);
    background-image: linear-gradient(0deg, #1B1B1B 19%, #444 87%);
    background-position: inherit
}

.bdtwin247 .one_click img {
    background-position: -94px -4240px
}

.bdtwin247 .one_click.select {
    color: #1E1E1E !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#00C15D), to(#009347));
    background-image: -webkit-linear-gradient(top, #00C15D 0%, #009347 100%);
    background-image: linear-gradient(180deg, #00C15D 0%, #009347 100%);
    background-position: inherit
}

.bdtwin247 .one_click.select img {
    background-position: -94px -4210px
}

.bdtwin247 .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#009447), to(#00B557));
    background-image: -webkit-linear-gradient(top, #009447 0%, #00B557 100%);
    background-image: linear-gradient(180deg, #009447 0%, #00B557 100%)
}

.bdtwin247 .one_click:hover {
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(19%, #444), color-stop(87%, #1B1B1B));
    background-image: -webkit-linear-gradient(bottom, #444 19%, #1B1B1B 87%);
    background-image: linear-gradient(0deg, #444 19%, #1B1B1B 87%)
}

.bdtwin247 .slip_set-pop a:hover,
.bdtwin247 .oneclick_set-pop a:hover {
    text-decoration: none
}

.bdtwin247 .slip_set-pop .btn-send,
.bdtwin247 .oneclick_set-pop .btn-send {
    color: #F8D61C
}

.bdtwin247 .time_zone {
    color: #000
}

.bdtwin247 .time_zone span {
    color: #9A7816
}

.bdtwin247 .menu-list li {
    border-bottom-color: #EFE1C1
}

.bdtwin247 .menu-list a {
    color: #222
}

.bdtwin247 .menu-list a:hover {
    background-color: #F2DCA7
}

.bdtwin247 .menu-list .select {
    background-color: #F2DCA7;
    color: #222
}

.bdtwin247 .menu-list .select:hover {
    background-color: #F2DCA7
}

.bdtwin247 .menu-list .Go {
    background-position: 100% -1650px;
    height: 25px;
    width: 23px
}

.bdtwin247 .menu-list .Go:hover,
.bdtwin247 .menu-list .Go.Go-skyEX-hover {
    background-position: 100% -1675px
}

.bdtwin247 .path {
    background: #222
}

.bdtwin247 .path a {
    color: #FFB80C
}

.bdtwin247 .path .path-back {
    background-position: 0 -3975px;
    height: 25px;
    width: 36px
}

.bdtwin247 .path .path-back:hover,
.bdtwin247 .path .path-back.path-back-skyEX-hover {
    background-position: 0 -4000px
}

.bdtwin247 .path .path-back.select {
    background-position: 0 -4000px;
    height: 25px;
    width: 36px
}

.bdtwin247 .path-pop {
    background-color: #222
}

.bdtwin247 .login_to_go {
    background-color: #FFB80C
}

.bdtwin247 .login_to_go .close {
    background-position: -394px -1180px;
    height: 20px;
    width: 20px
}

.bdtwin247 .one_click-setting {
    color: #1E1E1E;
    background-repeat: repeat-x;
    background-color: #008841;
    background-position: 0 -5202px
}

.bdtwin247 .one_click-setting a {
    border-color: #005F2D
}

.bdtwin247 .one_click-setting .a-edit {
    color: #1E1E1E
}

.bdtwin247 .one_click-setting .a-edit img {
    background-position: -405px -2260px;
    height: 9px;
    width: 9px
}

.bdtwin247 .one_click-stake {
    background-position: 0 -4558px;
    height: 31px;
    width: 414px
}

.bdtwin247 .one_click-stake input,
.bdtwin247 .one_click-stake textarea,
.bdtwin247 .one_click-stake .member_select a,
.member_select .bdtwin247 .one_click-stake a {
    border-color: #005F2D;
    color: #fff;
    background-color: #1F8C53
}

.bdtwin247 .one_click-stake input:focus,
.bdtwin247 .one_click-stake textarea:focus,
.bdtwin247 .one_click-stake .member_select a:focus,
.member_select .bdtwin247 .one_click-stake a:focus {
    border-color: #fff;
    background-color: #00632F
}

.bdtwin247 .menu .logout,
.bdtwin247 .setting-wrap .logout {
    border-left-width: 0
}

.bdtwin247 .menu .logout img,
.bdtwin247 .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23222" fill-rule="evenodd"/></svg>') no-repeat
}

.bdtwin247 .footer_fixed .main_wrap,
.bdtwin247 .footer_fixed .header,
.bdtwin247 .footer_fixed .footer-content {
    background-color: #F0ECE1
}

.bdtwin247 .submit-wrap .btn-send span {
    background-color: #666
}

body.oceanexch1 {
    background-color: #EEEEEE
}

/* .oceanexch1 .btn.select,.oceanexch1 .select.btn-send,.oceanexch1 .pages a.select,.pages .oceanexch1 a.select,.oceanexch1 .calendarTable_inputBox input.select,.calendarTable_inputBox .oceanexch1 input.select,.oceanexch1 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .oceanexch1 textarea.select,.oceanexch1 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .oceanexch1 a.select,.oceanexch1 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .oceanexch1 a.select,.oceanexch1 .total_all .select.btn_replay,.total_all .oceanexch1 .select.btn_replay,.oceanexch1 .select.btn_expand,.oceanexch1 .select.add_member{
    background:#066BC1 url(../images/oceanexch1/btn-select-oceanexch1.png) repeat-x;
    border-color:#053E6E;
    color:#FFFFFF
}
.oceanexch1 .btn-send{
    background:#066BC1 url(../images/oceanexch1/bg-send-oceanexch1.png) repeat-x;
    border-color:#156DB2;
    border-radius:4px;
    color:#FFFFFF
}
.oceanexch1 .btn-send:hover{
    background:#0A8EFF url(../images/oceanexch1/bg-send_hover-oceanexch1.png) repeat-x
}
.oceanexch1 .btn-send.disable{
    background:#9BC3E6 url(../images/oceanexch1/bg-send-disable-oceanexch1.png) repeat-x;
    border-color:#85C2F6;
    color:#C3DFF6
}
.oceanexch1 .login_to_go{
    background-color:#FFFFFF
}
.oceanexch1 .kv{
    background:#066BC1 url(../images/oceanexch1/bg-login_wrap-oceanexch1.png) no-repeat
}
.oceanexch1 .icon-login{
    background-position:0 -472px;
    height:12px;
    width:12px
}
.oceanexch1 .announce-header h1:before{
    background:#066BC1
}
.oceanexch1 .top{
    background:#F1592A url(../images/oceanexch1/bg-top-oceanexch1.jpg) repeat-x
}
.oceanexch1 .header{
    color:#fff
}
.oceanexch1 .login-wrap .btn-login{
    background:#E71A05
}
.oceanexch1 .login-wrap li.user{
    background-position:-96px -5667px
}
.oceanexch1 .login-wrap .error{
    color:#FFFF00
}
.oceanexch1 .account-wrap li .refresh-bar{
    background:url(../images/livesports/refresh80-livesports.gif) no-repeat center
} */
.oceanexch1 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.oceanexch1 .account-wrap a {
    color: #fff
}

.oceanexch1 .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.oceanexch1 .account-wrap .loading-bar span {
    background: #fff
}

.oceanexch1 .account-wrap .head-refresh a {
    background-position: -80px -1950px;
    height: 25px;
    width: 30px
}

.oceanexch1 .account-wrap .account {
    background-position: 0 -800px;
    height: 25px;
    width: 110px
}

.oceanexch1 .account-wrap ul a {
    color: #1E1E1E
}

.oceanexch1 .account-wrap .logout a {
    color: #fff
}

.oceanexch1 .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#04559A), color-stop(0%, #04559A), color-stop(6%, #075792), color-stop(6%, #066BC1));
    background-image: -webkit-linear-gradient(top, #04559A 0%, #04559A 0%, #075792 6%, #066BC1 6%);
    background-image: linear-gradient(180deg, #04559A 0%, #04559A 0%, #075792 6%, #066BC1 6%);
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2)
}

.oceanexch1 .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.oceanexch1 .menu li,
.oceanexch1 .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.3)
}

.oceanexch1 .menu li ul a,
.oceanexch1 .setting-wrap li ul a {
    color: #FFFFFF
}

.oceanexch1 .menu li ul a:hover,
.oceanexch1 .setting-wrap li ul a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF
}

.oceanexch1 .menu a,
.oceanexch1 .setting-wrap a {
    color: #FFFFFF
}

.oceanexch1 .menu a:hover,
.oceanexch1 .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    text-decoration: underline
}

.oceanexch1 .menu .select,
.oceanexch1 .setting-wrap .select {
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.2)
}

.oceanexch1 .menu .logout,
.oceanexch1 .setting-wrap .logout {
    border-left-width: 0
}

.oceanexch1 .menu .logout img,
.oceanexch1 .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.oceanexch1 .live-menu {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.oceanexch1 .setting {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.oceanexch1 .time_zone {
    color: white
}

.oceanexch1 .time_zone span {
    color: rgba(255, 255, 255, 0.7)
}

.oceanexch1 .menu-list li {
    border-bottom-color: #DDDDDD
}

.oceanexch1 .menu-list a {
    color: #333333
}

.oceanexch1 .menu-list a:hover {
    background-color: #E8F5FF
}

.oceanexch1 .menu-list .select {
    background-color: #E8F5FF
}

.oceanexch1 .menu-list .Go {
    background-position: 100% -1400px;
    height: 25px;
    width: 23px
}

.oceanexch1 .menu-list .Go:hover,
.oceanexch1 .menu-list .Go.Go-oceanexch1-hover {
    background-position: 100% -1425px
}

.oceanexch1 .path {
    background-repeat: repeat-x;
    background-position: 0 -5973px
}

.oceanexch1 .path .path-back {
    background-position: 0 -3725px
}

.oceanexch1 .path .path-back:hover,
.oceanexch1 .path .path-back.path-back-oceanexch1-hover {
    background-position: 0 -3750px
}

.oceanexch1 .path .path-back.select {
    background-position: 0 -3750px
}

.oceanexch1 .path-pop {
    background-color: #1E1E1E
}

.oceanexch1 .path-pop a {
    background-position: -21px -2125px
}

.oceanexch1 .game-highlight-wrap h3 {
    background-color: #F1592A;
    color: #FFFFFF
}

.oceanexch1 .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10567px
}

.oceanexch1 .tab-markets {
    background: transparent
}

.oceanexch1 .submit-wrap .btn-send span {
    background-color: #053E6E
}

body.oceanbook1 {
    background-color: #EEEEEE
}

/* .oceanbook1 .btn.select,.oceanbook1 .select.btn-send,.oceanbook1 .pages a.select,.pages .oceanbook1 a.select,.oceanbook1 .calendarTable_inputBox input.select,.calendarTable_inputBox .oceanbook1 input.select,.oceanbook1 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .oceanbook1 textarea.select,.oceanbook1 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .oceanbook1 a.select,.oceanbook1 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .oceanbook1 a.select,.oceanbook1 .total_all .select.btn_replay,.total_all .oceanbook1 .select.btn_replay,.oceanbook1 .select.btn_expand,.oceanbook1 .select.add_member{
    background:#066BC1 url(../images/oceanbook1/btn-select-oceanbook1.png) repeat-x;
    border-color:#053E6E;
    color:#FFFFFF
}
.oceanbook1 .btn-send{
    background:#066BC1 url(../images/oceanbook1/bg-send-oceanbook1.png) repeat-x;
    border-color:#156DB2;
    border-radius:4px;
    color:#FFFFFF
}
.oceanbook1 .btn-send:hover{
    background:#0A8EFF url(../images/oceanbook1/bg-send_hover-oceanbook1.png) repeat-x
}
.oceanbook1 .btn-send.disable{
    background:#9BC3E6 url(../images/oceanbook1/bg-send-disable-oceanbook1.png) repeat-x;
    border-color:#85C2F6;
    color:#C3DFF6
}
.oceanbook1 .login_to_go{
    background-color:#FFFFFF
}
.oceanbook1 .kv{
    background:#066BC1 url(../images/oceanbook1/bg-login_wrap-oceanbook1.png) no-repeat
}
.oceanbook1 .announce-header h1:before{
    background:#066BC1
}
.oceanbook1 .top{
    background:#F1592A url(../images/oceanbook1/bg-top-oceanbook1.jpg) repeat-x
}
.oceanbook1 .header{
    color:#fff
}
.oceanbook1 .login-wrap .btn-login{
    background:#E71A05
}
.oceanbook1 .login-wrap li.user{
    background-position:-96px -5667px
}
.oceanbook1 .login-wrap .error{
    color:#FFFF00
}
.oceanbook1 .account-wrap li .refresh-bar{
    background:url(../images/livesports/refresh80-livesports.gif) no-repeat center
} */
.oceanbook1 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.oceanbook1 .account-wrap a {
    color: #fff
}

.oceanbook1 .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.oceanbook1 .account-wrap .loading-bar span {
    background: #fff
}

.oceanbook1 .account-wrap .head-refresh a {
    background-position: -80px -1950px;
    height: 25px;
    width: 30px
}

.oceanbook1 .account-wrap .account {
    background-position: 0 -800px;
    height: 25px;
    width: 110px
}

.oceanbook1 .account-wrap ul a {
    color: #1E1E1E
}

.oceanbook1 .account-wrap .logout a {
    color: #fff
}

.oceanbook1 .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#04559A), color-stop(0%, #04559A), color-stop(6%, #075792), color-stop(6%, #066BC1));
    background-image: -webkit-linear-gradient(top, #04559A 0%, #04559A 0%, #075792 6%, #066BC1 6%);
    background-image: linear-gradient(180deg, #04559A 0%, #04559A 0%, #075792 6%, #066BC1 6%);
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2)
}

.oceanbook1 .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.oceanbook1 .menu li,
.oceanbook1 .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.3)
}

.oceanbook1 .menu li ul a,
.oceanbook1 .setting-wrap li ul a {
    color: #FFFFFF
}

.oceanbook1 .menu li ul a:hover,
.oceanbook1 .setting-wrap li ul a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF
}

.oceanbook1 .menu a,
.oceanbook1 .setting-wrap a {
    color: #FFFFFF
}

.oceanbook1 .menu a:hover,
.oceanbook1 .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    text-decoration: underline
}

.oceanbook1 .menu .select,
.oceanbook1 .setting-wrap .select {
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.2)
}

.oceanbook1 .menu .logout,
.oceanbook1 .setting-wrap .logout {
    border-left-width: 0
}

.oceanbook1 .menu .logout img,
.oceanbook1 .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.oceanbook1 .live-menu {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.oceanbook1 .setting {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.oceanbook1 .time_zone {
    color: white
}

.oceanbook1 .time_zone span {
    color: rgba(255, 255, 255, 0.7)
}

.oceanbook1 .menu-list li {
    border-bottom-color: #DDDDDD
}

.oceanbook1 .menu-list a {
    color: #333333
}

.oceanbook1 .menu-list a:hover {
    background-color: #E8F5FF
}

.oceanbook1 .menu-list .select {
    background-color: #E8F5FF
}

.oceanbook1 .menu-list .Go {
    background-position: 100% -1400px;
    height: 25px;
    width: 23px
}

.oceanbook1 .menu-list .Go:hover,
.oceanbook1 .menu-list .Go.Go-oceanexch1-hover {
    background-position: 100% -1425px
}

.oceanbook1 .path {
    background-repeat: repeat-x;
    background-position: 0 -5973px
}

.oceanbook1 .path .path-back {
    background-position: 0 -3725px
}

.oceanbook1 .path .path-back:hover,
.oceanbook1 .path .path-back.path-back-oceanexch1-hover {
    background-position: 0 -3750px
}

.oceanbook1 .path .path-back.select {
    background-position: 0 -3750px
}

.oceanbook1 .path-pop {
    background-color: #1E1E1E
}

.oceanbook1 .path-pop a {
    background-position: -21px -2125px
}

.oceanbook1 .game-highlight-wrap h3 {
    background-color: #F1592A;
    color: #FFFFFF
}

.oceanbook1 .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10567px
}

.oceanbook1 .tab-markets {
    background: transparent
}

.oceanbook1 .submit-wrap .btn-send span {
    background-color: #053E6E
}

body.probetx {
    background-color: #EDEDED
}

/* .probetx .btn.select,.probetx .select.btn-send,.probetx .pages a.select,.pages .probetx a.select,.probetx .calendarTable_inputBox input.select,.calendarTable_inputBox .probetx input.select,.probetx .calendarTable_inputBox textarea.select,.calendarTable_inputBox .probetx textarea.select,.probetx .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .probetx a.select,.probetx .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .probetx a.select,.probetx .total_all .select.btn_replay,.total_all .probetx .select.btn_replay,.probetx .select.btn_expand,.probetx .select.add_member{
    color:#fff;
    background:#77A435 url(../images/probetx/btn-select-probetx.jpg) repeat-x;
    border-color:#265E04
}
.probetx .btn-send{
    background:#142240 url(../images/probetx/bg-send-probetx.png) repeat-x;
    border:1px solid #3A6EDE;
    border-radius:4px;
    color:#fff
}
.probetx .btn-send:hover{
    background:#1947AC url(../images/probetx/bg-send_hover-probetx.png) repeat-x
}
.probetx .btn-send.disable{
    background:#8F99AF url(../images/probetx/bg-send-disable-probetx.png) repeat-x;
    border-color:#A9C2F8;
    color:#BFD0EA
}
.probetx .login_to_go{
    background-color:#83AE17
}
.probetx .kv{
    background:#14213D url(../images/probetx/bg-login_wrap-probetx.png) no-repeat
} */
.probetx .login-panel {
    color: #1E1E1E
}

.probetx .login-panel input,
.probetx .login-panel textarea,
.probetx .login-panel .member_select a,
.member_select .probetx .login-panel a {
    border-color: #3E8142
}

.probetx .login-panel .error {
    color: #1E1E1E
}

.probetx .icon-login {
    background-position: 0 -401px;
    height: 12px;
    width: 12px
}

.probetx .announce-header h1:before {
    background: #83AE17
}

/* .probetx .top{
    background:#14213D url(../images/probetx/bg-top-probetx.jpg) repeat-x
} */
.probetx .header {
    color: #fff
}

.probetx .header h1 {
    margin-top: -15px
}

.probetx .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#E28614), to(#FDB52D));
    background-image: -webkit-linear-gradient(bottom, #E28614 0%, #FDB52D 100%);
    background-image: linear-gradient(0deg, #E28614 0%, #FDB52D 100%)
}

.probetx .login-wrap li.user {
    background-position: -96px -5517px
}

.probetx .login-wrap .error {
    color: rgba(255, 255, 255, 0.8)
}

.probetx .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.probetx .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.probetx .account-wrap a {
    color: #fff
}

.probetx .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.6);
    background-color: #3e5588
}

.probetx .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.probetx .account-wrap .loading-bar span {
    background: #fff
}

.probetx .account-wrap .a-refresh {
    background-color: #3e5588
}

.probetx .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.7)
}

.probetx .account-wrap .head-refresh a {
    background-position: -80px -2000px;
    height: 25px;
    width: 30px
}

.probetx .account-wrap .account {
    background-position: 0 -850px;
    height: 25px;
    width: 110px
}

.probetx .account-wrap ul a {
    color: #1E1E1E
}

.probetx .account-wrap .logout a {
    color: #fff
}

.probetx .menu-wrap {
    background-image: -webkit-linear-gradient(89deg, #61A123 3%, #B3CE60 99%);
    background-image: linear-gradient(1deg, #61A123 3%, #B3CE60 99%);
    border: 1px solid #417505
}

.probetx .menu-wrap a.menu-drop:after {
    border-top-color: #333
}

.probetx .menu-wrap a.select.menu-drop:after {
    border-top-color: #FFF;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #588D23), to(#609F22));
    background-image: -webkit-linear-gradient(top, #588D23 20%, #609F22 100%);
    background-image: linear-gradient(180deg, #588D23 20%, #609F22 100%);
    box-shadow: inset 0 1px 3px 0 rgba(6, 23, 7, 0.5)
}

.probetx .menu li,
.probetx .setting-wrap li {
    border-right-color: rgba(9, 68, 16, 0.3)
}

.probetx .menu li ul a,
.probetx .setting-wrap li ul a {
    color: #fff
}

.probetx .menu a,
.probetx .setting-wrap a {
    color: #333
}

.probetx .menu a:hover,
.probetx .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.probetx .menu .select,
.probetx .setting-wrap .select {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #588D23), to(#609F22));
    background-image: -webkit-linear-gradient(top, #588D23 20%, #609F22 100%);
    background-image: linear-gradient(180deg, #588D23 20%, #609F22 100%);
    box-shadow: inset 0 1px 3px 0 rgba(6, 23, 7, 0.5)
}

.probetx .menu .logout,
.probetx .setting-wrap .logout {
    border-left-width: 0
}

.probetx .menu .logout img,
.probetx .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23333" fill-rule="evenodd"/></svg>') no-repeat
}

.probetx .menu .tag-new,
.probetx .setting-wrap .tag-new {
    color: #FFFFFF
}

.probetx .live-menu {
    border-left-color: rgba(9, 68, 16, 0.2)
}

.probetx .live-menu img {
    background-position: -89px -4620px
}

.probetx .setting {
    border-left-color: rgba(9, 68, 16, 0.2)
}

.probetx .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23333" fill-rule="evenodd"/></svg>') no-repeat
}

.probetx .one_click {
    border-top-color: #F98181;
    color: #fff !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#374F82), to(#2B3E65));
    background-image: -webkit-linear-gradient(top, #374F82 0%, #2B3E65 100%);
    background-image: linear-gradient(180deg, #374F82 0%, #2B3E65 100%);
    background-position: inherit
}

.probetx .one_click img {
    background-position: -94px -4030px
}

.probetx .one_click.select {
    color: #fff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(18%, #E05951), to(#B0463F));
    background-image: -webkit-linear-gradient(top, #E05951 18%, #B0463F 100%);
    background-image: linear-gradient(180deg, #E05951 18%, #B0463F 100%);
    background-position: inherit
}

.probetx .one_click.select img {
    background-position: -94px -4000px
}

.probetx .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(18%, #B0463F), to(#E05951));
    background-image: -webkit-linear-gradient(top, #B0463F 18%, #E05951 100%);
    background-image: linear-gradient(180deg, #B0463F 18%, #E05951 100%)
}

.probetx .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2B3E65), to(#374F82));
    background-image: -webkit-linear-gradient(top, #2B3E65 0%, #374F82 100%);
    background-image: linear-gradient(180deg, #2B3E65 0%, #374F82 100%)
}

.probetx .time_zone {
    color: #333333
}

.probetx .time_zone span {
    color: rgba(51, 51, 51, 0.5)
}

.probetx .menu-list li {
    border-bottom-color: #D2D6E2
}

.probetx .menu-list a {
    color: #0E2E75
}

.probetx .menu-list a:hover {
    background-color: #E6EFD1
}

.probetx .menu-list .select {
    background-color: #D1DDEF;
    color: #1E1E1E
}

.probetx .menu-list .select:hover {
    background-color: #D1DDEF
}

.probetx .menu-list .Go {
    background-position: 100% -1500px;
    height: 25px;
    width: 23px
}

.probetx .menu-list .Go:hover,
.probetx .menu-list .Go.Go-probetx-hover {
    background-position: 100% -1525px
}

.probetx .path {
    background-repeat: repeat-x;
    background-position: 0 -6023px
}

.probetx .path a {
    color: #B7F875
}

.probetx .path .path-back {
    background-position: 0 -3825px
}

.probetx .path .path-back:hover,
.probetx .path .path-back.path-back-probetx-hover {
    background-position: 0 -3850px
}

.probetx .path .path-back.select {
    background-position: 0 -3850px
}

.probetx .path-pop {
    background-color: #14213D
}

.probetx .path-pop a {
    color: #B7F875
}

.probetx .game-highlight-wrap h3 {
    background-color: #99C04D
}

.probetx .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10611px
}

.probetx .tab-markets {
    background: transparent
}

.probetx .one_click-setting {
    color: #fff;
    background-repeat: repeat-x;
    background-color: #A4413B;
    background-position: 0 -5108px
}

.probetx .one_click-setting a {
    border-color: #7D3232
}

.probetx .one_click-setting .a-edit {
    color: #fff
}

.probetx .one_click-setting .a-edit img {
    background-position: -405px -2242px;
    height: 9px;
    width: 9px
}

.probetx .one_click-stake {
    background-position: 0 -4464px;
    height: 31px;
    width: 414px
}

.probetx .one_click-stake input,
.probetx .one_click-stake textarea,
.probetx .one_click-stake .member_select a,
.member_select .probetx .one_click-stake a {
    border-color: #7D3232;
    color: #1E1E1E;
    background-color: #C37E81
}

.probetx .one_click-stake input:focus,
.probetx .one_click-stake textarea:focus,
.probetx .one_click-stake .member_select a:focus,
.member_select .probetx .one_click-stake a:focus {
    border-color: #113687;
    background-color: #F4DDDB
}

.probetx .function-wrap {
    background-color: #D5E1E6
}

.probetx .submit-wrap .btn-send span {
    background-color: #EB5E55
}

body.alphaexch {
    background-color: #F8F9E9
}

/* .alphaexch .btn.select,.alphaexch .select.btn-send,.alphaexch .pages a.select,.pages .alphaexch a.select,.alphaexch .calendarTable_inputBox input.select,.calendarTable_inputBox .alphaexch input.select,.alphaexch .calendarTable_inputBox textarea.select,.calendarTable_inputBox .alphaexch textarea.select,.alphaexch .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .alphaexch a.select,.alphaexch .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .alphaexch a.select,.alphaexch .total_all .select.btn_replay,.total_all .alphaexch .select.btn_replay,.alphaexch .select.btn_expand,.alphaexch .select.add_member{
    background:#7BB54C url(../images/alphaexch/btn-select-alphaexch.jpg) repeat-x;
    border-color:#32803A
}
.alphaexch .btn-send{
    background:#38703D url(../images/alphaexch/bg-send-alphaexch.png) repeat-x;
    color:#fff;
    border-color:#07571D
}
.alphaexch .btn-send:hover{
    background:#48955B url(../images/alphaexch/bg-send_hover-alphaexch.png) repeat-x
}
.alphaexch .btn-send.disable{
    background:#C3D5C5 url(../images/alphaexch/bg-send-disable-alphaexch.png) repeat-x;
    border-color:#A2B4A7;
    color:#EFFCF4
}
.alphaexch .kv{
    background:#48955B url(../images/alphaexch/bg-login_wrap-alphaexch.jpg) no-repeat
} */
.alphaexch .login-panel {
    color: #fff
}

.alphaexch .login-panel dt {
    color: #000
}

.alphaexch .icon-login {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

/* .alphaexch .top{
    background:#307234 url(../images/alphaexch/bg-top-alphaexch.jpg) repeat-x
} */
.alphaexch .header {
    color: #fff
}

.alphaexch .header h1 {
    margin-top: -5px
}

.alphaexch .login-wrap li.user {
    background-position: -96px -4992px
}

.alphaexch .login-wrap .error {
    color: #EFDA54
}

.alphaexch .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.alphaexch .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #CA1F00;
    border-radius: 3px
}

.alphaexch .account-wrap a {
    color: #fff
}

.alphaexch .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4)
}

.alphaexch .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.alphaexch .account-wrap .loading-bar span {
    background: #fff
}

.alphaexch .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.alphaexch .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.alphaexch .account-wrap .a-wallet .red {
    background-color: #fff;
    padding: 0 3px;
    border-radius: 2px
}

.alphaexch .account-wrap .head-refresh a {
    background-position: -80px -1275px;
    height: 25px;
    width: 30px
}

.alphaexch .account-wrap .account {
    background-position: 0 -125px;
    height: 25px;
    width: 110px
}

.alphaexch .account-wrap ul a {
    color: #1E1E1E
}

.alphaexch .account-wrap .logout a {
    color: #fff
}

.alphaexch .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#204628), color-stop(13%, #2D6137), to(#3B7546));
    background-image: -webkit-linear-gradient(top, #204628 0%, #2D6137 13%, #3B7546 100%);
    background-image: linear-gradient(180deg, #204628 0%, #2D6137 13%, #3B7546 100%)
}

.alphaexch .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.alphaexch .menu li,
.alphaexch .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.alphaexch .menu a:hover,
.alphaexch .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.alphaexch .menu .select,
.alphaexch .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.alphaexch .setting {
    border-left-color: rgba(255, 255, 255, 0.1)
}

.alphaexch .one_click {
    border-top-color: #F0DB55;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(14%, #2B6B37), to(#014E0F));
    background-image: -webkit-linear-gradient(top, #2B6B37 14%, #014E0F 100%);
    background-image: linear-gradient(180deg, #2B6B37 14%, #014E0F 100%);
    background-position: inherit
}

.alphaexch .one_click img {
    background-position: -94px -2590px
}

.alphaexch .one_click.select {
    color: #604401;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BCA300), to(#BCA300));
    background-image: -webkit-linear-gradient(top, #BCA300 0%, #BCA300 100%);
    background-image: linear-gradient(180deg, #BCA300 0%, #BCA300 100%);
    background-position: inherit
}

.alphaexch .one_click.select img {
    background-position: -94px -2560px
}

.alphaexch .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(27%, #BDA500), to(#C8B71D));
    background-image: -webkit-linear-gradient(top, #BDA500 27%, #C8B71D 100%);
    background-image: linear-gradient(180deg, #BDA500 27%, #C8B71D 100%)
}

.alphaexch .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(14%, #014E0F), to(#2B6B37));
    background-image: -webkit-linear-gradient(top, #014E0F 14%, #2B6B37 100%);
    background-image: linear-gradient(180deg, #014E0F 14%, #2B6B37 100%)
}

.alphaexch .time_zone span {
    color: #99B39E
}

.alphaexch .menu-list li {
    border-bottom-color: #DDE7DE
}

.alphaexch .menu-list a {
    color: #1A4F3D
}

.alphaexch .menu-list a:hover {
    background-color: #C8D3CD
}

.alphaexch .menu-list .select {
    background-color: #C8D3CD;
    color: #1A4F3D
}

.alphaexch .menu-list .select:hover {
    background-color: #C8D3CD
}

.alphaexch .menu-list .Go {
    background-position: 100% -250px;
    height: 25px;
    width: 23px
}

.alphaexch .menu-list .Go:hover,
.alphaexch .menu-list .Go.Go-alphaexch-hover {
    background-position: 100% -275px
}

.alphaexch .sub_path {
    background-color: #ADACAC
}

.alphaexch .path {
    background: #33694F
}

.alphaexch .path .path-back {
    background-position: 0 -2475px;
    height: 25px;
    width: 36px
}

.alphaexch .path .path-back:hover,
.alphaexch .path .path-back.path-back-alphaexch-hover {
    background-position: 0 -2500px
}

.alphaexch .path .path-back.select {
    background-position: 0 -2500px;
    height: 25px;
    width: 36px
}

.alphaexch .game-highlight-wrap h3 {
    background-color: #32803A;
    color: #fff
}

.alphaexch .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10083px
}

.alphaexch .tab-markets {
    background: transparent
}

.alphaexch .one_click-setting {
    color: #604401;
    background-repeat: repeat-x;
    background-color: #B29500;
    background-position: 0 -4296px
}

.alphaexch .one_click-setting a {
    border-color: #604401
}

.alphaexch .one_click-setting .a-edit {
    color: #604401
}

.alphaexch .one_click-setting .a-edit img {
    background-position: -405px -2044px;
    height: 9px;
    width: 9px
}

.alphaexch .one_click-stake {
    background-position: 0 -3652px;
    height: 31px;
    width: 414px
}

.alphaexch .one_click-stake input,
.alphaexch .one_click-stake textarea,
.alphaexch .one_click-stake .member_select a,
.member_select .alphaexch .one_click-stake a {
    border-color: #604401;
    color: #1E1E1E;
    background-color: #B09C02
}

.alphaexch .one_click-stake input:focus,
.alphaexch .one_click-stake textarea:focus,
.alphaexch .one_click-stake .member_select a:focus,
.member_select .alphaexch .one_click-stake a:focus {
    border-color: #fff;
    background-color: #A5AB38
}

.alphaexch .menu .logout,
.alphaexch .setting-wrap .logout {
    border-left-width: 0
}

.alphaexch .menu .logout img,
.alphaexch .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.alphaexch .footer_fixed .main_wrap,
.alphaexch .footer_fixed .header,
.alphaexch .footer_fixed .footer-content {
    background-color: #F8F9E9
}

.alphaexch .submit-wrap .btn-send span {
    background-color: #E9D753;
    color: #07571D
}

body.alphaexchlive {
    background-color: #F8F9E9
}

/* .alphaexchlive .btn.select,.alphaexchlive .select.btn-send,.alphaexchlive .pages a.select,.pages .alphaexchlive a.select,.alphaexchlive .calendarTable_inputBox input.select,.calendarTable_inputBox .alphaexchlive input.select,.alphaexchlive .calendarTable_inputBox textarea.select,.calendarTable_inputBox .alphaexchlive textarea.select,.alphaexchlive .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .alphaexchlive a.select,.alphaexchlive .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .alphaexchlive a.select,.alphaexchlive .total_all .select.btn_replay,.total_all .alphaexchlive .select.btn_replay,.alphaexchlive .select.btn_expand,.alphaexchlive .select.add_member{
    background:#7BB54C url(../images/alphaexchlive/btn-select-alphaexchlive.jpg) repeat-x;
    border-color:#32803A
}
.alphaexchlive .btn-send{
    background:#38703D url(../images/alphaexchlive/bg-send-alphaexchlive.png) repeat-x;
    color:#fff;
    border-color:#07571D
}
.alphaexchlive .btn-send:hover{
    background:#48955B url(../images/alphaexchlive/bg-send_hover-alphaexchlive.png) repeat-x
}
.alphaexchlive .btn-send.disable{
    background:#C3D5C5 url(../images/alphaexchlive/bg-send-disable-alphaexchlive.png) repeat-x;
    border-color:#A2B4A7;
    color:#EFFCF4
}
.alphaexchlive .kv{
    background:#48955B url(../images/alphaexchlive/bg-login_wrap-alphaexchlive.jpg) no-repeat
} */
.alphaexchlive .login-panel {
    color: #fff
}

.alphaexchlive .login-panel dt {
    color: #000
}

.alphaexchlive .icon-login {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

/* .alphaexchlive .top{
    background:#307234 url(../images/alphaexchlive/bg-top-alphaexchlive.jpg) repeat-x
} */
.alphaexchlive .header {
    color: #fff
}

.alphaexchlive .header h1 {
    margin-top: -5px
}

.alphaexchlive .login-wrap li.user {
    background-position: -96px -5017px
}

.alphaexchlive .login-wrap .error {
    color: #EFDA54
}

.alphaexchlive .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.alphaexchlive .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #CA1F00;
    border-radius: 3px
}

.alphaexchlive .account-wrap a {
    color: #fff
}

.alphaexchlive .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4)
}

.alphaexchlive .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.alphaexchlive .account-wrap .loading-bar span {
    background: #fff
}

.alphaexchlive .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.alphaexchlive .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.alphaexchlive .account-wrap .a-wallet .red {
    background-color: #fff;
    padding: 0 3px;
    border-radius: 2px
}

.alphaexchlive .account-wrap .head-refresh a {
    background-position: -80px -1300px;
    height: 25px;
    width: 30px
}

.alphaexchlive .account-wrap .account {
    background-position: 0 -150px;
    height: 25px;
    width: 110px
}

.alphaexchlive .account-wrap ul a {
    color: #1E1E1E
}

.alphaexchlive .account-wrap .logout a {
    color: #fff
}

.alphaexchlive .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#204628), color-stop(13%, #2D6137), to(#3B7546));
    background-image: -webkit-linear-gradient(top, #204628 0%, #2D6137 13%, #3B7546 100%);
    background-image: linear-gradient(180deg, #204628 0%, #2D6137 13%, #3B7546 100%)
}

.alphaexchlive .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.alphaexchlive .menu li,
.alphaexchlive .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.alphaexchlive .menu a:hover,
.alphaexchlive .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.alphaexchlive .menu .select,
.alphaexchlive .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.alphaexchlive .setting {
    border-left-color: rgba(255, 255, 255, 0.1)
}

.alphaexchlive .one_click {
    border-top-color: #F0DB55;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(14%, #2B6B37), to(#014E0F));
    background-image: -webkit-linear-gradient(top, #2B6B37 14%, #014E0F 100%);
    background-image: linear-gradient(180deg, #2B6B37 14%, #014E0F 100%);
    background-position: inherit
}

.alphaexchlive .one_click img {
    background-position: -94px -2650px
}

.alphaexchlive .one_click.select {
    color: #604401;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BCA300), to(#BCA300));
    background-image: -webkit-linear-gradient(top, #BCA300 0%, #BCA300 100%);
    background-image: linear-gradient(180deg, #BCA300 0%, #BCA300 100%);
    background-position: inherit
}

.alphaexchlive .one_click.select img {
    background-position: -94px -2620px
}

.alphaexchlive .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(27%, #BDA500), to(#C8B71D));
    background-image: -webkit-linear-gradient(top, #BDA500 27%, #C8B71D 100%);
    background-image: linear-gradient(180deg, #BDA500 27%, #C8B71D 100%)
}

.alphaexchlive .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(14%, #014E0F), to(#2B6B37));
    background-image: -webkit-linear-gradient(top, #014E0F 14%, #2B6B37 100%);
    background-image: linear-gradient(180deg, #014E0F 14%, #2B6B37 100%)
}

.alphaexchlive .time_zone span {
    color: #99B39E
}

.alphaexchlive .menu-list li {
    border-bottom-color: #DDE7DE
}

.alphaexchlive .menu-list a {
    color: #1A4F3D
}

.alphaexchlive .menu-list a:hover {
    background-color: #C8D3CD
}

.alphaexchlive .menu-list .select {
    background-color: #C8D3CD;
    color: #1A4F3D
}

.alphaexchlive .menu-list .select:hover {
    background-color: #C8D3CD
}

.alphaexchlive .menu-list .Go {
    background-position: 100% -300px;
    height: 25px;
    width: 23px
}

.alphaexchlive .menu-list .Go:hover,
.alphaexchlive .menu-list .Go.Go-alphaexchlive-hover {
    background-position: 100% -325px
}

.alphaexchlive .sub_path {
    background-color: #ADACAC
}

.alphaexchlive .path {
    background: #33694F
}

.alphaexchlive .path .path-back {
    background-position: 0 -2525px;
    height: 25px;
    width: 36px
}

.alphaexchlive .path .path-back:hover,
.alphaexchlive .path .path-back.path-back-alphaexchlive-hover {
    background-position: 0 -2550px
}

.alphaexchlive .path .path-back.select {
    background-position: 0 -2550px;
    height: 25px;
    width: 36px
}

.alphaexchlive .game-highlight-wrap h3 {
    background-color: #32803A;
    color: #fff
}

.alphaexchlive .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10105px
}

.alphaexchlive .tab-markets {
    background: transparent
}

.alphaexchlive .one_click-setting {
    color: #604401;
    background-repeat: repeat-x;
    background-color: #B29500;
    background-position: 0 -4327px
}

.alphaexchlive .one_click-setting a {
    border-color: #604401
}

.alphaexchlive .one_click-setting .a-edit {
    color: #604401
}

.alphaexchlive .one_click-setting .a-edit img {
    background-position: -405px -2053px;
    height: 9px;
    width: 9px
}

.alphaexchlive .one_click-stake {
    background-position: 0 -3683px;
    height: 31px;
    width: 414px
}

.alphaexchlive .one_click-stake input,
.alphaexchlive .one_click-stake textarea,
.alphaexchlive .one_click-stake .member_select a,
.member_select .alphaexchlive .one_click-stake a {
    border-color: #604401;
    color: #1E1E1E;
    background-color: #B09C02
}

.alphaexchlive .one_click-stake input:focus,
.alphaexchlive .one_click-stake textarea:focus,
.alphaexchlive .one_click-stake .member_select a:focus,
.member_select .alphaexchlive .one_click-stake a:focus {
    border-color: #fff;
    background-color: #A5AB38
}

.alphaexchlive .menu .logout,
.alphaexchlive .setting-wrap .logout {
    border-left-width: 0
}

.alphaexchlive .menu .logout img,
.alphaexchlive .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.alphaexchlive .footer_fixed .main_wrap,
.alphaexchlive .footer_fixed .header,
.alphaexchlive .footer_fixed .footer-content {
    background-color: #F8F9E9
}

.alphaexchlive .submit-wrap .btn-send span {
    background-color: #E9D753;
    color: #07571D
}

body.maxexch9 {
    background-color: #E5E5DF
}

/* .maxexch9 .btn.select,.maxexch9 .select.btn-send,.maxexch9 .pages a.select,.pages .maxexch9 a.select,.maxexch9 .calendarTable_inputBox input.select,.calendarTable_inputBox .maxexch9 input.select,.maxexch9 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .maxexch9 textarea.select,.maxexch9 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .maxexch9 a.select,.maxexch9 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .maxexch9 a.select,.maxexch9 .total_all .select.btn_replay,.total_all .maxexch9 .select.btn_replay,.maxexch9 .select.btn_expand,.maxexch9 .select.add_member{
    background:#F2D65D url(../images/maxexch9/btn-select-maxexch9.jpg) repeat-x;
    border-color:#897700
}
.maxexch9 .btn-send{
    background:#BD9406 url(../images/maxexch9/bg-send-maxexch9.png) repeat-x;
    border-color:#897700
}
.maxexch9 .btn-send:hover{
    background:#EAC535 url(../images/maxexch9/bg-send_hover-maxexch9.png) repeat-x
}
.maxexch9 .btn-send.disable{
    background:#EBDEB4 url(../images/maxexch9/bg-send-disable-maxexch9.png) repeat-x;
    border-color:#A2B4A7
}
.maxexch9 .kv{
    background:#122343 url(../images/maxexch9/bg-login_wrap-maxexch9.jpg) no-repeat
}
.maxexch9 .icon-login{
    background-position:0 -353px;
    height:12px;
    width:12px
}
.maxexch9 .top{
    background:#C4A73F url(../images/maxexch9/bg-top-maxexch9.jpg) repeat-x
} */
.maxexch9 .header h1 {
    margin-top: -9px
}

.maxexch9 .login-wrap .error {
    color: #024168
}

.maxexch9 .login-wrap li.user {
    background-position: -96px -5392px
}

.maxexch9 .account-wrap .head-refresh a {
    background-position: -80px -1850px;
    height: 25px;
    width: 30px
}

.maxexch9 .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.4)
}

.maxexch9 .account-wrap .account {
    background-position: 0 -700px;
    height: 25px;
    width: 110px
}

.maxexch9 .menu-wrap {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#162231), color-stop(70%, #23354B));
    background-image: -webkit-linear-gradient(bottom, #162231 0%, #23354B 70%);
    background-image: linear-gradient(0deg, #162231 0%, #23354B 70%);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.4)
}

.maxexch9 .menu li,
.maxexch9 .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.maxexch9 .menu li ul,
.maxexch9 .setting-wrap li ul {
    background-color: #22354A
}

.maxexch9 .menu li li,
.maxexch9 .setting-wrap li li {
    border-bottom-color: #466071
}

.maxexch9 .menu a:hover,
.maxexch9 .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.maxexch9 .menu .select,
.maxexch9 .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.maxexch9 .setting {
    border-left-color: rgba(255, 255, 255, 0.1)
}

.maxexch9 .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23C4A73F" fill-rule="evenodd"/></svg>') no-repeat
}

.maxexch9 .one_click {
    border-top-color: #D56525;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #2D4561), to(#1E2E41));
    background-image: -webkit-linear-gradient(top, #2D4561 12%, #1E2E41 100%);
    background-image: linear-gradient(180deg, #2D4561 12%, #1E2E41 100%);
    background-position: inherit
}

.maxexch9 .one_click img {
    background-position: -94px -3850px
}

.maxexch9 .one_click.select {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #B83A08), color-stop(77%, #912B06));
    background-image: -webkit-linear-gradient(top, #B83A08 10%, #912B06 77%);
    background-image: linear-gradient(180deg, #B83A08 10%, #912B06 77%);
    background-position: inherit
}

.maxexch9 .one_click.select img {
    background-position: -94px -3820px
}

.maxexch9 .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #912B06), color-stop(77%, #B83A08));
    background-image: -webkit-linear-gradient(top, #912B06 10%, #B83A08 77%);
    background-image: linear-gradient(180deg, #912B06 10%, #B83A08 77%)
}

.maxexch9 .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #1E2E41), to(#2D4561));
    background-image: -webkit-linear-gradient(top, #1E2E41 12%, #2D4561 100%);
    background-image: linear-gradient(180deg, #1E2E41 12%, #2D4561 100%)
}

.maxexch9 .slip_set-pop a,
.maxexch9 .oneclick_set-pop a {
    color: #1E1E1E
}

.maxexch9 .time_zone {
    color: #ECCB4B
}

.maxexch9 .time_zone span {
    color: #847C48
}

.maxexch9 .menu-list li {
    border-bottom-color: #466071
}

.maxexch9 .menu-list a {
    color: #D3CFB6
}

.maxexch9 .menu-list a:hover {
    background-color: #12232E
}

.maxexch9 .menu-list .select {
    background-color: #12232E;
    color: #D3CFB6
}

.maxexch9 .menu-list .select:hover {
    background-color: #12232E
}

.maxexch9 .menu-list .Go {
    background-position: 100% -1250px;
    height: 25px;
    width: 23px
}

.maxexch9 .menu-list .Go:hover,
.maxexch9 .menu-list .Go.Go-maxexch9-hover {
    background-position: 100% -1275px
}

.maxexch9 .sub_path {
    background-color: #243A48
}

.maxexch9 .path {
    background-repeat: repeat-x;
    background-position: 0 -5923px
}

.maxexch9 .path a {
    color: #243A48
}

.maxexch9 .path .path-back {
    background-position: 0 -3575px;
    height: 25px;
    width: 36px
}

.maxexch9 .path .path-back:hover,
.maxexch9 .path .path-back.path-back-maxexch9-hover {
    background-position: 0 -3600px
}

.maxexch9 .path .path-back.select {
    background-position: 0 -3600px;
    height: 25px;
    width: 36px
}

.maxexch9 .game-highlight-wrap h3 {
    background-color: #D7C45E;
    color: #222
}

.maxexch9 .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10501px
}

.maxexch9 .tab-markets {
    background: transparent
}

.maxexch9 .one_click-setting {
    color: #ECCB4B;
    background-repeat: repeat-x;
    background-color: #912B06;
    background-position: 0 -4984px
}

.maxexch9 .one_click-setting a {
    border-color: #621F04
}

.maxexch9 .one_click-setting .a-edit {
    color: #ECCB4B
}

.maxexch9 .one_click-setting .a-edit img {
    background-position: -405px -2206px;
    height: 9px;
    width: 9px
}

.maxexch9 .one_click-stake {
    background-position: 0 -4340px;
    height: 31px;
    width: 414px
}

.maxexch9 .one_click-stake input,
.maxexch9 .one_click-stake textarea,
.maxexch9 .one_click-stake .member_select a,
.member_select .maxexch9 .one_click-stake a {
    border-color: #621F04;
    color: #1E1E1E;
    background-color: #CD7441
}

.maxexch9 .one_click-stake input:focus,
.maxexch9 .one_click-stake textarea:focus,
.maxexch9 .one_click-stake .member_select a:focus,
.member_select .maxexch9 .one_click-stake a:focus {
    border-color: #ECCB4B;
    background-color: #D6956D
}

.maxexch9 .footer_fixed .main_wrap,
.maxexch9 .footer_fixed .header,
.maxexch9 .footer_fixed .footer-content {
    background-color: #E5E5DF
}

.maxexch9 .submit-wrap .btn-send span {
    background-color: #243A48
}

body.cftbet365 {
    background-color: #EDEDED
}

/* .cftbet365 .btn.select,.cftbet365 .select.btn-send,.cftbet365 .pages a.select,.pages .cftbet365 a.select,.cftbet365 .calendarTable_inputBox input.select,.calendarTable_inputBox .cftbet365 input.select,.cftbet365 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .cftbet365 textarea.select,.cftbet365 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .cftbet365 a.select,.cftbet365 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .cftbet365 a.select,.cftbet365 .total_all .select.btn_replay,.total_all .cftbet365 .select.btn_replay,.cftbet365 .select.btn_expand,.cftbet365 .select.add_member{
    color:#F5B942;
    background:#444 url(../images/cftbet365/btn-select-cftbet365.jpg) repeat-x;
    border-color:#222
}
.cftbet365 .btn.select:hover,.cftbet365 .select.btn-send:hover,.cftbet365 .pages a.select:hover,.pages .cftbet365 a.select:hover,.cftbet365 .calendarTable_inputBox input.select:hover,.calendarTable_inputBox .cftbet365 input.select:hover,.cftbet365 .calendarTable_inputBox textarea.select:hover,.calendarTable_inputBox .cftbet365 textarea.select:hover,.cftbet365 .calendarTable_inputBox .member_select a.select:hover,.calendarTable_inputBox .member_select .cftbet365 a.select:hover,.cftbet365 .member_select .calendarTable_inputBox a.select:hover,.member_select .calendarTable_inputBox .cftbet365 a.select:hover,.cftbet365 .total_all .select.btn_replay:hover,.total_all .cftbet365 .select.btn_replay:hover,.cftbet365 .select.btn_expand:hover,.cftbet365 .select.add_member:hover{
    background:#444 url(../images/cftbet365/btn-select-cftbet365.jpg) repeat-x
}
.cftbet365 .btn-send{
    background:#121212 url(../images/cftbet365/bg-send-cftbet365.png) repeat-x;
    color:#F5B942;
    border-color:#000
}
.cftbet365 .btn-send:hover{
    background:#464646 url(../images/cftbet365/bg-send_hover-cftbet365.png) repeat-x
}
.cftbet365 .btn-send.disable{
    background:#A4A4A4 url(../images/cftbet365/bg-send-disable-cftbet365.png) repeat-x;
    border-color:#aaa;
    color:#E6DFCD
}
.cftbet365 .kv{
    background:#000 url(../images/cftbet365/bg-login_wrap-cftbet365.png) no-repeat
} */
.cftbet365 .login-panel {
    color: #000
}

.cftbet365 .icon-login {
    background-position: 0 -232px;
    height: 12px;
    width: 12px
}

.cftbet365 .announce-header h1:before {
    background: #83AE17
}

/* .cftbet365 .top{
    background:#14213D url(../images/cftbet365/bg-top-cftbet365.jpg) repeat-x
} */
.cftbet365 .header {
    color: #fff
}

.cftbet365 .header h1 {
    margin-top: -11px
}

.cftbet365 .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#E28614), to(#FDB52D));
    background-image: -webkit-linear-gradient(bottom, #E28614 0%, #FDB52D 100%);
    background-image: linear-gradient(0deg, #E28614 0%, #FDB52D 100%)
}

.cftbet365 .login-wrap li.user {
    background-position: -96px -5142px
}

.cftbet365 .login-wrap .error {
    color: rgba(255, 255, 255, 0.8)
}

.cftbet365 .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.cftbet365 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.cftbet365 .account-wrap a {
    color: #F5B942
}

.cftbet365 .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8)
}

.cftbet365 .account-wrap .a-wallet .nums {
    border: 1px solid #FFB80C
}

.cftbet365 .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important
}

.cftbet365 .account-wrap .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.25) !important
}

.cftbet365 .account-wrap .loading-bar span {
    background: #fff
}

.cftbet365 .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23F5B942"/></svg>') no-repeat center center;
    background-size: contain
}

.cftbet365 .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.8)
}

.cftbet365 .account-wrap .head-refresh a {
    background-position: -80px -1475px;
    height: 25px;
    width: 30px
}

.cftbet365 .account-wrap .account {
    background-position: 0 -325px;
    height: 25px;
    width: 110px
}

.cftbet365 .account-wrap ul a {
    color: #1E1E1E
}

.cftbet365 .account-wrap .logout a {
    color: #fff
}

.cftbet365 .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#3D3D3D), to(#2D2D2D));
    background-image: -webkit-linear-gradient(top, #3D3D3D 0%, #2D2D2D 100%);
    background-image: linear-gradient(180deg, #3D3D3D 0%, #2D2D2D 100%)
}

.cftbet365 .menu-wrap a.menu-drop:after {
    border-top-color: #F5B942
}

.cftbet365 .menu li,
.cftbet365 .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.2)
}

.cftbet365 .menu li ul a,
.cftbet365 .setting-wrap li ul a {
    color: #F5B942
}

.cftbet365 .menu a,
.cftbet365 .setting-wrap a {
    color: #F5B942
}

.cftbet365 .menu a:hover,
.cftbet365 .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.cftbet365 .menu .select,
.cftbet365 .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.cftbet365 .menu .logout,
.cftbet365 .setting-wrap .logout {
    border-left-width: 0
}

.cftbet365 .menu .logout img,
.cftbet365 .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23F5B942" fill-rule="evenodd"/></svg>') no-repeat;
    background-position: 0px
}

.cftbet365 .menu .tag-new,
.cftbet365 .setting-wrap .tag-new {
    color: #FFFFFF
}

.cftbet365 .live-menu {
    border-left-color: #555
}

.cftbet365 .live-menu img {
    background-position: -89px -4530px
}

.cftbet365 .setting {
    border-left-color: #555
}

.cftbet365 .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23F5B942" fill-rule="evenodd"/></svg>') no-repeat
}

.cftbet365 .one_click {
    border-top-color: #FFEBAB;
    background-repeat: repeat-x;
    color: #fff !important;
    background-position: 0 -1530px
}

.cftbet365 .one_click:hover,
.cftbet365 .one_click.bg-1click-cftbet365-hover {
    background-position: 0 -1440px
}

.cftbet365 .one_click img {
    background-position: -94px -3010px
}

.cftbet365 .one_click.select {
    color: #292929 !important;
    background-repeat: repeat-x;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-position: 0 -1500px
}

.cftbet365 .one_click.select:hover,
.cftbet365 .one_click.select.bg-1click-cftbet365-select-hover {
    background-position: 0 -1470px
}

.cftbet365 .one_click.select img {
    background-position: -94px -2980px
}

.cftbet365 .menu-list li {
    border-bottom-color: #E0E6E6
}

.cftbet365 .menu-list a {
    color: #222
}

.cftbet365 .menu-list a:hover {
    background-color: #D6D6D6
}

.cftbet365 .menu-list .select {
    background-color: #D6D6D6;
    color: #222
}

.cftbet365 .menu-list .select:hover {
    background-color: #D6D6D6
}

.cftbet365 .menu-list .Go {
    background-position: 100% -550px;
    height: 25px;
    width: 24px
}

.cftbet365 .menu-list .Go:hover,
.cftbet365 .menu-list .Go.Go-cftbet365-hover {
    background-position: 100% -575px
}

.cftbet365 .path {
    background: #222
}

.cftbet365 .path a {
    color: #FFB80C
}

.cftbet365 .path .path-back {
    background-position: 0 -2825px;
    height: 25px;
    width: 36px
}

.cftbet365 .path .path-back:hover,
.cftbet365 .path .path-back.path-back-cftbet365-hover {
    background-position: 0 -2850px
}

.cftbet365 .path .path-back.select {
    background-position: 0 -2850px;
    height: 25px;
    width: 36px
}

.cftbet365 .path-pop {
    background-color: #222
}

.cftbet365 .game-highlight-wrap h3 {
    background-color: #252525;
    color: #F5B942
}

.cftbet365 .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10237px
}

.cftbet365 .tab-markets {
    background: transparent
}

.cftbet365 .one_click-setting {
    color: #1E1E1E;
    background-repeat: repeat-x;
    background-color: #F5B942;
    background-position: 0 -4514px
}

.cftbet365 .one_click-setting .a-edit {
    color: #1E1E1E
}

.cftbet365 .one_click-setting .a-edit img {
    background-position: -405px -2098px;
    height: 9px;
    width: 9px
}

.cftbet365 .one_click-stake {
    background-position: 0 -3870px;
    height: 31px;
    width: 414px
}

.cftbet365 .one_click-stake input,
.cftbet365 .one_click-stake textarea,
.cftbet365 .one_click-stake .member_select a,
.member_select .cftbet365 .one_click-stake a {
    border-color: #757575;
    color: #1E1E1E;
    background-color: #fff
}

.cftbet365 .one_click-stake input:focus,
.cftbet365 .one_click-stake textarea:focus,
.cftbet365 .one_click-stake .member_select a:focus,
.member_select .cftbet365 .one_click-stake a:focus {
    border-color: #222;
    color: #F5B942;
    background-color: #444
}

.cftbet365 .function-wrap {
    background-color: #D5E1E6
}

.cftbet365 .submit-wrap .btn-send span {
    background-color: #EB5E55
}

body.crickzoom {
    background-color: #EDEDED
}

/* .crickzoom .btn.select,.crickzoom .select.btn-send,.crickzoom .pages a.select,.pages .crickzoom a.select,.crickzoom .calendarTable_inputBox input.select,.calendarTable_inputBox .crickzoom input.select,.crickzoom .calendarTable_inputBox textarea.select,.calendarTable_inputBox .crickzoom textarea.select,.crickzoom .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .crickzoom a.select,.crickzoom .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .crickzoom a.select,.crickzoom .total_all .select.btn_replay,.total_all .crickzoom .select.btn_replay,.crickzoom .select.btn_expand,.crickzoom .select.add_member{
    color:#fff;
    background:#77A435 url(../images/crickzoom/btn-select-crickzoom.jpg) repeat-x;
    border-color:#265E04
}
.crickzoom .btn-send{
    background:#142240 url(../images/crickzoom/bg-send-crickzoom.png) repeat-x;
    border:1px solid #3A6EDE;
    border-radius:4px;
    color:#fff
}
.crickzoom .btn-send:hover{
    background:#1947AC url(../images/crickzoom/bg-send_hover-crickzoom.png) repeat-x
}
.crickzoom .btn-send.disable{
    background:#8F99AF url(../images/crickzoom/bg-send-disable-crickzoom.png) repeat-x;
    border-color:#A9C2F8;
    color:#BFD0EA
}
.crickzoom .login_to_go{
    background-color:#83AE17
}
.crickzoom .kv{
    background:#14213D url(../images/crickzoom/bg-login_wrap-crickzoom.png) no-repeat
} */
.crickzoom .login-panel {
    color: #1E1E1E
}

.crickzoom .login-panel input,
.crickzoom .login-panel textarea,
.crickzoom .login-panel .member_select a,
.member_select .crickzoom .login-panel a {
    border-color: #3E8142
}

.crickzoom .login-panel .error {
    color: #1E1E1E
}

.crickzoom .icon-login {
    background-position: 0 -256px;
    height: 12px;
    width: 12px
}

.crickzoom .announce-header h1:before {
    background: #83AE17
}

/* .crickzoom .top{
    background:#14213D url(../images/crickzoom/bg-top-crickzoom.jpg) repeat-x
} */
.crickzoom .header {
    color: #fff
}

.crickzoom .header h1 {
    margin-top: -9px
}

.crickzoom .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#E28614), to(#FDB52D));
    background-image: -webkit-linear-gradient(bottom, #E28614 0%, #FDB52D 100%);
    background-image: linear-gradient(0deg, #E28614 0%, #FDB52D 100%)
}

.crickzoom .login-wrap li.user {
    background-position: -96px -5192px
}

.crickzoom .login-wrap .error {
    color: rgba(255, 255, 255, 0.8)
}

.crickzoom .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.crickzoom .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.crickzoom .account-wrap a {
    color: #fff
}

.crickzoom .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.6);
    background-color: #3e5588
}

.crickzoom .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.crickzoom .account-wrap .loading-bar span {
    background: #fff
}

.crickzoom .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #3e5588;
    border: 1px solid rgba(0, 0, 0, 0.6)
}

.crickzoom .account-wrap .head-refresh a {
    background-position: -80px -1525px;
    height: 25px;
    width: 30px
}

.crickzoom .account-wrap .account {
    background-position: 0 -375px;
    height: 25px;
    width: 110px
}

.crickzoom .account-wrap ul a {
    color: #1E1E1E
}

.crickzoom .account-wrap .logout a {
    color: #fff
}

.crickzoom .menu-wrap {
    background-image: -webkit-linear-gradient(89deg, #61A123 3%, #B3CE60 99%);
    background-image: linear-gradient(1deg, #61A123 3%, #B3CE60 99%);
    border: 1px solid #417505
}

.crickzoom .menu-wrap a.menu-drop:after {
    border-top-color: #333
}

.crickzoom .menu-wrap a.select.menu-drop:after {
    border-top-color: #FFF;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #588D23), to(#609F22));
    background-image: -webkit-linear-gradient(top, #588D23 20%, #609F22 100%);
    background-image: linear-gradient(180deg, #588D23 20%, #609F22 100%);
    box-shadow: inset 0 1px 3px 0 rgba(6, 23, 7, 0.5)
}

.crickzoom .menu li,
.crickzoom .setting-wrap li {
    border-right-color: rgba(9, 68, 16, 0.3)
}

.crickzoom .menu li ul a,
.crickzoom .setting-wrap li ul a {
    color: #fff
}

.crickzoom .menu a,
.crickzoom .setting-wrap a {
    color: #333
}

.crickzoom .menu a:hover,
.crickzoom .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.crickzoom .menu .select,
.crickzoom .setting-wrap .select {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #588D23), to(#609F22));
    background-image: -webkit-linear-gradient(top, #588D23 20%, #609F22 100%);
    background-image: linear-gradient(180deg, #588D23 20%, #609F22 100%);
    box-shadow: inset 0 1px 3px 0 rgba(6, 23, 7, 0.5)
}

.crickzoom .menu .logout,
.crickzoom .setting-wrap .logout {
    border-left-width: 0
}

.crickzoom .menu .logout img,
.crickzoom .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23333" fill-rule="evenodd"/></svg>') no-repeat
}

.crickzoom .menu .tag-new,
.crickzoom .setting-wrap .tag-new {
    color: #FFFFFF
}

.crickzoom .live-menu {
    border-left-color: rgba(9, 68, 16, 0.2)
}

.crickzoom .live-menu img {
    background-position: -89px -4566px
}

.crickzoom .setting {
    border-left-color: rgba(9, 68, 16, 0.2)
}

.crickzoom .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23333" fill-rule="evenodd"/></svg>') no-repeat
}

.crickzoom .one_click {
    border-top-color: #F98181;
    color: #fff !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#374F82), to(#2B3E65));
    background-image: -webkit-linear-gradient(top, #374F82 0%, #2B3E65 100%);
    background-image: linear-gradient(180deg, #374F82 0%, #2B3E65 100%);
    background-position: inherit
}

.crickzoom .one_click img {
    background-position: -94px -3130px
}

.crickzoom .one_click.select {
    color: #fff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(18%, #E05951), to(#B0463F));
    background-image: -webkit-linear-gradient(top, #E05951 18%, #B0463F 100%);
    background-image: linear-gradient(180deg, #E05951 18%, #B0463F 100%);
    background-position: inherit
}

.crickzoom .one_click.select img {
    background-position: -94px -3100px
}

.crickzoom .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(18%, #B0463F), to(#E05951));
    background-image: -webkit-linear-gradient(top, #B0463F 18%, #E05951 100%);
    background-image: linear-gradient(180deg, #B0463F 18%, #E05951 100%)
}

.crickzoom .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2B3E65), to(#374F82));
    background-image: -webkit-linear-gradient(top, #2B3E65 0%, #374F82 100%);
    background-image: linear-gradient(180deg, #2B3E65 0%, #374F82 100%)
}

.crickzoom .time_zone {
    color: #333333
}

.crickzoom .time_zone span {
    color: rgba(51, 51, 51, 0.5)
}

.crickzoom .menu-list li {
    border-bottom-color: #D2D6E2
}

.crickzoom .menu-list a {
    color: #0E2E75
}

.crickzoom .menu-list a:hover {
    background-color: #E6EFD1
}

.crickzoom .menu-list .select {
    background-color: #D1DDEF;
    color: #1E1E1E
}

.crickzoom .menu-list .select:hover {
    background-color: #D1DDEF
}

.crickzoom .menu-list .Go {
    background-position: 100% -650px;
    height: 25px;
    width: 23px
}

.crickzoom .menu-list .Go:hover,
.crickzoom .menu-list .Go.Go-crickzoom-hover {
    background-position: 100% -675px
}

.crickzoom .path {
    background-repeat: repeat-x;
    background-position: 0 -5698px
}

.crickzoom .path a {
    color: #B7F875
}

.crickzoom .path .path-back {
    background-position: 0 -2925px
}

.crickzoom .path .path-back:hover,
.crickzoom .path .path-back.path-back-crickzoom-hover {
    background-position: 0 -2950px
}

.crickzoom .path .path-back.select {
    background-position: 0 -2950px
}

.crickzoom .path-pop {
    background-color: #14213D
}

.crickzoom .path-pop a {
    color: #B7F875
}

.crickzoom .game-highlight-wrap h3 {
    background-color: #99C04D
}

.crickzoom .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10281px
}

.crickzoom .tab-markets {
    background: transparent
}

.crickzoom .one_click-setting {
    color: #fff;
    background-repeat: repeat-x;
    background-color: #A4413B;
    background-position: 0 -4576px
}

.crickzoom .one_click-setting a {
    border-color: #7D3232
}

.crickzoom .one_click-setting .a-edit {
    color: #fff
}

.crickzoom .one_click-setting .a-edit img {
    background-position: -405px -2116px;
    height: 9px;
    width: 9px
}

.crickzoom .one_click-stake {
    background-position: 0 -3932px;
    height: 31px;
    width: 414px
}

.crickzoom .one_click-stake input,
.crickzoom .one_click-stake textarea,
.crickzoom .one_click-stake .member_select a,
.member_select .crickzoom .one_click-stake a {
    border-color: #7D3232;
    color: #1E1E1E;
    background-color: #C37E81
}

.crickzoom .one_click-stake input:focus,
.crickzoom .one_click-stake textarea:focus,
.crickzoom .one_click-stake .member_select a:focus,
.member_select .crickzoom .one_click-stake a:focus {
    border-color: #113687;
    background-color: #F4DDDB
}

.crickzoom .function-wrap {
    background-color: #D5E1E6
}

.crickzoom .submit-wrap .btn-send span {
    background-color: #EB5E55
}

body.crickzoomlive {
    background-color: #EDEDED
}

/* .crickzoomlive .btn.select,.crickzoomlive .select.btn-send,.crickzoomlive .pages a.select,.pages .crickzoomlive a.select,.crickzoomlive .calendarTable_inputBox input.select,.calendarTable_inputBox .crickzoomlive input.select,.crickzoomlive .calendarTable_inputBox textarea.select,.calendarTable_inputBox .crickzoomlive textarea.select,.crickzoomlive .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .crickzoomlive a.select,.crickzoomlive .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .crickzoomlive a.select,.crickzoomlive .total_all .select.btn_replay,.total_all .crickzoomlive .select.btn_replay,.crickzoomlive .select.btn_expand,.crickzoomlive .select.add_member{
    color:#fff;
    background:#77A435 url(../images/crickzoomlive/btn-select-crickzoomlive.jpg) repeat-x;
    border-color:#265E04
}
.crickzoomlive .btn-send{
    background:#142240 url(../images/crickzoomlive/bg-send-crickzoomlive.png) repeat-x;
    border:1px solid #3A6EDE;
    border-radius:4px;
    color:#fff
}
.crickzoomlive .btn-send:hover{
    background:#1947AC url(../images/crickzoomlive/bg-send_hover-crickzoomlive.png) repeat-x
}
.crickzoomlive .btn-send.disable{
    background:#8F99AF url(../images/crickzoomlive/bg-send-disable-crickzoomlive.png) repeat-x;
    border-color:#A9C2F8;
    color:#BFD0EA
}
.crickzoomlive .login_to_go{
    background-color:#83AE17
}
.crickzoomlive .kv{
    background:#14213D url(../images/crickzoomlive/bg-login_wrap-crickzoomlive.png) no-repeat
} */
.crickzoomlive .login-panel {
    color: #1E1E1E
}

.crickzoomlive .login-panel input,
.crickzoomlive .login-panel textarea,
.crickzoomlive .login-panel .member_select a,
.member_select .crickzoomlive .login-panel a {
    border-color: #3E8142
}

.crickzoomlive .login-panel .error {
    color: #1E1E1E
}

.crickzoomlive .icon-login {
    background-position: 0 -268px;
    height: 12px;
    width: 12px
}

.crickzoomlive .announce-header h1:before {
    background: #83AE17
}

/* .crickzoomlive .top{
    background:#14213D url(../images/crickzoomlive/bg-top-crickzoomlive.jpg) repeat-x
} */
.crickzoomlive .header {
    color: #fff
}

.crickzoomlive .header h1 {
    margin-top: -9px
}

.crickzoomlive .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#E28614), to(#FDB52D));
    background-image: -webkit-linear-gradient(bottom, #E28614 0%, #FDB52D 100%);
    background-image: linear-gradient(0deg, #E28614 0%, #FDB52D 100%)
}

.crickzoomlive .login-wrap li.user {
    background-position: -96px -5217px
}

.crickzoomlive .login-wrap .error {
    color: rgba(255, 255, 255, 0.8)
}

.crickzoomlive .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.crickzoomlive .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.crickzoomlive .account-wrap a {
    color: #fff
}

.crickzoomlive .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.6);
    background-color: #3e5588
}

.crickzoomlive .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.crickzoomlive .account-wrap .loading-bar span {
    background: #fff
}

.crickzoomlive .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #3e5588;
    border: 1px solid rgba(0, 0, 0, 0.6)
}

.crickzoomlive .account-wrap .head-refresh a {
    background-position: -80px -1550px;
    height: 25px;
    width: 30px
}

.crickzoomlive .account-wrap .account {
    background-position: 0 -400px;
    height: 25px;
    width: 110px
}

.crickzoomlive .account-wrap ul a {
    color: #1E1E1E
}

.crickzoomlive .account-wrap .logout a {
    color: #fff
}

.crickzoomlive .menu-wrap {
    background-image: -webkit-linear-gradient(89deg, #61A123 3%, #B3CE60 99%);
    background-image: linear-gradient(1deg, #61A123 3%, #B3CE60 99%);
    border: 1px solid #417505
}

.crickzoomlive .menu-wrap a.menu-drop:after {
    border-top-color: #333
}

.crickzoomlive .menu-wrap a.select.menu-drop:after {
    border-top-color: #FFF;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #588D23), to(#609F22));
    background-image: -webkit-linear-gradient(top, #588D23 20%, #609F22 100%);
    background-image: linear-gradient(180deg, #588D23 20%, #609F22 100%);
    box-shadow: inset 0 1px 3px 0 rgba(6, 23, 7, 0.5)
}

.crickzoomlive .menu li,
.crickzoomlive .setting-wrap li {
    border-right-color: rgba(9, 68, 16, 0.3)
}

.crickzoomlive .menu li ul a,
.crickzoomlive .setting-wrap li ul a {
    color: #fff
}

.crickzoomlive .menu a,
.crickzoomlive .setting-wrap a {
    color: #333
}

.crickzoomlive .menu a:hover,
.crickzoomlive .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.crickzoomlive .menu .select,
.crickzoomlive .setting-wrap .select {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #588D23), to(#609F22));
    background-image: -webkit-linear-gradient(top, #588D23 20%, #609F22 100%);
    background-image: linear-gradient(180deg, #588D23 20%, #609F22 100%);
    box-shadow: inset 0 1px 3px 0 rgba(6, 23, 7, 0.5);
    background-position: inherit
}

.crickzoomlive .menu .logout,
.crickzoomlive .setting-wrap .logout {
    border-left-width: 0
}

.crickzoomlive .menu .logout img,
.crickzoomlive .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23333" fill-rule="evenodd"/></svg>') no-repeat
}

.crickzoomlive .menu .tag-new,
.crickzoomlive .setting-wrap .tag-new {
    color: #FFFFFF
}

.crickzoomlive .live-menu {
    border-left-color: rgba(9, 68, 16, 0.2)
}

.crickzoomlive .live-menu img {
    background-position: -89px -4584px
}

.crickzoomlive .setting {
    border-left-color: rgba(9, 68, 16, 0.2)
}

.crickzoomlive .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23333" fill-rule="evenodd"/></svg>') no-repeat
}

.crickzoomlive .one_click {
    border-top-color: #F98181;
    color: #fff !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#374F82), to(#2B3E65));
    background-image: -webkit-linear-gradient(top, #374F82 0%, #2B3E65 100%);
    background-image: linear-gradient(180deg, #374F82 0%, #2B3E65 100%);
    background-position: inherit
}

.crickzoomlive .one_click img {
    background-position: -94px -3190px
}

.crickzoomlive .one_click.select {
    color: #fff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(18%, #E05951), to(#B0463F));
    background-image: -webkit-linear-gradient(top, #E05951 18%, #B0463F 100%);
    background-image: linear-gradient(180deg, #E05951 18%, #B0463F 100%);
    background-position: inherit
}

.crickzoomlive .one_click.select img {
    background-position: -94px -3160px
}

.crickzoomlive .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(18%, #B0463F), to(#E05951));
    background-image: -webkit-linear-gradient(top, #B0463F 18%, #E05951 100%);
    background-image: linear-gradient(180deg, #B0463F 18%, #E05951 100%)
}

.crickzoomlive .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2B3E65), to(#374F82));
    background-image: -webkit-linear-gradient(top, #2B3E65 0%, #374F82 100%);
    background-image: linear-gradient(180deg, #2B3E65 0%, #374F82 100%)
}

.crickzoomlive .time_zone {
    color: #333333
}

.crickzoomlive .time_zone span {
    color: rgba(51, 51, 51, 0.5)
}

.crickzoomlive .menu-list li {
    border-bottom-color: #D2D6E2
}

.crickzoomlive .menu-list a {
    color: #0E2E75
}

.crickzoomlive .menu-list a:hover {
    background-color: #E6EFD1
}

.crickzoomlive .menu-list .select {
    background-color: #D1DDEF;
    color: #1E1E1E
}

.crickzoomlive .menu-list .select:hover {
    background-color: #D1DDEF
}

.crickzoomlive .menu-list .Go {
    background-position: 100% -700px;
    height: 25px;
    width: 23px
}

.crickzoomlive .menu-list .Go:hover,
.crickzoomlive .menu-list .Go.Go-crickzoomlive-hover {
    background-position: 100% -725px
}

.crickzoomlive .path {
    background-repeat: repeat-x;
    background-position: 0 -5723px
}

.crickzoomlive .path a {
    color: #B7F875
}

.crickzoomlive .path .path-back {
    background-position: 0 -2975px
}

.crickzoomlive .path .path-back:hover,
.crickzoomlive .path .path-back.path-back-crickzoomlive-hover {
    background-position: 0 -3000px
}

.crickzoomlive .path .path-back.select {
    background-position: 0 -3000px
}

.crickzoomlive .path-pop {
    background-color: #14213D
}

.crickzoomlive .path-pop a {
    color: #B7F875
}

.crickzoomlive .game-highlight-wrap h3 {
    background-color: #99C04D
}

.crickzoomlive .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10303px
}

.crickzoomlive .tab-markets {
    background: transparent
}

.crickzoomlive .one_click-setting {
    color: #fff;
    background-repeat: repeat-x;
    background-color: #A4413B;
    background-position: 0 -4607px
}

.crickzoomlive .one_click-setting a {
    border-color: #7D3232
}

.crickzoomlive .one_click-setting .a-edit {
    color: #fff
}

.crickzoomlive .one_click-setting .a-edit img {
    background-position: -405px -2125px;
    height: 9px;
    width: 9px
}

.crickzoomlive .one_click-stake {
    background-position: 0 -3963px;
    height: 31px;
    width: 414px
}

.crickzoomlive .one_click-stake input,
.crickzoomlive .one_click-stake textarea,
.crickzoomlive .one_click-stake .member_select a,
.member_select .crickzoomlive .one_click-stake a {
    border-color: #7D3232;
    color: #1E1E1E;
    background-color: #C37E81
}

.crickzoomlive .one_click-stake input:focus,
.crickzoomlive .one_click-stake textarea:focus,
.crickzoomlive .one_click-stake .member_select a:focus,
.member_select .crickzoomlive .one_click-stake a:focus {
    border-color: #113687;
    background-color: #F4DDDB
}

.crickzoomlive .function-wrap {
    background-color: #D5E1E6
}

.crickzoomlive .submit-wrap .btn-send span {
    background-color: #EB5E55
}

body.maxinplay {
    background-color: #E5E5DF
}

/* .maxinplay .btn.select,.maxinplay .select.btn-send,.maxinplay .pages a.select,.pages .maxinplay a.select,.maxinplay .calendarTable_inputBox input.select,.calendarTable_inputBox .maxinplay input.select,.maxinplay .calendarTable_inputBox textarea.select,.calendarTable_inputBox .maxinplay textarea.select,.maxinplay .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .maxinplay a.select,.maxinplay .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .maxinplay a.select,.maxinplay .total_all .select.btn_replay,.total_all .maxinplay .select.btn_replay,.maxinplay .select.btn_expand,.maxinplay .select.add_member{
    background:#F2D65D url(../images/maxinplay/btn-select-maxinplay.jpg) repeat-x;
    border-color:#897700
}
.maxinplay .btn-send{
    background:#BD9406 url(../images/maxinplay/bg-send-maxinplay.png) repeat-x;
    border-color:#897700
}
.maxinplay .btn-send:hover{
    background:#EAC535 url(../images/maxinplay/bg-send_hover-maxinplay.png) repeat-x
}
.maxinplay .btn-send.disable{
    background:#EBDEB4 url(../images/maxinplay/bg-send-disable-maxinplay.png) repeat-x;
    border-color:#A2B4A7
}
.maxinplay .kv{
    background:#122343 url(../images/maxinplay/bg-login_wrap-maxinplay.jpg) no-repeat
}
.maxinplay .top{
    background:#C4A73F url(../images/maxinplay/bg-top-maxinplay.jpg) repeat-x
} */
.maxinplay .header h1 {
    margin-top: -9px
}

.maxinplay .login-wrap .error {
    color: #024168
}

.maxinplay .login-wrap li.user {
    background-position: -96px -5417px
}

.maxinplay .account-wrap .head-refresh a {
    background-position: -80px -1875px;
    height: 25px;
    width: 30px
}

.maxinplay .account-wrap .account {
    background-position: 0 -725px;
    height: 25px;
    width: 110px
}

.maxinplay .menu-wrap {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#162231), color-stop(70%, #23354B));
    background-image: -webkit-linear-gradient(bottom, #162231 0%, #23354B 70%);
    background-image: linear-gradient(0deg, #162231 0%, #23354B 70%);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.4)
}

.maxinplay .menu li,
.maxinplay .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.maxinplay .menu li ul,
.maxinplay .setting-wrap li ul {
    background-color: #22354A
}

.maxinplay .menu li li,
.maxinplay .setting-wrap li li {
    border-bottom-color: #466071
}

.maxinplay .menu a:hover,
.maxinplay .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.maxinplay .menu .select,
.maxinplay .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.maxinplay .setting {
    border-left-color: rgba(255, 255, 255, 0.1)
}

.maxinplay .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23C4A73F" fill-rule="evenodd"/></svg>') no-repeat
}

.maxinplay .one_click {
    border-top-color: #D56525;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #2D4561), to(#1E2E41));
    background-image: -webkit-linear-gradient(top, #2D4561 12%, #1E2E41 100%);
    background-image: linear-gradient(180deg, #2D4561 12%, #1E2E41 100%);
    background-position: inherit
}

.maxinplay .one_click img {
    background-position: -94px -3910px
}

.maxinplay .one_click.select {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #B83A08), color-stop(77%, #912B06));
    background-image: -webkit-linear-gradient(top, #B83A08 10%, #912B06 77%);
    background-image: linear-gradient(180deg, #B83A08 10%, #912B06 77%);
    background-position: inherit
}

.maxinplay .one_click.select img {
    background-position: -94px -3880px
}

.maxinplay .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #912B06), color-stop(77%, #B83A08));
    background-image: -webkit-linear-gradient(top, #912B06 10%, #B83A08 77%);
    background-image: linear-gradient(180deg, #912B06 10%, #B83A08 77%)
}

.maxinplay .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #1E2E41), to(#2D4561));
    background-image: -webkit-linear-gradient(top, #1E2E41 12%, #2D4561 100%);
    background-image: linear-gradient(180deg, #1E2E41 12%, #2D4561 100%)
}

.maxinplay .slip_set-pop a,
.maxinplay .oneclick_set-pop a {
    color: #1E1E1E
}

.maxinplay .time_zone {
    color: #ECCB4B
}

.maxinplay .time_zone span {
    color: #847C48
}

.maxinplay .menu-list li {
    border-bottom-color: #466071
}

.maxinplay .menu-list a {
    color: #D3CFB6
}

.maxinplay .menu-list a:hover {
    background-color: #12232E
}

.maxinplay .menu-list .select {
    background-color: #12232E;
    color: #D3CFB6
}

.maxinplay .menu-list .select:hover {
    background-color: #12232E
}

.maxinplay .menu-list .Go {
    background-position: 100% -1300px;
    height: 25px;
    width: 23px
}

.maxinplay .menu-list .Go:hover,
.maxinplay .menu-list .Go.Go-maxinplay-hover {
    background-position: 100% -1325px
}

.maxinplay .sub_path {
    background-color: #243A48
}

.maxinplay .path {
    background-repeat: repeat-x;
    background-position: 0 -5948px
}

.maxinplay .path a {
    color: #243A48
}

.maxinplay .path .path-back {
    background-position: 0 -3625px;
    height: 25px;
    width: 36px
}

.maxinplay .path .path-back:hover,
.maxinplay .path .path-back.path-back-maxinplay-hover {
    background-position: 0 -3650px
}

.maxinplay .path .path-back.select {
    background-position: 0 -3650px;
    height: 25px;
    width: 36px
}

.maxinplay .game-highlight-wrap h3 {
    background-color: #D7C45E;
    color: #222
}

.maxinplay .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10523px
}

.maxinplay .tab-markets {
    background: transparent
}

.maxinplay .one_click-setting {
    color: #ECCB4B;
    background-repeat: repeat-x;
    background-color: #912B06;
    background-position: 0 -5015px
}

.maxinplay .one_click-setting a {
    border-color: #621F04
}

.maxinplay .one_click-setting .a-edit {
    color: #ECCB4B
}

.maxinplay .one_click-setting .a-edit img {
    background-position: -405px -2215px;
    height: 9px;
    width: 9px
}

.maxinplay .one_click-stake {
    background-position: 0 -4371px;
    height: 31px;
    width: 414px
}

.maxinplay .one_click-stake input,
.maxinplay .one_click-stake textarea,
.maxinplay .one_click-stake .member_select a,
.member_select .maxinplay .one_click-stake a {
    border-color: #621F04;
    color: #1E1E1E;
    background-color: #CD7441
}

.maxinplay .one_click-stake input:focus,
.maxinplay .one_click-stake textarea:focus,
.maxinplay .one_click-stake .member_select a:focus,
.member_select .maxinplay .one_click-stake a:focus {
    border-color: #ECCB4B;
    background-color: #D6956D
}

.maxinplay .footer_fixed .main_wrap,
.maxinplay .footer_fixed .header,
.maxinplay .footer_fixed .footer-content {
    background-color: #E5E5DF
}

.maxinplay .submit-wrap .btn-send span {
    background-color: #243A48
}

body.betbarter {
    background-color: #E6E7E8
}

.betbarter a {
    color: #444
}

.betbarter a:hover {
    text-decoration: none
}

.betbarter .table01 caption,
.betbarter .table-s caption,
.betbarter .table_one caption {
    background-color: #444
}

/* .betbarter .btn.select,.betbarter .select.btn-send,.betbarter .pages a.select,.pages .betbarter a.select,.betbarter .calendarTable_inputBox input.select,.calendarTable_inputBox .betbarter input.select,.betbarter .calendarTable_inputBox textarea.select,.calendarTable_inputBox .betbarter textarea.select,.betbarter .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .betbarter a.select,.betbarter .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .betbarter a.select,.betbarter .total_all .select.btn_replay,.total_all .betbarter .select.btn_replay,.betbarter .select.btn_expand,.betbarter .select.add_member{
    color:#fff;
    background:#DF8284 url(../images/betbarter/btn-select-betbarter.jpg) repeat-x;
    border-color:#B73B40
}
.betbarter .btn-send{
    background:#C4494E url(../images/betbarter/bg-send-betbarter.png) repeat-x;
    color:#fff;
    border-color:#912F33
}
.betbarter .btn-send:hover{
    background:#D76E71 url(../images/betbarter/bg-send_hover-betbarter.png) repeat-x
}
.betbarter .btn-send.disable{
    background:#CDCDCD url(../images/betbarter/bg-send-disable-betbarter.png) repeat-x;
    border-color:#aaa;
    color:#A3A3A3
}
.betbarter .login-panel{
    color:#000
}
.betbarter .top{
    background:#930000 url(../images/betbarter/bg-top-betbarter.jpg) repeat-x
} */
.betbarter .header {
    color: #fff
}

.betbarter .header h1 {
    display: none
}

.betbarter .login-wrap .error {
    background-color: transparent;
    color: #FEEC6E
}

.betbarter .login-wrap .btn-login {
    background-color: #E83523;
    background-image: none
}

.betbarter .login-wrap li.user {
    background-position: -96px -4867px
}

.betbarter .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.betbarter .account-wrap .main-wallet.no-multi .red {
    color: #FFE000 !important;
    background: none
}

.betbarter .account-wrap a {
    color: #fff
}

.betbarter .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4);
    background-color: #bd0b0b
}

.betbarter .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.betbarter .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.betbarter .account-wrap .a-wallet .red {
    background-color: #fff;
    padding: 0 3px;
    border-radius: 2px
}

.betbarter .account-wrap .loading-bar span {
    background: #fff
}

.betbarter .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #bd0b0b;
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.betbarter .account-wrap .head-refresh a {
    background-position: -80px -1400px;
    height: 25px;
    width: 30px
}

.betbarter .account-wrap .account {
    background-position: 0 -250px;
    height: 25px;
    width: 110px
}

.betbarter .account-wrap ul a {
    color: #1E1E1E
}

/* .betbarter .account-wrap .logout a{
    background:#C4494E url(../images/betbarter/bg-send-betbarter.png) repeat-x;
    color:#fff
} */
.betbarter .menu a,
.betbarter .setting-wrap a {
    color: #fff
}

.betbarter .one_click {
    border-top-color: #FF9B1A;
    color: #fff !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #959595), to(#6B6B6B));
    background-image: -webkit-linear-gradient(top, #959595 11%, #6B6B6B 100%);
    background-image: linear-gradient(180deg, #959595 11%, #6B6B6B 100%);
    background-position: inherit
}

.betbarter .one_click img {
    background-position: -94px -2830px
}

.betbarter .one_click.select {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #F77A00), to(#FB5F00));
    background-image: -webkit-linear-gradient(top, #F77A00 11%, #FB5F00 100%);
    background-image: linear-gradient(180deg, #F77A00 11%, #FB5F00 100%)
}

.betbarter .one_click.select img {
    background-position: -94px -2800px
}

.betbarter .one_click.select:hover {
    background-image: -webkit-linear-gradient(89deg, #F77A00 0%, #FB5F00 89%);
    background-image: linear-gradient(1deg, #F77A00 0%, #FB5F00 89%)
}

.betbarter .one_click:hover {
    background-image: -webkit-linear-gradient(89deg, #959595 0%, #6B6B6B 89%);
    background-image: linear-gradient(1deg, #959595 0%, #6B6B6B 89%)
}

.betbarter .menu-list a:hover {
    color: #f60
}

.betbarter .menu-list .select {
    background-color: #F2DCA7;
    color: #f60
}

.betbarter .menu-list .select:hover {
    background-color: #F2DCA7
}

.betbarter .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.betbarter .path {
    background-repeat: repeat-x;
    background-position: 0 -5623px
}

.betbarter .path a {
    color: #fff
}

.betbarter .path .path-back {
    background-position: 0 -2675px;
    height: 25px;
    width: 36px
}

.betbarter .path .path-back:hover,
.betbarter .path .path-back.path-back-betbarter-hover {
    background-position: 0 -2700px
}

.betbarter .path .path-back.select {
    background-position: 0 -2700px;
    height: 25px;
    width: 36px
}

.betbarter .path-pop {
    background-color: #666
}

.betbarter .path-pop a {
    color: #fff;
    background-position: -21px -2025px
}

.betbarter .slip-wrap h3 {
    background-repeat: repeat-x;
    background-position: 0 -6198px
}

.betbarter .slip-wrap .to-expand {
    background-position: 100% -224px
}

.betbarter .slip-wrap.close .to-expand {
    background-position: 100% -35px
}

.betbarter .slip-head {
    background-color: #ddd
}

.betbarter .matched-head {
    background-color: #fff
}

.betbarter .tips-popup {
    background-color: #FEEC6E
}

.betbarter .matched-wrap h3 {
    background-repeat: repeat-x;
    background-position: 0 -6198px
}

.betbarter .matched-wrap .to-expand {
    background-position: 100% -224px
}

.betbarter .matched-wrap.close .to-expand {
    background-position: 100% -35px
}

.betbarter .slip_refresh {
    background-position: -51px -519px;
    height: 25px;
    width: 29px
}

.betbarter .cashout-wrap .col-profit {
    border-color: #B73B40
}

.betbarter .slider {
    border-color: #B73B40
}

.betbarter .slider-bar span {
    border-color: #B73B40;
    background-repeat: repeat-x;
    background-position: 0 -9906px
}

/* .betbarter .slider-bar a{
    background:url(../images/betbarter/btn-slider-betbarter.png) no-repeat
} */
.betbarter .upcome-head {
    background-repeat: repeat-x;
    background-position: 0 -6198px
}

.betbarter .upcome-head a {
    color: #FED63A
}

.betbarter .upcome-head a:hover {
    color: #f60
}

.betbarter .upcome a:hover {
    color: #f60
}

.betbarter .game-wrap h3 {
    background: #353535
}

.betbarter .game-wrap .to-expand {
    background-position: 100% -1639px
}

.betbarter .game-wrap.close .to-expand {
    background-position: 100% -1430px
}

.betbarter .game-highlight-wrap h3 {
    background-color: #C4484D;
    color: #fff
}

.betbarter .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10171px
}

.betbarter .tab-highlight a {
    color: #fff;
    background-position: -284px -139px
}

.betbarter .tab-highlight a.select {
    color: #1E1E1E;
    background-position: -284px -227px
}

.betbarter .success {
    background-color: #8DDE73
}

.betbarter .error {
    background-color: #ff4800
}

.betbarter .warning {
    background-color: #FEEC6E
}

.betbarter .game-wrap-horse h3 {
    background: #353535
}

.betbarter .game-wrap-horse .to-expand {
    background-position: 100% -1639px
}

.betbarter .game-wrap-horse.close .to-expand {
    background-position: 100% -1430px
}

.betbarter .game-wrap-horse .hs-time a:hover {
    border-color: #444
}

.betbarter .tab-markets {
    background: transparent
}

.betbarter .tab-markets a.select {
    background-position: -284px -706px
}

.betbarter .add-pin h3 {
    background-position: -385px -885px;
    height: 25px;
    width: 29px
}

.betbarter .add-pin h3:hover,
.betbarter .add-pin h3.btn-add-pin-betbarter-hover {
    background-position: -385px -860px
}

.betbarter .delete-pin h3 {
    background-position: -385px -1285px;
    height: 25px;
    width: 29px
}

.betbarter .delete-pin h3:hover,
.betbarter .delete-pin h3.btn-delete-pin-hover {
    background-position: -385px -1260px
}

.betbarter .one_click-setting {
    background-color: #FF4B00;
    background-repeat: repeat-x;
    background-position: 0 -4420px
}

.betbarter .one_click-setting a {
    border-color: #ab4800
}

.betbarter .one_click-stake {
    background-position: 0 -3776px;
    height: 31px;
    width: 414px
}

.betbarter .one_click-stake input,
.betbarter .one_click-stake textarea,
.betbarter .one_click-stake .member_select a,
.member_select .betbarter .one_click-stake a {
    border-color: #AB4800;
    color: #1E1E1E;
    background-color: #ddd
}

.betbarter .one_click-stake input:focus,
.betbarter .one_click-stake textarea:focus,
.betbarter .one_click-stake .member_select a:focus,
.member_select .betbarter .one_click-stake a:focus {
    border-color: #B73B40;
    background-color: #fff
}

.betbarter .profile-wrap h3 {
    background-color: #666
}

.betbarter .tab li {
    border-color: #912F33
}

.betbarter .tab a {
    border-color: #912F33;
    color: #912F33
}

.betbarter .tab .select {
    background-color: #912F33;
    color: #fff
}

.betbarter .menu .logout,
.betbarter .setting-wrap .logout {
    border-left-width: 0
}

.betbarter .menu .logout img,
.betbarter .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.betbarter .function-wrap {
    background-color: #DADBCE
}

.betbarter .footer_fixed .main_wrap,
.betbarter .footer_fixed .header,
.betbarter .footer_fixed .footer-content {
    background-color: #E6E7E8
}

.betbarter .submit-wrap .btn-send span {
    background-color: #912F33
}

body.probet247 {
    background-color: #EDEDED
}

/* .probet247 .btn.select,.probet247 .select.btn-send,.probet247 .pages a.select,.pages .probet247 a.select,.probet247 .calendarTable_inputBox input.select,.calendarTable_inputBox .probet247 input.select,.probet247 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .probet247 textarea.select,.probet247 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .probet247 a.select,.probet247 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .probet247 a.select,.probet247 .total_all .select.btn_replay,.total_all .probet247 .select.btn_replay,.probet247 .select.btn_expand,.probet247 .select.add_member{
    color:#fff;
    background:#77A435 url(../images/probet247/btn-select-probet247.jpg) repeat-x;
    border-color:#265E04
}
.probet247 .btn-send{
    background:#142240 url(../images/probet247/bg-send-probet247.png) repeat-x;
    border:1px solid #3A6EDE;
    border-radius:4px;
    color:#fff
}
.probet247 .btn-send:hover{
    background:#1947AC url(../images/probet247/bg-send_hover-probet247.png) repeat-x
}
.probet247 .btn-send.disable{
    background:#8F99AF url(../images/probet247/bg-send-disable-probet247.png) repeat-x;
    border-color:#A9C2F8;
    color:#BFD0EA
}
.probet247 .login_to_go{
    background-color:#83AE17
}
.probet247 .kv{
    background:#14213D url(../images/probet247/bg-login_wrap-probet247.png) no-repeat
} */
.probet247 .login-panel {
    color: #1E1E1E
}

.probet247 .login-panel input,
.probet247 .login-panel textarea,
.probet247 .login-panel .member_select a,
.member_select .probet247 .login-panel a {
    border-color: #3E8142
}

.probet247 .login-panel .error {
    color: #1E1E1E
}

.probet247 .icon-login {
    background-position: 0 -389px;
    height: 12px;
    width: 12px
}

.probet247 .announce-header h1:before {
    background: #83AE17
}

/* .probet247 .top{
    background:#14213D url(../images/probet247/bg-top-probet247.jpg) repeat-x
} */
.probet247 .header {
    color: #fff
}

.probet247 .header h1 {
    margin-top: -15px
}

.probet247 .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#E28614), to(#FDB52D));
    background-image: -webkit-linear-gradient(bottom, #E28614 0%, #FDB52D 100%);
    background-image: linear-gradient(0deg, #E28614 0%, #FDB52D 100%)
}

.probet247 .login-wrap li.user {
    background-position: -96px -5492px
}

.probet247 .login-wrap .error {
    color: rgba(255, 255, 255, 0.8)
}

.probet247 .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.probet247 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.probet247 .account-wrap a {
    color: #fff
}

.probet247 .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.6);
    background-color: #3e5588
}

.probet247 .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.probet247 .account-wrap .loading-bar span {
    background: #fff
}

.probet247 .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #3e5588;
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.probet247 .account-wrap .head-refresh a {
    background-position: -80px -1975px;
    height: 25px;
    width: 30px
}

.probet247 .account-wrap .account {
    background-position: 0 -825px;
    height: 25px;
    width: 110px
}

.probet247 .account-wrap ul a {
    color: #1E1E1E
}

.probet247 .account-wrap .logout a {
    color: #fff
}

.probet247 .menu-wrap {
    background-image: -webkit-linear-gradient(89deg, #61A123 3%, #B3CE60 99%);
    background-image: linear-gradient(1deg, #61A123 3%, #B3CE60 99%);
    border: 1px solid #417505
}

.probet247 .menu-wrap a.menu-drop:after {
    border-top-color: #333
}

.probet247 .menu-wrap a.select.menu-drop:after {
    border-top-color: #FFF;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #588D23), to(#609F22));
    background-image: -webkit-linear-gradient(top, #588D23 20%, #609F22 100%);
    background-image: linear-gradient(180deg, #588D23 20%, #609F22 100%);
    box-shadow: inset 0 1px 3px 0 rgba(6, 23, 7, 0.5)
}

.probet247 .menu li,
.probet247 .setting-wrap li {
    border-right-color: rgba(9, 68, 16, 0.3)
}

.probet247 .menu li ul a,
.probet247 .setting-wrap li ul a {
    color: #fff
}

.probet247 .menu a,
.probet247 .setting-wrap a {
    color: #333
}

.probet247 .menu a:hover,
.probet247 .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.probet247 .menu .select,
.probet247 .setting-wrap .select {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #588D23), to(#609F22));
    background-image: -webkit-linear-gradient(top, #588D23 20%, #609F22 100%);
    background-image: linear-gradient(180deg, #588D23 20%, #609F22 100%);
    box-shadow: inset 0 1px 3px 0 rgba(6, 23, 7, 0.5)
}

.probet247 .menu .logout,
.probet247 .setting-wrap .logout {
    border-left-width: 0
}

.probet247 .menu .logout img,
.probet247 .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23333" fill-rule="evenodd"/></svg>') no-repeat
}

.probet247 .menu .tag-new,
.probet247 .setting-wrap .tag-new {
    color: #FFFFFF
}

.probet247 .live-menu {
    border-left-color: rgba(9, 68, 16, 0.2)
}

.probet247 .live-menu img {
    background-position: -89px -4602px
}

.probet247 .setting {
    border-left-color: rgba(9, 68, 16, 0.2)
}

.probet247 .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23333" fill-rule="evenodd"/></svg>') no-repeat
}

.probet247 .one_click {
    border-top-color: #F98181;
    color: #fff !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#374F82), to(#2B3E65));
    background-image: -webkit-linear-gradient(top, #374F82 0%, #2B3E65 100%);
    background-image: linear-gradient(180deg, #374F82 0%, #2B3E65 100%);
    background-position: inherit
}

.probet247 .one_click img {
    background-position: -94px -4030px
}

.probet247 .one_click.select {
    color: #fff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(18%, #E05951), to(#B0463F));
    background-image: -webkit-linear-gradient(top, #E05951 18%, #B0463F 100%);
    background-image: linear-gradient(180deg, #E05951 18%, #B0463F 100%);
    background-position: inherit
}

.probet247 .one_click.select img {
    background-position: -94px -4000px
}

.probet247 .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(18%, #B0463F), to(#E05951));
    background-image: -webkit-linear-gradient(top, #B0463F 18%, #E05951 100%);
    background-image: linear-gradient(180deg, #B0463F 18%, #E05951 100%)
}

.probet247 .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2B3E65), to(#374F82));
    background-image: -webkit-linear-gradient(top, #2B3E65 0%, #374F82 100%);
    background-image: linear-gradient(180deg, #2B3E65 0%, #374F82 100%)
}

.probet247 .time_zone {
    color: #333333
}

.probet247 .time_zone span {
    color: rgba(51, 51, 51, 0.5)
}

.probet247 .menu-list li {
    border-bottom-color: #D2D6E2
}

.probet247 .menu-list a {
    color: #0E2E75
}

.probet247 .menu-list a:hover {
    background-color: #E6EFD1
}

.probet247 .menu-list .select {
    background-color: #D1DDEF;
    color: #1E1E1E
}

.probet247 .menu-list .select:hover {
    background-color: #D1DDEF
}

.probet247 .menu-list .Go {
    background-position: 100% -1450px;
    height: 25px;
    width: 23px
}

.probet247 .menu-list .Go:hover,
.probet247 .menu-list .Go.Go-probet247-hover {
    background-position: 100% -1475px
}

.probet247 .path {
    background-repeat: repeat-x;
    background-position: 0 -5998px
}

.probet247 .path a {
    color: #B7F875
}

.probet247 .path .path-back {
    background-position: 0 -3775px
}

.probet247 .path .path-back:hover,
.probet247 .path .path-back.path-back-probet247-hover {
    background-position: 0 -3800px
}

.probet247 .path .path-back.select {
    background-position: 0 -3800px
}

.probet247 .path-pop {
    background-color: #14213D
}

.probet247 .path-pop a {
    color: #B7F875
}

.probet247 .game-highlight-wrap h3 {
    background-color: #99C04D
}

.probet247 .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10589px
}

.probet247 .tab-markets {
    background: transparent
}

.probet247 .one_click-setting {
    color: #fff;
    background-repeat: repeat-x;
    background-color: #A4413B;
    background-position: 0 -5077px
}

.probet247 .one_click-setting a {
    border-color: #7D3232
}

.probet247 .one_click-setting .a-edit {
    color: #fff
}

.probet247 .one_click-setting .a-edit img {
    background-position: -405px -2233px;
    height: 9px;
    width: 9px
}

.probet247 .one_click-stake {
    background-position: 0 -4433px;
    height: 31px;
    width: 414px
}

.probet247 .one_click-stake input,
.probet247 .one_click-stake textarea,
.probet247 .one_click-stake .member_select a,
.member_select .probet247 .one_click-stake a {
    border-color: #7D3232;
    color: #1E1E1E;
    background-color: #C37E81
}

.probet247 .one_click-stake input:focus,
.probet247 .one_click-stake textarea:focus,
.probet247 .one_click-stake .member_select a:focus,
.member_select .probet247 .one_click-stake a:focus {
    border-color: #113687;
    background-color: #F4DDDB
}

.probet247 .function-wrap {
    background-color: #D5E1E6
}

.probet247 .submit-wrap .btn-send span {
    background-color: #EB5E55
}

/* .goexchange .btn.select,.goexchange .select.btn-send,.goexchange .pages a.select,.pages .goexchange a.select,.goexchange .calendarTable_inputBox input.select,.calendarTable_inputBox .goexchange input.select,.goexchange .calendarTable_inputBox textarea.select,.calendarTable_inputBox .goexchange textarea.select,.goexchange .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .goexchange a.select,.goexchange .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .goexchange a.select,.goexchange .total_all .select.btn_replay,.total_all .goexchange .select.btn_replay,.goexchange .select.btn_expand,.goexchange .select.add_member{
    color:#F8D61C;
    background:#444 url(../images/goexchange/btn-select-goEX.jpg) repeat-x;
    border-color:#222
}
.goexchange .btn.select:hover,.goexchange .select.btn-send:hover,.goexchange .pages a.select:hover,.pages .goexchange a.select:hover,.goexchange .calendarTable_inputBox input.select:hover,.calendarTable_inputBox .goexchange input.select:hover,.goexchange .calendarTable_inputBox textarea.select:hover,.calendarTable_inputBox .goexchange textarea.select:hover,.goexchange .calendarTable_inputBox .member_select a.select:hover,.calendarTable_inputBox .member_select .goexchange a.select:hover,.goexchange .member_select .calendarTable_inputBox a.select:hover,.member_select .calendarTable_inputBox .goexchange a.select:hover,.goexchange .total_all .select.btn_replay:hover,.total_all .goexchange .select.btn_replay:hover,.goexchange .select.btn_expand:hover,.goexchange .select.add_member:hover{
    background:#444 url(../images/goexchange/btn-select-goEX.jpg) repeat-x
}
.goexchange .btn-send{
    background:#121212 url(../images/goexchange/bg-send-goEX.png) repeat-x;
    color:#F8D61C;
    border-color:#121212
}
.goexchange .btn-send:hover{
    background:#464646 url(../images/goexchange/bg-send_hover-goEX.png) repeat-x
}
.goexchange .btn-send.disable{
    background:#A4A4A4 url(../images/goexchange/bg-send-disable-goEX.png) repeat-x;
    border-color:#aaa;
    color:#E6DFCD
}
.goexchange .kv{
    background:#000 url(../images/goexchange/bg-login_wrap-goEX.jpg) no-repeat
} */
.goexchange .login-panel {
    color: #000
}

.goexchange .icon-login {
    background-position: 0 -292px;
    height: 12px;
    width: 12px
}

.goexchange .announce-header h1:before {
    background: #FFB80C
}

/* .goexchange .top{
    background:url(../images/goexchange/bg-top-goEX.png),-webkit-gradient(linear, left top, left bottom, from(#281E00), to(#000));
    background:url(../images/goexchange/bg-top-goEX.png),-webkit-linear-gradient(top, #281E00 0%, #000 100%);
    background:url(../images/goexchange/bg-top-goEX.png),linear-gradient(180deg, #281E00 0%, #000 100%);
    background-repeat:no-repeat
} */
.goexchange .header {
    color: #FFB80C
}

.goexchange .header h1 {
    margin-top: -15px
}

.goexchange .login-wrap li.user {
    background-position: -96px -5267px
}

.goexchange .login-wrap .error {
    color: #F8D61C
}

.goexchange .login-wrap .btn-signup {
    display: none;
    background-color: #666666;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#666), to(#333));
    background-image: -webkit-linear-gradient(top, #666 0%, #333 100%);
    background-image: linear-gradient(-180deg, #666 0%, #333 100%)
}

.goexchange .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.goexchange .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #F2E9CF !important;
    background-color: #BF0000;
    border-radius: 3px
}

.goexchange .account-wrap a {
    color: #FFB80C
}

.goexchange .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
    background-color: #282828
}

.goexchange .account-wrap .a-wallet .nums {
    border: 1px solid #FFB80C
}

.goexchange .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important
}

.goexchange .account-wrap .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.25) !important
}

.goexchange .account-wrap .loading-bar span {
    background: #fff
}

.goexchange .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23F5B942"/></svg>') no-repeat center center;
    background-size: contain
}

.goexchange .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #282828;
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.goexchange .account-wrap .head-refresh a {
    background-position: -80px -1650px;
    height: 25px;
    width: 30px
}

.goexchange .account-wrap .account {
    background-position: 0 -500px;
    height: 25px;
    width: 110px
}

.goexchange .account-wrap ul a {
    color: #1E1E1E
}

.goexchange .account-wrap .logout a {
    color: #fff
}

.goexchange .menu-wrap {
    background: #1E1E1E;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, #000), to(#2A2A2A));
    background-image: -webkit-linear-gradient(top, #000 8%, #2A2A2A 100%);
    background-image: linear-gradient(180deg, #000 8%, #2A2A2A 100%);
    border-top: solid 1px #695600
}

.goexchange .menu-wrap a.menu-drop:after {
    border-top-color: #fff
}

.goexchange .menu li,
.goexchange .setting-wrap li {
    border-right-color: rgba(69, 32, 7, 0.2)
}

.goexchange .menu li ul a,
.goexchange .setting-wrap li ul a {
    color: #FFB80C
}

.goexchange .menu a,
.goexchange .setting-wrap a {
    color: #fff
}

.goexchange .menu a:hover,
.goexchange .setting-wrap a:hover {
    text-decoration: underline;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#404040), to(#222));
    background-image: -webkit-linear-gradient(top, #404040 0%, #222 100%);
    background-image: linear-gradient(180deg, #404040 0%, #222 100%);
    box-shadow: inset 0 1px 2px 0 rgba(52, 9, 9, 0.5)
}

.goexchange .menu .select,
.goexchange .setting-wrap .select {
    color: #222;
    background: #D29A00;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D29A00), color-stop(50%, #FFBC00));
    background-image: -webkit-linear-gradient(top, #D29A00 0%, #FFBC00 50%);
    background-image: linear-gradient(180deg, #D29A00 0%, #FFBC00 50%);
    box-shadow: inset 0 1px 3px 0 rgba(52, 9, 9, 0.4)
}

.goexchange .menu .select:hover,
.goexchange .setting-wrap .select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D29A00), color-stop(50%, #FFBC00));
    background-image: -webkit-linear-gradient(top, #D29A00 0%, #FFBC00 50%);
    background-image: linear-gradient(180deg, #D29A00 0%, #FFBC00 50%);
    box-shadow: inset 0 1px 2px 0 rgba(52, 9, 9, 0.4)
}

.goexchange .menu .select.menu-drop:after,
.goexchange .setting-wrap .select.menu-drop:after {
    border-top-color: #000
}

.goexchange .menu .tag-new,
.goexchange .setting-wrap .tag-new {
    color: #FFFFFF
}

.goexchange .setting {
    border-left-color: rgba(69, 32, 7, 0.2)
}

.goexchange .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23fff" fill-rule="evenodd"/></svg>') no-repeat
}

.goexchange .one_click {
    border-top-color: #FF5F5F;
    background-repeat: repeat-x;
    color: #fff !important;
    background-position: 0 -2280px
}

.goexchange .one_click:hover,
.goexchange .one_click.bg-1click-goEX-hover {
    background-position: 0 -2190px
}

.goexchange .one_click img {
    background-position: -94px -3430px
}

.goexchange .one_click.select {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F00015), to(#AB0019));
    background-image: -webkit-linear-gradient(top, #F00015 0%, #AB0019 100%);
    background-image: linear-gradient(180deg, #F00015 0%, #AB0019 100%);
    background-repeat: repeat
}

.goexchange .one_click.select img {
    background-position: -94px -3400px
}

.goexchange .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#A6000F), to(#F00015));
    background-image: -webkit-linear-gradient(top, #A6000F 0%, #F00015 100%);
    background-image: linear-gradient(180deg, #A6000F 0%, #F00015 100%);
    background-repeat: repeat
}

.goexchange .slip_set-pop a:hover,
.goexchange .oneclick_set-pop a:hover {
    text-decoration: none
}

.goexchange .slip_set-pop .btn-send,
.goexchange .oneclick_set-pop .btn-send {
    color: #F8D61C
}

.goexchange .time_zone {
    color: #fff
}

.goexchange .time_zone span {
    color: #9A7816
}

.goexchange .menu-list li {
    border-bottom-color: #EFE1C1
}

.goexchange .menu-list a {
    color: #222
}

.goexchange .menu-list a:hover {
    background-color: #F2DCA7
}

.goexchange .menu-list .select {
    background-color: #F2DCA7;
    color: #222
}

.goexchange .menu-list .select:hover {
    background-color: #F2DCA7
}

.goexchange .menu-list .Go {
    background-position: 100% -900px;
    height: 25px;
    width: 23px
}

.goexchange .menu-list .Go:hover,
.goexchange .menu-list .Go.Go-goEX-hover {
    background-position: 100% -925px
}

.goexchange .path {
    background: #222
}

.goexchange .path a {
    color: #FFB80C
}

.goexchange .path .path-back {
    background-position: 0 -3225px;
    height: 25px;
    width: 36px
}

.goexchange .path .path-back:hover,
.goexchange .path .path-back.path-back-goEX-hover {
    background-position: 0 -3250px
}

.goexchange .path .path-back.select {
    background-position: 0 -3250px;
    height: 25px;
    width: 36px
}

.goexchange .path-pop {
    background-color: #222
}

.goexchange .game-highlight-wrap h3 {
    background-color: #DEB34A
}

.goexchange .game-highlight-wrap .tab-highlight {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #DEB34A), to(#9e7f4d));
    background-image: -webkit-linear-gradient(top, #DEB34A 30%, #9e7f4d 100%);
    background-image: linear-gradient(180deg, #DEB34A 30%, #9e7f4d 100%);
    background-repeat: inherit;
    background-position: inherit
}

.goexchange .login_to_go {
    background-color: #DEB34A
}

.goexchange .login_to_go .close {
    background-position: -394px -1120px;
    height: 20px;
    width: 20px
}

.goexchange .one_click-setting {
    color: #fff;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(10%, rgba(51, 51, 51, 0)), to(#D00101));
    background-image: -webkit-linear-gradient(bottom, rgba(51, 51, 51, 0) 10%, #D00101 100%);
    background-image: linear-gradient(0deg, rgba(51, 51, 51, 0) 10%, #D00101 100%);
    background-color: #940000;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.36);
    background-repeat: inherit
}

.goexchange .one_click-setting a {
    border-color: #530606
}

.goexchange .one_click-setting .a-edit {
    color: #1E1E1E;
    color: #fff
}

.goexchange .one_click-setting .a-edit img {
    background-position: -405px -2152px;
    height: 9px;
    width: 9px
}

.goexchange .one_click-stake {
    background-position: 0 -4120px;
    height: 31px;
    width: 414px
}

.goexchange .one_click-stake input,
.goexchange .one_click-stake textarea,
.goexchange .one_click-stake .member_select a,
.member_select .goexchange .one_click-stake a {
    border-color: #005F2D;
    color: #000;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #fff), color-stop(93%, #E1E1E1));
    background-image: -webkit-linear-gradient(top, #fff 15%, #E1E1E1 93%);
    background-image: linear-gradient(180deg, #fff 15%, #E1E1E1 93%);
    border: 1px solid #530606;
    border-radius: 4px
}

.goexchange .one_click-stake input:focus,
.goexchange .one_click-stake textarea:focus,
.goexchange .one_click-stake .member_select a:focus,
.member_select .goexchange .one_click-stake a:focus {
    border-color: #fff;
    background-color: #D00101
}

.goexchange .menu .logout,
.goexchange .setting-wrap .logout {
    border-left-width: 0
}

.goexchange .menu .logout img,
.goexchange .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23fff" fill-rule="evenodd"/></svg>') no-repeat
}

.goexchange .footer_fixed .main_wrap,
.goexchange .footer_fixed .header,
.goexchange .footer_fixed .footer-content {
    background-color: #F0ECE1
}

.goexchange .submit-wrap .btn-send span {
    background-color: #666
}

.goexchange .pages a.select {
    background-color: #444
}

body.kingfair24 {
    background-color: #F8F9E9
}

/* .kingfair24 .btn.select,.kingfair24 .select.btn-send,.kingfair24 .pages a.select,.pages .kingfair24 a.select,.kingfair24 .calendarTable_inputBox input.select,.calendarTable_inputBox .kingfair24 input.select,.kingfair24 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .kingfair24 textarea.select,.kingfair24 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .kingfair24 a.select,.kingfair24 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .kingfair24 a.select,.kingfair24 .total_all .select.btn_replay,.total_all .kingfair24 .select.btn_replay,.kingfair24 .select.btn_expand,.kingfair24 .select.add_member{
    background:#7BB54C url(../images/kingfair24/btn-select-kingfair24.jpg) repeat-x;
    border-color:#32803A
}
.kingfair24 .btn-send{
    background:#38703D url(../images/kingfair24/bg-send-kingfair24.png) repeat-x;
    color:#fff;
    border-color:#07571D
}
.kingfair24 .btn-send:hover{
    background:#48955B url(../images/kingfair24/bg-send_hover-kingfair24.png) repeat-x
}
.kingfair24 .btn-send.disable{
    background:#C3D5C5 url(../images/kingfair24/bg-send-disable-kingfair24.png) repeat-x;
    border-color:#A2B4A7;
    color:#EFFCF4
}
.kingfair24 .kv{
    background:#48955B url(../images/kingfair24/bg-login_wrap-kingfair24.jpg) no-repeat
} */
.kingfair24 .login-panel {
    color: #fff
}

.kingfair24 .login-panel dt {
    color: #000
}

.kingfair24 .icon-login {
    background-position: 0 -316px;
    height: 12px;
    width: 12px
}

/* .kingfair24 .top{
    background:#307234 url(../images/kingfair24/bg-top-kingfair24.jpg) repeat-x
} */
.kingfair24 .header {
    color: #fff
}

.kingfair24 .header h1 {
    margin-top: -13px
}

.kingfair24 .dialog-wrap-w {
    background-color: #e1e8d5
}

.kingfair24 .login-wrap li.user {
    background-position: -96px -5317px
}

.kingfair24 .login-wrap .error {
    color: #EFDA54
}

.kingfair24 .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.kingfair24 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #CA1F00;
    border-radius: 3px
}

.kingfair24 .account-wrap a {
    color: #fff
}

.kingfair24 .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4)
}

.kingfair24 .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.kingfair24 .account-wrap .loading-bar span {
    background: #fff
}

.kingfair24 .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.kingfair24 .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.kingfair24 .account-wrap .a-wallet .red {
    background-color: #fff;
    padding: 0 3px;
    border-radius: 2px
}

.kingfair24 .account-wrap .head-refresh a {
    background-position: -80px -1725px;
    height: 25px;
    width: 30px
}

.kingfair24 .account-wrap .account {
    background-position: 0 -575px;
    height: 25px;
    width: 110px
}

.kingfair24 .account-wrap ul a {
    color: #1E1E1E
}

.kingfair24 .account-wrap .logout a {
    color: #fff
}

.kingfair24 .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2C5F36), to(#3C7447));
    background-image: -webkit-linear-gradient(top, #2C5F36 0%, #3C7447 100%);
    background-image: linear-gradient(180deg, #2C5F36 0%, #3C7447 100%);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.4)
}

.kingfair24 .menu-wrap .menu-drop:after,
.kingfair24 .menu-wrap a.menu-drop.select:after {
    border-color: #fff transparent transparent transparent
}

.kingfair24 .menu li,
.kingfair24 .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.kingfair24 .menu a:hover,
.kingfair24 .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.kingfair24 .menu .select,
.kingfair24 .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.kingfair24 .setting {
    border-left-color: rgba(255, 255, 255, 0.1)
}

.kingfair24 .one_click {
    border-top-color: #F0DB55;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(14%, #2B6B37), to(#014E0F));
    background-image: -webkit-linear-gradient(top, #2B6B37 14%, #014E0F 100%);
    background-image: linear-gradient(180deg, #2B6B37 14%, #014E0F 100%);
    background-position: inherit
}

.kingfair24 .one_click img {
    background-position: -94px -3610px
}

.kingfair24 .one_click.select {
    color: #604401;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BCA300), to(#BCA300));
    background-image: -webkit-linear-gradient(top, #BCA300 0%, #BCA300 100%);
    background-image: linear-gradient(180deg, #BCA300 0%, #BCA300 100%);
    background-position: inherit
}

.kingfair24 .one_click.select img {
    background-position: -94px -3580px
}

.kingfair24 .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(27%, #BDA500), to(#C8B71D));
    background-image: -webkit-linear-gradient(top, #BDA500 27%, #C8B71D 100%);
    background-image: linear-gradient(180deg, #BDA500 27%, #C8B71D 100%)
}

.kingfair24 .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(14%, #014E0F), to(#2B6B37));
    background-image: -webkit-linear-gradient(top, #014E0F 14%, #2B6B37 100%);
    background-image: linear-gradient(180deg, #014E0F 14%, #2B6B37 100%)
}

.kingfair24 .time_zone span {
    color: #99B39E
}

.kingfair24 .menu-list li {
    border-bottom-color: #DDE7DE
}

.kingfair24 .menu-list a {
    color: #1A4F3D
}

.kingfair24 .menu-list a:hover {
    background-color: #C8D3CD
}

.kingfair24 .menu-list .select {
    background-color: #C8D3CD;
    color: #1A4F3D
}

.kingfair24 .menu-list .select:hover {
    background-color: #C8D3CD
}

.kingfair24 .menu-list .Go {
    background-position: 100% -1050px;
    height: 25px;
    width: 23px
}

.kingfair24 .menu-list .Go:hover,
.kingfair24 .menu-list .Go.Go-kingfair24-hover {
    background-position: 100% -1075px
}

.kingfair24 .sub_path {
    background-color: #ADACAC
}

.kingfair24 .path {
    background: #33694F
}

.kingfair24 .path .path-back {
    background-position: 0 -3375px;
    height: 25px;
    width: 36px
}

.kingfair24 .path .path-back:hover,
.kingfair24 .path .path-back.path-back-kingfair24-hover {
    background-position: 0 -3400px
}

.kingfair24 .path .path-back.select {
    background-position: 0 -3400px;
    height: 25px;
    width: 36px
}

.kingfair24 .game-highlight-wrap h3 {
    background-color: #32803A;
    color: #fff
}

.kingfair24 .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10413px
}

.kingfair24 .tab-markets {
    background: transparent
}

.kingfair24 .one_click-setting {
    color: #604401;
    background-repeat: repeat-x;
    background-color: #B29500;
    background-position: 0 -4858px
}

.kingfair24 .one_click-setting a {
    border-color: #604401
}

.kingfair24 .one_click-setting .a-edit {
    color: #604401
}

.kingfair24 .one_click-setting .a-edit img {
    background-position: -405px -2170px;
    height: 9px;
    width: 9px
}

.kingfair24 .one_click-stake {
    background-position: 0 -4214px;
    height: 31px;
    width: 414px
}

.kingfair24 .one_click-stake input,
.kingfair24 .one_click-stake textarea,
.kingfair24 .one_click-stake .member_select a,
.member_select .kingfair24 .one_click-stake a {
    border-color: #604401;
    color: #1E1E1E;
    background-color: #B09C02
}

.kingfair24 .one_click-stake input:focus,
.kingfair24 .one_click-stake textarea:focus,
.kingfair24 .one_click-stake .member_select a:focus,
.member_select .kingfair24 .one_click-stake a:focus {
    border-color: #fff;
    background-color: #A5AB38
}

.kingfair24 .menu .logout,
.kingfair24 .setting-wrap .logout {
    border-left-width: 0
}

.kingfair24 .menu .logout img,
.kingfair24 .setting-wrap .logout img {
    background-position: 0 -316px;
    height: 12px;
    width: 12px
}

.kingfair24 .footer_fixed .main_wrap,
.kingfair24 .footer_fixed .header,
.kingfair24 .footer_fixed .footer-content {
    background-color: #F8F9E9
}

.kingfair24 .submit-wrap .btn-send span {
    background-color: #E9D753;
    color: #07571D
}

body.sgexch247 {
    background-color: #E1E3E1
}

/* .sgexch247 .btn.select,.sgexch247 .select.btn-send,.sgexch247 .pages a.select,.pages .sgexch247 a.select,.sgexch247 .calendarTable_inputBox input.select,.calendarTable_inputBox .sgexch247 input.select,.sgexch247 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .sgexch247 textarea.select,.sgexch247 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .sgexch247 a.select,.sgexch247 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .sgexch247 a.select,.sgexch247 .total_all .select.btn_replay,.total_all .sgexch247 .select.btn_replay,.sgexch247 .select.btn_expand,.sgexch247 .select.add_member{
    color:#fff;
    background:#C97608 url(../images/sgexch247/btn-select-sgexch247.jpg) repeat-x;
    border-color:#764B11
}
.sgexch247 .btn-send{
    background:#466D04 url(../images/sgexch247/bg-send-sgexch247.png) repeat-x;
    border:1px solid #7BBE09;
    border-radius:4px;
    color:#fff
}
.sgexch247 .btn-send:hover{
    background:#8FBE17 url(../images/sgexch247/bg-send_hover-sgexch247.png) repeat-x
}
.sgexch247 .btn-send.disable{
    background:#A3B782 url(../images/sgexch247/bg-send-disable-sgexch247.png) repeat-x;
    border-color:#C4D5A6;
    color:#D5E9CC
}
.sgexch247 .login_to_go{
    background-color:#fff
}
.sgexch247 .kv{
    background:#14213D url(../images/sgexch247/bg-login_wrap-sgexch247.png) no-repeat
} */
.sgexch247 .login-panel {
    color: #1E1E1E
}

.sgexch247 .login-panel input,
.sgexch247 .login-panel textarea,
.sgexch247 .login-panel .member_select a,
.member_select .sgexch247 .login-panel a {
    border-color: #3E8142
}

.sgexch247 .login-panel .error {
    color: #1E1E1E
}

.sgexch247 .icon-login {
    background-position: 0 -413px;
    height: 12px;
    width: 12px
}

.sgexch247 .announce-header h1:before {
    background: #83AE17
}

/* .sgexch247 .top{
    background:#000 url(../images/sgexch247/bg-top-sgexch247.jpg) repeat-x
} */
.sgexch247 .header {
    color: #fff
}

.sgexch247 .header h1 {
    margin-top: -15px
}

.sgexch247 .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#E28614), to(#FDB52D));
    background-image: -webkit-linear-gradient(bottom, #E28614 0%, #FDB52D 100%);
    background-image: linear-gradient(0deg, #E28614 0%, #FDB52D 100%)
}

.sgexch247 .login-wrap li.user {
    background-position: -96px -5542px
}

.sgexch247 .login-wrap .error {
    color: #83AE17
}

.sgexch247 .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.sgexch247 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.sgexch247 .account-wrap a {
    color: #fff
}

.sgexch247 .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
    background-color: #31362c
}

.sgexch247 .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.sgexch247 .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important
}

.sgexch247 .account-wrap .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.25) !important
}

.sgexch247 .account-wrap .loading-bar span {
    background: #fff
}

.sgexch247 .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.sgexch247 .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #31362c;
    border: 1px solid rgba(0, 0, 0, 0.7)
}

.sgexch247 .account-wrap .head-refresh a {
    background-position: -80px -2050px;
    height: 25px;
    width: 30px
}

.sgexch247 .account-wrap .account {
    background-position: 0 -900px;
    height: 25px;
    width: 110px
}

.sgexch247 .account-wrap ul a {
    color: #1E1E1E
}

.sgexch247 .account-wrap .logout a {
    color: #fff
}

.sgexch247 .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1F3C01), to(#3F7607));
    background-image: -webkit-linear-gradient(top, #1F3C01 0%, #3F7607 100%);
    background-image: linear-gradient(180deg, #1F3C01 0%, #3F7607 100%);
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2);
    background-position: inherit
}

.sgexch247 .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.sgexch247 .menu li,
.sgexch247 .setting-wrap li {
    border-right-color: rgba(218, 255, 116, 0.2)
}

.sgexch247 .menu li ul a,
.sgexch247 .setting-wrap li ul a {
    color: #fff
}

.sgexch247 .menu a,
.sgexch247 .setting-wrap a {
    color: #fff
}

.sgexch247 .menu a:hover,
.sgexch247 .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.sgexch247 .menu .select,
.sgexch247 .setting-wrap .select {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#83AE17), to(#4E7B08));
    background-image: -webkit-linear-gradient(top, #83AE17 0%, #4E7B08 100%);
    background-image: linear-gradient(180deg, #83AE17 0%, #4E7B08 100%)
}

.sgexch247 .menu .logout,
.sgexch247 .setting-wrap .logout {
    border-left-width: 0
}

.sgexch247 .menu .logout img,
.sgexch247 .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.sgexch247 .live-menu {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.sgexch247 .live-menu img {
    background-position: -89px -4638px
}

.sgexch247 .setting {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.sgexch247 .one_click {
    border-top-color: #C8B810;
    color: #fff !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #32372C), to(#272B23));
    background-image: -webkit-linear-gradient(top, #32372C 12%, #272B23 100%);
    background-image: linear-gradient(180deg, #32372C 12%, #272B23 100%);
    background-position: inherit
}

.sgexch247 .one_click img {
    background-position: -94px -4180px
}

.sgexch247 .one_click.select {
    color: #fff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #479C88), to(#377969));
    background-image: -webkit-linear-gradient(top, #479C88 11%, #377969 100%);
    background-image: linear-gradient(180deg, #479C88 11%, #377969 100%);
    background-position: inherit
}

.sgexch247 .one_click.select img {
    background-position: -94px -4150px
}

.sgexch247 .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #377969), to(#479C88));
    background-image: -webkit-linear-gradient(top, #377969 11%, #479C88 100%);
    background-image: linear-gradient(180deg, #377969 11%, #479C88 100%)
}

.sgexch247 .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #272B23), to(#32372C));
    background-image: -webkit-linear-gradient(top, #272B23 12%, #32372C 100%);
    background-image: linear-gradient(180deg, #272B23 12%, #32372C 100%)
}

.sgexch247 .time_zone {
    color: white
}

.sgexch247 .time_zone span {
    color: rgba(255, 255, 255, 0.5)
}

.sgexch247 .menu-list li {
    border-bottom-color: #D2D6E2
}

.sgexch247 .menu-list a {
    color: #000
}

.sgexch247 .menu-list a:hover {
    background-color: #E6EFD1
}

.sgexch247 .menu-list .Go {
    background-position: 100% -1600px;
    height: 25px;
    width: 23px
}

.sgexch247 .menu-list .Go:hover,
.sgexch247 .menu-list .Go.Go-sgexch247-hover {
    background-position: 100% -1625px
}

.sgexch247 .path {
    background-repeat: repeat-x;
    background-position: 0 -6073px
}

.sgexch247 .path .path-back {
    background-position: 0 -3925px
}

.sgexch247 .path .path-back:hover,
.sgexch247 .path .path-back.path-back-sgexch247-hover {
    background-position: 0 -3950px
}

.sgexch247 .path .path-back.select {
    background-position: 0 -3950px
}

.sgexch247 .path-pop {
    background-color: #000
}

.sgexch247 .game-highlight-wrap h3 {
    background-color: #CB7808;
    color: #fff
}

.sgexch247 .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10655px
}

.sgexch247 .tab-markets {
    background: transparent
}

.sgexch247 .one_click-setting {
    color: #fff;
    background-repeat: repeat-x;
    background-color: #A4413B;
    background-position: 0 -5171px
}

.sgexch247 .one_click-setting a {
    border-color: #244A3E
}

.sgexch247 .one_click-setting .a-edit {
    color: #fff
}

.sgexch247 .one_click-setting .a-edit img {
    background-position: -405px -2251px;
    height: 9px;
    width: 9px
}

.sgexch247 .one_click-stake {
    background-position: 0 -4527px;
    height: 31px;
    width: 414px
}

.sgexch247 .one_click-stake input,
.sgexch247 .one_click-stake textarea,
.sgexch247 .one_click-stake .member_select a,
.member_select .sgexch247 .one_click-stake a {
    border-color: #244A3E;
    color: #1E1E1E;
    background-color: #E9DC76
}

.sgexch247 .one_click-stake input:focus,
.sgexch247 .one_click-stake textarea:focus,
.sgexch247 .one_click-stake .member_select a:focus,
.member_select .sgexch247 .one_click-stake a:focus {
    border-color: #8F3B27;
    background-color: #F4F4DB
}

.sgexch247 .function-wrap {
    background-color: #E7EFEF
}

.sgexch247 .submit-wrap .btn-send span {
    background-color: #1D3900
}

body.sixball {
    background-color: #E1E3E1
}

/* .sixball .btn.select,.sixball .select.btn-send,.sixball .pages a.select,.pages .sixball a.select,.sixball .calendarTable_inputBox input.select,.calendarTable_inputBox .sixball input.select,.sixball .calendarTable_inputBox textarea.select,.calendarTable_inputBox .sixball textarea.select,.sixball .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .sixball a.select,.sixball .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .sixball a.select,.sixball .total_all .select.btn_replay,.total_all .sixball .select.btn_replay,.sixball .select.btn_expand,.sixball .select.add_member{
    color:#fff;
    background:#C97608 url(../images/sixball/btn-select-6ball.jpg) repeat-x;
    border-color:#764B11
}
.sixball .btn-send{
    background:#466D04 url(../images/sixball/bg-send-6ball.png) repeat-x;
    border:1px solid #7BBE09;
    border-radius:4px;
    color:#fff
}
.sixball .btn-send:hover{
    background:#8FBE17 url(../images/sixball/bg-send_hover-6ball.png) repeat-x
}
.sixball .btn-send.disable{
    background:#A3B782 url(../images/sixball/bg-send-disable-6ball.png) repeat-x;
    border-color:#C4D5A6;
    color:#D5E9CC
}
.sixball .login_to_go{
    background-color:#fff
}
.sixball .kv{
    background:#14213D url(../images/sixball/bg-login_wrap-6ball.png) no-repeat
} */
.sixball .login-panel {
    color: #1E1E1E
}

.sixball .login-panel input,
.sixball .login-panel textarea,
.sixball .login-panel .member_select a,
.member_select .sixball .login-panel a {
    border-color: #3E8142
}

.sixball .login-panel .error {
    color: #1E1E1E
}

.sixball .icon-login {
    background-position: 0 -124px;
    height: 12px;
    width: 12px
}

.sixball .announce-header h1:before {
    background: #83AE17
}

/* .sixball .top{
    background:#000 url(../images/sixball/bg-top-6ball.jpg) repeat-x
} */
.sixball .header {
    color: #fff
}

.sixball .header h1 {
    margin-top: -15px
}

.sixball .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#E28614), to(#FDB52D));
    background-image: -webkit-linear-gradient(bottom, #E28614 0%, #FDB52D 100%);
    background-image: linear-gradient(0deg, #E28614 0%, #FDB52D 100%)
}

.sixball .login-wrap li.user {
    background-position: -96px -4892px
}

.sixball .login-wrap .error {
    color: #83AE17
}

.sixball .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.sixball .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.sixball .account-wrap a {
    color: #fff
}

.sixball .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
    background-color: #31362c
}

.sixball .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.sixball .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important
}

.sixball .account-wrap .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.25) !important
}

.sixball .account-wrap .loading-bar span {
    background: #fff
}

.sixball .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.sixball .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #31362c;
    border: 1px solid rgba(0, 0, 0, 0.7)
}

.sixball .account-wrap .head-refresh a {
    background-position: -80px -1175px;
    height: 25px;
    width: 30px
}

.sixball .account-wrap .account {
    background-position: 0 -25px;
    height: 25px;
    width: 110px
}

.sixball .account-wrap ul a {
    color: #1E1E1E
}

.sixball .account-wrap .logout a {
    color: #fff
}

.sixball .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1F3C01), to(#3F7607));
    background-image: -webkit-linear-gradient(top, #1F3C01 0%, #3F7607 100%);
    background-image: linear-gradient(180deg, #1F3C01 0%, #3F7607 100%);
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2);
    background-position: inherit
}

.sixball .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.sixball .menu li,
.sixball .setting-wrap li {
    border-right-color: rgba(218, 255, 116, 0.2)
}

.sixball .menu li ul a,
.sixball .setting-wrap li ul a {
    color: #fff
}

.sixball .menu a,
.sixball .setting-wrap a {
    color: #fff
}

.sixball .menu a:hover,
.sixball .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.sixball .menu .select,
.sixball .setting-wrap .select {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#83AE17), to(#4E7B08));
    background-image: -webkit-linear-gradient(top, #83AE17 0%, #4E7B08 100%);
    background-image: linear-gradient(180deg, #83AE17 0%, #4E7B08 100%)
}

.sixball .menu .logout,
.sixball .setting-wrap .logout {
    border-left-width: 0
}

.sixball .menu .logout img,
.sixball .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.sixball .live-menu {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.sixball .live-menu img {
    background-position: -89px -4440px
}

.sixball .setting {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.sixball .one_click {
    border-top-color: #C8B810;
    color: #fff !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #32372C), to(#272B23));
    background-image: -webkit-linear-gradient(top, #32372C 12%, #272B23 100%);
    background-image: linear-gradient(180deg, #32372C 12%, #272B23 100%);
    background-position: inherit
}

.sixball .one_click img {
    background-position: -94px -2350px
}

.sixball .one_click.select {
    color: #fff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #479C88), to(#377969));
    background-image: -webkit-linear-gradient(top, #479C88 11%, #377969 100%);
    background-image: linear-gradient(180deg, #479C88 11%, #377969 100%);
    background-position: inherit
}

.sixball .one_click.select img {
    background-position: -94px -2320px
}

.sixball .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #377969), to(#479C88));
    background-image: -webkit-linear-gradient(top, #377969 11%, #479C88 100%);
    background-image: linear-gradient(180deg, #377969 11%, #479C88 100%)
}

.sixball .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #272B23), to(#32372C));
    background-image: -webkit-linear-gradient(top, #272B23 12%, #32372C 100%);
    background-image: linear-gradient(180deg, #272B23 12%, #32372C 100%)
}

.sixball .time_zone {
    color: white
}

.sixball .time_zone span {
    color: rgba(255, 255, 255, 0.5)
}

.sixball .menu-list li {
    border-bottom-color: #D2D6E2
}

.sixball .menu-list a {
    color: #000
}

.sixball .menu-list a:hover {
    background-color: #E6EFD1
}

.sixball .menu-list .Go {
    background-position: 100% -50px;
    height: 25px;
    width: 23px
}

.sixball .menu-list .Go:hover,
.sixball .menu-list .Go.Go-6ball-hover {
    background-position: 100% -75px
}

.sixball .path {
    background-repeat: repeat-x;
    background-position: 0 -5523px
}

.sixball .path .path-back {
    background-position: 0 -2275px
}

.sixball .path .path-back:hover,
.sixball .path .path-back.path-back-6ball-hover {
    background-position: 0 -2300px
}

.sixball .path .path-back.select {
    background-position: 0 -2300px
}

.sixball .path-pop {
    background-color: #000
}

.sixball .game-highlight-wrap h3 {
    background-color: #CB7808;
    color: #fff
}

.sixball .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10007px
}

.sixball .tab-markets {
    background: transparent
}

.sixball .one_click-setting {
    color: #fff;
    background-repeat: repeat-x;
    background-color: #A4413B;
    background-position: 0 -4172px
}

.sixball .one_click-setting a {
    border-color: #244A3E
}

.sixball .one_click-setting .a-edit {
    color: #fff
}

.sixball .one_click-setting .a-edit img {
    background-position: -405px -2017px;
    height: 9px;
    width: 9px
}

.sixball .one_click-stake {
    background-position: 0 -3528px;
    height: 31px;
    width: 414px
}

.sixball .one_click-stake input,
.sixball .one_click-stake textarea,
.sixball .one_click-stake .member_select a,
.member_select .sixball .one_click-stake a {
    border-color: #244A3E;
    color: #1E1E1E;
    background-color: #E9DC76
}

.sixball .one_click-stake input:focus,
.sixball .one_click-stake textarea:focus,
.sixball .one_click-stake .member_select a:focus,
.member_select .sixball .one_click-stake a:focus {
    border-color: #8F3B27;
    background-color: #F4F4DB
}

.sixball .function-wrap {
    background-color: #E7EFEF
}

.sixball .submit-wrap .btn-send span {
    background-color: #1D3900
}

body.sixballio {
    background-color: #E1E3E1
}

/* .sixballio .btn.select,.sixballio .select.btn-send,.sixballio .pages a.select,.pages .sixballio a.select,.sixballio .calendarTable_inputBox input.select,.calendarTable_inputBox .sixballio input.select,.sixballio .calendarTable_inputBox textarea.select,.calendarTable_inputBox .sixballio textarea.select,.sixballio .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .sixballio a.select,.sixballio .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .sixballio a.select,.sixballio .total_all .select.btn_replay,.total_all .sixballio .select.btn_replay,.sixballio .select.btn_expand,.sixballio .select.add_member{
    color:#fff;
    background:#C97608 url(../images/sixballio/btn-select-6ballio.jpg) repeat-x;
    border-color:#764B11
}
.sixballio .btn-send{
    background:#466D04 url(../images/sixballio/bg-send-6ballio.png) repeat-x;
    border:1px solid #7BBE09;
    border-radius:4px;
    color:#fff
}
.sixballio .btn-send:hover{
    background:#8FBE17 url(../images/sixballio/bg-send_hover-6ballio.png) repeat-x
}
.sixballio .btn-send.disable{
    background:#A3B782 url(../images/sixballio/bg-send-disable-6ballio.png) repeat-x;
    border-color:#C4D5A6;
    color:#D5E9CC
}
.sixballio .login_to_go{
    background-color:#fff
}
.sixballio .kv{
    background:#14213D url(../images/sixballio/bg-login_wrap-6ballio.png) no-repeat
} */
.sixballio .login-panel {
    color: #1E1E1E
}

.sixballio .login-panel input,
.sixballio .login-panel textarea,
.sixballio .login-panel .member_select a,
.member_select .sixballio .login-panel a {
    border-color: #3E8142
}

.sixballio .login-panel .error {
    color: #1E1E1E
}

.sixballio .icon-login {
    background-position: 0 -136px;
    height: 12px;
    width: 12px
}

.sixballio .announce-header h1:before {
    background: #83AE17
}

/* .sixballio .top{
    background:#000 url(../images/sixballio/bg-top-6ballio.jpg) repeat-x
} */
.sixballio .header {
    color: #fff
}

.sixballio .header h1 {
    margin-top: -15px
}

.sixballio .login-wrap .btn-login {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#E28614), to(#FDB52D));
    background-image: -webkit-linear-gradient(bottom, #E28614 0%, #FDB52D 100%);
    background-image: linear-gradient(0deg, #E28614 0%, #FDB52D 100%)
}

.sixballio .login-wrap li.user {
    background-position: -96px -4917px
}

.sixballio .login-wrap .error {
    color: #83AE17
}

.sixballio .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.sixballio .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.sixballio .account-wrap a {
    color: #fff
}

.sixballio .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
    background-color: #31362c
}

.sixballio .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.sixballio .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important
}

.sixballio .account-wrap .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.25) !important
}

.sixballio .account-wrap .loading-bar span {
    background: #fff
}

.sixballio .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.sixballio .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #31362c;
    border: 1px solid rgba(0, 0, 0, 0.7)
}

.sixballio .account-wrap .head-refresh a {
    background-position: -80px -1200px;
    height: 25px;
    width: 30px
}

.sixballio .account-wrap .account {
    background-position: 0 -50px;
    height: 25px;
    width: 110px
}

.sixballio .account-wrap ul a {
    color: #1E1E1E
}

.sixballio .account-wrap .logout a {
    color: #fff
}

.sixballio .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1F3C01), to(#3F7607));
    background-image: -webkit-linear-gradient(top, #1F3C01 0%, #3F7607 100%);
    background-image: linear-gradient(180deg, #1F3C01 0%, #3F7607 100%);
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2);
    background-position: inherit
}

.sixballio .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.sixballio .menu li,
.sixballio .setting-wrap li {
    border-right-color: rgba(218, 255, 116, 0.2)
}

.sixballio .menu li ul a,
.sixballio .setting-wrap li ul a {
    color: #fff
}

.sixballio .menu a,
.sixballio .setting-wrap a {
    color: #fff
}

.sixballio .menu a:hover,
.sixballio .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.sixballio .menu .select,
.sixballio .setting-wrap .select {
    color: #fff;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#83AE17), to(#4E7B08));
    background-image: -webkit-linear-gradient(top, #83AE17 0%, #4E7B08 100%);
    background-image: linear-gradient(180deg, #83AE17 0%, #4E7B08 100%)
}

.sixballio .menu .logout,
.sixballio .setting-wrap .logout {
    border-left-width: 0
}

.sixballio .menu .logout img,
.sixballio .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.sixballio .live-menu {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.sixballio .live-menu img {
    background-position: -89px -4440px
}

.sixballio .setting {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.sixballio .one_click {
    border-top-color: #C8B810;
    color: #fff !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #32372C), to(#272B23));
    background-image: -webkit-linear-gradient(top, #32372C 12%, #272B23 100%);
    background-image: linear-gradient(180deg, #32372C 12%, #272B23 100%);
    background-position: inherit
}

.sixballio .one_click img {
    background-position: -94px -2350px
}

.sixballio .one_click.select {
    color: #fff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #479C88), to(#377969));
    background-image: -webkit-linear-gradient(top, #479C88 11%, #377969 100%);
    background-image: linear-gradient(180deg, #479C88 11%, #377969 100%);
    background-position: inherit
}

.sixballio .one_click.select img {
    background-position: -94px -2380px
}

.sixballio .time_zone {
    color: white
}

.sixballio .time_zone span {
    color: rgba(255, 255, 255, 0.5)
}

.sixballio .menu-list li {
    border-bottom-color: #D2D6E2
}

.sixballio .menu-list a {
    color: #000
}

.sixballio .menu-list a:hover {
    background-color: #E6EFD1
}

.sixballio .menu-list .Go {
    background-position: 100% -100px;
    height: 25px;
    width: 23px
}

.sixballio .menu-list .Go:hover,
.sixballio .menu-list .Go.Go-6ballio-hover {
    background-position: 100% -125px
}

.sixballio .path {
    background-repeat: repeat-x;
    background-position: 0 -5548px
}

.sixballio .path .path-back {
    background-position: 0 -2325px
}

.sixballio .path .path-back:hover,
.sixballio .path .path-back.path-back-6ballio-hover {
    background-position: 0 -2350px
}

.sixballio .path .path-back.select {
    background-position: 0 -2350px
}

.sixballio .path-pop {
    background-color: #000
}

.sixballio .game-highlight-wrap h3 {
    background-color: #CB7808;
    color: #fff
}

.sixballio .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10029px
}

.sixballio .tab-markets {
    background: transparent
}

.sixballio .one_click-setting {
    color: #fff;
    background-repeat: repeat-x;
    background-color: #A4413B;
    background-position: 0 -4203px
}

.sixballio .one_click-setting a {
    border-color: #244A3E
}

.sixballio .one_click-setting .a-edit {
    color: #fff
}

.sixballio .one_click-setting .a-edit img {
    background-position: -405px -2026px;
    height: 9px;
    width: 9px
}

.sixballio .one_click-stake {
    background-position: 0 -3559px;
    height: 31px;
    width: 414px
}

.sixballio .one_click-stake input,
.sixballio .one_click-stake textarea,
.sixballio .one_click-stake .member_select a,
.member_select .sixballio .one_click-stake a {
    border-color: #244A3E;
    color: #1E1E1E;
    background-color: #E9DC76
}

.sixballio .one_click-stake input:focus,
.sixballio .one_click-stake textarea:focus,
.sixballio .one_click-stake .member_select a:focus,
.member_select .sixballio .one_click-stake a:focus {
    border-color: #8F3B27;
    background-color: #F4F4DB
}

.sixballio .function-wrap {
    background-color: #E7EFEF
}

.sixballio .submit-wrap .btn-send span {
    background-color: #1D3900
}

body.wickets247 {
    background-color: #F0ECE1
}

/* .wickets247 .btn.select,.wickets247 .select.btn-send,.wickets247 .pages a.select,.pages .wickets247 a.select,.wickets247 .calendarTable_inputBox input.select,.calendarTable_inputBox .wickets247 input.select,.wickets247 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .wickets247 textarea.select,.wickets247 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .wickets247 a.select,.wickets247 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .wickets247 a.select,.wickets247 .total_all .select.btn_replay,.total_all .wickets247 .select.btn_replay,.wickets247 .select.btn_expand,.wickets247 .select.add_member{
    color:#F8D61C;
    background:#444 url(../images/9wickets247/btn-select-9wickets247.jpg) repeat-x;
    border-color:#222
}
.wickets247 .btn.select:hover,.wickets247 .select.btn-send:hover,.wickets247 .pages a.select:hover,.pages .wickets247 a.select:hover,.wickets247 .calendarTable_inputBox input.select:hover,.calendarTable_inputBox .wickets247 input.select:hover,.wickets247 .calendarTable_inputBox textarea.select:hover,.calendarTable_inputBox .wickets247 textarea.select:hover,.wickets247 .calendarTable_inputBox .member_select a.select:hover,.calendarTable_inputBox .member_select .wickets247 a.select:hover,.wickets247 .member_select .calendarTable_inputBox a.select:hover,.member_select .calendarTable_inputBox .wickets247 a.select:hover,.wickets247 .total_all .select.btn_replay:hover,.total_all .wickets247 .select.btn_replay:hover,.wickets247 .select.btn_expand:hover,.wickets247 .select.add_member:hover{
    background:#444 url(../images/9wickets247/btn-select-9wickets247.jpg) repeat-x
}
.wickets247 .btn-send{
    background:#121212 url(../images/9wickets247/bg-send-9wickets247.png) repeat-x;
    color:#F8D61C;
    border-color:#000
}
.wickets247 .btn-send:hover{
    background:#464646 url(../images/9wickets247/bg-send_hover-9wickets247.png) repeat-x
}
.wickets247 .btn-send.disable{
    background:#A4A4A4 url(../images/9wickets247/bg-send-disable-9wickets247.png) repeat-x;
    border-color:#aaa;
    color:#E6DFCD
}
.wickets247 .kv{
    background:#000 url(../images/9wickets247/bg-login_wrap-9wickets247.png) no-repeat
} */
.wickets247 .login-panel {
    color: #000
}

.wickets247 .icon-login {
    background-position: 0 -148px;
    height: 12px;
    width: 12px
}

.wickets247 .announce-header h1:before {
    background: #FFB80C
}

/* .wickets247 .top{
    background:#000 url(../images/9wickets247/bg-top-9wickets247.jpg) repeat-x
} */
.wickets247 .header {
    color: #FFB80C
}

.wickets247 .header h1 {
    margin-top: -10px
}

.wickets247 .login-wrap li.user {
    background-position: -96px -4942px
}

.wickets247 .login-wrap .error {
    color: #F8D61C
}

.wickets247 .login-wrap .btn-signup {
    display: block;
    background-color: #666666;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#666), to(#333));
    background-image: -webkit-linear-gradient(top, #666 0%, #333 100%);
    background-image: linear-gradient(-180deg, #666 0%, #333 100%)
}

.wickets247 .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.wickets247 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #F2E9CF !important;
    background-color: #BF0000;
    border-radius: 3px
}

.wickets247 .account-wrap a {
    color: #FFB80C
}

.wickets247 .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8)
}

.wickets247 .account-wrap .a-wallet .nums {
    border: 1px solid #FFB80C
}

.wickets247 .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important
}

.wickets247 .account-wrap .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.25) !important
}

.wickets247 .account-wrap .loading-bar span {
    background: #fff
}

.wickets247 .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFB80C"/></svg>') no-repeat center center;
    background-size: contain
}

.wickets247 .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.8)
}

.wickets247 .account-wrap .head-refresh a {
    background-position: -80px -1225px;
    height: 25px;
    width: 30px
}

.wickets247 .account-wrap .account {
    background-position: 0 -75px;
    height: 25px;
    width: 110px
}

.wickets247 .account-wrap ul a {
    color: #1E1E1E
}

.wickets247 .account-wrap .logout a {
    color: #fff
}

.wickets247 .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, #FFCC2F), to(#FFB80C));
    background-image: -webkit-linear-gradient(top, #FFCC2F 8%, #FFB80C 100%);
    background-image: linear-gradient(180deg, #FFCC2F 8%, #FFB80C 100%);
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.75)
}

.wickets247 .menu-wrap a.menu-drop:after {
    border-top-color: #222
}

.wickets247 .menu li,
.wickets247 .setting-wrap li {
    border-right-color: rgba(69, 32, 7, 0.2)
}

.wickets247 .menu li ul a,
.wickets247 .setting-wrap li ul a {
    color: #FFB80C
}

.wickets247 .menu a,
.wickets247 .setting-wrap a {
    color: #222
}

.wickets247 .menu a:hover,
.wickets247 .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.wickets247 .menu .select,
.wickets247 .setting-wrap .select {
    box-shadow: inset 0 1px 3px 0 rgba(52, 9, 9, 0.5);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#DBBC68), color-stop(61%, #FFDC7A));
    background-image: -webkit-linear-gradient(top, #DBBC68 0%, #FFDC7A 61%);
    background-image: linear-gradient(180deg, #DBBC68 0%, #FFDC7A 61%);
    background-position: inherit
}

.wickets247 .menu .tag-new,
.wickets247 .setting-wrap .tag-new {
    color: #FFFFFF
}

.wickets247 .setting {
    border-left-color: rgba(69, 32, 7, 0.2)
}

.wickets247 .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23222" fill-rule="evenodd"/></svg>') no-repeat
}

.wickets247 .one_click {
    border-top-color: #FFB80C;
    color: #FFB80C !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(13%, #444), color-stop(81%, #1B1B1B));
    background-image: -webkit-linear-gradient(top, #444 13%, #1B1B1B 81%);
    background-image: linear-gradient(180deg, #444 13%, #1B1B1B 81%);
    background-position: inherit
}

.wickets247 .one_click img {
    background-position: -94px -2470px
}

.wickets247 .one_click.select {
    color: #1E1E1E !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#00C15D), to(#009347)) !important;
    background-image: -webkit-linear-gradient(top, #00C15D 0%, #009347 100%) !important;
    background-image: linear-gradient(180deg, #00C15D 0%, #009347 100%) !important;
    background-position: inherit
}

.wickets247 .one_click.select img {
    background-position: -94px -2440px
}

.wickets247 .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(100%, #009347), color-stop(0%, #00C15D)) !important;
    background-image: -webkit-linear-gradient(top, #009347 100%, #00C15D 0%) !important;
    background-image: linear-gradient(180deg, #009347 100%, #00C15D 0%) !important
}

.wickets247 .one_click:hover {
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(19%, #444), color-stop(87%, #1B1B1B));
    background-image: -webkit-linear-gradient(bottom, #444 19%, #1B1B1B 87%);
    background-image: linear-gradient(0deg, #444 19%, #1B1B1B 87%)
}

.wickets247 .slip_set-pop a:hover,
.wickets247 .oneclick_set-pop a:hover {
    text-decoration: none
}

.wickets247 .slip_set-pop .btn-send,
.wickets247 .oneclick_set-pop .btn-send {
    color: #F8D61C
}

.wickets247 .time_zone {
    color: #000
}

.wickets247 .time_zone span {
    color: #9A7816
}

.wickets247 .menu-list li {
    border-bottom-color: #EFE1C1
}

.wickets247 .menu-list a {
    color: #222
}

.wickets247 .menu-list a:hover {
    background-color: #F2DCA7
}

.wickets247 .menu-list .select {
    background-color: #F2DCA7;
    color: #222
}

.wickets247 .menu-list .select:hover {
    background-color: #F2DCA7
}

.wickets247 .menu-list .Go {
    background-position: 100% -150px;
    height: 25px;
    width: 23px
}

.wickets247 .menu-list .Go:hover,
.wickets247 .menu-list .Go.Go-9wickets247-hover {
    background-position: 100% -175px
}

.wickets247 .path {
    background: #222
}

.wickets247 .path a {
    color: #FFB80C
}

.wickets247 .path .path-back {
    background-position: 0 -2375px;
    height: 25px;
    width: 36px
}

.wickets247 .path .path-back:hover,
.wickets247 .path .path-back.path-back-9wickets247-hover {
    background-position: 0 -2400px
}

.wickets247 .path .path-back.select {
    background-position: 0 -2400px;
    height: 25px;
    width: 36px
}

.wickets247 .path-pop {
    background-color: #222
}

.wickets247 .login_to_go {
    background-color: #FFB80C
}

.wickets247 .login_to_go .close {
    background-position: -394px -1060px;
    height: 20px;
    width: 20px
}

.wickets247 .one_click-setting {
    color: #1E1E1E;
    background-repeat: repeat-x;
    background-color: #008841;
    background-position: 0 -4234px
}

.wickets247 .one_click-setting a {
    border-color: #005F2D
}

.wickets247 .one_click-setting .a-edit {
    color: #1E1E1E
}

.wickets247 .one_click-setting .a-edit img {
    background-position: -405px -2035px;
    height: 9px;
    width: 9px
}

.wickets247 .one_click-stake {
    background-position: 0 -3590px;
    height: 31px;
    width: 414px
}

.wickets247 .one_click-stake input,
.wickets247 .one_click-stake textarea,
.wickets247 .one_click-stake .member_select a,
.member_select .wickets247 .one_click-stake a {
    border-color: #005F2D;
    color: #fff;
    background-color: #1F8C53
}

.wickets247 .one_click-stake input:focus,
.wickets247 .one_click-stake textarea:focus,
.wickets247 .one_click-stake .member_select a:focus,
.member_select .wickets247 .one_click-stake a:focus {
    border-color: #fff;
    background-color: #00632F
}

.wickets247 .menu .logout,
.wickets247 .setting-wrap .logout {
    border-left-width: 0
}

.wickets247 .menu .logout img,
.wickets247 .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23222" fill-rule="evenodd"/></svg>') no-repeat
}

.wickets247 .footer_fixed .main_wrap,
.wickets247 .footer_fixed .header,
.wickets247 .footer_fixed .footer-content {
    background-color: #F0ECE1
}

.wickets247 .submit-wrap .btn-send span {
    background-color: #666
}

/* .crickex .btn.select,.crickex .select.btn-send,.crickex .pages a.select,.pages .crickex a.select,.crickex .calendarTable_inputBox input.select,.calendarTable_inputBox .crickex input.select,.crickex .calendarTable_inputBox textarea.select,.calendarTable_inputBox .crickex textarea.select,.crickex .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .crickex a.select,.crickex .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .crickex a.select,.crickex .total_all .select.btn_replay,.total_all .crickex .select.btn_replay,.crickex .select.btn_expand,.crickex .select.add_member{
    color:#fff;
    background:#4577A5 url(../images/crickex/btn-select-crickex.jpg) repeat-x;
    border-color:#00539C
}
.crickex .btn.select:hover,.crickex .select.btn-send:hover,.crickex .pages a.select:hover,.pages .crickex a.select:hover,.crickex .calendarTable_inputBox input.select:hover,.calendarTable_inputBox .crickex input.select:hover,.crickex .calendarTable_inputBox textarea.select:hover,.calendarTable_inputBox .crickex textarea.select:hover,.crickex .calendarTable_inputBox .member_select a.select:hover,.calendarTable_inputBox .member_select .crickex a.select:hover,.crickex .member_select .calendarTable_inputBox a.select:hover,.member_select .calendarTable_inputBox .crickex a.select:hover,.crickex .total_all .select.btn_replay:hover,.total_all .crickex .select.btn_replay:hover,.crickex .select.btn_expand:hover,.crickex .select.add_member:hover{
    background:#004987 url(../images/crickex/btn-select-crickex.jpg) repeat-x
}
.crickex .btn-send{
    background:#005DAC url(../images/crickex/bg-send-crickex.png) repeat-x;
    color:#fff;
    border-color:#00539C
}
.crickex .btn-send:hover{
    background:#004987 url(../images/crickex/bg-send_hover-crickex.png) repeat-x
}
.crickex .btn-send.disable{
    background:#7FADD5 url(../images/crickex/bg-send-disable-crickex.png) repeat-x;
    border-color:#76A2C7;
    color:#A2CAEC
}
.crickex .kv{
    background:#000 url(../images/crickex/bg-login_wrap-crickex.png) no-repeat
} */
.crickex .login-panel {
    color: #000
}

.crickex .icon-login {
    background-position: 0 -244px;
    height: 12px;
    width: 12px
}

.crickex .announce-header h1:before {
    background: #0D7FD6
}

/* .crickex .top{
    background:#005dab url(../images/crickex/bg-top-crickex.jpg) repeat-x
} */
.crickex .header {
    color: #fff
}

.crickex .login-wrap li.user {
    background-position: -96px -5167px
}

.crickex .login-wrap .error {
    color: #F8D61C
}

.crickex .login-wrap .btn-signup {
    display: block;
    background-color: #6CAE4E
}

.crickex .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.crickex .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #F2E9CF !important;
    background-color: #BF0000;
    border-radius: 3px
}

.crickex .account-wrap a {
    color: #fff
}

.crickex .account-wrap .main-wallet {
    background-color: #216eb5
}

.crickex .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.crickex .account-wrap .loading-bar span {
    background: #fff
}

.crickex .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #216eb5;
    border: 1px solid rgba(0, 0, 0, 0.3)
}

.crickex .account-wrap .head-refresh a {
    background-position: -80px -1500px;
    height: 25px;
    width: 30px
}

.crickex .account-wrap .account {
    background-position: 0 -350px;
    height: 25px;
    width: 110px
}

.crickex .account-wrap ul a {
    color: #1E1E1E
}

.crickex .account-wrap .logout a {
    color: #fff
}

.crickex .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10259px
}

.crickex .game-highlight-wrap h3 {
    background-color: #005DAC;
    color: #fff
}

.crickex .menu-wrap {
    background-color: #0D7FD6
}

.crickex .menu-wrap a.menu-drop:after {
    border-top-color: #fff
}

.crickex .menu li,
.crickex .setting-wrap li {
    border-right-color: rgba(69, 32, 7, 0.2)
}

.crickex .menu li li,
.crickex .setting-wrap li li {
    border-bottom: 1px solid #1471ba
}

.crickex .menu li ul,
.crickex .setting-wrap li ul {
    background-color: #1471ba;
    border-top: 1px solid #1471ba
}

.crickex .menu li ul a,
.crickex .setting-wrap li ul a {
    color: #fff;
    background-color: #0D7FD6
}

.crickex .menu a,
.crickex .setting-wrap a {
    color: #fff
}

.crickex .menu a:hover,
.crickex .setting-wrap a:hover {
    background-color: #1599FF;
    text-decoration: none
}

.crickex .menu .select,
.crickex .setting-wrap .select {
    background-color: #52A9EC
}

.crickex .menu .tag-new,
.crickex .setting-wrap .tag-new {
    color: #FFFFFF
}

.crickex .setting {
    border-left-color: rgba(69, 32, 7, 0.2)
}

.crickex .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23fff" fill-rule="evenodd"/></svg>') no-repeat
}

.crickex .one_click {
    border-top-color: #79DA4D;
    background-repeat: repeat-x;
    color: #fff !important;
    background-position: 0 -1650px
}

.crickex .one_click:hover,
.crickex .one_click.bg-1click-crickex-hover {
    background-position: 0 -1560px
}

.crickex .one_click img {
    background-position: -94px -3070px
}

.crickex .one_click.select {
    color: #fff !important;
    background-repeat: repeat-x;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-position: 0 -1620px
}

.crickex .one_click.select:hover,
.crickex .one_click.select.bg-1click-crickex-select-hover {
    background-position: 0 -1590px
}

.crickex .one_click.select img {
    background-position: -94px -3040px
}

.crickex .live-menu {
    border-left: rgba(69, 32, 7, 0.2)
}

.crickex .slip_set-pop a:hover,
.crickex .oneclick_set-pop a:hover {
    text-decoration: none
}

.crickex .slip_set-pop .btn-send,
.crickex .oneclick_set-pop .btn-send {
    color: #fff
}

.crickex .time_zone {
    color: #fff
}

.crickex .menu-list a {
    color: #222
}

.crickex .menu-list a:hover {
    background-color: #E5EEF4
}

.crickex .menu-list .select {
    background-color: #E5EEF4;
    color: #222
}

.crickex .menu-list .select:hover {
    background-color: #E5EEF4
}

.crickex .menu-list .Go {
    background-position: 100% -600px;
    height: 25px;
    width: 23px
}

.crickex .menu-list .Go:hover,
.crickex .menu-list .Go.Go-crickex-hover {
    background-position: 100% -625px
}

.crickex .path {
    background: #005DAC
}

.crickex .path a {
    color: #fff
}

.crickex .path .path-back {
    background-position: 0 -2875px;
    height: 25px;
    width: 36px
}

.crickex .path .path-back:hover,
.crickex .path .path-back.path-back-crickex-hover {
    background-position: 0 -2900px
}

.crickex .path .path-back.select {
    background-position: 0 -2900px;
    height: 25px;
    width: 36px
}

.crickex .path-pop {
    background-color: #222
}

.crickex .login_to_go {
    background-color: #FFB80C
}

.crickex .login_to_go .close {
    background-position: -394px -1100px;
    height: 20px;
    width: 20px
}

.crickex .one_click-setting {
    background-color: #0D7FD6;
    background-repeat: repeat-x;
    background-position: 0 -4545px
}

.crickex .one_click-setting a {
    border-color: #004987
}

.crickex .one_click-setting .a-edit {
    color: #fff
}

.crickex .one_click-setting .a-edit img {
    background-position: -405px -2107px;
    height: 9px;
    width: 9px
}

.crickex .one_click-stake {
    background-position: 0 -3901px;
    height: 31px;
    width: 414px
}

.crickex .one_click-stake input,
.crickex .one_click-stake textarea,
.crickex .one_click-stake .member_select a,
.member_select .crickex .one_click-stake a {
    border-color: #345877;
    color: #fff;
    background-color: #345877
}

.crickex .one_click-stake input:focus,
.crickex .one_click-stake textarea:focus,
.crickex .one_click-stake .member_select a:focus,
.member_select .crickex .one_click-stake a:focus {
    border-color: #fff;
    background-color: #274259
}

.crickex .menu .logout,
.crickex .setting-wrap .logout {
    border-left-width: 0
}

.crickex .menu .logout img,
.crickex .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23fff" fill-rule="evenodd"/></svg>') no-repeat
}

/* .crickex .pages a.select:hover{
    background:#00539C url(../images/crickex/bg-send_hover-crickex.jpg) repeat-x
} */
.crickex .footer_fixed .main_wrap,
.crickex .footer_fixed .header,
.crickex .footer_fixed .footer-content {
    background-color: #F0ECE1
}

.crickex .submit-wrap .btn-send span {
    background-color: #1599FF
}

/* .iceEX .btn.select,.iceEX .select.btn-send,.iceEX .pages a.select,.pages .iceEX a.select,.iceEX .calendarTable_inputBox input.select,.calendarTable_inputBox .iceEX input.select,.iceEX .calendarTable_inputBox textarea.select,.calendarTable_inputBox .iceEX textarea.select,.iceEX .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .iceEX a.select,.iceEX .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .iceEX a.select,.iceEX .total_all .select.btn_replay,.total_all .iceEX .select.btn_replay,.iceEX .select.btn_expand,.iceEX .select.add_member{
    color:#F8D61C;
    background:#444 url(../images/iceEX/btn-select-iceEX.jpg) repeat-x;
    border-color:#222
}
.iceEX .btn.select:hover,.iceEX .select.btn-send:hover,.iceEX .pages a.select:hover,.pages .iceEX a.select:hover,.iceEX .calendarTable_inputBox input.select:hover,.calendarTable_inputBox .iceEX input.select:hover,.iceEX .calendarTable_inputBox textarea.select:hover,.calendarTable_inputBox .iceEX textarea.select:hover,.iceEX .calendarTable_inputBox .member_select a.select:hover,.calendarTable_inputBox .member_select .iceEX a.select:hover,.iceEX .member_select .calendarTable_inputBox a.select:hover,.member_select .calendarTable_inputBox .iceEX a.select:hover,.iceEX .total_all .select.btn_replay:hover,.total_all .iceEX .select.btn_replay:hover,.iceEX .select.btn_expand:hover,.iceEX .select.add_member:hover{
    background:#444 url(../images/iceEX/btn-select-iceEX.jpg) repeat-x
}
.iceEX .btn-send{
    background:#121212 url(../images/iceEX/bg-send-iceEX.png) repeat-x;
    color:#F8D61C;
    border-color:#121212
}
.iceEX .btn-send:hover{
    background:#464646 url(../images/iceEX/bg-send_hover-iceEX.png) repeat-x
}
.iceEX .btn-send.disable{
    background:#A4A4A4 url(../images/iceEX/bg-send-disable-iceEX.png) repeat-x;
    border-color:#aaa;
    color:#E6DFCD
}
.iceEX .kv{
    background:#000 url(../images/iceEX/bg-login_wrap-iceEX.jpg) no-repeat
} */
.iceEX .login-panel {
    color: #000
}

.iceEX .icon-login {
    background-position: 0 -304px;
    height: 12px;
    width: 12px
}

.iceEX .announce-header h1:before {
    background: #FFB80C
}

/* .iceEX .top{
    background:url(../images/iceEX/bg-top-iceEX.png),-webkit-gradient(linear, left top, left bottom, from(#281E00), to(#000));
    background:url(../images/iceEX/bg-top-iceEX.png),-webkit-linear-gradient(top, #281E00 0%, #000 100%);
    background:url(../images/iceEX/bg-top-iceEX.png),linear-gradient(180deg, #281E00 0%, #000 100%);
    background-repeat:no-repeat
} */
.iceEX .header {
    color: #FFB80C
}

.iceEX .header h1 {
    margin-top: -15px
}

.iceEX .login-wrap li.user {
    background-position: -96px -5292px
}

.iceEX .login-wrap .error {
    color: #F8D61C
}

.iceEX .login-wrap .btn-signup {
    display: none;
    background-color: #666666;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#666), to(#333));
    background-image: -webkit-linear-gradient(top, #666 0%, #333 100%);
    background-image: linear-gradient(-180deg, #666 0%, #333 100%)
}

.iceEX .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.iceEX .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #F2E9CF !important;
    background-color: #BF0000;
    border-radius: 3px
}

.iceEX .account-wrap a {
    color: #FFB80C
}

.iceEX .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8);
    background-color: #2a2a2a
}

.iceEX .account-wrap .a-wallet .nums {
    border: 1px solid #FFB80C
}

.iceEX .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important
}

.iceEX .account-wrap .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.3) !important
}

.iceEX .account-wrap .loading-bar {
    background-color: rgba(255, 255, 255, 0.15)
}

.iceEX .account-wrap .loading-bar span {
    background: #fff
}

.iceEX .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23F5B942"/></svg>') no-repeat center center;
    background-size: contain
}

.iceEX .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #282828;
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.iceEX .account-wrap .head-refresh a {
    background-position: -80px -1675px;
    height: 25px;
    width: 30px
}

.iceEX .account-wrap .account {
    background-position: 0 -525px;
    height: 25px;
    width: 110px
}

.iceEX .account-wrap ul a {
    color: #1E1E1E
}

.iceEX .account-wrap .logout a {
    color: #fff
}

.iceEX .menu-wrap {
    background: #1E1E1E;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, #000), to(#2A2A2A));
    background-image: -webkit-linear-gradient(top, #000 8%, #2A2A2A 100%);
    background-image: linear-gradient(180deg, #000 8%, #2A2A2A 100%);
    border-top: solid 1px #695600
}

.iceEX .menu-wrap a.menu-drop:after {
    border-top-color: #fff
}

.iceEX .menu li,
.iceEX .setting-wrap li {
    border-right-color: rgba(69, 32, 7, 0.2)
}

.iceEX .menu li ul a,
.iceEX .setting-wrap li ul a {
    color: #FFB80C
}

.iceEX .menu a,
.iceEX .setting-wrap a {
    color: #fff
}

.iceEX .menu a:hover,
.iceEX .setting-wrap a:hover {
    text-decoration: underline;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#404040), to(#222));
    background-image: -webkit-linear-gradient(top, #404040 0%, #222 100%);
    background-image: linear-gradient(180deg, #404040 0%, #222 100%);
    box-shadow: inset 0 1px 2px 0 rgba(52, 9, 9, 0.5)
}

.iceEX .menu .select,
.iceEX .setting-wrap .select {
    color: #222;
    background: #D29A00;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D29A00), color-stop(50%, #FFBC00));
    background-image: -webkit-linear-gradient(top, #D29A00 0%, #FFBC00 50%);
    background-image: linear-gradient(180deg, #D29A00 0%, #FFBC00 50%);
    box-shadow: inset 0 1px 3px 0 rgba(52, 9, 9, 0.4)
}

.iceEX .menu .select:hover,
.iceEX .setting-wrap .select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D29A00), color-stop(50%, #FFBC00));
    background-image: -webkit-linear-gradient(top, #D29A00 0%, #FFBC00 50%);
    background-image: linear-gradient(180deg, #D29A00 0%, #FFBC00 50%);
    box-shadow: inset 0 1px 2px 0 rgba(52, 9, 9, 0.4)
}

.iceEX .menu .select.menu-drop:after,
.iceEX .setting-wrap .select.menu-drop:after {
    border-top-color: #000
}

.iceEX .menu .tag-new,
.iceEX .setting-wrap .tag-new {
    color: #FFFFFF
}

.iceEX .setting {
    border-left-color: rgba(69, 32, 7, 0.2)
}

.iceEX .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23fff" fill-rule="evenodd"/></svg>') no-repeat
}

.iceEX .one_click {
    border-top-color: #FF5F5F;
    background-repeat: repeat-x;
    color: #fff !important;
    background-position: 0 -2430px
}

.iceEX .one_click:hover,
.iceEX .one_click.bg-1click-iceEX-hover {
    background-position: 0 -2340px
}

.iceEX .one_click img {
    background-position: -94px -3490px
}

.iceEX .one_click.select {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F00015), to(#AB0019));
    background-image: -webkit-linear-gradient(top, #F00015 0%, #AB0019 100%);
    background-image: linear-gradient(180deg, #F00015 0%, #AB0019 100%)
}

.iceEX .one_click.select img {
    background-position: -94px -3460px
}

.iceEX .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#A6000F), to(#F00015));
    background-image: -webkit-linear-gradient(top, #A6000F 0%, #F00015 100%);
    background-image: linear-gradient(180deg, #A6000F 0%, #F00015 100%)
}

.iceEX .slip_set-pop a:hover,
.iceEX .oneclick_set-pop a:hover {
    text-decoration: none
}

.iceEX .slip_set-pop .btn-send,
.iceEX .oneclick_set-pop .btn-send {
    color: #F8D61C
}

.iceEX .time_zone {
    color: #fff
}

.iceEX .time_zone span {
    color: #9A7816
}

.iceEX .menu-list li {
    border-bottom-color: #EFE1C1
}

.iceEX .menu-list a {
    color: #222
}

.iceEX .menu-list a:hover {
    background-color: #F2DCA7
}

.iceEX .menu-list .select {
    background-color: #F2DCA7;
    color: #222
}

.iceEX .menu-list .select:hover {
    background-color: #F2DCA7
}

.iceEX .menu-list .Go {
    background-position: 100% -950px;
    height: 25px;
    width: 23px
}

.iceEX .menu-list .Go:hover,
.iceEX .menu-list .Go.Go-iceEX-hover {
    background-position: 100% -975px
}

.iceEX .path {
    background: #222
}

.iceEX .path a {
    color: #FFB80C
}

.iceEX .path .path-back {
    background-position: 0 -3275px;
    height: 25px;
    width: 36px
}

.iceEX .path .path-back:hover,
.iceEX .path .path-back.path-back-iceEX-hover {
    background-position: 0 -3300px
}

.iceEX .path .path-back.select {
    background-position: 0 -3300px;
    height: 25px;
    width: 36px
}

.iceEX .path-pop {
    background-color: #222
}

.iceEX .game-highlight-wrap h3 {
    background-color: #DEB34A
}

.iceEX .game-highlight-wrap .tab-highlight {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #DEB34A), to(#9e7f4d));
    background-image: -webkit-linear-gradient(top, #DEB34A 30%, #9e7f4d 100%);
    background-image: linear-gradient(180deg, #DEB34A 30%, #9e7f4d 100%);
    background-repeat: inherit;
    background-position: inherit
}

.iceEX .login_to_go {
    background-color: #DEB34A
}

.iceEX .login_to_go .close {
    background-position: -394px -1140px;
    height: 20px;
    width: 20px
}

.iceEX .one_click-setting {
    color: #fff;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(10%, rgba(51, 51, 51, 0)), to(#D00101));
    background-image: -webkit-linear-gradient(bottom, rgba(51, 51, 51, 0) 10%, #D00101 100%);
    background-image: linear-gradient(0deg, rgba(51, 51, 51, 0) 10%, #D00101 100%);
    background-color: #940000;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.36);
    background-repeat: inherit
}

.iceEX .one_click-setting a {
    border-color: #530606
}

.iceEX .one_click-setting .a-edit {
    color: #1E1E1E;
    color: #fff
}

.iceEX .one_click-setting .a-edit img {
    background-position: -405px -2161px;
    height: 9px;
    width: 9px
}

.iceEX .one_click-stake {
    background-position: 0 -4151px;
    height: 31px;
    width: 414px
}

.iceEX .one_click-stake input,
.iceEX .one_click-stake textarea,
.iceEX .one_click-stake .member_select a,
.member_select .iceEX .one_click-stake a {
    border-color: #005F2D;
    color: #000;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #fff), color-stop(93%, #E1E1E1));
    background-image: -webkit-linear-gradient(top, #fff 15%, #E1E1E1 93%);
    background-image: linear-gradient(180deg, #fff 15%, #E1E1E1 93%);
    border: 1px solid #530606;
    border-radius: 4px
}

.iceEX .one_click-stake input:focus,
.iceEX .one_click-stake textarea:focus,
.iceEX .one_click-stake .member_select a:focus,
.member_select .iceEX .one_click-stake a:focus {
    border-color: #fff;
    background-color: #D00101
}

.iceEX .menu .logout,
.iceEX .setting-wrap .logout {
    border-left-width: 0
}

.iceEX .menu .logout img,
.iceEX .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23fff" fill-rule="evenodd"/></svg>') no-repeat
}

.iceEX .submit-wrap .btn-send span {
    background-color: #666
}

.iceEX .pages a.select {
    background-color: #444
}

body.iceinplay {
    background-color: #EEEEEE
}

/* .iceinplay .btn.select,.iceinplay .select.btn-send,.iceinplay .pages a.select,.pages .iceinplay a.select,.iceinplay .calendarTable_inputBox input.select,.calendarTable_inputBox .iceinplay input.select,.iceinplay .calendarTable_inputBox textarea.select,.calendarTable_inputBox .iceinplay textarea.select,.iceinplay .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .iceinplay a.select,.iceinplay .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .iceinplay a.select,.iceinplay .total_all .select.btn_replay,.total_all .iceinplay .select.btn_replay,.iceinplay .select.btn_expand,.iceinplay .select.add_member{
    background:#443A56 url(../images/iceinplay/btn-select-iceinplay.png) repeat-x;
    border-color:#160C28;
    color:#FFFFFF
}
.iceinplay .btn-send{
    background:#FF9C00 url(../images/iceinplay/bg-send-iceinplay.png) repeat-x;
    border-color:transparent;
    border-radius:4px
}
.iceinplay .btn-send:hover{
    background:#FBC30A url(../images/iceinplay/bg-send_hover-iceinplay.png) repeat-x
}
.iceinplay .btn-send.disable{
    background:#FFD899 url(../images/iceinplay/bg-send-disable-iceinplay.png) repeat-x;
    border-color:transparent;
    color:#A5A5A5
}
.iceinplay .login_to_go{
    background-color:#fff
}
.iceinplay .kv{
    background:#443A56 url(../images/iceinplay/bg-login_wrap-iceinplay.jpg) no-repeat
} */
.iceinplay .login-panel {
    color: #1E1E1E
}

.iceinplay .icon-login {
    background-position: 0 -184px;
    height: 12px;
    width: 12px
}

.iceinplay .announce-header h1:before {
    background: #443A56
}

/* .iceinplay .top{
    background:#14213D url(../images/iceinplay/bg-top-iceinplay.jpg) repeat-x
} */
.iceinplay .header {
    color: #fff
}

.iceinplay .header h1 {
    margin-top: -15px
}

.iceinplay .login-wrap .btn-login {
    background: #E71A05
}

.iceinplay .login-wrap li.user {
    background-position: -96px -5667px
}

.iceinplay .login-wrap .error {
    color: #cccccc
}

.iceinplay .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.iceinplay .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.iceinplay .account-wrap a {
    color: #fff
}

.iceinplay .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4);
    background-color: #58437f
}

.iceinplay .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.iceinplay .account-wrap .loading-bar span {
    background: #fff
}

.iceinplay .account-wrap .loading-bar,
.iceinplay .account-wrap .a-refresh {
    background-color: #58437f
}

.iceinplay .account-wrap .main-wallet.no-multi .a-refresh {
    border: 1px solid rgba(0, 0, 0, 0.7)
}

.iceinplay .account-wrap .head-refresh a {
    background-position: -80px -1700px;
    height: 25px;
    width: 30px
}

.iceinplay .account-wrap .account {
    background-position: 0 -550px;
    height: 25px;
    width: 110px
}

.iceinplay .account-wrap ul a {
    color: #1E1E1E
}

.iceinplay .account-wrap .logout a {
    color: #fff
}

.iceinplay .menu-wrap {
    background: #FBC30A
}

.iceinplay .menu-wrap a.menu-drop:after {
    border-top-color: #443a56
}

.iceinplay .menu li,
.iceinplay .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.3)
}

.iceinplay .menu li ul a,
.iceinplay .setting-wrap li ul a {
    color: #FFFFFF
}

.iceinplay .menu li ul a:hover,
.iceinplay .setting-wrap li ul a:hover {
    background-color: rgba(70, 46, 113, 0.5);
    color: #FFFFFF
}

.iceinplay .menu a,
.iceinplay .setting-wrap a {
    color: #443A56
}

.iceinplay .menu a:hover,
.iceinplay .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #462E71;
    text-decoration: underline
}

.iceinplay .menu .select,
.iceinplay .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.5);
    color: #462E71
}

.iceinplay .menu .logout,
.iceinplay .setting-wrap .logout {
    border-left-width: 0
}

.iceinplay .menu .logout img,
.iceinplay .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23443a56" fill-rule="evenodd"/></svg>') no-repeat
}

.iceinplay .menu .tag-new,
.iceinplay .setting-wrap .tag-new {
    color: #FFFFFF
}

.iceinplay .menu .tag-new:hover,
.iceinplay .setting-wrap .tag-new:hover {
    color: #FFFFFF
}

.iceinplay .live-menu {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.iceinplay .setting {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.iceinplay .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23443a56" fill-rule="evenodd"/></svg>') no-repeat
}

.iceinplay .one_click {
    border-top-color: #FBC30A;
    color: #443A56 !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E9E9E9), to(#D9D9D9));
    background-image: -webkit-linear-gradient(top, #E9E9E9 0%, #D9D9D9 100%);
    background-image: linear-gradient(180deg, #E9E9E9 0%, #D9D9D9 100%);
    background-position: inherit
}

.iceinplay .one_click img {
    background-position: -94px -3550px
}

.iceinplay .one_click.select {
    color: #462E71 !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D5D5D5), to(#D9D9D9));
    background-image: -webkit-linear-gradient(top, #D5D5D5 0%, #D9D9D9 100%);
    background-image: linear-gradient(180deg, #D5D5D5 0%, #D9D9D9 100%);
    background-position: inherit
}

.iceinplay .one_click.select img {
    background-position: -94px -3520px
}

.iceinplay .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E9E9E9), to(#D9D9D9));
    background-image: -webkit-linear-gradient(top, #E9E9E9 0%, #D9D9D9 100%);
    background-image: linear-gradient(180deg, #E9E9E9 0%, #D9D9D9 100%)
}

.iceinplay .time_zone {
    color: #1e1e1e
}

.iceinplay .time_zone span {
    color: rgba(30, 30, 30, 0.7)
}

.iceinplay .menu-list li {
    border-bottom-color: #DDDDDD
}

.iceinplay .menu-list a {
    color: #462E71
}

.iceinplay .menu-list a:hover {
    background-color: rgba(70, 46, 113, 0.2)
}

.iceinplay .menu-list .select {
    background-color: rgba(70, 46, 113, 0.2)
}

.iceinplay .menu-list .Go {
    background-position: 100% -1000px;
    height: 25px;
    width: 23px
}

.iceinplay .menu-list .Go:hover,
.iceinplay .menu-list .Go.Go-iceinplay-hover {
    background-position: 100% -1025px
}

.iceinplay .path {
    background-repeat: repeat-x;
    background-position: 0 -5848px
}

.iceinplay .path .path-back {
    background-position: 0 -3325px
}

.iceinplay .path .path-back:hover,
.iceinplay .path .path-back.path-back-iceinplay-hover {
    background-position: 0 -3350px
}

.iceinplay .path .path-back.select {
    background-position: 0 -3350px
}

.iceinplay .path-pop {
    background-color: #160C28
}

.iceinplay .path-pop a {
    background-position: -21px -2125px
}

.iceinplay .game-highlight-wrap h3 {
    background-color: #443A56;
    color: #fff
}

.iceinplay .tab-highlight {
    background-repeat: repeat-x;
    background-position: 0 -10391px
}

.iceinplay .tab-markets {
    background: transparent
}

.iceinplay .one_click-setting {
    color: #1E1E1E;
    background-repeat: repeat-x;
    background-color: #ECECEC;
    background-position: 0 -4826px
}

.iceinplay .one_click-setting a {
    border-color: #9B9B9B
}

/* .iceinplay .one_click-setting .a-edit{
    background:#FF9C00 url(../images/iceinplay/bg-send-iceinplay.png) repeat-x;
    border-color:transparent;
    color:#1E1E1E
} */
.iceinplay .one_click-setting .a-edit img {
    background-position: -405px -2062px;
    height: 9px;
    width: 9px
}

.iceinplay .one_click-setting .btn-send {
    border-color: transparent
}

.iceinplay .one_click-stake {
    background-position: 0 -4182px;
    height: 32px;
    width: 414px
}

.iceinplay .one_click-stake input,
.iceinplay .one_click-stake textarea,
.iceinplay .one_click-stake .member_select a,
.member_select .iceinplay .one_click-stake a {
    border-color: #9B9B9B;
    color: #fff;
    background-color: #777777
}

.iceinplay .one_click-stake input:focus,
.iceinplay .one_click-stake textarea:focus,
.iceinplay .one_click-stake .member_select a:focus,
.member_select .iceinplay .one_click-stake a:focus {
    border-color: #462E71;
    color: #fff;
    background-color: #443A56
}

.iceinplay .submit-wrap .btn-send span {
    background-color: #462E71
}

body.dhoom {
    background-color: #EEEEEE
}

/* .dhoom .btn.select,.dhoom .select.btn-send,.dhoom .pages a.select,.pages .dhoom a.select,.dhoom .calendarTable_inputBox input.select,.calendarTable_inputBox .dhoom input.select,.dhoom .calendarTable_inputBox textarea.select,.calendarTable_inputBox .dhoom textarea.select,.dhoom .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .dhoom a.select,.dhoom .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .dhoom a.select,.dhoom .total_all .select.btn_replay,.total_all .dhoom .select.btn_replay,.dhoom .select.btn_expand,.dhoom .select.add_member{
    color:#fff;
    background:#C1292E url(../images/dhoom/btn-select-dhoom.png) repeat-x;
    border-color:#7B070B
} */
.dhoom .btn-send {
    background: none;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #F4D218), to(#BAA012));
    background-image: -webkit-linear-gradient(top, #F4D218 15%, #BAA012 100%);
    background-image: linear-gradient(180deg, #F4D218 15%, #BAA012 100%);
    border: 1px solid #6B5F02;
    border-radius: 4px;
    color: #000
}

.dhoom .btn-send:hover {
    background: none;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #BAA012), to(#F4D218));
    background-image: -webkit-linear-gradient(top, #BAA012 15%, #F4D218 100%);
    background-image: linear-gradient(180deg, #BAA012 15%, #F4D218 100%)
}

.dhoom .btn-send.disable {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #FBEDA3), to(#E5DBA1));
    background-image: -webkit-linear-gradient(top, #FBEDA3 15%, #E5DBA1 100%);
    background-image: linear-gradient(180deg, #FBEDA3 15%, #E5DBA1 100%);
    border: 1px solid #C4BF9A;
    color: #A5A5A5
}

.dhoom .login_to_go {
    background-color: #C1292E
}

.dhoom .login_to_go .close {
    background-position: -394px -1220px;
    height: 20px;
    width: 20px
}

/* .dhoom .kv{
    background:#14213D url(../images/dhoom/bg-login_wrap-dhoom.png) no-repeat
} */
.dhoom .login-panel {
    color: #fff
}

.dhoom .announce-header h1:before {
    background: #303FA3
}

/* .dhoom .top{
    background:#14213D url(../images/dhoom/bg-top-dhoom.jpg) repeat-x
} */
.dhoom .header {
    color: #fff
}

.dhoom .header h1 {
    margin-top: -7px;
    margin-top: -19px
}

.dhoom .login-wrap .btn-login {
    background: #E71A05
}

.dhoom .login-wrap li.user {
    background-position: -96px -5342px
}

.dhoom .login-wrap .error {
    color: #cccccc
}

.dhoom .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.dhoom .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.dhoom .account-wrap a {
    color: #fff
}

.dhoom .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4);
    background-color: #4552ac
}

.dhoom .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.dhoom .account-wrap .loading-bar span {
    background: #fff
}

.dhoom .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #4552ac;
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.dhoom .account-wrap .head-refresh a {
    background-position: -80px -1775px;
    height: 25px;
    width: 30px
}

.dhoom .account-wrap .account {
    background-position: 0 -625px;
    height: 25px;
    width: 110px
}

.dhoom .account-wrap ul a {
    color: #1E1E1E
}

.dhoom .account-wrap .logout a {
    color: #fff
}

.dhoom .menu-wrap {
    background: #C1292E
}

.dhoom .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.dhoom .menu li,
.dhoom .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.3)
}

.dhoom .menu li ul a,
.dhoom .setting-wrap li ul a {
    color: #fff
}

.dhoom .menu a,
.dhoom .setting-wrap a {
    color: #fff
}

.dhoom .menu a:hover,
.dhoom .setting-wrap a:hover {
    background-color: rgba(0, 0, 0, 0.3);
    text-decoration: underline
}

.dhoom .menu .select,
.dhoom .setting-wrap .select {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff
}

.dhoom .menu .logout,
.dhoom .setting-wrap .logout {
    border-left-width: 0
}

.dhoom .menu .logout img,
.dhoom .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23FFF" fill-rule="evenodd"/></svg>') no-repeat
}

.dhoom .live-menu {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.dhoom .setting {
    border-left-color: rgba(255, 255, 255, 0.3)
}

.dhoom .one_click {
    border-top-color: #532169;
    color: #141E63 !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#E9E9E9), to(#D9D9D9));
    background-image: -webkit-linear-gradient(top, #E9E9E9 0%, #D9D9D9 100%);
    background-image: linear-gradient(180deg, #E9E9E9 0%, #D9D9D9 100%);
    background-position: inherit
}

.dhoom .one_click img {
    background-position: -94px -3670px
}

.dhoom .one_click.select {
    color: #FFFFFF;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F58E8E), to(#F06969));
    background-image: -webkit-linear-gradient(top, #F58E8E 0%, #F06969 100%);
    background-image: linear-gradient(180deg, #F58E8E 0%, #F06969 100%);
    background-position: inherit
}

.dhoom .one_click.select img {
    background-position: -94px -3640px
}

.dhoom .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F06969), to(#F58E8E));
    background-image: -webkit-linear-gradient(top, #F06969 0%, #F58E8E 100%);
    background-image: linear-gradient(180deg, #F06969 0%, #F58E8E 100%)
}

.dhoom .time_zone {
    color: white
}

.dhoom .time_zone span {
    color: rgba(255, 255, 255, 0.5)
}

.dhoom .menu-list li {
    border-bottom-color: #DDDDDD
}

.dhoom .menu-list a {
    color: #141E63
}

.dhoom .menu-list a:hover {
    background-color: rgba(48, 63, 163, 0.1)
}

.dhoom .menu-list .select {
    background-color: rgba(193, 41, 46, 0.2)
}

.dhoom .menu-list .select:hover {
    background-color: rgba(193, 41, 46, 0.4)
}

.dhoom .menu-list .Go {
    background-position: 100% -1100px;
    height: 25px;
    width: 23px
}

.dhoom .menu-list .Go:hover,
.dhoom .menu-list .Go.Go-lucky7-hover {
    background-position: 100% -1125px
}

.dhoom .path {
    background: #0027AE
}

.dhoom .path .path-back {
    background-position: 0 -3075px
}

.dhoom .path .path-back:hover,
.dhoom .path .path-back.path-back-dhoom-hover {
    background-position: 0 -3100px
}

.dhoom .path .path-back.select {
    background-position: 0 -3100px
}

.dhoom .path-pop {
    background-color: #141E63
}

.dhoom .game-highlight-wrap h3 {
    background-color: #C1292E;
    color: #fff
}

.dhoom .tab-highlight {
    background: inherit;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(2%, #A32125), to(#C1292E));
    background-image: -webkit-linear-gradient(bottom, #A32125 2%, #C1292E 100%);
    background-image: linear-gradient(0deg, #A32125 2%, #C1292E 100%)
}

.dhoom .tab-markets {
    background: transparent
}

.dhoom .one_click-setting {
    color: #1E1E1E;
    color: #ECECEC;
    background: inherit;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#375BC2), color-stop(82%, #28429B));
    background-image: -webkit-linear-gradient(top, #375BC2 0%, #28429B 82%);
    background-image: linear-gradient(180deg, #375BC2 0%, #28429B 82%)
}

.dhoom .one_click-setting a {
    border-color: #384B90
}

.dhoom .one_click-setting .a-edit {
    border: 1px solid #112057;
    color: #FFF
}

.dhoom .one_click-setting .a-edit img {
    background-position: -405px -2134px;
    height: 9px;
    width: 9px
}

.dhoom .one_click-setting .btn-send {
    border-color: transparent
}

.dhoom .one_click-stake {
    background-position: 0 -4025px;
    height: 32px;
    width: 414px
}

.dhoom .one_click-stake input,
.dhoom .one_click-stake textarea,
.dhoom .one_click-stake .member_select a,
.member_select .dhoom .one_click-stake a {
    border-color: #9B9B9B;
    color: #fff;
    background-color: #555
}

.dhoom .one_click-stake input:focus,
.dhoom .one_click-stake textarea:focus,
.dhoom .one_click-stake .member_select a:focus,
.member_select .dhoom .one_click-stake a:focus {
    border-color: #5A0D10;
    color: #fff;
    background-color: #A8272C
}

.dhoom .submit-wrap .btn-send span {
    background-color: #215867
}

.baji .btn.select,
.baji .select.btn-send,
.baji .pages a.select,
.pages .baji a.select,
.baji .calendarTable_inputBox input.select,
.calendarTable_inputBox .baji input.select,
.baji .calendarTable_inputBox textarea.select,
.calendarTable_inputBox .baji textarea.select,
.baji .calendarTable_inputBox .member_select a.select,
.calendarTable_inputBox .member_select .baji a.select,
.baji .member_select .calendarTable_inputBox a.select,
.member_select .calendarTable_inputBox .baji a.select,
.baji .total_all .select.btn_replay,
.total_all .baji .select.btn_replay,
.baji .select.btn_expand,
.baji .select.add_member {
    color: #000;
    background: #F3DE00;
    border: 1px solid #4A4A4A;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.1)
}

.baji .btn.select:hover,
.baji .select.btn-send:hover,
.baji .pages a.select:hover,
.pages .baji a.select:hover,
.baji .calendarTable_inputBox input.select:hover,
.calendarTable_inputBox .baji input.select:hover,
.baji .calendarTable_inputBox textarea.select:hover,
.calendarTable_inputBox .baji textarea.select:hover,
.baji .calendarTable_inputBox .member_select a.select:hover,
.calendarTable_inputBox .member_select .baji a.select:hover,
.baji .member_select .calendarTable_inputBox a.select:hover,
.member_select .calendarTable_inputBox .baji a.select:hover,
.baji .total_all .select.btn_replay:hover,
.total_all .baji .select.btn_replay:hover,
.baji .select.btn_expand:hover,
.baji .select.add_member:hover {
    background: #F3DE00;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2)
}

.baji .btn-send {
    background: inherit;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #14805E), to(#026949));
    background-image: -webkit-linear-gradient(top, #14805E 15%, #026949 100%);
    background-image: linear-gradient(180deg, #14805E 15%, #026949 100%);
    border: 1px solid #08571D;
    border-radius: 4px;
    color: #fff
}

.baji .btn-send:hover {
    background: inherit;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #026949), to(#14805E));
    background-image: -webkit-linear-gradient(top, #026949 15%, #14805E 100%);
    background-image: linear-gradient(180deg, #026949 15%, #14805E 100%)
}

.baji .btn-send.disable {
    background: inherit;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #C9D9D2), to(#C4D1CB));
    background-image: -webkit-linear-gradient(top, #C9D9D2 15%, #C4D1CB 100%);
    background-image: linear-gradient(180deg, #C9D9D2 15%, #C4D1CB 100%);
    border: 1px solid #ABBBB6;
    color: #EEFCF4;
    border-radius: 4px
}

.baji .login_to_go .close {
    background-position: -394px -1080px;
    height: 20px;
    width: 20px
}

/* .baji .kv{
    background:#14213D url(../images/baji/bg-login_wrap-baji.png) no-repeat
} */
.baji .login-panel {
    color: #fff
}

.baji .login-panel input,
.baji .login-panel textarea,
.baji .login-panel .member_select a,
.member_select .baji .login-panel a {
    border-color: #3E8142
}

.baji .login-panel .error {
    color: #CDBC00
}

.baji .icon-login {
    background-position: 0 -196px;
    height: 12px;
    width: 12px
}

.baji .announce-header h1:before {
    background: #14805E
}

.baji .top {
    background: #14805E
}

.baji .header {
    color: #fff
}

.baji .header h1 {
    margin-top: -15px
}

.baji .login-wrap .btn-login {
    color: #000;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F3DE00), to(#CDBC00));
    background-image: -webkit-linear-gradient(top, #F3DE00 0%, #CDBC00 100%);
    background-image: linear-gradient(180deg, #F3DE00 0%, #CDBC00 100%);
    border: 1px solid #08571D
}

.baji .login-wrap .btn-login img {
    width: 10px;
    height: 11px;
    margin: -3px 0 0 3px;
    vertical-align: middle;
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23000" fill-rule="evenodd"/></svg>') no-repeat
}

.baji .login-wrap li.user {
    background-position: -96px -5042px
}

.baji .login-wrap .error {
    color: #CDBC00
}

.baji .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.baji .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.baji .account-wrap a {
    color: #fff
}

.baji .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4);
    background-color: #166c51
}

.baji .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.baji .account-wrap .loading-bar span {
    background: #fff
}

.baji .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.baji .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #166c51;
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.baji .account-wrap .a-wallet .red {
    background-color: #fff;
    padding: 0 3px;
    border-radius: 2px
}

.baji .account-wrap .head-refresh a {
    background-position: -80px -1350px;
    height: 25px;
    width: 30px
}

.baji .account-wrap .account {
    background-position: 0 -200px;
    height: 25px;
    width: 110px
}

.baji .account-wrap ul a {
    color: #1E1E1E
}

.baji .account-wrap .logout a {
    color: #fff
}

.baji .menu-wrap {
    background-image: inherit;
    background-color: #333333;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2);
    background-position: inherit
}

.baji .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.baji .menu li,
.baji .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.baji .menu a:hover,
.baji .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.baji .menu .select,
.baji .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.baji .live-menu {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.baji .live-menu img {
    background-position: -89px -4476px
}

.baji .setting {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.baji .one_click {
    border-top-color: #C8B810;
    color: #fff !important;
    background-position: inherit;
    background: #3E5F57
}

.baji .one_click img {
    background-position: -94px -4180px
}

.baji .one_click.select {
    color: #fff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #479C88), to(#377969));
    background-image: -webkit-linear-gradient(top, #479C88 11%, #377969 100%);
    background-image: linear-gradient(180deg, #479C88 11%, #377969 100%);
    background-position: inherit
}

.baji .one_click.select img {
    background-position: -94px -4150px
}

.baji .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #377969), to(#479C88));
    background-image: -webkit-linear-gradient(top, #377969 11%, #479C88 100%);
    background-image: linear-gradient(180deg, #377969 11%, #479C88 100%)
}

.baji .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #272B23), to(#32372C));
    background-image: -webkit-linear-gradient(top, #272B23 12%, #32372C 100%);
    background-image: linear-gradient(180deg, #272B23 12%, #32372C 100%)
}

.baji .time_zone {
    color: white
}

.baji .time_zone span {
    color: rgba(255, 255, 255, 0.5)
}

.baji .sub_path {
    background-color: #333333
}

.baji .menu-list li {
    border-bottom-color: #464646
}

.baji .menu-list a {
    color: #BFBFBF
}

.baji .menu-list a:hover,
.baji .menu-list a.select {
    background-color: #575757
}

.baji .menu-list .Go {
    background-position: 100% -350px;
    height: 25px;
    width: 23px
}

.baji .menu-list .Go:hover,
.baji .menu-list .Go.Go-baji-hover {
    background-position: 100% -425px
}

.baji .path {
    background-repeat: inherit;
    background-image: none;
    background-color: #14805E
}

.baji .path .path-back {
    background-position: 0 -2575px
}

.baji .path .path-back:hover,
.baji .path .path-back.path-back-baji-hover {
    background-position: 0 -2650px
}

.baji .path .path-back.select {
    background-position: 0 -2650px
}

.baji .path-pop {
    background-color: #000
}

.baji .game-highlight-wrap h3 {
    background-color: #14805E;
    color: #fff
}

.baji .tab-highlight {
    background: none;
    background-repeat: inherit;
    background-image: -webkit-linear-gradient(89deg, #379477 3%, #14805E 98%);
    background-image: linear-gradient(1deg, #379477 3%, #14805E 98%)
}

.baji .tab-markets {
    background: transparent
}

.baji .one_click-setting {
    color: #fff;
    background-repeat: repeat-x;
    background-position: 0 -4358px
}

.baji .one_click-setting .btn-send {
    background-repeat: inherit;
    background: none;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#439380), to(#387B6B));
    background-image: -webkit-linear-gradient(top, #439380 0%, #387B6B 100%);
    background-image: linear-gradient(180deg, #439380 0%, #387B6B 100%);
    border: 1px solid #244A3E;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
    border-radius: 4px
}

.baji .one_click-setting .btn-send:hover {
    background: none;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#387B6B), to(#439380));
    background-image: -webkit-linear-gradient(top, #387B6B 0%, #439380 100%);
    background-image: linear-gradient(180deg, #387B6B 0%, #439380 100%)
}

.baji .one_click-setting .a-edit {
    color: #fff
}

.baji .one_click-setting .a-edit img {
    background-position: -405px -2071px;
    height: 9px;
    width: 9px
}

.baji .one_click-stake {
    background-position: 0 -3714px;
    height: 31px;
    width: 414px
}

.baji .one_click-stake input,
.baji .one_click-stake textarea,
.baji .one_click-stake .member_select a,
.member_select .baji .one_click-stake a {
    color: #000;
    background: #AFDAC3;
    border: 1px solid #47685C;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.1)
}

.baji .one_click-stake input:focus,
.baji .one_click-stake textarea:focus,
.baji .one_click-stake .member_select a:focus,
.member_select .baji .one_click-stake a:focus {
    border-color: #8F3B27;
    background-color: #F4F4DB
}

.baji .function-wrap {
    background-color: #E7EFEF
}

.baji .submit-wrap .btn-send span {
    background-color: #1D3900
}

.agexch247 .btn.select,
.agexch247 .select.btn-send,
.agexch247 .pages a.select,
.pages .agexch247 a.select,
.agexch247 .calendarTable_inputBox input.select,
.calendarTable_inputBox .agexch247 input.select,
.agexch247 .calendarTable_inputBox textarea.select,
.calendarTable_inputBox .agexch247 textarea.select,
.agexch247 .calendarTable_inputBox .member_select a.select,
.calendarTable_inputBox .member_select .agexch247 a.select,
.agexch247 .member_select .calendarTable_inputBox a.select,
.member_select .calendarTable_inputBox .agexch247 a.select,
.agexch247 .total_all .select.btn_replay,
.total_all .agexch247 .select.btn_replay,
.agexch247 .select.btn_expand,
.agexch247 .select.add_member {
    color: #fff;
    border-color: #830000;
    background: #C90000;
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.1)
}

/* .agexch247 .btn.select:hover,.agexch247 .select.btn-send:hover,.agexch247 .pages a.select:hover,.pages .agexch247 a.select:hover,.agexch247 .calendarTable_inputBox input.select:hover,.calendarTable_inputBox .agexch247 input.select:hover,.agexch247 .calendarTable_inputBox textarea.select:hover,.calendarTable_inputBox .agexch247 textarea.select:hover,.agexch247 .calendarTable_inputBox .member_select a.select:hover,.calendarTable_inputBox .member_select .agexch247 a.select:hover,.agexch247 .member_select .calendarTable_inputBox a.select:hover,.member_select .calendarTable_inputBox .agexch247 a.select:hover,.agexch247 .total_all .select.btn_replay:hover,.total_all .agexch247 .select.btn_replay:hover,.agexch247 .select.btn_expand:hover,.agexch247 .select.add_member:hover{
    background:#830000 url(../images/agexch247/btn-select-agEX.png) repeat-x
} */
.agexch247 .btn-send {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#B90010), to(#720010));
    background-image: -webkit-linear-gradient(top, #B90010 0%, #720010 100%);
    background-image: linear-gradient(180deg, #B90010 0%, #720010 100%);
    border-color: #590000;
    color: #fff
}

.agexch247 .btn-send:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#720010), to(#B90010));
    background-image: -webkit-linear-gradient(top, #720010 0%, #B90010 100%);
    background-image: linear-gradient(180deg, #720010 0%, #B90010 100%)
}

.agexch247 .btn-send.disable {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BCAAAA), to(#BCAAAA));
    background-image: -webkit-linear-gradient(top, #BCAAAA 0%, #BCAAAA 100%);
    background-image: linear-gradient(-180deg, #BCAAAA 0%, #BCAAAA 100%);
    border-color: #aaa;
    color: rgba(255, 255, 255, 0.6)
}

/* .agexch247 .kv{
    background:#000 url(../images/agEX247/bg-login_wrap-agEX.jpg) no-repeat
} */
.agexch247 .login-panel {
    color: #fff
}

.agexch247 .icon-login {
    width: 10px;
    height: 11px;
    background-image: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23fff" fill-rule="evenodd"/></svg>');
    background-repeat: no-repeat
}

.agexch247 .announce-header h1:before {
    background: #D00101
}

/* .agexch247 .top{
    background:url(../images/agEX247/bg-top-agEX.png),-webkit-gradient(linear, left top, left bottom, from(#280000), to(#000));
    background:url(../images/agEX247/bg-top-agEX.png),-webkit-linear-gradient(top, #280000 0%, #000 100%);
    background:url(../images/agEX247/bg-top-agEX.png),linear-gradient(180deg, #280000 0%, #000 100%);
    background-repeat:no-repeat
} */
.agexch247 .header {
    color: #fff
}

.agexch247 .header h1 {
    margin-top: -15px
}

.agexch247 .login-wrap li.user {
    background-position: -96px -4967px
}

.agexch247 .login-wrap .error {
    color: #e61e36
}

.agexch247 .login-wrap .btn-signup {
    display: none;
    background-color: #666666;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#666), to(#333));
    background-image: -webkit-linear-gradient(top, #666 0%, #333 100%);
    background-image: linear-gradient(-180deg, #666 0%, #333 100%)
}

.agexch247 .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.agexch247 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #F2E9CF !important;
    background-color: #BF0000;
    border-radius: 3px
}

.agexch247 .account-wrap a {
    color: #fff
}

.agexch247 .account-wrap .main-wallet {
    background-color: #ab0000
}

.agexch247 .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.agexch247 .account-wrap .loading-bar span {
    background: #fff
}

.agexch247 .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #ab0000
}

.agexch247 .account-wrap .head-refresh a {
    background-position: -80px -1250px;
    height: 25px;
    width: 30px
}

.agexch247 .account-wrap .account {
    background-position: 0 -100px;
    height: 25px;
    width: 110px
}

.agexch247 .account-wrap ul a {
    color: #333
}

.agexch247 .account-wrap .logout a {
    color: #fff;
    background: #AB0000
}

.agexch247 .menu-wrap {
    background: #60000C;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, #8C0000), to(#7C0000));
    background-image: -webkit-linear-gradient(top, #8C0000 8%, #7C0000 100%);
    background-image: linear-gradient(180deg, #8C0000 8%, #7C0000 100%);
    border-top: solid 1px #B90017
}

.agexch247 .menu-wrap a.menu-drop:after {
    border-top-color: #fff
}

.agexch247 .menu li,
.agexch247 .setting-wrap li {
    border-right-color: rgba(45, 0, 0, 0.3)
}

.agexch247 .menu li ul a,
.agexch247 .setting-wrap li ul a {
    color: #fff !important
}

.agexch247 .menu li ul a:hover,
.agexch247 .setting-wrap li ul a:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#404040), to(#222));
    background-image: -webkit-linear-gradient(top, #404040 0%, #222 100%);
    background-image: linear-gradient(180deg, #404040 0%, #222 100%)
}

.agexch247 .menu a,
.agexch247 .setting-wrap a {
    color: #fff
}

.agexch247 .menu a:hover,
.agexch247 .setting-wrap a:hover {
    text-decoration: underline;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D00014), to(#AB0000));
    background-image: -webkit-linear-gradient(top, #D00014 0%, #AB0000 100%);
    background-image: linear-gradient(180deg, #D00014 0%, #AB0000 100%);
    box-shadow: inset 0 1px 2px 0 rgba(52, 9, 9, 0.8)
}

.agexch247 .menu .select,
.agexch247 .setting-wrap .select {
    color: #FFF;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D00014), color-stop(50%, #AB0000));
    background-image: -webkit-linear-gradient(top, #D00014 0%, #AB0000 50%);
    background-image: linear-gradient(180deg, #D00014 0%, #AB0000 50%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#FF0018), to(#D20000));
    background-image: -webkit-linear-gradient(top, #FF0018 0%, #D20000 100%);
    background-image: linear-gradient(180deg, #FF0018 0%, #D20000 100%);
    box-shadow: inset 0 1px 3px 0 rgba(52, 9, 9, 0.4)
}

.agexch247 .menu .select:hover,
.agexch247 .setting-wrap .select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#D20000), to(#FF0018));
    background-image: -webkit-linear-gradient(top, #D20000 0%, #FF0018 100%);
    background-image: linear-gradient(180deg, #D20000 0%, #FF0018 100%);
    box-shadow: inset 0 1px 2px 0 rgba(52, 9, 9, 0.4)
}

.agexch247 .menu .tag-new,
.agexch247 .setting-wrap .tag-new {
    color: #FFFFFF
}

.agexch247 .setting {
    border-left-color: rgba(69, 32, 7, 0.2)
}

.agexch247 .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23fff" fill-rule="evenodd"/></svg>') no-repeat
}

.agexch247 .one_click {
    border-top-color: #FF5F5F;
    background-repeat: repeat-x;
    color: #fff !important
}

.agexch247 .one_click img {
    background-position: -94px -2530px
}

.agexch247 .one_click.select {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F00015), color-stop(50%, #AB0019));
    background-image: -webkit-linear-gradient(top, #F00015 0%, #AB0019 50%);
    background-image: linear-gradient(180deg, #F00015 0%, #AB0019 50%);
    background-repeat: repeat
}

.agexch247 .one_click.select img {
    background-position: -94px -2500px
}

.agexch247 .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#A6000F), to(#F00015));
    background-image: -webkit-linear-gradient(top, #A6000F 0%, #F00015 100%);
    background-image: linear-gradient(180deg, #A6000F 0%, #F00015 100%);
    background-repeat: repeat
}

.agexch247 .slip_set-pop a:hover,
.agexch247 .oneclick_set-pop a:hover {
    text-decoration: none
}

.agexch247 .slip_set-pop .btn-send,
.agexch247 .oneclick_set-pop .btn-send {
    color: #fff
}

.agexch247 .time_zone {
    color: #fff
}

.agexch247 .time_zone span {
    color: #E87272
}

.agexch247 .menu-list li {
    border-bottom-color: #F8DDD5
}

.agexch247 .menu-list a {
    color: #222
}

.agexch247 .menu-list a:hover {
    background-color: #F8DDD5
}

.agexch247 .menu-list .select {
    background-color: #F8DDD5;
    color: #222
}

.agexch247 .menu-list .select:hover {
    background-color: #F8DDD5
}

.agexch247 .menu-list .Go {
    background-position: 100% -200px;
    height: 25px;
    width: 23px
}

.agexch247 .menu-list .Go:hover,
.agexch247 .menu-list .Go.Go-agEX-hover {
    background-position: 100% -225px
}

.agexch247 .path {
    background: #222
}

.agexch247 .path a {
    color: #fff
}

.agexch247 .path .path-back {
    background-position: 0 -2425px;
    height: 25px;
    width: 36px
}

.agexch247 .path .path-back:hover,
.agexch247 .path .path-back.path-back-agEX-hover {
    background-position: 0 -2450px
}

.agexch247 .path .path-back.select {
    background-position: 0 -2450px;
    height: 25px;
    width: 36px
}

.agexch247 .path-pop {
    background-color: #710000
}

.agexch247 .game-highlight-wrap h3 {
    color: #fff;
    background-color: #710000
}

.agexch247 .game-highlight-wrap .tab-highlight {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #710000), to(#960004));
    background-image: -webkit-linear-gradient(top, #710000 30%, #960004 100%);
    background-image: linear-gradient(180deg, #710000 30%, #960004 100%);
    background-repeat: inherit;
    background-position: inherit
}

.agexch247 .login_to_go {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#600009), to(#190004));
    background-image: -webkit-linear-gradient(top, #600009 0%, #190004 100%);
    background-image: linear-gradient(180deg, #600009 0%, #190004 100%)
}

.agexch247 .login_to_go .close {
    background-position: -394px -1220px;
    height: 20px;
    width: 20px
}

.agexch247 .change_pass {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#600009), to(#190004));
    background-image: -webkit-linear-gradient(top, #600009 0%, #190004 100%);
    background-image: linear-gradient(180deg, #600009 0%, #190004 100%)
}

.agexch247 .change_pass .note {
    color: #A06969
}

.agexch247 .one_click-setting {
    color: #fff;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(10%, rgba(51, 51, 51, 0)), to(#D00101));
    background-image: -webkit-linear-gradient(bottom, rgba(51, 51, 51, 0) 10%, #D00101 100%);
    background-image: linear-gradient(0deg, rgba(51, 51, 51, 0) 10%, #D00101 100%);
    background-color: #940000;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.36);
    background-repeat: inherit
}

.agexch247 .one_click-setting a {
    border-color: #530606
}

.agexch247 .one_click-setting .a-edit {
    color: #1E1E1E;
    color: #fff
}

.agexch247 .one_click-setting .a-edit img {
    background-position: -405px -2296px;
    height: 9px;
    width: 9px
}

.agexch247 .one_click-stake {
    background-position: 0 -3621px;
    height: 31px;
    width: 414px
}

.agexch247 .one_click-stake input,
.agexch247 .one_click-stake textarea,
.agexch247 .one_click-stake .member_select a,
.member_select .agexch247 .one_click-stake a {
    border-color: #530606;
    color: #000;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #fff), color-stop(93%, #E1E1E1));
    background-image: -webkit-linear-gradient(top, #fff 15%, #E1E1E1 93%);
    background-image: linear-gradient(180deg, #fff 15%, #E1E1E1 93%);
    border-radius: 4px
}

.agexch247 .one_click-stake input:focus,
.agexch247 .one_click-stake textarea:focus,
.agexch247 .one_click-stake .member_select a:focus,
.member_select .agexch247 .one_click-stake a:focus {
    border-color: #fff;
    background-color: #D00101
}

.agexch247 .one_click-stake .select {
    background-color: #000
}

.agexch247 .menu .logout,
.agexch247 .setting-wrap .logout {
    border-left-width: 0
}

.agexch247 .menu .logout img,
.agexch247 .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23fff" fill-rule="evenodd"/></svg>') no-repeat
}

.agexch247 .footer_fixed .main_wrap,
.agexch247 .footer_fixed .header,
.agexch247 .footer_fixed .footer-content {
    background-color: #F0ECE1
}

.agexch247 .submit-wrap .btn-send span {
    background-color: #000
}

.agexch247 .pages a.select {
    color: #fff;
    background-color: #C10000
}

.agexch247 .pages a.select:hover {
    background-color: #ab0000
}

.baji365 .btn.select,
.baji365 .select.btn-send,
.baji365 .pages a.select,
.pages .baji365 a.select,
.baji365 .calendarTable_inputBox input.select,
.calendarTable_inputBox .baji365 input.select,
.baji365 .calendarTable_inputBox textarea.select,
.calendarTable_inputBox .baji365 textarea.select,
.baji365 .calendarTable_inputBox .member_select a.select,
.calendarTable_inputBox .member_select .baji365 a.select,
.baji365 .member_select .calendarTable_inputBox a.select,
.member_select .calendarTable_inputBox .baji365 a.select,
.baji365 .total_all .select.btn_replay,
.total_all .baji365 .select.btn_replay,
.baji365 .select.btn_expand,
.baji365 .select.add_member {
    color: #000;
    background: #F3DE00;
    border: 1px solid #4A4A4A;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.1)
}

.baji365 .btn.select:hover,
.baji365 .select.btn-send:hover,
.baji365 .pages a.select:hover,
.pages .baji365 a.select:hover,
.baji365 .calendarTable_inputBox input.select:hover,
.calendarTable_inputBox .baji365 input.select:hover,
.baji365 .calendarTable_inputBox textarea.select:hover,
.calendarTable_inputBox .baji365 textarea.select:hover,
.baji365 .calendarTable_inputBox .member_select a.select:hover,
.calendarTable_inputBox .member_select .baji365 a.select:hover,
.baji365 .member_select .calendarTable_inputBox a.select:hover,
.member_select .calendarTable_inputBox .baji365 a.select:hover,
.baji365 .total_all .select.btn_replay:hover,
.total_all .baji365 .select.btn_replay:hover,
.baji365 .select.btn_expand:hover,
.baji365 .select.add_member:hover {
    background: #F3DE00;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2)
}

.baji365 .btn-send {
    background: inherit;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #14805E), to(#026949));
    background-image: -webkit-linear-gradient(top, #14805E 15%, #026949 100%);
    background-image: linear-gradient(180deg, #14805E 15%, #026949 100%);
    border: 1px solid #08571D;
    border-radius: 4px;
    color: #fff
}

.baji365 .btn-send:hover {
    background: inherit;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #026949), to(#14805E));
    background-image: -webkit-linear-gradient(top, #026949 15%, #14805E 100%);
    background-image: linear-gradient(180deg, #026949 15%, #14805E 100%)
}

.baji365 .btn-send.disable {
    background: inherit;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(15%, #C9D9D2), to(#C4D1CB));
    background-image: -webkit-linear-gradient(top, #C9D9D2 15%, #C4D1CB 100%);
    background-image: linear-gradient(180deg, #C9D9D2 15%, #C4D1CB 100%);
    border: 1px solid #ABBBB6;
    color: #EEFCF4;
    border-radius: 4px
}

.baji365 .login_to_go .close {
    background-position: -394px -1080px;
    height: 20px;
    width: 20px
}

/* .baji365 .kv{
    background:#14213D url(../images/baji365/bg-login_wrap-baji365.png) no-repeat
} */
.baji365 .login-panel {
    color: #fff
}

.baji365 .login-panel input,
.baji365 .login-panel textarea,
.baji365 .login-panel .member_select a,
.member_select .baji365 .login-panel a {
    border-color: #3E8142
}

.baji365 .login-panel .error {
    color: #CDBC00
}

.baji365 .icon-login {
    background-position: 0 -208px;
    height: 12px;
    width: 12px
}

.baji365 .announce-header h1:before {
    background: #14805E
}

.baji365 .top {
    background: #14805E
}

.baji365 .header {
    color: #fff
}

.baji365 .header h1 {
    margin-top: -5px
}

.baji365 .login-wrap .btn-login {
    color: #000;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#F3DE00), to(#CDBC00));
    background-image: -webkit-linear-gradient(top, #F3DE00 0%, #CDBC00 100%);
    background-image: linear-gradient(180deg, #F3DE00 0%, #CDBC00 100%);
    border: 1px solid #08571D
}

.baji365 .login-wrap .btn-login img {
    width: 10px;
    height: 11px;
    margin: -3px 0 0 3px;
    vertical-align: middle;
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23000" fill-rule="evenodd"/></svg>') no-repeat
}

.baji365 .login-wrap li.user {
    background-position: -96px -5067px
}

.baji365 .login-wrap .error {
    color: #CDBC00
}

.baji365 .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.baji365 .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #fff !important;
    background-color: #BE0000;
    border-radius: 3px
}

.baji365 .account-wrap a {
    color: #fff
}

.baji365 .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.4);
    background-color: #166c51
}

.baji365 .account-wrap .a-wallet .nums {
    border: 1px solid #FFF
}

.baji365 .account-wrap .loading-bar span {
    background: #fff
}

.baji365 .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFF"/></svg>') no-repeat center center;
    background-size: contain
}

.baji365 .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: #166c51;
    border: 1px solid rgba(0, 0, 0, 0.5)
}

.baji365 .account-wrap .a-wallet .red {
    background-color: #fff;
    padding: 0 3px;
    border-radius: 2px
}

.baji365 .account-wrap .head-refresh a {
    background-position: -80px -1375px;
    height: 25px;
    width: 30px
}

.baji365 .account-wrap .account {
    background-position: 0 -225px;
    height: 25px;
    width: 110px
}

.baji365 .account-wrap ul a {
    color: #1E1E1E
}

.baji365 .account-wrap .logout a {
    color: #fff
}

.baji365 .menu-wrap {
    background-image: inherit;
    background-color: #333333;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2);
    background-position: inherit
}

.baji365 .menu-wrap a.menu-drop:after {
    border-top-color: #FFF
}

.baji365 .menu li,
.baji365 .setting-wrap li {
    border-right-color: rgba(255, 255, 255, 0.1)
}

.baji365 .menu a:hover,
.baji365 .setting-wrap a:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

.baji365 .menu .select,
.baji365 .setting-wrap .select {
    background-color: rgba(255, 255, 255, 0.2)
}

.baji365 .live-menu {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.baji365 .live-menu img {
    background-position: -89px -4494px
}

.baji365 .setting {
    border-left-color: rgba(218, 255, 116, 0.2)
}

.baji365 .one_click {
    border-top-color: #C8B810;
    color: #fff !important;
    background-position: inherit;
    background: #3E5F57
}

.baji365 .one_click img {
    background-position: -94px -4180px
}

.baji365 .one_click.select {
    color: #fff;
    box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.4);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #479C88), to(#377969));
    background-image: -webkit-linear-gradient(top, #479C88 11%, #377969 100%);
    background-image: linear-gradient(180deg, #479C88 11%, #377969 100%);
    background-position: inherit
}

.baji365 .one_click.select img {
    background-position: -94px -4150px
}

.baji365 .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(11%, #377969), to(#479C88));
    background-image: -webkit-linear-gradient(top, #377969 11%, #479C88 100%);
    background-image: linear-gradient(180deg, #377969 11%, #479C88 100%)
}

.baji365 .one_click:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(12%, #272B23), to(#32372C));
    background-image: -webkit-linear-gradient(top, #272B23 12%, #32372C 100%);
    background-image: linear-gradient(180deg, #272B23 12%, #32372C 100%)
}

.baji365 .time_zone {
    color: white
}

.baji365 .time_zone span {
    color: rgba(255, 255, 255, 0.5)
}

.baji365 .sub_path {
    background-color: #333333
}

.baji365 .menu-list li {
    border-bottom-color: #464646
}

.baji365 .menu-list a {
    color: #BFBFBF
}

.baji365 .menu-list a:hover,
.baji365 .menu-list a.select {
    background-color: #575757
}

.baji365 .menu-list .Go {
    background-position: 100% -375px;
    height: 25px;
    width: 23px
}

.baji365 .menu-list .Go:hover,
.baji365 .menu-list .Go.Go-baji365-hover {
    background-position: 100% -400px
}

.baji365 .path {
    background-repeat: inherit;
    background-image: none;
    background-color: #14805E
}

.baji365 .path .path-back {
    background-position: 0 -2600px
}

.baji365 .path .path-back:hover,
.baji365 .path .path-back.path-back-baji365-hover {
    background-position: 0 -2625px
}

.baji365 .path .path-back.select {
    background-position: 0 -2625px
}

.baji365 .path-pop {
    background-color: #000
}

.baji365 .game-highlight-wrap h3 {
    background-color: #14805E;
    color: #fff
}

.baji365 .tab-highlight {
    background: none;
    background-repeat: inherit;
    background-image: -webkit-linear-gradient(89deg, #379477 3%, #14805E 98%);
    background-image: linear-gradient(1deg, #379477 3%, #14805E 98%)
}

.baji365 .tab-markets {
    background: transparent
}

.baji365 .one_click-setting {
    color: #fff;
    background-repeat: repeat-x;
    background-position: 0 -4389px
}

.baji365 .one_click-setting .btn-send {
    background-repeat: inherit;
    background: none;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#439380), to(#387B6B));
    background-image: -webkit-linear-gradient(top, #439380 0%, #387B6B 100%);
    background-image: linear-gradient(180deg, #439380 0%, #387B6B 100%);
    border: 1px solid #244A3E;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
    border-radius: 4px
}

.baji365 .one_click-setting .btn-send:hover {
    background: none;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#387B6B), to(#439380));
    background-image: -webkit-linear-gradient(top, #387B6B 0%, #439380 100%);
    background-image: linear-gradient(180deg, #387B6B 0%, #439380 100%)
}

.baji365 .one_click-setting .a-edit {
    color: #fff
}

.baji365 .one_click-setting .a-edit img {
    background-position: -405px -2080px;
    height: 9px;
    width: 9px
}

.baji365 .one_click-stake {
    background-position: 0 -3745px;
    height: 31px;
    width: 414px
}

.baji365 .one_click-stake input,
.baji365 .one_click-stake textarea,
.baji365 .one_click-stake .member_select a,
.member_select .baji365 .one_click-stake a {
    color: #000;
    background: #AFDAC3;
    border: 1px solid #47685C;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.1)
}

.baji365 .one_click-stake input:focus,
.baji365 .one_click-stake textarea:focus,
.baji365 .one_click-stake .member_select a:focus,
.member_select .baji365 .one_click-stake a:focus {
    border-color: #8F3B27;
    background-color: #F4F4DB
}

.baji365 .function-wrap {
    background-color: #E7EFEF
}

.baji365 .submit-wrap .btn-send span {
    background-color: #1D3900
}

/* .yabo101sport .btn.select,.yabo101sport .select.btn-send,.yabo101sport .pages a.select,.pages .yabo101sport a.select,.yabo101sport .calendarTable_inputBox input.select,.calendarTable_inputBox .yabo101sport input.select,.yabo101sport .calendarTable_inputBox textarea.select,.calendarTable_inputBox .yabo101sport textarea.select,.yabo101sport .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .yabo101sport a.select,.yabo101sport .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .yabo101sport a.select,.yabo101sport .total_all .select.btn_replay,.total_all .yabo101sport .select.btn_replay,.yabo101sport .select.btn_expand,.yabo101sport .select.add_member{
    background:#FFB80C url(../images/yabo101sport/btn-select-yabo101sport.png) repeat-x
}
.yabo101sport .btn-send{
    background:#FFA00C url(../images/yabo101sport/bg-send-yabo101sport.png) repeat-x
}
.yabo101sport .btn-send:hover{
    background:#FFB80C url(../images/yabo101sport/bg-send_hover-yabo101sport.png) repeat-x
}
.yabo101sport .btn-send.disable{
    background:#FFE2B6 url(../images/yabo101sport/bg-send-disable-yabo101sport.png) repeat-x
}
.yabo101sport .kv{
    background:#FFB80C url(../images/yabo101sport/bg-login_wrap-yabo101sport.png) no-repeat
}
.yabo101sport .announce-header h1:before{
    background:#FFB80C
}
.yabo101sport .submit-wrap .btn-send span{
    background-color:#243A48
}
.wicketspro .btn.select,.wicketspro .select.btn-send,.wicketspro .pages a.select,.pages .wicketspro a.select,.wicketspro .calendarTable_inputBox input.select,.calendarTable_inputBox .wicketspro input.select,.wicketspro .calendarTable_inputBox textarea.select,.calendarTable_inputBox .wicketspro textarea.select,.wicketspro .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .wicketspro a.select,.wicketspro .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .wicketspro a.select,.wicketspro .total_all .select.btn_replay,.total_all .wicketspro .select.btn_replay,.wicketspro .select.btn_expand,.wicketspro .select.add_member{
    background:#FFB80C url(../images/9wicketspro/btn-select-9wicketspro.png) repeat-x
}
.wicketspro .btn-send{
    background:#FFA00C url(../images/9wicketspro/bg-send-9wicketspro.png) repeat-x
}
.wicketspro .btn-send:hover{
    background:#FFB80C url(../images/9wicketspro/bg-send_hover-9wicketspro.png) repeat-x
}
.wicketspro .btn-send.disable{
    background:#FFE2B6 url(../images/9wicketspro/bg-send-disable-9wicketspro.png) repeat-x
}
.wicketspro .kv{
    background:#FFB80C url(../images/9wicketspro/bg-login_wrap-9wicketspro.png) no-repeat
} */
.wicketspro .announce-header h1:before {
    background: #FFB80C
}

.wicketspro .submit-wrap .btn-send span {
    background-color: #243A48
}

body.sportsexchlive {
    background-color: #F0ECE1
}

/* .sportsexchlive .btn.select,.sportsexchlive .select.btn-send,.sportsexchlive .pages a.select,.pages .sportsexchlive a.select,.sportsexchlive .calendarTable_inputBox input.select,.calendarTable_inputBox .sportsexchlive input.select,.sportsexchlive .calendarTable_inputBox textarea.select,.calendarTable_inputBox .sportsexchlive textarea.select,.sportsexchlive .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .sportsexchlive a.select,.sportsexchlive .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .sportsexchlive a.select,.sportsexchlive .total_all .select.btn_replay,.total_all .sportsexchlive .select.btn_replay,.sportsexchlive .select.btn_expand,.sportsexchlive .select.add_member{
    color:#F8D61C;
    background:#444 url(../images/sportsexchlive/btn-select-sportsexchlive.jpg) repeat-x;
    border-color:#222
}
.sportsexchlive .btn.select:hover,.sportsexchlive .select.btn-send:hover,.sportsexchlive .pages a.select:hover,.pages .sportsexchlive a.select:hover,.sportsexchlive .calendarTable_inputBox input.select:hover,.calendarTable_inputBox .sportsexchlive input.select:hover,.sportsexchlive .calendarTable_inputBox textarea.select:hover,.calendarTable_inputBox .sportsexchlive textarea.select:hover,.sportsexchlive .calendarTable_inputBox .member_select a.select:hover,.calendarTable_inputBox .member_select .sportsexchlive a.select:hover,.sportsexchlive .member_select .calendarTable_inputBox a.select:hover,.member_select .calendarTable_inputBox .sportsexchlive a.select:hover,.sportsexchlive .total_all .select.btn_replay:hover,.total_all .sportsexchlive .select.btn_replay:hover,.sportsexchlive .select.btn_expand:hover,.sportsexchlive .select.add_member:hover{
    background:#444 url(../images/sportsexchlive/btn-select-sportsexchlive.jpg) repeat-x
}
.sportsexchlive .btn-send{
    background:#121212 url(../images/sportsexchlive/bg-send-sportsexchlive.png) repeat-x;
    color:#F8D61C;
    border-color:#000
}
.sportsexchlive .btn-send:hover{
    background:#464646 url(../images/sportsexchlive/bg-send_hover-sportsexchlive.png) repeat-x
}
.sportsexchlive .btn-send.disable{
    background:#A4A4A4 url(../images/sportsexchlive/bg-send-disable-sportsexchlive.png) repeat-x;
    border-color:#aaa;
    color:#E6DFCD
}
.sportsexchlive .kv{
    background:#000 url(../images/sportsexchlive/bg-login_wrap-sportsexchlive.jpg) no-repeat
} */
.sportsexchlive .login-panel {
    color: #000
}

.sportsexchlive .icon-login {
    background-position: 0 -460px;
    height: 12px;
    width: 12px
}

.sportsexchlive .announce-header h1:before {
    background: #FFB80C
}

/* .sportsexchlive .top{
    background:#000 url(../images/sportsexchlive/bg-top-sportsexchlive.jpg) repeat-x
} */
.sportsexchlive .header {
    color: #FFB80C
}

.sportsexchlive .header h1 {
    margin-top: -10px;
    display: none
}

.sportsexchlive .login-wrap li.user {
    background-position: -96px -5642px
}

.sportsexchlive .login-wrap .error {
    color: #F8D61C
}

.sportsexchlive .login-wrap .btn-signup {
    display: block;
    background-color: #666666;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#666), to(#333));
    background-image: -webkit-linear-gradient(top, #666 0%, #333 100%);
    background-image: linear-gradient(-180deg, #666 0%, #333 100%)
}

.sportsexchlive .account-wrap li .refresh-bar {
    background: url(../images/livesports/refresh80-livesports.gif) no-repeat center
}

.sportsexchlive .account-wrap .main-wallet.no-multi .red {
    padding: 1px 3px;
    color: #F2E9CF !important;
    background-color: #BF0000;
    border-radius: 3px
}

.sportsexchlive .account-wrap a {
    color: #FFB80C
}

.sportsexchlive .account-wrap .main-wallet {
    border-color: rgba(0, 0, 0, 0.8)
}

.sportsexchlive .account-wrap .a-wallet .nums {
    border: 1px solid #FFB80C
}

.sportsexchlive .account-wrap .a-wallet {
    background-color: rgba(255, 255, 255, 0.15) !important
}

.sportsexchlive .account-wrap .a-wallet.open {
    background-color: rgba(255, 255, 255, 0.25) !important
}

.sportsexchlive .account-wrap .loading-bar span {
    background: #fff
}

.sportsexchlive .account-wrap .a-refresh img {
    background: url('data:image/svg+xml,<svg width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg"><path d="M6.408 3.223c1.164 0 2.238.288 3.223.863.972.563 1.739 1.324 2.302 2.283.588.998.882 2.072.882 3.223 0 1.151-.294 2.225-.882 3.223-.563.972-1.33 1.739-2.302 2.302-.998.588-2.072.882-3.223.882-1.151 0-2.225-.294-3.223-.882-.972-.563-1.739-1.33-2.302-2.302-.588-.998-.882-2.072-.882-3.223h1.612c0 .87.214 1.672.643 2.408.428.735 1.01 1.317 1.746 1.746.735.428 1.538.643 2.408.643.87 0 1.672-.214 2.408-.643.735-.428 1.317-1.01 1.746-1.746.428-.735.643-1.538.643-2.408 0-.87-.214-1.672-.643-2.408-.428-.735-1.01-1.317-1.746-1.746-.735-.428-1.538-.643-2.408-.643v3.223l-4.01-4.01 4.01-4.01v3.223z" fill="%23FFB80C"/></svg>') no-repeat center center;
    background-size: contain
}

.sportsexchlive .account-wrap .main-wallet.no-multi .a-refresh {
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.8)
}

.sportsexchlive .account-wrap .head-refresh a {
    background-position: -80px -2150px;
    height: 25px;
    width: 30px
}

.sportsexchlive .account-wrap .account {
    background-position: 0 -1025px;
    height: 25px;
    width: 110px
}

.sportsexchlive .account-wrap ul a {
    color: #1E1E1E
}

.sportsexchlive .account-wrap .logout a {
    color: #fff
}

.sportsexchlive .menu-wrap {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, #FFCC2F), to(#FFB80C));
    background-image: -webkit-linear-gradient(top, #FFCC2F 8%, #FFB80C 100%);
    background-image: linear-gradient(180deg, #FFCC2F 8%, #FFB80C 100%);
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.75)
}

.sportsexchlive .menu-wrap a.menu-drop:after {
    border-top-color: #222
}

.sportsexchlive .menu li,
.sportsexchlive .setting-wrap li {
    border-right-color: rgba(69, 32, 7, 0.2)
}

.sportsexchlive .menu li ul a,
.sportsexchlive .setting-wrap li ul a {
    color: #FFB80C
}

.sportsexchlive .menu a,
.sportsexchlive .setting-wrap a {
    color: #222
}

.sportsexchlive .menu a:hover,
.sportsexchlive .setting-wrap a:hover {
    background-color: transparent;
    text-decoration: underline
}

.sportsexchlive .menu .select,
.sportsexchlive .setting-wrap .select {
    box-shadow: inset 0 1px 3px 0 rgba(52, 9, 9, 0.5);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#DBBC68), color-stop(61%, #FFDC7A));
    background-image: -webkit-linear-gradient(top, #DBBC68 0%, #FFDC7A 61%);
    background-image: linear-gradient(180deg, #DBBC68 0%, #FFDC7A 61%);
    background-position: inherit
}

.sportsexchlive .menu .tag-new,
.sportsexchlive .setting-wrap .tag-new {
    color: #FFFFFF
}

.sportsexchlive .setting {
    border-left-color: rgba(69, 32, 7, 0.2)
}

.sportsexchlive .setting img {
    background: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M6 8.106c.385 0 .745-.096 1.081-.289.336-.192.602-.45.8-.771a2.002 2.002 0 0 0 0-2.099 2.19 2.19 0 0 0-.8-.779A2.139 2.139 0 0 0 6 3.88c-.385 0-.743.096-1.074.288-.33.193-.594.452-.792.78a2.002 2.002 0 0 0 0 2.098c.198.322.462.579.792.771.331.193.689.289 1.074.289zm4.605-1.515l1.288.981c.06.048.094.11.104.188a.333.333 0 0 1-.044.216l-1.244 2.077a.269.269 0 0 1-.156.13.336.336 0 0 1-.214-.015l-1.526-.591c-.394.279-.745.476-1.05.591L7.54 11.74a.364.364 0 0 1-.111.188.272.272 0 0 1-.185.072H4.756a.29.29 0 0 1-.281-.26l-.237-1.572A3.752 3.752 0 0 1 3.2 9.577l-1.54.591c-.158.068-.28.03-.37-.115L.047 7.976a.333.333 0 0 1-.044-.216.278.278 0 0 1 .104-.188l1.303-.98A4.395 4.395 0 0 1 1.38 6c0-.26.01-.457.03-.591L.107 4.428a.278.278 0 0 1-.104-.188.333.333 0 0 1 .044-.216l1.244-2.077c.09-.144.212-.183.37-.115l1.54.591c.356-.26.701-.457 1.037-.591L4.475.26A.29.29 0 0 1 4.756 0h2.488c.069 0 .13.024.185.072.054.048.091.11.111.188l.222 1.572a3.87 3.87 0 0 1 1.051.591l1.526-.591a.336.336 0 0 1 .214-.015c.064.02.116.063.156.13l1.244 2.077c.04.067.054.14.044.216a.278.278 0 0 1-.104.188l-1.288.98c.02.135.03.332.03.592 0 .26-.01.457-.03.591z" fill="%23222" fill-rule="evenodd"/></svg>') no-repeat
}

.sportsexchlive .one_click {
    border-top-color: #FFB80C;
    color: #FFB80C !important;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(13%, #444), color-stop(81%, #1B1B1B));
    background-image: -webkit-linear-gradient(top, #444 13%, #1B1B1B 81%);
    background-image: linear-gradient(180deg, #444 13%, #1B1B1B 81%);
    background-position: inherit
}

.sportsexchlive .one_click img {
    background-position: -94px -4360px
}

.sportsexchlive .one_click.select {
    color: #1E1E1E !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#00C15D), to(#009347)) !important;
    background-image: -webkit-linear-gradient(top, #00C15D 0%, #009347 100%) !important;
    background-image: linear-gradient(180deg, #00C15D 0%, #009347 100%) !important;
    background-position: inherit
}

.sportsexchlive .one_click.select img {
    background-position: -94px -4330px
}

.sportsexchlive .one_click.select:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(100%, #009347), color-stop(0%, #00C15D)) !important;
    background-image: -webkit-linear-gradient(top, #009347 100%, #00C15D 0%) !important;
    background-image: linear-gradient(180deg, #009347 100%, #00C15D 0%) !important
}

.sportsexchlive .one_click:hover {
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(19%, #444), color-stop(87%, #1B1B1B));
    background-image: -webkit-linear-gradient(bottom, #444 19%, #1B1B1B 87%);
    background-image: linear-gradient(0deg, #444 19%, #1B1B1B 87%)
}

.sportsexchlive .slip_set-pop a:hover,
.sportsexchlive .oneclick_set-pop a:hover {
    text-decoration: none
}

.sportsexchlive .slip_set-pop .btn-send,
.sportsexchlive .oneclick_set-pop .btn-send {
    color: #F8D61C
}

.sportsexchlive .time_zone {
    color: #000
}

.sportsexchlive .time_zone span {
    color: #9A7816
}

.sportsexchlive .menu-list li {
    border-bottom-color: #EFE1C1
}

.sportsexchlive .menu-list a {
    color: #222
}

.sportsexchlive .menu-list a:hover {
    background-color: #F2DCA7
}

.sportsexchlive .menu-list .select {
    background-color: #F2DCA7;
    color: #222
}

.sportsexchlive .menu-list .select:hover {
    background-color: #F2DCA7
}

.sportsexchlive .menu-list .Go {
    background-position: 100% -1750px;
    height: 25px;
    width: 23px
}

.sportsexchlive .menu-list .Go:hover,
.sportsexchlive .menu-list .Go.Go-sportsexchlive-hover {
    background-position: 100% -1775px
}

.sportsexchlive .path {
    background: #222
}

.sportsexchlive .path a {
    color: #FFB80C
}

.sportsexchlive .path .path-back {
    background-position: 0 -4125px;
    height: 25px;
    width: 36px
}

.sportsexchlive .path .path-back:hover,
.sportsexchlive .path .path-back.path-back-sportsexchlive-hover {
    background-position: 0 -4150px
}

.sportsexchlive .path .path-back.select {
    background-position: 0 -4150px;
    height: 25px;
    width: 36px
}

.sportsexchlive .path-pop {
    background-color: #222
}

.sportsexchlive .login_to_go {
    background-color: #FFB80C
}

.sportsexchlive .login_to_go .close {
    background-position: -394px -1200px;
    height: 20px;
    width: 20px
}

.sportsexchlive .one_click-setting {
    color: #1E1E1E;
    background-repeat: repeat-x;
    background-color: #008841;
    background-position: 0 -5295px
}

.sportsexchlive .one_click-setting a {
    border-color: #005F2D
}

.sportsexchlive .one_click-setting .a-edit {
    color: #1E1E1E
}

.sportsexchlive .one_click-setting .a-edit img {
    background-position: -405px -2278px;
    height: 9px;
    width: 9px
}

.sportsexchlive .one_click-stake {
    background-position: 0 -4651px;
    height: 31px;
    width: 414px
}

.sportsexchlive .one_click-stake input,
.sportsexchlive .one_click-stake textarea,
.sportsexchlive .one_click-stake .member_select a,
.member_select .sportsexchlive .one_click-stake a {
    border-color: #005F2D;
    color: #fff;
    background-color: #1F8C53
}

.sportsexchlive .one_click-stake input:focus,
.sportsexchlive .one_click-stake textarea:focus,
.sportsexchlive .one_click-stake .member_select a:focus,
.member_select .sportsexchlive .one_click-stake a:focus {
    border-color: #fff;
    background-color: #00632F
}

.sportsexchlive .menu .logout,
.sportsexchlive .setting-wrap .logout {
    border-left-width: 0
}

.sportsexchlive .menu .logout img,
.sportsexchlive .setting-wrap .logout img {
    background: url('data:image/svg+xml,<svg width="10" height="11" xmlns="http://www.w3.org/2000/svg"><path d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z" fill="%23222" fill-rule="evenodd"/></svg>') no-repeat
}

.sportsexchlive .footer_fixed .main_wrap,
.sportsexchlive .footer_fixed .header,
.sportsexchlive .footer_fixed .footer-content {
    background-color: #F0ECE1
}

.sportsexchlive .submit-wrap .btn-send span {
    background-color: #666
}

/* .aecricket888 .btn.select,.aecricket888 .select.btn-send,.aecricket888 .pages a.select,.pages .aecricket888 a.select,.aecricket888 .calendarTable_inputBox input.select,.calendarTable_inputBox .aecricket888 input.select,.aecricket888 .calendarTable_inputBox textarea.select,.calendarTable_inputBox .aecricket888 textarea.select,.aecricket888 .calendarTable_inputBox .member_select a.select,.calendarTable_inputBox .member_select .aecricket888 a.select,.aecricket888 .member_select .calendarTable_inputBox a.select,.member_select .calendarTable_inputBox .aecricket888 a.select,.aecricket888 .total_all .select.btn_replay,.total_all .aecricket888 .select.btn_replay,.aecricket888 .select.btn_expand,.aecricket888 .select.add_member{
    background:#FFB80C url(../images/aecricket888/btn-select-aecricket888.png) repeat-x
}
.aecricket888 .btn-send{
    background:#FFA00C url(../images/aecricket888/bg-send-aecricket888.png) repeat-x
}
.aecricket888 .btn-send:hover{
    background:#FFB80C url(../images/aecricket888/bg-send_hover-aecricket888.png) repeat-x
}
.aecricket888 .btn-send.disable{
    background:#FFE2B6 url(../images/aecricket888/bg-send-disable-aecricket888.png) repeat-x
}
.aecricket888 .kv{
    background:#FFB80C url(../images/aecricket888/bg-login_wrap-aecricket888.png) no-repeat
} */
.aecricket888 .announce-header h1:before {
    background: #FFB80C
}

.aecricket888 .submit-wrap .btn-send span {
    background-color: #243A48
}

*::-ms-backdrop,
th {
    text-align: right
}

.path a {
    color: #eee9;
}

.slip-back dd input,
.slip-back dd textarea,
.slip-back dd .member_select a,
.member_select .slip-back dd a,
.slip-lay dd input,
.slip-lay dd textarea,
.slip-lay dd .member_select a,
.member_select .slip-lay dd a {
    height: 22px\9
}

.quick_bet-wrap dd input,
.quick_bet-wrap dd textarea,
.quick_bet-wrap dd .member_select a,
.member_select .quick_bet-wrap dd a {
    height: 33px\9
}

*::-ms-backdrop,
.slip-back dd input,
.slip-back dd textarea,
.slip-back dd .member_select a,
.member_select .slip-back dd a {
    height: 22px
}

*::-ms-backdrop,
.slip-lay dd input,
.slip-lay dd textarea,
.slip-lay dd .member_select a,
.member_select .slip-lay dd a {
    height: 22px
}

*::-ms-backdrop,
.quick_bet-wrap dd input,
.quick_bet-wrap dd textarea,
.quick_bet-wrap dd .member_select a,
.member_select .quick_bet-wrap dd a {
    height: 33px
}

.expand td {
    position: static\9
}

.expand td {
    position: relative\9\0
}

.expand-arrow {
    visibility: hidden\9
}

.expand-arrow {
    visibility: visible\9\0
}

.table01 .border-t td,
.table-s .border-t td,
.table_one .border-t td {
    border-top: 2px solid #7E97A7 \9
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .game-scores .scores-inns {
        display: inline-block;
        -webkit-transform: scale(0.8);
        transform: scale(0.8)
    }

    .slider {
        display: -webkit-box;
        display: flex
    }

    .fancy-info {
        padding: 0 6px;
        -webkit-transform: scale(0.9);
        transform: scale(0.9)
    }

    td .fancy-info {
        padding: 0
    }

    .table-pt td {
        padding: 8px 3px
    }
}

/* .dd-tips .fancy-tips:not(*:root){
    -webkit-transform:scale(0.9);
    transform:scale(0.9)
} */
.tag-new:not(*:root) {
    padding: 6px;
    -webkit-transform: scale(0.8);
    transform: scale(0.8)
}

.other-tab .tag-new:not(*:root) {
    top: -12px;
    right: -16px
}

.menu .tag-new:not(*:root),
.setting-wrap .tag-new:not(*:root) {
    padding: 0 10px 0 20px;
    -webkit-transform: none;
    transform: none
}

.game-E:not(*:root) {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
}

@keyframes marqueeAnimation-327153 {
    100% {
        transform: translateX(-1293px);
    }
}





.bdtwin .pages li.active a.undefined {
    color: #F8D61C;
    background: #444 url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/2wCEAAEBAQEBAQIBAQIDAgICAwQDAwMDBAUEBAQEBAUGBQUFBQUFBgYGBgYGBgYHBwcHBwcICAgICAkJCQkJCQkJCQkBAQEBAgICBAICBAkGBQYJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCf/AABEIABIABQMBIgACEQEDEQH/xABSAAEBAQAAAAAAAAAAAAAAAAAABAkQAQABBQAAAAAAAAAAAAAAAAABBBVSodEBAQAAAAAAAAAAAAAAAAAAAAARAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AMEbbRYbnouAAAf/2Q==') repeat-x;
    border-color: #222;
}

.pages li.disabled a {
    background: #dfdfdf;
    color: #999;
    cursor: not-allowed;
}



.react-datepicker__header {
    background-color: #3546b3 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #fff !important;
}

.react-datepicker__day-name {
    color: #fff !important;
}

.react-datepicker__navigation--previous {
    color: #fff !important;
}

.react-datepicker__navigation--next {
    color: #fff !important;
}

.marquee-box .marquee a span {
    height: 15px;
    line-height: 15px;
    font-size: 11px;
    font-style: italic;
    font-weight: normal;
    color: #1c2834;
    background-color: #6ac2ff;
    border-radius: 2px;
    padding: 0 5px;
    margin-right: 5px;
}

.dropcolor {
    color: #000
}

.menu li ul a {
    color: #fff;
}

.header {
    color: #ffb80c;
}

.pages a.select {
    color: white;
    background: #0a5b7c url(../images/skyexch/btn-select-skyEX.jpg) repeat-x;
    border-color: #222;
}

.play_race-wrap:after {
    content: "";
    display: block;
    clear: both;
}

.bg-img {
    background-image: url(../images/12bet/bg-login.jpeg)
}

.btn-send {
    background: #024168;
    color: #fffefe !important;
    border-color: #000;
}

.btn-send:hover {
    background: #ff6c65;
}


.bets-wrap .bet-all {
    line-height: 22px;
}

.table011 .open-odds {
    margin-right: 10px;
}

.open-odds:before {
    position: absolute;
    content: '';
    background-position: -33px -1219px;
    height: 23px;
    width: 25px;
    display: block;
}

.open-odds:before {
    background-image: url(http://localhost:3000/static/media/agent-sf42eb279ae.1b7f59561d954e93ce49.png);
    background-repeat: no-repeat;
}

.png-match {
    font-size: 16px;
    margin-left: 10px;
    height: 19px;
}

.menu .select {
    /* background-image: linear-gradient(180deg, #d9837e 0%, #d9837e 61%) !important; */
    background: linear-gradient(180deg, #dbbc68 0%, #ffdc7a 61%) !important;
}

.react-datepicker__navigation {
    top: 9px !important;
}

.react-datepicker__header {
    background-color: #f0f0f0 !important;
}

.react-datepicker__current-month {
    color: #398cc9 !important;
    font-size: 13px !important;
}

.react-datepicker__day-name {
    color: black !important;
    font-size: 12px !important;
}

.react-datepicker {
    font-size: 12px;
}

.react-datepicker__week {
    line-height: 23px !important;
}

.react-datepicker__header {
    border-bottom: none !important;


}

.react-datepicker__header {
    padding: 8px ​0 !important;
}

.react-datepicker__navigation {
    height: 14px !important;
}

.expand-open {
    background-position: -16px -151px !important;
}



.header h1 a {
    background: url(../images/bdtwin_logo.png) no-repeat;
    margin: -7px;
    height: 60px;
    width: 40px;
    margin-left: 10px;
}



/* input[type=text]:enabled {
    background: #ffff00 !important;
  }
  
  input[type=text]:disabled {
    background: #dddddd !important;
  }
  input[type=radio]:enabled {
    background: #ffff00 !important;
  }
  
  input[type=radio]:disabled {
    background: #dddddd !important;
  } */
input[type=radio]:focus {
    background-color: yellow !important;
}